import React from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { GetWebCurrentUser, RemoveUserSanitizerFilter, RemoveUserSanitizerFilterVariables, SetUserSanitizerFilter, SetUserSanitizerFilterVariables } from '../../GraphQL';
import { SanitizersFilter } from './Planner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Select from 'react-select';
import Dropdown from '@ssg/common/Components/Dropdown';
import UserContext from '../../UserContext';
import Loading from '@ssg/common/Components/Loading';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const SET_USER_SANITIZER_FILTER = loader('src/GraphQL/Users/SetUserSanitizerFilter.gql');
const REMOVE_USER_SANITIZER_FILTER = loader('src/GraphQL/Users/RemoveUserSanitizerFilter.gql');

interface Props {
	filters: SanitizersFilter;
	setFilters: React.Dispatch<React.SetStateAction<SanitizersFilter>>;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<SanitizersFilter>>;
	setSanitizerSearchTerm: (v: string) => void;
	sanitizerSearchTerm: string;
	activeUserFilter: SanitizersFilter;
	departmentsList: string[];
	locationsList: string[];
	jobFunctionsList: string[];
	selectedUsers: string[];
	setSelectedUsersId: React.Dispatch<React.SetStateAction<string[]>>;
}

const SanitizerFilters: React.FC<Props> = ({
	filters,
	setFilters,
	setActiveUserFilter,
	setSanitizerSearchTerm,
	sanitizerSearchTerm,
	activeUserFilter,
	departmentsList,
	locationsList,
	jobFunctionsList,
	selectedUsers,
	setSelectedUsersId,
}) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const nameRef = React.useRef<HTMLInputElement>(null);

	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [updateFilterActive, setUpdateFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(filters.name ?? '');

	const [createUserFilter] = useMutation<SetUserSanitizerFilter, SetUserSanitizerFilterVariables>(SET_USER_SANITIZER_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserSanitizerFilter, RemoveUserSanitizerFilterVariables>(REMOVE_USER_SANITIZER_FILTER);

	const onFilterSave = async () => {
		setUpdateFilterActive(true);
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					departments: filters.departments ?? [],
					locations: filters.locations ?? [],
					jobFunctions: filters.jobFunctions ?? [],
					sanitizers: selectedUsers ?? [],
				},
			});
		} catch (e) {
			console.log(e);
		}
		setUpdateFilterActive(false);
	};

	const resetFilters = () => {
		setFilters({
			name: '',
			departments: [],
			locations: [],
			jobFunctions: [],
			sanitizers: [],
		});
		setActiveUserFilter({
			name: '',
			locations: [],
			departments: [],
			jobFunctions: [],
			sanitizers: [],
		});
		setSanitizerSearchTerm('');
		if (nameRef.current !== null) {
			nameRef.current.value = '';
		}
	};

	const postUserFilter = async (filterValues: SetUserSanitizerFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserSanitizerFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.sanitizerFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							caseFilters: [cachedRequest.currentUser?.sanitizerFilters, data.setUserSanitizerFilter.sanitizerFilters],
						},
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserSanitizerFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.removeUserSanitizerFilter === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetWebCurrentUser>({
						query: GET_CURRENT_USER,
					});

					if (cachedRequest === null || cachedRequest.currentUser?.sanitizerFilters === null) {
						return;
					}

					cache.writeQuery({
						query: GET_CURRENT_USER,
						data: {
							user: {
								...cachedRequest.currentUser,
								caseFilters: cachedRequest.currentUser?.sanitizerFilters.filter(s => s.key !== filterKey),
							},
						},
					});
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className="relative">
			{updateFilterActive && (
				<div className="h-full w-full">
					<Loading highOpacity />
				</div>
			)}
			<div>
				<label className="mb-1 block text-xs font-medium text-white">
					{t('common.name')}
					<div className="relative w-full">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input
							innerRef={nameRef}
							name="overview-search"
							className="pr-8 text-sm text-black lg:w-full"
							style={{ height: '36px', minWidth: '220px' }}
							defaultValue={sanitizerSearchTerm}
							onChange={e => setSanitizerSearchTerm(e.target.value)}
						/>
					</div>
				</label>

				<div className="mb-3">
					<label className="mb-1 block text-xs font-medium text-white">{t('common.quickFilter')}</label>
					<div className="flex flex-wrap">
						<Dropdown
							name="overview-quick-filters"
							onChange={e => {
								const findFilter = userContext.user?.sanitizerFilters.find(f => f.name === e.target.value);
								setActiveUserFilter(
									findFilter ?? {
										name: '',
										departments: [],
										locations: [],
										jobFunctions: [],
										sanitizers: [],
									},
								);
								setSelectedUsersId([]);
								setFilterSaveName(e.target.value);
								e.target.value !== ''
									? setFilters(filters => ({
											...filters,
											name: e.target.value,
									  }))
									: resetFilters();
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...(userContext.user?.sanitizerFilters.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? []),
							]}
							className="w-full"
							style={{ minWidth: '100%', height: 34 }}
							value={activeUserFilter.name}
						/>

						<div className="mt-3 flex w-full justify-between">
							<Button
								secondary={filters.name !== '' || typeof filters.name !== 'undefined'}
								disabled={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null}
								text={t('caseOverview.filters.deleteFilter')}
								onClick={() => setDeleteFilter(true)}
								className="px-5 py-2 text-xs"
							/>

							<Button
								secondary
								text={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? t('caseOverview.filters.saveFilter') : t('caseOverview.filters.updateFilter')}
								onClick={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
								className="px-5 py-2 text-xs"
							/>
						</div>
					</div>
				</div>

				<label className="mt-4 mb-1 block text-xs font-medium text-white">
					{t('common.location')}
					<Select
						placeholder={''}
						isClearable={true}
						isMulti={true}
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						noOptionsMessage={() => t('planner.noLocations')}
						className="react-select-custom text-xs"
						value={
							filters.locations?.map(l => ({
								value: l,
								label: l,
							})) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
							option: base => ({ ...base, fontSize: '0.75rem' }),
							noOptionsMessage: base => ({
								...base,
								fontSize: '0.75rem',
							}),
						}}
						onChange={values =>
							setFilters(() => ({
								...filters,
								locations: values?.map(v => v.value) ?? [],
							}))
						}
						options={locationsList.map(l => ({
							value: l,
							label: l,
						}))}
					/>
				</label>

				<label className="mt-4 mb-1 block text-xs font-medium text-white">
					{t('common.department')}
					<Select
						placeholder={''}
						isClearable={true}
						isMulti={true}
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						noOptionsMessage={() => t('planner.noDepartments')}
						className="react-select-custom text-xs"
						value={
							filters.departments?.map(d => ({
								value: d,
								label: d,
							})) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
							option: base => ({ ...base, fontSize: '0.75rem' }),
							noOptionsMessage: base => ({
								...base,
								fontSize: '0.75rem',
							}),
						}}
						onChange={values =>
							setFilters(() => ({
								...filters,
								departments: values?.map(v => v.value) ?? [],
							}))
						}
						options={departmentsList.map(d => ({
							value: d,
							label: d,
						}))}
					/>
				</label>

				<label className="mt-4 mb-1 block text-xs font-medium text-white">
					{t('common.jobFunction')}
					<Select
						placeholder={''}
						isClearable={true}
						isMulti={true}
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						noOptionsMessage={() => t('planner.noJobfunctions')}
						className="react-select-custom text-xs"
						value={
							filters.jobFunctions?.map(j => ({
								value: j,
								label: j,
							})) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
							option: base => ({ ...base, fontSize: '0.75rem' }),
							noOptionsMessage: base => ({
								...base,
								fontSize: '0.75rem',
							}),
						}}
						onChange={values =>
							setFilters(() => ({
								...filters,
								jobFunctions: values?.map(v => v.value) ?? [],
							}))
						}
						options={jobFunctionsList.map(j => ({
							value: j,
							label: j,
						}))}
					/>
				</label>

				<div className="flex justify-between">
					<Button secondary text={t('common.resetFilter')} className="px-5 py-2 text-xs" onClick={resetFilters} />
				</div>
			</div>

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{filters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(filters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default SanitizerFilters;
