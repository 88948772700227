import React from 'react';
import { faCheck, faFileExcel, faFilePdf, faFileWord } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { GetCaseESDHFiles, GetSingleCase_case } from '../../../GraphQL';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import TextButton from '@ssg/common/Components/TextButton';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

const GET_ESDH_FILES = loader('src/GraphQL/Cases/GetCaseESDHFiles.gql');

interface Props {
	caseData: GetSingleCase_case;
}

interface Metadata {
	key: string;
	value: string;
}

const ReportsTab: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const { t } = useTranslation();

	const { data } = useQuery<GetCaseESDHFiles>(GET_ESDH_FILES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			id: caseData.id,
		},
		skip: caseData.id === '',
	});

	// Can be removed when all files have proper metadata
	const isReport = (name: string): boolean => {
		return name.startsWith('Rapporter');
	};
	const isReportType = (metadata: Array<Metadata>): boolean => {
		return metadata.some(o => o.key === 'DokumentType' && o.value === 'Rapport');
	};

	const isExtension = (extension: string) => {
		if (extension === 'pdf') {
			return faFilePdf;
		} else if (extension === 'xls') {
			return faFileExcel;
		} else if (extension === 'docx') {
			return faFileWord;
		}
	};

	const reports = React.useMemo(() => data?.case.documents.filter(f => isReport(f.name) || isReportType(f.metadata)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [data]);

	return (
		<table className="text-left text-xs">
			<thead>
				<tr>
					<th className="w-10"></th>
					<th>{t('common.name')}</th>
					<th>{t('common.created')}</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{reports.map((f, i) => (
					<tr key={i} className="text-xs even:bg-gray-100">
						<td className="flex w-10 justify-center px-1 py-2">{i === 0 && caseData.reportSent && <FontAwesomeIcon icon={faCheck} size="lg" className="text-green" />}</td>
						<td className="py-2">
							<TextButton link icon={isExtension(f.extension)} text={f.name} onClick={() => window.open(f.url, '_blank')} />
						</td>
						<td className="flex flex-row py-2">
							<p className="text-sm">{dateToDateTimeString(f.created)}</p>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default ReportsTab;
