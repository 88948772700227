import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const UpdateDebitorSchema = yup.object().shape({
	company: yup.string().required(),
	address: yup.string().required(),
	address2: yup.string(),
	zip: yup.string().required(),
	city: yup.string().required(),
});
