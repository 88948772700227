import { useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import React from 'react';
import Select from 'react-select';
import { GetMovableStatusOptions } from '../../GraphQL';

const GET_MOVABLE_STATUS_OPTIONS = loader('src/GraphQL/Movables/GetMovableStatusOptions.gql');

interface Props {
	value: string;
	onSelect(option: SelectOption): void;
}

const MovableStatusOptions: React.FC<Props> = ({ value, onSelect }) => {
	const { data } = useQuery<GetMovableStatusOptions>(GET_MOVABLE_STATUS_OPTIONS);

	const locationOptions = React.useMemo(() => data?.movableStatusOptions.map(m => ({ value: m, label: m })) ?? [], [data?.movableStatusOptions]);

	return (
		<Select
			placeholder=""
			isClearable
			closeMenuOnSelect={false}
			menuPortalTarget={document.body}
			className="react-select-custom w-40 text-xs"
			value={{
				label: locationOptions.find(l => l.value === value)?.label ?? '',
				value,
			}}
			styles={{
				menuPortal: base => ({ ...base, zIndex: 6667 }),
				option: base => ({ ...base, fontSize: '0.75rem' }),
				noOptionsMessage: base => ({ ...base, fontSize: '0.75rem' }),
				control: base => ({ ...base, height: '36px' }),
			}}
			onChange={option => {
				onSelect({
					label: option?.label ?? '',
					value: option?.value ?? '',
				});
			}}
			options={locationOptions}
		/>
	);
};

export default MovableStatusOptions;
