import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const caseViewDebitorSchema = yup.object().shape(
	{
		requisitionerInsuranceId: yup.string().required(),

		shippingAddressReference: yup.string(),

		insuranceInvoiceRoad: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceZip', 'insuranceInvoiceHouseNumber'], {
			is: (city: string, zip: string, houseNo: string) => city !== '' || zip !== '' || houseNo !== '',
			then: yup.string().required('error.addressMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceHouseNumber: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceZip', 'insuranceInvoiceRoad'], {
			is: (city: string, zip: string, road: string) => city !== '' || zip !== '' || road !== '',
			then: yup.string().required('error.addressMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceFloor: yup.string(),

		insuranceInvoiceAddress2: yup.string(),

		insuranceInvoiceZip: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'], {
			is: (city: string, road: string, houseNo: string) => city !== '' || road !== '' || houseNo !== '',
			then: yup.string().required('error.zipMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceCity: yup.string().when(['insuranceInvoiceZip', 'insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'], {
			is: (zip: string, road: string, houseNo: string) => zip !== '' || road !== '' || houseNo !== '',
			then: yup.string().required('error.cityMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceCountry: yup.string(),
	},
	[
		['insuranceInvoiceRoad', 'insuranceInvoiceZip'],
		['insuranceInvoiceCity', 'insuranceInvoiceZip'],
		['insuranceInvoiceRoad', 'insuranceInvoiceCity'],
		['insuranceInvoiceCity', 'insuranceInvoiceHouseNumber'],
		['insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'],
		['insuranceInvoiceZip', 'insuranceInvoiceHouseNumber'],
	],
);
