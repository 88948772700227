import React from 'react';
import classNames from 'classnames';
import { GetUsersWithCalendarsQuery } from '@ssg/common/GraphQL/indexV2';

interface Props {
	employee: GetUsersWithCalendarsQuery['usersWithCalendars'][number];
	onClick(): void;
	active: boolean;
	bgColor: string;
}

const EmployeeCard: React.FC<Props> = ({ employee, onClick, active, bgColor }): React.ReactElement => (
	<div
		className={classNames('border-1 rounded-default mb-1 flex w-full cursor-pointer flex-row bg-white p-1 text-sm', active && bgColor, {
			'text-white': active,
		})}
		onClick={onClick}
	>
		<div className="mr-2 flex w-10 items-center justify-center">
			<div className="border-1 flex h-10 w-10 items-center justify-center rounded-full border-white bg-gray-900 text-lg text-white">{employee.name?.substr(0, 1).toUpperCase()}</div>
		</div>
		<div className="overflow-hidden">
			<p className="truncate font-medium">{employee.name}</p>
			<p className="truncate text-xs">
				{employee.department}
			</p>

			<p className="truncate text-xs">{employee.jobFunction}</p>

		</div>
	</div>
);
export default EmployeeCard;
