import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const CustomTooltipPeriod: React.FC<TooltipProps<ValueType, NameType>> = ({ payload, label }) => {
	const { t } = useTranslation();

	if (payload && payload.length > 0) {
		return (
			<div
				className="custom-tooltip ml-2 bg-white"
				style={{
					border: '1px solid rgb(224,224,224)',
				}}
			>
				<div className="m-3">
					<p className="label">{label}</p>
					<p className="label" style={{ color: '#BA5BEF' }}>{`${t('common.days')} : ${payload[0].value}`}</p>
					<p className="desc">{`${t('common.period')} : ${payload[0].payload.tickText}`}</p>
				</div>
			</div>
		);
	}

	return null;
};

export default CustomTooltipPeriod;
