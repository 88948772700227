import React from 'react';
import { useMutation } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import { formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetJobs, GetJobsVariables, GetJobs_jobs, JobType, UpdateJob, UpdateJobVariables } from '../../GraphQL';

const GET_JOBS = loader('src/GraphQL/Jobs/GetJobs.gql');
const UPDATE_JOB = loader('src/GraphQL/Jobs/UpdateJob.gql');

interface Props {
	jobs: GetJobs_jobs[];
	queryProps: QueryProps;
	loading?: boolean;
	noDataText: string;
}

interface QueryProps {
	type?: JobType;
	completed?: boolean;
	personalOnly?: boolean;
	location?: string;
	userId?: string;
}

const JobsTable: React.FC<Props> = ({ jobs, queryProps, loading, noDataText }) => {
	const { t } = useTranslation();

	const [updateJob] = useMutation<UpdateJob, UpdateJobVariables>(UPDATE_JOB);

	return (
		<Table
			loading={loading}
			data={jobs}
			keySelector={j => j.id}
			noDataFoundText={noDataText}
			columns={[
				{
					label: 'common.description',
					selectFn: j => j.description,
					sortFn: (a, b) => a.description.localeCompare(b.description),
				},
				{
					label: 'common.deadline',
					selectFn: j => formatTimestamp(new Date(j.deadline)),
					sortFn: (a, b) => a.deadline.localeCompare(b.deadline),
				},
				{
					label: 'jobs.assignedTo',
					selectFn: j => j.assignedTo?.name ?? '',
					sortFn: (a, b) => (a.assignedTo?.name ?? '').localeCompare(b.assignedTo?.name ?? ''),
				},
				{
					label: 'common.location',
					selectFn: j => j.location?.name ?? '',
					sortFn: (a, b) => (a.location?.name ?? '').localeCompare(b.location?.name ?? ''),
				},
				{
					label: `${t('common.case')} ~ ${t('common.movable')}`,
					selectFn: j => (
						<div>
							{j.case?.erpNo ?? ''}
							{j.movable !== null && (
								<>
									{j.case?.erpNo && <br />}
									{`${j.movable.description}, ${j.movable.status}, ${j.movable.placement.name}`}
								</>
							)}
						</div>
					),
					sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
				},
				{
					label: 'common.complete',
					selectFn: j => (
						<Button
							primary
							className="my-1"
							icon={faCheck}
							onClick={async () => {
								if (window.confirm(t('movable.confirmCompleteJobMessage'))) {
									await updateJob({
										variables: {
											id: j.id,
											completed: true,
										},
										update: (cache, { data }): void => {
											console.log(data);
											if (typeof data === 'undefined' || data === null) {
												return;
											}
											// Update cache for personal only query
											const cachedRequestPersonalOnly = cache.readQuery<GetJobs, GetJobsVariables>({
												query: GET_JOBS,
												variables: queryProps,
											});
											if (cachedRequestPersonalOnly !== null) {
												cache.writeQuery<GetJobs, GetJobsVariables>({
													query: GET_JOBS,
													variables: queryProps,
													data: {
														jobs: cachedRequestPersonalOnly.jobs.filter(cj => cj.id !== j.id),
													},
												});
											}
											// Update cache for "all" query
											const cachedRequestAll = cache.readQuery<GetJobs, GetJobsVariables>({
												query: GET_JOBS,
												variables: queryProps,
											});
											if (cachedRequestAll !== null && cachedRequestAll.jobs !== null) {
												cache.writeQuery({
													query: GET_JOBS,
													variables: queryProps,
													data: {
														jobs: cachedRequestAll.jobs.filter(cj => cj.id !== data.updateJob?.id),
													},
												});
											}
										},
									});
								}
							}}
						/>
					),
				},
			]}
		/>
	);
};

export default JobsTable;
