import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { DateTime } from 'luxon';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	FileActionType,
	CatalogGlobalType,
	GetWebCatalogCustomers_catalogCustomers,
	TimedMessageInput,
	GetCatalog,
	GetCatalogsForDropdown,
	GetCatalogsForDropdownVariables,
	GetCatalog_catalog_timedMessages,
	GetTimedMessagesVariables,
	GetCatalogVariables,
} from '../../../GraphQL';
import { CreateTimedMessage, CreateTimedMessageVariables, UpdateTimedMessage, UpdateTimedMessageVariables, GetTimedMessages } from '../../../GraphQL';
import { TimedMessageSchema } from '../../../Schemas/TimedMessageSchema';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { TimedMessageFilter } from './TimedMessages';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Select, { ValueType, ControlProps } from 'react-select';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import UserContext from '../../../UserContext';
import Checkbox from '@ssg/common/Components/Checkbox';
import Dropdown from '@ssg/common/Components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const CREATE_TIMED_MESSAGE = loader('src/GraphQL/TimedMessages/CreateTimedMessage.gql');
const UPDATE_TIMED_MESSAGE = loader('src/GraphQL/TimedMessages/UpdateTimedMessage.gql');
const GET_TIMED_MESSAGES = loader('src/GraphQL/TimedMessages/GetTimedMessages.gql');
const GET_CATALOGS_FOR_DROPDOWN = loader('src/GraphQL/Catalogs/GetCatalogsForDropdown.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetCatalog_catalog_timedMessages;
	changedBy: string;
	activeFilters: TimedMessageFilter;
	catalogId: string | undefined;
	catalogCustomers?: GetWebCatalogCustomers_catalogCustomers[];
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
}

const TimedMessageModal: React.FC<Props> = ({ open, close, edit = false, data, changedBy, activeFilters, catalogId, catalogCustomers, catalogCustomer }) => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);
	const isExternal = userContext.user?.external;

	const [selectedCustomer, setSelectedCustomer] = React.useState<string | undefined>(isExternal ? catalogCustomer?.id ?? undefined : data?.customer.id);
	const { data: catalogList, loading: catalogListLoading } = useQuery<GetCatalogsForDropdown, GetCatalogsForDropdownVariables>(GET_CATALOGS_FOR_DROPDOWN, {
		fetchPolicy: 'cache-and-network',
		variables: {
			thisCustomerOnly: isExternal,
			customerIds: [selectedCustomer ?? ''],
		},
		skip: typeof selectedCustomer === 'undefined' || typeof catalogId !== 'undefined',
	});

	const [createTimedMessage, { loading: loadingCreate }] = useMutation<CreateTimedMessage, CreateTimedMessageVariables>(CREATE_TIMED_MESSAGE);
	const [updateTimedMessage, { loading: loadingUpdate }] = useMutation<UpdateTimedMessage, UpdateTimedMessageVariables>(UPDATE_TIMED_MESSAGE);

	const [selectedCats, setSelectedCats] = React.useState<string[]>(catalogId ? [...(data?.catalogIds ?? []).filter(c => c !== catalogId), ...[catalogId]] : data?.catalogIds ?? []);

	const [globalType, setGlobalType] = React.useState<CatalogGlobalType>(data ? data.globalType : !catalogId ? CatalogGlobalType.CUSTOMER_GLOBAL : CatalogGlobalType.NOT_GLOBAL);

	const [searchText, setSearchText] = React.useState('');

	const { handleSubmit, control, setValue, errors, register } = useForm<TimedMessageInput>({
		resolver: yupResolver(TimedMessageSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			customer: catalogCustomer?.id,
		},
	});

	const [startDate, setStartDate] = React.useState<DateTime>(data?.startTime ? DateTime.fromISO(data.startTime) : DateTime.now);
	const [endDate, setEndDate] = React.useState<DateTime>(data?.endTime ? DateTime.fromISO(data.endTime) : DateTime.now);

	const onSubmit = async (formData: TimedMessageInput) => {
		if (edit) {
			await updateTimedMessage({
				variables: {
					id: data?.id ?? '',
					customer: formData.customer,
					changedBy: changedBy,
					startTime: startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC().toISO(),
					endTime: endDate
						.set({
							hour: 23,
							minute: 59,
							second: 59,
							millisecond: 0,
						})
						.toUTC()
						.toISO(),
					message: formData.message,
					global: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL,
					active: !catalogId ? formData.active : data?.active ?? true,
					globalType: formData.globalType,
					catalogs: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? [] : selectedCats,
					catalogsBefore: data?.catalogIds ?? [],
					action: FileActionType.UPDATE,
				},
			});
		} else {
			await createTimedMessage({
				variables: {
					timedMessage: {
						customer: formData.customer,
						changedBy: changedBy,
						startTime: startDate
							.set({
								hour: 0,
								minute: 0,
								second: 0,
								millisecond: 0,
							})
							.toISO(),
						endTime: endDate
							.set({
								hour: 23,
								minute: 59,
								second: 59,
								millisecond: 0,
							})
							.toISO(),
						message: formData.message,
						global: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL,
						active: !catalogId ? formData.active : true,
						globalType: formData.globalType,
						catalogs: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? [] : selectedCats,
					},
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedTimedMessageRequest = cache.readQuery<GetTimedMessages, GetTimedMessagesVariables>({
						query: GET_TIMED_MESSAGES,
						variables: {
							fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
							toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
							thisCustomerOnly: isExternal,
						},
					});

					if (cachedTimedMessageRequest !== null && cachedTimedMessageRequest.timedMessages !== null) {
						cache.writeQuery<GetTimedMessages, GetTimedMessagesVariables>({
							query: GET_TIMED_MESSAGES,
							variables: {
								fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
								toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
								thisCustomerOnly: isExternal,
							},
							data: {
								timedMessages: [...cachedTimedMessageRequest.timedMessages, cacheData.createTimedMessage],
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								timedMessages: [...cachedCatalogRequest.catalog.timedMessages, cacheData.createTimedMessage],
							},
						},
					});
				},
			});
		}
		close();
	};

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);

	const [searchCustomerText, setSearchCustomerText] = React.useState<string>('');

	React.useEffect(() => {
		setCustomers((catalogCustomers ?? []).filter(ct => ct.name.toLowerCase().includes(searchCustomerText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, searchCustomerText]);

	const cats = React.useMemo(
		() =>
			catalogList?.catalogs
				.filter(c => c.customer.id === selectedCustomer)
				.map(
					(d): SelectOption => ({
						value: d.id ?? '',
						label: d.propertyNumber ?? '',
					}),
				) ?? [],
		[catalogList?.catalogs, selectedCustomer],
	);

	const CatalogHandler = (value: ValueType<SelectOption, true>): void => {
		if (value) {
			setSelectedCats(value.map(val => val.value));
		} else {
			setSelectedCats([]);
		}
		setValue('catalogs', value, { shouldValidate: true });
	};

	const selectedCatalogs = React.useMemo(() => {
		return cats.filter(c => selectedCats.includes(c.value));
	}, [cats, selectedCats]);

	React.useEffect(() => console.log(selectedCatalogs), [selectedCatalogs]);

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: React.CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: React.CSSProperties, state: ControlProps<SelectOption, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	React.useEffect(() => {
		if (edit) {
			setValue('customer', data?.customer.id);
		} else {
			setValue('customer', catalogCustomer?.id);
		}
	}, [setValue, catalogCustomer, edit, data]);

	return (
		<Modal
			title={(edit && 'catalog.timedMessage.update') || 'catalog.timedMessage.create'}
			size={ModalSize.SMALL}
			visible={open}
			close={close}
			body={
				<form autoComplete="nope" onSubmit={handleSubmit(onSubmit)}>
					<SearchableSelect
						key="userLabel"
						control={control}
						name="customer"
						title="catalog.customer"
						required
						readOnly={catalogCustomer !== undefined}
						options={customers}
						searchFn={searchText => setSearchCustomerText(searchText)}
						onSelect={value => {
							setValue('customer', value, {
								shouldValidate: true,
							});
							setSelectedCustomer(value);
						}}
						onBlur={() => undefined}
						minInputLength={-1}
						allowEmpty
						className={catalogCustomer !== undefined ? 'hidden' : undefined}
						isLoading={false}
						errorMessage={errors?.customer?.message ?? ''}
						initialSelection={
							typeof data === 'undefined'
								? undefined
								: {
									value: data.customer.id ?? '',
									label: data.customer.name ?? '',
								}
						}
					/>
					{catalogCustomer !== undefined && (
						<>
							<h2>{catalogCustomer.name}</h2>
						</>
					)}

					<Datepicker
						title="catalog.timedMessage.startTime"
						name="startTime"
						required
						innerRef={register}
						errorMessage={errors?.startTime?.message ?? ''}
						value={startDate.toISODate()}
						onChange={e => {
							setStartDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()));
							if (endDate < DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date())) {
								setEndDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()));
							}
						}}
					/>

					<Datepicker
						title="catalog.timedMessage.endTime"
						name="endTime"
						required
						innerRef={register}
						min={startDate.toISODate()}
						errorMessage={errors?.endTime?.message ?? ''}
						value={endDate.toISODate()}
						onChange={e => setEndDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()))}
					/>

					<Input title="common.message" name="message" required defaultValue={data?.message} innerRef={register} errorMessage={errors?.message?.message ?? ''} />

					{!catalogId && (
						<Checkbox title="common.active" name="active" className="w-full pt-3 lg:w-full" innerRef={register} defaultChecked={data?.active} errorMessage={errors.active?.message} />
					)}

					<Dropdown
						title="catalog.attached"
						name="globalType"
						innerRef={register}
						required
						value={globalType}
						disableSortLabelAlphabetically
						onChange={e => {
							setGlobalType(e.target.value as CatalogGlobalType);
							catalogId && setSelectedCats([catalogId]);
						}}
						data={[
							{
								value: CatalogGlobalType.CATALOGS_GLOBAL,
								label: typeof catalogId === 'undefined' ? 'catalog.catalog' : 'catalog.thisCatalog',
							},
							{
								value: CatalogGlobalType.CUSTOMER_GLOBAL,
								label: 'catalog.customer',
							},
						]}
					/>

					<div>
						{typeof catalogId === 'undefined' && globalType === CatalogGlobalType.CATALOGS_GLOBAL && (
							<div>
								{!catalogId && <FormFieldHeader title="catalog.propertyNumbers" />}
								<Controller
									name="catalogs"
									control={control}
									render={() => (
										<Select
											isMulti
											options={cats?.filter(d => d.label.toLowerCase().includes(searchText.toLowerCase())) ?? []}
											closeMenuOnSelect={false}
											noOptionsMessage={inputValue =>
												inputValue.inputValue === '' ? t('catalog.timedMessage.noCatalogs') : `${t('catalog.timedMessage.noCatalogWithName')} '${inputValue.inputValue}'`
											}
											placeholder={<div>{t('catalog.timedMessage.searchCatalogs')}</div>}
											styles={customStyles}
											inputRef={register}
											onChange={val => CatalogHandler(val)}
											onInputChange={val => setSearchText(val)}
											value={selectedCatalogs}
											className={catalogId ? 'invisible m-0 h-0 p-0' : 'border-1 rounded-default w-full border-gray-600 lg:w-full'}
										/>
									)}
								/>
								{catalogListLoading && (
									<p className="text-blue text-xs">
										<FontAwesomeIcon icon={faSpinner} className="mr-1 animate-spin" />
										{t('catalog.loadingPropertyNumbers')}
									</p>
								)}
								<FormErrorText text={typeof errors.catalogs !== 'undefined' ? (errors.catalogs as unknown as FieldError).message ?? '' : ''} />
							</div>
						)}
					</div>

					<Button
						success
						submit
						text={edit ? 'catalog.timedMessage.update' : 'catalog.timedMessage.create'}
						className="mt-4"
						loading={loadingCreate || loadingUpdate}
						disabled={loadingCreate || loadingUpdate}
					/>
				</form>
			}
		/>
	);
};

export default TimedMessageModal;
