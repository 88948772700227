import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faCheck, faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
	AddCatalogToCraftsman,
	AddCatalogToCraftsmanVariables,
	GetCatalog,
	GetCatalogCraftsmenForAddExcisting,
	GetCatalogCraftsmenForAddExcistingVariables,
	GetCatalogCraftsmenForAddExcisting_catalogCraftsmen,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalogVariables,
} from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import Button from '@ssg/common/Components/Button';

const ADD_CATALOG_TO_CRAFTSMAN = loader('src/GraphQL/CatalogCraftsmen/AddCatalogToCraftsman.gql');
const GET_CATALOG_CRAFTSMEN_FOR_ADD_EXCISTING = loader('src/GraphQL/CatalogCraftsmen/GetCatalogCraftsmenForAddExcisting.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

interface Props {
	changedBy: string;
	open: boolean;
	close: () => void;
	catalogId: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers;
	catalogCraftsmenId: string[];
}

const CraftsmanAddExisting: React.FC<Props> = ({ changedBy, catalogId, open, close, catalogCustomer, catalogCraftsmenId }) => {
	const { t } = useTranslation();

	const [addCatalogToCraftsman, { loading }] = useMutation<AddCatalogToCraftsman, AddCatalogToCraftsmanVariables>(ADD_CATALOG_TO_CRAFTSMAN);
	const [idToAdd, setIdToAdd] = React.useState('');

	const { loading: loadingCraftsmenList, data: craftsmenList } = useQuery<GetCatalogCraftsmenForAddExcisting, GetCatalogCraftsmenForAddExcistingVariables>(GET_CATALOG_CRAFTSMEN_FOR_ADD_EXCISTING, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			customerIds: [catalogCustomer.id],
			notGlobalOnly: true,
		},
	});

	const addCraftsmanToCatalog = async (craftsman: GetCatalogCraftsmenForAddExcisting_catalogCraftsmen) => {
		setIdToAdd(craftsman.id);
		try {
			await addCatalogToCraftsman({
				variables: {
					craftsmanId: craftsman.id,
					catalogId: catalogId,
					changedBy,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedRequest === null || cachedRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedRequest.catalog,
								craftsmen: [...cachedRequest.catalog.craftsmen, cacheData.addCatalogToCraftsman],
							},
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
		setIdToAdd('');
	};

	return (
		<Modal
			title="catalog.craftsman.addExistingCraftsman"
			size={ModalSize.XLARGE}
			visible={open}
			close={close}
			body={
				<div className="text-blue">
					<Table
						data={craftsmenList?.catalogCraftsmen ?? []}
						loading={loadingCraftsmenList && !craftsmenList?.catalogCraftsmen}
						columns={[
							{
								label: t('common.type'),
								selectFn: c => <p>{c.type ?? ''}</p>,
								sortFn: (a, b) => (a.type ?? '').localeCompare(b.type ?? ''),
							},
							{
								label: t('catalog.contact.contactName'),
								selectFn: c => <p>{c.contactName}</p>,
								sortFn: (a, b) => a.contactName.localeCompare(b.contactName),
							},
							{
								label: t('catalog.contact.email'),
								numeric: true,
								selectFn: c => <p>{c.email}</p>,
								sortFn: (a, b) => (a.email ?? '').localeCompare(b.email ?? ''),
							},
							{
								label: t('common.phone'),
								noTruncate: true,
								numeric: true,
								selectFn: c => <div>{c.informations && c.informations.map(inf => inf && <p className="py-1">{`${formatPhoneNumberIntl(inf.phoneNumber)} - ${inf.remark}`}</p>)}</div>,
							},
							{
								label: t('catalog.craftsman.priority'),
								noTruncate: true,
								selectFn: c => <p>{c.priority}</p>,
								sortFn: (a, b) => (a.priority ?? '').localeCompare(b.priority ?? ''),
							},
							{
								label: t('common.add'),
								classNameTh: 'text-right',
								selectFn: c => {
									if (catalogCraftsmenId.includes(c.id)) {
										return (
											<div className="flex content-start justify-end text-right">
												<Button>
													<FontAwesomeIcon icon={faCheck} size="lg" />
												</Button>
											</div>
										);
									} else {
										return (
											<div className="flex content-start justify-end text-right">
												<Button onClick={() => addCraftsmanToCatalog(c)} disabled={loading}>
													<FontAwesomeIcon
														className={idToAdd === c.id && loading ? 'animate-spin' : undefined}
														icon={idToAdd === c.id && loading ? faSpinner : faPlus}
														size="lg"
													/>
												</Button>
											</div>
										);
									}
								},
							},
						]}
						keySelector={c => c.id}
					/>
				</div>
			}
		/>
	);
};

export default CraftsmanAddExisting;
