import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const AdviserSchema = yup.object().shape(
	{
		name: yup.string().when(['phone', 'email'], {
			is: (phone: string | undefined, email: string | undefined) => (phone ?? '') !== '' || (email ?? '') !== '',
			then: yup.string().required('error.adviserMissingName'),
			otherwise: yup.string(),
		}),

		phone: yup.string().when(['name', 'email'], {
			is: (name: string | undefined, email: string | undefined) => (name ?? '') !== '' || (email ?? '') !== '',
			then: yup.string().required('error.adviserMissingPhone'),
			otherwise: yup.string(),
		}),

		email: yup.string().when(['name', 'phone', 'noEmail'], {
			is: (name: string | undefined, phone: string | undefined, noEmail: boolean) => ((name ?? '') !== '' || (phone ?? '') !== '') && !noEmail,
			then: yup.string().email().required('error.adviserMissingEmail'),
			otherwise: yup.string().email(),
		}),

		noEmail: yup.boolean(),

		companyName: yup.string(),

		companyPhone: yup.string(),
	},
	[
		['phone', 'email'],
		['name', 'email'],
		['name', 'phone'],
	],
);
