import React, { useEffect, useState } from 'react';
import { UseFormMethods, useWatch } from 'react-hook-form';
import { CasePriority, GetWebCaseAdminData_damageCauses, GetWebCase_case, GetCauseQuestions, GetCauseQuestionsVariables } from '../../GraphQL';
import Box from '../../Components/Layout/Box';
import FormFieldHeader from '../../Components/Forms/FormFieldHeader';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import Input from '@ssg/common/Components/Input';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

interface Props {
	caseData: GetWebCase_case | undefined;
	causeList: GetWebCaseAdminData_damageCauses[];
	register: UseFormMethods['register'];
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
}

const GET_CAUSE_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetCauseQuestions.gql');

const VisitationBox: React.FC<Props> = ({ caseData, causeList, register, control, setValue }): React.ReactElement => {
	const [showHelpText, setShowHelpText] = React.useState(false);

	const calledBack = useWatch({
		control,
		name: 'VisitationBox.calledBack',
		defaultValue: caseData?.visitation.calledBack.value,
	});

	const [isUrgent] = useState<boolean>(caseData ? caseData.visitation.urgent : false);

	const selectedDamageCause = useWatch({
		control,
		name: 'DamageBox.damageCause',
		defaultValue: '',
	});

	useEffect(() => {
		const cause = causeList?.find(c => c.id === selectedDamageCause);

		setValue('VisitationBox.visitationImidiateAttention', cause ? cause.urgent : false);
	}, [selectedDamageCause, causeList, setValue]);

	const { data, loading } = useQuery<GetCauseQuestions, GetCauseQuestionsVariables>(GET_CAUSE_QUESTIONS, {
		variables: {
			damageCause: selectedDamageCause,
		},
		skip: selectedDamageCause.length === 0,
	});

	return (
		<Box form title="case.visitation" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText}>
			{showHelpText && <FormHelpText text="case.helpText.visitation" />}
			<hr />
			<div className="flex w-full flex-wrap lg:w-3/4">
				<div className="w-full lg:w-1/2 lg:pr-8">
					<FormFieldHeader title="case.call" />

					<Checkbox
						name="VisitationBox.visitationImidiateAttention"
						title="case.visitationImidiateAttention"
						innerRef={register}
						helpText="case.helpText.visitationImidiateAttention"
						showHelpText={showHelpText}
						defaultChecked={caseData?.visitation.urgent}
						checked={isUrgent}
					/>

					<FormFieldHeader title="case.calledBack" />

					<Checkbox name="VisitationBox.calledBack" title="case.calledBack" innerRef={register} defaultChecked={caseData?.visitation.calledBack.value} />

					<Input
						name="VisitationBox.calledBackDescription"
						title="case.hyphenDescription"
						disabled={!calledBack}
						innerRef={register}
						defaultValue={caseData?.visitation.calledBack.comment ?? ''}
					/>
				</div>
				<div className="lg:ml-8">
					<FormFieldHeader title="case.priority" />

					<div className="my-2 flex flex-col space-y-4">
						<Checkbox
							name="VisitationBox.visitationSocialIssues"
							title="case.SOCIAL_IMPACT"
							innerRef={register}
							helpText="case.helpText.visitationSocialIssues"
							showHelpText={showHelpText}
							defaultChecked={caseData?.visitation.priorities.includes(CasePriority.SOCIAL_IMPACT)}
						/>

						<Checkbox
							name="VisitationBox.visitationResidentialArea"
							title="case.RESIDENTIAL_AREA"
							innerRef={register}
							helpText="case.helpText.visitationResidentialArea"
							showHelpText={showHelpText}
							defaultChecked={caseData?.visitation.priorities.includes(CasePriority.RESIDENTIAL_AREA)}
						/>

						<Checkbox
							name="VisitationBox.visitationLossOfBusiness"
							title="case.LOSS_OF_BUSINESS_EARNING_CAPACITY"
							innerRef={register}
							helpText="case.helpText.visitationLossOfBusiness"
							showHelpText={showHelpText}
							defaultChecked={caseData?.visitation.priorities.includes(CasePriority.LOSS_OF_BUSINESS_EARNING_CAPACITY)}
						/>

						<Checkbox
							name="VisitationBox.isMoistureReportRequired"
							title="case.isMoistureReportRequired"
							innerRef={register}
							helpText="case.isMoistureReportRequired"
							showHelpText={showHelpText}
							defaultChecked={caseData?.visitation.isMoistureReportRequired ?? false}
						/>
					</div>
				</div>
			</div>
			{typeof data?.causeQuestions !== 'undefined' && data?.causeQuestions?.length > 0 && !loading && (
				<div>
					<FormFieldHeader title="case.visitationQuestions" />

					<div className="w-full lg:w-1/2 lg:pr-8">
						{data?.causeQuestions.map((q, i) => {
							return (
								<div key={i}>
									<Input name={`VisitationBox.answers[${i}].question`} key={`${i}-q`} className="hidden" innerRef={register} value={q.id} readOnly />
									<Input name={`VisitationBox.answers[${i}].answer`} key={`${i}-a`} title={q.question} innerRef={register} />
								</div>
							);
						})}
					</div>
				</div>
			)}
		</Box>
	);
};

export default VisitationBox;
