import { stringToByteArray, toBase64 } from '@ssg/common/Helpers/inputFileHelper';
import ObjectID from 'bson-objectid';
import { FileInput } from './GraphQL';

export const getObjectId = (): string => {
	return new ObjectID().toHexString();
};

type FunctionRemoveTypename = <TValue>(value: TValue) => TValue;

export const removeTypename: FunctionRemoveTypename = <TValue>(value: TValue) => {
	if (value === null || value === undefined) {
		return value;
	} else if (Array.isArray(value)) {
		return value.map(v => removeTypename(v));
	} else if (typeof value === 'object') {
		const newObj: any = {};

		Object.entries(value).forEach(([key, v]) => {
			if (key !== '__typename') {
				newObj[key] = removeTypename(v);
			}
		});

		return newObj;
	}

	return value;
};

export const tokenizeStringWithQuotesBySpaces = (string: string): string[] => string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export type Modify<T, R> = Omit<T, keyof R> & R;

export const getSessionStorageOrDefault = (key: string): string | undefined => {
	const stored = sessionStorage.getItem(key);
	if (!stored) {
		return undefined;
	}
	return JSON.parse(stored);
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export const dateDiffInDays = (a: Date, b: Date): number => {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const isDatesBetweenDates = (startDate: string | Date | null, endDate: string | Date | null, checkDateStart: Date | undefined, checkDateEnd: Date | undefined): boolean => {
	if (startDate === null || endDate === null || typeof checkDateStart === 'undefined' || typeof checkDateEnd === 'undefined') return false;

	const startDateFormat = new Date(startDate);
	const endDateFormat = new Date(endDate);

	return (
		(checkDateStart >= startDateFormat && checkDateStart <= endDateFormat) || // Date start overlaps with existing date
		(checkDateEnd >= startDateFormat && checkDateEnd <= endDateFormat) || // Date end overlaps with existing date
		(checkDateStart <= startDateFormat && checkDateEnd >= endDateFormat)
	); // Existing date is "inside" date start/end
};

export const isDateBetweenDates = (startDate: Date | string | null, endDate: Date | string | null, checkDate: Date | undefined): boolean | undefined => {
	if (startDate === null || endDate === null || typeof checkDate === 'undefined') return undefined;

	const startDateDateFormat = new Date(startDate);
	const endDateDateFormat = new Date(endDate);

	return checkDate >= startDateDateFormat && checkDate <= endDateDateFormat;
};

export const fileListToFileInputList = async (fileList: FileList): Promise<FileInput[]> => {
	const result: FileInput[] = [];

	for (let i = 0; i < fileList.length; i++) {
		const file = fileList[i];

		const fileAsString = await toBase64(file);

		const fileData = stringToByteArray(fileAsString);

		result.push({ fileData: fileData, filename: file.name });
	}

	return result;
};

function reviver(key: string, value: any) {
	if (typeof value !== 'string') return value;

	const valueAsDate = new Date(value);
	if (valueAsDate.toISOString() === value) return valueAsDate;

	return value;
}

export const parseJSONWithDates = (json: string): any => JSON.parse(json, reviver);

// Used for fetch-more in overviews
export const getCurrentOffset = (currentCount: number, fetcLimit: number): number => {
	const modulo = currentCount % fetcLimit;

	return currentCount - modulo;
};

const newFileName = (fileName: string, number: number): string => {
	const index = fileName.lastIndexOf('.');
	return fileName.substring(0, index) + number + fileName.substring(index);
};

export const appendNo = (fileName: string, fileNames: string[]): string => {
	let number = 1;
	while (true) {
		const fileNameWithNo = newFileName(fileName, number);
		if (!fileNames.includes(fileNameWithNo)) {
			return fileNameWithNo;
		}
		number++;
	}
};
