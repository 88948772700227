import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetWebCatalogCustomers_catalogCustomers, GetWebCatalogs, GetWebCatalogsVariables, Permissions } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';
import { formatDateForInputEU } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useHistory } from 'react-router';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Table from '@ssg/common/Components/Table';
import CatalogOverviewFilter from './CatalogOverviewFilter';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import UserContext from '../../../UserContext';
import CatalogsExport from './CatalogsExport';
import Box from '../../../Components/Layout/Box';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Button from '@ssg/common/Components/Button';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';

const GET_CATALOGS = loader('src/GraphQL/Catalogs/GetWebCatalogs.gql');

export interface CatalogFilter {
	debitors: string[] | null;
	smsService: boolean | null;
	verified: boolean | null;
	debitorVerified: boolean | null;
	noDebitor: boolean | null;
	customerIds: string[] | null;
}

interface Props {
	changedBy: string | undefined;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
}

const CatalogOverview: React.FC<Props> = ({ changedBy, catalogCustomers }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const canView = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VIEW]);
	const isExternalUser = userContext.user?.external;

	const [searchTerm, setSearchTerm] = useDebouncedState('', 200);

	const [activeFilters, setActiveFilters] = useStorageState<CatalogFilter>(window.sessionStorage, 'activeCatalogFilters', {
		debitors: null,
		smsService: null,
		verified: null,
		debitorVerified: null,
		noDebitor: null,
		customerIds: null,
	});

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const {
		loading: loadingCatalogs,
		data,
		fetchMore,
	} = useQuery<GetWebCatalogs, GetWebCatalogsVariables>(GET_CATALOGS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			debitors: activeFilters.debitors,
			smsService: activeFilters.smsService,
			verified: activeFilters.verified,
			debitorVerified: activeFilters.debitorVerified,
			noDebitor: activeFilters.noDebitor,
			customerIds: activeFilters.customerIds,
			thisCustomerOnly: isExternalUser,
			offset: 0,
			limit: 20,
			searchString: searchTerm.length === 0 ? null : searchTerm,
		},
	});

	const catalogs = React.useMemo(() => data?.catalogs ?? [], [data?.catalogs]);

	return (
		<Box full>
			<div className="flex">
				<div className="flex w-3/4 flex-row">
					<CatalogOverviewFilter setFilterTerm={setSearchTerm} onFilter={setActiveFilters} activeFilters={activeFilters} catalogCustomers={catalogCustomers} />
				</div>
				<div className="flex w-1/4 justify-end">
					<CatalogsExport activeFilters={activeFilters} searchTerm={searchTerm} isExternalUser={isExternalUser ?? true} />
				</div>
			</div>

			<GraphQLExtensionsElement
				name="catalogsTotalCount"
				render={value => <TotalCount totalCount={value as number | undefined | null} loading={loadingCatalogs} quantityText={t('common.quantity')} entityText={t('catalog.overviewTitle')} />}
			/>

			<Table
				data={catalogs ?? []}
				loading={loadingCatalogs && typeof data === 'undefined'}
				columns={[
					{
						label: 'catalog.propertyNumber',
						selectFn: c => <p>{c.propertyNumber} </p>,
						sortFn: (a, b) => a.propertyNumber.localeCompare(b.propertyNumber),
					},
					{
						label: 'catalog.customer',
						selectFn: c => <p>{c.customer.name} </p>,
						sortFn: (a, b) => a.customer.name.localeCompare(b.customer.name),
					},
					{
						label: 'common.debitor',
						selectFn: c => <p>{c.debitor ? `${c.debitor.debitorId} - ${c.debitor.company}${c.debitor.gln ? ` - ${c.debitor.gln}` : ''}` : ''}</p>,
						sortFn: (a, b) => (a.debitor?.company ?? '').localeCompare(b.debitor?.company ?? ''),
					},
					{
						label: 'catalog.smsService',
						selectFn: c => <p>{c.smsService ? t('common.yes') : t('common.no')}</p>,
						sortFn: (a, b) => (a.smsService ? 'yes' : 'no').localeCompare(b.smsService ? 'yes' : 'no'),
						align: 'CENTER',
					},
					{
						label: 'catalog.addresses',
						selectFn: c => (
							<div>
								{c.addresses.map((add, i) => (
									<p key={i}>{`${add?.addressLine} ${add?.zipCode} ${add?.city}, ${add?.startNumber}-${add?.endNumber} ${t(`catalog.address.${add?.houseNumbers}`)}`}</p>
								))}
							</div>
						),
					},
					{
						label: 'common.updatedAt',
						selectFn: c => <p>{`${formatDateForInputEU(new Date(c.updatedAt))} - ${c.changedBy}`}</p>,
						sortFn: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
					},
					{
						label: 'catalog.verified',
						selectFn: c => <p>{c.verified ? t('common.yes') : t('common.no')}</p>,
						sortFn: (a, b) => (a.verified ? 'yes' : 'no').localeCompare(b.verified ? 'yes' : 'no'),
						align: 'RIGHT',
					},
					{
						label: 'catalog.debitorVerified',
						selectFn: c => <p>{c.debitorVerified ? t('common.yes') : t('common.no')}</p>,
						sortFn: (a, b) => (a.debitorVerified ? 'yes' : 'no').localeCompare(b.debitorVerified ? 'yes' : 'no'),
						align: 'RIGHT',
					},
				]}
				keySelector={c => c.id}
				onRowClick={c => {
					if (canView) {
						history.push(`/catalog/${c.id}`, {
							changedBy: changedBy,
							catalogCustomer: c.customer.id,
						});
					}
				}}
				noDataFoundText={t('catalog.noCatalogsFound')}
			/>
			{typeof data !== 'undefined' && data?.catalogs.length % 20 === 0 && data.catalogs.length !== 0 && (
				<Button
					fullWidth
					text="common.getMore"
					secondary
					loading={fetchMoreLoading}
					className="mt-2"
					onClick={async () => {
						setFetchMoreLoading(true);
						await fetchMore({
							variables: {
								offset: data.catalogs.length,
							},
						});
						setFetchMoreLoading(false);
					}}
				/>
			)}
		</Box>
	);
};
export default CatalogOverview;
