import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { GetCatalogChanges, GetCatalogChangesVariables, GetCatalogChanges_catalog_changes_after, GetCatalogChanges_catalog_changes_before } from '../../../GraphQL';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import _ from 'lodash';
import CatalogDescriptionChangeLog from './CatalogDescriptionChangeLog';
import CatalogTimedMessageChangeLog from './CatalogTimedMessageChangeLog';
import CatalogContactsChangeLog from './CatalogContactsChangeLog';
import CatalogCraftsmenChangeLog from './CatalogCraftsmenChangeLog';
import CatalogFilesChangeLog from './CatalogFilesChangeLog';
import TextButton from '@ssg/common/Components/TextButton';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import Loading from '@ssg/common/Components/Loading';

const GET_CATALOG_CHANGES = loader('src/GraphQL/Catalogs/GetCatalogChanges.gql');
interface Props {
	catalogId: string;
}

const CatalogChangeLog: React.FC<Props> = ({ catalogId }) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = React.useState(false);

	const { data, loading } = useQuery<GetCatalogChanges, GetCatalogChangesVariables>(GET_CATALOG_CHANGES, {
		fetchPolicy: 'no-cache',
		variables: {
			id: catalogId,
		},
		skip: !showModal,
	});

	const sorted = React.useMemo(() => (data?.catalog.changes ?? []).slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [data]);

	return (
		<>
			<TextButton text="catalog.catalogChangesView" icon={faHistory} onClick={() => setShowModal(true)} />

			<Modal
				title="catalog.catalogChanges"
				size={ModalSize.MEDIUM}
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<>
						{loading ? (
							<div className="relative h-28 w-full">
								<Loading />
							</div>
						) : (
							<div className="text-blue space-y-4">
								{sorted &&
									sorted.map(c => (
										<div key={c.timestamp}>
											<strong>
												{dateToDateTimeString(c.timestamp)}
												&nbsp;{t('common.by')}&nbsp;
												{c.changedBy ? c.changedBy : c.user.name}
											</strong>

											<div className="mt-1">{renderChanges(t, c.before, c.after)}</div>
										</div>
									))}
							</div>
						)}
					</>
				}
			/>
		</>
	);
};
export default CatalogChangeLog;

function renderChanges(t: TFunction, before: GetCatalogChanges_catalog_changes_before, after: GetCatalogChanges_catalog_changes_after): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// Name was changed
	if (!(before.propertyNumber === null && after.propertyNumber === null) && before.propertyNumber !== after.propertyNumber) {
		const tValues = {
			before: before.propertyNumber ?? NO_VALUE,
			after: after.propertyNumber ?? NO_VALUE,
		};

		result.push(
			<div key="name" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.name" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// User was changed
	if (!(before.customer?.name === null && after.customer?.name === null) && before.customer?.name !== after.customer?.name) {
		const tValues = {
			before: before.customer?.name ?? NO_VALUE,
			after: after.customer?.name ?? NO_VALUE,
		};

		result.push(
			<div key="username" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.username" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Description was changed
	if (!(before.description === null && after.description === null) && before.description !== after.description) {
		const tValues = {
			before: before.description ?? NO_VALUE,
			after: after.description ?? NO_VALUE,
		};

		result.push(
			<div key="description" className="my-2">
				<CatalogDescriptionChangeLog before={tValues.before} after={tValues.after} />
			</div>,
		);
	}

	// ContactNumber was changed
	if (!(before.contactNumber === null && after.contactNumber === null) && before.contactNumber !== after.contactNumber) {
		const tValues = {
			before: before.contactNumber ?? NO_VALUE,
			after: after.contactNumber ?? NO_VALUE,
		};

		result.push(
			<div key="contactNumber" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.contactNumber" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// ContactHours was changed
	if (!(before.contactHours === null && after.contactHours === null) && before.contactHours !== after.contactHours) {
		const tValues = {
			before: before.contactHours ?? NO_VALUE,
			after: after.contactHours ?? NO_VALUE,
		};

		result.push(
			<div key="contactHours" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.contactHours" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// ContactHours was changed
	if (!(before.smsService === null && after.smsService === null) && before.smsService !== after.smsService) {
		const tValues = {
			before: before.smsService ? t('common.yes') : t('common.no') ?? NO_VALUE,
			after: after.smsService ? t('common.yes') : t('common.no') ?? NO_VALUE,
		};

		result.push(
			<div key="smsService" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.smsService" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Verify was changed
	if (!(before.verified === null && after.verified === null) && before.verified !== after.verified) {
		const tValues = {
			before: before.verified ? t('common.yes') : t('common.no') ?? NO_VALUE,
			after: after.verified ? t('common.yes') : t('common.no') ?? NO_VALUE,
		};

		result.push(
			<div key="verified" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.verified" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Debitor Verify was changed
	if (!(before.debitorVerified === null && after.debitorVerified === null) && before.debitorVerified !== after.debitorVerified) {
		const tValues = {
			before: before.debitorVerified ? t('common.yes') : t('common.no') ?? NO_VALUE,
			after: after.debitorVerified ? t('common.yes') : t('common.no') ?? NO_VALUE,
		};

		result.push(
			<div key="debitorVerified" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.debitorVerified" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Debitor was changed
	if (!(before.debitor === null && after.debitor === null) && before.debitor !== after.debitor) {
		const tValues = {
			before: before.debitor ? `${before.debitor?.debitorId} - ${before.debitor?.company}` : NO_VALUE,
			after: after.debitor ? `${after.debitor?.debitorId} - ${after.debitor?.company}` : NO_VALUE,
		};

		result.push(
			<div key="debitor" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.debitor" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// SSGAccountable was changed
	if (!(before.ssgAccountable === null && after.ssgAccountable === null) && before.ssgAccountable !== after.ssgAccountable) {
		const tValues = {
			before: before.ssgAccountable ?? NO_VALUE,
			after: after.ssgAccountable ?? NO_VALUE,
		};

		result.push(
			<div key="ssgAccountable" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.ssgAccountable" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Addresses was changed
	if (!(before.addresses === null && after.addresses === null)) {
		if (before.addresses?.length === 0 && after.addresses?.length) {
			let afterString = '';
			after.addresses?.forEach(afAdd => {
				afterString += `${afAdd?.addressLine}, ${afAdd?.zipCode}, ${afAdd?.city}, ${afAdd?.startNumber}-${afAdd?.endNumber}, ${t(`catalog.address.${afAdd?.houseNumbers}`)}, `;
			});
			const tValues = {
				before: NO_VALUE,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="addresses" className="my-2">
					<Trans t={t} i18nKey="catalog.changelog.addresses" values={tValues} components={[<mark />]} />
				</div>,
			);
		} else {
			const difference = _.difference(before.addresses, after.addresses ?? []);
			if (difference.length) {
				let afterString = '';
				after.addresses?.forEach(afAdd => {
					afterString += `${afAdd?.addressLine}, ${afAdd?.zipCode}, ${afAdd?.city}, ${afAdd?.startNumber}-${afAdd?.endNumber}, ${t(`catalog.address.${afAdd?.houseNumbers}`)}, `;
				});
				let beforeString = '';
				difference?.forEach(diff => {
					beforeString += `${diff?.addressLine}, ${diff?.zipCode}, ${diff?.city}, ${diff?.startNumber}-${diff?.endNumber}, ${t(`catalog.address.${diff?.houseNumbers}`)}, `;
				});

				const tValues = {
					before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
					after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
				};

				result.push(
					<div key="addresses" className="my-2">
						<Trans t={t} i18nKey="catalog.changelog.addresses" values={tValues} components={[<mark />]} />
					</div>,
				);
			}
		}
	}

	// Timed messages was changed
	if (!(before.timedMessages === null && after.timedMessages === null)) {
		result.push(
			<div key="timedMessages" className="my-2">
				<CatalogTimedMessageChangeLog before={before.timedMessages} after={after.timedMessages} />
			</div>,
		);
	}

	// Files was changed
	if (before.filenames !== null || after.filenames !== null) {
		result.push(
			<div key="filenames" className="my-2">
				<CatalogFilesChangeLog before={before.filenames} after={after.filenames} />
			</div>,
		);
	}

	// Contacts was changed
	if (!(before.contacts === null && after.contacts === null)) {
		result.push(
			<div key="contacts" className="my-2">
				<CatalogContactsChangeLog before={before.contacts} after={after.contacts} />
			</div>,
		);
	}

	// Craftsmen was changed
	if (!(before.craftsmen === null && after.craftsmen === null)) {
		result.push(
			<div key="contacts" className="my-2">
				<CatalogCraftsmenChangeLog before={before.craftsmen} after={after.craftsmen} />
			</div>,
		);
	}

	return result;
}
