import React, { useMemo } from 'react';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { faArrowDown, faChevronLeft, faScanner } from '@fortawesome/pro-regular-svg-icons';
import {
	GetWebCasesByErpNo,
	GetWebCasesByErpNoVariables,
	GetWebMachine,
	GetWebMachineGuideFiles,
	GetWebMachineGuideFilesVariables,
	GetWebMachineLocations,
	GetWebMachines,
	GetWebMachinesVariables,
	GetWebMachineVariables,
	MoveWebMachine,
	MoveWebMachineVariables,
	MachineReserve,
	MachineReserveVariables,
	GetWebMachine_machine,
} from '../../GraphQL';
import { yupResolver } from '@hookform/resolvers/yup';
import { moveMachineToNewCase } from '../../Schemas/moveMachineToNewCase';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { isDateBetweenDates } from '../../helper';
import { DateTime } from 'luxon';
import dateToDateOnlyString, { formatDateForInput, formatDateForInputEU } from '@ssg/common/Helpers/dateToDateOnlyString';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Box from '../../Components/Layout/Box';
import BarcodeScanner from '../Movables/BarcodeScannerTwo';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Loading from '@ssg/common/Components/Loading';
import Datepicker from '@ssg/common/Components/Datepicker';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import ReserveMachine from './ReserveMachine';
import UserContext from '../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Checkbox from '@ssg/common/Components/Checkbox';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');
const GET_MACHINE = loader('src/GraphQL/Machines/GetWebMachine.gql');
const GET_MACHINE_GUIDES = loader('../../GraphQL/Machines/GetWebMachineGuideFiles.gql');
const GET_MACHINE_LOCATIONS = loader('../../GraphQL/Machines/GetWebMachineLocations.gql');
const MOVE_MACHINE = loader('src/GraphQL/Machines/MoveWebMachine.gql');
const GET_MACHINES = loader('src/GraphQL/Machines/GetWebMachines.gql');
const MACHINE_RESERVE = loader('src/GraphQL/Machines/MachineReserve.gql');

const machineErrors: string[] = ['machines.errors.notFound'];

interface IMachine {
	machineNo: string;
}

interface IMoveMachine {
	erpCaseReference?: string;
	erpLocationReference?: string;
	consumption: number;
	startingDate: Date;
	hibernation?: boolean;
	hibernationReason: string;
	unavailableReason?: string;
	placementDescription?: string;
}

enum Steps {
	SCAN = 'SCAN',
	MACHINE = 'MACHINE',
	RESERVATION = 'RESERVATION',
	LOCATION = 'LOCATION',
	CASE = 'CASE',
	HIBERNATION = 'RENT_COUNTER',
	UNAVAILABLE = 'UNAVAILABLE'
}

const consumptionLimit = 5000;

const EditMachine: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const machineUnavailableReasonFlag = useFlag(FeatureFlagEnums.MACHINE_UNAVAILABLE_REASON);
	const filterOutDiscontinuedMachines = useFlag(FeatureFlagEnums.FILTER_OUT_DISCONTINUED_MACHINES);

	const userContext = React.useContext(UserContext);

	const [step, setStep] = React.useState<Steps>(Steps.SCAN);
	const [showScannerModal, setShowScannerModal] = React.useState(false);
	const [showEducationRequirementsModal, setShowEducationRequirementsModal] = React.useState(false);

	const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);
	const [findMachineId, setFindMachineId] = React.useState('');
	const [machineError, setMachineError] = React.useState('');
	const [hibernationReasonText, setHibernationReasonText] = React.useState('');
	const [unavailableReasonText, setUnavailableReasonText] = React.useState('');

	const [casesSearchText, setCasesSearchText] = useDebouncedState('', 100);

	const [machineLocationSearchText, setMachineLocationSearchText] = useDebouncedState('', 100);

	const [consumptionLess, setConsumptionLess] = React.useState(false);

	const [consumptionMore, setConsumptionMore] = React.useState<'ERROR' | 'OVERRIDE' | undefined>(undefined);

	const consumptionBlocker = (newConsumption: number): void => {
		setConsumptionLess(newConsumption < machine.consumption);
		setConsumptionMore(newConsumption - machine.consumption > consumptionLimit ? 'ERROR' : undefined);
	};

	const [moveMachineNnotValid, setMoveMachineNotValid] = React.useState(false);

	const { data: getMachineData, loading: getMachineLoading } = useQuery<GetWebMachine, GetWebMachineVariables>(GET_MACHINE, {
		variables: {
			machineERPReferenceNo: findMachineId.toUpperCase(),
		},
		fetchPolicy: 'cache-and-network',
		skip: findMachineId === '',
	});

	const machine = useMemo(() => {
		return getMachineData?.machine ?? ({} as GetWebMachine_machine); // Backwards compatibility
	}, [getMachineData]);

	const machineIsValid = useMemo(() => {
		return getMachineData?.machine ? true : false;
	}, [getMachineData]);

	const [machineNoText, setMachineNoText] = useDebouncedState('', 100);

	const { data: machinesData, loading: loadingMachinesData } = useQuery<GetWebMachines, GetWebMachinesVariables>(GET_MACHINES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			searchString: machineNoText,
			discontinued: filterOutDiscontinuedMachines ? false : undefined,
		},
		skip: machineNoText.length < 3,
	});

	const [MachineReserve] = useMutation<MachineReserve, MachineReserveVariables>(MACHINE_RESERVE);

	const machines = React.useMemo(
		() =>
			(machinesData?.machines ?? []).map(
				(m): SelectOption => ({
					label: m.erpReferenceNo,
					value: m.erpReferenceNo,
				}),
			),
		[machinesData?.machines],
	);

	const { data: guides } = useQuery<GetWebMachineGuideFiles, GetWebMachineGuideFilesVariables>(GET_MACHINE_GUIDES, {
		variables: {
			filter: !getMachineData?.machine
				? undefined
				: {
					filterName: 'Maskintype',
					filterQuery: getMachineData.machine.name,
				},
		},
		skip: !getMachineData?.machine,
	});

	const { data: cases, loading: loadingCases } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: {
			erpReferenceNo: casesSearchText,
			closedCases: false,
			appliedClosed: false,
		},
		skip: casesSearchText === '',
	});

	const { data: locationsData, loading: loadingLocationsData } = useQuery<GetWebMachineLocations>(GET_MACHINE_LOCATIONS);

	const [moveMachine, { loading: moveMachineLoading }] = useMutation<MoveWebMachine, MoveWebMachineVariables>(MOVE_MACHINE);

	const machineLocations = React.useMemo<SelectOption[]>(
		() =>
			(locationsData?.machineLocations ?? []).map(l => ({
				label: l.name,
				value: l.locationCode,
			})),
		[locationsData],
	);

	const postMoveMachine = async (data: IMoveMachine) => {
		if (machineIsValid) {
			try {
				await moveMachine({
					variables: {
						machineInput: {
							allocationType: 'Job',
							erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
							erpCaseReference: data.erpCaseReference ?? '',
							startingDate: dateToDateOnlyString(new Date(data.startingDate)),
							startingTime: DateTime.now().toFormat('HH:mm:ss'),
							erpLocationReference: data.erpLocationReference ?? '',
							hibernate: data.erpLocationReference ? false : data.hibernation ?? false,
							consumption: data.consumption ? Number(data.consumption) : getPostValues('consumption'),
							hibernationReason: data.hibernationReason ?? '',
							placementDescription: data.placementDescription,
							unavailableReason: data.unavailableReason ?? '',
						},
						caseERPreferenceNo: machine.eRPCaseReference ?? '',
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null || cacheData.moveMachine === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetWebMachine, GetWebMachineVariables>({
							query: GET_MACHINE,
							variables: {
								machineERPReferenceNo: machine.erpReferenceNo.toUpperCase(),
							},
						});

						if (cachedRequest === null || cachedRequest.machine === null) {
							return;
						}
						cache.writeQuery<GetWebMachine, GetWebMachineVariables>({
							query: GET_MACHINE,
							variables: {
								machineERPReferenceNo: machine.erpReferenceNo.toUpperCase(),
							},
							data: {
								machine: cacheData.moveMachine,
							},
						});
					},
				});

				if (step === Steps.LOCATION) {
					clearMachineReservation();
				}

				setStep(Steps.MACHINE);
			} catch (e) {
				console.log(e);
			}
		}
	};

	const setUnavailable = async (unavailableReason: string) => {
		if (machineIsValid) {
			try {
				await moveMachine({
					variables: {
						machineInput: {
							allocationType: 'Location',
							erpReferenceNo: machine.erpReferenceNo.toUpperCase(),
							erpCaseReference: '',
							startingDate: dateToDateOnlyString(new Date()),
							startingTime: machine.startTime,
							erpLocationReference: 'INDISP',
							hibernate: machine.eRPLocationReference ? false : machine.hibernate ?? false,
							consumption: Number(machine.consumption),
							hibernationReason: machine.hibernationReason ?? '',
							unavailableReason: unavailableReason,
						},
						caseERPreferenceNo: machine.eRPCaseReference ?? '',
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null || cacheData.moveMachine === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetWebMachine, GetWebMachineVariables>({
							query: GET_MACHINE,
							variables: {
								machineERPReferenceNo: machine.erpReferenceNo.toUpperCase(),
							},
						});

						if (cachedRequest === null || cachedRequest.machine === null) {
							return;
						}
						cache.writeQuery<GetWebMachine, GetWebMachineVariables>({
							query: GET_MACHINE,
							variables: {
								machineERPReferenceNo: machine.erpReferenceNo.toUpperCase(),
							},
							data: {
								machine: cacheData.moveMachine,
							},
						});
					},
				});
				setStep(Steps.MACHINE);
			} catch (e) {
				console.log(e);
			}
		}
	};

	// Used to clear reservation when machine moves location
	const clearMachineReservation = async () => {
		try {
			await MachineReserve({
				variables: {
					machineInput: {
						erpReferenceNo: machine.erpReferenceNo ?? '',
						startingDate: machine.dateStart ?? '',
						startingTime: machine.startTime ?? '',
						erpCaseReference: machine.eRPCaseReference ?? '',
						consumption: machine.consumption ?? 0,
						hibernate: machine.hibernate ?? false,
						hibernationReason: machine.hibernationReason ?? '',
						erpLocationReference: machine.eRPLocationReference ?? '',
						allocationType: machine.type ?? '',
						reservationByRessource: null,
						reservationDescription: null,
						reservationOnCase: null,
						reservationStart: null,
						reservationEnd: null,
						unavailableReason: machine.unavailableReason,
					},
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		if (typeof scannedId !== 'undefined') {
			const scannedIdSplit = scannedId?.split(':');
			setShowScannerModal(false);
			if (scannedIdSplit[0] === 'ma') {
				setFindMachineId(scannedIdSplit[1]);
			} else {
				if (scannedIdSplit[0].length > 0) {
					setFindMachineId(scannedIdSplit[0]);
				} else {
					setMachineError(machineErrors[0]);
					setScannedId(undefined);
				}
			}
		}
	}, [scannedId]);

	const backClick = () => {
		switch (step) {
			case Steps.MACHINE:
				setFindMachineId('');
				setStep(Steps.SCAN);
				break;
			default:
				setStep(Steps.MACHINE);
				setConsumptionLess(false);
				setConsumptionMore(undefined);
				break;
		}
	};

	const schemaMachineNo = yup.object().shape(
		{
			machineNo: yup.string().required(),
		},
		[['erpCaseReference', 'erpLocationReference']],
	);

	const {
		handleSubmit: handleSubmitMachine,
		control: machineControl,
		errors: machineFormErrors,
		setValue: setMachineValue,
	} = useForm<IMachine>({
		mode: 'onChange',
		resolver: yupResolver(schemaMachineNo),
	});

	const {
		register: registerPost,
		handleSubmit: handleSubmitPost,
		setValue: setPostValue,
		getValues: getPostValues,
		control: postControl,
		errors: postErrors,
		trigger: postTrigger,
	} = useForm<IMoveMachine>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(moveMachineToNewCase),
		defaultValues: {
			erpCaseReference: machine.eRPCaseReference ?? '',
			erpLocationReference: machine.eRPLocationReference ?? '',
			consumption: undefined,
			startingDate: machine.dateStart ?? new Date(),
		},
	});

	const isMachineAvailable = (startingDate: Date): void => {
		const reservedStart = machine.reservationStart;
		const reservedEnd = machine.reservationEnd;
		const reservedBy = machine.reservationByRessource;

		if (isDateBetweenDates(reservedStart ?? null, reservedEnd ?? null, startingDate) && userContext.user?.email.split('@')[0].toUpperCase() === reservedBy) {
			setMoveMachineNotValid(false);
		} else if (isDateBetweenDates(reservedStart ?? null, reservedEnd ?? null, startingDate)) {
			setMoveMachineNotValid(true);
		} else {
			setMoveMachineNotValid(false);
		}
	};

	const onSubmitMachine = (data: IMachine) => {
		setMachineNoText('');
		setFindMachineId(data.machineNo);
	};

	React.useEffect(() => {
		if (getMachineData?.machine) {
			setStep(Steps.MACHINE);
		}
	}, [getMachineData]);

	return (
		<BoxContainer>
			<Box full className="h-full">
				<div className="text-blue w-full lg:w-2/5">
					{step === Steps.SCAN && (
						<div>
							{!machines ? (
								<Loading />
							) : (
								<div className="flex w-full flex-col items-center">
									<Button
										primary
										text="common.scanLabel"
										icon={faScanner}
										onClick={() => {
											setScannedId(undefined);
											setShowScannerModal(true);
										}}
										className="py-5"
										textClassName="text-xl ml-5"
										fullWidth
										iconSize="lg"
										loading={getMachineLoading}
										loadingColor="text-blue"
										disabled={getMachineLoading}
									/>
									<p className="mt-4 text-lg">{t('common.or')}</p>

									<form className="w-full" onSubmit={handleSubmitMachine(onSubmitMachine)}>
										<SearchableSelect
											key="machinenolabel"
											control={machineControl}
											name="machineNo"
											title="machines.no"
											allowEmpty
											options={machines ?? []}
											searchFn={searchText => setMachineNoText(searchText)}
											onSelect={value => setMachineValue('machineNo', value, { shouldValidate: true })}
											onBlur={() => undefined}
											minInputLength={3}
											isLoading={loadingMachinesData}
											errorMessage={machineFormErrors.machineNo?.message}
											initialSelection={{
												value: '',
												label: '',
											}}
										/>

										<Button text="common.find" primary fullWidth submit className="mt-1" loadingColor="text-blue" />
									</form>
									<div className="text-red mt-2 h-5 font-semibold">{t(machineError)}</div>
								</div>
							)}
						</div>
					)}
					{machineIsValid && (
						<div>
							<Button secondary text="common.back" icon={faChevronLeft} className="mb-2" iconClassName="-ml-2" onClick={() => backClick()} />

							<p className="text-lg font-semibold">
								{t('machines.no')} {machine.erpReferenceNo}
								{(filterOutDiscontinuedMachines && machine.discontinued) && <span className="ml-2 text-red">({t('machines.discontinued')})</span>}
							</p>


							{/* {machine.serviceDate != null && new Date(machine.serviceDate) < new Date() && (
                                <div className="flex py-1 text-red">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" />
                                    <p className="font-medium">{t('machines.serviceOverdue')}</p>
                                </div>
                            )} */}

							<p>
								{t('common.type')}: <span className="font-semibold">{machine.name}</span>
							</p>

							<p>
								{t('machines.moved')}:{' '}
								<span className="font-semibold">{machine.dateStart !== '0001-01-01' ? dateToDateTimeString(machine.dateStart + ' ' + machine.startTime) : '-'}</span>
							</p>

							<p>
								{t('machines.consumption')}:{' '}
								<span className="font-semibold">
									{machine.consumption} {t('machines.consumptionTypes.' + machine.consumptionType)}
								</span>
							</p>

							<p>
								{t('machines.currentCase') + ': '}
								{machine.eRPCaseReference ? (
									// <Link to={`/case/${machine.case}`} target="_blank" className="hover:underline font-semibold">
									<p className="font-semibold">{machine.eRPCaseReference}</p>
								) : (
									// </Link>
									<span>{t('common.none')}</span>
								)}
							</p>

							{machine.placementDescription && (
								<p>
									{t('common.placement') + ': '}
									<span className="font-semibold">{machine.placementDescription}</span>
								</p>
							)}

							{machine.reservationStart && machine.reservationEnd && (
								<p>
									{t('machines.machineIsReserved') + ': '}
									<span className="font-semibold">
										{machine.reservationStart === '0001-01-01T00:00:00'
											? t('machines.noReservation')
											: formatDateForInputEU(new Date(machine.reservationStart)) + ' - ' + formatDateForInputEU(new Date(machine.reservationEnd))}
									</span>
								</p>
							)}

							{machine.reservationByRessource && (
								<p>
									{t('machines.machineReservedBy') + ': '}
									<span className="font-semibold">{machine.reservationByRessource}</span>
								</p>
							)}

							{machine.eRPCaseReference && (
								<>
									<p>
										{t('machines.hibernation') + ': '}
										{machine.hibernate ? <span className="font-semibold">{t('common.yes')}</span> : <span className="font-semibold">{t('common.no')}</span>}
									</p>
									{machine.hibernate && (
										<p>
											{t('machines.hibernationReason') + ': '}
											<span className="font-semibold">{machine.hibernationReason}</span>
										</p>
									)}
								</>
							)}

							<p>
								{t('common.location') + ': '}
								{machine.eRPLocationReference ? (
									<span className="font-semibold">{machineLocations.find(l => l.value === machine.eRPLocationReference)?.label}</span>
								) : (
									<span className="font-semibold">{t('common.none')}</span>
								)}
							</p>

							{machine.eRPLocationReference === 'INDISP' && machine.unavailableReason.length > 0 && <p>{t('machines.unavailableReason')}: <span className="font-semibold">{machine.unavailableReason}</span></p>}


							{/* {machine.serviceDate && (
                                <p>
                                    {t('machines.nextService') + ': '}
                                    <span className="font-semibold">
                                        {machine.serviceDate === '0001-01-01T00:00:00'
                                            ? t('common.notSpecified')
                                            : formatDateForInputEU(new Date(machine.serviceDate))}
                                    </span>
                                </p>
                            )} */}

							<div>
								{t('common.guides')}:
								<ul className="list-disc">
									{guides?.machineGuideFiles.map((g, i) => {
										return (
											<li key={g.name} className="text-hover ml-5">
												<a href={g.url}>{g.name}</a>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					)}
					{(step === Steps.MACHINE || step === Steps.HIBERNATION || step === Steps.UNAVAILABLE) && machineIsValid && (
						<>
							<div className="flex flex-col items-center">
								<Button primary text="machines.reserveMachine" className="mt-5 py-5" textClassName="text-xl" iconSize="lg" onClick={() => setStep(Steps.RESERVATION)} fullWidth />

								<Button primary text="machines.changeLocation" className="mt-2 py-5" textClassName="text-xl" iconSize="lg" onClick={() => setStep(Steps.LOCATION)} fullWidth />

								<Button
									primary
									text="machines.onCase"
									className="mt-2 py-5"
									textClassName="text-xl"
									fullWidth
									iconSize="lg"
									onClick={() => {
										if (machine.educationPrerequisit) {
											setShowEducationRequirementsModal(true);
										} else {
											setStep(Steps.CASE);
										}
									}}
									disabled={machine.eRPCaseReference !== null || machine.eRPLocationReference === 'INDISP'}
								/>
								{machine.eRPCaseReference !== null && machine.eRPLocationReference !== 'INDISP' && <p className="h-5 font-semibold">{t('machines.unavailable')}</p>}
								{machine.eRPLocationReference === 'INDISP' && <p className="h-5 font-semibold">{t('machines.unavailableLocation')}</p>}

								<Button
									primary
									text={machine.hibernate ? 'machines.endHibernation' : 'machines.startHibernation'}
									className="mt-2 py-5"
									textClassName="text-xl"
									fullWidth
									iconSize="lg"
									onClick={() => setStep(Steps.HIBERNATION)}
									disabled={machine.eRPCaseReference === null}
								/>
								{machine.eRPCaseReference === null && <p className="h-5 font-semibold">{machine.eRPCaseReference === null && t('machines.notOnCase')}</p>}

								<Button primary text="machines.setUnavailable" className="mt-2 py-5" textClassName="text-xl" fullWidth iconSize="lg" onClick={() => machineUnavailableReasonFlag ? setStep(Steps.UNAVAILABLE) : setUnavailable('')} />
							</div>
						</>
					)}
					{step === Steps.RESERVATION && machineIsValid && <ReserveMachine machine={machine} />}
					{step === Steps.LOCATION && machineIsValid && (
						<form onSubmit={handleSubmitPost(postMoveMachine)}>
							<div>
								<SearchableSelect
									control={postControl}
									name="erpLocationReference"
									title="common.location"
									required
									options={machineLocations.filter(l => l.label.toLowerCase().includes(machineLocationSearchText.toLowerCase()))}
									searchFn={searchText => setMachineLocationSearchText(searchText)}
									onSelect={value => {
										setPostValue('erpLocationReference', value, { shouldValidate: true });
									}}
									onBlur={() => undefined}
									minInputLength={2}
									isLoading={loadingLocationsData}
									initialSelection={machineLocations.find(l => l.value === machine.eRPLocationReference ?? '')}
									errorMessage={postErrors.erpLocationReference?.message}
								/>
							</div>

							<div>
								<div className="mt-2 -mb-2">
									{t('machines.lastConsumption')}:&nbsp;
									<span className="font-semibold">
										{machine.consumption} {t('machines.consumptionTypes.' + machine.consumptionType)}
									</span>
									<Button
										primary
										icon={faArrowDown}
										text="machines.insertConsumption"
										fullWidth
										className="my-1"
										onClick={() => {
											setPostValue('consumption', machine.consumption);
											postTrigger('consumption');
										}}
									/>
								</div>
								<Input
									title="machines.consumption"
									name="consumption"
									type="number"
									required
									unitClassName="w-full lg:w-full"
									innerRef={registerPost}
									step="0.0000001"
									unit={'machines.consumptionTypes.' + machine.consumptionType}
									onChange={e => consumptionBlocker(e.currentTarget.valueAsNumber)}
									errorMessage={postErrors.consumption?.message}
								/>
								{consumptionLess && <p className="text-orange font-semibold">{t('machines.consumptionLess')}</p>}
								{consumptionMore && (
									<div>
										{consumptionMore === 'ERROR' && <p className="text-orange font-semibold">{t('machines.consumptionMore', { number: consumptionLimit })}</p>}
										<Checkbox name="ignoreMePls" onChange={e => setConsumptionMore(e.currentTarget.checked ? 'OVERRIDE' : 'ERROR')} title="machines.consumptionMoreConfirm" />
									</div>
								)}
							</div>

							<Datepicker
								name="startingDate"
								required
								title="common.date"
								innerRef={registerPost}
								defaultValue={formatDateForInput(new Date(machine.dateStart))}
								errorMessage={postErrors.startingDate?.message}
							/>

							<Button
								success
								text="machines.changeLocation"
								className="mt-5 py-5"
								textClassName="text-xl"
								iconSize="lg"
								submit
								fullWidth
								loading={moveMachineLoading}
								disabled={consumptionLess || consumptionMore === 'ERROR'}
							/>
						</form>
					)}

					{step === Steps.CASE && machineIsValid && (
						<form className="w-full" onSubmit={handleSubmitPost(postMoveMachine)}>
							<SearchableSelect
								name="erpCaseReference"
								title="common.case"
								searchFn={searchText => setCasesSearchText(searchText)}
								onSelect={value => {
									setPostValue('erpCaseReference', value, {
										shouldValidate: true,
									});
								}}
								onBlur={() => undefined}
								minInputLength={2}
								options={(cases?.cases ?? []).map(c => ({
									label: c.erpNo,
									value: c.erpNo,
								}))}
								isLoading={loadingCases}
								control={postControl}
								required
								errorMessage={postErrors.erpCaseReference?.message}
							/>

							<Input name="placementDescription" title="common.placement" innerRef={registerPost} />

							<div>
								<div className="mt-2 -mb-2">
									{t('machines.lastConsumption')}:&nbsp;
									<span className="font-semibold">
										{machine.consumption} {t('machines.consumptionTypes.' + machine.consumptionType)}
									</span>
									<Button
										primary
										icon={faArrowDown}
										text="machines.insertConsumption"
										fullWidth
										className="my-1"
										onClick={() => {
											setPostValue('consumption', machine.consumption);
											postTrigger('consumption');
										}}
									/>
								</div>
								<Input
									title="machines.consumption"
									name="consumption"
									type="number"
									required
									unitClassName="w-full lg:w-full"
									innerRef={registerPost}
									step="0.0000001"
									unit={'machines.consumptionTypes.' + machine.consumptionType}
									onChange={e => consumptionBlocker(e.currentTarget.valueAsNumber)}
									errorMessage={postErrors.consumption?.message}
								/>
								{consumptionLess && <p className="text-orange font-semibold">{t('machines.consumptionLess')}</p>}
								{consumptionMore && (
									<div>
										{consumptionMore === 'ERROR' && <p className="text-orange font-semibold">{t('machines.consumptionMore', { number: consumptionLimit })}</p>}
										<Checkbox name="ignoreMePls" onChange={e => setConsumptionMore(e.currentTarget.checked ? 'OVERRIDE' : 'ERROR')} title="machines.consumptionMoreConfirm" />
									</div>
								)}
							</div>

							<Datepicker
								name="startingDate"
								required
								title="common.date"
								innerRef={registerPost}
								defaultValue={formatDateForInput(new Date())}
								onChange={e => {
									const dateValue = new Date(e.target.value);
									isMachineAvailable(dateValue);
								}}
								errorMessage={postErrors.startingDate?.message}
							/>

							<Button
								text="machines.saveNewCase"
								success
								className="mt-1 py-5"
								submit
								loading={moveMachineLoading}
								disabled={(postErrors && moveMachineNnotValid) || consumptionLess || consumptionMore === 'ERROR'}
								fullWidth
							/>
							{moveMachineNnotValid && <p className="text-red text-sm font-semibold">{t('machines.machineAlreadyReserved')}</p>}
						</form>
					)}
				</div>
			</Box>

			<Modal title="common.scanLabel" close={() => setShowScannerModal(false)} visible={showScannerModal} body={<BarcodeScanner setScannedId={setScannedId} />} />

			<Modal
				title="machines.hibernation"
				close={() => setStep(Steps.MACHINE)}
				visible={step === Steps.HIBERNATION}
				body={
					<div>
						{machineIsValid && (
							<>
								{!machine.hibernate && (
									<div className="mb-4 flex-1">
										<Input
											required
											name="machines.hibernationReason"
											title="machines.hibernationReasonQuestion"
											onChange={e => setHibernationReasonText(e.currentTarget.value)}
											maxLength={50}
										/>
									</div>
								)}
								<div className="flex items-center justify-between space-x-2">
									<div className="flex-1">
										<Button
											success
											text={machine.hibernate ? 'machines.endHibernation' : 'machines.startHibernation'}
											className="py-5"
											textClassName="text-xl"
											iconSize="lg"
											disabled={!machine.hibernate && hibernationReasonText.length === 0}
											loading={moveMachineLoading}
											onClick={async () => {
												if (machine.eRPCaseReference !== null) {
													await postMoveMachine({
														consumption: machine.consumption,
														hibernation: !machine.hibernate,
														erpCaseReference: machine.eRPCaseReference,
														placementDescription: machine.placementDescription ?? undefined,
														hibernationReason: hibernationReasonText,
														startingDate: new Date(machine.dateStart) < new Date() ? new Date() : new Date(machine.dateStart),
														unavailableReason: '',
													});
												}
												setStep(Steps.MACHINE);
											}}
											fullWidth
										/>
									</div>
									<div className="flex-1">
										<Button secondary text="common.cancel" className="py-5" textClassName="text-xl" fullWidth iconSize="lg" onClick={() => setStep(Steps.MACHINE)} />
									</div>
								</div>
							</>
						)}
					</div>
				}
			/>

			<Modal
				title="machines.hibernation"
				close={() => setStep(Steps.MACHINE)}
				visible={step === Steps.UNAVAILABLE}
				body={
					<div>
						{machineIsValid && (
							<>

								<div className="mb-4 flex-1">
									<Input
										required
										name="machines.unavailableReason"
										title="machines.unavailableReasonQuestion"
										onChange={e => setUnavailableReasonText(e.currentTarget.value)}
										maxLength={50}
									/>
								</div>

								<div className="flex items-center justify-between space-x-2">
									<div className="flex-1">
										<Button
											success
											text="machines.setUnavailable"
											className="py-5"
											textClassName="text-xl"
											iconSize="lg"
											disabled={unavailableReasonText.length === 0}
											loading={moveMachineLoading}
											onClick={async () => {
												if (unavailableReasonText.length > 0) {
													await setUnavailable(unavailableReasonText);
													setUnavailableReasonText('');
												}
												setStep(Steps.MACHINE);
											}}
											fullWidth
										/>
									</div>
									<div className="flex-1">
										<Button secondary text="common.cancel" className="py-5" textClassName="text-xl" fullWidth iconSize="lg" onClick={() => setStep(Steps.MACHINE)} />
									</div>
								</div>
							</>
						)}
					</div>
				}
			/>

			<Modal
				size={ModalSize.SMALL}
				title="machines.eduReqTitle"
				close={() => setShowEducationRequirementsModal(false)}
				visible={showEducationRequirementsModal}
				body={
					<div className="text-blue-calm">
						<div className="text-lg">{t('machines.eduReqText1')}</div>
						<div>{`${t('machines.eduReqText2')} ${machine.name}:`}</div>
						<div className="my-2 ml-3">
							<ol>
								{machine.educationPrerequisit &&
									machine.educationPrerequisit.map((edu, i) => (
										<li key={i} className="font-bold">
											{edu}
										</li>
									))}
							</ol>
						</div>
						<div className="mt-4 flex justify-between">
							<Button
								text={t('common.accept')}
								onClick={() => {
									setStep(Steps.CASE);
									setShowEducationRequirementsModal(false);
								}}
								success
								fullWidth
								className="mr-2"
							/>
							<Button text={t('common.decline')} onClick={() => setShowEducationRequirementsModal(false)} fullWidth danger className="ml-2" />
						</div>
					</div>
				}
			/>
		</BoxContainer>
	);
};

export default EditMachine;
