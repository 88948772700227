import React from 'react';
import { faTruck, faWasher, faHome, faArchive } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { GetWebCases_cases, DrivingSlipStatus } from '../GraphQL';
import ProgressIcon, { ProgressColor } from './ProgressIcon';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

interface Props {
	caseData: GetWebCases_cases;
	compact?: boolean;
}

interface DrivingSlipCount {
	total: number;
	arrived: number;
	completed: number;
	overdue: number;
}

const getOverdue = (date: Date): boolean => {
	const today = new Date();
	return date < today;
};

const OperationProgress: React.FC<Props> = ({ caseData, compact = false }): React.ReactElement => {
	const { t } = useTranslation();

	// Maps driving slip counts and icon color
	const getDrivingSlipsCounts = (): DrivingSlipCount => {
		const count: DrivingSlipCount = {
			total: 0,
			arrived: 0,
			completed: 0,
			overdue: 0,
		};

		count.total = caseData.drivingSlipSeries.reduce((sum, dss) => dss.drivingSlips.length + sum, 0);
		count.arrived = caseData.drivingSlipSeries.reduce((sum, dss) => dss.drivingSlips.filter(ds => ds.status === DrivingSlipStatus.ARRIVED).length + sum, 0);
		count.completed = caseData.drivingSlipSeries.reduce((sum, dss) => dss.drivingSlips.filter(ds => ds.status === DrivingSlipStatus.COMPLETED).length + sum, 0);
		count.overdue = caseData.drivingSlipSeries.reduce((sum, dss) => dss.drivingSlips.filter(ds => getOverdue(new Date(ds.start)) && ds.status === DrivingSlipStatus.UNPLANNED).length + sum, 0);

		return count;
	};

	const drivingSlipCount = getDrivingSlipsCounts();

	const getDrivingSlipIConColor = (count: DrivingSlipCount): ProgressColor => {
		if (count.total === 0) return ProgressColor.TRANSPARRENT;
		if (count.overdue > 0) return ProgressColor.RED;
		if (count.total === count.completed) return ProgressColor.GREEN;

		return ProgressColor.BLUE;
	};

	return (
		<div
			className={classNames('flex flex-wrap', {
				'w-32': !compact,
				'w-20': compact,
			})}
		>
			{/* Arrived / Ankommet */}
			<ProgressIcon icon={faHome} popperTitle="common.arrived" iconColor={caseData.arrived ? ProgressColor.GREEN : ProgressColor.RED}>
				<div>{caseData.arrived ? `${t('common.yes')} ${caseData.arrivedDate !== null && `(${dateToDateTimeString(caseData.arrivedDate)})`}` : `${t('common.no')}`}</div>
			</ProgressIcon>
			{/* Drivng slips on case / Køresdler */}
			<ProgressIcon icon={faTruck} popperTitle="drivingSlips.overviewTitle" iconColor={getDrivingSlipIConColor(drivingSlipCount)}>
				{caseData.drivingSlipSeries.length > 0 ? (
					<table className="text-left text-xs">
						<thead>
							<tr>
								<th>{t('drivingSlips.plannedStart')}</th>
								<th>{t('drivingSlips.driver')}</th>
								<th>{t('common.status')}</th>
							</tr>
						</thead>
						<tbody>
							{caseData.drivingSlipSeries
								.flatMap(dss => dss.drivingSlips)
								.map(ds => (
									<tr key={ds.id + 'popper'} className="odd:bg-gray-100">
										<td className="pr-4">
											<div className="flex w-24 flex-row">
												<span
													className={classNames('py-1', {
														'text-red': getOverdue(new Date(ds.start)) && ds.status === DrivingSlipStatus.UNPLANNED,
													})}
												>
													{dateToDateTimeString(ds.start)}
												</span>
											</div>
										</td>
										<td className="pr-4 ">
											<div className="">{ds.driver?.name ?? t('common.notSpecified')}</div>
										</td>
										<td className="pr-2">
											<div>{t('drivingSlips.status.' + ds.status)}</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				) : (
					<p>{t('common.noDrivingSlipsFound')}</p>
				)}
			</ProgressIcon>

			{/* Machines / Maskiner */}
			<ProgressIcon icon={faWasher} popperTitle="common.machines" iconColor={caseData.machineOnCase ? ProgressColor.RED : ProgressColor.GREEN}>
				{/* TODO: Show machines on case */}
				{caseData.machineOnCase ? <div>{t('case.activeMachinesOnCase')}</div> : <div>{t('machines.noneOnCase')}</div>}
			</ProgressIcon>

			{/* Movables */}
			<ProgressIcon icon={faArchive} popperTitle="common.movable" iconColor={caseData.activeMovables ? ProgressColor.RED : ProgressColor.GREEN}>
				{/* TODO: Show machines on case */}
				{caseData.activeMovables ? <div>{t('case.activeMovables')}</div> : <div>{t('case.noActiveMovables')}</div>}
			</ProgressIcon>
		</div>
	);
};

export default OperationProgress;
