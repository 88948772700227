import React from 'react';
import { faEdit, faExchange, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	CatalogGlobalType,
	GetWebCatalogCustomers_catalogCustomers,
	/*GetCatalogsForDropdown_catalogs,*/ GetTimedMessages,
	GetTimedMessagesVariables,
	GetTimedMessages_timedMessages,
	Permissions,
} from '../../../GraphQL';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { tokenizeStringWithQuotesBySpaces } from '../../../helper';
import Table from '@ssg/common/Components/Table';
import TimedMessageChangeLog from './TimedMessageChangeLog';
import TimedMessageModal from './TimedMessageModal';
import TimedMessagesFilter from './TimedMessagesFilter';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import UserContext from '../../../UserContext';
import TimedMessageAddExisting from './TimedMessageAddExisting';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Box from '../../../Components/Layout/Box';
import TextButton from '@ssg/common/Components/TextButton';
import DeleteTimedMessageModal from './DeleteTimedMessageModal';

const GET_TIMED_MESSAGES = loader('src/GraphQL/TimedMessages/GetTimedMessages.gql');

export interface TimedMessageFilter {
	fromDate: Date | undefined;
	toDate: Date | undefined;
	customerIds: string[] | null;
}

interface Props {
	changedBy: string;
	catalogId?: string;
	catalogCustomers?: GetWebCatalogCustomers_catalogCustomers[];
	catalogCustomer?: GetWebCatalogCustomers_catalogCustomers;
	timedMessagesData?: GetTimedMessages_timedMessages[];
}

const TimedMessages: React.FC<Props> = ({ changedBy, catalogId, catalogCustomers, catalogCustomer, timedMessagesData }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);
	const isExternalUser = userContext.user?.external ?? true;

	const [activeFilters, setActiveFilters] = React.useState<TimedMessageFilter>({
		fromDate: undefined,
		toDate: undefined,
		customerIds: null,
	});

	const { loading: loadingTimedMessageList, data: timedMessageList } = useQuery<GetTimedMessages, GetTimedMessagesVariables>(GET_TIMED_MESSAGES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
			toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
			thisCustomerOnly: isExternalUser,
			customerIds: activeFilters.customerIds,
		},
		skip: typeof timedMessagesData !== 'undefined',
	});

	const [updateTimedMessage, setUpdateTimedMessage] = React.useState(false);
	const [createTimedMessage, setCreateTimedMessage] = React.useState(false);
	const [deleteTimedMessage, setDeleteTimedMessage] = React.useState(false);
	const [addExistingTimedMessage, setAddExistingTimedMessage] = React.useState(false);
	const [viewTimedMessageChanges, setViewTimedMessageChanges] = React.useState(false);

	const [timedMessage, setTimedMessage] = React.useState<GetTimedMessages_timedMessages>();
	const [timedMessageId, setTimedMessageId] = React.useState<string | undefined>(undefined);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const fuse = React.useMemo(
		() =>
			new Fuse(timedMessagesData ?? timedMessageList?.timedMessages ?? [], {
				shouldSort: true,
				threshold: 0.1,
				ignoreLocation: true,
				findAllMatches: true,
				keys: ['customer.name', 'startTime', 'endTime', 'message', 'catalogString', 'globalAsString', 'activeAsString', 'globalForString'],
			}),
		[timedMessageList?.timedMessages, timedMessagesData],
	);

	const filteredTimedMessages =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ name: searchToken },
								{ 'customer.name': searchToken },
								{ startTime: searchToken },
								{ endTime: searchToken },
								{ message: searchToken },
								{ catalogString: searchToken },
								{ globalAsString: searchToken },
								{ activeAsString: searchToken },
								{ globalForString: searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: timedMessagesData ?? timedMessageList?.timedMessages ?? [];

	return (
		<Box full className={catalogId && 'mb-8'} title={catalogId && 'catalog.timedMessage.overviewTitle'}>
			<div className="text flex justify-between">
				<TimedMessagesFilter
					setFilterTerm={setSearchTerm}
					onFilter={setActiveFilters}
					activeFilters={activeFilters}
					catalogCustomers={catalogCustomers ?? []}
					catalogCustomer={catalogCustomer}
				/>
				{canEdit && (
					<div className="space-y-2">
						<TextButton text="catalog.timedMessage.create" icon={faPlus} onClick={() => setCreateTimedMessage(true)} />
						{catalogId && <TextButton text="catalog.timedMessage.addExistingTimedMessage" icon={faPlus} onClick={() => setAddExistingTimedMessage(true)} />}
					</div>
				)}
			</div>

			<div>
				<Table
					data={filteredTimedMessages ?? []}
					loading={loadingTimedMessageList && typeof timedMessageList === 'undefined'}
					columns={[
						{
							label: 'catalog.customer',
							hideColumn: catalogId !== undefined,
							selectFn: c => <p>{c.customer.name}</p>,
							sortFn: (a, b) => a.customer.name.localeCompare(b.customer.name),
						},
						{
							label: 'catalog.timedMessage.startTime',
							selectFn: c => <p>{dateToDateTimeString(c.startTime)}</p>,
							sortFn: (a, b) => a.startTime.localeCompare(b.startTime),
						},
						{
							label: 'catalog.timedMessage.endTime',
							selectFn: c => <p>{dateToDateTimeString(c.endTime)}</p>,
							sortFn: (a, b) => a.endTime.localeCompare(b.endTime),
						},
						{
							label: 'common.message',
							noTruncate: true,
							selectFn: c => <p>{c.message}</p>,
							sortFn: (a, b) => (a.message ?? '').localeCompare(b.message ?? ''),
						},
						{
							label: 'catalog.attached',
							selectFn: c => (
								<p>
									{c.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : c.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : ''}
								</p>
							),
							sortFn: (a, b) =>
								(a.globalType === CatalogGlobalType.CUSTOMER_GLOBAL
									? t('catalog.customer')
									: a.globalType === CatalogGlobalType.CATALOGS_GLOBAL
									  ? t('catalog.overviewTitle')
									  : ''
								).localeCompare(
									b.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? t('catalog.customer') : b.globalType === CatalogGlobalType.CATALOGS_GLOBAL ? t('catalog.overviewTitle') : '',
								),
						},
						{
							label: 'catalog.timedMessage.catalogs',
							noTruncate: true,
							hideColumn: catalogId !== undefined,
							selectFn: c => {
								const catalogString =
									c.catalogs?.map((cat, catIndex) => {
										let catString = `${t('catalog.propertyNumber')}: ${cat.propertyNumber}`;
										if (!cat.addresses) {
											return catString;
										}
										catString += ` - ${t('catalog.addresses')}: `;
										catString += cat.addresses.map(
											(add, index) =>
												`${add.addressLine}, ${add.zipCode}, ${add.city} ${add.startNumber}-${add.endNumber}${cat.addresses && index !== cat.addresses.length - 1 ? ', ' : ''}`,
										);

										if (c.catalogs && c.catalogs.length - 1 !== catIndex) {
											catString += ', ';
										}

										return catString;
									}) ?? '';

								return <p>{catalogString}</p>;
							},
						},
						{
							label: 'common.active',
							classNameTh: 'text-right',
							hideColumn: catalogId !== undefined,
							selectFn: c => <div className="flex content-start justify-end pr-2">{c.active ? t('common.yes') : t('common.no')}</div>,
							sortFn: (a, b) => (a.active ? 'yes' : 'no').localeCompare(b.active ? 'yes' : 'no'),
						},
						{
							label: 'common.changes',
							classNameTh: 'text-right',
							selectFn: c => {
								if (c.version > 1) {
									return (
										<div className="flex content-start justify-end pr-2">
											<FontAwesomeIcon
												icon={faExchange}
												size="lg"
												onClick={() => {
													setTimedMessageId(c.id);
													setViewTimedMessageChanges(true);
												}}
												className="cursor-pointer"
											/>
										</div>
									);
								}
								return <></>;
							},
						},
						{
							label: 'common.edit',
							classNameTh: 'text-right',
							hideColumn: !canEdit,
							selectFn: c => (
								<div className="flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faEdit}
										size="lg"
										onClick={() => {
											setTimedMessage(c);
											setUpdateTimedMessage(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
						{
							label: 'common.remove',
							classNameTh: 'text-right',
							hideColumn: !canEdit,
							selectFn: c => (
								<div>
									<div className="text-red flex content-start justify-end text-right">
										{(!c.global || !catalogId) && (
											<FontAwesomeIcon
												icon={faTrashAlt}
												size="lg"
												onClick={() => {
													setTimedMessage(c);
													setDeleteTimedMessage(true);
												}}
												className="cursor-pointer"
											/>
										)}
									</div>
								</div>
							),
						},
					]}
					keySelector={c => `${c.id}`}
					noDataFoundText={'catalog.timedMessage.noTimedMessagesFound'}
				/>
			</div>

			{timedMessageId && (
				<TimedMessageChangeLog
					timedMessageId={timedMessageId}
					open={viewTimedMessageChanges}
					close={() => {
						setViewTimedMessageChanges(false);
						setTimedMessageId(undefined);
					}}
				/>
			)}

			{addExistingTimedMessage && typeof catalogCustomer !== 'undefined' && catalogId && (
				<TimedMessageAddExisting
					catalogId={catalogId}
					changedBy={changedBy}
					open={addExistingTimedMessage}
					close={() => setAddExistingTimedMessage(false)}
					catalogCustomer={catalogCustomer}
					catalogTimedMessageIds={timedMessagesData?.map(c => c.id) ?? []}
				/>
			)}

			{createTimedMessage && (
				<TimedMessageModal
					catalogCustomer={catalogCustomer}
					catalogCustomers={catalogCustomers}
					changedBy={changedBy}
					activeFilters={activeFilters}
					catalogId={catalogId}
					open={createTimedMessage}
					close={() => setCreateTimedMessage(false)}
				/>
			)}

			{updateTimedMessage && (
				<TimedMessageModal
					catalogCustomer={catalogCustomer}
					catalogCustomers={catalogCustomers}
					changedBy={changedBy}
					activeFilters={activeFilters}
					catalogId={catalogId}
					open={updateTimedMessage}
					close={() => setUpdateTimedMessage(false)}
					edit
					data={timedMessage}
				/>
			)}

			{deleteTimedMessage && typeof timedMessage !== 'undefined' && (
				<DeleteTimedMessageModal
					catalogCustomer={catalogCustomer}
					changedBy={changedBy}
					activeFilters={activeFilters}
					catalogId={catalogId}
					visible={deleteTimedMessage}
					close={() => setDeleteTimedMessage(false)}
					timedMessageData={timedMessage}
					isExternalUser={isExternalUser}
				/>
			)}
		</Box>
	);
};

export default TimedMessages;
