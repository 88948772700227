import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faCheck, faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	AddCatalogToTimedMessage,
	AddCatalogToTimedMessageVariables,
	GetCatalog,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalogVariables,
	GetTimedMessagesForAddExcisting,
	GetTimedMessagesForAddExcistingVariables,
	GetTimedMessagesForAddExcisting_timedMessages,
} from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import Button from '@ssg/common/Components/Button';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

const GET_TIMED_MESSAGES_FOR_ADD_EXCISTING = loader('src/GraphQL/TimedMessages/GetTimedMessagesForAddExcisting.gql');
const ADD_CATALOG_TO_TIMED_MESSAGE = loader('src/GraphQL/TimedMessages/AddCatalogToTimedMessage.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

interface Props {
	changedBy: string;
	open: boolean;
	close: () => void;
	catalogId: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers;
	catalogTimedMessageIds: string[];
}

const TimedMessageAddExisting: React.FC<Props> = ({ changedBy, open, close, catalogId, catalogCustomer, catalogTimedMessageIds }) => {
	const { t } = useTranslation();

	const [addCatalogToTimedMessage, { loading }] = useMutation<AddCatalogToTimedMessage, AddCatalogToTimedMessageVariables>(ADD_CATALOG_TO_TIMED_MESSAGE);
	const [idToAdd, setIdToAdd] = React.useState('');

	const { loading: loadingTimedMessageList, data: timedMessageList } = useQuery<GetTimedMessagesForAddExcisting, GetTimedMessagesForAddExcistingVariables>(GET_TIMED_MESSAGES_FOR_ADD_EXCISTING, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			customerIds: [catalogCustomer.id],
			notGlobalOnly: true,
		},
	});

	const addTimedMessageToCatalog = async (message: GetTimedMessagesForAddExcisting_timedMessages) => {
		setIdToAdd(message.id);
		await addCatalogToTimedMessage({
			variables: {
				timedMessageId: message.id,
				catalogId: catalogId,
				changedBy: changedBy,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null || typeof catalogId === 'undefined') {
					return;
				}

				const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
					query: GET_CATALOG,
					variables: {
						id: catalogId,
					},
				});

				if (cachedRequest === null || cachedRequest.catalog === null) {
					return;
				}

				cache.writeQuery<GetCatalog, GetCatalogVariables>({
					query: GET_CATALOG,
					variables: {
						id: catalogId,
					},
					data: {
						catalog: {
							...cachedRequest.catalog,
							timedMessages: [...cachedRequest.catalog.timedMessages, cacheData.addCatalogToTimedMessage],
						},
					},
				});
			},
		});
		setIdToAdd('');
	};

	return (
		<Modal
			title="catalog.timedMessage.addExistingTimedMessage"
			size={ModalSize.XLARGE}
			visible={open}
			close={close}
			body={
				<div className="text-blue">
					<Table
						data={timedMessageList?.timedMessages ?? []}
						loading={loadingTimedMessageList}
						columns={[
							{
								label: t('catalog.timedMessage.startTime'),
								selectFn: c => <p>{dateToDateTimeString(c.startTime)}</p>,
								sortFn: (a, b) => a.startTime.localeCompare(b.startTime),
							},
							{
								label: t('catalog.timedMessage.endTime'),
								selectFn: c => <p>{dateToDateTimeString(c.endTime)}</p>,
								sortFn: (a, b) => a.endTime.localeCompare(b.endTime),
							},
							{
								label: t('common.message'),
								noTruncate: true,
								selectFn: c => <p>{c.message}</p>,
								sortFn: (a, b) => (a.message ?? '').localeCompare(b.message ?? ''),
							},
							{
								label: t('common.add'),
								classNameTh: 'text-right',
								selectFn: c => {
									if (catalogTimedMessageIds.includes(c.id)) {
										return (
											<div className="flex content-start justify-end text-right">
												<Button>
													<FontAwesomeIcon icon={faCheck} size="lg" />
												</Button>
											</div>
										);
									} else {
										return (
											<div className="flex content-start justify-end text-right">
												<Button onClick={() => addTimedMessageToCatalog(c)} disabled={loading}>
													<FontAwesomeIcon
														className={idToAdd === c.id && loading ? 'animate-spin' : undefined}
														icon={idToAdd === c.id && loading ? faSpinner : faPlus}
														size="lg"
													/>
												</Button>
											</div>
										);
									}
								},
							},
						]}
						keySelector={c => `${c.id}`}
					/>
				</div>
			}
		/>
	);
};

export default TimedMessageAddExisting;
