import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { Permissions } from './GraphQL';
import { Toaster } from 'react-hot-toast';
import Main from './Components/Layout/Main';
import Tasks from './Pages/Tasks';
import CaseCreation from './Pages/CaseCreation/CaseCreation';
import CaseOverview from './Pages/CaseOverview/CaseOverview';
import RestrictedRoute from '@ssg/common/Components/RestrictedRoute';
import Login from './Pages/Login';
import SingleCase from './Pages/SingleCase/SingleCase';
import FileOverview from './Pages/SingleCase/Files/FileOverview';
import OfferOverview from './Pages/SingleCase/Offer/OfferOverview';
import CreditNote from './Pages/CreditNote/CreditNote';
import Planner from './Pages/Planner/Planner';
import UserContext from './UserContext';
import Machines from './Pages/Machines/Machines';
import Loading from '@ssg/common/Components/Loading';
import CatalogSingle from './Pages/Catalog/CatalogSingle/CatalogSingle';
import CatalogTabs from './Pages/Catalog/CatalogOverview/CatalogTabs';
import MovablesTabs from './Pages/Movables/MovablesTabs';
import DrivingSlipOverview from './Pages/DrivingSlips/DrivingSlipOverview';
import MyPageOverview from './Pages/MyPage/MyPageOverview';
import NoUser from './Pages/NoUser';
import TimeRegistrationTabs from './Pages/TimeRegistration/TimeRegistrationTabs';
import Requisitions from './Pages/Requisitions/Requisitions';
import InspectionReport from './Pages/SingleCase/Reports/InspectionReportForm';
import TrygWaterDamageReport from './Pages/SingleCase/Reports/TrygWaterDamageReportForm';
import WaterDamageReport from './Pages/SingleCase/Reports/WaterDamageReportForm';
import IndoorClimateReport from './Pages/SingleCase/Reports/IndoorClimateReport';
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import GDPR from './Pages/GDPR';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import InspectionMovablesReport from './Pages/SingleCase/Reports/InspectionMovablesReportForm';
import PlannerPage from './Pages/NewPlanner/PlannerPage';
import { PlannerContextProvider } from './Pages/NewPlanner/PlannerContext';
import PlannerLocationScreen from './Pages/NewPlanner/PlannerLocationScreen';

const App: React.FC<{ telemetryPlugin: ReactPlugin }> = ({ telemetryPlugin }): React.ReactElement => {
	const { isAuthenticated } = useMicrosoftAuth();

	const userContext = React.useContext(UserContext);

	const GDPRFlag = useFlag(FeatureFlagEnums.GDPR_AGREEMENT);

	if (!isAuthenticated) {
		return <Login />;
	}

	if (userContext.loading) {
		return <Loading />;
	}

	if (!userContext.user) {
		return <NoUser />;
	}
	const gdpr = userContext.user.gdpr;

	if (!gdpr && userContext.user.external && GDPRFlag) {
		return <GDPR />;
	}

	const userPermissions = userContext.user?.permissions ?? [];

	return (
		<AppInsightsErrorBoundary onError={() => <h1>Noget gik galt.</h1>} appInsights={telemetryPlugin}>
			<DndProvider backend={HTML5Backend}>
				<Router>
					<Main>
						<Toaster position="top-right" />
						<Switch>
							<Route path="/" exact component={MyPageOverview} />
							<RestrictedRoute path="/casecreation" exact component={CaseCreation} userPermissions={userPermissions} requiredPermissions={[Permissions.CASES_CREATE]} />
							<Route path="/casecreation/catalog/:catId/:index" exact component={CaseCreation} />
							<Route path="/casecreation/:id" exact component={CaseCreation} />
							<RestrictedRoute path="/caseoverview" exact component={CaseOverview} userPermissions={userPermissions} requiredPermissions={[Permissions.CASES_VIEW]} />
							<Route path="/case/:id/offers" component={OfferOverview} />
							<Route path="/case/:id/files" component={FileOverview} />
							<Route path="/case/:id/creditnote/:docuNo" component={CreditNote} />
							<Route path="/case/:id/inspection-report" component={InspectionReport} />
							<Route path="/case/:id/inspection-report-movables" component={InspectionMovablesReport} />
							<Route path="/case/:id/tryg-water-report" component={TrygWaterDamageReport} />
							<Route path="/case/:id/water-report" component={WaterDamageReport} />
							<Route path="/case/:id/indoor-climate-report" component={IndoorClimateReport} />
							<Route path="/case/:id" component={SingleCase} />
							{/* <Route path="/case/:id/creditnote" component={CreditNote} /> */}
							<RestrictedRoute
								path="/movables"
								exact
								component={MovablesTabs}
								userPermissions={userPermissions}
								requiredPermissions={[Permissions.MOVABLES_VIEW, Permissions.MOVABLES_EDIT]}
							/>
							<RestrictedRoute path="/tasks" exact component={Tasks} userPermissions={userPermissions} requiredPermissions={[Permissions.TASKS_VIEW, Permissions.TASKS_EDIT]} />
							<RestrictedRoute
								path="/machines"
								exact
								component={Machines}
								userPermissions={userPermissions}
								requiredPermissions={[Permissions.MACHINES_EDIT, Permissions.MACHINES_VIEW]}
							/>

							<Route path="/timeregistration" exact component={TimeRegistrationTabs} />
							<Route path="/timeregistration/:id" exact component={TimeRegistrationTabs} />

							<RestrictedRoute path="/catalog/:id" component={CatalogSingle} userPermissions={userPermissions} requiredPermissions={[Permissions.CATALOGS_VIEW]} />
							<RestrictedRoute path="/catalogoverview" exact component={CatalogTabs} userPermissions={userPermissions} requiredPermissions={[Permissions.CATALOGS_VIEW]} />
							<RestrictedRoute
								path="/drivingslipoverview"
								exact
								component={DrivingSlipOverview}
								userPermissions={userPermissions}
								requiredPermissions={[Permissions.DRIVING_SLIPS_VIEW]}
							/>
							<Route path="/mypage" component={MyPageOverview} />

							<Route path="/plannerLocationScreen" component={PlannerLocationScreen} />
							{/* Add restricted route */}
							<Route path="/requisitions" component={Requisitions} />
							<RestrictedRoute path="/planner" exact component={Planner} userPermissions={userPermissions} requiredPermissions={[Permissions.PLANNING_VIEW, Permissions.PLANNING_EDIT]} />
							<PlannerContextProvider>
								<RestrictedRoute path="/plannerpage" exact component={PlannerPage} userPermissions={userPermissions} requiredPermissions={[Permissions.PLANNING_VIEW, Permissions.PLANNING_EDIT]} />
							</PlannerContextProvider>
							{/* <Route path="/gdpr" component={GDPR} /> */}
						</Switch>
					</Main>
				</Router>
			</DndProvider>
		</AppInsightsErrorBoundary>
	);
};

export default App;
