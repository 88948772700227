import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import { stringToByteArray, toBase64 } from '@ssg/common/Helpers/inputFileHelper';
import compressImage from '@ssg/common/Helpers/compressImage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCaseImages from '../useCaseImages';
import { EsdhFileFragmentFragment, FileMetadataField } from '@ssg/common/GraphQL/indexV2';


interface Props {
	caseNo: string;
	title: string;
	onUploadComplete?: (files: EsdhFileFragmentFragment[]) => unknown;
	closeModal(): void;
}

const UploadImageForm: React.FC<Props> = ({ title, caseNo, onUploadComplete, closeModal }) => {
	const { t } = useTranslation();
	const { upload, isUploading } = useCaseImages(caseNo, onUploadComplete);

	const [image, setImage] = React.useState<File | undefined>();
	const url = React.useMemo(() => (image ? URL.createObjectURL(image) : null), [image]);

	const [comments, setComments] = React.useState<string>();

	async function handleSubmit(e: React.FormEvent) {
		e.stopPropagation();
		e.preventDefault();
		if (image == null) return;

		let base64Data: string | undefined;

		try {
			const compressedImage = await compressImage(image);
			base64Data = await toBase64(compressedImage);
		} catch (error) {
			console.error('Unable to compress photo. Falling back to original version.', error);
			base64Data = await toBase64(image);
		}

		if (!base64Data) {
			throw new Error('No base64 data for image');
		}

		const fileData = stringToByteArray(base64Data);

		await upload({
			fileData: fileData,
			filename: image.name,
			fileMetadata: typeof comments === 'string' ? [{ key: FileMetadataField.Comments, value: comments }] : null,
		});

		setImage(undefined);
		setComments(undefined);
		closeModal();
	}

	return (
		<form onSubmit={handleSubmit}>
			<label className="rounded-default text-blue block cursor-pointer px-1 pt-2 focus:outline-none">
				{url && <img src={url} alt="uploaded preview" className="h-68 w-68 object-cover" />}
				<span>
					<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
					{t('reports.uploadImage')}
				</span>
				<input
					accept="image/*"
					name="image"
					type="file"
					autoComplete="nope"
					className="hidden"
					onChange={async e => {
						if (e.target.files == null || e.target.files.length !== 1) return;
						const [file] = Array.from(e.target.files);
						setImage(file);
					}}
				/>
			</label>

			<Input value={comments ?? ''} onChange={e => setComments(e.target.value)} title={t('reports.imageComments')} name="comments" />

			<div className="flex pt-2">
				<Button success text="reports.uploadSelectedImage" disabled={image == null || isUploading} onClick={handleSubmit} loading={isUploading} />
			</div>
		</form>
	);
};

export default UploadImageForm;
