import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required', // Translation key goes here
	},
	number: {
		required: 'error.required',
	},
});

export const movableCreationSchema = yup.object().shape({
	description: yup.string().required(),

	volume: yup.number().min(0).required(),

	case: yup.string().required(),

	status: yup.string().required(),

	location: yup.string().required(),

	placement: yup.string().required(),
});
