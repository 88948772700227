import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
	titles: string[];
	onClick(title: string): void;
	active: string;
	count?: number[];
}

const PlannerTabs: React.FC<Props> = ({ children, titles, onClick, active, count = [] }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<div className="text-blue -mb-px flex flex-row w-full" >
				{titles.map((title, i) => {
					return (
						<button
							type="button"
							className={classNames('border-1 rounded-t-default whitespace-nowrap bg-white py-1 text-sm focus:outline-none', {
								'mb-px border-b-0 border-transparent px-2': title !== active,
								'border-blue -mt-px border-b-0 px-2 font-medium': title === active,
							})}
							key={'tab' + title}
							onClick={() => onClick(title)}
						>
							{t(title, { nsSeparator: false })} {typeof count[i] !== 'undefined' && ` (${count[i]})`}
						</button>
					);
				})}
			</div>
			<div
				className={classNames('border-1 flex flex-col border-blue rounded-default bg-white h-full overflow-y-hidden px-2', {
					'rounded-b-default rounded-l-none': titles[0] === active,
				})}
			>
				{children}
			</div>
		</>
	);
};

export default PlannerTabs;
