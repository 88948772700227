import React, { useContext } from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { faArrowAltDown, faArrowAltRight } from '@fortawesome/pro-regular-svg-icons';
import { postalCodeToCity } from '@ssg/common/Helpers/postalCodes';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useCallback } from 'react';
import { countries } from '@ssg/common/Helpers/countries';
import { GetWebCase_case, GetWebDebitors_debitors } from '../../GraphQL';
import Box from '../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import InputPhone from '@ssg/common/Components/InputPhone';
import Checkbox from '@ssg/common/Components/Checkbox';
import TextButton from '@ssg/common/Components/TextButton';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { FullAddressObject, fullAddressRequest } from './AddressRequest';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	caseData: GetWebCase_case | undefined;
	selectedDebitor: GetWebDebitors_debitors | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	getValues: UseFormMethods['getValues'];
	setValue: UseFormMethods['setValue'];
	trigger: UseFormMethods['trigger'];
}

const PolicyHolderBox: React.FC<Props> = ({ caseData, selectedDebitor, register, errors, control, getValues, setValue, trigger }): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);
	const getAddressFromDebitorFlag = useFlag(FeatureFlagEnums.GET_ADDRESS_FROM_DEBITOR);

	const [showHelpText, setShowHelpText] = React.useState(false);
	const [country, setCountry] = React.useState(caseData?.policyHolder.address.country ?? envVar.defaultCountry ?? '');
	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === country)?.label ?? '');
	const [phonenumber, setPhonenumber] = React.useState(caseData?.policyHolder.phone ?? '');
	const [distinctItems, setDistinctItems] = React.useState<SelectOption[]>([]);
	const [loadingDistinctItems, setLoadingDistinctItems] = React.useState<boolean>(false);
	const [address, setAddress] = React.useState<SelectOption>({
		label: caseData?.policyHolder.address.road ?? '',
		value: caseData?.policyHolder.address.road ?? '',
	});

	const phonenumberHandler = useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('PolicyHolderBox.policyPhone', phonenumber, {
				shouldValidate: true,
			});
			setPhonenumber(phonenumber);
		},
		[setValue],
	);

	const countrySelectHandler = useCallback(
		(value: string): void => {
			setValue('PolicyHolderBox.policyCountry', value, {
				shouldValidate: true,
			});
			setCountry(value);
		},
		[setValue],
	);

	React.useEffect(() => {
		countrySelectHandler(country);
	}, [country, countrySelectHandler]);

	React.useEffect(() => {
		phonenumberHandler(phonenumber);
	}, [phonenumber, phonenumberHandler]);

	const noEmail = useWatch({
		control,
		name: 'PolicyHolderBox.noEmail',
		defaultValue: false,
	});

	React.useEffect(() => {
		noEmail &&
			setValue('PolicyHolderBox.policyEmail', '', {
				shouldValidate: true,
			});
	}, [noEmail, setValue]);

	const transferValuesDamageAddressToPolicyHolder = () => {
		getFullAddresses(getValues('DamageAddressBox.damageRoad'));
		setValue('PolicyHolderBox.policyCountry', getValues('DamageAddressBox.damageCountry'));
		setValue('PolicyHolderBox.policyPhone', getValues('DamageAddressBox.damagePhone'));
		setValue('PolicyHolderBox.policyName', getValues('DamageAddressBox.damageName'));
		//setValue('PolicyHolderBox.policyAttention',getValues('DamageAddressBox.damageAttention'));
		setValue('PolicyHolderBox.policyRoad', getValues('DamageAddressBox.damageRoad'));
		setValue('PolicyHolderBox.policyHouseNumber', getValues('DamageAddressBox.damageHouseNumber'));
		setValue('PolicyHolderBox.policyFloor', getValues('DamageAddressBox.damageFloor'));
		setValue('PolicyHolderBox.policyAddress2', getValues('DamageAddressBox.damageAddress2'));
		setValue('PolicyHolderBox.policyZip', getValues('DamageAddressBox.damageZip'));
		setValue('PolicyHolderBox.policyCity', getValues('DamageAddressBox.damageCity'));
		setValue('PolicyHolderBox.policyEmail', getValues('DamageAddressBox.damageEmail'));

		setAddress({
			label: getValues('DamageAddressBox.damageRoad') ?? '',
			value: getValues('DamageAddressBox.damageRoad') ?? '',
		});
		setCountry(countries.find(c => c.value === getValues('DamageAddressBox.damageCountry'))?.value ?? 'undefined');
		phonenumberHandler(getValues('DamageAddressBox.damagePhone'));
	};

	const transferValuesSelectedDebitorToPolicyHolder = async () => {
		if (selectedDebitor) {
			setValue('PolicyHolderBox.policyCountry', getValues('DamageAddressBox.damageCountry'));
			if (selectedDebitor.phone) {
				phonenumberHandler('+45' + selectedDebitor.phone);
			}
			setValue('PolicyHolderBox.policyName', selectedDebitor.company);
			setValue('PolicyHolderBox.policyEmail', selectedDebitor.email);
			if (selectedDebitor.address !== null) {
				const addressLine = selectedDebitor.address.addressLine ?? '';
				const addresses = await fullAddressRequest(`${addressLine} ${selectedDebitor.address.postalCode} ${selectedDebitor.address.city}`, true);
				if (addresses.length > 1) {
					const findIndex = addresses.findIndex(a => a.forslagstekst.startsWith(addressLine));
					if (findIndex > -1) {
						console.log(findIndex);
						const address = addresses[findIndex];
						const { city, floor, houseNumber, postalCode, road, door } = address.data;
						const doorFloor = door ? floor + '. ' + door : floor;
						setAddress({
							label: road,
							value: road,
						});
						setValue('PolicyHolderBox.policyRoad', road);
						setValue('PolicyHolderBox.policyHouseNumber', houseNumber);
						setValue('PolicyHolderBox.policyCity', city);
						setValue('PolicyHolderBox.policyFloor', doorFloor);
						setValue('PolicyHolderBox.policyZip', postalCode);
						return;
					}
				}
				setValue('PolicyHolderBox.policyRoad', selectedDebitor.address.addressLine);
				setValue('PolicyHolderBox.policyHouseNumber', '');
				setValue('PolicyHolderBox.policyFloor', '');
				setValue('PolicyHolderBox.policyAddress2', selectedDebitor.address.addressLineAlt);
				setValue('PolicyHolderBox.policyZip', selectedDebitor.address.postalCode);
				setValue('PolicyHolderBox.policyCity', selectedDebitor.address.city);
				setAddress({
					label: selectedDebitor.address.addressLine ?? '',
					value: selectedDebitor.address.addressLine ?? '',
				});
			}
		}
	};

	const [fullAddresses, setFullAddresses] = React.useState<FullAddressObject[]>([]);

	const getFullAddresses = async (addressText: string) => {
		setLoadingDistinctItems(true);
		const addresses = await fullAddressRequest(addressText);
		setDistinctItems(addresses.map(a => ({ label: a.forslagstekst, value: a.data.id })));
		setFullAddresses(addresses);
		setLoadingDistinctItems(false);
	};

	React.useEffect(() => setValue('PolicyHolderBox.policyRoad', address.label), [address, setValue]);

	return (
		<Box form title="case.policyHolder" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.policyHolder" />}
				<div className="flex w-full flex-row space-x-4">
					<TextButton onClick={() => transferValuesDamageAddressToPolicyHolder()} text="case.sameAsDamage" icon={faArrowAltRight} className="mt-3 hidden lg:flex" />

					<TextButton onClick={() => transferValuesDamageAddressToPolicyHolder()} text="case.sameAsDamage" icon={faArrowAltDown} className="mt-3 flex lg:hidden" />
					{getAddressFromDebitorFlag && (
						<TextButton
							onClick={() => transferValuesSelectedDebitorToPolicyHolder()}
							disabled={typeof selectedDebitor === 'undefined'}
							text="case.sameAsDebitor"
							icon={faArrowAltDown}
							className="mt-3 flex"
						/>
					)}
				</div>

				<SearchableSelect
					key="country"
					control={control}
					name="PolicyHolderBox.policyCountry"
					title="common.country"
					options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
					searchFn={(searchText): void => setCountrySearchText(searchText)}
					onSelect={(value): void => countrySelectHandler(value)}
					onBlur={(): void => undefined}
					minInputLength={1}
					isLoading={false}
					errorMessage={errors.PolicyHolderBox?.policyCountry?.message ?? ''}
					initialSelection={countries.find(c => c.value === country)}
				/>

				<InputPhone
					title="common.phone"
					innerRef={register}
					name="PolicyHolderBox.policyPhone"
					errorMessage={errors.PolicyHolderBox?.policyPhone?.message ?? ''}
					control={control}
					trigger={trigger}
					searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
					onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
					defaultCountry={country}
					defaultPhoneNumber={phonenumber}
				/>

				<Input
					title="common.email"
					innerRef={register}
					name="PolicyHolderBox.policyEmail"
					errorMessage={errors.PolicyHolderBox?.policyEmail?.message ?? ''}
					helpText="case.helpText.policyEmail"
					showHelpText={showHelpText}
					disabled={noEmail}
					defaultValue={caseData?.policyHolder.email}
				/>

				<Checkbox
					title="case.noEmail"
					name="PolicyHolderBox.noEmail"
					className="mt-1"
					innerRef={register}
					onChange={() => {
						trigger('PolicyHolderBox.policyEmail');
					}}
					defaultChecked={typeof caseData !== 'undefined' && caseData.policyHolder.email === ''}
				/>

				<Input
					title="common.name"
					innerRef={register}
					name="PolicyHolderBox.policyName"
					errorMessage={errors.PolicyHolderBox?.policyName?.message ?? ''}
					helpText="case.helpText.policyName"
					showHelpText={showHelpText}
					defaultValue={caseData?.policyHolder.name}
				/>

				<div className="w-ful flex">
					<div className="w-5/6">
						<SearchableSelect
							key={address.label}
							control={control}
							name="PolicyHolderBox.policyRoad"
							title="common.road"
							helpText="case.helpText.policyAddress"
							showHelpText={showHelpText}
							options={[address, ...distinctItems]}
							addressSlice
							searchFn={searchText => {
								getFullAddresses(searchText);
							}}
							onSelect={value => {
								const fullAddress = fullAddresses.find(fa => fa.data.id === value);
								if (fullAddress) {
									const { city, floor, houseNumber, postalCode, road, door } = fullAddress.data;
									const doorFloor = door ? floor + '. ' + door : floor;
									setValue('PolicyHolderBox.policyRoad', road);
									setAddress({
										label: road,
										value: road,
									});
									setValue('PolicyHolderBox.policyHouseNumber', houseNumber);
									setValue('PolicyHolderBox.policyCity', city);
									setValue('PolicyHolderBox.policyFloor', doorFloor);
									setValue('PolicyHolderBox.policyZip', postalCode);
								}
							}}
							onBlur={() => undefined}
							minInputLength={2}
							isLoading={loadingDistinctItems}
							errorMessage={errors.PolicyHolderBox?.policyRoad?.message ?? ''}
							initialSelection={address}
						/>
					</div>

					<div className="ml-4 w-1/6">
						<Input
							title="common.houseNumber"
							innerRef={register}
							name="PolicyHolderBox.policyHouseNumber"
							errorMessage={errors.PolicyHolderBox?.policyHouseNumber?.message ?? ''}
							defaultValue={caseData?.policyHolder.address.houseNumber}
						/>
					</div>

					<div className="ml-4 w-1/6">
						<Input
							title="common.floor"
							innerRef={register}
							name="PolicyHolderBox.policyFloor"
							errorMessage={errors.PolicyHolderBox?.policyFloor?.message ?? ''}
							defaultValue={caseData?.policyHolder.address.floor ?? ''}
						/>
					</div>
				</div>

				<Input
					title="common.addressLine2"
					innerRef={register}
					name="PolicyHolderBox.policyAddress2"
					errorMessage={errors.PolicyHolderBox?.policyAddress2?.message ?? ''}
					helpText="case.helpText.policyAddress2"
					showHelpText={showHelpText}
					defaultValue={caseData?.policyHolder.address.addressLineAlt ?? ''}
				/>

				<div className="flex">
					<div className="w-28">
						<Input
							title="common.postalcode"
							innerRef={register}
							name="PolicyHolderBox.policyZip"
							errorMessage={errors.PolicyHolderBox?.policyZip?.message ?? ''}
							helpText="case.helpText.policyZip"
							showHelpText={showHelpText}
							onBlur={async e => {
								const pc = e.currentTarget.value;

								const city = await postalCodeToCity(country, pc);
								if (typeof city !== 'undefined') {
									setValue('PolicyHolderBox.policyCity', city);
								}
							}}
							defaultValue={caseData?.policyHolder.address.postalCode}
						/>
					</div>
					<div className="flex-grow-default ml-4">
						<Input
							title="common.city"
							innerRef={register}
							name="PolicyHolderBox.policyCity"
							errorMessage={errors.PolicyHolderBox?.policyCity?.message ?? ''}
							helpText="case.helpText.policyCity"
							showHelpText={showHelpText}
							className="w-full md:w-full lg:w-full"
							defaultValue={caseData?.policyHolder.address.city}
						/>
					</div>
				</div>
			</div>
		</Box>
	);
};

export default PolicyHolderBox;
