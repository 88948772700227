import React from 'react';
import Input from '@ssg/common/Components/Input';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';

interface Props {
	title: string;
	name: string;
	type: 'NUMBER' | 'BOOLEAN';
	unit: string;
	className?: string;
	labelWeight?: 'NONE' | 'BOLD';
	labelInfoPopover?: React.ReactElement;
	disabled?: boolean;
	value?: string | number | readonly string[] | undefined;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const DynamicInput: React.FC<Props> = ({ type, title, name, unit, labelWeight = 'BOLD', labelInfoPopover, disabled = false, value, onChange, className }) =>
	type === 'NUMBER' ? (
		<Input
			compact
			name={name}
			type="number"
			unit={unit}
			header={<FormFieldHeader title={title} smallTitle labelWeight={labelWeight} labelInfoPopover={labelInfoPopover} htmlFor={name} />}
			disabled={disabled}
			value={value ?? ''}
			onChange={onChange}
			className={className}
			onWheel={e => e.currentTarget.blur()}
		// Used for disabling scroll to change number value
		/>
	) : (
		<Checkbox
			name={name}
			title={title}
			textClassName="text-sm"
			labelWeight={labelWeight}
			labelInfoPopover={labelInfoPopover}
			disabled={disabled}
			onChange={onChange}
			checkedControlled={value === 1}
			className={className}
		/>
	);
export default DynamicInput;
