import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetWebMachineGuideFiles, GetWebMachineGuideFilesVariables } from '../../GraphQL';
import Loading from '@ssg/common/Components/Loading';
import Modal from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';

const GET_GUIDES = loader('../../GraphQL/Machines/GetWebMachineGuideFiles.gql');

interface Props {
	open: boolean;
	close: () => void;
}

const ViewGuides: React.FC<Props> = ({ open, close }) => {
	const { data, loading } = useQuery<GetWebMachineGuideFiles, GetWebMachineGuideFilesVariables>(GET_GUIDES);

	return (
		<Modal
			title="common.guides"
			visible={open}
			close={close}
			body={
				<div className="relative flex h-full w-full flex-row">
					{loading ? (
						<div className="h-56 w-full">
							<Loading />
						</div>
					) : (
						<>
							{data?.machineGuideFiles.map((file, index) => (
								<div className="m-2 w-40" key={index}>
									<div className="border-1 h-40 w-40 overflow-hidden border-gray-300">
										<a href={file.url} target="_blank" rel="noreferrer">
											<img src={file.thumbnail} alt={file.name} className="h-40 w-40 object-cover object-left-top" />
										</a>
									</div>
									<a href={file.url} target="_blank" rel="noreferrer">
										<TextButton link text={file.name} textClassName="w-40 truncate text-left" />
									</a>
								</div>
							))}
						</>
					)}
				</div>
			}
		/>
	);
};

export default ViewGuides;
