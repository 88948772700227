import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { machineReservationSchema } from '../../Schemas/machineReservationSchema';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { isDatesBetweenDates } from '../../helper';
import {
	/*GetCases,
	GetCasesVariables,*/
	GetWebMachine_machine,
	MachineReserve,
	MachineReserveVariables,
	GetWebMachine,
	GetWebCasesByErpNo,
	GetWebCasesByErpNoVariables,
	GetWebMachineVariables,
} from '../../GraphQL';
import Datepicker from '@ssg/common/Components/Datepicker';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import Button from '@ssg/common/Components/Button';
import UserContext from '../../UserContext';

const MACHINE_RESERVE = loader('src/GraphQL/Machines/MachineReserve.gql');
const GET_MACHINE = loader('src/GraphQL/Machines/GetWebMachine.gql');
const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');

interface Props {
	machine: GetWebMachine_machine;
}
interface FormInputs {
	erpReferenceNo: string;
	reservationOnCase: string;
	reservationDates: {
		reservationStart: Date | string;
		reservationEnd: Date | string;
		alreadyReservedStart: string | null;
		alreadyReservedEnd: string | null;
	};
	reservationDescription: string | null;

	erpCaseReference: string;
	startingDate: string;
	consumption: number;
	hibernate: boolean;
	hibernationReason: string;
	erpLocationReference: string;
	allocationType: string;
	reservationByRessource: string | null;
}

const ReserveMachine: React.FC<Props> = ({ machine }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const [caseNoText, setCaseNoText] = React.useState<string>('');
	const { data: caseData, loading: caseLoading } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		fetchPolicy: 'cache-and-network',
		variables: {
			erpReferenceNo: caseNoText,
		},
		skip: caseNoText.length < 2,
	});

	const [MachineReserve] = useMutation<MachineReserve, MachineReserveVariables>(MACHINE_RESERVE);

	const [startDate, setStartDate] = React.useState<Date>(new Date());
	const [endDate, setEndDate] = React.useState<Date>(new Date());

	const cases = React.useMemo(() => (caseData?.cases ?? []).map((c): SelectOption => ({ label: c.erpNo, value: c.erpNo })), [caseData]);

	const { handleSubmit, register, setValue, control, errors, reset } = useForm<FormInputs>({
		resolver: yupResolver(machineReservationSchema),
		reValidateMode: 'onChange',
		defaultValues: {
			erpReferenceNo: machine.erpReferenceNo,
			reservationOnCase: '',
			reservationDates: {
				reservationStart: new Date(),
				reservationEnd: new Date(),
				alreadyReservedStart: machine?.reservationStart ?? null,
				alreadyReservedEnd: machine?.reservationEnd ?? null,
			},

			erpCaseReference: machine?.eRPCaseReference ?? '',
			startingDate: machine?.dateStart ?? '',
			consumption: machine?.consumption ?? 0,
			hibernate: machine?.hibernate ?? false,
			hibernationReason: machine?.hibernationReason ?? '',
			erpLocationReference: machine?.eRPLocationReference ?? '',
			allocationType: machine?.type ?? '',
			reservationByRessource: userContext.user?.email ?? '',
		},
	});

	const onSubmit = async (data: FormInputs) => {
		try {
			await MachineReserve({
				variables: {
					machineInput: {
						erpReferenceNo: machine.erpReferenceNo,
						reservationOnCase: data.reservationOnCase,
						reservationEnd: formatDateForInput(new Date(data.reservationDates.reservationEnd)),
						reservationStart: formatDateForInput(new Date(data.reservationDates.reservationStart)),
						reservationDescription: data.reservationDescription,
						erpCaseReference: machine.eRPCaseReference ?? '',
						startingDate: machine.dateStart ?? '',
						startingTime: machine.startTime ?? '',
						consumption: machine.consumption ?? 0,
						hibernate: machine.hibernate ?? false,
						hibernationReason: machine.hibernationReason ?? '',
						erpLocationReference: machine.eRPLocationReference ?? '',
						allocationType: machine.type ?? '',
						reservationByRessource: userContext.user?.email ?? '',
						unavailableReason: machine.unavailableReason,
					},
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null || cacheData.machineReserve === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetWebMachine, GetWebMachineVariables>({
						query: GET_MACHINE,
						variables: {
							machineERPReferenceNo: machine.erpReferenceNo.toUpperCase(),
						},
					});

					if (cachedRequest === null || cachedRequest.machine === null) {
						return;
					}

					cache.writeQuery<GetWebMachine>({
						query: GET_MACHINE,
						data: {
							machine: cacheData.machineReserve,
						},
					});
				},
			});

			reset();
			setStartDate(new Date());
			setEndDate(new Date());
		} catch (e) {
			console.log(e);
		}
	};

	const isAlreadyBooked = isDatesBetweenDates(
		machine.reservationStart ?? null,
		machine?.reservationEnd ?? null,
		new Date(control.getValues('reservationDates.reservationStart')),
		new Date(control.getValues('reservationDates.reservationEnd')),
	);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SearchableSelect
					control={control}
					name="reservationOnCase"
					title="case.caseNo"
					required
					allowEmpty
					options={cases ?? []}
					searchFn={searchText => setCaseNoText(searchText)}
					onSelect={value =>
						setValue('reservationOnCase', value, {
							shouldValidate: true,
						})
					}
					onBlur={() => undefined}
					minInputLength={-1}
					isLoading={caseLoading}
					errorMessage={errors.reservationOnCase?.message}
					initialSelection={{ value: '', label: '' }}
				/>

				<Datepicker
					name="reservationDates.reservationStart"
					title="common.start"
					required
					min={formatDateForInput(new Date())}
					value={formatDateForInput(startDate)}
					onChange={e => {
						const minDate = e.target.valueAsDate;
						if (minDate === null || typeof minDate === 'undefined') return;

						minDate.setHours(0, 0, 0, 0);

						setStartDate(minDate);

						if (typeof endDate === 'undefined') return;

						if (minDate > endDate) {
							const maxDate = new Date(minDate);
							maxDate.setHours(23, 59, 59, 999);
							setEndDate(maxDate);
						}
					}}
					innerRef={register}
					errorMessage={errors.reservationDates?.reservationStart?.message}
				/>

				<Datepicker
					name="reservationDates.reservationEnd"
					title="common.end"
					required
					min={formatDateForInput(startDate)}
					onChange={e => {
						const maxDate = e.target.valueAsDate;
						if (maxDate === null) return;

						maxDate.setHours(23, 59, 59, 999);
						setEndDate(maxDate);
					}}
					innerRef={register}
					errorMessage={errors.reservationDates?.reservationEnd?.message}
				/>

				<Textarea name="reservationDescription" title="common.description" innerRef={register} defaultValue="" />

				<Button success submit className="mt-4" text="machines.reserveMachine" disabled={isAlreadyBooked} />
				{isAlreadyBooked && <p className="text-red">{t('machines.machineAlreadyReserved')}</p>}
			</form>
		</>
	);
};

export default ReserveMachine;
