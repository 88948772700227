import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle as faQuistionCircleFull } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Loading from '@ssg/common/Components/Loading';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	title?: string;
	edit?: boolean;
	form?: boolean;
	half?: boolean;
	full?: boolean;
	className?: string;
	onClick?(): void;
	showHelpText?: boolean;
	loading?: boolean;
	helpButton?: boolean;
	noPadding?: boolean;
	icon?: IconDefinition;
	iconText?: string;
}
const Box: React.FC<Props> = ({
	title,
	edit = false,
	form = false,
	half = false,
	full = false,
	children,
	className,
	onClick,
	noPadding = false,
	showHelpText,
	loading,
	helpButton = false,
	icon,
	iconText,
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames(className, 'w-full px-4', {
				'lg:w-1/3': !form && !half,
				'lg:w-full': form || full,
				'lg:w-1/2 xl:w-1/2': half,
				'lg:px-4': !noPadding,
				'lg:px-0': noPadding,
			})}
		>
			{loading && (
				<div className="absolute h-screen w-full">
					<Loading />
				</div>
			)}
			<div className="bg-blue-xlight w-full p-4">
				<div className="flex flex-row items-center justify-between">
					{typeof title !== 'undefined' && <div className="text-blue text-lg font-semibold">{t(title)}</div>}
					{helpButton && (
						<div onClick={onClick} className="text-blue flex h-8 w-8 cursor-pointer items-center justify-center">
							{showHelpText ? <FontAwesomeIcon icon={faQuistionCircleFull} size="lg" /> : <FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
						</div>
					)}
					{icon && <TextButton icon={icon} text={iconText} onClick={onClick} />}
				</div>
				{children}
			</div>
		</div>
	);
};

export default Box;
