import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
	GetCaseForMovable,
	GetCaseForMovableVariables,
	GetWebCasesByErpNo,
	GetWebCasesByErpNoVariables,
	GetWebLocations,
	GetWebLocationsVariables,
	GetMovableLocations,
	GetMovableLocationsVariables,
	GetMovableStatusOptions,
	CaseStatus,
} from '../../GraphQL';
import { IMovableCreation } from '../../Schemas/IMovableCreation';
import { movableCreationSchema } from '../../Schemas/MovableCreationSchema';
import { faScanner } from '@fortawesome/pro-regular-svg-icons';
import { MOVABLE_PLACEMENT_PREFIX } from './movablePrefixes';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import classNames from 'classnames';
import Modal from '@ssg/common/Components/Modal';
import BarcodeScanner from './BarcodeScanner';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { GetMovablesDocument, GetMovablesQuery, GetMovablesQueryVariables, useCreateMovableMutation } from '@ssg/common/GraphQL/indexV2';

const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');
const GET_CASE_FOR_MOVABLE = loader('src/GraphQL/Cases/GetCaseForMovable.gql');
const GET_LOCATIONS = loader('src/GraphQL/Locations/GetWebLocations.gql');
const GET_MOVABLE_STATUS_OPTIONS = loader('src/GraphQL/Movables/GetMovableStatusOptions.gql');
const GET_MOVABLE_LOCATIONS = loader('src/GraphQL/Movables/GetMovableLocations.gql');

interface Props {
	callback: () => unknown;
	movableId: string;
	caseId?: string;
	status: string;
	box?: boolean;
	movablesVariables: GetMovablesQueryVariables | undefined;
}

const CreateMovable: React.FC<Props> = ({ callback, movableId, caseId, status, box = false, movablesVariables }) => {
	const { t } = useTranslation();

	const [movableVolume, setMovableVolume] = React.useState<number>();
	const [placementId, setPlacementId] = React.useState('');
	const [hasVolumeAlready, setHasVolumeAlready] = React.useState(false);
	const [volumeExceeded, setVolumeExceeded] = React.useState(false);

	const { register, control, watch, errors, formState, setValue, handleSubmit } = useForm<IMovableCreation>({
		resolver: yupResolver(movableCreationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			description: box ? t('movable.labels.box') : undefined,
			volume: box ? 0.11 : undefined,
		},
		shouldUnregister: false,
	});

	const locationValue = watch('location');

	const [casesSearchText, setCasesSearchText] = useDebouncedState<string>('', 100);

	const { data: caseById, loading: loadingCaseById } = useQuery<GetCaseForMovable, GetCaseForMovableVariables>(GET_CASE_FOR_MOVABLE, {
		variables: { id: caseId ?? '' },
		skip: typeof caseId === 'undefined',
	});

	React.useEffect(() => {
		if (typeof caseById?.case !== 'undefined') {
			setCasesSearchText(caseById.case.erpNo);
			setValue('case', caseById.case.id, { shouldValidate: true });
		}
	}, [caseById?.case, setCasesSearchText, setValue]);

	const { data: casesByErpNo, loading: loadingCasesByErpNo } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: { erpReferenceNo: casesSearchText },
		skip: casesSearchText.length < 2,
	});

	const { data: locations } = useQuery<GetWebLocations, GetWebLocationsVariables>(GET_LOCATIONS, {
		variables: { movableLocationsOnly: true },
	});

	const { data: movableStatusOptions } = useQuery<GetMovableStatusOptions>(GET_MOVABLE_STATUS_OPTIONS, {
		fetchPolicy: 'cache-first',
	});
	React.useEffect(() => {
		if (typeof movableStatusOptions?.movableStatusOptions !== 'undefined') {
			const match =
				movableStatusOptions.movableStatusOptions.find(opt => status.toUpperCase() === opt.toUpperCase()) ??
				movableStatusOptions.movableStatusOptions.find(opt => status.toUpperCase().startsWith(opt.toUpperCase()));

			if (typeof match !== 'undefined') {
				setValue('status', match, {
					shouldDirty: true,
					shouldValidate: true,
				});
			}
		}
	}, [movableStatusOptions?.movableStatusOptions, setValue, status]);

	const { data: movableLocations } = useQuery<GetMovableLocations, GetMovableLocationsVariables>(GET_MOVABLE_LOCATIONS, {
		fetchPolicy: 'cache-and-network',
	});

	const [showScannerModal, setShowScannerModal] = React.useState(false);
	const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);
	React.useEffect(() => {
		if (typeof scannedId !== 'undefined') {
			setShowScannerModal(false);

			if (scannedId.startsWith(MOVABLE_PLACEMENT_PREFIX)) {
				const placement = movableLocations?.movablesLocations.find(ml => ml.id === scannedId.substring(MOVABLE_PLACEMENT_PREFIX.length));
				if (typeof placement !== 'undefined') {
					setValue('location', placement.location.id, {
						shouldDirty: true,
						shouldValidate: true,
					});
					window.setTimeout(() => {
						// Wrapped in timeout since the line above will cause dropdown to rerender and in the process reset the value
						setValue('placement', placement.id, {
							shouldDirty: true,
							shouldValidate: true,
						});
					}, 0);

					setPlacementId(placement.id);
				}
			}
		}
	}, [movableLocations?.movablesLocations, scannedId, setValue]);

	const [createMovable, { loading: createMovableSubmitting }] = useCreateMovableMutation();

	const onSubmit = async (data: IMovableCreation) => {
		// Remove location id since it is not needed when creating movable
		const { location, ...movable } = data;

		// Create movable and update cache
		await createMovable({
			variables: {
				movable: {
					...movable,
					id: movableId,
				},
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null) {
					return;
				}

				// Update cache
				const cachedRequest = cache.readQuery<GetMovablesQuery, GetMovablesQueryVariables>({
					query: GetMovablesDocument,
					variables: {
						...movablesVariables,
					},
				});

				if (cachedRequest !== null) {
					cache.writeQuery<GetMovablesQuery, GetMovablesQueryVariables>({
						query: GetMovablesDocument,
						variables: {
							...movablesVariables,
						},
						data: {
							movables: [data.createMovable, ...cachedRequest.movables],
						},
					});
				}

				// Call callback fn
				callback();
			},
		});
	};

	// Calc if placement volume is exceeded
	React.useEffect(() => {
		setVolumeExceeded(false);

		const placement = movableLocations?.movablesLocations.find(m => m.id === placementId);
		if (typeof placement === 'undefined') {
			return;
		}
		setHasVolumeAlready(placement.accVolume > 0);

		if (typeof movableVolume === 'undefined') {
			return;
		}

		const calcPlacementVolume = placement.accVolume + movableVolume;
		const placementVolume = placement.volume;
		if (calcPlacementVolume > placement.volume) {
			setVolumeExceeded(calcPlacementVolume > placementVolume);
		}
	}, [movableLocations?.movablesLocations, movableVolume, placementId]);

	if (loadingCaseById) {
		return <Loading />;
	}

	return (
		<>
			<form className="space-y-8 text-sm" onSubmit={handleSubmit(onSubmit)}>
				{caseById?.case.status !== CaseStatus.OPEN && (
					<div className="bg-red border-red border-1 rounded-md bg-opacity-25 p-2">
						<p>{t('movable.caseClosedOrAppliedClosed')}</p>
					</div>
				)}
				<div>
					<SearchableSelect
						required
						name="case"
						title="common.case"
						searchFn={searchText => setCasesSearchText(searchText)}
						onSelect={value => setValue('case', value, { shouldValidate: true })}
						onBlur={() => undefined}
						minInputLength={2}
						options={(
							casesByErpNo?.cases ?? [])
							.filter(c => c.status !== CaseStatus.APPLIED_CLOSED)
							.filter(c => c.status !== CaseStatus.CLOSED)
							.map(c => ({
								label: c.erpNo,
								value: c.id,
							}),
							)}
						isLoading={loadingCasesByErpNo}
						errorMessage={errors.case?.message}
						control={control}
						initialSelection={
							caseById?.case
								? {
									label: caseById?.case.erpNo,
									value: caseById.case.id,
								}
								: undefined
						}
					/>
				</div>
				<div>
					<Textarea required name="description" rows={3} title="common.description" errorMessage={errors.description?.message} innerRef={register} />
				</div>

				<div>
					<Input
						required
						name="volume"
						type="number"
						unit="m&sup3;"
						min={0}
						step={0.01}
						title="movable.volume"
						errorMessage={errors.volume?.message}
						innerRef={register}
						onChange={e => setMovableVolume(parseFloat(e.target.value))}
						readOnly={box}
						className={classNames({ 'bg-gray-300': box })}
					/>
				</div>

				<div>
					<Dropdown
						required
						name="status"
						title="common.status"
						data={[{ label: '', value: '' }, ...(movableStatusOptions?.movableStatusOptions ?? []).map(so => ({ label: so, value: so }))]}
						errorMessage={errors.status?.message}
						innerRef={register}
					/>
				</div>

				<div className="w-3/4">
					<Dropdown
						required
						name="location"
						title="common.location"
						data={[
							{ label: '', value: '' },
							...(locations?.locations ?? []).map(l => ({
								label: l.name,
								value: l.id,
							})),
						]}
						onChange={e => {
							if (e.target.value !== locationValue) {
								setValue('placement', '');
							}
						}}
						errorMessage={errors.location?.message}
						innerRef={register}
					/>
				</div>

				<div className="float-right">
					<div className="-mt-10 mr-8">
						<Button
							primary
							icon={faScanner}
							iconSize="2x"
							onClick={() => {
								setScannedId(undefined);
								setShowScannerModal(true);
							}}
							className="px-8 py-6"
						/>
					</div>
				</div>

				<div className="w-3/4">
					<Dropdown
						required
						name="placement"
						title="common.placement"
						data={[
							{ label: '', value: '' },
							...(movableLocations?.movablesLocations ?? [])
								.filter(ml => ml.location.id === locationValue)
								.map(ml => ({
									label: `${ml.name} - ${ml.accVolume} / ${ml.volume}`,
									value: ml.id,
								})),
						]}
						onChange={e => setPlacementId(e.target.value)}
						errorMessage={errors.placement?.message}
						innerRef={register}
						disabled={(locationValue ?? '').length === 0}
						className={classNames({
							'border-2': hasVolumeAlready || volumeExceeded,
							'border-orange focus:border-orange': hasVolumeAlready && !volumeExceeded,
							'border-red focus:border-red border-2': volumeExceeded,
						})}
					/>
					{hasVolumeAlready && !volumeExceeded && <p>{t('movable.placementHasVolumeAlready')}</p>}
					{volumeExceeded && <p>{t('movable.placementVolumeExceeded')}</p>}
				</div>

				<div className="pl-4">
					<Button submit success text="movable.createMovable" loading={createMovableSubmitting} disabled={!formState.isValid || createMovableSubmitting || caseById?.case.status !== CaseStatus.OPEN} />
				</div>
			</form>

			<Modal title="movable.scanLabel" close={() => setShowScannerModal(false)} visible={showScannerModal} body={<BarcodeScanner setScannedId={setScannedId} />} />
		</>
	);
};
export default CreateMovable;
