import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faCheck, faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
	AddCatalogToContact,
	AddCatalogToContactVariables,
	GetCatalog,
	GetCatalogContactForAddExcisting,
	GetCatalogContactForAddExcistingVariables,
	GetCatalogContactForAddExcisting_catalogContacts,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalogVariables,
} from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import Button from '@ssg/common/Components/Button';

const GET_CATALOG_CONTACTS_FOR_ADD_EXCITSING = loader('src/GraphQL/CatalogContacts/GetCatalogContactForAddExcisting.gql');
const ADD_CATALOG_TO_CONTACT = loader('src/GraphQL/CatalogContacts/AddCatalogToContact.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

interface Props {
	changedBy: string;
	open: boolean;
	close: () => void;
	catalogId: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers;
	catalogContactsIds: string[];
}

const ContactAddExisting: React.FC<Props> = ({ changedBy, open, close, catalogId, catalogCustomer, catalogContactsIds }) => {
	const { t } = useTranslation();

	const [addCatalogToContact, { loading }] = useMutation<AddCatalogToContact, AddCatalogToContactVariables>(ADD_CATALOG_TO_CONTACT);
	const [idToAdd, setIdToAdd] = React.useState('');

	const { loading: loadingContactsList, data: contactsList } = useQuery<GetCatalogContactForAddExcisting, GetCatalogContactForAddExcistingVariables>(GET_CATALOG_CONTACTS_FOR_ADD_EXCITSING, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			customerIds: [catalogCustomer.id],
			notGlobalOnly: true,
		},
	});

	const addContactToCatalog = async (contact: GetCatalogContactForAddExcisting_catalogContacts) => {
		setIdToAdd(contact.id);
		try {
			await addCatalogToContact({
				variables: {
					contactId: contact.id,
					catalogId: catalogId,
					changedBy,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null || typeof catalogId === 'undefined') {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedRequest === null || cachedRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedRequest.catalog,
								contacts: [...cachedRequest.catalog.contacts, cacheData.addCatalogToContact],
							},
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
		setIdToAdd('');
	};

	return (
		<Modal
			title="catalog.contact.addExistingContact"
			size={ModalSize.XLARGE}
			visible={open}
			close={close}
			body={
				<div className="text-blue">
					<Table
						data={contactsList?.catalogContacts ?? []}
						loading={loadingContactsList}
						columns={[
							{
								label: t('common.type'),
								classNameTh: 'w-20',
								selectFn: c => <p>{c.type ?? ''}</p>,
								sortFn: (a, b) => (a.type ?? '').localeCompare(b.type ?? ''),
							},
							{
								label: t('catalog.contact.contactName'),
								noTruncate: true,
								selectFn: c => <p>{c.contactName}</p>,
								sortFn: (a, b) => a.contactName.localeCompare(b.contactName),
							},
							{
								label: t('catalog.contact.email'),
								noTruncate: true,
								numeric: true,
								selectFn: c => <p>{c.email}</p>,
								sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
							},
							{
								label: t('common.phone'),
								noTruncate: true,
								numeric: true,
								selectFn: c => (
									<div>
										{c.informations &&
											c.informations.map(inf => inf && <p className="py-1">{<>{`${formatPhoneNumberIntl(inf.phoneNumber ?? '')}${inf.remark && ` - ${inf.remark}`}`}</>}</p>)}
									</div>
								),
							},
							{
								label: t('common.type'),
								selectFn: c => <p>{t('catalog.contact.' + c.contactType)}</p>,
								sortFn: (a, b) => a.contactType.localeCompare(b.contactType),
							},
							{
								label: t('common.add'),
								classNameTh: 'text-right',
								selectFn: c => {
									if (catalogContactsIds.includes(c.id)) {
										return (
											<div className="flex content-start justify-end text-right">
												<Button>
													<FontAwesomeIcon icon={faCheck} size="lg" />
												</Button>
											</div>
										);
									} else {
										return (
											<div className="flex content-start justify-end text-right">
												<Button onClick={() => addContactToCatalog(c)} disabled={loading}>
													<FontAwesomeIcon
														className={idToAdd === c.id && loading ? 'animate-spin' : undefined}
														icon={idToAdd === c.id && loading ? faSpinner : faPlus}
														size="lg"
													/>
												</Button>
											</div>
										);
									}
								},
							},
						]}
						keySelector={c => c.id}
					/>
				</div>
			}
		/>
	);
};

export default ContactAddExisting;
