import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GetWebCases, GetWebCasesVariables, GetSingleCase_case } from '../../../GraphQL';
import Loading from '@ssg/common/Components/Loading';

const GET_CASES = loader('../../../GraphQL/Cases/GetWebCases.gql');

interface Props {
	caseData: GetSingleCase_case;
}

const SubProjects: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const { t } = useTranslation();

	const { data: sameAddressCases, loading } = useQuery<GetWebCases, GetWebCasesVariables>(GET_CASES, {
		variables: {
			address: {
				road: caseData.damage.contact.address.road,
				houseNumber: caseData.damage.contact.address.houseNumber,
				city: caseData.damage.contact.address.city,
				postalCode: caseData.damage.contact.address.postalCode,
				addressLineAlt: '',
				country: caseData.damage.contact.address.country,
			},
			includeEconomy: false,
		},
	});

	if (loading)
		return (
			<div className="relative h-full w-full">
				<Loading title="case.gettingSubProjects" />
			</div>
		);

	return (
		<div>
			{sameAddressCases && sameAddressCases.cases.length > 0 ? (
				<div className="text-blue mt-1 pl-5">
					<ul className="list-disc pl-5">
						{sameAddressCases.cases.map(c => (
							<li key={c.id}>
								<div className="flex h-6 items-center font-semibold underline">
									<Link to={`/case/${c.id}`} target="_blank">
										{t('case.caseNo') + c.erpNo}
									</Link>
								</div>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div className="text-blue text-center font-medium">{t('case.noSubProjects')}</div>
			)}
		</div>
	);
};

export default SubProjects;
