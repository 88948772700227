import Textarea from '@ssg/common/Components/Textarea';
import TextareaAutoResize from '@ssg/common/Components/TextAreaAutoResize';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

interface Props {
    label: string;
    placeholder?: string;
    onChange(value: string): void;
    value: string | undefined;
    errorMessage?: string;
    required?: boolean;
}

const TextAreaInput: React.FC<Props> = ({ value, onChange, label, placeholder, errorMessage, required = false }) => {
    const autoResizeTextAreaFlag = useFlag(FeatureFlagEnums.AUTO_RESIZE_TEXT_AREA);

    return (
        <div className="w-full">
            {autoResizeTextAreaFlag
                ?
                <TextareaAutoResize title={label} name={label} value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} errorMessage={errorMessage} required={required} minHeight={154} />
                :
                <Textarea title={label} name={label} value={value} onChange={e => onChange(e.target.value)} rows={6} cols={20} placeholder={placeholder} errorMessage={errorMessage} required={required} />
            }
        </div>
    );
};

export default TextAreaInput;
