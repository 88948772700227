import React from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Html5QrcodeScanner } from 'html5-qrcode';
import classNames from 'classnames';

interface Props {
	setScannedId: React.Dispatch<React.SetStateAction<string | undefined>>;
	multiple?: boolean;
	allowedPrefixes?: string[];
}

const NOT_FOUND = 'SCANNED_TEXT_NOT_FOUND';

const BarcodeScanner: React.FC<Props> = ({ setScannedId, multiple = false, allowedPrefixes }): React.ReactElement => {
	const { t } = useTranslation();

	//const [active, setActive] = React.useState(false);
	const [data, setData] = React.useState(NOT_FOUND);
	const [success, setSuccess] = React.useState(false);
	const [html5QrcodeScanner, setHtml5QrcodeScanner] = React.useState<Html5QrcodeScanner>();

	React.useEffect(() => {
		return () => {
			html5QrcodeScanner?.clear();
		};
	}, [html5QrcodeScanner]);

	React.useEffect(() => {
		if (data !== NOT_FOUND && (typeof allowedPrefixes === 'undefined' || allowedPrefixes.some(prefix => data.startsWith(prefix)))) {
			if (!multiple) {
				setScannedId(data);
			} else {
				setScannedId(current => {
					if (typeof current === 'undefined' || current.length === 0) {
						return data;
					}

					if (current.includes(data)) {
						return current;
					}

					return `${current},${data}`;
				});

				setSuccess(true);
				window.setTimeout(() => setSuccess(false), 1500);
			}
		}
	}, [data, setScannedId, multiple, allowedPrefixes, html5QrcodeScanner]);

	const successHandler = React.useCallback(async (scannedString: string) => {
		console.log('id:', scannedString);

		setData(scannedString);
	}, []);

	React.useEffect(() => {
		if (typeof html5QrcodeScanner === 'undefined') {
			const newHtml5QrcodeScanner = new Html5QrcodeScanner(
				'reader',
				{
					fps: 10,
					qrbox: { width: 250, height: 250 },
					supportedScanTypes: [],
					showTorchButtonIfSupported: true,
				},
				/* verbose= */ false,
			);
			setHtml5QrcodeScanner(newHtml5QrcodeScanner);
		}
		if (typeof html5QrcodeScanner !== 'undefined') {
			html5QrcodeScanner.render(
				(data: string) => successHandler(data),
				(err: string) => console.log('err ->', err),
			);
		}
	}, [html5QrcodeScanner, successHandler]);

	return (
		<div className="relative mx-auto" style={{ width: 500, height: 600 }}>
			<div className="absolute z-40">
				<div className={classNames({ hidden: success })}>
					<div style={{ width: '500px' }} id="reader"></div>
				</div>
			</div>

			{success && (
				<div className="absolute z-50 mx-auto w-full text-center text-2xl font-semibold" style={{ top: '50%', transform: 'translateY(-50%)' }}>
					<div className="text-green-calm">
						{t('common.scanned')}
						<FontAwesomeIcon icon={faCheck} className="ml-2" size="lg" />
					</div>
				</div>
			)}
		</div>
	);
};

export default BarcodeScanner;
