import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { GetWebDrivingSlips_drivingSlips_changes } from '../../GraphQL';
import { DateTime } from 'luxon';
import DrivingSlipFilesChangeLog from './DrivingSlipFilesChangeLog';

interface IProps {
	version: number;
	changes: GetWebDrivingSlips_drivingSlips_changes[];
	open: boolean;
	close: () => void;
}

const DrivingSlipChangeLog: React.FC<IProps> = ({ version, changes, open, close }) => {
	const { t } = useTranslation();
	const sorted = React.useMemo(() => changes.slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [changes]);

	if (version === 1 || changes.length === 0) {
		return null;
	}

	return (
		<>
			<Modal
				title={t('drivingSlips.changes')}
				size={ModalSize.MEDIUM}
				visible={open}
				close={close}
				body={
					<div className="text-blue space-y-4">
						{sorted.map(c => (
							<div key={c.timestamp}>
								<strong>
									{dateToDateTimeString(c.timestamp)}
									&nbsp;{t('common.by')}&nbsp;
									{c.user.name}&nbsp;({c.user.email})
								</strong>

								<div className="mt-1">{renderChanges(t, c.before, c.after)}</div>
							</div>
						))}
					</div>
				}
			/>
		</>
	);
};
export default DrivingSlipChangeLog;

function renderChanges(t: TFunction, before: IProps['changes'][number]['before'], after: IProps['changes'][number]['after']): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// Locked was changed
	if (!(before.locked === null && after.locked === null) && before.locked !== after.locked) {
		const tValues = {
			before: before.locked ?? NO_VALUE,
			after: after.locked ?? NO_VALUE,
		};

		result.push(
			<div key="locked" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.locked" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Status was changed
	if (!(before.status === null && after.status === null) && before.status !== after.status) {
		const tValues = {
			before: t(`drivingSlips.status.${before.status}`) ?? NO_VALUE,
			after: t(`drivingSlips.status.${after.status}`) ?? NO_VALUE,
		};

		result.push(
			<div key="status" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.status" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Urgent was changed
	if (!(before.urgent === null && after.urgent === null) && before.urgent !== after.urgent) {
		const tValues = {
			before: before.urgent ? t('common.yes') : t('common.no'),
			after: after.urgent ? t('common.yes') : t('common.no'),
		};

		result.push(
			<div key="urgent" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.urgent" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Driver number was changed
	if (!(before.driver === null && after.driver === null) && (before.driver && before.driver.name) !== (after.driver && after.driver.name)) {
		const tValues = {
			before: before.driver?.name ?? NO_VALUE,
			after: after.driver?.name ?? NO_VALUE,
		};

		result.push(
			<div key="driver" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.driver" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Car was changed
	if (!(before.car === null && after.car === null) && (before.car && before.car.vehicleNumber) !== (after.car && after.car.vehicleNumber)) {
		const tValues = {
			before: before.car?.vehicleNumber ?? NO_VALUE,
			after: after.car?.vehicleNumber ?? NO_VALUE,
		};

		result.push(
			<div key="car" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.car" values={tValues} components={[<mark />]} />
			</div>,
		);
	}
	if (!(before.materials === null && after.materials === null) && ((before.materials ?? []).length !== (after.materials ?? []).length)) {
		const tValues = {
			before: before.materials?.map(m => m.vehicleNumber).join(', ') ?? NO_VALUE,
			after: after.materials?.map(m => m.vehicleNumber).join(', ') ?? NO_VALUE,
		};

		result.push(
			<div key="car" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.materials" values={tValues} components={[<mark />]} />
			</div>,
		);
	}
	// Start was changed
	if (!(before.start === null && after.start === null) && before.start !== after.start) {
		const tValues = {
			before: DateTime.fromISO(before.start ?? '').toFormat('dd-MM-yyyy - hh:mm') ?? NO_VALUE,
			after: DateTime.fromISO(after.start ?? '').toFormat('dd-MM-yyyy - hh:mm') ?? NO_VALUE,
		};

		result.push(
			<div key="start" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.start" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	if (!(before.eventReferenceId === null && after.eventReferenceId === null) && (before.eventReferenceId !== after.eventReferenceId)) {
		result.push(
			<div key="car" className="my-2">
				{t('drivingSlips.changelog.event')}
			</div>,
		);
	}

	// End was changed
	if (!(before.end === null && after.end === null) && before.end !== after.end) {
		const tValues = {
			before: DateTime.fromISO(before.end ?? '').toFormat('dd-MM-yyyy - hh:mm') ?? NO_VALUE,
			after: DateTime.fromISO(after.end ?? '').toFormat('dd-MM-yyyy - hh:mm') ?? NO_VALUE,
		};

		result.push(
			<div key="end" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.end" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Comment number was changed
	if (!(before.comment === null && after.comment === null) && before.comment !== after.comment) {
		const tValues = {
			before: before.comment ?? NO_VALUE,
			after: after.comment ?? NO_VALUE,
		};

		result.push(
			<div key="comment" className="my-2">
				<Trans t={t} i18nKey="drivingSlips.changelog.comment" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Files was changed
	if (before.fileNames !== null || after.fileNames !== null) {
		result.push(
			<div key="filenames" className="my-2">
				<DrivingSlipFilesChangeLog before={before.fileNames} after={after.fileNames} />
			</div>,
		);
	}

	return result;
}
