import { useQuery } from '@apollo/client';
import { faExclamationTriangle, faSpinner, faStopCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from '@ssg/common/Components/Dropdown';
import Textarea from '@ssg/common/Components/Textarea';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../Components/Layout/Box';
import { GetInvoiceReasonCodes, ValidateInvoice, ValidationRuleBypassInput } from '../../../GraphQL';
import InvoiceValidationLine from './InvoiceValidationLine';

const GET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/GetInvoiceReasonCodes.gql');

interface Props {
	loading: boolean;
	checks: ValidateInvoice['validateInvoice'];

	defaultValues: ValidationRuleBypassInput[];
	setBypassReasonCode: (rule: ValidationRuleBypassInput['rule'], reason: ValidationRuleBypassInput['reasonCode']) => unknown;
	setBypassComment: (rule: ValidationRuleBypassInput['rule'], comment: ValidationRuleBypassInput['comment']) => unknown;
}

const InvoiceStepValidation: React.FC<Props> = ({ loading, checks, defaultValues, setBypassReasonCode, setBypassComment }) => {
	const { t } = useTranslation();

	const { data: invoiceReasonCodesData, loading: invoiceReasonCodesLoading } = useQuery<GetInvoiceReasonCodes>(GET_INVOICE_REASON_CODES);

	const invalidResults = React.useMemo(() => checks.filter(r => !r.value.valid), [checks]);
	const anyBypassableNotAllowed = React.useMemo(() => invalidResults.filter(r => !r.value.bypassAllowed) ?? [], [invalidResults]);
	const bypassableErrorKeys = invalidResults.filter(r => r.value.bypassAllowed).map(r => r.key);

	return (
		<Box full noPadding loading={loading} title="case.invoice.validation" className="-mx-4">
			<div className="space-y-2">
				{checks.map(l => (
					<InvoiceValidationLine invoiceValidation={l} key={l.key} />
				))}

				{anyBypassableNotAllowed.length > 0 ? (
					<p>
						<FontAwesomeIcon icon={faStopCircle} className="mr-1 mt-4" />
						<span>
							{anyBypassableNotAllowed.length}&nbsp;
							{t('case.invoice.bypassNotAllowed')}
						</span>
					</p>
				) : (
					<p>
						<FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 mt-4" />
						<span>
							{invalidResults.length}&nbsp;
							{t('case.invoice.invalidResults')}
						</span>
					</p>
				)}
			</div>

			{anyBypassableNotAllowed.length === 0 &&
				(invoiceReasonCodesLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="text-blue mt-8 animate-spin" size="lg" />
				) : (
					<div className="mt-8">
						<p className="text-blue text-lg font-bold">{t('case.invoice.bypassAllowed')}</p>

						<div className="flex flex-wrap">
							{bypassableErrorKeys.map((validationKey, idx) => (
								<Box half noPadding key={validationKey} className="border-1 rounded border-gray-400">
									<p className="text-blue text-md font-bold">{t(`case.invoice.${validationKey}`)}</p>

									<Dropdown
										required
										title="case.invoice.reasonCode"
										name={`reasons.${idx}.reasonCode`}
										data={[
											{ label: '', value: '' },
											...(invoiceReasonCodesData?.invoiceReasonCodes ?? []).map(rc => ({
												label: rc.name,
												value: rc.id,
											})),
										]}
										defaultValue={defaultValues.find(dv => dv.rule === validationKey)?.reasonCode ?? ''}
										onChange={e => setBypassReasonCode(validationKey, e.target.value)}
									/>

									<Textarea
										title="case.invoice.comment"
										name={`reasons.${idx}.comment`}
										defaultValue={defaultValues.find(dv => dv.rule === validationKey)?.comment ?? ''}
										onChange={e => setBypassComment(validationKey, e.target.value)}
									/>
								</Box>
							))}
						</div>
					</div>
				))}
		</Box>
	);
};
export default InvoiceStepValidation;
