import React, { useContext } from 'react';
import { GetWebCase_case } from '../../GraphQL';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import Box from '../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import Checkbox from '@ssg/common/Components/Checkbox';
import Textarea from '@ssg/common/Components/Textarea';
import classNames from 'classnames';
import TextButton from '@ssg/common/Components/TextButton';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

interface Props {
	caseData: GetWebCase_case | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	trigger: UseFormMethods['trigger'];
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
	getValues: UseFormMethods['getValues'];
}

const AlternativeContactBox: React.FC<Props> = ({ caseData, register, errors, control, trigger, setValue, getValues }): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const [showHelpText, setShowHelpText] = React.useState(false);
	const [phonenumber, setPhonenumber] = React.useState(caseData?.alternativeContact?.phone ?? '');
	const [country, setCountry] = React.useState<string>(envVar.defaultCountry ?? '');
	const [showAlternativeContact, setShowAlternativeContact] = React.useState(typeof caseData !== 'undefined' && caseData.alternativeContact !== null);

	const phonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('AlternativeContactBox.phone', phonenumber, {
				shouldValidate: true,
			});
			setPhonenumber(phonenumber);
		},
		[setValue],
	);

	React.useEffect(() => {
		phonenumberHandler(phonenumber);
	}, [phonenumber, phonenumberHandler]);

	const noEmail = useWatch({
		control,
		name: 'AlternativeContactBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.requisitioner.email === '',
	});

	React.useEffect(() => {
		noEmail &&
			setValue('AlternativeContactBox.email', '', {
				shouldValidate: true,
			});
	}, [noEmail, setValue]);

	return (
		<Box form title="case.alternativeContact" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				<TextButton
					text={!showAlternativeContact ? 'case.showAlternativeContact' : 'case.hideAlternativeContact'}
					icon={!showAlternativeContact ? faChevronDown : faChevronUp}
					onClick={() => setShowAlternativeContact(!showAlternativeContact)}
				/>

				<div
					className={classNames({
						hidden: !showAlternativeContact,
					})}
				>
					<Input
						title="common.name"
						innerRef={register}
						name="AlternativeContactBox.name"
						errorMessage={errors.AlternativeContactBox?.name?.message ?? ''}
						defaultValue={caseData?.alternativeContact?.name}
					/>

					<InputPhone
						title="common.phone"
						innerRef={register}
						name="AlternativeContactBox.phone"
						errorMessage={errors.AlternativeContactBox?.phone?.message ?? ''}
						control={control}
						trigger={trigger}
						searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
						onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
						defaultCountry={country}
						defaultPhoneNumber={phonenumber}
					/>

					<Input
						title="common.email"
						innerRef={register}
						name="AlternativeContactBox.email"
						disabled={noEmail}
						errorMessage={errors.AlternativeContactBox?.email?.message}
						defaultValue={caseData?.alternativeContact?.email}
					/>

					<Checkbox
						title="case.noEmail"
						name="AlternativeContactBox.noEmail"
						className="mt-1"
						innerRef={register}
						onChange={() => {
							trigger('AlternativeContactBox.email');
						}}
						defaultChecked={typeof caseData !== 'undefined' && caseData.alternativeContact !== null && caseData.alternativeContact.email === ''}
					/>

					<Textarea title="common.comment" name="AlternativeContactBox.comment" rows={3} innerRef={register} defaultValue={caseData?.alternativeContact?.comment ?? ''} />
				</div>
			</div>
		</Box>
	);
};

export default AlternativeContactBox;
