import * as yup from 'yup';
import { AdviserSchema } from './AdviserSchema';
import { AlternativeContactSchema } from './AlternativeContactSchema';
import { CustomerSchema } from './CustomerSchema';
import { DamageAddressSchema } from './DamageAddressSchema';
import { DamageSchema } from './DamageSchema';
import { DrivingSlipCaseCreationSchema } from './DrivingSlipCaseCreateSchema';
import { InformationSchema } from './InformationSchema';
import { PolicyHolderSchema } from './PolicyHolderSchema';
import { ReferrerSchema } from './ReferrerSchema';
import { RequisitionerSchema } from './RequisitionerSchema';
import { RiskEvaluationSchema } from './RiskEvaluationSchema';
import { SSGInfoSchema } from './SSGInformationSchema';
import { VisitationSchema } from './VisitationSchema';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const caseCreationSchema = yup.object().shape({
	InformationBox: InformationSchema,

	DamageBox: DamageSchema,

	CustomerBox: CustomerSchema,

	VisitationBox: VisitationSchema,

	DamageAddressBox: DamageAddressSchema,

	PolicyHolderBox: PolicyHolderSchema,

	DrivingSlipBox: DrivingSlipCaseCreationSchema,

	SSGInformationBox: SSGInfoSchema,

	RequisitionerInformationBox: RequisitionerSchema,

	AdviserBox: AdviserSchema,

	ReferrerBox: ReferrerSchema,

	AlternativeContactBox: AlternativeContactSchema,

	RiskEvaluationBox: RiskEvaluationSchema,
});
