import React from 'react';
import { GetWebCase_case } from '../../GraphQL';
import { UseFormMethods } from 'react-hook-form';
import Checkbox from '@ssg/common/Components/Checkbox';
import Box from '../../Components/Layout/Box';

interface Props {
	caseData: GetWebCase_case | undefined;
	register: UseFormMethods['register'];
}

const SkaforBox: React.FC<Props> = ({ caseData, register }): React.ReactElement => {
	const [showHelpText, setShowHelpText] = React.useState(false);

	return (
		<Box form title="case.SKAFOR" half={true} onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton>
			<hr />
			<Checkbox
				name="InformationBox.skafor"
				title="case.SKAFOR"
				innerRef={register}
				helpText="case.helpText.SKAFOR"
				showHelpText={showHelpText}
				className="mt-2"
				defaultChecked={caseData?.skafor}
			/>
		</Box>
	);
};

export default SkaforBox;
