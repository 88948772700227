import React from 'react';
import { /*CatalogGlobalType,*/ GetCatalog_catalog_timedMessages } from '../../../GraphQL';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Box from '../../../Components/Layout/Box';

interface Props {
	timedMessages: GetCatalog_catalog_timedMessages[];
}

export const CatalogTimedMessagesInformation: React.FC<Props> = ({ timedMessages }) => {
	const activeMessages = React.useMemo(() => {
		const now = new Date();
		return timedMessages.filter(m => new Date(m.startTime) <= now && now <= new Date(m.endTime));
	}, [timedMessages]);

	return (
		<Box title="" className="text-blue mb-8" full>
			<div className="text flex justify-between">
				<FormFieldHeader title="catalog.activeMessages" labelClass="text-lg" titleClass="" />
			</div>
			{activeMessages.map(tm => (
				<div key={tm.id} className="text-red-calm">
					{tm.message}
				</div>
			))}
		</Box>
	);
};

export default CatalogTimedMessagesInformation;
