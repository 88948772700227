import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CompanyContactInput, GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { AdviserReferrerSchema } from '../../Schemas/AdviserReferrerSchema';
import { IAdviserReferrer } from '../../Schemas/ICaseCreation';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import Checkbox from '@ssg/common/Components/Checkbox';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}
const AdviserReferrerBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);
	const defaultCountry = envVar.defaultCountry;

	const [editActive, setEditActive] = React.useState(false);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	//Handles adviser fields
	const [adviserCountry, setAdviserCountry] = React.useState(envVar.defaultCountry);
	const [adviserCompanyCountry, setAdviserCompanyCountry] = React.useState(defaultCountry);
	const [adviserPhonenumber, setAdviserPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.adviser?.phone ?? '').replaceAll(/(\s|-)+/g, ''));
	const [adviserCompanyPhonenumber, setAdviserCompanyPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.referrer?.companyPhone ?? '').replaceAll(/(\s|-)+/g, ''));

	const adviserPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('AdviserBox.phone', phonenumber, { shouldValidate: true });
		setAdviserPhonenumber(phonenumber);
	};

	const adviserCompanyPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('AdviserBox.companyPhone', phonenumber, {
			shouldValidate: true,
		});
		setAdviserCompanyPhonenumber(phonenumber);
	};

	//Handles referrer fields
	const [referrerCountry, setReferrerCountry] = React.useState(defaultCountry);
	const [referrerCompanyCountry, setReferrerCompanyCountry] = React.useState(defaultCountry);
	const [referrerPhonenumber, setReferrerPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.referrer?.phone ?? '').replaceAll(/(\s|-)+/g, ''));
	const [referrerCompanyPhonenumber, setReferrerCompanyPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.referrer?.companyPhone ?? '').replaceAll(/(\s|-)+/g, ''));

	const referrerPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('ReferrerBox.phone', phonenumber, { shouldValidate: true });
		setReferrerPhonenumber(phonenumber);
	};

	const referrerCompanyPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('ReferrerBox.companyPhone', phonenumber, {
			shouldValidate: true,
		});
		setReferrerCompanyPhonenumber(phonenumber);
	};

	const { handleSubmit, errors, register, control, setValue, trigger } = useForm<IAdviserReferrer>({
		resolver: yupResolver(AdviserReferrerSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			AdviserBox: {
				phone: caseData.adviser?.phone ?? '',
				companyPhone: caseData.adviser?.companyPhone ?? '',
			},
			ReferrerBox: {
				phone: caseData.referrer?.phone ?? '',
				companyPhone: caseData.referrer?.companyPhone ?? '',
			},
		},
	});

	const adviserNoEmail = useWatch({
		control,
		name: 'AdviserBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.adviser?.email === '',
	});

	const referrerNoEmail = useWatch({
		control,
		name: 'ReferrerBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.referrer?.email === '',
	});

	React.useEffect(() => {
		if (adviserNoEmail) {
			setValue('AdviserBox.email', '', { shouldValidate: true });
		}
	}, [adviserNoEmail, setValue]);

	React.useEffect(() => {
		if (referrerNoEmail) {
			setValue('ReferrerBox.email', '', { shouldValidate: true });
		}
	}, [referrerNoEmail, setValue]);

	const onSubmit = async (data: IAdviserReferrer) => {
		try {
			let updatedAdviser: CompanyContactInput | null = null;

			if (data.AdviserBox.name.length > 0 || data.AdviserBox.phone.length > 0 || data.AdviserBox.email.length > 0) {
				updatedAdviser = {
					name: data.AdviserBox.name,
					phone: data.AdviserBox.phone,
					email: data.AdviserBox.email,
					companyName: data.AdviserBox.companyName,
					companyPhone: data.AdviserBox.companyPhone,
				};
			}

			let updatedReferrer: CompanyContactInput | null = null;

			if (data.ReferrerBox.name.length > 0 || data.ReferrerBox.phone.length > 0 || data.ReferrerBox.email.length > 0) {
				updatedReferrer = {
					name: data.ReferrerBox.name,
					phone: data.ReferrerBox.phone,
					email: data.ReferrerBox.email,
					companyName: data.ReferrerBox.companyName,
					companyPhone: data.ReferrerBox.companyPhone,
				};
			}
			await postCaseUpdate({
				id: caseData.id,
				adviser: updatedAdviser,
				referrer: updatedReferrer,
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, adviser, referrer }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id,
				adviser,
				referrer,
			},
		});
	};

	const adviser: SelectOption[] = [
		{
			value: caseData.adviser?.name ?? 'case.noAdviser',
			label: 'case.adviser',
		},
		{ value: caseData.adviser?.phone ?? '', label: 'common.tel' },
		{ value: caseData.adviser?.email ?? '', label: 'common.mail' },
		{
			value: caseData.adviser?.companyName ?? '',
			label: 'common.companyName',
		},
		{
			value: caseData.adviser?.companyPhone ?? '',
			label: 'common.companyPhone',
		},
	];

	const referrer: SelectOption[] = [
		{
			value: caseData.referrer?.name ?? 'case.noReferrer',
			label: 'case.referrer',
		},
		{ value: caseData.referrer?.phone ?? '', label: 'common.tel' },
		{ value: caseData.referrer?.email ?? '', label: 'common.mail' },
		{
			value: caseData.referrer?.companyName ?? '',
			label: 'common.companyName',
		},
		{
			value: caseData.referrer?.companyPhone ?? '',
			label: 'common.companyPhone',
		},
	];

	return (
		<>
			<Box title="case.adviserReferrer" icon={caseViewExternal ? undefined : faEdit} full onClick={() => setEditActive(true)} className="mt-8">
				<hr />
				<BoxFormDataViewer data={adviser} keyId="case.ssgInformation" />
				<br />
				<BoxFormDataViewer data={referrer} keyId="case.ssgInformation" />
			</Box>
			<Modal
				title="case.editAdviserReferrer"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.MEDIUM}
				body={
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
						<div className="w-1/2 pr-2">
							<p className="text-blue text-lg font-medium">{t('case.adviser')}</p>
							<Input title="common.name" name="AdviserBox.name" innerRef={register} errorMessage={errors.AdviserBox?.name?.message ?? ''} defaultValue={caseData.adviser?.name} />

							<InputPhone
								title="common.phone"
								innerRef={register}
								name="AdviserBox.phone"
								errorMessage={errors.AdviserBox?.phone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => adviserPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setAdviserCountry(countrycode ?? '')}
								defaultCountry={adviserCountry}
								defaultPhoneNumber={adviserPhonenumber}
							/>

							<Input
								title="common.email"
								name="AdviserBox.email"
								innerRef={register}
								errorMessage={errors.AdviserBox?.email?.message ?? ''}
								defaultValue={caseData.adviser?.email}
								disabled={adviserNoEmail}
							/>

							<Checkbox
								title="case.noEmail"
								name="AdviserBox.noEmail"
								className="mt-1"
								innerRef={register}
								onChange={() => {
									trigger('AdviserBox.email');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.adviser?.email === ''}
							/>

							<Input
								title="common.companyName"
								name="AdviserBox.companyName"
								innerRef={register}
								errorMessage={errors.AdviserBox?.companyName?.message ?? ''}
								defaultValue={caseData.adviser?.companyName ?? ''}
							/>

							<InputPhone
								title="common.companyPhone"
								innerRef={register}
								name="AdviserBox.companyPhone"
								errorMessage={errors.AdviserBox?.companyPhone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => adviserCompanyPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setAdviserCompanyCountry(countrycode ?? '')}
								defaultCountry={adviserCompanyCountry}
								defaultPhoneNumber={adviserCompanyPhonenumber}
							/>
						</div>
						<div className="w-1/2 pl-2">
							<p className="text-blue text-lg font-medium">{t('case.referrer')}</p>
							<Input title="common.name" name="ReferrerBox.name" innerRef={register} errorMessage={errors.ReferrerBox?.name?.message ?? ''} defaultValue={caseData.referrer?.name} />

							<InputPhone
								title="common.phone"
								innerRef={register}
								name="ReferrerBox.phone"
								errorMessage={errors.ReferrerBox?.phone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => referrerPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setReferrerCountry(countrycode ?? '')}
								defaultCountry={referrerCountry}
								defaultPhoneNumber={referrerPhonenumber}
							/>

							<Input
								title="common.email"
								name="ReferrerBox.email"
								innerRef={register}
								errorMessage={errors.ReferrerBox?.email?.message ?? ''}
								defaultValue={caseData.referrer?.email}
								disabled={referrerNoEmail}
							/>

							<Checkbox
								title="case.noEmail"
								name="ReferrerBox.noEmail"
								className="mt-1"
								innerRef={register}
								onChange={() => {
									trigger('ReferrerBox.email');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.referrer?.email === ''}
							/>

							<Input
								title="common.companyName"
								name="ReferrerBox.companyName"
								innerRef={register}
								errorMessage={errors.ReferrerBox?.companyName?.message ?? ''}
								defaultValue={caseData.referrer?.companyName ?? ''}
							/>

							<InputPhone
								title="common.companyPhone"
								innerRef={register}
								name="ReferrerBox.companyPhone"
								errorMessage={errors.ReferrerBox?.companyPhone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => referrerCompanyPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setReferrerCompanyCountry(countrycode ?? '')}
								defaultCountry={referrerCompanyCountry}
								defaultPhoneNumber={referrerCompanyPhonenumber}
							/>
						</div>
						<div className="mt-3 w-full">
							<Button submit success text="case.editAdviserReferrer" loading={updateCaseSubmitting} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default AdviserReferrerBox;
