import React from 'react';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Dropdown from '@ssg/common/Components/Dropdown';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TrackSchema } from '../../Schemas/TrackSchema';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface IProps {
	open: boolean;
	close: () => void;
	currentTrack: number;
	caseId: string;
}

interface ITrack {
	track: number;
}

const ChangeTrack: React.FC<IProps> = ({ open, close, currentTrack, caseId }): React.ReactElement => {
	const { t } = useTranslation();

	const trackOptions: SelectOption[] = [
		{
			label: `${t('case.changeTrack')} 0`,
			value: '0',
		},
		{
			label: `${t('case.changeTrack')} 1`,
			value: '1',
		},
		{
			label: `${t('case.changeTrack')} 2`,
			value: '2',
		},
		{
			label: `${t('case.changeTrack')} 3`,
			value: '3',
		},
		{
			label: `${t('case.changeTrack')} 4`,
			value: '4',
		},
		{
			label: `${t('case.changeTrack')} 5`,
			value: '5',
		},
		{
			label: `${t('case.changeTrack')} 6`,
			value: '6',
		},
		{
			label: `${t('case.changeTrack')} 7`,
			value: '7',
		},
		{
			label: `${t('case.changeTrack')} 8`,
			value: '8',
		},
		{
			label: `${t('case.changeTrack')} 9`,
			value: '9',
		},
		{
			label: `${t('case.changeTrack')} 10`,
			value: '10',
		},
	];

	const { handleSubmit, register } = useForm<ITrack>({
		resolver: yupResolver(TrackSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const onSubmit = async (data: ITrack) => {
		try {
			await updateCase({
				variables: {
					id: caseId,
					track: data.track,
				},
			});
		} catch (e) {
			console.log(e);
		}
		close();
	};

	return (
		<Modal
			title="case.changeTrack"
			visible={open}
			close={close}
			size={ModalSize.SMALL}
			body={
				<form onSubmit={handleSubmit(onSubmit)} className="text-sm">
					<Dropdown title="case.track" name="track" innerRef={register} data={trackOptions} required defaultValue={currentTrack} disableSortLabelAlphabetically />

					<div className="mt-3">
						<Button submit success text="case.changeTrack" loading={updateCaseSubmitting} />
					</div>
				</form>
			}
		/>
	);
};

export default ChangeTrack;
