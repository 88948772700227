import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle as faQuistionCircleFull } from '@fortawesome/pro-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import TextEditor from '@ssg/common/Components/TextEditor';
import Input from '@ssg/common/Components/Input';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import 'react-quill/dist/quill.snow.css';

interface Props {
	description: string;
	register: UseFormMethods['register'];
	setValue: UseFormMethods['setValue'];
}

const CatalogDescriptionBox: React.FC<Props> = ({ description, register, setValue }) => {
	const [showHelpText, setShowHelpText] = React.useState(false);

	const handleChange = (content: string) => {
		setValue('description', content, { shouldValidate: true });
	};

	return (
		<div>
			<div className="flex">
				<FormFieldHeader title="common.description" />
				<div onClick={() => setShowHelpText(!showHelpText)} className="text-blue mt-1 flex h-8 w-8 cursor-pointer items-center justify-center">
					{showHelpText ? <FontAwesomeIcon icon={faQuistionCircleFull} size="lg" /> : <FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
				</div>
			</div>
			{showHelpText && <FormHelpText text="catalog.helpText.descriptionInfo" />}

			<TextEditor editorId="descriptionEditor" handleChange={handleChange} value={description} />
			<div className="mt-10">
				<Input name="description" defaultValue={description} innerRef={register} className="hidden" />
			</div>
			{/* </Box> */}
		</div>
	);
};

export default CatalogDescriptionBox;
