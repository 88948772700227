import React from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { countries } from '@ssg/common/Helpers/countries';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import {
	DebitorType,
	GetWebCase_case,
	GetWebDebitors,
	GetDebitorShipmentAddresses,
	GetDebitorShipmentAddressesVariables,
	GetWebDebitorsVariables,
	GetWebDebitors_debitors,
	GetCatalogCaseCreation,
} from '../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { postalCodeToCity } from '@ssg/common/Helpers/postalCodes';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { FullAddressObject, fullAddressRequest } from './AddressRequest';
import Box from '../../Components/Layout/Box';
import FormFieldHeader from '../../Components/Forms/FormFieldHeader';
import Checkbox from '@ssg/common/Components/Checkbox';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Radio from '@ssg/common/Components/Radio';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Dropdown from '@ssg/common/Components/Dropdown';

const GET_DEBITORS = loader('../../GraphQL/Debitors/GetWebDebitors.gql');
const GET_DEBITOR_SHIPMENT_ADDRESSES = loader('../../GraphQL/Debitors/GetDebitorShipmentAddresses.gql');

interface Props {
	caseData: GetWebCase_case | undefined;
	selectedDebitor: GetWebDebitors_debitors | undefined;
	setSelectedDebitor: React.Dispatch<React.SetStateAction<GetWebDebitors_debitors | undefined>>;
	catalogDebitor: GetCatalogCaseCreation['catalog']['debitor'] | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
	trigger: UseFormMethods['trigger'];
}

interface WatchProps extends Omit<Props, 'catalogDebitor' | 'selectedDebitor' | 'setSelectedDebitor'> {
	showHelpText: boolean;
}

const debitorTypes = enumToSelectOptions(DebitorType);

// Changes inputs based on debitor type
const CustomerTypeWatch: React.FC<WatchProps> = ({ caseData, register, errors, control, trigger, setValue }): React.ReactElement => {
	const customerType = useWatch({
		control,
		name: 'CustomerBox.debitorType',
		defaultValue: caseData?.debitor.type,
	});

	const noCVR = useWatch({
		control,
		name: 'CustomerBox.noCVR',
		defaultValue: typeof caseData !== 'undefined' && caseData.debitor.cvrNumber === '',
	});

	React.useEffect(() => {
		noCVR &&
			setValue('CustomerBox.requisitionerCVR', '', {
				shouldValidate: true,
			});
	}, [noCVR, setValue]);

	return (
		<>
			{customerType === 'BUSINESS' && (
				<>
					<Input
						title="case.companyRegistrationNumber"
						name="CustomerBox.requisitionerCVR"
						helpText="case.helpText.requisitionerCVR"
						required
						disabled={noCVR}
						innerRef={register}
						errorMessage={errors.CustomerBox?.requisitionerCVR?.message ?? ''}
						defaultValue={caseData?.debitor.cvrNumber}
					/>

					<Checkbox
						title="case.noCVR"
						name="CustomerBox.noCVR"
						className="mt-1"
						innerRef={register}
						onChange={() => trigger('CustomerBox.requisitionerCVR')}
						defaultChecked={typeof caseData !== 'undefined' && caseData.debitor.policeNumber === ''}
					/>
				</>
			)}
		</>
	);
};

const CustomerBox: React.FC<Props> = ({ caseData, selectedDebitor, setSelectedDebitor, catalogDebitor, register, errors, control, setValue, trigger }): React.ReactElement => {
	const { t } = useTranslation();

	const [country, setCountry] = React.useState(caseData?.debitor.billingAddress?.country ?? '');
	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === country)?.label ?? '');
	const [distinctItems, setDistinctItems] = React.useState<SelectOption[]>([]);
	const [loadingDistinctItems, setLoadingDistinctItems] = React.useState<boolean>(false);
	const [address] = React.useState<SelectOption>({
		label: caseData?.debitor.billingAddress?.road ?? '',
		value: caseData?.debitor.billingAddress?.road ?? '',
	});

	const [showHelpText, setShowHelpText] = React.useState(false);

	// Only one debitor is allowed on catalogs: Stefan 1/12
	const [companySearchText, setCompanySearchText] = useDebouncedState((caseData?.debitor.company || catalogDebitor?.debitorId) ?? undefined, 100);

	const noInsuranceNumber = useWatch({
		control,
		name: 'CustomerBox.noInsuranceNumber',
		defaultValue: typeof caseData !== 'undefined' && caseData.debitor.policeNumber === '',
	});

	React.useEffect(() => {
		noInsuranceNumber &&
			setValue('CustomerBox.requisitionerInsuranceNumber', '', {
				shouldValidate: true,
			});
	}, [noInsuranceNumber, setValue]);

	const countrySelectHandler = React.useCallback(
		(value: string): void => {
			setCountry(value);
			setValue('CustomerBox.insuranceInvoiceCountry', value, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	React.useEffect(() => {
		countrySelectHandler(country);
	}, [country, countrySelectHandler]);

	const { loading, data } = useQuery<GetWebDebitors, GetWebDebitorsVariables>(GET_DEBITORS, {
		variables: {
			searchText: companySearchText ?? '',
		},
		skip: typeof companySearchText === 'undefined',
	});

	const { data: addressesData } = useQuery<GetDebitorShipmentAddresses, GetDebitorShipmentAddressesVariables>(GET_DEBITOR_SHIPMENT_ADDRESSES, {
		variables: {
			id: selectedDebitor?.debitorId ?? '',
		},
		skip: typeof selectedDebitor === 'undefined',
	});

	const debitorShipmentAddresses = React.useMemo(
		() =>
			(addressesData?.debitorShipmentAddresses ?? []).map(
				(a): SelectOption => ({
					label: `${a.name} (${a.gln})`,
					value: a.id,
				}),
			),
		[addressesData?.debitorShipmentAddresses],
	);

	const searchSelectHandler = React.useCallback(
		(value: string): void => {
			const debitor = data?.debitors
				.find(d => d.debitorId === value);
			if (typeof debitor !== 'undefined') {
				setValue('CustomerBox.requisitionerInsuranceName', value, {
					shouldValidate: true,
				});
			}
			setSelectedDebitor(debitor);

			if (typeof debitor !== 'undefined') {
				if (debitor.type !== DebitorType.UNKNOWN) {
					setValue('CustomerBox.debitorType', debitor.type);
				}
			}
		},
		[data?.debitors, setSelectedDebitor, setValue],
	);

	const searchedDebitors = React.useMemo(() => {
		if (data?.debitors) {
			return data?.debitors
				.slice()
				.sort((a, b) => (a?.company ?? '').localeCompare(b?.company ?? ''))
				.map(
					(d): SelectOption => ({
						value: d.debitorId,
						label: d.company + (d.debitorId !== null ? ` (${d.debitorId})` : ''),
					}),
				);
		}
		return [];
	}, [data]);

	React.useEffect(() => {
		(caseData?.debitor.debitorId || catalogDebitor) && searchSelectHandler((caseData?.debitor.debitorId || (catalogDebitor && catalogDebitor.debitorId)) ?? '');
	}, [caseData?.debitor, catalogDebitor, searchSelectHandler]);

	const [fullAddresses, setFullAddresses] = React.useState<FullAddressObject[]>([]);

	const getFullAddresses = async (addressText: string) => {
		setLoadingDistinctItems(true);
		const addresses = await fullAddressRequest(addressText);
		setDistinctItems(addresses.map(a => ({ label: a.forslagstekst, value: a.data.id })));
		setFullAddresses(addresses);
		setLoadingDistinctItems(false);
	};

	return (
		<Box form title="case.customer" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.customer" />}

				<SearchableSelect
					key="label"
					control={control}
					name="CustomerBox.requisitionerInsuranceName"
					title="case.companyOrInsuranceCompany"
					helpText="case.helpText.companyOrInsuranceCompany"
					showHelpText={showHelpText}
					required
					options={searchedDebitors ?? []}
					searchFn={searchText => setCompanySearchText(searchText)}
					onSelect={value => searchSelectHandler(value)}
					onBlur={() => undefined}
					minInputLength={2}
					isLoading={loading}
					errorMessage={errors.CustomerBox?.requisitionerInsuranceName?.message ?? ''}
					initialSelection={
						typeof data?.debitors.find(d => d.debitorId === caseData?.debitor.debitorId || d.debitorId === (catalogDebitor && catalogDebitor.debitorId)) !== 'undefined'
							? {
								value: (caseData?.debitor.debitorId || (catalogDebitor && catalogDebitor.debitorId)) ?? '',
								label: (caseData?.debitor.company || (catalogDebitor && catalogDebitor.company)) ?? '',
							}
							: undefined
					}
				/>

				<FormFieldHeader title="common.selectedDebitor" />
				{selectedDebitor ? (
					<>
						<div>
							<div className="text-blue flex w-full flex-row">
								<div className="flex-1">
									<p>
										{selectedDebitor.company} ({selectedDebitor.debitorId})
									</p>
									<p>{selectedDebitor.address?.addressLine}</p>
									<p>{selectedDebitor.address?.addressLineAlt}</p>
									<p>
										{selectedDebitor.address?.postalCode} {selectedDebitor.address?.city}
									</p>
									{selectedDebitor.gln && <p>EAN: {selectedDebitor.gln}</p>}
									<p>{selectedDebitor.phone}</p>
									<p>{selectedDebitor.email}</p>
								</div>
								{debitorShipmentAddresses.length > 0 && (
									<div className="flex-1">
										<Dropdown
											title="case.debitorShipmentAddress"
											name="CustomerBox.shippingAddressReference"
											data={[
												{
													label: 'case.selectDebitorShipmentAddress',
													value: '',
												},
												...debitorShipmentAddresses,
											]}
											disableSortLabelAlphabetically
											innerRef={register}
											errorMessage={errors.CustomerBox?.shippingAddressReference?.message ?? ''}
											defaultValue={caseData?.debitor.shippingAddress?.id}
										/>
									</div>
								)}
							</div>
						</div>
					</>
				) : (
					<p>{t('case.chooseInsurance')}</p>
				)}
				<Radio
					title="case.customerType"
					required
					options={debitorTypes.filter(dt => dt.value !== DebitorType.UNKNOWN)}
					name="CustomerBox.debitorType"
					innerRef={register}
					errorMessage={errors.CustomerBox?.debitorType?.message ?? ''}
					helpText="case.helpText.customerType"
					showHelpText={showHelpText}
					className="pb-2"
					dValue={caseData?.debitor.type}
				/>

				<Input
					title="case.attentionOrDepartment"
					name="CustomerBox.requisitionerAttentionName"
					helpText="case.helpText.attentionOrDepartment"
					showHelpText={showHelpText}
					innerRef={register}
					errorMessage={errors.CustomerBox?.requisitionerAttentionName?.message ?? ''}
					defaultValue={caseData?.debitor.attention ?? ''}
				/>

				{selectedDebitor?.createInECB === true && (
					<Checkbox
						name="CustomerBox.createInECB"
						title="case.createInScalepoint"
						innerRef={register}
						defaultChecked={true}
						className="mt-4"
						helpText="case.helpText.createInECB"
						showHelpText={showHelpText}
					/>
				)}

				<div className="flex">
					<div className="w-5/6">
						<SearchableSelect
							control={control}
							name="CustomerBox.insuranceInvoiceRoad"
							title="common.road"
							helpText="case.helpText.customerAddress"
							showHelpText={showHelpText}
							options={[address, ...distinctItems]}
							addressSlice
							searchFn={searchText => {
								getFullAddresses(searchText);
							}}
							onSelect={value => {
								const fullAddress = fullAddresses.find(fa => fa.data.id === value);
								if (fullAddress) {
									const { city, floor, houseNumber, postalCode, road, door } = fullAddress.data;
									console.log(fullAddress);
									const doorFloor = door ? floor + '. ' + door : floor;
									setValue('CustomerBox.insuranceInvoiceRoad', road);
									setValue('CustomerBox.insuranceInvoiceHouseNumber', houseNumber);
									setValue('CustomerBox.insuranceInvoiceCity', city);
									setValue('CustomerBox.insuranceInvoiceFloor', doorFloor);
									setValue('CustomerBox.insuranceInvoiceZip', postalCode);
								}
							}}
							onBlur={() => undefined}
							minInputLength={2}
							isLoading={loadingDistinctItems}
							errorMessage={errors.CustomerBox?.insuranceInvoiceRoad?.message ?? ''}
							initialSelection={address.label !== '' ? address : undefined}
						/>
					</div>
					<div className="ml-4 w-1/6">
						<Input
							title="common.houseNumber"
							name="CustomerBox.insuranceInvoiceHouseNumber"
							innerRef={register}
							errorMessage={errors.CustomerBox?.insuranceInvoiceHouseNumber?.message ?? ''}
							defaultValue={caseData?.debitor.billingAddress?.houseNumber ?? ''}
						/>
					</div>
					<div className="ml-4 w-1/6">
						<Input
							title="common.floor"
							name="CustomerBox.insuranceInvoiceFloor"
							innerRef={register}
							errorMessage={errors.CustomerBox?.insuranceInvoiceFloor?.message ?? ''}
							defaultValue={caseData?.debitor.billingAddress?.floor ?? ''}
						/>
					</div>
				</div>

				<Input
					title="common.addressLine2"
					name="CustomerBox.insuranceInvoiceAddress2"
					helpText="case.helpText.customerAddress2"
					showHelpText={showHelpText}
					innerRef={register}
					errorMessage={errors.CustomerBox?.insuranceInvoiceAddress2?.message ?? ''}
					defaultValue={caseData?.debitor.billingAddress?.addressLineAlt ?? ''}
				/>

				<div className="flex">
					<div className="w-28">
						<Input
							title="common.postalcode"
							name="CustomerBox.insuranceInvoiceZip"
							helpText="case.helpText.customerZip"
							showHelpText={showHelpText}
							innerRef={register}
							errorMessage={errors.CustomerBox?.insuranceInvoiceZip?.message ?? ''}
							onBlur={async e => {
								const pc = e.currentTarget.value;

								const city = await postalCodeToCity(country, pc);
								if (typeof city !== 'undefined') {
									setValue('CustomerBox.insuranceInvoiceCity', city);
								}
							}}
							defaultValue={caseData?.debitor.billingAddress?.postalCode ?? ''}
						/>
					</div>
					<div className="flex-grow-default ml-4">
						<Input
							title="common.city"
							name="CustomerBox.insuranceInvoiceCity"
							helpText="case.helpText.customerCity"
							showHelpText={showHelpText}
							innerRef={register}
							errorMessage={errors.CustomerBox?.insuranceInvoiceCity?.message ?? ''}
							defaultValue={caseData?.debitor.billingAddress?.city ?? ''}
						/>
					</div>
				</div>

				<SearchableSelect
					key="country"
					control={control}
					name="CustomerBox.insuranceInvoiceCountry"
					title="common.country"
					helpText="case.helpText.customerCountry"
					showHelpText={showHelpText}
					options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
					searchFn={searchText => setCountrySearchText(searchText)}
					onSelect={value => countrySelectHandler(value)}
					onBlur={() => undefined}
					minInputLength={1}
					isLoading={loading}
					errorMessage={errors.CustomerBox?.insuranceInvoiceCountry?.message ?? ''}
					initialSelection={countries.find(c => c.value === country)}
				/>

				<Input
					title="case.policyOrClaimNumber"
					required
					name="CustomerBox.requisitionerInsuranceNumber"
					disabled={noInsuranceNumber}
					helpText="case.helpText.policyOrClaimNumber"
					showHelpText={showHelpText}
					innerRef={register}
					errorMessage={errors.CustomerBox?.requisitionerInsuranceNumber?.message ?? ''}
					defaultValue={caseData?.debitor.policeNumber ?? ''}
				/>

				<Checkbox
					title="case.noPolicyNumber"
					name="CustomerBox.noInsuranceNumber"
					className="mt-1"
					innerRef={register}
					onChange={() => trigger('CustomerBox.requisitionerInsuranceNumber')}
					defaultChecked={typeof caseData !== 'undefined' && caseData.debitor.policeNumber === ''}
				/>

				<CustomerTypeWatch control={control} register={register} errors={errors} setValue={setValue} showHelpText={showHelpText} trigger={trigger} caseData={caseData} />
			</div>
		</Box>
	);
};

export default CustomerBox;
