import { DrivingSlipStatus, InvoiceStatus, GetOperationManagerCaseData_cases } from '../../GraphQL';

interface CaseValidationProperties {
	machineOnCase: boolean;
	openTimeRegistrations: boolean;
	openDrivingSlips: boolean;
	openMovables: boolean;
	activeMovables: boolean;
	activePurchases: boolean;
	activeInvoiceOnCase: boolean;
	invalidForClose: boolean;
}

export const isCaseValidToClose = (caseObj: GetOperationManagerCaseData_cases, activePurchaseDocumentsFlag: boolean): CaseValidationProperties => {
	const { machineOnCase, drivingSlipSeries, openTimeRegistrations, openMovables, activeMovables, activeRequisitions, activeInvoiceStatus, activePurchaseDocuments } = caseObj;
	const checkActiveInvoiceOnCase = (invoiceStatus: InvoiceStatus | null): boolean => {
		switch (invoiceStatus) {
			case InvoiceStatus.AWAITING_APPROVAL:
				return true;
			case InvoiceStatus.APPROVED:
				return true;
			case InvoiceStatus.REJECTED:
				return true;
			case InvoiceStatus.POSTED:
				return false;
			case InvoiceStatus.DRAFT:
				return true;
			default:
				return false;
		}
	};

	const openDrivingSlips =
		drivingSlipSeries
			.flatMap(dss => dss.drivingSlips)
			.filter(d => d.status !== DrivingSlipStatus.COMPLETED && d.status !== DrivingSlipStatus.OBSOLETE && d.status !== DrivingSlipStatus.CONVERTED_TO_REQUISITION).length > 0;

	const activeInvoiceOnCase = checkActiveInvoiceOnCase(activeInvoiceStatus);
	const activePurchases = activeRequisitions || (activePurchaseDocuments && activePurchaseDocumentsFlag);
	const blockCloseCase = machineOnCase || openTimeRegistrations || openDrivingSlips || openMovables || activeMovables || activePurchases || activeInvoiceOnCase;
	const caseValidForClose: CaseValidationProperties = {
		machineOnCase,
		openTimeRegistrations,
		openDrivingSlips,
		openMovables,
		activeMovables,
		activePurchases,
		activeInvoiceOnCase,
		invalidForClose: blockCloseCase,
	};

	return caseValidForClose;
};

