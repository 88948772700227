import React, { useContext } from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { faArrowAltDown } from '@fortawesome/pro-regular-svg-icons';
import { CollectionItemType, GetWebCase_case, GetCollectionItems, GetWebDebitors_debitors } from '../../GraphQL';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Box from '../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import InputPhone from '@ssg/common/Components/InputPhone';
import Checkbox from '@ssg/common/Components/Checkbox';
import TextButton from '@ssg/common/Components/TextButton';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	caseData: GetWebCase_case | undefined;
	selectedDebitor: GetWebDebitors_debitors | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	trigger: UseFormMethods['trigger'];
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
	getValues: UseFormMethods['getValues'];
}

const RequsitionerInformationBox: React.FC<Props> = ({ caseData, selectedDebitor, register, errors, control, trigger, setValue, getValues }): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);
	const getAddressFromDebitorFlag = useFlag(FeatureFlagEnums.GET_ADDRESS_FROM_DEBITOR);

	const [showHelpText, setShowHelpText] = React.useState(false);
	const [phonenumber, setPhonenumber] = React.useState(caseData?.requisitioner.phone ?? '');
	const [country, setCountry] = React.useState<string>(envVar.defaultCountry ?? '');

	const phonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('RequisitionerInformationBox.requisitionerPhone', phonenumber, { shouldValidate: true });
			setPhonenumber(phonenumber);
		},
		[setValue],
	);

	const { data: collectionItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		variables: {
			type: CollectionItemType.DAMAGE_BOX_REQUESTER_RELATIONSHIP,
		},
	});

	const relationValues = React.useMemo(
		() =>
			collectionItems?.collectionItems.map((item): SelectOption => {
				return { label: item.name, value: item.name };
			}),
		[collectionItems],
	);

	React.useEffect(() => {
		phonenumber !== '' && phonenumberHandler(phonenumber);
	}, [phonenumber, phonenumberHandler]);

	const noEmail = useWatch({
		control,
		name: 'RequisitionerInformationBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.requisitioner.email === '',
	});

	React.useEffect(() => {
		noEmail &&
			setValue('RequisitionerInformationBox.requisitionerEmail', '', {
				shouldValidate: true,
			});
	}, [noEmail, setValue]);

	const transferValuesDamageAddressToRequisitioner = () => {
		setValue('RequisitionerInformationBox.requisitionerName', getValues('DamageAddressBox.damageName'), { shouldValidate: true });
		setValue('RequisitionerInformationBox.requisitionerEmail', getValues('DamageAddressBox.damageEmail'), { shouldValidate: true });

		phonenumberHandler(getValues('DamageAddressBox.damagePhone'));
	};

	const transferValuesSelectedDebitorToRequisitioner = async () => {
		if (selectedDebitor) {
			setValue('RequisitionerInformationBox.requisitionerName', selectedDebitor.company, { shouldValidate: true });
			setValue('RequisitionerInformationBox.requisitionerEmail', selectedDebitor.email, { shouldValidate: true });
			if (selectedDebitor.phone) {
				phonenumberHandler('+45' + selectedDebitor.phone);
			}
		}
	};

	return (
		<Box form title="case.requisitioner" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.requisitioner" />}
				<div className="flex w-full flex-row space-x-4">
					<TextButton onClick={() => transferValuesDamageAddressToRequisitioner()} text="case.sameAsDamage" icon={faArrowAltDown} className="mt-3" />
					{getAddressFromDebitorFlag && (
						<TextButton
							onClick={() => transferValuesSelectedDebitorToRequisitioner()}
							disabled={typeof selectedDebitor === 'undefined'}
							text="case.sameAsDebitor"
							icon={faArrowAltDown}
							className="mt-3 flex"
						/>
					)}
				</div>
				<Input
					title="common.name"
					required
					innerRef={register}
					name="RequisitionerInformationBox.requisitionerName"
					errorMessage={errors.RequisitionerInformationBox?.requisitionerName?.message ?? ''}
					helpText="case.helpText.requisitionerName"
					showHelpText={showHelpText}
					defaultValue={caseData?.requisitioner.name}
				/>

				<InputPhone
					title="common.phone"
					required
					innerRef={register}
					name="RequisitionerInformationBox.requisitionerPhone"
					errorMessage={errors.RequisitionerInformationBox?.requisitionerPhone?.message ?? ''}
					helpText="case.helpText.damagePhone"
					showHelpText={showHelpText}
					control={control}
					trigger={trigger}
					searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
					onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
					defaultCountry={country}
					defaultPhoneNumber={phonenumber}
				/>

				<Input
					title="common.email"
					innerRef={register}
					name="RequisitionerInformationBox.requisitionerEmail"
					disabled={noEmail}
					errorMessage={errors.RequisitionerInformationBox?.requisitionerEmail?.message ?? ''}
					helpText="case.helpText.requisitionerEmail"
					showHelpText={showHelpText}
					defaultValue={caseData?.requisitioner.email}
				/>

				<Checkbox
					title="case.noEmail"
					name="RequisitionerInformationBox.noEmail"
					className="mt-1"
					innerRef={register}
					onChange={() => {
						trigger('RequisitionerInformationBox.requisitionerEmail');
					}}
					defaultChecked={typeof caseData !== 'undefined' && caseData.requisitioner.email === ''}
				/>

				<Dropdown
					title="case.relation"
					name="RequisitionerInformationBox.requisitionerRelation"
					errorMessage={errors.RequisitionerInformationBox?.requisitionerRelation?.message ?? ''}
					helpText="case.helpText.requisitionerRelation"
					showHelpText={showHelpText}
					data={relationValues}
					innerRef={register}
					defaultValue={caseData?.requisitioner.relation}
				/>
			</div>
		</Box>
	);
};

export default RequsitionerInformationBox;
