import React from 'react';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useRejectedTimeTrackingsForUserQuery } from '@ssg/common/TimeRegistration/TimeRegHelpers';

interface Props {
	userId: string | undefined;
}

const RejectedTimeRegistrationWarningModal: React.FC<Props> = ({ userId }) => {
	const { t } = useTranslation();

	const RejectedTimeRegistrationWarningFlag = useFlag(FeatureFlagEnums.REJECTED_TIME_REGISTRATION_WARNING);
	
	const { rejectedTimeTrackingDataRaw } = useRejectedTimeTrackingsForUserQuery(userId ?? '', RejectedTimeRegistrationWarningFlag);

	const [showRejectedRegistrationWarningModal, setShowRejectedRegistrationWarningModal] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!RejectedTimeRegistrationWarningFlag) {
			return;
		}
		if (typeof rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser !== 'undefined') {
			const hasRejected = rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser.length > 0;
			const showRejectModalIfNotSubstitute = hasRejected;
			setShowRejectedRegistrationWarningModal(showRejectModalIfNotSubstitute);
		}
	}, [RejectedTimeRegistrationWarningFlag, rejectedTimeTrackingDataRaw]);

	return (
		<Modal
			size={ModalSize.SMALL}
			visible={showRejectedRegistrationWarningModal}
			close={() => setShowRejectedRegistrationWarningModal(false)}
			title="timeRegistration.rejectedTimeRegistrations"
			body={<p>{t('timeRegistration.rejectedTimeRegistrationWarningModal.pendingRejectedReigstrations')}</p>}
			footer={
				<div className="flex w-full flex-row justify-between">
					<Button
						text="Ok"
						disabled={false}
						success
						onClick={() => setShowRejectedRegistrationWarningModal(false)}
					/>
				</div>
			}
		/>
	);
};

export default RejectedTimeRegistrationWarningModal;
