import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faBuilding, faCalendarAlt, faCog, faCopy, faFilePlus, faShoppingCart, faStopwatch, faTruck, faWasher } from '@fortawesome/pro-regular-svg-icons';
import { useSpring, animated } from 'react-spring';
import { Permissions } from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import classNames from 'classnames';
import SideMenu from '@ssg/common/Components/SideMenu';
import MenuLink from '@ssg/common/Components/MenuLink';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useLocation } from 'react-router-dom';
import CaseSearchField from '../CaseSearchField';

const Main: React.FC = ({ children }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);
	const location = useLocation();
	const hideMenu = React.useMemo(() => location.pathname === '/plannerLocationScreen', [location.pathname]);
	const newPlannerAccessFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_ACCESS);
	const newPlannerKillOldPlannerFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_KILL_OLD_PLANNER);
	const caseSearchFieldFlag = useFlag(FeatureFlagEnums.CASE_SEARCH_FIELD);
	const getWindowWidth = window.innerWidth;

	const [menuCollapse, setMenuCollapse] = useStorageState(window.localStorage, 'menuCollapse', getWindowWidth <= 1024 ? true : false);


	const mainPaddingAnimation = useSpring({
		marginLeft: menuCollapse ? '4.5rem' : '15rem',
	});

	if (hideMenu) return <>{children}</>;
	return (
		<div className="flex min-h-screen flex-row overflow-hidden bg-white">
			<SideMenu menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
				{caseSearchFieldFlag &&
					<CaseSearchField menuCollapsed={menuCollapse} setMenuCollapse={setMenuCollapse} />
				}
				<RestrictedArea permissions={[Permissions.CASES_CREATE]}>
					<MenuLink to="/casecreation" text="common.caseCreateTitle" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faFilePlus} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.CASES_VIEW]}>
					<MenuLink to="/caseoverview" text="common.caseOverview" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faCopy} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.MOVABLES_VIEW]}>
					<MenuLink to="/movables" text="common.movable" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faArchive} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.MACHINES_VIEW]}>
					<MenuLink to="/machines" text="common.machines" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faWasher} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.TIME_TRACKING]}>
					<MenuLink to="/timeregistration" text="timeRegistration.overviewTitle" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faStopwatch} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.CATALOGS_VIEW]}>
					<MenuLink to="/catalogoverview" text="catalog.overviewTitle" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faBuilding} size="lg" />
					</MenuLink>
				</RestrictedArea>
				<RestrictedArea permissions={[Permissions.REQUISITION_VIEW]}>
					<MenuLink to="/requisitions" text="requisitions.overviewTitle" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faShoppingCart} size="lg" />
					</MenuLink>
				</RestrictedArea>

				<RestrictedArea permissions={[Permissions.DRIVING_SLIPS_VIEW]}>
					<MenuLink to="/drivingslipoverview" text="drivingSlips.overviewTitle" collapsed={menuCollapse}>
						<FontAwesomeIcon icon={faTruck} size="lg" />
					</MenuLink>
				</RestrictedArea>
				{!newPlannerKillOldPlannerFlag &&
					<RestrictedArea permissions={[Permissions.PLANNING_VIEW]}>
						<MenuLink to="/planner" text="planner.overviewTitle" collapsed={menuCollapse}>
							<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
						</MenuLink>
					</RestrictedArea>
				}
				{newPlannerAccessFlag &&
					<RestrictedArea permissions={[Permissions.PLANNING_VIEW]}>
						<MenuLink beta={!newPlannerKillOldPlannerFlag} to="/plannerpage" text="planner.overviewTitle" collapsed={menuCollapse}>
							<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
						</MenuLink>
					</RestrictedArea>
				}
				<RestrictedArea permissions={[Permissions.ADMINISTRATION_SITE_VIEW]}>
					{/* Change for real life */}
					<a href={envVar.siteUrlAdmin ?? ''} rel="noreferrer" target="_blank" className="group mb-10 flex flex-row items-center font-medium tracking-wide">
						<div className="flex w-10 justify-center">
							<div className="flex w-10 justify-center text-center">
								<FontAwesomeIcon icon={faCog} size="lg" />
							</div>
						</div>
						<div className="line-clamp-2 ml-6 text-base group-hover:underline">{t('toAdmin')}</div>
					</a>
				</RestrictedArea>
				{/* <MenuLink to="/gdpr" text="gdpr.overviewTitle" collapsed={menuCollapse}>
					<FontAwesomeIcon icon={faUserCog} size="lg" />
				</MenuLink> */}
			</SideMenu>
			<animated.main style={mainPaddingAnimation} className={classNames('relative w-full overflow-y-auto')}>
				{children}
			</animated.main>
		</div>
	);
};

export default Main;
