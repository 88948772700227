import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetWebCase_case_caseAgreements } from '../../../GraphQL';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

interface Props {
	caseAgreement: GetWebCase_case_caseAgreements;
}

const CaseAgreementItem: React.FC<Props> = ({ caseAgreement }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div className="flex w-full flex-col px-4 py-2  text-sm even:bg-gray-100">
			<div className="text-blue flex flex-row font-medium">
				{t('case.' + caseAgreement.category)} <span className="flex-grow-default text-right">{dateToDateTimeString(caseAgreement.timestamp)}</span>
			</div>
			<p className="text-blue text-xs">
				{t('common.by')} {caseAgreement.user.name}
			</p>
			<p className="whitespace-pre-wrap break-words">{caseAgreement.content}</p>
		</div>
	);
};

export default CaseAgreementItem;
