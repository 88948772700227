import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const DamageSchema = yup.object().shape({
	damageLocation: yup.string().required(),

	damageCause: yup.string().required().min(1),

	damageDate: yup.date().required(),
	//damageDate: yup.date().transform((val, original) => original === "" ? undefined : val),

	damageDescription: yup.string(),
});
