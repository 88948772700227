import React from 'react';
import Box from '../../Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import MovablesOverviewDownload from '../../Components/MovablesOverviewDownload';
import CaseSearch from './CaseSearch';

const MovableExportOverview: React.FC = () => {
	const [selectedCase, setSelectedCase] = React.useState<{
		id: string;
		erpNo: string;
	}>();

	return (
		<BoxContainer>
			<Box full>
				<CaseSearch setSelected={setSelectedCase} helpText="movable.selectCaseExport" closedFilter={false}>
					{typeof selectedCase !== 'undefined' && <MovablesOverviewDownload caseId={selectedCase.id} erpNo={selectedCase.erpNo} />}
				</CaseSearch>
			</Box>
		</BoxContainer>
	);
};
export default MovableExportOverview;
