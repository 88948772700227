import React from 'react';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { useCaseNo } from './useCaseNo';
//import { notNull } from '@ssg/common/Helpers/notNull';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import Button from '@ssg/common/Components/Button';
import Header from '@ssg/common/Components/Header';
import Box from '../../../Components/Layout/Box';
import CaseImageInput from './Inputs/CaseImageInput';
import Loading from '@ssg/common/Components/Loading';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import useCaseImages from './useCaseImages';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import { EsdhFileFragmentFragment, useCreateWebInspectionReportMutation, useGetCaseReportsAndDocumentsQuery } from '@ssg/common/GraphQL/indexV2';

interface Params {
	id: string;
}

const InspectionReport: React.FC = () => {
	const { id: caseId } = useParams<Params>();
	const caseNo = useCaseNo(caseId);
	const { t } = useTranslation();
	const history = useHistory();
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const { loading: caseReportsAndDocumentsLoading } = useGetCaseReportsAndDocumentsQuery({
		variables: {
			id: caseId,
		},
		skip: !caseId || caseId === '',
		onCompleted(data) {
			const formData = removeTypenameGeneric(data.case.caseReports?.inspectionReportFormData);
			if (formData) {
				if (formData.backgroundStory) setBackgroundStory(formData.backgroundStory);
				if (formData.observations) setObservations(formData.observations);
				if (formData.assessmentAndConclusion) setAssessmentAndConclusion(formData.assessmentAndConclusion);
				if (formData.suggestedActionPlan) setSuggestedActionPlan(formData.suggestedActionPlan);
				if (formData.comments) setComments(formData.comments);

				if (formData.facadeImage) {
					const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], data.case.documents)[0];
					if (sanitizedFacadeImage) {
						setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, data.case.documents));
					}
				}
				if (formData.floorplanImage) {
					const sanitizedFloorPlanImage = checkFilesExist([formData.floorplanImage], data.case.documents)[0];
					if (sanitizedFloorPlanImage) {
						setFloorplanImage(tryGetFile(sanitizedFloorPlanImage.fileName, data.case.documents));
					}
				}
				const sanitizedOtherImages = checkFilesExist(formData.otherImages ?? [], data.case.documents);
				if (sanitizedOtherImages.length > 0) {
					setOtherImages(
						sanitizedOtherImages.reduce((images: EsdhFileFragmentFragment[], otherImage) => {
							const file = tryGetFile(otherImage.fileName, data.case.documents);
							if (file) {
								images.push(file);
							}
							return images;
						}, []),
					);
				}
			}
		},
	});

	const { images } = useCaseImages(caseNo ?? '');

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${caseId}-inspection-${name}`, initialValue);

	// Form values
	const [backgroundStory, setBackgroundStory] = useLocalStorage('backgroundStory', '');
	const [observations, setObservations] = useLocalStorage('observations', '');
	const [assessmentAndConclusion, setAssessmentAndConclusion] = useLocalStorage('assessmentAndConclusion', '');
	const [suggestedActionPlan, setSuggestedActionPlan] = useLocalStorage('suggestedActionPlan', '');
	const [comments, setComments] = useLocalStorage('comments', '');
	const [facadeImage, setFacadeImage] = useLocalStorage<EsdhFileFragmentFragment | null>('facadeImage', null);
	const [floorplanImage, setFloorplanImage] = useLocalStorage<EsdhFileFragmentFragment | null>('floorplanImage', null);
	const [otherImages, setOtherImages] = useLocalStorage<EsdhFileFragmentFragment[]>('otherImages', []);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const [floorplanImageError, setFloorplanImageError] = React.useState(false);

	React.useEffect(() => {
		submitted && setFacadeImageError(facadeImage == null);
	}, [submitted, facadeImage]);
	React.useEffect(() => {
		submitted && setFloorplanImageError(floorplanImage == null);
	}, [submitted, floorplanImage]);

	// Setup mutation to create report
	const [createInspectionReport, { loading: submitting }] = useCreateWebInspectionReportMutation();

	async function submitReport(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setSubmitted(true);
		if (facadeImage == null) {
			setFacadeImageError(true);
		}
		if (floorplanImage == null) {
			setFloorplanImageError(true);
		}

		if (facadeImage == null || floorplanImage == null) {
			return;
		}

		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisFloorplanImage = images.find(image => image.name === floorplanImage.name) ?? floorplanImage;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);
		try {
			setLoadingType('CREATE');
			await createInspectionReport({
				variables: {
					caseId,
					formData: {
						backgroundStory,
						observations,
						assessmentAndConclusion,
						suggestedActionPlan,
						comments,
						facadeImage: fileToInput(thisFacadeImage),
						floorplanImage: fileToInput(thisFloorplanImage),
						otherImages: thisOtherImages.map(fileToInput),
					},
					onlySave: false,
				},
			});

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}

	React.useEffect(() => {
		setSaved(false);
	}, [backgroundStory,
		observations,
		assessmentAndConclusion,
		suggestedActionPlan,
		comments,
		facadeImage,
		floorplanImage,
		otherImages]);

	async function saveReport() {
		const thisFacadeImage = facadeImage ? images.find(image => image.name === facadeImage.name) ?? facadeImage : null;
		const thisFloorplanImage = floorplanImage ? images.find(image => image.name === floorplanImage.name) ?? floorplanImage : null;
		const thisOtherImages = otherImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);
		try {
			setLoadingType('SAVE');
			await createInspectionReport({
				variables: {
					caseId,
					formData: {
						backgroundStory,
						observations,
						assessmentAndConclusion,
						suggestedActionPlan,
						comments,
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						floorplanImage: thisFloorplanImage ? fileToInput(thisFloorplanImage) : null,
						otherImages: thisOtherImages.map(fileToInput),
					},
					onlySave: true,
				},
			});
			setSaved(true);

		} catch (e) {
			console.error(e);
		}
	}

	if (caseReportsAndDocumentsLoading) return <Loading />;

	return (
		<div>
			<Header title="reports.reportTypes.inspection" backButton />

			<form autoComplete="nope" className="space-y-8 px-10 pb-10 text-sm" onSubmit={submitReport}>
				<ReportStaticInfo caseId={caseId} />

				<Box full>
					{caseNo != null && (
						<>
							<CaseImageInput
								caseNo={caseNo}
								headerTitle="reports.facadeImage"
								title="reports.uploadFacadeImage"
								noSelectedImageText={t('reports.noFacadeImage')}
								selectedImagesNames={facadeImage !== null ? [facadeImage.name] : []}
								onImageSelect={setFacadeImage}
								onImageRemove={() => setFacadeImage(null)}
							/>
							{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
						</>
					)}

					<TextAreaInput label={t('reports.backgroundStory')} placeholder={t('reports.backgroundStoryPlaceholder')} value={backgroundStory} onChange={setBackgroundStory} />

					<TextAreaInput label={t('reports.observations')} placeholder={t('reports.observationsPlaceholder')} value={observations} onChange={setObservations} />

					<TextAreaInput
						label={t('reports.assessmentAndConclusion')}
						placeholder={t('reports.assessmentAndConclusionPlaceholder')}
						value={assessmentAndConclusion}
						onChange={setAssessmentAndConclusion}
					/>

					<TextAreaInput label={t('reports.suggestedActionPlan')} placeholder={t('reports.suggestedActionPlanPlaceholder')} value={suggestedActionPlan} onChange={setSuggestedActionPlan} />

					<TextAreaInput label={t('reports.comments')} placeholder={t('reports.commentsPlaceholder')} value={comments} onChange={setComments} />

					{caseNo != null && (
						<>
							<CaseImageInput
								headerTitle="reports.floorPlanImage"
								title="reports.uploadFloorplanImage"
								noSelectedImageText="reports.noFloorplanImage"
								caseNo={caseNo}
								selectedImagesNames={floorplanImage !== null ? [floorplanImage.name] : []}
								onImageSelect={setFloorplanImage}
								onImageRemove={() => setFloorplanImage(null)}
							/>
							{floorplanImageError && <FormErrorText text="reports.error.floorplanImage" />}
						</>
					)}

					{caseNo != null && (
						<CaseImageInput
							headerTitle="reports.extraImages"
							title="reports.uploadExtraImages"
							noSelectedImageText={t('reports.noExtraImages')}
							caseNo={caseNo}
							onImageSelect={image => setOtherImages(toggleArrayValue(otherImages, image, img => img.url))}
							selectedImagesNames={otherImages.map(oi => oi.name)}
						/>
					)}
				</Box>

				<div className="pl-4 flex-row flex space-x-2">
					<Button disabled={submitting} loading={submitting && loadingType === 'CREATE'} submit success text="reports.createReport" />
					{saveButtonReportsFlag &&
						<Button disabled={submitting} loading={submitting && loadingType === 'SAVE'} primary onClick={() => saveReport()} text={saved ? '' : 'reports.saveReport'} icon={saved ? faCheck : undefined} />
					}
				</div>
			</form>
		</div>
	);
};

export default InspectionReport;
