import React from 'react';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { GetWebCaseAdminData_damageCategories, GetWebCaseAdminData_damageCauses, GetWebCase_case } from '../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Box from '../../Components/Layout/Box';
import Textarea from '@ssg/common/Components/Textarea';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormHelpText from '@ssg/common/Components/FormHelpText';

interface Props {
	caseData: GetWebCase_case | undefined;
	categoryList: GetWebCaseAdminData_damageCategories[];
	causeList: GetWebCaseAdminData_damageCauses[];
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
}

const DamageBox: React.FC<Props> = ({ caseData, categoryList, causeList, register, errors, control, setValue }): React.ReactElement => {
	const [showHelpText, setShowHelpText] = React.useState(false);

	const damageLocation = useWatch({
		control,
		name: 'DamageBox.damageLocation',
		defaultValue: caseData?.damage.category.id ?? categoryList[0].id,
	});

	const filteredLocations: SelectOption[] | undefined = React.useMemo(() => {
		return categoryList.map(c => ({ value: c.id, label: c.name }));
	}, [categoryList]);

	const filteredCauses: SelectOption[] | undefined = React.useMemo(() => {
		return causeList.filter(a => a.category.id === damageLocation).map(c => ({ value: c.id, label: c.name }));
	}, [causeList, damageLocation]);

	React.useEffect(() => {
		if (filteredCauses.length > 0 && caseData?.damage.cause.id) {
			const duplicateVal = caseData?.damage.cause.id;
			const val = duplicateVal ?? filteredCauses[0].value;
			setValue('DamageBox.damageCause', val);
		}
	}, [caseData?.damage.cause.id, filteredCauses, setValue]);

	return (
		<Box form title="case.damage" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.damage" />}

				<Dropdown
					title="common.damageCategory"
					required
					name="DamageBox.damageLocation"
					errorMessage={errors.DamageBox?.damageLocation?.message ?? ''}
					helpText="case.helpText.damageLocation"
					showHelpText={showHelpText}
					data={[{ value: '', label: '' }, ...filteredLocations]}
					innerRef={register}
					defaultValue={caseData?.damage.category.id ?? ''}
				/>

				<Dropdown
					title="case.damageCause"
					required
					disabled={damageLocation === null}
					name="DamageBox.damageCause"
					errorMessage={errors.DamageBox?.damageCause?.message ?? ''}
					helpText="case.helpText.damageCause"
					showHelpText={showHelpText}
					data={[{ value: '', label: '' }, ...filteredCauses]}
					innerRef={register}
					defaultValue={caseData?.damage.cause.id ?? ''}
				/>

				<Datepicker
					title="case.damageDate"
					name="DamageBox.damageDate"
					required
					innerRef={register}
					errorMessage={errors.DamageBox?.damageDate?.message ?? ''}
					helpText="case.helpText.damageDate"
					showHelpText={showHelpText}
					defaultValue={caseData?.damage.date}
				/>

				<Textarea
					name="DamageBox.damageDescription"
					title="case.damageDescription"
					rows={3}
					innerRef={register}
					errorMessage={errors.DamageBox?.damageDescription?.message ?? ''}
					helpText="case.helpText.damageDescription"
					showHelpText={showHelpText}
					className="h-56"
					defaultValue={caseData?.damage.description}
				/>
			</div>
		</Box>
	);
};

export default DamageBox;
