import React from 'react';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useTranslation } from 'react-i18next';
import { GetWebDrivingSlips_drivingSlips_driver } from '../../GraphQL';

interface Props {
	open: boolean;
	close: () => void;
	data?: GetWebDrivingSlips_drivingSlips_driver;
}

const DrivingSlipDriverBox: React.FC<Props> = ({ open, close, data }) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={data ? data.name : 'common.pleaseWait'}
			visible={open}
			close={close}
			size={ModalSize.SMALL}
			body={
				<div className="text-blue">
					<p>
						<span className="font-semibold">{t('common.tlf')}:</span>{' '}
						{data?.phone ? (
							<a href={`tel:${data?.phone}`} className="underline">
								{data?.phone}
							</a>
						) : (
							t('common.notSpecified')
						)}
					</p>
					<p>
						<span className="font-semibold">{t('common.email')}:</span>{' '}
						{data?.email ? (
							<a href={`mailto:${data?.email}`} className="underline">
								{data?.email}
							</a>
						) : (
							t('common.notSpecified')
						)}
					</p>
					<p>
						<span className="font-semibold">{t('common.department')}:</span> {data?.department ?? t('common.notSpecified')}
					</p>
					<p>
						<span className="font-semibold">{t('common.jobFunction')}:</span> {data?.jobFunction ?? t('common.notSpecified')}
					</p>
				</div>
			}
		/>
	);
};

export default DrivingSlipDriverBox;
