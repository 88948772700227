import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	before: string | null;
	after: string | null;
}

const DrivingSlipFilesChangeLog: React.FC<IProps> = ({ before, after }) => {
	const { t } = useTranslation();

	return (
		<div>
			{before && (
				<div>
					<strong>
						{t('common.file')} {t('common.added')}:
					</strong>
					{before && <div>{before}</div>}
				</div>
			)}
			{after && (
				<div>
					<strong>
						{t('common.file')} {t('common.removed')}:
					</strong>
					{after && <div>{after}</div>}
				</div>
			)}
		</div>
	);
};

export default DrivingSlipFilesChangeLog;
