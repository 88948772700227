import React from 'react';
import ComponentToPrint from './ComponentToPrint';
import CaseSearch from './CaseSearch';
import { useTranslation } from 'react-i18next';

const CreateCaseLabel: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const [selectedCase, setSelectedCase] = React.useState<{
		id: string;
		erpNo: string;
	}>();

	return (
		<CaseSearch setSelected={setSelectedCase} helpText="movable.selectCaseLabel" closedFilter={true}>
			{typeof selectedCase !== 'undefined' && (
				<div className="flex flex-row justify-between px-4">
					{(['BOX', 'OTHER'] as ('BOX' | 'OTHER')[]).map(type => (
						<div key={type} className="space-y-2">
							<p className="text-blue font-medium">{t(`movable.labels.${type === 'BOX' ? 'box' : 'other'}`)}</p>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-yellow w-full border-transparent px-4 py-1 text-white"
								qrColor="#FCD361"
								qrColorText="#FFFFFF"
								qrText="movable.labels.gdprValuables"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-red-calm w-full border-transparent px-4 py-1 text-white"
								qrColor="#D34E65"
								qrColorText="#FFFFFF"
								qrText="movable.labels.discarded"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-green w-full border-transparent px-4 py-1 text-white"
								qrColor="#52D28F"
								qrColorText="#FFFFFF"
								qrText="movable.labels.storage"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 w-full border-gray-300 bg-white px-4 py-1 text-black"
								qrColor="#FFFFFF"
								qrColorText="#000000"
								qrText="movable.labels.washing"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-blue-lightcalm w-full border-transparent px-4 py-1 text-white"
								qrColor="#64B2FF"
								qrColorText="#FFFFFF"
								qrText="movable.labels.cleaning"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-purple-calm w-full border-transparent px-4 py-1 text-white"
								qrColor="#BA5BEF"
								qrColorText="#FFFFFF"
								qrText="movable.labels.woodworker"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 bg-orange-calm w-full border-transparent px-4 py-1 text-white"
								qrColor="#EB9459"
								qrColorText="#FFFFFF"
								qrText="movable.labels.clothesCleaning"
							/>

							<ComponentToPrint
								caseId={selectedCase.id}
								type={type}
								className="border-1 w-full border-transparent bg-black px-4 py-1 text-white"
								qrColor="#000000"
								qrColorText="#FFFFFF"
								qrText="common.subcontractor"
							/>
						</div>
					))}
				</div>
			)}
		</CaseSearch>
	);
};

export default CreateCaseLabel;
