import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GetWebRequisitions, GetWebRequisitionsVariables, RequisitionType } from '../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { getCurrentOffset, parseJSONWithDates } from '../../helper';
import { GraphQLExtensionsData } from '@ssg/common/Components/GraphQLExtensionsContext';
import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Box from '../../Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import RequisitionsOverviewFilter from './RequisitionsOverviewFilter';
import Button from '@ssg/common/Components/Button';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';

const FETCH_LIMIT = 20;

interface Props {
	damageCategoryOptions: SelectOption[];
	departmentOptions: SelectOption[];
	damageCauseOptions: SelectOption[];
	setRequisitionVariables: React.Dispatch<React.SetStateAction<GetWebRequisitionsVariables | undefined>>;
}

export enum RequisitionStatus {
	CLOSED = 'CLOSED',
	OPEN = 'OPEN',
}

export interface RequisitionsFilter {
	myRequisitionsOnly: boolean;
	departments?: string[];
	caseId?: string;
	vendor?: string;
	status?: RequisitionStatus;
	damageCategories?: string[];
	damageCauses?: string[];
	type?: RequisitionType;
	fromDate?: Date;
	toDate?: Date;
}

const GET_REQUISITIONS = loader('src/GraphQL/Requisitions/GetWebRequisitions.gql');

const RequisitionsOverview: React.FC<Props> = ({ damageCategoryOptions, departmentOptions, damageCauseOptions, setRequisitionVariables }): React.ReactElement => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useDebouncedState('', 400);

	const [activeFilter, setActiveFilter] = useStorageState<RequisitionsFilter>(
		window.sessionStorage,
		'activeRequisitionFilters',
		{
			myRequisitionsOnly: true,
			departments: undefined,
			caseId: undefined,
			vendor: undefined,
			status: undefined,
			damageCategories: undefined,
			damageCauses: undefined,
			type: undefined,
			fromDate: undefined,
			toDate: undefined,
		},
		parseJSONWithDates,
	);

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const {
		data,
		loading: requisitionsLoading,
		fetchMore,
	} = useQuery<GetWebRequisitions, GetWebRequisitionsVariables>(GET_REQUISITIONS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			myRequisitionsOnly: activeFilter.myRequisitionsOnly,
			departments: activeFilter.departments,
			caseId: activeFilter.caseId,
			vendor: activeFilter.vendor,
			//status: activeFilter.status, // BC-derived
			damageCategories: activeFilter.damageCategories,
			damageCauses: activeFilter.damageCauses,
			type: activeFilter.type,
			fromDate: activeFilter.fromDate == null ? null : dateToDateOnlyString(activeFilter.fromDate),
			toDate: activeFilter.toDate == null ? null : dateToDateOnlyString(activeFilter.toDate),
			offset: 0,
			limit: FETCH_LIMIT,
			searchString: searchTerm.length === 0 ? null : searchTerm,
		},
	});

	const [offset, setOffset] = React.useState(getCurrentOffset((data?.requisitions ?? []).length, FETCH_LIMIT));

	// Used for updating cache in create req. modal
	React.useEffect(() => {
		setRequisitionVariables({
			...activeFilter,
			fromDate: activeFilter.fromDate == null ? null : dateToDateOnlyString(activeFilter.fromDate),
			toDate: activeFilter.toDate == null ? null : dateToDateOnlyString(activeFilter.toDate),
			offset: offset,
			limit: FETCH_LIMIT,
			searchString: searchTerm.length === 0 ? null : searchTerm,
		});
	}, [activeFilter, offset, searchTerm, setRequisitionVariables]);

	const requisitions = React.useMemo(() => {
		const requisitions = data?.requisitions ?? [];

		if (typeof activeFilter.status === 'undefined') {
			return requisitions;
		}

		// This is entirely stupid, but not removing.
		return requisitions.filter(s => (activeFilter.status === RequisitionStatus.OPEN ? s.status === true : s.status === false));
	}, [activeFilter, data?.requisitions]);

	const requisitionsTotalCountName = 'requisitionsTotalCount';
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const totalRequisitions = React.useMemo(() => (graphQLExtensionsData[requisitionsTotalCountName] as number | undefined | null) ?? 0, [graphQLExtensionsData]);

	return (
		<BoxContainer>
			<Box full>
				<RequisitionsOverviewFilter
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
					setSearchTerm={setSearchTerm}
					damageCategoryOptions={damageCategoryOptions}
					departmentOptions={departmentOptions}
					damageCauseOptions={damageCauseOptions}
				/>

				<GraphQLExtensionsElement
					name={requisitionsTotalCountName}
					render={value => (
						<TotalCount totalCount={value as number | undefined | null} loading={requisitionsLoading} quantityText={t('common.quantity')} entityText={t('requisitions.overviewTitle')} />
					)}
				/>

				<Table
					data={requisitions}
					keySelector={r => r.id}
					noDataFoundText={'requisitions.noRequisitions'}
					loading={requisitionsLoading}
					columns={[
						{
							label: 'requisitions.requisitionId',
							selectFn: r => r.orderNumber ?? '',
							sortFn: (a, b) => (a.orderNumber ?? '').localeCompare(b.orderNumber ?? ''),
							numeric: true,
						},
						{
							label: 'common.status',
							selectFn: r => <p>{r.status ? t('requisitions.status.OPEN') : t('requisitions.status.CLOSED')}</p>,
						},
						{
							label: 'common.case',
							selectFn: r =>
								r.case ? (
									<Link to={`/case/${r.case?.id}`} target="_blank" className="hover:underline">
										{r.case?.erpNo}
									</Link>
								) : (
									''
								),
							sortFn: (a, b) => (a.case?.erpNo ?? '').localeCompare(b.case?.erpNo ?? ''),
						},
						{
							label: 'common.damageAddress',
							selectFn: r => (
								<p>
									{r.case?.damage.contact.address?.road} {r.case?.damage.contact.address.houseNumber} {r.case?.damage.contact.address.floor} <br />{' '}
									{r.case?.damage.contact.address?.postalCode} {r.case?.damage.contact.address?.city}
								</p>
							),
							sortFn: (a, b) => (a.case?.damage.contact.address?.postalCode ?? '').localeCompare(b.case?.damage.contact.address?.postalCode ?? ''),
						},
						{
							label: `${t('common.damageCategory')} ~ ${t('case.damageCause')}`,
							selectFn: c => (
								<>
									<p>{c.case?.damage.category.name}</p>
									<p>{c.case?.damage.cause.name}</p>
								</>
							),
							sortFn: (a, b) => (a.case?.damage.cause.name ?? '').localeCompare(b.case?.damage.cause.name ?? ''),
						},
						{
							label: 'common.drivingSlip',
							selectFn: r => <p>{r.drivingSlip ? t('common.yes') : t('common.no')}</p>,
							sortFn: (a, b) => (a.drivingSlip ? 'yes' : 'no').localeCompare(b.drivingSlip ? 'yes' : 'no'),
							align: 'CENTER',
						},
						{
							label: 'common.department',
							selectFn: r => r.department?.name ?? '',
							sortFn: (a, b) => (a.department?.name ?? '').localeCompare(b.department?.name ?? ''),
						},
						{
							label: 'common.subcontractor',
							selectFn: r => {
								return (
									<div>
										<p className="font-semibold">{r.vendorName ?? r.vendor?.company}</p>
										<p>
											<a href={`mailto:${r.vendorEmail ?? r.vendor?.email}`} className="hover:underline">
												{r.vendorEmail ?? r.vendor?.email}
											</a>
										</p>
										<p>
											<a href={`tel:${r.vendorPhoneNumber ?? r.vendor?.phone}`} className="hover:underline">
												{r.vendorPhoneNumber ?? r.vendor?.phone}
											</a>
										</p>
									</div>
								);
							},
							sortFn: (a, b) => (a.vendor?.company ?? a.vendorName).localeCompare(b.vendor?.company ?? b.vendorName),
						},
						{
							label: 'common.description',
							selectFn: r => <div className="whitespace-normal">{r.description}</div>,
						},
						{
							label: 'common.type',
							selectFn: r => <div>{t(`requisitions.${r.type}`)}</div>,
							sortFn: (a, b) => a.type.localeCompare(b.type),
						},
						{
							label: 'common.created',
							selectFn: r => dateToDateTimeString(r.createdAt),
							sortFn: (a, b) => a.createdAt.localeCompare(b.createdAt),
						},
						{
							label: 'common.createdBy',
							selectFn: r => (
								<div>
									<p className="font-semibold">{r.createdBy.name}</p>
									<p>
										<a href={`mailto:${r.createdBy.email}`} className="hover:underline">
											{r.createdBy.email}
										</a>
									</p>
									<p>
										<a href={`tel:${r.createdBy.phone}`} className="hover:underline">
											{r.createdBy.phone}
										</a>
									</p>
								</div>
							),
							sortFn: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
						},
					]}
				/>

				{typeof requisitions !== 'undefined' && totalRequisitions > requisitions.length && (
					<Button
						fullWidth
						text="common.getMore"
						secondary
						loading={fetchMoreLoading}
						className="mt-2"
						onClick={async () => {
							setOffset(requisitions.length);
							setFetchMoreLoading(true);
							await fetchMore({
								variables: {
									offset: requisitions.length,
								},
							});
							setFetchMoreLoading(false);
						}}
					/>
				)}
			</Box>
		</BoxContainer>
	);
};

export default RequisitionsOverview;
