import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

declare module 'yup' {
	export interface StringSchema {
		phone(message?: string): StringSchema;
	}
}

yup.addMethod(yup.string, 'phone', function (message?: string) {
	// eslint-disable-next-line no-template-curly-in-string
	const invalidMsg = message ?? 'errors:phone';
	return this.test('phone', invalidMsg, (value: string | undefined = '') => value.length === 0 || isPossiblePhoneNumber(value));
});

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const ReferrerSchema = yup.object().shape(
	{
		name: yup.string().when(['phone', 'email'], {
			is: (phone: string | undefined, email: string | undefined) => (phone ?? '') !== '' || (email ?? '') !== '',
			then: yup.string().required('error.referrerMissingName'),
			otherwise: yup.string(),
		}),

		// phone: yup.string().when(
		//     ['name','email'],{
		//         is: (name: string | undefined, email:string | undefined) => ((name ?? '') !== '' || (email ?? '') !== ''),
		//         then: yup.string().required("error.referrerMissingPhone"),
		//         otherwise: yup.string()
		//     },
		// ),

		phone: yup.string(),

		email: yup.string().when(['name', 'phone', 'noEmail'], {
			is: (name: string | undefined, phone: string | undefined, noEmail: boolean) => ((name ?? '') !== '' || (phone ?? '') !== '') && !noEmail,
			then: yup.string().email().required('error.referrerMissingEmail'),
			otherwise: yup.string().email(),
		}),

		noEmail: yup.boolean(),

		companyName: yup.string(),

		companyPhone: yup.string(),
	},
	[
		['phone', 'email'],
		['name', 'email'],
		['name', 'phone'],
	],
);
