import React from 'react';
import { RestrictedAreaProvider } from './Components/RestrictedArea';

interface UserContextValues {
	user: any;
	loading: boolean;
}

const UserContext = React.createContext<UserContextValues>({
	user: null,
	loading: false,
});

export const UserContextProvider: React.FC = ({ children }) => {
	const context: UserContextValues = {
		user: null,
		loading: false,
	};

	return (
		<UserContext.Provider value={context}>
			<RestrictedAreaProvider value={context.user?.permissions ?? []}>{children}</RestrictedAreaProvider>
		</UserContext.Provider>
	);
};

export default UserContext;
