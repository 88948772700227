import Modal from '@ssg/common/Components/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IUpdateEvent } from '../../Schemas/IUpdateEvent';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateEventSchema } from '../../Schemas/UpdateEventSchema';
import { CalendarEventType, EventFragment } from '@ssg/common/GraphQL/indexV2';
import { eventTypes, useCalendarEvents, usePlannerAdminData, usePlannerDrivingSlips } from './PlannerHelpers';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Dropdown from '@ssg/common/Components/Dropdown';
import Datepicker from '@ssg/common/Components/Datepicker';
import { DateTime } from 'luxon';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';

export type EventAction = 'EDIT' | 'COPY';
interface Props {
	userId: string;
	nameUser: string;
	event: EventFragment;
	action: EventAction;
	visible: boolean;
	close: () => unknown;
}

const EventActionModal: React.FC<Props> = ({ userId, nameUser, event, action, visible, close }): React.ReactElement => {

	const { updateUserEventDeciderAndPost, updateUserEventDeciderAndPostLoading, postDeleteCalendarEvent, postDeleteCalendarEventLoading } = useCalendarEvents();
	const { copyDrivingSlip, copyDrivingSlipSubmitting } = usePlannerDrivingSlips();
	const { adminData: { usersWithCalendars } } = usePlannerAdminData();

	const [employeeSearchText, setEmployeeSearchText] = React.useState(nameUser);

	const { handleSubmit, control, register, errors, setValue } = useForm<IUpdateEvent>({
		resolver: yupResolver(UpdateEventSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			userId: action === 'EDIT' ? userId : '',
			type: event.type,
		},
	});

	const [searchedUsers, setSearchedUsers] = React.useState<SelectOption[]>(
		usersWithCalendars.filter(s => employeeSearchText.length === 0 || s.name.toLowerCase().includes(employeeSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
	);
	const searchUserSelectHandler = (value: string) => setValue('userId', value, { shouldValidate: true });

	React.useEffect(() => {
		setSearchedUsers(
			usersWithCalendars.filter(s => employeeSearchText.length === 0 || s.name.toLowerCase().includes(employeeSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
		);
	}, [employeeSearchText, usersWithCalendars]);

	const onSubmit = (drivingSlipId: string | undefined) => async (data: IUpdateEvent) => {
		switch (action) {
			case 'COPY':
				if (event.drivingSlip === null) {
					return;
				}
				const start = DateTime.fromISO(data.fromDate).toUTC();
				const end = DateTime.fromISO(data.toDate).toUTC();
				const diffInHours = end.diff(start, 'hours').hours;
				const isNewDriver = event.drivingSlip.driver?.id !== data.userId;
				await copyDrivingSlip({

					drivingSlip:
					{
						input: {
							car: isNewDriver ? null : event.drivingSlip.car?.vehicleNumber,
							materials: isNewDriver ? null : event.drivingSlip.materials.map(m => m.vehicleNumber),
							series: event.drivingSlip.series,
							driver: data.userId,
							start: start.toISO(),
							end: end.toISO(),
							estimatedHours: diffInHours,
							urgent: event.drivingSlip.urgent ?? false,
							comment: event.drivingSlip?.comment,
							deadline: DateTime.fromISO(data.deadline).toUTC().toISO(),
							location: event.drivingSlip?.location.id ?? '',
							department: event.drivingSlip?.department.id ?? '',
						},
						additionalDays: [],
						additionalDrivers: [],
						driverCars: [],
						immediatelyPlan: true,
					},
					caseId: event.drivingSlip.case.id ?? 'error',
				},
				);
				close();
				break;
			case 'EDIT':
				const fromDate = DateTime.fromISO(data.fromDate);
				const toDate = DateTime.fromISO(data.toDate).plus({ days: event.isAllDay ? 1 : 0 });
				await updateUserEventDeciderAndPost(
					event.referenceId,
					{
						drivingSlip: event.drivingSlip?.id,
						title: data.title,
						type: data.type as CalendarEventType,
						timeRange: {
							from: event.isAllDay ? fromDate.toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\'') : fromDate.toUTC().toISO(),
							to: event.isAllDay ? toDate.toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\'') : toDate.toUTC().toISO(),
						},
						isAllDay: event.isAllDay,
					},
					data.userId,
					userId,
				);
				close();
				break;
		}

	};
	const defaultToDate = React.useMemo(() => {
		return event.isAllDay ? DateTime.fromISO(event.timeRange.to).minus({ minutes: 1 }).toISODate() : DateTime.fromISO(event.timeRange.to.replace('Z', '') + 'Z').toISO({
			includeOffset: false,
		});

	}, [event]);

	return (
		<Modal
			visible={visible}
			close={close}
			title={action === 'COPY' ? 'planner.copyEvent' : 'planner.editEvent'}
			body={
				<div className="select-none">
					<form onSubmit={handleSubmit(onSubmit(event.drivingSlip?.id))}>

						<SearchableSelect
							control={control}
							name="userId"
							required
							title="planner.sanitizer"
							key="label"
							options={searchedUsers}
							searchFn={searchText => setEmployeeSearchText(searchText)}
							onSelect={value => searchUserSelectHandler(value)}
							onBlur={() => undefined}
							minInputLength={0}
							isLoading={false}
							errorMessage={errors.userId?.message ?? ''}
							initialSelection={
								action === 'EDIT'
									? {
										value: userId,
										label: nameUser,
									}
									: undefined
							}
						/>



						<Dropdown
							data={
								event.type === CalendarEventType.DrivingSlip
									? [
										{
											label: 'common.drivingSlip',
											value: CalendarEventType.DrivingSlip,
										},
									]
									: eventTypes
							}
							name="type"
							title="common.category"
							//readonly={event.type === CalendarEventType.DrivingSlip}
							innerRef={register}
							required
							errorMessage={errors.type?.message}
							defaultValue={event.type}
						/>

						<Input name="title" title="common.title" innerRef={register} readOnly={event.type === CalendarEventType.DrivingSlip} required errorMessage={errors.title?.message} defaultValue={event.title} />

						<Datepicker
							time={!event.isAllDay}
							name="fromDate"
							title="common.start"
							// defaultValue={() => 
							// 	const date = DateTime.fromISO(event.timeRange.from.replace('Z', '') + 'Z')
							// 	.toISO({
							// 		includeOffset: false,
							// 	})
							// }
							innerRef={register}
							defaultValue={formatDateForInput(new Date(event.timeRange.from.replace('Z', '') + 'Z'), !event.isAllDay)}
							required
							errorMessage={errors.fromDate?.message}
						/>

						<Datepicker
							time={!event.isAllDay}
							name="toDate"
							title="common.end"
							// defaultValue={DateTime.fromISO(event.timeRange.to.replace('Z', '') + 'Z')
							// 	.toISO({
							// 		includeOffset: false,
							// 	})}
							defaultValue={defaultToDate}
							innerRef={register}
							required
							errorMessage={errors.toDate?.message}
						/>

						<Datepicker
							name="deadline"
							title="common.deadline"
							defaultValue={formatDateForInput(new Date(event.drivingSlip?.deadline ?? ''))}
							innerRef={register}
							errorMessage={errors.deadline?.message}
							className={event.type !== CalendarEventType.DrivingSlip || action !== 'COPY' ? 'hidden' : ''}
							titleClass={event.type !== CalendarEventType.DrivingSlip || action !== 'COPY' ? 'hidden' : ''}
						/>
						<div className="mt-3 flex flex-row justify-between">
							{action === 'EDIT'
								?
								<>
									<Button
										submit
										text="planner.editEvent"
										success
										className="mt-3"
										loading={updateUserEventDeciderAndPostLoading}
									/>

									<Button
										text="planner.deleteEvent"
										danger
										onClick={async () => {
											await postDeleteCalendarEvent({ eventId: event.referenceId, userId, drivingSlipId: event.drivingSlip?.id });
											close();
										}}
										className="mt-3"
										loading={postDeleteCalendarEventLoading}
									/>
								</>
								:
								<Button
									submit
									text="planner.copyEvent"
									success
									className="mt-3"
									loading={copyDrivingSlipSubmitting}
								/>
							}
						</div>
					</form>
				</div>
			}
		/>
	);
};

export default EventActionModal;