const map: Record<string, Record<string, string>> = {};

const postalCodeToCity = async (country: string, postalCode: string): Promise<string | undefined> => {
	if (!(country in map)) {
		if (country === 'DK') {
			await import('../Assets/postalcodes/DK.json').then(m => {
				map['DK'] = m.default;
			});
		} else if (country === 'NO') {
			await import('../Assets/postalcodes/NO.json').then(m => {
				map['NO'] = m.default;
			});
		} else if (country === 'SE') {
			await import('../Assets/postalcodes/SE.json').then(m => {
				map['SE'] = m.default;
			});
		}
	}

	if (country in map) {
		return map[country][postalCode];
	}

	return undefined;
};

const resolvePostalCodes = async (country: string, postalCodes: string[]): Promise<string[] | undefined> => {
	const resolvedPostalCodesList: string[] = [];

	if (country === 'DK') {
		await import('../Assets/postalcodes/DK.json').then(m => {
			const postalCodesList = Object.keys(m.default);
			const postalCodesListAsNumbers = postalCodesList.map(p => parseInt(p));

			postalCodes.forEach(p => {
				if (p.includes('-')) {
					const splitP = p.split('-');
					const postalCodesBetweenP = postalCodesListAsNumbers.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString());
					resolvedPostalCodesList.push(...postalCodesBetweenP);
				} else {
					postalCodesList.includes(p) && resolvedPostalCodesList.push(p);
				}
			});
		});
	} else if (country === 'NO') {
		await import('../Assets/postalcodes/NO.json').then(m => {
			const postalCodesList = Object.keys(m.default);
			const postalCodesListAsNumbers = postalCodesList.map(p => parseInt(p));

			postalCodes.forEach(p => {
				if (p.includes('-')) {
					const splitP = p.split('-');
					const postalCodesBetweenP = postalCodesListAsNumbers.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString());
					resolvedPostalCodesList.push(...postalCodesBetweenP);
				} else {
					postalCodesList.includes(p) && resolvedPostalCodesList.push(p);
				}
			});
		});
	} else if (country === 'SE') {
		await import('../Assets/postalcodes/SE.json').then(m => {
			const postalCodesList = Object.keys(m.default);
			const postalCodesListAsNumbers = postalCodesList.map(p => parseInt(p.replace(' ', '')));

			postalCodes.forEach(p => {
				if (p.includes('-')) {
					const splitP = p.split('-');
					const postalCodesBetweenP = postalCodesListAsNumbers
						.filter(p => parseInt(splitP[0].replace(' ', '')) <= p && p <= parseInt(splitP[1].replace(' ', '')))
						.map(p => {
							const pString = p.toString();
							return pString.substring(0, 3) + ' ' + pString.substring(3);
						});

					resolvedPostalCodesList.push(...postalCodesBetweenP);
				} else {
					postalCodesList.includes(p) && resolvedPostalCodesList.push(p);
				}
			});
		});
	}

	const uniqueAndSortedPostalCodes = Array.from(new Set(resolvedPostalCodesList)).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

	return uniqueAndSortedPostalCodes;
};

export { postalCodeToCity, resolvePostalCodes };
