import React from 'react';
import { faCalendarMinus, faCalendarPlus, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	startDate: DateTime;
	setStartDate(date: DateTime): void;
	endDate: DateTime;
	setEndDate(date: DateTime): void;
	forceShowEndDatePicker?: boolean;
	forceHideTodayControls?: boolean;
}

const DateRangePicker: React.FC<Props> = ({ startDate, setStartDate, endDate, setEndDate, forceShowEndDatePicker, forceHideTodayControls }): React.ReactElement => {
	const [showEndDatePicker, setShowEndDatePicker] = useStorageState<boolean>(window.sessionStorage, 'showEndDatePicker', forceShowEndDatePicker === undefined ? false : forceShowEndDatePicker);
	const [thisStartDate, setThisStartDate] = React.useState(startDate);
	const [thisEndDate, setThisEndDate] = React.useState(endDate);

	React.useEffect(() => {
		setThisStartDate(startDate);
		setThisEndDate(endDate);
	}, [startDate, endDate]);

	return (
		<div className="text-blue my-2 flex flex-row items-center pl-4 font-semibold">
			{!forceHideTodayControls && (
				<div className="mr-8 flex flex-row">
					<Button
						secondary
						text="planner.showToday"
						className="py-1 px-2 text-sm"
						onClick={() => {
							setStartDate(DateTime.now());
							setEndDate(DateTime.now());
						}}
					/>
					<div className="flex flex-row">
						<TextButton
							icon={faChevronLeft}
							onClick={() => {
								setEndDate(endDate.minus({ days: 1 }));
								setStartDate(startDate.minus({ days: 1 }));
							}}
						/>
						<TextButton
							icon={faChevronRight}
							onClick={() => {
								setEndDate(endDate.plus({ days: 1 }));
								setStartDate(startDate.plus({ days: 1 }));
							}}
						/>
					</div>
				</div>
			)}

			<div className="flex flex-row items-center">
				<Datepicker
					className="w-30"
					title=""
					name="pickStartRangeDate"
					max={showEndDatePicker ? endDate.toISODate() : undefined}
					value={thisStartDate.toISODate()}
					onChange={e => setThisStartDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()))}
					onBlur={e => {
						setStartDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()));
						!showEndDatePicker && setEndDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()));
					}}
				/>
				{!showEndDatePicker && (
					<TextButton
						icon={faCalendarPlus}
						text="planner.showDateRange"
						onClick={() => {
							setEndDate(startDate);
							setShowEndDatePicker(true);
						}}
					/>
				)}
				{showEndDatePicker && (
					<>
						<span className="mx-1">-</span>
						<Datepicker
							className="w-40"
							title=""
							name="pickEndRangeDate"
							min={startDate.toISODate()}
							value={thisEndDate.toISODate()}
							onChange={e => setThisEndDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? new Date()))}
							onBlur={e => {
								setEndDate(DateTime.fromJSDate(e.currentTarget.valueAsDate ?? startDate.toJSDate()));
							}}
						/>
						{!forceShowEndDatePicker && (
							<TextButton
								icon={faCalendarMinus}
								text="planner.hideDateRange"
								onClick={() => {
									setShowEndDatePicker(false);
									setEndDate(startDate);
								}}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default DateRangePicker;
