import React from 'react';
import { bgColors, useCalendarEvents } from './PlannerHelpers';
import { DateTime } from 'luxon';
import PlannerLine from './PlannerLine';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Button from '@ssg/common/Components/Button';
import { useTranslation } from 'react-i18next';
import Datepicker from '@ssg/common/Components/Datepicker';
import { IconDefinition, faChevronDoubleLeft, faChevronDoubleRight, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateUserEventComponent from './CreateUserEventComponent';
import TextButton from '@ssg/common/Components/TextButton';
import Loading from '@ssg/common/Components/Loading';
import { GetCalendarsQuery } from '@ssg/common/GraphQL/indexV2';
import PlannerContext from './PlannerContext';

export type UserCalendarAndColor = GetCalendarsQuery['userCalendars'][number] & { color: string };
const fakeuserCalendarAndColor: UserCalendarAndColor = {
	events: [],
	user: {
		department: '',
		area: '',
		defaultLanguage: '',
		email: '',
		employeeId: '',
		id: '',
		jobFunction: '',
		name: '',
		phone: '',
		plannerHelpText: '',
		plannerHelpTextUpdatedAt: '',
	},
	color: 'bg-white',
};
const PlannerSchedule: React.FC = (): React.ReactElement => {

	const { t } = useTranslation();

	const { date, dates, numberOfDays, setDate, setNumberOfDays, selectedUsers, setSelectedUsers } = React.useContext(PlannerContext);
	const { usersCalendars, userCalendarsLoading } = useCalendarEvents();

	return (
		<div className="relative h-screen flex-1 overflow-auto">
			<div>
				<PlannerLine
					top
					numberOfDays={numberOfDays}
					dates={dates ? dates.shownDates : []}
					userCalendarAndColor={fakeuserCalendarAndColor}
					leftBoxElement={
						<div className="p-1">
							<div className="flex justify-between items-end mb-1">
								<div>
									<label className="text-blue block text-xs font-medium">{t('planner.daysShow')}</label>
									<ButtonGroup
										items={[
											{
												isActive: numberOfDays === 7,
												onClick: () => setNumberOfDays(7),
												title: '7',
											},
											{
												isActive: numberOfDays === 14,
												onClick: () => setNumberOfDays(14),
												title: '14',
											},
										]}
									/>
								</div>
								<Button
									secondary
									text="planner.showToday"
									className="py-1 px-2 text-sm h-9"
									onClick={() => setDate(DateTime.now().toISODate())}
								/>
							</div>
							<div className="flex flex-row items-center justify-between">
								<CalendarButton icon={faChevronDoubleLeft} onClick={() => setDate(current => DateTime.fromISO(current).minus({ days: numberOfDays }).toISODate())} />
								<CalendarButton icon={faChevronLeft} onClick={() => setDate(current => DateTime.fromISO(current).minus({ days: 1 }).toISODate())} />

								<div className="text-blue w-32">
									<Datepicker
										className="mt-0"
										name="startingDate"
										title=""
										value={date}
										onChange={(e) => {
											// console.log('currentTarget', e.currentTarget.value);
											//dateRef.current = e.currentTarget.value;
											setDate(e.currentTarget.value);
										}}
									// onBlur={() => setDate(dateRef.current)}
									/>
								</div>
								<CalendarButton icon={faChevronRight} onClick={() => setDate(current => DateTime.fromISO(current).plus({ days: 1 }).toISODate())} />
								<CalendarButton icon={faChevronDoubleRight} onClick={() => setDate(current => DateTime.fromISO(current).plus({ days: numberOfDays }).toISODate())} />

							</div>
						</div>}
				/>
				{selectedUsers.length === 0 &&
					<div className="text-blue h-full w-full pt-14 text-center font-semibold">{t('planner.pickEmployees')}</div>
				}
				{userCalendarsLoading && <Loading title="planner.gettingCalendars" />}

				{/* Location user calendars */}
				{usersCalendars.filter(uc => typeof selectedUsers.find(su => uc.user.id === su.id) !== 'undefined' && uc.user.id.includes('planner')).map((u, index) => {
					const userCalendarAndColor: UserCalendarAndColor = {
						...u,
						color: 'bg-white',
					};

					return (
						<PlannerLine
							numberOfDays={numberOfDays}
							key={u.user.id}
							dates={dates ? dates.shownDates : []}
							userCalendarAndColor={userCalendarAndColor}
							leftBoxElement={
								<div className="p-2 text-blue">
									<div className="flex flex-row justify-between items-start">
										<p>{u.user.name}</p>
										<TextButton icon={faTimes} iconClassName="text-blue text-xl" onClick={() => setSelectedUsers(current => current.filter(c => c.id !== u.user.id))} />
									</div>
									<p>{u.user.phone}</p>
									{/* <CreateUserEventComponent user={u.user} /> */}
								</div>
							}
						/>
					);
				})}
				{/* Regular user calendars */}
				{usersCalendars.filter(uc => typeof selectedUsers.find(su => uc.user.id === su.id) !== 'undefined' && !uc.user.id.includes('planner')).map((u, index) => {
					const userCalendarAndColor: UserCalendarAndColor = {
						...u,
						color: selectedUsers.find(s => s.id === u.user.id)?.color ?? 'bg-white',
					};

					return (
						<PlannerLine
							numberOfDays={numberOfDays}
							key={u.user.id}
							dates={dates ? dates.shownDates : []}
							userCalendarAndColor={userCalendarAndColor}
							leftBoxElement={
								<div className="p-2 text-white">
									<div className="flex flex-row justify-between items-start">
										<p>{u.user.name}</p>
										<TextButton icon={faTimes} iconClassName="text-white text-xl" onClick={() => setSelectedUsers(current => current.filter(c => c.id !== u.user.id))} />
									</div>
									<p>{u.user.phone}</p>
									<CreateUserEventComponent user={u.user} />
								</div>
							}
						/>
					);
				})}
			</div>
		</div>
	);
};


const CalendarButton: React.FC<{ icon: IconDefinition, onClick(): void }> = ({ icon, onClick }): React.ReactElement => {
	return (
		<button className="w-7 h-8 text-center border-default border-blue text-blue bg-white rounded-default" onClick={onClick}>
			<FontAwesomeIcon icon={icon} />
		</button>
	);
};

export default PlannerSchedule;