import React, { CSSProperties, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { ControlProps, ValueType } from 'react-select';
import { GetCaseFiles, GetCaseFilesVariables, GetSingleCase_case, SearchWebUsers, SearchWebUsersVariables, SendCaseEmail, SendCaseEmailVariables } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Modal from '@ssg/common/Components/Modal';
import Tabs from '@ssg/common/Components/Tabs';
import Textarea from '@ssg/common/Components/Textarea';
import Loading from '@ssg/common/Components/Loading';
import Checkbox from '@ssg/common/Components/Checkbox';
import CreateableSelect from 'react-select/creatable';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { toNumber } from 'lodash';
import classNames from 'classnames';

const SEND_EMAIL = loader('../../GraphQL/Cases/SendCaseEmail.gql');
const SEARCH_USERS = loader('../../GraphQL/Users/SearchWebUsers.gql');
const GET_CASE_FILES = loader('../../GraphQL/Files/GetCaseFiles.gql');

const FILE_SIZE_LIMIT = 20000000;

interface Props {
	open: boolean;
	close: () => void;
	data: GetSingleCase_case;
	config?: {
		subject: string;
		recipients: Recipient[];
		message: string;
		fileNames: string[];
		defaultTabIndex: number;
	};
	quoteMail?: boolean;
}

interface TabContent {
	category: string;
	content: File[];
}

type File = NonNullable<GetCaseFiles['caseFiles']>[number];

interface ICaseMail {
	mailTo: string[];
	mailCC: string[];
	mailSubject: string;
	mailBody: string;
	mailAttachments: File[];
}

interface Recipient {
	email: string;
	role: string;
	name: string;
}

const TABS = ['common.pictures', 'common.offer', 'case.screenings', 'case.otherFiles', 'common.emails'];

const CaseMail: React.FC<Props> = ({ open, close, data, config, quoteMail = false }): React.ReactElement => {
	const { t } = useTranslation();
	const potentialRecipients = config?.recipients.filter(r => r.email !== '').filter((r, i, a) => a.indexOf(r) === i) ?? [];

	const [activeTab, setActiveTab] = useState<string>(TABS[typeof config !== 'undefined' ? config.defaultTabIndex : 0]);
	const [attachments, setAttachments] = useState<string[]>([]);
	const [receiverList, setReceiverList] = useState<{ value: string; label: string }[]>([]);
	const [receiverListCC, setReceiverListCC] = useState<{ value: string; label: string }[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [includeCaseAgreements, setIncludeCaseAgreements] = useState<boolean>(false);
	const [totalFileSize, setTotalFileSize] = useState<number>(0);

	const { data: caseFilesData, loading: caseFilesDataLoading } = useQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, {
		variables: { caseErpNo: data.erpNo },
	});

	const isImage = (extension: string): boolean => {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'bmp', 'svg', 'tif', 'tiff', 'webp'];
		return imageExtensions.includes(extension);
	};

	const isOffer = (groupingKey: string): boolean => {
		return groupingKey.startsWith('Q');
	};

	const isScreening = (groupingKey: string): boolean => {
		return groupingKey.startsWith('SCR');
	};

	const isEmail = (extension: string): boolean => {
		return extension === 'eml';
	};

	const images = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isImage(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const offerFiles = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isOffer(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const screeningFiles = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isScreening(f.groupingKey)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);
	const otherFiles = React.useMemo(
		() =>
			caseFilesData?.caseFiles
				.filter(f => !isScreening(f.groupingKey) && !isImage(f.extension) && !isOffer(f.groupingKey) && !isEmail(f.extension))
				.sort((a, b) => b.created.localeCompare(a.created)) ?? [],
		[caseFilesData],
	);
	const emails = React.useMemo(() => caseFilesData?.caseFiles.filter(f => isEmail(f.extension)).sort((a, b) => b.created.localeCompare(a.created)) ?? [], [caseFilesData]);

	const tabContent: TabContent[] = React.useMemo(
		() => [
			{ category: 'common.pictures', content: images },
			{ category: 'common.offer', content: offerFiles },
			{ category: 'case.screenings', content: screeningFiles },
			{ category: 'case.otherFiles', content: otherFiles },
			{ category: 'common.emails', content: emails },
		],
		[emails, images, offerFiles, otherFiles, screeningFiles],
	);

	const { data: users } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: searchText,
		},
		skip: searchText === '',
	});

	const mails = users?.searchUsers
		// Remove duplicate users
		?.filter((v, i, a) => a.findIndex(user => user.email === v.email) === i)
		// Map users to select options
		?.map((u): SelectOption => ({ value: u.email, label: u.name }));

	const [sendEmail, { loading: sendEmailLoading }] = useMutation<SendCaseEmail, SendCaseEmailVariables>(SEND_EMAIL);

	const { handleSubmit, register, setValue, control } = useForm<ICaseMail>({
		reValidateMode: 'onChange',
	});

	const onSubmit = async (mail: ICaseMail) => {
		try {
			// Send mail
			await sendEmail({
				variables: {
					caseId: data.id,
					to: mail.mailTo,
					cc: mail.mailCC ?? [],
					bcc: ['5c-mail-in@ssg.dk'],
					subject: mail.mailSubject,
					body: mail.mailBody,
					includeCaseAgreements: includeCaseAgreements,
					quote: quoteMail,
					attachements: attachments.map(a => decodeURI(a)),
				},
			});

			close();
		} catch (e) {
			console.log(e);
		}
	};

	const chooseAttachments = (file: File): void => {
		if (attachments.some(a => file.url.includes(a))) {
			setAttachments(attachments.filter(a => !file.url.includes(a)));
			setTotalFileSize(totalFileSize - toNumber(file.metadata.find(m => m.key === 'FileSizeDisplay')?.value));
			return;
		}

		//TODO: BELFOR update change sharepoint link
		const attachment = file.url.replace('https://ssgcloud.sharepoint.com/sites/SSGESDH', '');

		setAttachments([...attachments, attachment]);
		setTotalFileSize(totalFileSize + toNumber(file.metadata.find(m => m.key === 'FileSizeDisplay')?.value));
	};

	const setRecieversHandler = (value: ValueType<SelectOption, true>): void => {
		const values = Array.isArray(value) ? value : value === null ? [] : [value];
		console.log(values);
		setReceiverList(values);

		setValue(
			'mailTo',
			values.filter(x => x !== null).map(v => v.value),
			{ shouldValidate: true },
		);

		setSearchText('');
	};

	const setRecieversHandlerList = (value: string, label: string, add: boolean): void => {
		if (add) {
			setReceiverList(arr => [...arr, { value: value, label: label }]);
		} else {
			setReceiverList(arr => arr.filter(element => element.value !== value));
		}
	};

	const setRecieversHandlerListCC = (value: string, label: string, add: boolean): void => {
		if (add) {
			setReceiverListCC(arr => [...arr, { value: value, label: label }]);
		} else {
			setReceiverListCC(arr => arr.filter(element => element.value !== value));
		}
	};

	React.useEffect(() => {
		setValue(
			'mailCC',
			receiverListCC.filter(x => x !== null).map(v => v.value),
			{ shouldValidate: true },
		);
	}, [receiverListCC, setValue]);

	React.useEffect(() => {
		setValue(
			'mailTo',
			receiverList.filter(x => x !== null).map(v => v.value),
			{ shouldValidate: true },
		);
	}, [receiverList, setValue]);

	// React.useEffect(() => {
	//     typeof config !== 'undefined' && setValue('mailTo', config.recipient);
	// }, []);
	React.useEffect(() => {
		if (typeof config !== 'undefined' && typeof caseFilesData !== 'undefined') {
			config.fileNames.forEach(n => {
				const caseFile = caseFilesData.caseFiles.find(f => f.name.includes(n));
				typeof caseFile !== 'undefined' && chooseAttachments(caseFile);
			});
		}
	}, [caseFilesData, config, chooseAttachments]);

	const setCCHandler = (value: ValueType<SelectOption, true>): void => {
		const values = Array.isArray(value) ? value : [value];

		setReceiverListCC(values);
		console.log(values);
		//setValue('mailCC', values.map(v => v.value), { shouldValidate: true });
		setSearchText('');
	};

	React.useEffect(() => {
		const checkboxes = document.querySelectorAll('#tabs input[type="checkbox"') as NodeListOf<HTMLInputElement>;

		checkboxes.forEach(box => {
			box.checked = false;
			attachments?.forEach(att => {
				if (att.includes(encodeURI(box.id))) {
					box.checked = true;
				}
			});
		});
	}, [activeTab, attachments]);

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: CSSProperties, state: ControlProps<{ label: string; value: string }, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	return (
		<Modal
			title={quoteMail ? 'offer.sendOffer' : 'case.sendCaseMail'}
			visible={open}
			close={close}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormFieldHeader title="common.mailTo" htmlFor="mailTo" required />
					<Controller
						control={control}
						name="mailTo"
						defaultValue={null}
						render={() => (
							<CreateableSelect
								isMulti
								name="mailTo"
								options={mails}
								closeMenuOnSelect={false}
								formatCreateLabel={inputValue => `${t('mail.addMail')} "${inputValue}"`}
								noOptionsMessage={() => t('mail.addDifferentMail')}
								placeholder={<div>{t('mail.searchForMail')}</div>}
								styles={customStyles}
								inputRef={register}
								onChange={val => setRecieversHandler(val)}
								onInputChange={val => setSearchText(val)}
								defaultValue={receiverList}
								className="border-1 rounded-default border-gray-600"
								value={receiverList}
							/>
						)}
					/>

					<FormFieldHeader title="mail.CC" htmlFor="mailCC" />

					<Controller
						control={control}
						name="mailCC"
						defaultValue={null}
						render={() => (
							<CreateableSelect
								isMulti
								name="mailCC"
								options={mails}
								closeMenuOnSelect={false}
								formatCreateLabel={inputValue => `${t('mail.addMail')} "${inputValue}"`}
								noOptionsMessage={() => t('mail.addDifferentMail')}
								placeholder={<div>{t('mail.searchForMail')}</div>}
								styles={customStyles}
								inputRef={register}
								onChange={val => setCCHandler(val)}
								onInputChange={val => setSearchText(val)}
								className="border-1 rounded-default border-gray-600"
								value={receiverListCC}
							/>
						)}
					/>
					<div className="py-2">
						<table>
							<thead>
								<th className="text-blue whitespace-no-wrap text-left text-base font-semibold">{t('common.recipient')}</th>
								<th className="text-blue whitespace-no-wrap px-5 text-center text-base font-semibold">{t('common.mailTo')}</th>
								<th className="text-blue whitespace-no-wrap px-5 text-center text-base font-semibold">{t('mail.CC')}</th>
							</thead>
							<tbody>
								{potentialRecipients.map((r, i) => (
									<tr key={i}>
										<td>
											{r.email}
											{r.role !== '' && ` (${t(r.role)})`}
										</td>

										<td>
											<input
												id={`to-${r.email}`}
												checked={receiverList.filter(rl => rl !== null).some(rl => rl.value === r.email)}
												onChange={e => setRecieversHandlerList(r.email, r.email, e.target.checked)}
												className={'rounded-default text-blue mx-5 cursor-pointer ring-transparent focus:outline-none'}
												type="checkbox"
											/>
										</td>
										<td>
											<input
												id={`cc-${r.email}`}
												checked={receiverListCC.filter(rl => rl !== null).some(rl => rl.value === r.email)}
												onChange={e => setRecieversHandlerListCC(r.email, r.email, e.target.checked)}
												className={'rounded-default text-blue mx-5 cursor-pointer ring-transparent focus:outline-none'}
												type="checkbox"
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<Input
						title="mail.subject"
						name="mailSubject"
						required
						innerRef={register}
						defaultValue={
							typeof config !== 'undefined'
								? config.subject
								: `${t('mail.defaultSubject', {
										erpNo: data.erpNo,
										policeNumber: data.debitor.policeNumber,
								  })}`
						}
					/>

					<Textarea title="common.message" name="mailBody" rows={6} required innerRef={register} defaultValue={config?.message} />

					{!quoteMail && (
						<Checkbox name="includeCaseAgreements" title="mail.includeCaseAgreements" checked={includeCaseAgreements} onClick={() => setIncludeCaseAgreements(!includeCaseAgreements)} />
					)}

					<Tabs titles={TABS} active={activeTab} onClick={setActiveTab}>
						<div id="tabs" className="relative h-full w-full">
							{caseFilesDataLoading ? (
								<Loading />
							) : (
								tabContent
									.filter(tab => tab.category === activeTab)
									.map((tab, index) => (
										<div className="flex flex-row" key={index}>
											{tab.content?.length === 0 ? (
												<div className="text-blue ml-2 flex justify-center font-medium">{`${t('mail.noFile')} ${t(tab.category)}`}</div>
											) : (
												tab.content?.map((file, index) => {
													return (
														<div className="m-2 w-40 cursor-pointer" title={file.name} key={index}>
															<div onClick={() => chooseAttachments(file)} className="border-1 h-40 w-40 overflow-hidden border-gray-300">
																<div className="h-40">
																	<img src={file.thumbnail} alt={file.name} className="h-40 w-40 object-cover object-left-top" />
																</div>
															</div>
															<Checkbox
																name={file.name}
																id={file.name}
																title={file.name.replace('Sales Quote - ', '')}
																textClassName="w-40 truncate text-blue"
																checked={typeof attachments.find(a => file.url.includes(a)) !== 'undefined'}
																onClick={() => chooseAttachments(file)}
															/>
														</div>
													);
												})
											)}
										</div>
									))
							)}
						</div>
					</Tabs>

					<div className="mt-3 h-4 w-full rounded-full bg-gray-400">
						<div
							className={classNames('h-4 rounded-full text-white', {
								'bg-blue-light': (totalFileSize / FILE_SIZE_LIMIT) * 100 <= 100,
								'bg-red-calm': (totalFileSize / FILE_SIZE_LIMIT) * 100 > 100,
							})}
							style={{
								width: `${(totalFileSize / FILE_SIZE_LIMIT) * 100 > 100 ? 100 : Math.ceil((totalFileSize / FILE_SIZE_LIMIT) * 100)}%`,
							}}
						>
							<p className="mx-2 text-center text-xs">{Math.ceil((totalFileSize / FILE_SIZE_LIMIT) * 100)}%</p>
						</div>
					</div>

					<div className="mt-3 flex">
						<Button submit success text="case.sendCaseMail" loading={sendEmailLoading} disabled={receiverList.length === 0 || (totalFileSize / FILE_SIZE_LIMIT) * 100 > 100} />
						{(totalFileSize / FILE_SIZE_LIMIT) * 100 > 100 && <p className="text-red ml-3 self-end">{t('error.fileSizeLimitReached')}</p>}
					</div>
				</form>
			}
		/>
	);
};

export default CaseMail;
