import React from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { VendorFilter } from './VendorsOverview';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Select from 'react-select';

interface Props {
	activeFilter: VendorFilter;
	setActiveFilter: React.Dispatch<React.SetStateAction<VendorFilter>>;
	setSearchTerm: (v: string) => void;
	typeOptions: SelectOption[];
	locationOptions: SelectOption[];
}

const VendorsOverviewFilter: React.FC<Props> = ({ setSearchTerm, activeFilter, setActiveFilter, typeOptions, locationOptions }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="flex flex-row items-center space-x-2">
				<div className="mb-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
					<div className="flex justify-between">
						<div className="relative flex">
							<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
							<Input
								name="search"
								placeholder={t('requisitions.filters.searchVendorPlaceholder')}
								className="pr-8 text-sm"
								style={{ minWidth: '220px' }}
								onChange={(e): void => setSearchTerm(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="mb-3 w-40">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.type')}</label>
					<Dropdown
						title=""
						name="types"
						onChange={e =>
							setActiveFilter(currentFilters => ({
								...currentFilters,
								type: e.target.value.length > 0 ? e.target.value : undefined,
							}))
						}
						data={typeOptions}
					/>
				</div>
				<div className="mb-3">
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.location')}</label>
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilter.locations?.map(v => {
								const l = locationOptions.find(location => location.value === v);
								return {
									value: l?.value ?? '',
									label: l?.label ?? '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values => {
							setActiveFilter(currentFilters => ({
								...currentFilters,
								locations: values?.map(v => v.value) ?? undefined,
							}));
						}}
						options={locationOptions}
					/>
				</div>
			</div>
		</div>
	);
};

export default VendorsOverviewFilter;
