import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormHelpText from './FormHelpText';
import FormErrorText from './FormErrorText';
import FormFieldHeader from './FormFieldHeader';

interface Props {
	className?: string;
	innerRef?: React.Ref<HTMLTextAreaElement>;
	required?: boolean;
	title: string;
	errorMessage?: string;
	helpText?: string;
	showHelpText?: boolean;
	name: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
	minHeight: number;
}
const TextareaAutoResize: React.FC<Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
	className,
	innerRef,
	title,
	required = false,
	errorMessage,
	helpText,
	showHelpText,
	name,
	defaultValue,
	disabled = false,
	placeholder,
	minHeight,
	...restProps
}): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<FormFieldHeader htmlFor={name} required={required} title={title} />
			{showHelpText && helpText && <FormHelpText text={helpText} />}
			<div className="relative w-full text-sm" style={{ minHeight: minHeight }}>
				<div
					className="w-full border-1 rounded-default bg-white border-transparent break-words p-1 whitespace-pre-wrap"
					style={{ lineHeight: '24px', minHeight: minHeight }}
				>{restProps.value}<span className="text-transparent text-xs">.</span></div>
				<div className="absolute top-0 left-0 w-full h-full">
					<textarea
						{...restProps}
						//value={text}
						defaultValue={defaultValue}
						name={name}
						id={name}
						ref={innerRef}
						disabled={disabled}
						style={{ caretColor: '#000' }}
						className="h-full resize-none w-full text-sm caret-black placeholder-gray-500 rounded-default bg-transparent text-transparent p-1 pl"
						onChange={e => {
							const input = e.target.value;
							//setText(input);
							restProps.onChange?.(e);
						}}
						placeholder={placeholder ? t(placeholder) : undefined}
					/>
				</div>
			</div>
			{errorMessage && <FormErrorText text={t(errorMessage)} />}
		</>
	);
};

export default TextareaAutoResize;
