import React from 'react';
import PlannerContext from './PlannerContext';
import { useTranslation } from 'react-i18next';
import { useGetVehiclesQuery, useRemoveUserMaterialFilterMutation, useSetUserMaterialFilterMutation } from '@ssg/common/GraphQL/indexV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { P } from '@ssg/common/Components/Text';
import PlannerCustomDragLayer from './PlannerCustomDragLayer';
import VehicleCard from './VehicleCard';
import { Bookings, DragItemTypes, getWeekDay, mapBookingsOnVehicle } from './PlannerHelpers';
import VehicleFilters from './VehicleFilters';
import { GetWebCurrentUser, RemoveUserMaterialFilterVariables, SetUserMaterialFilterVariables } from '../../GraphQL';
import UserContext from '../../UserContext';
import { loader } from 'graphql.macro';
const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');

const PlannerMaterials: React.FC = (): React.ReactElement => {
	const { dates, activeMaterialFilters, setActiveMaterialFilters, clearActiveMaterialFilters, activeUserMaterialFilter, setActiveUserMaterialFilter } = React.useContext(PlannerContext);
	const { t } = useTranslation();
	const datesAndWeekDay = React.useMemo(() => (dates?.shownDates ?? []).map(date => ({ date, weekDay: getWeekDay(date) })), [dates]);

	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [filterSaveName, setFilterSaveName] = React.useState<string>(activeMaterialFilters.name ?? '');
	const userContext = React.useContext(UserContext);
	const materialFiltersData = React.useMemo(() => userContext.user?.materialFilters ?? [], [userContext.user?.materialFilters]);
	const [removeUserFilter] = useRemoveUserMaterialFilterMutation();
	const [createUserFilter] = useSetUserMaterialFilterMutation();

	const { data, loading } = useGetVehiclesQuery({
		context: { debatch: true },
		variables: {
			isCar: false,
			dateRange: {
				from: dates?.fromDate ?? '',
				to: dates?.toDate ?? '',
			},
			locations: activeMaterialFilters.locations,
			//type: activeMaterialFilters.type,
			departments: activeMaterialFilters.departments,
		},
		skip: typeof dates === 'undefined',
	});

	const materials = React.useMemo(() => data?.vehicles ?? [], [data?.vehicles]);
	const resetFilters = () => {
		clearActiveMaterialFilters();

		setActiveMaterialFilters({
			name: undefined,
			locations: undefined,
			//type: undefined,
			departments: undefined,
		});

		//setPostalCodeValues([]);
		setActiveUserMaterialFilter('');
	};
	if (typeof dates === 'undefined') return <></>;


	const postUserFilter = async (filterValues: SetUserMaterialFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserMaterialFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.materialFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							materialFilters: [cachedRequest.currentUser?.materialFilters, data.setUserMaterialFilter.materialFilters],
						},
					},
				});
			},
		});
		console.log('filter name ', filterValues.filter.name);
		setActiveUserMaterialFilter(filterValues.filter.name);
		//setFilterSaveName(false);
	};


	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					departments: activeMaterialFilters.departments ?? [],
					locations: activeMaterialFilters.locations ?? [],
					cars: [],

				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserMaterialFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<div>
				<VehicleFilters
					setSearchTerm={setSearchTerm}
					activeVehiclesFilter={activeMaterialFilters}
					setActiveVehiclesFilter={setActiveMaterialFilters}
					resetFilters={resetFilters}
					activeUserFilterName={activeUserMaterialFilter}
					handleDeleteUserFilter={async (name) => handleDeleteUserFilter(name)}
					onFilterSave={async () => onFilterSave()}
					setActiveUserVehiclesFilter={setActiveUserMaterialFilter}
					userVehiclesFilters={materialFiltersData}
					setFilterName={setFilterSaveName}
					filterName={filterSaveName} />
			</div>
			<hr className="my-2 text-blue" />

			<div className="overflow-y-auto">
				{loading && (
					<div className="h-40 text-blue text-center">
						<FontAwesomeIcon icon={faSpinner} className="animate-spin" size="5x" />
						<P className="text-2xl">{t('planner.gettingMaterials')}</P>
					</div>
				)}
				<PlannerCustomDragLayer />
				{materials.map(c => <VehicleCard key={c.vehicleNumber} vehicle={c} dragItemType={DragItemTypes.MATERIAL} bookings={mapBookingsOnVehicle(c.bookedDays as Bookings, datesAndWeekDay)} />)}
			</div>
		</>
	);
};

export default PlannerMaterials;