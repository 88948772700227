import { useQuery } from '@apollo/client';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { loader } from 'graphql.macro';
import React from 'react';
import { useForm } from 'react-hook-form';
import { GetWebCasesByErpNo, GetWebCasesByErpNoVariables } from '../../GraphQL';

const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');

interface Props {
	onSelect(caseId: string): void;
	erpNo: boolean;
}

const SearchableCaseSelect: React.FC<Props> = ({ onSelect, erpNo }) => {
	const [casesSearchText, setCasesSearchText] = React.useState('');

	const dummyForm = useForm();

	const { data: cases, loading: loadingCases } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: { erpReferenceNo: casesSearchText },
		skip: casesSearchText.length === 0,
	});

	return (
		<SearchableSelect
			name="caseId"
			searchFn={searchText => setCasesSearchText(searchText)}
			onSelect={value => {
				onSelect(value);
				dummyForm.setValue('caseId', value);
			}}
			onBlur={() => undefined}
			minInputLength={2}
			options={(cases?.cases ?? []).map(c => ({
				label: c.erpNo,
				value: erpNo ? c.erpNo : c.id,
			}))}
			isLoading={loadingCases}
			control={dummyForm.control}
		/>
	);
};

export default SearchableCaseSelect;
