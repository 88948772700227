import React from 'react';
import { faExchange } from '@fortawesome/pro-regular-svg-icons';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import Modal from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { GetMovablesQuery } from '@ssg/common/GraphQL/indexV2';

type Props = Pick<GetMovablesQuery['movables'][number], 'version' | 'changes'>;

const MovableChangeLog: React.FC<Props> = ({ version, changes }) => {
	const { t } = useTranslation();

	const [showModal, setShowModal] = React.useState(false);
	const sorted = React.useMemo(() => changes.slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [changes]);

	if (version <= 1 || changes.length === 0) {
		return null;
	}

	return (
		<>
			<TextButton icon={faExchange} onClick={() => setShowModal(true)} />

			<Modal
				title="common.changes"
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<div className="space-y-4">
						{sorted.map(c => (
							<div key={dateToDateTimeString(c.timestamp)}>
								<strong>
									{dateToDateTimeString(c.timestamp)}
									&nbsp;{t('common.by')}&nbsp;
									{c.user.name}&nbsp;({c.user.email})
								</strong>

								<div className="mt-1">{renderChanges(t, c.before, c.after)}</div>
							</div>
						))}
					</div>
				}
			/>
		</>
	);
};
export default MovableChangeLog;

function renderChanges(t: TFunction, before: Props['changes'][number]['before'], after: Props['changes'][number]['after']): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// Description was changed
	if (!(before.description === null && after.description === null) && before.description !== after.description) {
		const tValues = {
			before: before.description !== null ? before.description : NO_VALUE,
			after: after.description !== null ? after.description : NO_VALUE,
		};

		result.push(
			<div key="description" className="my-2">
				<Trans t={t} i18nKey="movable.changelog.description" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Volume was changed
	if (!(before.volume === null && after.volume === null) && before.volume !== after.volume) {
		const tValues = {
			before: before.volume !== null ? before.volume : NO_VALUE,
			after: after.volume !== null ? after.volume : NO_VALUE,
		};

		result.push(
			<div key="volume" className="my-2">
				<Trans t={t} i18nKey="movable.changelog.volume" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Completed was changed
	if (!(before.completed === null && after.completed === null) && before.completed !== after.completed) {
		const tValues = {
			before: before.completed !== null ? t(`common.${getBoolString(before.completed ?? false)}`) : NO_VALUE,
			after: after.completed !== null ? t(`common.${getBoolString(after.completed ?? false)}`) : NO_VALUE,
		};

		result.push(
			<div key="completed" className="my-2">
				<Trans t={t} i18nKey="movable.changelog.completed" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Status was changed
	if (!(before.status === null && after.status === null) && before.status !== after.status) {
		const tValues = {
			before: before.status !== null ? before.status : NO_VALUE,
			after: after.status !== null ? after.status : NO_VALUE,
		};

		result.push(
			<div key="status" className="my-2">
				<Trans t={t} i18nKey="movable.changelog.status" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Placement was changed
	if (!(before.placement === null && after.placement === null) && before.placement?.id !== after.placement?.id) {
		const tValues = {
			before: before.placement !== null ? before.placement?.name : NO_VALUE,
			after: after.placement !== null ? after.placement?.name : NO_VALUE,
		};

		result.push(
			<div key="placement" className="my-2">
				<Trans t={t} i18nKey="movable.changelog.placement" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	return result;
}

function getBoolString(bool: boolean): string {
	return bool ? 'yes' : 'no';
}
