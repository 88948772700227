import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from '@ssg/common/Components/Popover';

interface Props {
	icon: IconDefinition;
	iconColor?: ProgressColor;
	popperTitle?: string;
}

export enum ProgressColor {
	BLUE = 'BLUE',
	RED = 'RED',
	ORANGE = 'ORANGE',
	GREEN = 'GREEN',
	TRANSPARRENT = 'TRANSPARRENT',
}

const ProgressIcon: React.FC<Props> = ({ icon, iconColor = 'TRANSPARRENT', children, popperTitle }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<Popover
			placement="bottom"
			content={
				<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
					{popperTitle && <p className="mb-1 text-center text-sm font-medium">{t(popperTitle)}</p>}
					{children}
				</div>
			}
		>
			<div className="flex h-8 w-8 items-center justify-center">
				<FontAwesomeIcon
					className={classNames({
						'text-orange': iconColor === 'ORANGE',
						'text-blue': iconColor === 'BLUE',
						'text-green': iconColor === 'GREEN',
						'text-red': iconColor === 'RED',
						'text-blue opacity-25': iconColor === 'TRANSPARRENT',
					})}
					icon={icon}
					size="lg"
				/>
			</div>
		</Popover>
	);
};

export default ProgressIcon;
