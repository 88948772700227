import React from 'react';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useTranslation } from 'react-i18next';

interface Props {
	visible: boolean;
	close: () => void;
}

const RejectedTimeRegistrationWarningModal: React.FC<Props> = ({ visible, close }) => {
	const { t } = useTranslation();
	return (
		<Modal
			size={ModalSize.SMALL}
			visible={visible}
			close={close}
			title="timeRegistration.rejectedTimeRegistrations"
			body={<p>{t('timeRegistration.rejectedTimeRegistrationWarningModal.pendingRejectedReigstrations')}</p>}
			footer={
				<div className="flex w-full flex-row justify-between">
					<Button
						text="Ok"
						disabled={false}
						success
						onClick={async () => {
							close();
						}}
					/>
				</div>
			}
		/>
	);
};

export default RejectedTimeRegistrationWarningModal;
