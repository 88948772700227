import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { ApproveInvoice, ApproveInvoiceVariables, RejectInvoice, RejectInvoiceVariables } from '../../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import InvoiceApprovalModalBody from './InvoiceApprovalModalBody';

const APPROVE_INVOICE = loader('src/GraphQL/Invoices/ApproveInvoice.gql');
const REJECT_INVOICE = loader('src/GraphQL/Invoices/RejectInvoice.gql');

interface Props {
	caseId: string;
	caseERPno: string;
	invoiceNo: string;
	isScreening: boolean;
	close: () => void;
	refetch: () => unknown;
	totalCaseCost: number;
}

const InvoiceApprovalModal: React.FC<Props> = ({ caseId, caseERPno, invoiceNo, isScreening, close, refetch, totalCaseCost }) => {
	const { t } = useTranslation();

	const [approveInvoice, { loading: approving }] = useMutation<ApproveInvoice, ApproveInvoiceVariables>(APPROVE_INVOICE);
	const [rejectInvoice, { loading: rejecting }] = useMutation<RejectInvoice, RejectInvoiceVariables>(REJECT_INVOICE);
	const [rejectionComment, setRejectionComment] = React.useState<null | string>(null);

	return (
		<Modal
			size={ModalSize.XLARGE}
			visible={true}
			close={close}
			title="myPage.invoiceApproval.modal.title"
			body={
				<InvoiceApprovalModalBody
					rejectionComment={rejectionComment}
					setRejectionComment={setRejectionComment}
					caseId={caseId}
					caseERPno={caseERPno}
					invoiceNo={invoiceNo}
					isScreening={isScreening}
					totalCaseCost={totalCaseCost}
				/>
			}
			footer={
				<div className="flex w-full flex-row justify-between">
					<Button
						text="myPage.invoiceApproval.modal.approve"
						disabled={approving || rejecting}
						primary
						onClick={async () => {
							await approveInvoice({
								variables: {
									caseId,
									invoiceERPReference: invoiceNo,
								},
							});
							refetch();
							close();
						}}
					/>

					<Button
						text="myPage.invoiceApproval.modal.reject"
						disabled={approving || rejecting}
						danger
						onClick={async () => {
							// Make the user confirm that they don't want to give a reason for rejecting the invoice
							if (!rejectionComment && !window.confirm(t('myPage.invoiceApproval.modal.rejectWithoutCommentConfirmation'))) {
								return;
							}

							await rejectInvoice({
								variables: {
									caseId,
									invoiceERPReference: invoiceNo,
									rejectionReason: rejectionComment,
								},
							});
							refetch();
							close();
						}}
					/>
				</div>
			}
		/>
	);
};

export default InvoiceApprovalModal;
