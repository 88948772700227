import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faFileUpload, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permissions } from '@ssg/common/GraphQL';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	GetCatalog_catalog,
	GetCatalogFile,
	GetCatalogFileVariables,
	GetCatalogESDHFiles_catalog_documents,
	GetCatalogESDHFiles,
	GetCatalogESDHFilesVariables,
	GetGlobalCatalogESDHFiles,
	/*DeleteCatalogFile,
    DeleteCatalogFileVariables,*/
	LogCatalogFileChange,
	LogCatalogFileChangeVariables,
	FileActionType,
	FileInput,
	UploadCatalogFiles,
	UploadCatalogFilesVariables,
	GetGlobalCatalogESDHFilesVariables,
} from '../../../../GraphQL';
import { toBase64, stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import UserContext from '../../../../UserContext';
import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../../../Components/Layout/Box';
import TextButton from '@ssg/common/Components/TextButton';
import DeleteCatalogFileModal from './DeleteCatalogFileModal';

interface Props {
	catalogData: GetCatalog_catalog;
	changedBy: string;
}

interface FileProps {
	fileName: string;
	folder?: string;
}

const GET_FILE = loader('src/GraphQL/Files/GetCatalogFile.gql');
const LOG_FILE_CHANGE = loader('src/GraphQL/Files/LogCatalogFileChange.gql');
const GET_CATALOG_ESDH_FILES = loader('src/GraphQL/Files/GetCatalogESDHFiles.gql');
const GET_GLOBAL_CATALOG_FILES = loader('src/GraphQL/Files/GetGlobalCatalogESDHFiles.gql');
const UPLOAD_FILES = loader('src/GraphQL/Files/UploadCatalogFiles.gql');

const FileBox: React.FC<Props> = ({ catalogData, changedBy }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const canEdit = userContext.user?.permissions.includes(Permissions.CATALOGS_EDIT);

	const { data: catalogFiles, loading: catalogFilesLoading } = useQuery<GetCatalogESDHFiles, GetCatalogESDHFilesVariables>(GET_CATALOG_ESDH_FILES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			id: catalogData.id,
		},
	});

	const { data: globalFiles, loading: globalFilesLoading } = useQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>(GET_GLOBAL_CATALOG_FILES, {
		variables: {
			customerIds: [catalogData.customer.id],
		},
	});

	const [files, setFiles] = React.useState<GetCatalogESDHFiles_catalog_documents[]>([]);
	const [fileData, setFileData] = React.useState<FileProps>({
		fileName: '',
		folder: '',
	});
	const [fileNameToDelete, setFileNameToDelete] = useState<string | undefined>(undefined);

	const { data: file, loading: fileLoading } = useQuery<GetCatalogFile, GetCatalogFileVariables>(GET_FILE, {
		fetchPolicy: 'cache-and-network',
		variables: {
			fileName: fileData.fileName,
			folder: fileData.folder,
		},
		skip: fileData.fileName === '',
	});

	const [uploadCatalogFiles, { loading: loadingUploadFiles }] = useMutation<UploadCatalogFiles, UploadCatalogFilesVariables>(UPLOAD_FILES);

	React.useEffect(() => {
		const temp: any[] = [];
		if (typeof catalogFiles?.catalog.documents !== 'undefined' && catalogFiles.catalog.documents.length > 0) {
			temp.push(...catalogFiles.catalog.documents);
		}
		if (globalFiles && typeof globalFiles?.globalCatalogFiles !== 'undefined' && globalFiles.globalCatalogFiles.length > 0) {
			const globals = globalFiles.globalCatalogFiles.filter(g => g.metadata.find(m => m.key === 'Kunde')?.value === catalogData.customer.id);
			temp.push(...globals);
		}
		setFiles(temp);
	}, [catalogData.customer.name, catalogData.customer.id, catalogFiles, globalFiles]);

	const [logFileChange] = useMutation<LogCatalogFileChange, LogCatalogFileChangeVariables>(LOG_FILE_CHANGE);

	async function uploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files || e.target.files.length === 0) {
			alert(t('error.fileMissing'));
			return;
		}

		const returnValues: FileInput[] = [];

		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files[i];

			const fileAsString = await toBase64(file);

			const result = stringToByteArray(fileAsString);

			returnValues.push({ fileData: result, filename: file.name });
		}

		await uploadCatalogFiles({
			variables: {
				files: returnValues,
				folder: catalogData.id,
				customerId: catalogData.customer.id,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}
				const cachedRequest = cache.readQuery<GetCatalogESDHFiles, GetCatalogESDHFilesVariables>({
					query: GET_CATALOG_ESDH_FILES,
					variables: {
						id: catalogData.id,
					},
				});

				if (cachedRequest === null || cachedRequest.catalog === null) {
					return;
				}

				cache.writeQuery<GetCatalogESDHFiles, GetCatalogESDHFilesVariables>({
					query: GET_CATALOG_ESDH_FILES,
					variables: {
						id: catalogData.id,
					},
					data: {
						catalog: {
							...cachedRequest.catalog,
							documents: [...cachedRequest.catalog.documents, ...cacheData.uploadCatalogFiles],
						},
					},
				});
			},
		});

		await logFileChange({
			variables: {
				filename: returnValues.map(val => `${val.filename}`).join(', '),
				catalogId: catalogData.id,
				customerId: catalogData.customer.id,
				globalFile: false,
				action: FileActionType.ADD,
				changedBy: changedBy,
			},
		});
	}

	React.useEffect(() => {
		if (file?.catalogFile) {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(file.catalogFile.fileData)]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${file.catalogFile.filename}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
		}
	}, [fileData, file]);

	return (
		<Box title="common.files" full>
			<div className="text-blue -mt-6 flex justify-end">
				{canEdit && (
					<label className="rounded-default text-blue flex cursor-pointer flex-col items-center p-1 text-sm font-medium focus:outline-none">
						<span>
							<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
							{t('common.uploadFiles')}
						</span>
						<input type="file" autoComplete="nope" className="hidden" onChange={e => uploadFiles(e)} multiple />
					</label>
				)}
			</div>
			<Table
				data={files ?? []}
				loading={fileLoading || typeof files === 'undefined' || loadingUploadFiles || catalogFilesLoading || globalFilesLoading || fileLoading}
				columns={[
					{
						label: 'common.name',
						selectFn: f => <p>{f.name.substring(0, f.name.lastIndexOf('.'))}</p>,
					},
					{
						label: 'catalog.fileName',
						selectFn: f => (
							<div>
								{decodeURI(f.url).includes('Globale filer') ? (
									<TextButton
										text={f.name}
										onClick={() =>
											setFileData({
												fileName: f.name,
												folder: undefined,
											})
										}
									/>
								) : (
									<TextButton
										text={f.name}
										onClick={() =>
											setFileData({
												fileName: f.name,
												folder: catalogData.id,
											})
										}
									/>
								)}
							</div>
						),
					},
					{
						label: 'common.global',
						selectFn: f => <p className="py-1">{f.metadata.find(m => m.key === 'Global')?.value === 'True' ? t('common.yes') : t('common.no')}</p>,
						sortFn: (a, b) => (a.metadata.find(m => m.key === 'Global')?.value ?? '').localeCompare(b.metadata.find(m => m.key === 'Global')?.value ?? ''),
					},
					{
						label: 'common.updatedAt',
						selectFn: f => <p className="py-1">{dateToDateTimeString(f.metadata.find(m => m.key === 'Modified')?.value ?? '')}</p>,
					},
					{
						label: t('common.remove'),
						classNameTh: 'text-right',
						hideColumn: !canEdit,
						selectFn: f => (
							<div>
								{f.metadata.find(m => m.key === 'Global')?.value === 'False' && (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setFileNameToDelete(f.name);
											}}
											className="cursor-pointer"
										/>
									</div>
								)}
							</div>
						),
					},
				]}
				keySelector={f => f.name}
				noDataFoundText="catalog.noFiles"
			/>
			{typeof fileNameToDelete !== 'undefined' && (
				<DeleteCatalogFileModal
					catalogId={catalogData.id}
					changedBy={changedBy}
					close={() => setFileNameToDelete(undefined)}
					visible={typeof fileNameToDelete !== 'undefined'}
					customerId={catalogData.customer.id}
					filenameToDelete={fileNameToDelete}
				/>
			)}
		</Box>
	);
};

export default FileBox;
