import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldHeader from '../../../../Components/Forms/FormFieldHeader';
import { TrygMoistureMeassurementInput } from '../../../../GraphQL';

interface Props {
	meassurements: TrygMoistureMeassurementInput[];
	onChange(meassurements: TrygMoistureMeassurementInput[]): void;
}

const EMPTY_ROW = (): TrygMoistureMeassurementInput => ({
	method: '',
	paints: '',
	material: '',
	description: '',
	construction: '',
	controlValue: '',
	referenceValue: '',
	expectedFinalValue: '',
});

const TABLE_COLUMNS: Array<keyof TrygMoistureMeassurementInput> = ['construction', 'material', 'description', 'method', 'paints', 'expectedFinalValue', 'referenceValue', 'controlValue'];

const DATE_COLUMN: Array<keyof TrygMoistureMeassurementInput> = ['paintsDate', 'expectedFinalDate', 'referenceDate', 'controlDate'];

const TrygMoistureMeassurementForm: React.FC<Props> = ({ meassurements, onChange }) => {
	const { t } = useTranslation();
	// const [dates, setDates] = React.useState({
	//     paintsDate: meassurements[0]?.paintsDate,
	//     expectedFinalDate: meassurements[0]?.expectedFinalDate,
	//     referenceDate: meassurements[0]?.referenceDate,
	//     controlDate: meassurements[0]?.controlDate,
	// });

	function handleChange(idx: number, column: keyof TrygMoistureMeassurementInput, value: string) {
		const copy = meassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	// React.useEffect(() => {
	//     setDates({
	//         paintsDate: meassurements[0]?.paintsDate,
	//         expectedFinalDate: meassurements[0]?.expectedFinalDate,
	//         referenceDate: meassurements[0]?.referenceDate,
	//         controlDate: meassurements[0]?.controlDate,
	//     });
	// },[meassurements]);

	function addRow() {
		onChange([
			...meassurements,
			{
				paintsDate: meassurements[0]?.paintsDate ?? '',
				expectedFinalDate: meassurements[0]?.expectedFinalDate ?? '',
				referenceDate: meassurements[0]?.referenceDate ?? '',
				controlDate: meassurements[0]?.controlDate ?? '',
				...EMPTY_ROW(),
			},
		]);
	}

	function removeRow(idx: number) {
		onChange(meassurements.filter((_, i) => i !== idx));
	}

	const renderField = (column: keyof TrygMoistureMeassurementInput) => {
		// let name: 'paintsDate' | 'expectedFinalDate' | 'referenceDate' | 'controlDate' = 'paintsDate';
		// switch (column) {
		//     case 'paints':
		//         name = 'paintsDate';
		//         break;
		//     case 'expectedFinalValue':
		//         name = 'expectedFinalDate';
		//         break;
		//     case 'referenceValue':
		//         name = 'referenceDate';
		//         break;
		//     case 'controlValue':
		//         name = 'controlDate';
		// }

		if (column === 'paintsDate' || column === 'expectedFinalDate' || column === 'referenceDate' || column === 'controlDate') {
			return (
				<Input
					name={column}
					placeholder="Dato"
					value={meassurements[0][column] as any}
					//onChange={e => setDates({ ...dates, [name]: e.target.value })}
					onChange={e => handleChange(0, column, e.target.value)}
				/>
			);
		}
	};

	return (
		<div className="w-full">
			<FormFieldHeader title={t('reports.moistureMeassurement.title')} />

			<div className="flex gap-2">
				{TABLE_COLUMNS.map(column => (
					<div key={'label' + column} className="flex-1">
						<FormFieldHeader title={t(`reports.trygMoistureMeassurement.${column}`)} />
					</div>
				))}
			</div>

			{/* <div className="flex gap-2 py-2">
                {TABLE_COLUMNS.map((column, i) => (
                    <div key={'input' + column} className="flex flex-1">
                        {renderField(column)}
                        {i === TABLE_COLUMNS.length - 1 && <p className="text-red invisible cursor-pointer pl-2 pt-2 text-xl font-bold">x</p>}
                    </div>
                ))}
            </div> */}

			{meassurements.map((meassurement, idx) => (
				<>
					{idx === 0 && (
						<div className="flex gap-2 py-2">
							{[...TABLE_COLUMNS.slice(4), ...DATE_COLUMN].map((column, i) => (
								<div key={'input' + column} className="flex flex-1">
									{renderField(column)}
									{i === TABLE_COLUMNS.length - 1 && <p className="text-red invisible cursor-pointer pl-2 pt-2 text-xl font-bold">x</p>}
								</div>
							))}
						</div>
					)}
					<div className="flex gap-2 py-2">
						{TABLE_COLUMNS.map((column, i) => (
							<div key={'input2' + column + i} className="flex flex-1">
								<Input name={column} value={meassurement[column] ?? ''} onChange={e => handleChange(idx, column, e.target.value)} />

								{i === TABLE_COLUMNS.length - 1 && (
									<p className="text-red cursor-pointer pl-2 pt-2 text-xl font-bold" onClick={() => removeRow(idx)}>
										x
									</p>
								)}
							</div>
						))}
					</div>
				</>
			))}

			<Button className="mt-2" onClick={addRow} primary>
				{t('reports.addMoistureMeassurement')}
			</Button>
		</div>
	);
};

export default TrygMoistureMeassurementForm;
