import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { drivingSlipSchema } from '../../../Schemas/DrivingSlipSchema';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseViewRiskEvaluationSchema } from '../../../Schemas/CaseViewRiskEvaluationSchema';
import { IRiskEvaluationAnswers } from '../../../Schemas/ICaseCreation';
import { faCalendar, faFileUpload, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { toBase64, stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import {
	CreateDrivingSlip,
	CreateDrivingSlipVariables,
	DrivingSlipInput,
	GetWebCase,
	GetSingleCase_case,
	GetWebDrivingSlips_drivingSlips,
	UpdateWebDrivingSlip,
	UpdateWebDrivingSlipVariables,
	GetRiskEvaluationCategories,
	GetRiskEvaluationQuestions,
	UpdateCase,
	UpdateCaseVariables,
	AddCaseFileToDrivingSlip,
	AddCaseFileToDrivingSlipVariables,
	GetDrivingSlipFiles,
	GetCaseFiles,
	UploadDrivingSlipFile,
	UploadDrivingSlipFileVariables,
	GetWebCaseAdminData_locations,
	GetWebCaseAdminData_departments,
	GetWebCaseAdminData,
	GetDrivingSlipFilesVariables,
	GetCaseFilesVariables,
	GetWebCaseVariables,
	GetUsersWithCalendars,
	GetAdminSettings,
	GetAdminSettings_setting_DrivingSlipCategoryMappingSettings,
} from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Datepicker from '@ssg/common/Components/Datepicker';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import AddExistingFileToDrivingSlip from '../../DrivingSlips/AddExistingFileToDrivingSlip';
import Dropdown from '@ssg/common/Components/Dropdown';
import TextButton from '@ssg/common/Components/TextButton';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import TimePickerBandit from './TimePickerBandit';
import DriverCarPicker, { DriverCarInput, IdLabel, driverAndCarIsDefined, getCarLabel, idLabelIsDefined } from './DriverCarPicker';
import { DateTime, Interval } from 'luxon';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

const CREATE_DRIVINGSLIP = loader('src/GraphQL/DrivingSlips/CreateDrivingSlip.gql');
const UPDATE_DRIVINGSLIP = loader('src/GraphQL/DrivingSlips/UpdateWebDrivingSlip.gql');

const GET_RISK_EVALUATION_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');
const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');
const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');
const UPDATE_CASE = loader('src/GraphQL/Cases/UpdateCase.gql');
const GET_DRIVING_SLIP_FILES = loader('src/GraphQL/Files/GetDrivingSlipFiles.gql');
const UPLOAD_FILE = loader('src/GraphQL/Files/UploadDrivingSlipFile.gql');
const GET_CASE_FILES = loader('src/GraphQL/Files/GetCaseFiles.gql');
const ADD_CASE_FILE_TO_DRIVING_SLIP = loader('src/GraphQL/Files/AddCaseFileToDrivingSlip.gql');
const GET_SANITIZERS = loader('src/GraphQL/Planner/GetUsersWithCalendars.gql');
const GET_SETTINGS = loader('src/GraphQL/Settings/GetAdminSettings.gql');
interface FormInput extends Omit<DrivingSlipInput, 'driver' | 'end'> {
	plannedDuration?: number;
	immediatelyPlan: boolean;
}

interface Props {
	caseData: GetSingleCase_case;
	data?: GetWebDrivingSlips_drivingSlips;
	open: boolean;
	close: () => void;
	copy?: boolean;
	edit?: boolean;
	locationsData: GetWebCaseAdminData_locations[];
	departmentsData: GetWebCaseAdminData_departments[];
	categories: GetWebCaseAdminData['drivingSlipCategories'];
}

interface Question {
	questionId: string;
	question: string;
	helpText: string;
	answer: boolean;
}

interface Category {
	categoryName: string;
	questions: Question[];
}

const getInitialDriverCar = (data?: GetWebDrivingSlips_drivingSlips): DriverCarInput => {
	if (typeof data === 'undefined') {
		return {};
	}

	return {
		car: data.car ? { id: data.car.vehicleNumber, label: getCarLabel(data.car) } : undefined,
		driver: data.driver ? { id: data.driver.id, label: data.driver.name } : undefined,
	};
};

const DrivingSlipModal: React.FC<Props> = ({ caseData, data, open, close, copy = false, edit = false, locationsData, departmentsData, categories }) => {
	const { t } = useTranslation();
	const newPlannerMessageFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_MESSAGE);
	const newPlannerAddDriverCarToDrivingSlipFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_ADD_DRIVER_CAR_TO_DRIVINGSLIP);
	const newPlannerimmediatelyPlanOnEditFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_IMMEDIATELY_PLAN_ON_EDIT);
	const newPlannerHalfDayFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_HALF_DAY);
	const [driverSearchText, setDriverSearchText] = useDebouncedState('', 100);

	const [multipleDays, setMultipleDays] = React.useState(false);
	const additionalDaysFromInputRef = React.useRef<HTMLInputElement>(null);
	const additionalDaysToInputRef = React.useRef<HTMLInputElement>(null);
	const [additionalDays, setAdditionalDays] = React.useState<string[]>([]);
	// For new DriverCar add to drivingslips
	const [driversCars, setDriversCars] = React.useState<DriverCarInput[]>([getInitialDriverCar(data)]);
	const [selectedMaterials, setSelectedMaterials] = React.useState<IdLabel[]>((data?.materials ?? []).map(m => ({ id: m.vehicleNumber, label: getCarLabel(m) })));
	const [shouldFilter, setShouldFilter] = React.useState<boolean>(true);

	const updateDriversCars = (index: number, driverCar: DriverCarInput) => setDriversCars(current => {
		return [
			...current.slice(0, index),
			{
				...driverCar,
			},
			...current.slice(index + 1),
		];
	});

	const removeDriverCar = (index: number) => setDriversCars(current => current.filter((_, i) => i !== index));

	React.useEffect(() => setAdditionalDays([]), [multipleDays]);

	const [drivers, setDrivers] = React.useState<Array<{ id: string; label: string }>>(
		typeof data?.driver !== 'undefined' && data.driver !== null ? [{ id: data.driver.id, label: data.driver.name }] : [],
	);

	const [editRiskEval, setEditRiskEval] = React.useState<boolean>(false);
	const [noRiskEval, setNoRiskEval] = React.useState<boolean>(true);

	/*
	 * fetching settings
	 */
	const { data: settingsData } = useQuery<GetAdminSettings>(GET_SETTINGS, {
		variables: {
			type: 'DrivingSlipCategoryMappings',
		},
	});

	const exisitingDCSMSettings = useMemo(() => {
		if (settingsData?.setting && settingsData.setting.length > 0) {
			const setting = settingsData.setting[0] as GetAdminSettings_setting_DrivingSlipCategoryMappingSettings;

			return setting;
		}
	}, [settingsData]);

	const categoryValue = exisitingDCSMSettings?.drivingSlipCategoryMappings.find(x => x.damageCauseId === data?.case.damage.cause.id && x.damageCategoryId === data.case.damage.category.id);

	const [createDrivingSlip, { loading: createDrivingSlipSubmitting }] = useMutation<CreateDrivingSlip, CreateDrivingSlipVariables>(CREATE_DRIVINGSLIP);
	const [updateDrivingSlip, { loading: updateDrivingSlipSubmitting }] = useMutation<UpdateWebDrivingSlip, UpdateWebDrivingSlipVariables>(UPDATE_DRIVINGSLIP);

	const [uploadDrivingSlipFile] = useMutation<UploadDrivingSlipFile, UploadDrivingSlipFileVariables>(UPLOAD_FILE);
	const [addDrivingSlipFile] = useMutation<AddCaseFileToDrivingSlip, AddCaseFileToDrivingSlipVariables>(ADD_CASE_FILE_TO_DRIVING_SLIP);

	const searchSelectHandler = (value: string): void => {
		if (value.length === 0 || drivers.some(d => d.id === value)) {
			return;
		}

		const driver = searchedUsers.find(su => su.value === value);
		if (typeof driver !== 'undefined') {
			setDrivers(drivers => [...drivers, { id: driver.value, label: driver.label }]);
		}
	};

	const { data: usersData, loading: usersDataLoading } = useQuery<GetUsersWithCalendars>(GET_SANITIZERS, {
		fetchPolicy: 'cache-and-network',
	});
	const sanitizers = React.useMemo(() => usersData?.usersWithCalendars ?? [], [usersData]);

	const searchedUsers: SelectOption[] = React.useMemo(() => {
		let thisSanitizers = sanitizers;
		const fuse = new Fuse(sanitizers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name'],
		});

		if (driverSearchText.length > 0) {
			thisSanitizers = fuse.search(driverSearchText).map(v => v.item);
		}

		const mappedSanitizers = thisSanitizers.map((s): SelectOption => ({ value: s.id, label: s.name }));
		return mappedSanitizers;
	}, [sanitizers, driverSearchText]);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		errors,
		formState: { isDirty },
	} = useForm<FormInput>({
		resolver: yupResolver(drivingSlipSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (drivingSlip: FormInput) => {
		const endDate = new Date(drivingSlip.start);
		endDate.setHours(new Date(drivingSlip.start).getHours() + (drivingSlip.plannedDuration ?? 1)); // Default is one hour duration
		const filteredDrivers = driversCars.map(dc => dc.driver).filter(idLabelIsDefined);
		const driversToPost = newPlannerAddDriverCarToDrivingSlipFlag ? filteredDrivers : drivers;
		const driverCarList = newPlannerAddDriverCarToDrivingSlipFlag ? driversCars : [];

		if (edit && typeof data !== 'undefined') {
			try {
				await updateDrivingSlip({
					variables: {
						id: data.id,
						driver: driversToPost.length > 0 ? driversToPost[0].id : null,
						car: newPlannerAddDriverCarToDrivingSlipFlag ? driverCarList.find(dcl => driversToPost[0]?.id === dcl.driver?.id)?.car?.id ?? null : null,
						materials: selectedMaterials.map(m => m.id),
						estimatedHours: drivingSlip.plannedDuration,
						plannerMessage: drivingSlip.plannerMessage,
						start: new Date(drivingSlip.start).toISOString(),
						end: endDate.toISOString(),
						urgent: drivingSlip.urgent,
						comment: drivingSlip.comment,
						deadline: drivingSlip.deadline,
						additionalDays: additionalDays,
						location: drivingSlip.location,
						department: drivingSlip.department,
						category: drivingSlip.category,
						additionalDrivers: driversToPost.slice(1).map(driver => driver.id),
						eventId: data.eventReferenceId,
						driverCarList: driverAndCarIsDefined(driverCarList).map(dc => ({ car: dc.car.id, driver: dc.driver.id })),
						immediatelyPlan: drivingSlip.immediatelyPlan,
						halfDay: drivingSlip.halfDay,
					},
				});
				close();
			} catch (e) {
				console.log(e);
			}
		} else if (!edit) {
			try {
				await createDrivingSlip({
					variables: {
						caseId: caseData.id,
						drivingSlip: {
							input: {
								driver: driversToPost.length > 0 ? driversToPost[0].id : null,
								estimatedHours: drivingSlip.plannedDuration,
								plannerMessage: drivingSlip.plannerMessage,
								start: new Date(drivingSlip.start).toISOString(),
								end: endDate.toISOString(),
								urgent: drivingSlip.urgent,
								comment: drivingSlip.comment,
								deadline: drivingSlip.deadline,
								location: drivingSlip.location,
								department: drivingSlip.department,
								category: drivingSlip.category,
								series: copy ? data?.series : undefined,
								materials: selectedMaterials.map(m => m.id),
								halfDay: drivingSlip.halfDay,
							},
							immediatelyPlan: drivingSlip.immediatelyPlan,
							additionalDays: additionalDays,
							additionalDrivers: driversToPost.slice(1).map(driver => driver.id),
							// TODO: IMPLEMENT DRIVERCARS
							driverCars: driverAndCarIsDefined(driverCarList).map(dc => ({ car: dc.car.id, driver: dc.driver.id })),
						},
					},
					update: async (cache, { data: cacheData }) => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const caseErpNo = caseData.erpNo;
						const series = cacheData.createDrivingSlip[0].series;

						// Upload local files
						await Promise.all(
							files.map(async f => {
								const fileAsString = await toBase64(f);
								const fileAsByteArray = stringToByteArray(fileAsString);

								await uploadDrivingSlipFile({
									variables: {
										caseNo: caseErpNo,
										drivingSlipId: series,
										file: fileAsByteArray,
										fileName: f.name,
									},
									update: (cache, { data: cacheData }): void => {
										if (typeof cacheData === 'undefined' || cacheData === null) {
											return;
										}

										const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
											query: GET_DRIVING_SLIP_FILES,
											variables: {
												caseNo: caseErpNo,
												drivingSlipId: series,
											},
										});

										if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
											return;
										}

										cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
											query: GET_DRIVING_SLIP_FILES,
											variables: {
												caseNo: caseErpNo,
												drivingSlipId: series,
											},
											data: {
												drivingSlipFiles: [...cachedRequest.drivingSlipFiles, cacheData.uploadDrivingSlipFile],
											},
										});
									},
								});
							}),
						);

						// Upload case files
						await Promise.all(
							caseFiles.map(cf =>
								addDrivingSlipFile({
									variables: {
										caseFolder: caseErpNo,
										seriesFolder: series,
										fileName: cf,
									},
									update: (cache, { data: cacheData }): void => {
										if (typeof cacheData === 'undefined' || cacheData === null) {
											return;
										}

										const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
											query: GET_DRIVING_SLIP_FILES,
											variables: {
												caseNo: caseErpNo,
												drivingSlipId: series,
											},
										});

										if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
											return;
										}

										cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
											query: GET_DRIVING_SLIP_FILES,
											variables: {
												caseNo: caseErpNo,
												drivingSlipId: series,
											},
											data: {
												drivingSlipFiles: [...cachedRequest.drivingSlipFiles, cacheData.addCaseFileToDrivingSlip],
											},
										});

										const cachedRequestCaseFile = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
											query: GET_CASE_FILES,
											variables: {
												caseErpNo: caseErpNo,
											},
										});

										if (cachedRequestCaseFile === null || cachedRequestCaseFile.caseFiles === null) {
											return;
										}

										cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
											query: GET_CASE_FILES,
											variables: {
												caseErpNo: caseErpNo,
											},
											data: {
												caseFiles: cachedRequestCaseFile.caseFiles.filter(fil => fil.name !== cacheData.addCaseFileToDrivingSlip.name),
											},
										});
									},
								}),
							),
						);

						const cachedRequest = cache.readQuery<GetWebCase, GetWebCaseVariables>({
							query: GET_CASE,
							variables: {
								id: caseData.id,
							},
						});

						if (cachedRequest === null || cachedRequest.case === null) {
							return;
						}

						cache.writeQuery<GetWebCase, GetWebCaseVariables>({
							query: GET_CASE,
							variables: {
								id: caseData.id,
							},
							data: {
								case: {
									...cachedRequest.case,
									drivingSlipSeries: [
										...cachedRequest.case.drivingSlipSeries,
										{
											id: cacheData.createDrivingSlip[0].series,
											completed: false,
											drivingSlips: cacheData.createDrivingSlip,
										},
									],
								},
							},
						});
					},
				});
				close();
			} catch (e) {
				console.log(e);
			}
		}
	};

	const selectedLocation = useWatch({
		control,
		name: 'location',
		defaultValue: data?.location.id ?? caseData.ssgLocation.id,
	});
	const locations = React.useMemo(() => {
		return locationsData.map((l): SelectOption => ({ label: l.name, value: l.id }));
	}, [locationsData]);

	const departments = React.useMemo(() => {
		if (selectedLocation === '') {
			return [];
		}
		return departmentsData
			.filter(d => d.location.id === selectedLocation)
			.map(
				(d): SelectOption => ({
					label: `${d.name} (${d.departmentNumber})`,
					value: d.id,
				}),
			);
	}, [departmentsData, selectedLocation]);

	const halfDayWatch = useWatch({
		control,
		name: 'halfDay',
		defaultValue: data?.halfDay ?? false,
	});

	// React.useEffect(() => {
	// 	if (halfDayWatch) {
	// 		setValue('plannedDuration', 3.75, {
	// 			shouldValidate: true,
	// 		});
	// 	} else {
	// 		setValue('plannedDuration', undefined, {
	// 			shouldValidate: true,
	// 		});
	// 	}
	// }, [halfDayWatch, setValue]);

	const [updateCase, { loading: updateCaseLoading }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const {
		handleSubmit: handleSubmitRiskEval,
		errors: errorsRiskEval,
		register: registerRiskEval,
	} = useForm<IRiskEvaluationAnswers>({
		resolver: yupResolver(CaseViewRiskEvaluationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmitRiskEval = async (data: IRiskEvaluationAnswers) => {
		try {
			await updateCase({
				variables: {
					id: caseData.id ?? '',
					riskEvaluationAnswers: data.answers.filter(a => a !== null && typeof a !== 'undefined'),
				},
			});
			setEditRiskEval(false);
			setNoRiskEval(false);
		} catch (e) {
			console.log(e);
		}
	};

	const startDateWatch = useWatch({
		control,
		name: 'start',
		defaultValue: data?.start,
	});
	const changeDeadline = (): void => {
		setValue('deadline', startDateWatch?.substring(0, 10), {
			shouldValidate: true,
		});
		// Substring 0 - 10 is yyyy-MM-dd
	};

	const additionalStartDate = React.useMemo(() => {
		if (typeof startDateWatch === 'undefined') {
			return;
		}
		return DateTime.fromISO(startDateWatch).plus({ days: 1 }).toISODate();

	}, [startDateWatch]);

	const { data: questionsData } = useQuery<GetRiskEvaluationQuestions>(GET_RISK_EVALUATION_QUESTIONS);
	const { data: categoriesData } = useQuery<GetRiskEvaluationCategories>(GET_RISK_EVALUATION_CATEGORIES);

	const categoryMap = new Map<string, Category>();

	categoriesData?.riskEvaluationCategories.forEach(c => {
		questionsData?.riskEvaluationQuestions.forEach(q => {
			if (q.category.id === c.id) {
				if (caseData.riskEvaluationAnswers && caseData.riskEvaluationAnswers.length > 0) {
					caseData.riskEvaluationAnswers.forEach(a => {
						if (a.question.id === q.id) {
							if (categoryMap.has(c.id)) {
								categoryMap.set(c.id, {
									categoryName: c.categoryName,
									questions: [
										...(categoryMap.get(c.id)?.questions ?? []),
										{
											questionId: q.id,
											question: q.question,
											helpText: q.helpText,
											answer: a.answer,
										},
									],
								});
							} else {
								categoryMap.set(c.id, {
									categoryName: c.categoryName,
									questions: [
										{
											questionId: q.id,
											question: q.question,
											helpText: q.helpText,
											answer: a.answer,
										},
									],
								});
							}
						}
					});
				} else {
					if (categoryMap.has(c.id)) {
						categoryMap.set(c.id, {
							categoryName: c.categoryName,
							questions: [
								...(categoryMap.get(c.id)?.questions ?? []),
								{
									questionId: q.id,
									question: q.question,
									helpText: q.helpText,
									answer: false,
								},
							],
						});
					} else {
						categoryMap.set(c.id, {
							categoryName: c.categoryName,
							questions: [
								{
									questionId: q.id,
									question: q.question,
									helpText: q.helpText,
									answer: false,
								},
							],
						});
					}
				}
			}
		});
	});
	const categoryArray = Array.from(categoryMap.values());

	const [showAddFilesModal, setAddShowFilesModal] = React.useState(false);
	const [files, setFiles] = React.useState<File[]>([]);
	const [caseFiles, setCaseFiles] = React.useState<string[]>([]);

	return (
		<>
			<Modal
				title={(data && copy && 'drivingSlips.copy') || (data && !copy && 'drivingSlips.update') || 'drivingSlips.create'}
				close={close}
				visible={open}
				size={ModalSize.LARGE}
				body={
					<>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex flex-row">
								<div className="w-1/2">
									<Checkbox title="common.urgent" name="urgent" innerRef={register} errorMessage={errors.urgent?.message} defaultChecked={data?.urgent} />
								</div>
								<div className="w-1/2 pl-8">
									<Checkbox title="drivingSlips.multipleDays" name="multipleDays" onChange={e => setMultipleDays(e.target.checked)} />
								</div>
							</div>

							<div className="flex flex-row">
								<div className={multipleDays ? 'w-1/2' : 'w-full'}>
									<Datepicker
										title="case.drivingDateTime"
										name="start"
										time
										required
										errorMessage={errors.start?.message ?? ''}
										innerRef={register}
										onChange={changeDeadline}
										defaultValue={data?.start ? formatDateForInput(new Date(data.start), true) : formatDateForInput(new Date(new Date().setHours(7, 0, 0)), true)}
									/>
									<Datepicker
										title="common.deadline"
										name="deadline"
										required
										errorMessage={errors.deadline?.message ?? ''}
										innerRef={register}
										defaultValue={data?.deadline ? formatDateForInput(new Date(data.deadline)) : formatDateForInput(new Date())}
									/>

									<div className="flex w-full">
										<div className="mr-3 w-1/2">
											<Dropdown
												required
												name="location"
												title="common.location"
												data={[{ label: '', value: '' }, ...locations]}
												innerRef={register}
												defaultValue={copy || edit ? data?.location.id : caseData.ssgLocation.id}
											/>
										</div>
										<div className="w-1/2">
											<Dropdown
												required
												name="department"
												title="common.department"
												data={[{ label: '', value: '' }, ...departments]}
												innerRef={register}
												defaultValue={copy || edit ? data?.department.id : caseData.ssgDepartment.id}
											/>
										</div>
									</div>

									<div className="w-full">
										<Dropdown
											name="category"
											title="common.category"
											data={[
												{ label: '', value: '' },
												...categories.map(({ code, name }) => ({
													label: name,
													value: code,
												})),
											]}
											innerRef={register}
											defaultValue={copy || edit ? data?.category ?? '' : categoryValue?.drivingSlipCategory ?? ''}
										/>
									</div>

									<div className="flex w-full">
										<div className="mr-3 w-1/2 flex-row flex items-end">
											<div className="w-full">
												<Input
													title="case.drivingEstimatedTime"
													type="number"
													name="plannedDuration"
													innerRef={register}
													unitClassName="w-full lg:w-full"
													errorMessage={errors.plannedDuration?.message ?? ''}
													unit="common.hours"
													step={0.01}
													lang="da"
													inputMode="decimal"
													defaultValue={data?.estimatedHours ?? undefined}
													readOnly={halfDayWatch}
												/>
											</div>
											{newPlannerHalfDayFlag &&
												<div className="w-32 pb-3 ml-1">
													<Checkbox
														name="halfDay"
														title="drivingSlips.halfDay"
														innerRef={register}
														className="mt-1"
														defaultChecked={data?.halfDay}
														onChange={(e) => {
															if (e.currentTarget.checked) {
																setValue('plannedDuration', 3.75, {
																	shouldValidate: true,
																});
															} else {
																setValue('plannedDuration', undefined, {
																	shouldValidate: true,
																});
															}
														}}
													/>
												</div>
											}
										</div>
										<div className="w-1/2">
											{newPlannerMessageFlag &&
												<Input
													title="drivingSlips.plannerMessage"
													name="plannerMessage"
													innerRef={register}
													unitClassName="w-full lg:w-full"
													defaultValue={data?.plannerMessage ?? undefined}
													placeholder="drivingSlips.plannerMessagePlaceholder"
												/>
											}
										</div>
									</div>


									<div className="flex flex-row flex-wrap">

										{
											newPlannerAddDriverCarToDrivingSlipFlag
												?
												<div className="w-full">
													{driversCars.map((dc, index) => <DriverCarPicker date={startDateWatch} selectedMaterials={selectedMaterials} setSelectedMaterials={setSelectedMaterials} showAddMaterials={true} locationName={locations.find(l => selectedLocation === l.value)?.label ?? ''} key={index} control={control} index={index} updateDriversCars={updateDriversCars} driverCar={dc} removeDriverCar={removeDriverCar} setShouldFilter={setShouldFilter} shouldFilter={shouldFilter} />)}
													<TextButton icon={faPlus} text='drivingSlips.addDriver' onClick={() => setDriversCars(current => [...current, {}])} />
												</div>
												:
												<div
													className="w-1/2"
												>
													<SearchableSelect
														control={control}
														name="driver"
														title="case.drivenBy"
														key="label"
														options={searchedUsers ?? []}
														searchFn={searchText => setDriverSearchText(searchText)}
														onSelect={value => searchSelectHandler(value)}
														onBlur={clearFn => clearFn()}
														minInputLength={2}
														isLoading={usersDataLoading}
													/>
												</div>
										}

										{(!edit || newPlannerimmediatelyPlanOnEditFlag) && (
											<div className="my-1 w-full">
												<Checkbox
													name="immediatelyPlan"
													title="drivingSlips.immediatelyPlan"
													errorMessage={errors.immediatelyPlan?.message}
													innerRef={register}
													className="mt-1"
												/>
											</div>
										)}
										{!newPlannerAddDriverCarToDrivingSlipFlag &&
											<ul className="w-full">
												{drivers.map(d => (
													<li key={d.id} className="my-2">
														<div className="inline-block">
															<TextButton icon={faTrash} onClick={() => setDrivers(current => current.filter(ad => ad.id !== d.id))} />
														</div>
														<span className="ml-1">{d.label}</span>
													</li>
												))}
											</ul>
										}
									</div>

									<Textarea
										title="drivingSlips.commentForUser"
										name="comment"
										rows={3}
										innerRef={register}
										errorMessage={errors.comment?.message ?? ''}
										defaultValue={(copy || edit) && data?.comment ? data.comment : ''}
									/>

								</div>

								{multipleDays && (
									<div className="w-1/2 pl-8">
										<FormFieldHeader title="drivingSlips.drivingDateTimeAddNew" />
										<div className="flex flex-row">
											<div className="w-2/5">
												<Datepicker
													innerRef={additionalDaysFromInputRef}
													title="drivingSlips.drivingDateTimeFrom"
													name="additionalDaysAdd"
													min={additionalStartDate}
													defaultValue={additionalStartDate}
													className="lg:w-full"
												/>
											</div>
											<div className="w-2/5 ml-1">
												<Datepicker
													innerRef={additionalDaysToInputRef}
													title="drivingSlips.drivingDateTimeTo"
													name="additionalDaysAdd"
													min={additionalStartDate}
													defaultValue={additionalStartDate}
													className="lg:w-full"
												/>
											</div>
											<div className="w-1/5">
												<Button
													icon={faPlus}
													text="common.add"
													className="mt-7"
													onClick={() => {
														const from = additionalDaysFromInputRef.current?.valueAsDate;
														const to = additionalDaysToInputRef.current?.valueAsDate;

														if (typeof from !== 'undefined' && from !== null && typeof to !== 'undefined' && to !== null) {
															const fromDate = DateTime.fromJSDate(from);
															const toDate = DateTime.fromJSDate(to);
															const numberOfDays = Interval.fromDateTimes(fromDate, toDate).length('days');
															const arr = Array.from({ length: numberOfDays + 1 }, (_, i) => formatDateForInput(fromDate.plus({ days: i }).toJSDate()));
															setAdditionalDays(current => {
																const updated = [...new Set([...current, ...arr])];
																updated.sort((a, b) => a.localeCompare(b));
																return updated;
															});

															if (additionalDaysFromInputRef.current !== null) {
																additionalDaysFromInputRef.current.value = '';
															}
															if (additionalDaysToInputRef.current !== null) {
																additionalDaysToInputRef.current.value = '';
															}
														}
													}}
												/>
											</div>
										</div>

										<ul className="mt-4 space-y-2">
											<li className="text-sm font-semibold">{t('common.start')}</li>
											{typeof startDateWatch !== 'undefined' && (
												<li>
													<FontAwesomeIcon
														icon={faCalendar}
														style={{
															marginLeft: 1,
															marginRight: 1,
														}}
													/>
													<span className="ml-2">{startDateWatch.length > 0 ? dateToDateTimeString(new Date(startDateWatch)) : t('common.noneSpecified')}</span>
												</li>
											)}

											<li>
												<hr className="border-gray-200" />
											</li>

											<li className="text-sm font-semibold">{t('drivingSlips.additionalDays')}</li>
											{additionalDays.map(d => (
												<li key={d}>
													<div className="inline-block">
														<TextButton icon={faTrash} onClick={() => setAdditionalDays(current => current.filter(ad => ad !== d))} />
													</div>
													<span className="ml-1">{dateToDateTimeString(d, true)}</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>

							{!edit && typeof caseData !== 'undefined' && (
								<div className="flex flex-row flex-wrap">
									<div className="w-full">
										<FormFieldHeader title="common.files" />
									</div>

									<div className="w-1/2">
										<label className="rounded-default border-1 text-blue cursor-pointer border-gray-300 p-1 focus:outline-none">
											<input
												type="file"
												onChange={e => {
													const file = (e.target.files ?? [])[0];
													if (typeof file !== 'undefined') {
														setFiles(current => [...current.filter(cf => cf.name !== file.name), file]);
													}
												}}
												className="hidden"
											/>

											<span>
												<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
												{t('common.chooseFile')}
											</span>
										</label>

										<ul className="mt-4 space-y-1">
											{files.map(f => (
												<li key={f.name}>
													<div className="inline-block">
														<TextButton icon={faTrash} onClick={() => setFiles(current => current.filter(ef => ef.name !== f.name))} />
													</div>
													<span className="ml-1">{f.name}</span>
												</li>
											))}
										</ul>
									</div>

									<div className="w-1/2">
										<TextButton
											icon={faFileUpload}
											text="drivingSlips.addFilesFromCase"
											onClick={() => setAddShowFilesModal(true)}
											className="rounded-default border-1 text-blue cursor-pointer border-gray-300 p-1 focus:outline-none"
										/>
										<AddExistingFileToDrivingSlip
											open={showAddFilesModal}
											close={() => setAddShowFilesModal(false)}
											upload={files => setCaseFiles(files)}
											caseErpNo={caseData.erpNo}
											excludeFileNames={[]}
										/>

										<ul className="mt-4 space-y-1">
											{caseFiles.map(cf => (
												<li key={cf}>
													<div className="inline-block">
														<TextButton icon={faTrash} onClick={() => setCaseFiles(current => current.filter(ecf => ecf !== cf))} />
													</div>
													<span className="ml-1">{cf}</span>
												</li>
											))}
										</ul>
									</div>
								</div>
							)}

							<div className="mt-3 flex flex-row justify-between">
								<Button submit success text={edit ? 'drivingSlips.update' : 'drivingSlips.create'} loading={edit ? updateDrivingSlipSubmitting : createDrivingSlipSubmitting} />

								<Button text="case.editRiskEvaluation" onClick={() => setEditRiskEval(true)} className="border-1 border-gray-300" />
							</div>
							{isDirty && (
								<div className="flex justify-end">
									<p className="text-red">{t('common.changesNotSaved')}</p>
								</div>
							)}
						</form>
					</>
				}
			/>

			<Modal
				close={() => {
					setEditRiskEval(false);
					setNoRiskEval(false);
				}}
				visible={noRiskEval || editRiskEval}
				title="drivingSlips.mustHaveRiskEvaluation"
				size={ModalSize.XLARGE}
				body={
					<div>
						<form onSubmit={handleSubmitRiskEval(onSubmitRiskEval)} className={classNames('flex flex-col justify-between')}>
							<FormFieldHeader title="" />
							<div
								className={classNames('flex h-full flex-wrap', {
									'border-red rounded-default border-2 px-1': errorsRiskEval.answers,
								})}
							>
								{categoryArray.map((c, index) => (
									<div key={c.categoryName} className="w-1/3">
										<FormFieldHeader title={c.categoryName} />
										{c.questions.map((q, i) => (
											<div key={i}>
												<Checkbox
													name={`answers[${index + 1}${i}].answer`}
													title={q.question}
													labelWeight={'NONE'}
													popoverInteractive
													labelInfoPopover={
														<div
															dangerouslySetInnerHTML={{
																__html: sanitizeHtml(q.helpText, {
																	allowedAttributes: {
																		'*': ['style', 'href', 'rel', 'target'],
																	},
																}),
															}}
														/>
													}
													innerRef={registerRiskEval}
													defaultChecked={q.answer}
												/>
												<Input name={`answers[${index + 1}${i}].question`} value={q.questionId} innerRef={registerRiskEval} readOnly className="hidden" />
											</div>
										))}
									</div>
								))}
							</div>

							{errorsRiskEval.answers && (
								<div className="mt-2">
									<FormErrorText text="error.minOneSelected" />
								</div>
							)}

							<div className="flex flex-row space-x-2">
								<Button success submit text="common.save" loading={updateCaseLoading} className="mt-3" />
								{!noRiskEval && (
									<Button
										danger
										text="common.cancel"
										onClick={() => {
											setEditRiskEval(false);
											setNoRiskEval(false);
										}}
										className="mt-3 mr-3"
									/>
								)}
							</div>
						</form>
					</div>
				}
			/>
		</>
	);
};
export default DrivingSlipModal;
