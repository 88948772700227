import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	GetSingleCase_case,
	GetWebMachinesOnCase,
	GetWebMachinesOnCaseVariables,
	GetWebMachinesOnCase_machinesOnCase,
	GetMachinesPreviouslyOnCase,
	GetMachinesPreviouslyOnCaseVariables,
	MachineConsumptionType,
	MoveWebMachine,
	MoveWebMachineVariables,
	UpdateCase,
	UpdateCaseVariables,
	GetMachinesPreviouslyOnCase_machinesPreviouslyOnCase,
} from '../../../GraphQL';
import { DateTime } from 'luxon';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { faAlarmClock } from '@fortawesome/pro-regular-svg-icons';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import Button from '@ssg/common/Components/Button';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Loading from '@ssg/common/Components/Loading';
import Modal from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import Table from '@ssg/common/Components/Table';
import { dateDiffInDays } from '../../../helper';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const daysOnCase = (date: Date): number | undefined => {
	const days = DateTime.fromJSDate(date).diffNow('days').toObject();

	if (days.days !== undefined) return Math.round(Math.abs(days.days));

	return undefined;
};

const UPDATE_CASE = loader('src/GraphQL/Cases/UpdateCase.gql');
const GET_MACHINES_ON_CASE = loader('src/GraphQL/Machines/GetWebMachinesOnCase.gql');
const MOVE_MACHINE = loader('src/GraphQL/Machines/MoveWebMachine.gql');
const GET_MACHINES_PREVIOUSLY_ON_CASE = loader('src/GraphQL/Machines/GetMachinesPreviouslyOnCase.gql');

const MachinesTable: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const newMachineStatusFlag = useFlag(FeatureFlagEnums.NEW_MACHINE_STATUS);

	const [notifications, setNotifications] = useState(false);
	const [hibernation, setHibernation] = useState(false);
	const [machineData, setMachineData] = useState<GetWebMachinesOnCase_machinesOnCase>();

	const { refetch, data: machines } = useQuery<GetWebMachinesOnCase, GetWebMachinesOnCaseVariables>(GET_MACHINES_ON_CASE, {
		variables: {
			erpNo: caseData.erpNo,
		},
	});

	const { data: historyData } = useQuery<GetMachinesPreviouslyOnCase, GetMachinesPreviouslyOnCaseVariables>(GET_MACHINES_PREVIOUSLY_ON_CASE, {
		variables: { erpNo: caseData.erpNo },
		fetchPolicy: 'no-cache',
	});

	const [moveMachine, { loading: moveMachineLoading }] = useMutation<MoveWebMachine, MoveWebMachineVariables>(MOVE_MACHINE);
	const [updateCase, { loading: updateCaseLoading }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);
	const [hibernationReasonText, setHibernationReasonText] = React.useState('');

	const setMachineHibernation = async () => {
		if (typeof machineData !== 'undefined') {
			try {
				await moveMachine({
					variables: {
						machineInput: {
							hibernate: !machineData.hibernate,
							allocationType: 'Job',
							erpCaseReference: machineData.eRPCaseReference ?? '',
							erpLocationReference: machineData.eRPLocationReference ?? '',
							erpReferenceNo: machineData.erpReferenceNo,
							consumption: machineData.consumption,
							startingDate: dateToDateOnlyString(new Date(machineData.dateStart) < new Date() ? new Date() : new Date(machineData.dateStart)),
							placementDescription: machineData.placementDescription,
							startingTime: DateTime.now().toFormat('HH:mm:ss'),
							hibernationReason: hibernationReasonText,
							unavailableReason: machineData.unavailableReason,
						},
						caseERPreferenceNo: caseData.erpNo,
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
		refetch();
		setHibernation(false);
	};

	const setMachineNotifications = async () => {
		try {
			await updateCase({
				variables: {
					id: caseData.id,
					machineNotification: !caseData.machineNotification,
				},
			});
		} catch (e) {
			console.log(e);
		}

		setNotifications(false);
	};

	const getConsumptionString = (consumptionType: MachineConsumptionType, consumption: number, consumptionFactor: number): string => {
		if (consumptionType === MachineConsumptionType.HOURS) {
			if (consumptionFactor === 0) {
				return `${consumption} ${t('machines.consumptionTypes.HOURS')} (Kwh-faktor mangler i BC)`;
			}
			return `${(consumption * consumptionFactor).toFixed(2)} ${t('machines.consumptionTypes.KWH')}`;
		}

		return `${consumption.toFixed(2)} ${t('machines.consumptionTypes.' + consumptionType)}`;
	};

	const historyActuallyHibernate = (historyMachine: GetMachinesPreviouslyOnCase_machinesPreviouslyOnCase): { status: string; comment?: string } => {
		const isOnCase = machines?.machinesOnCase.find(m => m.erpReferenceNo === historyMachine.erpReferenceNo);
		const severalHistory = (historyData?.machinesPreviouslyOnCase ?? []).filter(m => m.erpReferenceNo === historyMachine.erpReferenceNo);
		const arr = [...severalHistory];
		if (isOnCase) {
			arr.push(isOnCase);
		}

		const lastIndex = arr.length - 1;
		const thisIndex = arr.findIndex(m => m.startTime === historyMachine.startTime && m.dateStart === historyMachine.dateStart && m.dateEnd === historyMachine.dateEnd);

		if (lastIndex === thisIndex) return { status: 'machines.takenDown' };

		if (arr[thisIndex + 1].hibernate) return { status: 'machines.hibernationStart', comment: arr[thisIndex + 1].hibernationReason };

		return { status: 'machines.hibernationEnd' };
	};

	return (
		<div className="h-full">
			{typeof machines?.machinesOnCase === 'undefined' || machines?.machinesOnCase.length < 0 ? (
				<div className="relative h-40">
					<Loading />
				</div>
			) : (
				<div className="flex h-full flex-col justify-between">
					<div className="flex flex-col">
						<div className="h-40 overflow-auto">
							<Table
								data={machines.machinesOnCase}
								keySelector={m => m.erpReferenceNo}
								noDataFoundText={t('machines.noActiveMachinesFound')}
								columns={[
									{
										label: t('machines.no'),
										selectFn: m => m.erpReferenceNo,
									},
									{
										label: t('common.description'),
										selectFn: m => <p>{m.name}</p>,
									},
									{
										label: t('common.placement'),
										selectFn: m => <p>{m.placementDescription}</p>,
									},
									{
										label: t('machines.daysOnCase'),
										selectFn: m => <p>{daysOnCase(new Date(m.dateStart))}</p>,
									},
									{
										label: t('machines.consumption'),
										selectFn: m => <p> - </p>,
									},
									{
										label: t('common.date'),
										selectFn: m => <p>{dateToDateTimeString(m.dateStart, true)}</p>,
									},
									// Removed for now
									// {
									//     label: t('machines.nextService'),
									//     selectFn: m => (
									//         <div>
									//         {m.serviceDate !== '0001-01-01T00:00:00' && new Date(m.serviceDate) < new Date() && (
									//             <div>
									//                 <p className="flex py-1 text-red">
									//                     <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" />
									//                     <p className="font-medium">{t('machines.serviceOverdue')}</p>
									//                 </p>
									//             </div>
									//         )}
									//         {m.serviceDate !== '0001-01-01T00:00:00'
									//             ?
									//                 dateToDateOnlyString(new Date(m.serviceDate))
									//             :
									//                 <p>{t('machines.noServiceOverdue')}</p>
									//         }
									//     </div>
									//     ),
									// },
									{
										label: t('common.status'),
										selectFn: m => (
											<div>
												<p>{m.hibernate ? t('machines.hibernation') : t('common.active')}</p>
												{m.hibernate && <p>{m.hibernationReason}</p>}
											</div>
										),
									},
									{
										label: t('machines.hibernation'),
										selectFn: m => (
											<Button
												icon={m.hibernate ? faPlay : faPause}
												onClick={() => {
													setMachineData(m);
													setHibernation(true);
												}}
												className="bg-blue text-white"
											/>
										),
										hideColumn: caseViewExternal,
									},
								]}
							/>
						</div>
						<p className="self-center text-2xl">{t('common.history')}</p>
						<div className="h-32 overflow-auto">
							<Table
								data={historyData?.machinesPreviouslyOnCase ?? []}
								keySelector={m => `${m.erpReferenceNo}-${Math.random().toString(36).substring(2)}`}
								noDataFoundText={t('machines.noPreviousMachinesFound')}
								columns={[
									{
										label: t('machines.no'),
										selectFn: m => <p className="font-bold">{m.erpReferenceNo}</p>,
									},
									{
										label: t('common.description'),
										selectFn: m => <p>{m.name}</p>,
									},
									{
										label: t('common.placement'),
										selectFn: m => <p>{m.placementDescription}</p>,
									},
									{
										label: t('machines.daysOnCase'),
										selectFn: m => <p>{dateDiffInDays(new Date(m.dateStart), new Date(m.dateEnd))}</p>,
									},
									{
										label: t('machines.consumption'),
										selectFn: m => <p>{getConsumptionString(m.consumptionType, m.usageMeterEnd - m.consumption, m.consumptionFactor)}</p>,
									},
									{
										label: t('common.date'),
										selectFn: m => (
											<p>
												{dateToDateTimeString(m.dateStart, true)} - {dateToDateTimeString(m.dateEnd, true)}
											</p>
										),
									},
									// Removed for now
									// {
									//     label: t('machines.nextService'),
									//     selectFn: m => (
									//         <div>
									//             {m.serviceDate != null && new Date(m.serviceDate) < new Date() ? (
									//                 <div className="text-red">
									//                     <FontAwesomeIcon icon={faExclamationCircle} size="lg" /> {t('machines.serviceOverdue')}
									//                 </div>
									//             ) : (
									//                 <div>{m.serviceDate}</div>
									//             )}
									//         </div>
									//     ),
									// },
									{
										label: 'common.status',
										hideColumn: newMachineStatusFlag,
										selectFn: m => (
											<div>
												<p>{t('machines.takenDown')}</p>
												{m.hibernate && <p>{m.hibernationReason}</p>}
											</div>
										),
									},
									{
										label: 'common.status',
										hideColumn: !newMachineStatusFlag,
										selectFn: m => {
											const newStatus = historyActuallyHibernate(m);
											return (
												<div>
													<p>{t(newStatus.status)}</p>
													{newStatus.comment && <p>{t(newStatus.comment)}</p>}
												</div>
											);
										},
									},
								]}
							/>
						</div>
					</div>
					<div className="mr-2 flex place-content-end">
						{!caseViewExternal && (
							<Button
								primary
								icon={faAlarmClock}
								text={caseData.machineNotification ? 'case.machineNotificationsOff' : 'case.machineNotificationsOn'}
								onClick={() => setNotifications(true)}
							/>
						)}
					</div>
				</div>
			)}

			{hibernation && (
				<Modal
					title={machineData?.hibernate ? 'machines.wantToResume' : 'machines.wantToHibernate'}
					visible={hibernation}
					close={() => setHibernation(false)}
					body={
						<div className="text-blue">
							<p>
								<span className="font-semibold">{t('machines.daysOnCase')}: </span>
								{daysOnCase(new Date(machineData?.dateStart ?? ''))}
							</p>
							{!machineData?.hibernate && (
								<Input
									required
									name="machines.hibernationReason"
									title="machines.hibernationReasonQuestion"
									onChange={e => setHibernationReasonText(e.currentTarget.value)}
									maxLength={50}
								/>
							)}
							<div className="mt-2 flex space-x-1">
								<Button
									success
									text="common.confirm"
									loading={moveMachineLoading}
									onClick={() => setMachineHibernation()}
									disabled={hibernationReasonText.length === 0 && !machineData?.hibernate}
								/>
								<Button danger text="common.cancel" onClick={() => setHibernation(false)} className="mr-2" />
							</div>
						</div>
					}
				/>
			)}

			{notifications && (
				<Modal
					title={caseData.machineNotification ? 'case.machineNotificationsOff' : 'case.machineNotificationsOn'}
					visible={notifications}
					close={() => setNotifications(false)}
					body={
						<div className="text-blue">
							<div className="flex justify-end">
								<Button danger text="common.cancel" onClick={() => setNotifications(false)} className="mr-2" />
								<Button success text="common.confirm" loading={updateCaseLoading} onClick={() => setMachineNotifications()} />
							</div>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default MachinesTable;
