import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
	/*GetDrivingSlips,*/
	GetWebCurrentUser,
	SetUserDrivingSlipFilter,
	SetUserDrivingSlipFilterVariables,
	RemoveUserDrivingSlipFilter,
	RemoveUserDrivingSlipFilterVariables,
	GetWebCaseAdminData,
	GetDrivingSlipsPlanner,
} from '../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { DrivingSlipsFilter } from './Planner';
import { GetWebDebitors } from '../../GraphQL';
import { useForm } from 'react-hook-form';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Select from 'react-select';
import Input from '@ssg/common/Components/Input';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Pill from '@ssg/common/Components/Pill';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import UserContext from '../../UserContext';
import dk from '@ssg/common/Assets/postalcodes/DK.json';
import no from '@ssg/common/Assets/postalcodes/NO.json';
import se from '@ssg/common/Assets/postalcodes/SE.json';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const GET_DEBITORS = loader('../../GraphQL/Debitors/GetWebDebitors.gql');
const SET_USER_DRIVING_SLIP_FILTER = loader('src/GraphQL/Users/SetUserDrivingSlipFilter.gql');
const REMOVE_USER_DRIVING_SLIP_FILTER = loader('src/GraphQL/Users/RemoveUserDrivingSlipFilter.gql');

interface Props {
	showFilters: boolean;
	data: GetDrivingSlipsPlanner['drivingSlips'];
	filters: DrivingSlipsFilter;
	setFilters: React.Dispatch<React.SetStateAction<DrivingSlipsFilter>>;
	clearFilters: () => unknown;
	setFilterTerm: (v: string) => void;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserFilter: string;
	selectData: GetWebCaseAdminData;
}

const DrivingSlipFilters: React.FC<Props> = ({ showFilters, data, filters, setFilters, clearFilters, setFilterTerm, setActiveUserFilter, activeUserFilter, selectData }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const planningForm = useForm();

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	//const [postalCodeValues, setPostalCodeValues] = useDebouncedState([''], 100);

	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(filters.name ?? '');

	const debitorMapRef = React.useRef<Record<string, string>>({});

	const deadlineRef = React.useRef<HTMLInputElement>(null);
	const createdAtRef = React.useRef<HTMLInputElement>(null);

	const [createUserFilter] = useMutation<SetUserDrivingSlipFilter, SetUserDrivingSlipFilterVariables>(SET_USER_DRIVING_SLIP_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserDrivingSlipFilter, RemoveUserDrivingSlipFilterVariables>(REMOVE_USER_DRIVING_SLIP_FILTER);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					debitors: filters.debitors ?? [],
					postalCodeText: filters.postalCodeText ?? '',
					postalCodes: filters.postalCodes ?? [],
					departments: filters.departments ?? [],
					locations: filters.locations ?? [],
					damageCategories: filters.damageCategories ?? [],
					damageCauses: filters.damageCauses ?? [],
					erpNos: filters.erpNos ?? [],
					projectManagers: filters.projectManagers ?? [],
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postUserFilter = async (filterValues: SetUserDrivingSlipFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserDrivingSlipFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.drivingSlipFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							drivingSlipFilters: [cachedRequest.currentUser?.drivingSlipFilters, data.setUserDrivingSlipFilter.drivingSlipFilters],
						},
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserDrivingSlipFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const resetFilters = () => {
		clearFilters();

		setFilters({
			name: null,
			locations: [],
			departments: [],
			debitors: [],
			damageCauses: [],
			damageCategories: [],
			postalCodeText: '',
			postalCodes: [],
			deadline: undefined,
			createdAt: undefined,
			projectManagers: [],
			erpNos: [],
		});

		if (deadlineRef.current !== null) {
			deadlineRef.current.value = '';
		}

		if (createdAtRef.current !== null) {
			createdAtRef.current.value = '';
		}

		//setPostalCodeValues([]);
		setActiveUserFilter('');
	};

	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: { searchText: debitorSearchText },
		onCompleted(data) {
			data.debitors.forEach(d => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
		skip: debitorSearchText.length === 0,
	});

	const getProjectManagers = () =>
		data
			.map(p => p.case.projectManager)
			.filter(p => p !== null)
			.filter((c, i, a) => a.findIndex(ac => ac?.id === c?.id) === i)
			.map(p => ({ value: p?.id ?? '', label: p?.name ?? '' }));

	const locationList = selectData.locations;
	const departmentList = selectData.departments;
	const damageCause = selectData.damageCauses;
	const damageCategory = selectData.damageCategories;

	const resolvePostalCodes = (postalCodes: string[]) => {
		const resolvedPostalCodesList: string[] = [];

		const postalCodesListDK = Object.keys(dk);
		const postalCodesListNO = Object.keys(no);
		const postalCodesListSE = Object.keys(se);
		const postalCodesListAsNumbersDK = postalCodesListDK.map(p => parseInt(p));
		const postalCodesListAsNumbersNO = postalCodesListNO.map(p => parseInt(p));
		const postalCodesListAsNumbersSE = postalCodesListSE.map(p => parseInt(p.replace(' ', '')));

		postalCodes?.forEach(p => {
			if (p.includes('-')) {
				const splitP = p.split('-');
				const postalCodesBetweenP = postalCodesListAsNumbersDK.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString());
				postalCodesBetweenP.concat(postalCodesListAsNumbersNO.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString()));
				postalCodesBetweenP.concat(
					postalCodesListAsNumbersSE
						.filter(p => parseInt(splitP[0].replace(' ', '')) <= p && p <= parseInt(splitP[1].replace(' ', '')))
						.map(p => {
							const pString = p.toString();
							return pString.substring(0, 3) + ' ' + pString.substring(3);
						}),
				);
				resolvedPostalCodesList.push(...postalCodesBetweenP);
			} else {
				resolvedPostalCodesList.push(p);
			}
		});

		const uniqueAndSortedPostalCodes = Array.from(new Set(resolvedPostalCodesList)).sort((a, b) =>
			a.localeCompare(b, undefined, {
				numeric: true,
				sensitivity: 'base',
			}),
		);

		return uniqueAndSortedPostalCodes;
	};
	const drivingSlipFilters = React.useMemo(() => userContext.user?.drivingSlipFilters ?? [], [userContext.user?.drivingSlipFilters]);

	return (
		<div>
			<div>
				<div className="relative my-3 mr-3 w-full">
					<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
					<Input
						name="overview-search"
						placeholder={t('caseOverview.filters.searchPlaceholder')}
						className="pr-8 text-sm lg:w-full"
						style={{ height: '36px', minWidth: '220px' }}
						onChange={(e): void => setFilterTerm(e.target.value)}
					/>
				</div>

				<div className="order-2 lg:order-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.quickFilter')}</label>
					<div className="flex flex-wrap justify-between">
						<Dropdown
							name="drivingslips-quick-filters"
							onChange={e => {
								setActiveUserFilter(e.target.value);
								if (e.target.value === '') {
									resetFilters();
								} else {
									const filter = drivingSlipFilters.find(f => f.name === e.target.value);
									if (filter) {
										setFilters({ ...filter, deadline: undefined, createdAt: undefined });
									}
								}
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...drivingSlipFilters.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? [],
							]}
							className="flex-grow-1 mb-3 w-full pr-8 lg:w-auto"
							style={{ maxWidth: '300px', height: 34 }}
							value={activeUserFilter}
						/>

						<div className="mb-3 flex flex-wrap">
							<Button
								secondary={filters.name !== '' || typeof filters.name !== 'undefined'}
								disabled={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null}
								text={t('caseOverview.filters.deleteFilter')}
								onClick={() => setDeleteFilter(true)}
								className="px-5 py-2 text-xs"
								style={{ height: 34 }}
							/>

							<Button
								primary
								text={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? t('caseOverview.filters.saveFilter') : t('caseOverview.filters.updateFilter')}
								onClick={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
								className="ml-3 px-5 py-2 text-xs"
								style={{ height: 34 }}
							/>
						</div>
					</div>
				</div>

				{showFilters && (
					<div className="mt-1 flex w-full flex-wrap">
						<div className="w-1/2">
							<label className="text-blue mb-1 mr-3 block text-xs font-medium">
								{t('common.location')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('planner.noLocations')}
									className="react-select-custom text-xs"
									value={
										filters.locations.map(l => ({
											value: l,
											label: l,
										})) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values =>
										setFilters(() => ({
											...filters,
											locations: values?.map(v => v.value) ?? [],
										}))
									}
									options={locationList.map(l => ({
										value: l.name,
										label: l.name,
									}))}
								/>
							</label>

							<label className="text-blue mb-1 mr-3 block text-xs font-medium">
								{t('common.debitor')}
								<SearchableSelect
									key="debitor-filter"
									control={planningForm.control}
									name="debitorFilter"
									options={
										debitorsData?.debitors
											.filter(d => d.debitorId !== null)
											.filter((d, i, a) => a.indexOf(d) === i)
											.map(d => ({
												value: d.company,
												label: d.company,
											})) ?? []
									}
									searchFn={searchText => setDebitorSearchText(searchText)}
									onSelect={value => {
										if (value !== '')
											setFilters(() => ({
												...filters,
												debitors: [...(filters.debitors ?? []), value],
											}));
									}}
									onBlur={clearFn => clearFn()}
									minInputLength={2}
									isLoading={debitorsLoading}
									className="react-select-custom text-xs lg:w-full"
								/>
							</label>

							{filters.debitors.length > 0 && (
								<ul className="block">
									{filters.debitors?.map(d => (
										<li key={d} className="m-1">
											<Pill
												onClick={() =>
													setFilters(() => ({
														...filters,
														debitors: filters.debitors?.filter(ed => ed !== d),
													}))
												}
												className="text-xs"
											>
												{debitorMapRef.current[d]} ({d})
											</Pill>
										</li>
									))}
								</ul>
							)}

							<label className="text-blue mb-1 mr-3 block text-xs font-medium">
								{t('common.damageCategory')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('planner.noCategories')}
									className="react-select-custom text-xs"
									value={
										filters.damageCategories.map(v => {
											const d = damageCategory.find(category => category.id === v);
											return {
												value: d?.id ?? '',
												label: d?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values =>
										setFilters(() => ({
											...filters,
											damageCategories: values?.map(v => v.value) ?? [],
										}))
									}
									options={damageCategory.map(c => ({
										value: c.id,
										label: c.name,
									}))}
								/>
							</label>

							<label className="text-blue mb-1 mr-3 block text-xs font-medium">
								{t('case.caseNo')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect
									menuPortalTarget={document.body}
									className="react-select-custom text-xs"
									value={
										filters.erpNos.map(d => ({
											value: d,
											label: d,
										})) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values =>
										setFilters(() => ({
											...filters,
											erpNos: values?.map(v => v.value) ?? [],
										}))
									}
									options={data.map(c => ({
										value: c.case.erpNo,
										label: c.case.erpNo,
									}))}
								/>
							</label>

							<label className="text-blue mb-1 mr-3 block text-xs font-medium">
								<Datepicker
									innerRef={deadlineRef}
									title={t('common.deadline')}
									xsmallTitle
									titleClass="mt-0"
									name="plannedStart"
									onChange={value =>
										setFilters(() => ({
											...filters,
											deadline: value.target.value ?? '',
										}))
									}
									value={filters.deadline}
									style={{ fontSize: '0.75rem' }}
								/>
							</label>
						</div>

						<div className="w-1/2">
							<label className="text-blue mb-1 block text-xs font-medium">
								{t('common.department')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('planner.noDepartments')}
									className="react-select-custom text-xs"
									value={
										filters.departments.map(d => ({
											value: d,
											label: d,
										})) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values =>
										setFilters(() => ({
											...filters,
											departments: values?.map(v => v.value) ?? [],
										}))
									}
									options={departmentList.map(l => ({
										value: l.name,
										label: `${l.name} (${l.departmentNumber})`,
									}))}
								/>
							</label>

							<label className="text-blue mb-1 block text-xs font-medium">
								{t('common.postalcode')}
								<input
									type="text"
									className="border-1 rounded-default block w-full border-gray-600 p-1 text-sm focus:outline-none"
									value={filters.postalCodeText}
									onChange={values => {
										//setPostalCodeValues(values.target.value.trim().split(','));
										setFilters(filters => ({
											...filters,
											postalCodeText: values.target.value,
											postalCodes: values.target.value !== '' ? resolvePostalCodes(values.target.value.trim().split(',')) ?? [] : [],
										}));
									}}
								/>
							</label>

							<label className="text-blue mb-1 block text-xs font-medium">
								{t('case.damageCause')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('planner.noCauses')}
									className="react-select-custom text-xs"
									value={
										filters.damageCauses.map(v => {
											const d = damageCause.find(causes => causes.id === v);
											return {
												value: d?.id ?? '',
												label: d?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values =>
										setFilters(() => ({
											...filters,
											damageCauses: values?.map(v => v.value) ?? [],
										}))
									}
									options={damageCause.map(c => ({
										value: c.id,
										label: c.name,
									}))}
								/>
							</label>

							<label className="text-blue mb-1 block text-xs font-medium">
								{t('case.ssgProjectManager')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('common.noCasesFound')}
									className="react-select-custom text-xs"
									value={
										filters.projectManagers.map(v => {
											const d = data.map(d => d.case.projectManager).find(projectManagers => projectManagers?.id === v);
											return {
												value: d?.id ?? '',
												label: d?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
										option: base => ({
											...base,
											fontSize: '0.75rem',
										}),
										noOptionsMessage: base => ({
											...base,
											fontSize: '0.75rem',
										}),
									}}
									onChange={values => {
										setFilters(() => ({
											...filters,
											projectManagers: values?.map(v => v.value) ?? [],
										}));
									}}
									options={getProjectManagers()}
								/>
							</label>

							<div className="w-full">
								<label className="text-blue mb-1 block text-xs font-medium">
									<Datepicker
										innerRef={createdAtRef}
										title={t('common.created')}
										xsmallTitle
										titleClass="mt-0"
										name="drivinglipCreated"
										onChange={value =>
											setFilters(() => ({
												...filters,
												createdAt: value.target.value ?? '',
											}))
										}
										value={filters.createdAt}
										style={{ fontSize: '0.75rem' }}
									/>
								</label>
							</div>
						</div>

						<div className="w-full">
							<Button secondary text={t('common.resetFilter')} className="mb-1 mt-3 text-xs" onClick={resetFilters} />
						</div>
					</div>
				)}
			</div>

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{filters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(filters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default DrivingSlipFilters;
