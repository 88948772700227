import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const RequisitionerSchema = yup.object().shape({
	requisitionerName: yup.string().required(),

	requisitionerPhone: yup.string().required(),

	requisitionerEmail: yup
		.string()
		.email()
		.when('noEmail', {
			is: (noEmail: boolean) => noEmail,
			then: yup.string().email(),
			otherwise: yup.string().email().required(),
		}),

	noEmail: yup.boolean(),

	requisitionerRelation: yup.string(),
});
