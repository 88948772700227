import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	GetRejectedTimeTrackingsForUser,
	GetRejectedTimeTrackingsForUserVariables,
	GetTimeTrackings_timeTrackings,
	OpenRejectedDay,
	OpenRejectedDayVariables,
	OpenRejectedDay_openRejectedDay,
	SearchWebUsers,
	SearchWebUsersVariables,
	TimeTrackingStatus,
} from '../../GraphQL';
import { Permissions } from '@ssg/common/GraphQL';
import { SelectOption, formatDate } from '@ssg/common/Helpers/Helpers';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import RejectedRegistrationModal from './RejectedRegistrationModal';
import UserContext from '../../UserContext';
import { useTranslation } from 'react-i18next';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useForm } from 'react-hook-form';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';

const GET_REJECTED_TIME_TRACKINGS = loader('src/GraphQL/TimeTracking/GetRejectedTimeTrackings.gql');
const OPEN_REJECTED_DAY = loader('src/GraphQL/TimeTracking/OpenRejectedDay.gql');
const SEARCH_USERS = loader('src/GraphQL/Users/SearchWebUsers.gql');

interface MergedTrackingItems {
	id: string;
	date: string;
	trackings: GetTimeTrackings_timeTrackings[];
}

const RejectedRegistrationsOverview: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const rejectedHoursUserSelect = useFlag(FeatureFlagEnums.REJECTED_HOURS_USER_SELECT);

	const [selectedDate, setSelectedDate] = useState<Date>();
	const [data, setData] = useState<OpenRejectedDay_openRejectedDay[]>();

	const [openRejectedDay] = useMutation<OpenRejectedDay, OpenRejectedDayVariables>(OPEN_REJECTED_DAY);

	const [searchUserText, setSearchUserText] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState('');

	const { data: users, loading: loadingUsers } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: searchUserText,
			onlyEmployees: true,
			checkInBc: true,
		},
		skip: searchUserText === '',
	});

	const searchedUsers = React.useMemo(() => users?.searchUsers.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [], [users]);

	const userId = selectedUser ? selectedUser : userContext.user?.id ?? '';

	const { data: rejectedTimeTrackingData, loading: loadingRejectedTimeTracking } = useQuery<GetRejectedTimeTrackingsForUser, GetRejectedTimeTrackingsForUserVariables>(GET_REJECTED_TIME_TRACKINGS, {
		variables: { user: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '' },
		fetchPolicy: 'network-only',
	});

	const { control: userListControl } = useForm();

	const rejectedTimeTrackings = React.useMemo(() => {
		return rejectedTimeTrackingData?.rejectedTimeTrackingsForUser ?? [];
	}, [rejectedTimeTrackingData]);

	const trackingBundle = rejectedTimeTrackings.reduce((trackingBundle, item) => {
		const { id, date } = item;

		let bundle = trackingBundle.find(i => i.date === item.date);

		if (typeof bundle === 'undefined') {
			const index = trackingBundle.push({ id, date, trackings: [] }) - 1;
			bundle = trackingBundle[index];
		}

		bundle.trackings.push(item);

		return trackingBundle;
	}, [] as Array<MergedTrackingItems>);

	const rejectedItems = (trackings: GetTimeTrackings_timeTrackings[]): number => {
		const timetrackings = trackings.reduce((acc, curr) => acc + (curr.timeTrackingStatus === TimeTrackingStatus.REJECTED ? 1 : 0), 0);
		const addonLines = trackings.flatMap(a => a?.addonLines ?? []).reduce((acc, curr) => acc + (curr?.timeTrackingStatus === TimeTrackingStatus.REJECTED ? 1 : 0), 0);

		return timetrackings + addonLines;
	};

	return (
		<div>
			<BoxContainer>
				<div className="flex w-full">
					<Box full>
						{rejectedHoursUserSelect && userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER) && (
							<div className="mb-4 w-64">
								<SearchableSelect
									control={userListControl}
									name="user"
									title="common.employeeName"
									options={searchedUsers.filter(u => u.label.toLowerCase().includes(searchUserText.toLowerCase()))}
									searchFn={searchText => setSearchUserText(searchText)}
									onSelect={value => {
										setSelectedUser(value);
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={loadingUsers}
								/>
								<p className="text-xs font-semibold">
									{t('common.selectedSubstituteNo')}: {users?.searchUsers.filter(u => u.id === selectedUser)[0]?.employeeId ?? ''}
								</p>
							</div>
						)}
						<div>
							<Table
								data={trackingBundle ?? []}
								columns={[
									{
										label: 'common.date',
										numeric: true,
										selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
										sortFn: (a, b) => a.date.localeCompare(b.date),
									},
									{
										label: 'timeRegistration.rejectedTimeRegistrations',
										selectFn: t => <p>{rejectedItems(t.trackings)}</p>,
									},
								]}
								keySelector={tb => tb.id}
								onRowClick={async tb => {
									try {
										setData(
											(
												await openRejectedDay({
													variables: {
														userEmail: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
														date: tb.date,
													},
												})
											).data?.openRejectedDay,
										);
										setSelectedDate(new Date(tb.date));
									} catch (e) {
										console.log(e);
										alert(t('error.timereg'));
									}
								}}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={typeof rejectedTimeTrackingData === 'undefined' && loadingRejectedTimeTracking}
							/>
						</div>
					</Box>
				</div>
			</BoxContainer>
			{selectedDate != null && (
				<RejectedRegistrationModal
					date={selectedDate}
					open={true}
					close={() => setSelectedDate(undefined)}
					data={data}
					userId={userId}
					refetch={async date =>
						setData(
							(
								await openRejectedDay({
									variables: {
										userEmail: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
										date: formatDateForInput(date),
									},
								})
							).data?.openRejectedDay,
						)
					}
				/>
			)}
		</div>
	);
};

export default RejectedRegistrationsOverview;
