import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface Props {
	unselectable?: boolean;
	items: {
		title: string;
		isActive: boolean;
		onClick: () => unknown;
		disabled?: boolean;
		hide?: boolean;
	}[];
	className?: string;
}

const ButtonGroup: React.FC<Props> = ({ items, className, unselectable = false }) => {
	const { t } = useTranslation();
	const itemsLength = items.length - 1;

	return (
		<div className={classNames('border-1 rounded-default inline-flex overflow-hidden border-gray-600', className)}>
			{items
				.filter(item => !item.hide)
				.map((item, index) => (
					<button
						key={item.title}
						onClick={() => {
							item.onClick();
						}}
						disabled={item.disabled}
						className={classNames(
							'hover:bg-blue flex items-center justify-center whitespace-nowrap px-3 py-2 text-xs font-medium hover:bg-opacity-95 hover:text-white focus:outline-none',
							{
								'rounded-r-none': index === 0,
								'rounded-l-none rounded-r-none': index !== 0 && index !== itemsLength,
								'rounded-l-none': index === itemsLength,
								'bg-blue text-white': item.isActive,
								'text-blue bg-white': !item.isActive,
								'px-5': !unselectable,
							},
						)}
					>
						<p
							className={classNames({
								'px-2': !item.isActive && unselectable,
							})}
						>
							{t(item.title)}
						</p>{' '}
						{unselectable && item.isActive && (
							<div className="flex h-4 w-4 items-center justify-center">
								<FontAwesomeIcon icon={faTimesCircle} size="sm" className="text-white" />
							</div>
						)}
					</button>
				))}
		</div>
	);
};

export default ButtonGroup;
