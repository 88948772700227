import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import { GetCatalogChangesVariables, GetCatalogContactChanges, GetCatalogContactChanges_catalogContact_changes_after, GetCatalogContactChanges_catalogContact_changes_before } from '../../../GraphQL';
import { useQuery } from '@apollo/client';
import Loading from '@ssg/common/Components/Loading';
const GET_CATALOG_CONTACT_CHANGES = loader('src/GraphQL/CatalogContacts/GetCatalogContactChanges.gql');

interface Props {
	contactId: string;
	open: boolean;
	close: () => void;
}

const CallContactChangeLog: React.FC<Props> = ({ contactId, open, close }) => {
	const { t } = useTranslation();
	const { data, loading } = useQuery<GetCatalogContactChanges, GetCatalogChangesVariables>(GET_CATALOG_CONTACT_CHANGES, {
		variables: {
			id: contactId,
		},
	});

	const sorted = React.useMemo(() => (data?.catalogContact.changes ?? []).slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [data?.catalogContact.changes]);

	return (
		<>
			<Modal
				title={t('catalog.contact.contactChanges')}
				size={ModalSize.MEDIUM}
				visible={open}
				close={close}
				body={
					<>
						{loading ? (
							<div className="relative h-28 w-full">
								<Loading />
							</div>
						) : (
							<div className="text-blue space-y-4">
								{sorted.map(c => (
									<div key={c.timestamp}>
										<strong>
											{dateToDateTimeString(c.timestamp)}
											&nbsp;{t('common.by')}&nbsp;
											{c.user.name}&nbsp;({c.user.email})
										</strong>

										<div className="mt-1">{renderChanges(t, c.before, c.after)}</div>
									</div>
								))}
							</div>
						)}
					</>
				}
			/>
		</>
	);
};
export default CallContactChangeLog;

function renderChanges(t: TFunction, before: GetCatalogContactChanges_catalogContact_changes_before, after: GetCatalogContactChanges_catalogContact_changes_after): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// Type name was changed
	if (!(before.type === null && after.type === null) && before.type !== after.type) {
		const tValues = {
			before: before.type ?? NO_VALUE,
			after: after.type ?? NO_VALUE,
		};

		result.push(
			<div key="type" className="my-2">
				<Trans t={t} i18nKey="catalog.craftsman.changelog.type" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Contact name was changed
	if (!(before.contactName === null && after.contactName === null) && before.contactName !== after.contactName) {
		const tValues = {
			before: before.contactName ?? NO_VALUE,
			after: after.contactName ?? NO_VALUE,
		};

		result.push(
			<div key="contactName" className="my-2">
				<Trans t={t} i18nKey="catalog.contact.changelog.contactName" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Email was changed
	if (!(before.email === null && after.email === null) && before.email !== after.email) {
		const tValues = {
			before: before.email ?? NO_VALUE,
			after: after.email ?? NO_VALUE,
		};

		result.push(
			<div key="email" className="my-2">
				<Trans t={t} i18nKey="catalog.contact.changelog.email" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Global type was changed
	if (!(before.globalType === null && after.globalType === null) && before.globalType !== after.globalType) {
		const tValues = {
			before: t(`catalog.changelog.${before.globalType}`) ?? NO_VALUE,
			after: t(`catalog.changelog.${after.globalType}`) ?? NO_VALUE,
		};

		result.push(
			<div key="globalType" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.globalType" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Informations was changed
	if (!(before.informations === null && after.informations === null)) {
		if (before.informations?.length !== after.informations?.length) {
			let beforeString = '';
			before.informations?.forEach(afInfo => {
				beforeString += `${afInfo?.phoneNumber}${afInfo.remark ? `, ${afInfo.remark}` : ''}, `;
			});
			let afterString = '';
			after.informations?.forEach(diff => {
				afterString += `${diff?.phoneNumber}${diff.remark ? `, ${diff.remark}` : ''}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="phoneNumbers" className="my-2">
					<Trans t={t} i18nKey="catalog.contact.changelog.phoneNumbers" values={tValues} components={[<mark />]} />
				</div>,
			);
		} else if (_.difference(before.informations, after.informations ?? []).length !== 0) {
			let beforeString = '';
			before.informations?.forEach(afInfo => {
				beforeString += `${afInfo?.phoneNumber}${afInfo.remark ? `, ${afInfo.remark}` : ''}, `;
			});
			let afterString = '';
			after.informations?.forEach(diff => {
				afterString += `${diff?.phoneNumber}${diff.remark ? `, ${diff.remark}` : ''}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="phoneNumbers" className="my-2">
					<Trans t={t} i18nKey="catalog.contact.changelog.phoneNumbers" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Active was changed
	if (!(before.active === null && after.active === null) && before.active !== after.active) {
		const tValues = {
			before: before.active ? t('common.yes') : t('common.no'),
			after: after.active ? t('common.yes') : t('common.no'),
		};

		result.push(
			<div key="active" className="my-2">
				<Trans t={t} i18nKey="catalog.contact.changelog.active" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Catalogs was changed
	if (!(before.catalogs === null && after.catalogs === null)) {
		if (before.catalogs?.length !== after.catalogs?.length) {
			let beforeString = '';
			before.catalogs?.forEach(afCat => {
				beforeString += `${afCat?.propertyNumber}, `;
			});
			let afterString = '';
			after.catalogs?.forEach(diff => {
				afterString += `${diff?.propertyNumber}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="catalogs" className="my-2">
					<Trans t={t} i18nKey="catalog.timedMessage.changelog.catalogs" values={tValues} components={[<mark />]} />
				</div>,
			);
		} else if (_.difference(before.catalogs, after.catalogs ?? []).length !== 0) {
			let beforeString = '';
			before.catalogs?.forEach(afCat => {
				beforeString += `${afCat?.propertyNumber}, `;
			});
			let afterString = '';
			after.catalogs?.forEach(diff => {
				afterString += `${diff?.propertyNumber}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="catalogs" className="my-2">
					<Trans t={t} i18nKey="catalog.timedMessage.changelog.catalogs" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	return result;
}
