import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	DeleteTimedMessage,
	DeleteTimedMessageVariables,
	FileActionType,
	GetCatalog,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalogVariables,
	GetTimedMessages,
	GetTimedMessagesVariables,
	GetTimedMessages_timedMessages,
	UpdateTimedMessage,
	UpdateTimedMessageVariables,
} from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { TimedMessageFilter } from './TimedMessages';

const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');
const GET_TIMED_MESSAGES = loader('src/GraphQL/TimedMessages/GetTimedMessages.gql');
const UPDATE_TIMED_MESSAGE = loader('src/GraphQL/TimedMessages/UpdateTimedMessage.gql');
const DELETE_TIMED_MESSAGE = loader('src/GraphQL/TimedMessages/DeleteTimedMessage.gql');

interface Props {
	timedMessageData: GetTimedMessages_timedMessages;
	close(): void;
	visible: boolean;
	catalogId?: string;
	changedBy: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
	isExternalUser: boolean;
	activeFilters: TimedMessageFilter;
}

const DeleteTimedMessageModal: React.FC<Props> = ({ timedMessageData, visible, close, catalogId, changedBy, isExternalUser, activeFilters }): React.ReactElement => {
	const { t } = useTranslation();
	const [updateTimedMessage, { loading: loadingUpdate }] = useMutation<UpdateTimedMessage, UpdateTimedMessageVariables>(UPDATE_TIMED_MESSAGE);
	const [deleteTimedMessage, { loading: loadingDelete }] = useMutation<DeleteTimedMessage, DeleteTimedMessageVariables>(DELETE_TIMED_MESSAGE);

	const postDeleteTimedMessage = async () => {
		try {
			await deleteTimedMessage({
				variables: {
					id: timedMessageData.id,
					changedBy: changedBy,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedTimedMessageRequest = cache.readQuery<GetTimedMessages, GetTimedMessagesVariables>({
						query: GET_TIMED_MESSAGES,
						variables: {
							fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
							toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
							thisCustomerOnly: isExternalUser,
						},
					});

					if (cachedTimedMessageRequest !== null && cachedTimedMessageRequest.timedMessages !== null) {
						cache.writeQuery<GetTimedMessages, GetTimedMessagesVariables>({
							query: GET_TIMED_MESSAGES,
							variables: {
								fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
								toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
								thisCustomerOnly: isExternalUser,
							},
							data: {
								timedMessages: cachedTimedMessageRequest.timedMessages.filter(t => t.id !== cacheData.deleteTimedMessage),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								timedMessages: cachedCatalogRequest.catalog.timedMessages.filter(t => t.id !== cacheData.deleteTimedMessage),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const postRemoveTimedMessage = async () => {
		try {
			await updateTimedMessage({
				variables: {
					id: timedMessageData.id,
					customer: timedMessageData.customer.id ?? '',
					changedBy: changedBy,
					startTime: timedMessageData.startTime,
					endTime: timedMessageData.endTime,
					message: timedMessageData.message,
					global: timedMessageData.global,
					globalType: timedMessageData.globalType,
					active: timedMessageData.active,
					catalogs: (timedMessageData.catalogs ?? []).filter(cat => cat.id !== catalogId).map(cat => cat.id),
					catalogsBefore: timedMessageData.catalogs ? timedMessageData.catalogs.map(cat => cat.id) : [],
					action: FileActionType.REMOVE,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedTimedMessageRequest = cache.readQuery<GetTimedMessages, GetTimedMessagesVariables>({
						query: GET_TIMED_MESSAGES,
						variables: {
							fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
							toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
							thisCustomerOnly: isExternalUser,
							globalOnly: true,
						},
					});

					if (cachedTimedMessageRequest !== null && cachedTimedMessageRequest.timedMessages !== null) {
						cache.writeQuery<GetTimedMessages, GetTimedMessagesVariables>({
							query: GET_TIMED_MESSAGES,
							variables: {
								fromDate: activeFilters.fromDate ? formatDateForInput(activeFilters.fromDate) : undefined,
								toDate: activeFilters.toDate ? formatDateForInput(activeFilters.toDate) : undefined,
								thisCustomerOnly: isExternalUser,
								globalOnly: true,
							},
							data: {
								timedMessages: cachedTimedMessageRequest.timedMessages.filter(t => t.id !== cacheData.updateTimedMessage.id),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								timedMessages: cachedCatalogRequest.catalog.timedMessages.filter(t => t.id !== cacheData.updateTimedMessage.id),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const toDelete = (): boolean => {
		if (timedMessageData.global) {
			return true;
		} else if (!timedMessageData.global && (timedMessageData.catalogs?.length === 1 || !catalogId)) {
			return true;
		} else {
			return false;
		}
	};

	const deleteRemoveText = (): string => {
		if (timedMessageData.global) {
			return 'catalog.timedMessage.globalDelete';
		} else if (!timedMessageData.global && (timedMessageData.catalogs?.length === 1 || !catalogId)) {
			return 'catalog.timedMessage.catalogDelete';
		} else {
			return 'catalog.timedMessage.catalogRemove';
		}
	};
	return (
		<Modal
			title={toDelete() ? 'catalog.timedMessage.delete' : 'catalog.timedMessage.remove'}
			size={ModalSize.SMALL}
			close={() => close()}
			visible={visible}
			body={
				<div>
					<div className="flex flex-col">
						<div>
							<p>
								{t(toDelete() ? 'catalog.timedMessage.delete' : 'catalog.timedMessage.remove')} {timedMessageData.message}?
							</p>
							<p className="text-red">{t(deleteRemoveText())}</p>
						</div>

						<Button
							danger
							text={toDelete() ? 'catalog.timedMessage.delete' : 'catalog.timedMessage.remove'}
							onClick={async () => {
								if (timedMessageData.global) {
									postDeleteTimedMessage();
								} else if (!timedMessageData.global && (timedMessageData.catalogs?.length === 1 || !catalogId)) {
									postDeleteTimedMessage();
								} else {
									postRemoveTimedMessage();
								}
							}}
							className="mt-4"
							loading={loadingDelete || loadingUpdate}
							disabled={loadingDelete || loadingUpdate}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteTimedMessageModal;
