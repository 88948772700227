import React from 'react';
import { faSearch, faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	GetWebCurrentUser,
	GetWebMachineLocations_machineLocations,
	GetMachineTypes,
	MachineStatus,
	RemoveUserMachineFilter,
	RemoveUserMachineFilterVariables,
	SetUserMachineFilter,
	SetUserMachineFilterVariables,
} from '../../GraphQL';
import { MachineFilters } from './MachineOverview';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import classnames from 'classnames';
import Select from 'react-select';
import UserContext from '../../UserContext';
import Toggle from '@ssg/common/Components/Toggle';
import SearchableCaseSelect from '../../Components/Select/SearchableCaseSelect';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';

const SET_USER_MACHINE_FILTER = loader('src/GraphQL/Users/SetUserMachineFilter.gql');
const REMOVE_USER_MACHINE_FILTER = loader('src/GraphQL/Users/RemoveUserMachineFilter.gql');
const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const GET_MACHINE_TYPES = loader('src/GraphQL/Machines/GetMachineTypes.gql');

interface Props {
	activeFilters: MachineFilters;
	onFilter: React.Dispatch<React.SetStateAction<MachineFilters>>;
	setFilterTerm: (v: string) => void;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserFilter: string;
	setBulkPrint(bool: boolean): void;
	bulkPrint: boolean;
	machineLocations: GetWebMachineLocations_machineLocations[];
}

const MachineOverviewFilter: React.FC<Props> = ({ activeFilters, onFilter, setFilterTerm, setActiveUserFilter, activeUserFilter, setBulkPrint, bulkPrint, machineLocations }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const { data: machineTypes } = useQuery<GetMachineTypes>(GET_MACHINE_TYPES, {
		fetchPolicy: 'cache-and-network',
	});

	const types = React.useMemo(
		() =>
			machineTypes?.machineTypes.map(c => ({
				value: c,
				label: toSentenceCase(c),
			})),
		[machineTypes],
	);

	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(activeFilters.name ?? '');

	const [showAllFilters, setShowAllFilters] = React.useState(false);

	const [createUserFilter] = useMutation<SetUserMachineFilter, SetUserMachineFilterVariables>(SET_USER_MACHINE_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserMachineFilter, RemoveUserMachineFilterVariables>(REMOVE_USER_MACHINE_FILTER);

	React.useEffect(() => {
		setFilterSaveName(activeUserFilter);
	}, [activeUserFilter]);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					status: activeFilters.status,
					cases: activeFilters.cases,
					locations: activeFilters.locations,
					types: activeFilters.types,
					unavailable: activeFilters.unavailable,
					hibernation: activeFilters.hibernation,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postUserFilter = async (filterValues: SetUserMachineFilterVariables) => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserMachineFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.machineFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: cachedRequest.currentUser,
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserMachineFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.removeUserMachineFilter === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetWebCurrentUser>({
						query: GET_CURRENT_USER,
					});

					if (cachedRequest === null || cachedRequest.currentUser?.machineFilters === null) {
						return;
					}

					cache.writeQuery({
						query: GET_CURRENT_USER,
						data: {
							user: cachedRequest.currentUser,
						},
					});
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const resetFilters = () => {
		setActiveUserFilter('');
		onFilter({
			status: MachineStatus.ALL,
			cases: undefined,
			locations: undefined,
			types: undefined,
			unavailable: false,
			hibernation: null,
		});
	};

	const machineLocationOptions = React.useMemo<SelectOption[]>(
		() =>
			(machineLocations ?? []).map(l => ({
				label: l.name,
				value: l.locationCode,
			})),
		[machineLocations],
	);

	return (
		<div className="my-2 mx-0">
			<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
				<div className="order-1 mb-3 mr-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.tableView')}</label>
					<ButtonGroup
						items={[
							{
								title: t('machines.status.ALL'),
								isActive: activeFilters.status === MachineStatus.ALL,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: MachineStatus.ALL,
									})),
							},
							{
								title: t('machines.status.AVAILABLE'),
								isActive: activeFilters.status === MachineStatus.AVAILABLE,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: MachineStatus.AVAILABLE,
									})),
							},
							{
								title: t('machines.status.DEPLOYED'),
								isActive: activeFilters.status === MachineStatus.DEPLOYED,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: MachineStatus.DEPLOYED,
									})),
							},
						]}
					/>
				</div>

				<div className="order-3 mb-3 mr-3 lg:order-2">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
					<div className="flex justify-between">
						<div className="relative flex">
							<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
							<Input
								name="overview-search"
								placeholder={t('machines.searchPlaceholder')}
								className="pr-8 text-sm"
								style={{ minWidth: '220px' }}
								onChange={(e): void => setFilterTerm(e.target.value)}
							/>
						</div>
						<Button
							primary
							text={!showAllFilters ? t('caseOverview.filters.showAllFilters') : t('common.hideFilters')}
							icon={faSlidersH}
							className="ml-3 inline-block px-5 py-2 text-xs lg:hidden xl:hidden"
							style={{ height: '36px' }}
							onClick={() => setShowAllFilters(!showAllFilters)}
						/>
					</div>
				</div>

				<div className="order-2 mb-3 mr-3 lg:order-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.quickFilter')}</label>
					<div className="flex">
						<Dropdown
							name="overview-quick-filters"
							onChange={e => {
								setActiveUserFilter(e.target.value);
								e.target.value !== ''
									? onFilter(currentFilters => ({
											...currentFilters,
											name: e.target.value,
									  }))
									: resetFilters();
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...(userContext.user?.machineFilters?.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? []),
							]}
							className="w-full pr-8 lg:w-1/2"
							style={{ minWidth: '220px', height: 34 }}
							value={activeUserFilter}
						/>

						<Button
							secondary={activeFilters.name !== '' || typeof activeFilters.name !== 'undefined'}
							disabled={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="ml-3 px-5 py-2 text-xs"
						/>

						<Button
							primary
							text={
								activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null
									? t('caseOverview.filters.saveFilter')
									: t('caseOverview.filters.updateFilter')
							}
							onClick={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
							className="ml-3 px-5 py-2 text-xs"
						/>

						<Button secondary text={t('common.resetFilter')} className="ml-3 px-5 py-2 text-xs" onClick={resetFilters} />
					</div>
				</div>
				<div className="order-4 mb-3 mr-3 lg:order-4">
					<label className="text-blue mb-1 block text-xs font-medium">{t('machines.viewMode')}</label>
					<ButtonGroup
						items={[
							{
								title: t('machines.viewNormal'),
								isActive: !bulkPrint,
								onClick: (): void => setBulkPrint(false),
							},
							{
								title: t('machines.viewPrint'),
								isActive: bulkPrint,
								onClick: (): void => setBulkPrint(true),
							},
						]}
					/>
				</div>
			</div>

			<div
				className={classnames('mr-6 flex w-full flex-row flex-wrap', {
					'hidden lg:flex': !showAllFilters,
				})}
			>
				<div className="mr-6 flex w-full flex-row flex-wrap">
					<div className="mb-3 mr-3">
						<label className="text-blue block text-xs font-medium">{t('machines.hibernation')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.hibernation === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											hibernation: activeFilters.hibernation !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.hibernation === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											hibernation: activeFilters.hibernation !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.case')}
						<SearchableCaseSelect
							erpNo={true}
							onSelect={erpNo => {
								onFilter(currentFilters => ({
									...currentFilters,
									cases: erpNo ? [erpNo] : [],
								}));
							}}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.location')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.locations?.map(v => {
									const l = machineLocationOptions.find(location => location.value === v);
									return {
										value: l?.value ?? '',
										label: l?.label ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								onFilter(currentFilters => ({
									...currentFilters,
									locations: values?.map(v => v.value) ?? [],
								}))
							}
							options={machineLocationOptions}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.type')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.types?.map(v => {
									return {
										value: v ?? '',
										label: v ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values => {
								onFilter(currentFilters => ({
									...currentFilters,
									types: values?.map(v => v.value) ?? [],
								}));
							}}
							options={types}
						/>
					</label>

					<div className="mr-3 mb-3 whitespace-nowrap text-sm">
						<label className="text-blue mb-1 block text-xs font-medium">{t('machines.filters.showUnavailable')}</label>
						<Toggle
							name="show-unavailable"
							text=""
							onClick={() =>
								onFilter(currentFilters => ({
									...currentFilters,
									unavailable: !activeFilters.unavailable,
								}))
							}
							defaultChecked={activeFilters.unavailable}
						/>
					</div>
				</div>
			</div>

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeFilters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeFilters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default MachineOverviewFilter;
