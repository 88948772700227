import React from 'react';

function useToggleSet<T>(defaultValue?: Set<T>): [Set<T>, (value: T) => void] {
	const [state, setState] = React.useState<Set<T>>(defaultValue ?? new Set<T>());

	function toggle(value: T): void {
		setState(oldSet => {
			const newSet = new Set(oldSet);

			if (newSet.has(value)) {
				newSet.delete(value);
			} else {
				newSet.add(value);
			}

			return newSet;
		});
	}

	return [state, toggle];
}

export default useToggleSet;
