import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import React from 'react';
import { DrivingSlipsFilter, NumberOfDays, SelectedUser, VehiclesFilter } from './PlannerHelpers';
import { DateTime } from 'luxon';

export function getDates(numberOfDays: number, date: DateTime) {
	const dayBefore = date.minus({ days: 1 });
	return Array.from({ length: numberOfDays + 2 }, (_, i) => dayBefore.plus({ days: i })).map(d => d.toISODate());

}

const emptyDrivingSlipFilter: DrivingSlipsFilter = {
	name: null,
	locations: undefined,
	departments: undefined,
	debitors: [],
	damageCauses: undefined,
	damageCategories: undefined,
	postalCodeText: '',
	postalCodes: [],
	projectManagers: [],
	erpNos: [],
};

const emptyVehiclesFilter: VehiclesFilter = {
	locations: undefined,
	//type: undefined,
	departments: undefined,
};

interface PlannerContextValues {
	dates: {
		fromDate: string;
		toDate: string;
		shownDates: string[];
	} | undefined;
	date: string;
	setDate: React.Dispatch<React.SetStateAction<string>>;
	numberOfDays: NumberOfDays;
	setNumberOfDays: (number: NumberOfDays) => void;
	selectedUsers: SelectedUser[];
	setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUser[]>>;
	activeUserDrivingSlipFilter: string;
	setActiveUserDrivingSlipFilter: React.Dispatch<React.SetStateAction<string>>;
	activeDrivingSlipsFilters: DrivingSlipsFilter;
	setActiveDrivingSlipsFilters: React.Dispatch<React.SetStateAction<DrivingSlipsFilter>>;
	clearActiveDrivingSlipFilters: () => void;
	activeCarFilters: VehiclesFilter;
	setActiveCarFilters: React.Dispatch<React.SetStateAction<VehiclesFilter>>;
	activeMaterialFilters: VehiclesFilter;
	setActiveMaterialFilters: React.Dispatch<React.SetStateAction<VehiclesFilter>>;
	clearActiveMaterialFilters: () => void;
	clearActiveCarFilters: () => void;
	activeUserCarFilter: string;
	setActiveUserCarFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserMaterialFilter: string;
	setActiveUserMaterialFilter: React.Dispatch<React.SetStateAction<string>>;
}
const PlannerContext = React.createContext<PlannerContextValues>({
	date: DateTime.now().toISODate(),
	dates: undefined,
	numberOfDays: 7,
	setDate: () => console.log(),
	setNumberOfDays: () => console.log(),
	selectedUsers: [],
	setSelectedUsers: () => console.log(),
	activeUserDrivingSlipFilter: '',
	setActiveUserDrivingSlipFilter: () => console.log(),
	activeDrivingSlipsFilters: emptyDrivingSlipFilter,
	clearActiveDrivingSlipFilters: () => console.log(),
	setActiveDrivingSlipsFilters: () => console.log(),
	activeCarFilters: emptyVehiclesFilter,
	activeMaterialFilters: emptyVehiclesFilter,
	setActiveCarFilters: () => console.log(),
	setActiveMaterialFilters: () => console.log(),
	clearActiveCarFilters: () => console.log(),
	clearActiveMaterialFilters: () => console.log(),
	activeUserCarFilter: '',
	setActiveUserCarFilter: () => console.log(),
	activeUserMaterialFilter: '',
	setActiveUserMaterialFilter: () => console.log(),
});


export const PlannerContextProvider: React.FC = ({ children }): React.ReactElement => {

	// Dates 
	const [date, setDate] = useStorageState<string>(window.sessionStorage, 'plannerDate', DateTime.now().toISODate());
	const [datesStore, setDateStore] = useStorageState<string[]>(window.sessionStorage, 'plannerDates', getDates(7, DateTime.now()));
	const [numberOfDays, setNumberOfDaysStore] = useStorageState<NumberOfDays>(window.sessionStorage, 'plannerDays', 7);

	// Selecting users
	const [selectedUsers, setSelectedUsers] = useStorageState<SelectedUser[]>(window.sessionStorage, 'plannerSelectedUsers', []);

	// Driving slips
	const [activeUserDrivingSlipFilter, setActiveUserDrivingSlipFilter] = useStorageState<string>(window.sessionStorage, 'newplanner-activeUserDrivingSlipsFilters', '');
	const [activeDrivingSlipsFilters, setActiveDrivingSlipsFilters, clearActiveDrivingSlipFilters] = useStorageState<DrivingSlipsFilter>(window.sessionStorage, 'newplanner-activeDrivingSlipsFilters', emptyDrivingSlipFilter);

	// Vehicles
	const [activeUserCarFilter, setActiveUserCarFilter] = useStorageState<string>(window.sessionStorage, 'newplanner-activeUserCarFilters', '');
	const [activeUserMaterialFilter, setActiveUserMaterialFilter] = useStorageState<string>(window.sessionStorage, 'newplanner-activeUserMaterialFilters', '');

	const [activeCarFilters, setActiveCarFilters, clearActiveCarFilters] = useStorageState<VehiclesFilter>(window.sessionStorage, 'newplanner-activeCarFilters', emptyVehiclesFilter);
	const [activeMaterialFilters, setActiveMaterialFilters, clearActiveMaterialFilters] = useStorageState<VehiclesFilter>(window.sessionStorage, 'newplanner-activeMaterialFilters', emptyVehiclesFilter);

	React.useEffect(() => {
		setDateStore(getDates(numberOfDays, DateTime.fromISO(date)));
	}, [date, numberOfDays, setDateStore]);

	const dates = React.useMemo(() => {
		if (datesStore.length > 0) {
			const fromDate = datesStore[0];
			const toDate = datesStore[datesStore.length - 1];
			const shownDates = datesStore.slice(1, datesStore.length - 1);
			return {
				fromDate, toDate, shownDates,
			};
		}
		return undefined;
	}, [datesStore]);

	const context: PlannerContextValues = React.useMemo(() => ({
		dates,
		date,
		setDate,
		numberOfDays,
		setNumberOfDays: (number: NumberOfDays) => setNumberOfDaysStore(number),
		selectedUsers,
		setSelectedUsers,
		activeUserDrivingSlipFilter,
		setActiveUserDrivingSlipFilter,
		activeDrivingSlipsFilters,
		setActiveDrivingSlipsFilters,
		clearActiveDrivingSlipFilters,
		activeCarFilters,
		activeMaterialFilters,
		setActiveCarFilters,
		setActiveMaterialFilters,
		clearActiveCarFilters,
		clearActiveMaterialFilters,
		activeUserCarFilter,
		activeUserMaterialFilter,
		setActiveUserCarFilter,
		setActiveUserMaterialFilter,
	}), [activeCarFilters, activeDrivingSlipsFilters, activeMaterialFilters, activeUserCarFilter, activeUserDrivingSlipFilter, activeUserMaterialFilter, clearActiveCarFilters, clearActiveDrivingSlipFilters, clearActiveMaterialFilters, date, dates, numberOfDays, selectedUsers, setActiveCarFilters, setActiveDrivingSlipsFilters, setActiveMaterialFilters, setActiveUserCarFilter, setActiveUserDrivingSlipFilter, setActiveUserMaterialFilter, setDate, setNumberOfDaysStore, setSelectedUsers]);

	//React.useEffect(() => console.log('context', context), [context]);
	return (
		<PlannerContext.Provider value={context}>
			{children}
		</PlannerContext.Provider>
	);
};

export default PlannerContext;