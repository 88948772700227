import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogInput, GetCatalog_catalog, GetWebDebitors, GetWebDebitorsVariables } from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { CatalogEditSchema } from '../../../Schemas/CatalogEditSchema';
import { useQuery } from '@apollo/client';
import { Permissions } from '@ssg/common/GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Box from '../../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import BoxFormDataViewer from '../../../Components/Layout/BoxFormDataViewer';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Checkbox from '@ssg/common/Components/Checkbox';
import InputPhone from '@ssg/common/Components/InputPhone';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_DEBITORS = loader('src/GraphQL/Debitors/GetWebDebitors.gql');

interface Props {
	data: GetCatalog_catalog;
	saveChanges: (data: CatalogInput) => Promise<void>;
	loadingUpdate: boolean;
	changedBy: string;
}

const InformationBox: React.FC<Props> = ({ data, changedBy, saveChanges, loadingUpdate }) => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const userPermissions = React.useContext(UserContext).user?.permissions ?? [];
	const canVerify = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VERIFY]);
	const canVerifyDebitor = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_DEBITOR_VERIFY]);
	const canEditInformationBox = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_INFORMATION_BOX_EDIT]);
	const [country, setCountry] = React.useState(envVar.defaultCountry ?? '');

	const [editActive, setEditActive] = React.useState(false);
	const [showHelpText, setShowHelpText] = React.useState(false);

	const [companySearchText, setCompanySearchText] = useDebouncedState('', 500);

	const { data: debitorList } = useQuery<GetWebDebitors, GetWebDebitorsVariables>(GET_DEBITORS, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
		variables: {
			searchText: companySearchText,
		},
		skip: companySearchText === '',
	});

	const debitors = React.useMemo(() => {
		if (debitorList?.debitors) {
			return debitorList?.debitors.map(
				(d): SelectOption => ({
					value: d.debitorId,
					label: d.company + (d.gln !== null ? ` (EAN: ${d.gln})` : ''),
				}),
			);
		}
		return [];
	}, [debitorList]);

	const { handleSubmit, register, setValue, errors, control, trigger } = useForm<CatalogInput>({
		resolver: yupResolver(CatalogEditSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			contactNumber: data.contactNumber,
			ssgAccountable: data.ssgAccountable,
			smsService: data.smsService,
			contactHours: data.contactHours,
			propertyNumber: data.propertyNumber,
			customer: data.customer.id,
			debitor: data.debitor?.debitorId,
		},
	});

	const [catalogInformation, setCatalogInformation] = React.useState<SelectOption[]>();

	const onSubmit = async (formData: CatalogInput) => {
		let changeVerify = canVerify;
		if (
			!canVerify &&
			(formData.propertyNumber !== data.propertyNumber ||
				formData.ssgAccountable !== data.ssgAccountable ||
				formData.contactHours !== data.contactHours ||
				formData.contactNumber !== data.contactNumber ||
				formData.smsService !== data.smsService)
		) {
			changeVerify = false;
		} else {
			changeVerify = true;
		}
		if (canEditInformationBox) {
			await saveChanges({
				customer: data.customer.id,
				changedBy: changedBy,
				propertyNumber: formData.propertyNumber,
				smsService: formData.smsService,
				description: data.description,
				verified: changeVerify,
				contactHours: formData.contactHours,
				contactNumber: formData.contactNumber,
				ssgAccountable: formData.ssgAccountable,
				debitor: formData.debitor,
				debitorVerified: canVerifyDebitor ? true : data.debitor?.debitorId !== formData.debitor ? false : true,
			});
		}

		setEditActive(false);
	};

	React.useEffect(() => {
		setCatalogInformation([
			{ value: data.propertyNumber, label: t('catalog.propertyNumber') },
			{ value: data.customer.name, label: t('catalog.customer') },
			{
				value: !data.debitor ? t('common.notSpecified') : `${data.debitor.debitorId} - ${data.debitor.company}${data.debitor.gln ? ` - ${data.debitor.gln}` : ''}`,
				label: t('common.debitor'),
			},
			{
				value: !data.ssgAccountable || data.ssgAccountable === '' ? t('common.notSpecified') : data.ssgAccountable,
				label: t('catalog.ssgAccountable'),
			},
			{
				value: !data.contactNumber || data.contactNumber === '' ? t('common.notSpecified') : data.contactNumber,
				label: t('common.contactNumber'),
			},
			{
				value: !data.contactHours || data.contactHours === '' ? t('common.notSpecified') : data.contactHours,
				label: t('common.contactHours'),
			},
			{
				value: data.smsService ? t('common.yes') : t('common.no'),
				label: t('catalog.smsService'),
			},
		]);
	}, [data, setCatalogInformation, t]);

	React.useEffect(() => {
		setValue('customer', data.customer.name);
	}, [setValue, data]);

	return (
		<>
			<Box title="common.information" className="text-blue mb-8" half icon={canEditInformationBox ? faEdit : undefined} onClick={() => canEditInformationBox && setEditActive(true)}>
				<BoxFormDataViewer data={catalogInformation ?? []} keyId="common.information" />
			</Box>

			<Modal
				title={`${t('common.edit')} ${t('common.information').toLowerCase()}`}
				close={() => setEditActive(false)}
				helpButtonClick={() => setShowHelpText(!showHelpText)}
				showHelpText={showHelpText}
				visible={editActive}
				size={ModalSize.SMALL}
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input title="catalog.propertyNumber" name="propertyNumber" defaultValue={data.propertyNumber} innerRef={register} errorMessage={errors?.propertyNumber?.message ?? ''} />
						<div>
							<Input title="catalog.customer" name="customerName" defaultValue={data.customer.name} readOnly required />

							<Input title="" name="customer" readOnly className="invisible m-0 h-0 w-0 p-0" innerRef={register} />
						</div>

						<SearchableSelect
							key="debitorlabel"
							control={control}
							name="debitor"
							title="common.debitor"
							helpText="catalog.helpText.debitor"
							showHelpText={showHelpText}
							allowEmpty
							options={debitors ?? []}
							searchFn={val => {
								setCompanySearchText(val);
								setValue('debitor', '', {
									shouldValidate: true,
								});
							}}
							onSelect={value =>
								setValue('debitor', value, {
									shouldValidate: true,
								})
							}
							onBlur={clearFn => console.log(clearFn)}
							minInputLength={-1}
							isLoading={false}
							errorMessage={errors?.debitor?.message ? t('catalog.noDebitorsFromValue') : ''}
							initialSelection={{
								value: data?.debitor ? data.debitor.debitorId : '',
								label: data?.debitor ? `${data.debitor.debitorId}-${data.debitor.company}` : '',
							}}
						/>

						<Input title="catalog.ssgAccountable" name="ssgAccountable" defaultValue={data.ssgAccountable ?? ''} errorMessage={errors?.ssgAccountable?.message ?? ''} innerRef={register} />

						<InputPhone
							title="common.contactNumber"
							innerRef={register}
							name="contactNumber"
							errorMessage={errors?.contactNumber?.message ?? ''}
							control={control}
							trigger={trigger}
							searchFn={(phonenumber): void => {
								phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
								setValue('contactNumber', phonenumber, {
									shouldValidate: true,
								});
							}}
							onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
							defaultCountry={country}
							defaultPhoneNumber={data.contactNumber ?? ''}
						/>

						<Input title="common.contactHours" name="contactHours" defaultValue={data.contactHours ?? ''} errorMessage={errors?.contactHours?.message ?? ''} innerRef={register} />

						<Checkbox title="catalog.smsService" name="smsService" defaultChecked={data.smsService} errorMessage={errors?.smsService?.message ?? ''} innerRef={register} className="py-2" />

						<Button success submit text={`${t('common.edit')} ${t('common.information').toLowerCase()}`} disabled={loadingUpdate} loading={loadingUpdate} className="pl-4" />
					</form>
				}
			/>
		</>
	);
};

export default InformationBox;
