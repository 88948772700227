import React from 'react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { GetWebCurrentUser } from '../../GraphQL';
import { useForm } from 'react-hook-form';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Select from 'react-select';
import Input from '@ssg/common/Components/Input';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Pill from '@ssg/common/Components/Pill';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import UserContext from '../../UserContext';
import dk from '@ssg/common/Assets/postalcodes/DK.json';
import no from '@ssg/common/Assets/postalcodes/NO.json';
import se from '@ssg/common/Assets/postalcodes/SE.json';
import { GetDrivingSlipsNewPlannerQuery, RemoveUserDrivingSlipFilterMutationVariables, SetUserDrivingSlipFilterMutationVariables, useGetWebDebitorsQuery, useRemoveUserDrivingSlipFilterMutation, useSetUserDrivingSlipFilterMutation } from '@ssg/common/GraphQL/indexV2';
import { usePlannerAdminData } from './PlannerHelpers';
import PlannerContext from './PlannerContext';
import Checkbox from '@ssg/common/Components/Checkbox';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');

interface Props {
	showFilters: boolean;
	setFilterTerm: (v: string) => void;
	drivingSlipsData: GetDrivingSlipsNewPlannerQuery['plannerDrivingSlips'];
	halfDay: boolean;
	setHalfDay: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlannerDrivingSlipFilter: React.FC<Props> = ({ showFilters, setFilterTerm, drivingSlipsData, halfDay, setHalfDay }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const planningForm = useForm();
	const { activeDrivingSlipsFilters, activeUserDrivingSlipFilter, clearActiveDrivingSlipFilters, setActiveDrivingSlipsFilters, setActiveUserDrivingSlipFilter } = React.useContext(PlannerContext);


	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);

	const { adminData } = usePlannerAdminData();

	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(activeDrivingSlipsFilters.name ?? '');

	const debitorMapRef = React.useRef<Record<string, string>>({});

	const [createUserFilter] = useSetUserDrivingSlipFilterMutation();
	const [removeUserFilter] = useRemoveUserDrivingSlipFilterMutation();

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					debitors: activeDrivingSlipsFilters.debitors ?? [],
					postalCodeText: activeDrivingSlipsFilters.postalCodeText ?? '',
					postalCodes: activeDrivingSlipsFilters.postalCodes ?? [],
					departments: activeDrivingSlipsFilters.departments ?? [],
					locations: activeDrivingSlipsFilters.locations ?? [],
					damageCategories: activeDrivingSlipsFilters.damageCategories ?? [],
					damageCauses: activeDrivingSlipsFilters.damageCauses ?? [],
					erpNos: activeDrivingSlipsFilters.erpNos ?? [],
					projectManagers: activeDrivingSlipsFilters.projectManagers ?? [],
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postUserFilter = async (filterValues: SetUserDrivingSlipFilterMutationVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserDrivingSlipFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.drivingSlipFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							drivingSlipFilters: [cachedRequest.currentUser?.drivingSlipFilters, data.setUserDrivingSlipFilter.drivingSlipFilters],
						},
					},
				});
			},
		});
		setActiveUserDrivingSlipFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserDrivingSlipFilterMutationVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const [departments, locations, damageCauses, damageCategories, drivers] = React.useMemo(() => {
		if (typeof adminData === 'undefined') {
			return [[], [], [], []];
		}

		const departments = adminData.departments
			.filter((d, i, a) => a.findIndex(ad => ad.id === d.id) === i)
			.map(d => ({
				value: d.id,
				label: `${d.name} (${d.departmentNumber})`,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		const locations = adminData.locations
			.filter((l, i, a) => a.findIndex(al => al.id === l.id) === i)
			.map(l => ({ value: l.id, label: l.name }))
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCauses = adminData.damageCauses
			.reduce(
				(allCauses, dc) => {
					let cause = allCauses.find(ac => ac.label === dc.name);
					if (typeof cause === 'undefined') {
						const index = allCauses.push({ label: dc.name, value: '' }) - 1;
						cause = allCauses[index];
					}

					cause.value += dc.id + ',';
					return allCauses;
				},
				[] as Array<{ label: string; value: string }>,
			)
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCategories = adminData.damageCategories.map(dc => ({ value: dc.id, label: dc.name })).sort((a, b) => a.label.localeCompare(b.label));

		const drivers = adminData.usersWithCalendars.map(d => ({
			value: d.id,
			label: d.name,
		}));

		return [departments, locations, damageCauses, damageCategories, drivers];
	}, [adminData]);

	const resetFilters = () => {
		clearActiveDrivingSlipFilters();

		setActiveDrivingSlipsFilters({
			name: null,
			locations: [],
			departments: [],
			debitors: [],
			damageCauses: [],
			damageCategories: [],
			postalCodeText: '',
			postalCodes: [],
			projectManagers: [],
			erpNos: [],
		});

		//setPostalCodeValues([]);
		setActiveUserDrivingSlipFilter('');
	};

	const { loading: debitorsLoading, data: debitorsData } = useGetWebDebitorsQuery({
		variables: { searchText: debitorSearchText },
		onCompleted(data) {
			data.debitors.forEach(d => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
		skip: debitorSearchText.length === 0,
	});

	const getProjectManagers = () =>
		drivingSlipsData
			.map(p => p.case.projectManager)
			.filter(p => p !== null)
			.filter((c, i, a) => a.findIndex(ac => ac?.id === c?.id) === i)
			.map(p => ({ value: p?.id ?? '', label: p?.name ?? '' }));

	const resolvePostalCodes = (postalCodes: string[]) => {
		const resolvedPostalCodesList: string[] = [];

		const postalCodesListDK = Object.keys(dk);
		const postalCodesListNO = Object.keys(no);
		const postalCodesListSE = Object.keys(se);
		const postalCodesListAsNumbersDK = postalCodesListDK.map(p => parseInt(p));
		const postalCodesListAsNumbersNO = postalCodesListNO.map(p => parseInt(p));
		const postalCodesListAsNumbersSE = postalCodesListSE.map(p => parseInt(p.replace(' ', '')));

		postalCodes?.forEach(p => {
			if (p.includes('-')) {
				const splitP = p.split('-');
				const postalCodesBetweenP = postalCodesListAsNumbersDK.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString());
				postalCodesBetweenP.concat(postalCodesListAsNumbersNO.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString()));
				postalCodesBetweenP.concat(
					postalCodesListAsNumbersSE
						.filter(p => parseInt(splitP[0].replace(' ', '')) <= p && p <= parseInt(splitP[1].replace(' ', '')))
						.map(p => {
							const pString = p.toString();
							return pString.substring(0, 3) + ' ' + pString.substring(3);
						}),
				);
				resolvedPostalCodesList.push(...postalCodesBetweenP);
			} else {
				resolvedPostalCodesList.push(p);
			}
		});

		const uniqueAndSortedPostalCodes = Array.from(new Set(resolvedPostalCodesList)).sort((a, b) =>
			a.localeCompare(b, undefined, {
				numeric: true,
				sensitivity: 'base',
			}),
		);

		return uniqueAndSortedPostalCodes;
	};

	const drivingSlipFilters = React.useMemo(() => userContext.user?.drivingSlipFilters ?? [], [userContext.user?.drivingSlipFilters]);

	return (
		<div className="w-full">
			<div className="relative my-3 w-full">
				<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
				<Input
					name="overview-search"
					placeholder={t('caseOverview.filters.searchPlaceholder')}
					className="pr-8 text-sm"
					style={{ height: '36px' }}
					onChange={(e): void => setFilterTerm(e.target.value)}
				/>
			</div>

			<div className="order-2 lg:order-3">
				<label className="text-blue mb-1  text-xs">{t('common.quickFilter')}</label>
				<div className="flex flex-wrap justify-between">
					<Dropdown
						name="drivingslips-quick-filters"
						onChange={e => {
							setActiveUserDrivingSlipFilter(e.target.value);
							if (e.target.value === '') {
								resetFilters();
							} else {
								const filter = drivingSlipFilters.find(f => f.name === e.target.value);
								if (filter) {
									setActiveDrivingSlipsFilters(filter);
								}
							}
						}}
						data={[
							{
								value: '',
								label: t('caseOverview.filters.pickFilter'),
							},
							...drivingSlipFilters.map(f => ({
								value: f.name,
								label: f.name,
							})) ?? [],
						]}
						className="flex-grow-1 mb-1 w-full pr-8 lg:w-auto"
						style={{ maxWidth: '300px', height: 34 }}
						value={activeUserDrivingSlipFilter}
					/>

					<div className="mb-1 flex flex-wrap">
						<Button
							secondary={activeDrivingSlipsFilters.name !== '' || typeof activeDrivingSlipsFilters.name !== 'undefined'}
							disabled={activeDrivingSlipsFilters.name === '' || typeof activeDrivingSlipsFilters.name == 'undefined' || activeDrivingSlipsFilters.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="px-5 py-2 text-xs"
							style={{ height: 34 }}
						/>

						<Button
							primary
							text={activeDrivingSlipsFilters.name === '' || typeof activeDrivingSlipsFilters.name == 'undefined' || activeDrivingSlipsFilters.name == null ? t('caseOverview.filters.saveFilter') : t('caseOverview.filters.updateFilter')}
							onClick={activeDrivingSlipsFilters.name === '' || typeof activeDrivingSlipsFilters.name == 'undefined' || activeDrivingSlipsFilters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
							className="ml-3 px-5 py-2 text-xs"
							style={{ height: 34 }}
						/>
					</div>
				</div>
			</div>
			<Checkbox
				name="halfDay"
				title="drivingSlips.halfDay"
				className="font-normal ml-px"
				textClassName='text-sm mt-px text-blue'
				labelWeight='NONE'
				checkedControlled={halfDay}
				onChange={() => setHalfDay(current => !current)}
			//defaultChecked={data?.halfDay}
			/>
			{showFilters && (
				<>
					<div className="flex w-full">
						<div className="flex-1">
							<label className="text-blue mb-1 text-xs font-medium w-40">
								{t('common.location')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeDrivingSlipsFilters.locations?.map(v => {
											const l = adminData?.locations?.find(location => location.id === v);
											return {
												value: l?.id ?? '',
												label: l?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveDrivingSlipsFilters(currentFilters => ({
											...currentFilters,
											locations: values?.map(v => v.value) ?? [],
										}))
									}
									options={locations}
								/>
							</label>
							<div className="text-blue mb-1 text-xs font-medium w-40">
								<label>
									{t('common.debitor')}
									<SearchableSelect
										key="debitor-filter"
										control={planningForm.control}
										name="debitorFilter"
										options={
											debitorsData?.debitors
												.filter(d => d.debitorId !== null)
												.filter((d, i, a) => a.indexOf(d) === i)
												.map(d => ({
													value: d.company,
													label: d.company,
												})) ?? []
										}
										searchFn={searchText => setDebitorSearchText(searchText)}
										onSelect={value => {
											if (value !== '')
												setActiveDrivingSlipsFilters(() => ({
													...activeDrivingSlipsFilters,
													debitors: [...(activeDrivingSlipsFilters.debitors ?? []), value],
												}));
										}}
										onBlur={clearFn => clearFn()}
										minInputLength={2}
										isLoading={debitorsLoading}
										className="text-xs w-40"
									/>
								</label>
							</div>
							{activeDrivingSlipsFilters.debitors.length > 0 && (
								<ul className="">
									{activeDrivingSlipsFilters.debitors?.map(d => (
										<li key={d} className="m-1">
											<Pill
												onClick={() =>
													setActiveDrivingSlipsFilters(() => ({
														...activeDrivingSlipsFilters,
														debitors: activeDrivingSlipsFilters.debitors?.filter(ed => ed !== d),
													}))
												}
												className="text-xs"
											>
												{debitorMapRef.current[d]} ({d})
											</Pill>
										</li>
									))}
								</ul>
							)}

							<label className="text-blue mb-1   text-xs font-medium">
								{t('common.damageCategory')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeDrivingSlipsFilters.damageCategories?.map(v => {
											const l = adminData?.damageCategories?.find(dc => dc.id === v);
											return {
												value: l?.id ?? '',
												label: l?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveDrivingSlipsFilters(currentFilters => ({
											...currentFilters,
											damageCategories: values?.map(v => v.value) ?? [],
										}))
									}
									options={damageCategories}
								/>
							</label>

							<label className="text-blue mb-1   text-xs font-medium">
								{t('case.caseNo')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeDrivingSlipsFilters.erpNos.map(d => ({
											value: d,
											label: d,
										})) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveDrivingSlipsFilters(() => ({
											...activeDrivingSlipsFilters,
											erpNos: values?.map(v => v.value) ?? [],
										}))
									}
									options={drivingSlipsData.map(c => ({
										value: c.case.erpNo,
										label: c.case.erpNo,
									}))}
								/>
							</label>

						</div>

						<div className="flex-1">
							<label className="text-blue mb-1  text-xs font-medium">
								{t('common.department')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeDrivingSlipsFilters.departments?.map(v => {
											const d = adminData?.departments?.find(department => department.id === v);
											return {
												value: d?.id ?? '',
												label: d?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values => {
										setActiveDrivingSlipsFilters(currentFilters => ({
											...currentFilters,
											departments: values?.map(v => v.value) ?? [],
										}));
									}}
									options={departments}
								/>
							</label>
							<div className="text-blue mb-1 text-xs font-medium w-40">

								<label className="text-blue mb-1  text-xs font-medium w-40">
									{t('common.postalcode')}
									<input
										type="text"
										className="border-1 rounded-default  w-full border-gray-600 p-1 text-sm focus:outline-none"
										value={activeDrivingSlipsFilters.postalCodeText}
										onChange={values => {
											//setPostalCodeValues(values.target.value.trim().split(','));
											setActiveDrivingSlipsFilters(filters => ({
												...filters,
												postalCodeText: values.target.value,
												postalCodes: values.target.value !== '' ? resolvePostalCodes(values.target.value.trim().split(',')) ?? [] : [],
											}));
										}}
									/>
								</label>
							</div>

							<label className="text-blue mb-1  text-xs font-medium">
								{t('case.damageCause')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeDrivingSlipsFilters.damageCauses
											?.map(v => {
												const l = damageCauses.find(dc => dc.value.includes(v));
												return {
													value: l?.value ?? '',
													label: l?.label ?? '',
												};
											})
											.filter((v, i, a) => a.findIndex(av => av.value === v.value) === i) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveDrivingSlipsFilters(currentFilters => ({
											...currentFilters,
											damageCauses: values?.flatMap(v => v.value.split(',').filter(v => v !== '')) ?? [],
										}))
									}
									options={damageCauses}
								/>
							</label>

							<label className="text-blue mb-1  text-xs font-medium">
								{t('case.ssgProjectManager')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									noOptionsMessage={() => t('common.noCasesFound')}
									className="react-select-custom text-xs w-40"
									value={
										activeDrivingSlipsFilters.projectManagers.map(v => {
											const d = drivingSlipsData.map(d => d.case.projectManager).find(projectManagers => projectManagers?.id === v);
											return {
												value: d?.id ?? '',
												label: d?.name ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values => {
										setActiveDrivingSlipsFilters(() => ({
											...activeDrivingSlipsFilters,
											projectManagers: values?.map(v => v.value) ?? [],
										}));
									}}
									options={getProjectManagers()}
								/>
							</label>
						</div>

					</div>
					<div className="w-full">
						<Button secondary text={t('common.resetFilter')} className="mb-1 mt-3 text-xs" onClick={resetFilters} />
					</div>
				</>
			)}


			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeDrivingSlipsFilters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeDrivingSlipsFilters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default PlannerDrivingSlipFilter;
