import React from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogInput, GetCatalog_catalog } from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { Permissions } from '@ssg/common/GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Box from '../../../Components/Layout/Box';
import sanitizeHtml from 'sanitize-html';
import TextEditor from '@ssg/common/Components/TextEditor';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import 'react-quill/dist/quill.snow.css';

interface Props {
	data: GetCatalog_catalog;
	changedBy: string;
	saveChanges: (data: CatalogInput) => Promise<void>;
	loadingUpdate: boolean;
}

const DescriptionBox: React.FC<Props> = ({ data, changedBy, saveChanges, loadingUpdate }) => {
	const { t } = useTranslation();

	const userPermissions = React.useContext(UserContext).user?.permissions ?? [];
	const canVerify = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VERIFY]);
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);

	const [editActive, setEditActive] = React.useState<boolean>(false);

	const { handleSubmit, register, setValue } = useForm();

	const onSubmit = async (formData: CatalogInput) => {
		await saveChanges({
			customer: data.customer.id,
			changedBy: changedBy,
			propertyNumber: data.propertyNumber,
			smsService: data.smsService,
			description: formData.description,
			verified: canVerify,
			debitor: data.debitor?.debitorId,
			debitorVerified: data.debitorVerified,
		});

		setEditActive(false);
	};

	const handleChange = (content: string) => {
		setValue('description', content, { shouldValidate: true });
	};

	const divRef = React.createRef<HTMLDivElement>();

	React.useEffect(() => {
		if (divRef.current) {
			divRef.current.innerHTML = sanitizeHtml(data.description ?? '', {
				allowedAttributes: {
					'*': ['style', 'href', 'rel', 'target', 'class'],
				},
			});
		}
	}, [divRef, data]);

	return (
		<>
			<Box title="common.description" className="pb-8" half icon={canEdit ? faEdit : undefined} onClick={() => setEditActive(true)}>
				<div className="ql-editor mt-2 pl-0">
					<div ref={divRef}></div>
				</div>
			</Box>

			<Modal
				title={`${t('common.edit')} ${t('common.description').toLowerCase()}`}
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.LARGE}
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextEditor editorId="descriptionEditor" handleChange={handleChange} value={data.description ?? ''} />
						<div>
							<Input name="description" defaultValue={data.description ?? ''} innerRef={register} className="invisible mb-4" />
						</div>

						<Button success submit text={`${t('common.edit')} ${t('common.description').toLowerCase()}`} loading={loadingUpdate} disabled={loadingUpdate} className="pl-4" />
					</form>
				}
			/>
		</>
	);
};

export default DescriptionBox;
