function isValidNumber(value: number | null): value is number {
	return value != null && !isNaN(value);
}

function multiplyNullableValues(a: number | null, b: number | null): number {
	const _a = isValidNumber(a) ? a : 0;
	const _b = isValidNumber(b) ? b : 0;

	return _a * _b;
}

interface OutputLine {
	unitPrice: number | null;
	quantity: number | null;
}

export function calculateLinePrice(line: OutputLine): number {
	return multiplyNullableValues(line.unitPrice, line.quantity);
}

interface InputLine {
	newUnitPrice: number | null;
	quantity: number | null;
}

export function calculateInputLinePrice(line: InputLine): number {
	return multiplyNullableValues(line.newUnitPrice, line.quantity);
}

interface CostLine {
	unitCost: number | null;
	quantity: number | null;
}

export function calculateCostLinePrice(line: CostLine): number {
	return multiplyNullableValues(line.unitCost, line.quantity);
}
