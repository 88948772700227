import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeLabelAndStatusMovable, ChangeLabelAndStatusMovableVariables, CheckMovable, CheckMovableVariables } from '../../GraphQL';
import BarcodeScanner from './BarcodeScannerTwo';
import { MOVABLE_BOX_PREFIX, MOVABLE_CASE_PREFIX, MOVABLE_MOVABLE_PREFIX, MOVABLE_OTHER_PREFIX, MOVABLE_STATUS_PREFIX } from './movablePrefixes';
import MovableStatusLabel from './MovableStatusLabel';

const CHANGE_LABEL_AND_STATUS = loader('src/GraphQL/Movables/ChangeLabelAndStatusMovable.gql');

interface Props {
	oldMovableId: string;
	oldMovableStatus: string;
	caseId: string;
	checkMovable(options?: MutationFunctionOptions<CheckMovable, CheckMovableVariables>): Promise<FetchResult<CheckMovable>>;
	movableStatusOptions: string[];
	close(): unknown;
}

const removeMovablePrefix = (movableId: string): string => {
	if (movableId.startsWith(MOVABLE_MOVABLE_PREFIX)) {
		return movableId.substring(MOVABLE_MOVABLE_PREFIX.length);
	}

	if (movableId.startsWith(MOVABLE_BOX_PREFIX)) {
		return movableId.substring(MOVABLE_BOX_PREFIX.length);
	}

	if (movableId.startsWith(MOVABLE_OTHER_PREFIX)) {
		return movableId.substring(MOVABLE_OTHER_PREFIX.length);
	}

	return 'ERROR';
};

const ChangeLabelAndStatus: React.FC<Props> = ({ oldMovableId, oldMovableStatus, checkMovable, movableStatusOptions, caseId, close }): React.ReactElement => {
	const { t } = useTranslation();

	const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);

	const [newCaseInfo, setNewCaseInfo] = React.useState<{ id: string; status: string } | undefined>(undefined);
	const [newMovableInfo, setNewMovableInfo] = React.useState<{ id: string; labelStatus: string; currentStatus: string } | undefined>(undefined);

	const [loading, setLoading] = React.useState(false);

	const [error, setError] = React.useState<'LABEL_ERROR' | undefined>(undefined);

	const [changeLabelAndStatus, { loading: loadingChange }] = useMutation<ChangeLabelAndStatusMovable, ChangeLabelAndStatusMovableVariables>(CHANGE_LABEL_AND_STATUS);

	const movableLabelStatusValue = React.useMemo(() => {
		if (typeof movableStatusOptions === 'undefined' || typeof newMovableInfo === 'undefined') {
			return;
		}
		return (
			movableStatusOptions.find(opt => newMovableInfo.labelStatus.toUpperCase() === opt.toUpperCase()) ??
			movableStatusOptions.find(opt => newMovableInfo.labelStatus.toUpperCase().startsWith(opt.toUpperCase()))
		);
	}, [movableStatusOptions, newMovableInfo]);

	React.useEffect(() => {
		async function checkMovableExistance(id: string): Promise<{
			registratonStatus: 'UNREGISTERED' | 'ACTIVE' | 'COMPLETED';
			currentStatus: string | null;
			movableId: string | null;
		}> {
			setLoading(true);
			const { data } = await checkMovable({ variables: { id } });
			setLoading(false);
			if (typeof data === 'undefined' || data === null || data.checkMovable === null) {
				return {
					registratonStatus: 'UNREGISTERED',
					currentStatus: null,
					movableId: null,
				};
			} else if (data.checkMovable.completed) {
				return {
					registratonStatus: 'COMPLETED',
					currentStatus: null,
					movableId: data.checkMovable.id,
				};
			}
			return {
				registratonStatus: 'ACTIVE',
				currentStatus: data.checkMovable.status,
				movableId: data.checkMovable.id,
			};
		}
		if (typeof scannedId !== 'undefined') {
			const [casePart, movablePart, statusPart] = scannedId.split('|');

			const status = statusPart.startsWith(MOVABLE_STATUS_PREFIX) ? statusPart.substring(MOVABLE_STATUS_PREFIX.length) : '';

			if (casePart.startsWith(MOVABLE_CASE_PREFIX)) {
				setNewCaseInfo({
					id: casePart.substring(MOVABLE_CASE_PREFIX.length),
					status,
				});
			}

			const movableId = removeMovablePrefix(movablePart);

			checkMovableExistance(movableId).then(({ registratonStatus, currentStatus }) => {
				if (registratonStatus === 'UNREGISTERED') {
					const match = movableStatusOptions.find(opt => status.toUpperCase() === opt.toUpperCase()) ?? movableStatusOptions.find(opt => status.toUpperCase().startsWith(opt.toUpperCase()));
					if (match) {
						setNewMovableInfo({
							id: movableId,
							labelStatus: status,
							currentStatus: currentStatus ?? '',
						});
					}
				} else {
					setError('LABEL_ERROR');
				}
			});
		}
	}, [checkMovable, movableStatusOptions, scannedId]);

	return (
		<div className="relative">
			{loading && (
				<div className="relative h-40 w-full">
					<Loading />
				</div>
			)}
			{typeof newMovableInfo === 'undefined' && typeof newCaseInfo === 'undefined' && <BarcodeScanner setScannedId={setScannedId} allowedPrefixes={[MOVABLE_CASE_PREFIX]} />}
			{error === 'LABEL_ERROR' && (
				<div>
					<p className="text-red">{t('movable.labelUsed')}</p>
					<Button
						text="movable.scanAgain"
						secondary
						fullWidth
						onClick={() => {
							setNewCaseInfo(undefined);
							setNewMovableInfo(undefined);
							setError(undefined);
						}}
					/>
				</div>
			)}
			{typeof newMovableInfo !== 'undefined' && typeof newCaseInfo !== 'undefined' && typeof movableLabelStatusValue !== 'undefined' && (
				<div>
					{newCaseInfo.id !== caseId && (
						<div>
							<p className="text-red">{t('movable.casesDifferent')}</p>
							<Button
								text="movable.scanAgain"
								secondary
								fullWidth
								onClick={() => {
									setNewCaseInfo(undefined);
									setNewMovableInfo(undefined);
								}}
							/>
						</div>
					)}
					{oldMovableStatus === movableLabelStatusValue && (
						<div>
							<p className="text-red">{t('movable.sameStatus')}</p>
							<Button
								text="movable.scanAgain"
								secondary
								fullWidth
								onClick={() => {
									setNewCaseInfo(undefined);
									setNewMovableInfo(undefined);
								}}
							/>
						</div>
					)}
					{newCaseInfo.id === caseId && oldMovableStatus !== movableLabelStatusValue}{' '}
					{
						<>
							<div className="flex items-center">
								<p className="mr-1">{t('movable.changeStatusFrom')}</p>
								<MovableStatusLabel status={oldMovableStatus.toUpperCase()} />
								<p className="mx-1">{t('common.to')}</p>
								<MovableStatusLabel status={movableLabelStatusValue.toUpperCase()} />
							</div>
							<div className="bg-blue border-blue border-1 my-2 rounded-md bg-opacity-25 p-2">
								<p className="text-sm">
									<FontAwesomeIcon icon={faExclamationCircle} className="text-blue mr-3" />
									{t('movable.rememberChange')}
								</p>
							</div>
							<Button
								text="Skift"
								primary
								fullWidth
								loading={loadingChange}
								className="mt-4 mb-1"
								onClick={async () => {
									try {
										await changeLabelAndStatus({
											variables: {
												newMovableId: newMovableInfo.id,
												oldMovableId: oldMovableId,
												newStatus: movableLabelStatusValue,
											},
										});
									} catch (e) {
										console.log(e);
									}
									close();
								}}
							/>
							<Button
								text="movable.scanAgain"
								secondary
								fullWidth
								onClick={() => {
									setNewCaseInfo(undefined);
									setNewMovableInfo(undefined);
								}}
							/>
						</>
					}
				</div>
			)}
		</div>
	);
};

export default ChangeLabelAndStatus;
