import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
	GetWebCurrentUser,
	RemoveUserMovableFilter,
	RemoveUserMovableFilterVariables,
	SetUserMovableFilter,
	SetUserMovableFilterVariables,
	GetWebDebitors,
	//GetDebitorsVariables,
	ExportMovableToExcel,
	ExportMovableToExcelVariables,
} from '../../GraphQL';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MovableFilters } from './Movables';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import UserContext from '../../UserContext';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';
import Button from '@ssg/common/Components/Button';
import Toggle from '@ssg/common/Components/Toggle';
import SearchableCaseSelect from '../../Components/Select/SearchableCaseSelect';
import MovableLocationsSelect from '../../Components/Select/MovableLocationsSelect';
import MovableStatusOptions from '../../Components/Select/MovableStatusOptions';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
//import { SelectOption } from '@ssg/common/Helpers/Helpers';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useFlag } from '@unleash/proxy-client-react';
import Pill from '@ssg/common/Components/Pill';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import useSaveFile from '@ssg/common/Hooks/useSaveFile';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { GetMovablesQuery } from '@ssg/common/GraphQL/indexV2';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const SET_USER_MOVABLE_FILTER = loader('src/GraphQL/Users/SetUserMovableFilter.gql');
const REMOVE_USER_MOVABLE_FILTER = loader('src/GraphQL/Users/RemoveUserMovableFilter.gql');
const GET_DEBITORS = loader('src/GraphQL/Debitors/GetWebDebitors.gql');
const EXPORT_MOVABLES_TO_EXCEL = loader('src/GraphQL/Movables/ExportMovableToExcel.gql');

interface Props {
	activeMovableFilters: MovableFilters;
	setActiveMovableFilters: React.Dispatch<React.SetStateAction<MovableFilters>>;
	setFilterTerm: (v: string) => void;
	setActiveMovableFiltersName: React.Dispatch<React.SetStateAction<string>>;
	activeMovableFiltersName: string;
	movables: GetMovablesQuery['movables'];
}

const MovableFilter: React.FC<Props> = ({ activeMovableFilters, setActiveMovableFilters, setFilterTerm, setActiveMovableFiltersName, activeMovableFiltersName, movables }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const isMovableNewFilters = useFlag(FeatureFlagEnums.IS_MOVABLE_NEW_FILTERS);
	const exportListToExcelFF = useFlag(FeatureFlagEnums.EXPORT_LIST_TO_EXCEL);

	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(activeMovableFilters.name ?? '');

	const [createUserFilter] = useMutation<SetUserMovableFilter, SetUserMovableFilterVariables>(SET_USER_MOVABLE_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserMovableFilter, RemoveUserMovableFilterVariables>(REMOVE_USER_MOVABLE_FILTER);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					erpNo: activeMovableFilters.erpNo,
					location: activeMovableFilters.location,
					status: activeMovableFilters.status,
					includeCompleted: activeMovableFilters.includeCompleted,
					debitors: activeMovableFilters.debitors,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const resetFilters = () => {
		setActiveMovableFilters({
			name: null,
			erpNo: '',
			location: '',
			status: '',
			includeCompleted: false,
			debitors: [],
			fromDate: null,
			toDate: null,
		});
		setFilterTerm('');
		setActiveMovableFiltersName('');
		setDebitorSearchText('');
	};

	const postUserFilter = async (filterValues: SetUserMovableFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserMovableFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.movableFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							movableFilters: [cachedRequest.currentUser?.movableFilters, data.setUserMovableFilter.movableFilters],
						},
					},
				});
			},
		});

		setActiveMovableFiltersName(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const debitorMapRef = React.useRef<Record<string, string>>({});
	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: { searchText: debitorSearchText },
		skip: debitorSearchText.length === 0,
		onCompleted(data) {
			data.debitors.forEach((d: GetWebDebitors['debitors'][number]) => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
	});

	const { control /*, setValue*/ } = useForm();

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserMovableFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const [exportListToExcel, { loading, data }] = useLazyQuery<ExportMovableToExcel, ExportMovableToExcelVariables>(EXPORT_MOVABLES_TO_EXCEL, {
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'no-cache',
	});

	useSaveFile(data?.exportMovableToExcel, `${t('case.movableOverview')}.xlsx`);

	return (
		<div className="mx-0 my-2">
			<div className="flex flex-wrap">
				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('case.caseNo')}</label>
					<SearchableCaseSelect
						erpNo={false}
						onSelect={value => {
							setActiveMovableFilters(currentFilters => ({
								...currentFilters,
								erpNo: value,
							}));
						}}
					/>
				</div>

				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('common.location')}</label>
					<MovableLocationsSelect
						value={activeMovableFilters.location}
						onSelect={option => {
							setActiveMovableFilters(currentFilters => ({
								...currentFilters,
								location: option?.value ?? '',
							}));
						}}
					/>
				</div>

				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('common.status')}</label>
					<MovableStatusOptions
						value={activeMovableFilters.status}
						onSelect={option => {
							setActiveMovableFilters(currentFilters => ({
								...currentFilters,
								status: option?.value ?? '',
							}));
						}}
					/>
				</div>

				{isMovableNewFilters && (
					<div className="mb-3 mr-6">
						<label className="text-blue inline-block text-xs font-medium">{t('common.debitor')}</label>
						<SearchableSelect
							key="debitor-filter"
							control={control}
							name="debitorFilter"
							options={(debitorsData?.debitors ?? [])
								.filter(d => d.debitorId !== null)
								.filter((d, i, a) => a.indexOf(d) === i)
								.map(d => ({
									value: d.debitorId ?? '?',
									label: d.company,
								}))}
							searchFn={searchText => setDebitorSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									setActiveMovableFilters(currentFilters => ({
										...currentFilters,
										debitors: [...(activeMovableFilters.debitors ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={2}
							isLoading={debitorsLoading}
							className="lg:w-full"
						/>

						<ul className="text-xs">
							{activeMovableFilters.debitors?.map(d => (
								<li key={d}>
									<Pill
										onClick={() =>
											setActiveMovableFilters(currentFilters => ({
												...currentFilters,
												debitors: activeMovableFilters.debitors?.filter(ed => ed !== d) ?? [],
											}))
										}
									>
										{debitorMapRef.current[d]} ({d})
									</Pill>
								</li>
							))}
						</ul>
					</div>
				)}

				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('common.search')}</label>
					<div className="relative flex">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input
							name="overview-search"
							placeholder={t('movable.searchPlaceholder')}
							className="w-auto pr-8 text-sm"
							style={{ minWidth: '220px', height: '36px' }}
							onChange={(e): void => setFilterTerm(e.target.value)}
						/>
					</div>
				</div>

				{isMovableNewFilters && (
					<div className="mb-3 mr-6">
						<label className="text-blue mb-1 mr-3 text-xs font-medium">{t('common.created')}</label>
						<div className="flex items-center">
							<input
								type="date"
								value={formatDateForInput(new Date(activeMovableFilters.fromDate ?? ''))}
								onChange={e => {
									const fromDate = e.target.valueAsDate;
									if (fromDate === null || fromDate === undefined) {
										return;
									}

									fromDate.setHours(0, 0, 0, 0);

									setActiveMovableFilters(currentFilters => ({
										...currentFilters,
										fromDate,
									}));

									if (activeMovableFilters.fromDate === null) {
										return;
									}

									if (fromDate > (activeMovableFilters.toDate ?? new Date())) {
										const toDate = new Date(fromDate);
										toDate.setHours(23, 59, 59, 999);
										setActiveMovableFilters(currentFilters => ({
											...currentFilters,
											toDate,
										}));
									}
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							<span className="px-1">&mdash;</span>
							<input
								type="date"
								min={formatDateForInput(new Date(activeMovableFilters.fromDate ?? ''))}
								value={formatDateForInput(new Date(activeMovableFilters.toDate ?? ''))}
								onChange={e => {
									const toDate = e.target.valueAsDate;
									if (toDate === null) {
										return;
									}

									toDate.setHours(23, 59, 59, 999);

									setActiveMovableFilters(currentFilters => ({
										...currentFilters,
										toDate,
									}));
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							{(activeMovableFilters.fromDate !== null || activeMovableFilters.toDate !== null) && (
								<FontAwesomeIcon
									icon={faTimes}
									size="1x"
									className="ml-2 cursor-pointer"
									onClick={() =>
										setActiveMovableFilters(currentFilters => ({
											...currentFilters,
											fromDate: null,
											toDate: null,
										}))
									}
								/>
							)}
						</div>
					</div>
				)}

				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('common.quickFilter')}</label>
					<div className="flex">
						<Dropdown
							name="overview-quick-filters"
							placeholder={t('common.quickFilter')}
							value={activeMovableFiltersName}
							onChange={e => {
								setActiveMovableFiltersName(e.target.value);
								e.target.value !== ''
									? setActiveMovableFilters(currentFilters => ({
										...currentFilters,
										name: e.target.value,
									}))
									: resetFilters();
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...(userContext.user?.movableFilters.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? []),
							]}
							className="mb-3 mr-3 pr-8 lg:w-1/2"
							style={{ minWidth: '220px', height: '36px' }}
						/>

						<Button
							primary
							text={
								activeMovableFilters.name === '' || typeof activeMovableFilters.name == 'undefined' || activeMovableFilters.name == null
									? t('caseOverview.filters.saveFilter')
									: t('caseOverview.filters.updateFilter')
							}
							onClick={
								activeMovableFilters.name === '' || typeof activeMovableFilters.name == 'undefined' || activeMovableFilters.name == null
									? () => setSaveFilterActive(true)
									: () => onFilterSave()
							}
							className="mb-3 mr-3 px-5 py-2 text-xs"
						/>

						<Button secondary text={t('common.resetFilter')} className="mb-3 mr-3 px-5 py-2 text-xs" onClick={resetFilters} />

						<Button
							secondary={activeMovableFilters.name !== '' || typeof activeMovableFilters.name !== 'undefined'}
							disabled={activeMovableFilters.name === '' || typeof activeMovableFilters.name == 'undefined' || activeMovableFilters.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="mb-3 px-5 py-2 text-xs"
						/>
					</div>
				</div>

				<div className="mb-3 mr-6">
					<label className="text-blue inline-block text-xs font-medium">{t('common.showFinished')}</label>
					<div>
						<Toggle
							name="includeCompleted"
							text=""
							onClick={() =>
								setActiveMovableFilters(currentFilters => ({
									...currentFilters,
									includeCompleted: !activeMovableFilters.includeCompleted,
								}))
							}
							defaultChecked={activeMovableFilters.includeCompleted}
						/>
					</div>
				</div>

				{exportListToExcelFF && (
					<div className="mb-3 mr-6">
						<label className="text-blue inline-block text-xs font-medium"></label>
						<Button
							secondary
							text={t('common.exportForExcel')}
							className="mb-3 mr-3 px-5 py-2 text-xs"
							onClick={() =>
								exportListToExcel({
									variables: {
										filters: {
											excludeTotalCount: true,
											case: activeMovableFilters.erpNo,
											debitors: activeMovableFilters.debitors,
											includeCompleted: activeMovableFilters.includeCompleted,
											location: activeMovableFilters.location,
											status: activeMovableFilters.status,
											fromDate: activeMovableFilters.fromDate ? formatDateForInput(new Date(activeMovableFilters.fromDate ?? '')) : null,
											toDate: activeMovableFilters.toDate ? formatDateForInput(new Date(activeMovableFilters.toDate ?? '')) : null,
										},
									},
								})
							}
							loading={loading}
							disabled={loading}
						/>
					</div>
				)}
			</div>

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeMovableFilters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeMovableFilters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default MovableFilter;
