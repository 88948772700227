import React from 'react';

interface Props {
	fromAdr: {
		road: string;
		houseNumber: string;
		postalCode: string;
		city: string;
	};
	toAdr: {
		road: string;
		houseNumber: string;
		postalCode: string;
		city: string;
	};
}

const GoogleMapsDirections: React.FC<Props> = ({ fromAdr, toAdr, children }): React.ReactElement => {
	return (
		<a
			href={`https://www.google.com/maps/dir/${fromAdr.road}${fromAdr.houseNumber},${fromAdr.postalCode}+${fromAdr.city}+/${toAdr.road}${toAdr.houseNumber},+${toAdr.postalCode}+${toAdr.city}`}
			target="_blank"
			rel="noreferrer"
		>
			{children}
		</a>
	);
};

export default GoogleMapsDirections;
