import React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GetWebCaseAdminData, GetWebRequisitionsVariables, Permissions } from '../../GraphQL';
import { loader } from 'graphql.macro';
import Button from '@ssg/common/Components/Button';
import classNames from 'classnames';
import Header from '@ssg/common/Components/Header';
import VendorsOverview from './VendorsOverview';
import RequisitionsOverview from './RequisitionsOverview';
import RequisitionsModal from './RequisitionModal';
import UserContext from '../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';

const GET_CASE_ADMIN_DATA = loader('src/GraphQL/Cases/GetWebCaseAdminData.gql');

const tabs = ['requisitions.overviewTitle', 'requisitions.vendors'];

const Requisitions: React.FC = (): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.REQUISITION_EDIT]);

	const { t } = useTranslation();

	const [activeTab, setActiveTab] = React.useState(tabs[0]);
	const [showCreateRequisitionModal, setShowCreateRequisitionModal] = React.useState(false);

	const { data: adminDataRaw } = useQuery<GetWebCaseAdminData>(GET_CASE_ADMIN_DATA);
	const adminData = React.useMemo(() => adminDataRaw ?? undefined, [adminDataRaw]);

	const [requisitionVariables, setRequisitionVariables] = React.useState<GetWebRequisitionsVariables>();

	const departmentOptions = React.useMemo(
		() =>
			adminData?.departments
				.map(d => ({
					value: d.id,
					label: `${d.name} (${d.departmentNumber})`,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)) ?? [],
		[adminData?.departments],
	);

	const locationOptions = React.useMemo(() => adminData?.locations.map(l => ({ value: l.name, label: l.name })).sort((a, b) => a.label.localeCompare(b.label)) ?? [], [adminData?.locations]);

	const damageCategoryOptions = React.useMemo(
		() => adminData?.damageCategories.map(c => ({ value: c.id, label: c.name })).sort((a, b) => a.label.localeCompare(b.label)) ?? [],
		[adminData?.damageCategories],
	);

	const damageCauseOptions = React.useMemo(() => adminData?.damageCauses.map(c => ({ value: c.id, label: c.name })).sort((a, b) => a.label.localeCompare(b.label)) ?? [], [adminData?.damageCauses]);

	return (
		<div>
			<Header
				title="requisitions.overviewTitle"
				actions={<>{canEdit && <Button text="requisitions.createRequisition" primary icon={faPlus} onClick={() => setShowCreateRequisitionModal(true)} className="mr-4" />}</>}
			>
				<div className="flex">
					{tabs.map(title => (
						<div key={title} className="mr-3 mt-1">
							<button
								type="button"
								onClick={() => setActiveTab(title)}
								className={classNames('text-blue focus:outline-none', {
									'border-blue border-b-2': title === activeTab,
								})}
							>
								{t(title)}
							</button>
						</div>
					))}
				</div>
			</Header>

			{activeTab === 'requisitions.overviewTitle' && (
				<RequisitionsOverview
					departmentOptions={departmentOptions}
					damageCategoryOptions={damageCategoryOptions}
					damageCauseOptions={damageCauseOptions}
					setRequisitionVariables={setRequisitionVariables}
				/>
			)}
			{activeTab === 'requisitions.vendors' && <VendorsOverview locationOptions={locationOptions} />}

			<RequisitionsModal visible={showCreateRequisitionModal} close={() => setShowCreateRequisitionModal(false)} requisitionVariables={requisitionVariables} />
		</div>
	);
};

export default Requisitions;
