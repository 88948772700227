import classNames from 'classnames';
import React from 'react';

interface Props {
	status: string;
}

const MovableStatusLabel: React.FC<Props> = ({ status }): React.ReactElement => {
	return (
		<p
			className={classNames('rounded-full border-2 bg-opacity-25 py-px px-1 text-center text-xs font-bold', {
				// 'border-transparent text-white bg-yellow': status === 'VÆRDI',
				// 'border-transparent text-white bg-red-calm' :status === 'FRASORT',
				// 'border-transparent text-white bg-green' :status === 'OPMAG',
				// 'border-gray-300 text-black bg-white' :status === 'AFVASK',
				// 'border-transparent text-white bg-blue-lightcalm' :status === 'RENS',
				// 'border-transparent text-white bg-purple-calm' :status === 'SNEDKER',
				// 'border-transparent text-white bg-orange-calm' :status === 'TØJVASK',
				// 'border-transparent text-white bg-black' :status === 'UNDERLEV',
				'text-yellow border-yellow bg-yellow': status === 'VÆRDI',
				'text-red-calm border-red-calm bg-red-calm': status === 'FRASORT',
				'text-green border-green bg-green': status === 'OPMAG',
				'border-gray-500 bg-gray-100 text-gray-500': status === 'AFVASK',
				'text-blue-lightcalm border-blue-lightcalm bg-blue-lightcalm': status === 'RENS',
				'text-purple-calm border-purple-calm bg-purple-calm': status === 'SNEDKER',
				'text-orange-calm border-orange-calm bg-orange-calm': status === 'TØJVASK',
				'border-black bg-black text-black': status === 'UNDERLEV',
			})}
		>
			{status}
		</p>
	);
};

export default MovableStatusLabel;
