import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	CaseStatus,
	CloseCases,
	CloseCasesVariables,
	GetWebDepartments,
	GetOperationManagerCaseData,
	GetOperationManagerCaseDataVariables,
	//RejectCaseClosing,
	RejectCaseClosingVariables,
	RejectCaseClosing_rejectCaseClosing,
} from '../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { SelectOption, formatDate, formatTimestampOnly } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { isCaseValidToClose } from '../../Components/Helpers/isCaseValidToClose';
import Box from '../../Components/Layout/Box';
import Table from '@ssg/common/Components/Table';
import Button from '@ssg/common/Components/Button';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Popover from '@ssg/common/Components/Popover';
import Select from 'react-select';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import AppliedClosedCasseRejectionModal from './AppliedClosedCasesRejection/AppliedClosedCasesRejectionModal';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const CLOSE_CASES = loader('src/GraphQL/Cases/CloseCases.gql');
const GET_OPERATION_MANAGER_CASE_DATA = loader('src/GraphQL/Cases/GetOperationManagerCaseData.gql');
const REJECT_CASE_CLOSE = loader('src/GraphQL/Cases/RejectCaseClosing.gql');

const FETCH_LIMIT = 20;

interface Props {
	departmentData?: GetWebDepartments;
	loadingDepartments: boolean;
}

const AppliedClosedCases: React.FC<Props> = ({ departmentData, loadingDepartments }) => {
	const { t } = useTranslation();
	const activePurchaseDocumentsFlag = useFlag(FeatureFlagEnums.ACTIVE_PURCHASE_DOCUMENTS);
	const [bulkClose, setBulkClose] = React.useState(false);
	const [selectedRows, toggleSelectedRows, toggleAllRows] = useArrayToggler<string>([]);
	const [idRejectCaseClose, setIdRejectCaseClose] = React.useState<string>('');
	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);
	const [selectedLocations, setSelectedLocations] = useStorageState<string[]>(window.localStorage, 'appliedClosedCasesFilter', []);
	function getUniqueArrayByValue(arr: SelectOption[]): SelectOption[] {
		const uniqueValues = new Set();
		const resultArray: SelectOption[] = [];

		for (const obj of arr) {
			if (!uniqueValues.has(obj.value)) {
				uniqueValues.add(obj.value);
				resultArray.push(obj);
			}
		}

		return resultArray;
	}
	const locations: SelectOption[] = React.useMemo(() => {
		const allDepartments = departmentData?.departments ?? [];
		const allLocations = allDepartments.map(
			(d): SelectOption => ({
				label: d.location.name,
				value: d.location.id,
			}),
		);
		return getUniqueArrayByValue(allLocations);
	}, [departmentData?.departments]);

	const {
		data: operationManagerCaseData,
		loading: loadingCases,
		fetchMore,
	} = useQuery<GetOperationManagerCaseData, GetOperationManagerCaseDataVariables>(GET_OPERATION_MANAGER_CASE_DATA, {
		variables: {
			location: selectedLocations.length === 0 ? undefined : selectedLocations,
			department: departmentData?.departments.map(d => d.id),
			includeEconomy: true,
			appliedClosed: true,
			offset: 0,
			limit: FETCH_LIMIT,
		},
		skip: typeof departmentData === 'undefined' || departmentData.departments.length === 0,
		fetchPolicy: 'cache-and-network',
	});

	const [closeCases] = useMutation<CloseCases, CloseCasesVariables>(CLOSE_CASES);
	const [rejectCaseClose] = useMutation<RejectCaseClosing_rejectCaseClosing, RejectCaseClosingVariables>(REJECT_CASE_CLOSE);

	const closeSelectedCases = async (selectedCases: string[]) => {
		try {
			await closeCases({
				variables: { erpNos: selectedCases },
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetOperationManagerCaseData, GetOperationManagerCaseDataVariables>({
						query: GET_OPERATION_MANAGER_CASE_DATA,
						variables: {
							location: selectedLocations.length === 0 ? undefined : selectedLocations,
							department: departmentData?.departments.map(d => d.id),
							includeEconomy: true,
							appliedClosed: true,
						},
					});

					if (cachedRequest !== null && cachedRequest.cases !== null) {
						cache.writeQuery<GetOperationManagerCaseData, GetOperationManagerCaseDataVariables>({
							query: GET_OPERATION_MANAGER_CASE_DATA,
							variables: {
								location: selectedLocations.length === 0 ? undefined : selectedLocations,
								department: departmentData?.departments.map(d => d.id),
								includeEconomy: true,
								appliedClosed: true,
							},
							data: {
								cases: cachedRequest.cases.filter(c => !selectedCases.includes(c.erpNo)),
							},
						});
					}
				},
			});

			window.location.reload();
		} catch (e) {
			console.log(e);
		}
	};
	const rejectCaseClosing = async (caseId: string, applicantEmail: string, rejectionComment: string) => {
		try {
			await rejectCaseClose({
				variables: {
					id: caseId,
					email: applicantEmail,
					rejectionComment: rejectionComment,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetOperationManagerCaseData, GetOperationManagerCaseDataVariables>({
						query: GET_OPERATION_MANAGER_CASE_DATA,
						variables: {
							location: selectedLocations.length === 0 ? undefined : selectedLocations,
							department: departmentData?.departments.map(d => d.id),
							includeEconomy: true,
							appliedClosed: true,
						},
					});

					if (cachedRequest !== null && cachedRequest.cases !== null) {
						cache.writeQuery<GetOperationManagerCaseData, GetOperationManagerCaseDataVariables>({
							query: GET_OPERATION_MANAGER_CASE_DATA,
							variables: {
								location: selectedLocations.length === 0 ? undefined : selectedLocations,
								department: departmentData?.departments.map(d => d.id),
								includeEconomy: true,
								appliedClosed: true,
							},
							data: {
								cases: cachedRequest.cases.filter(c => c.id !== caseId),
							},
						});
					}
				},
			});
			window.location.reload();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Box title="case.appliedClosed" full className="text-blue mb-8">
			<div className="mb-3 flex flex-row space-x-2">
				<div>
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.tableView')}</label>
					<ButtonGroup
						items={[
							{
								title: t('case.viewNormal'),
								isActive: !bulkClose,
								onClick: () => setBulkClose(false),
							},
							{
								title: t('case.viewClose'),
								isActive: bulkClose,
								onClick: () => setBulkClose(true),
							},
						]}
					/>
				</div>
				<label className="text-blue block text-xs font-medium">
					{t('common.location')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom mt-1 w-40 text-xs"
						value={
							selectedLocations.map(v => {
								const d = locations.find(l => l.value === v);
								return {
									value: d?.value ?? '',
									label: typeof d !== 'undefined' ? `${d.label}` : '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values => setSelectedLocations(values?.map(v => v.value) ?? [])}
						options={locations}
					/>
				</label>
			</div>

			{bulkClose && (
				<Button primary icon={faCheck} text={`${t('case.closeSelected')} (${selectedRows.length})`} onClick={() => closeSelectedCases(selectedRows)} disabled={selectedRows.length === 0} />
			)}

			<Table
				data={operationManagerCaseData?.cases ?? []}
				keySelector={c => c.id}
				noDataFoundText={(operationManagerCaseData?.cases ?? []).length !== 0 ? '' : 'caseOverview.noCasesFound'}
				onRowClick={bulkClose ? r => toggleSelectedRows(r.erpNo) : undefined}
				rowIsHighlighted={r => selectedRows.includes(r.erpNo) && bulkClose}
				rowIsInvalid={r => isCaseValidToClose(r, activePurchaseDocumentsFlag).invalidForClose}
				loading={loadingDepartments || loadingCases}
				columns={[
					!bulkClose
						? {
							label: 'case.close',
							selectFn: c => {
								const caseInvalid = isCaseValidToClose(c, activePurchaseDocumentsFlag);

								return (
									<Popover
										placement="bottom"
										content={
											<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
												<p>{t('case.cannotCloseReason')}:</p>
												<ul className="list-disc pl-4">
													{caseInvalid.machineOnCase && <li className="font-semibold">{t('case.machinesOnCase')}</li>}
													{caseInvalid.openTimeRegistrations && <li className="font-semibold">{t('case.openTimeRegistrations')}</li>}
													{caseInvalid.openMovables && <li className="font-semibold">{t('case.openMovables')}</li>}
													{caseInvalid.openDrivingSlips && <li className="font-semibold">{t('case.openDrivingSlips')}</li>}
													{caseInvalid.activeMovables && <li className="font-semibold">{t('case.activeMovables')}</li>}
													{caseInvalid.activePurchases && <li className="font-semibold">{t('case.activePurchases')}</li>}
													{caseInvalid.activeInvoiceOnCase && <li className="font-semibold">{t('case.activeInvoice')}</li>}
												</ul>
											</div>
										}
										visible={caseInvalid.invalidForClose ? undefined : false}
									>
										<div className="flex space-x-2">
											<Button icon={faCheck} primary onClick={() => closeSelectedCases([c.erpNo])} disabled={caseInvalid.invalidForClose} />
											<Button
												icon={faBan}
												danger
												onClick={() => setIdRejectCaseClose(c.id)}
												disabled={caseInvalid.invalidForClose || c.changes.find(ch => ch.after.status === CaseStatus.APPLIED_CLOSED)?.user === undefined}
											/>
										</div>
									</Popover>
								);
							},
						}
						: {
							label: '',
							selectFn: c => {
								const caseInvalid = isCaseValidToClose(c, activePurchaseDocumentsFlag);

								return (
									<div>
										{selectedRows.includes(c.erpNo) ? (
											<FontAwesomeIcon icon={faCheckSquare} size="lg" />
										) : (
											<Popover
												placement="bottom"
												content={
													<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
														<p>{t('case.cannotCloseReason')}:</p>
														<ul className="list-disc pl-4">
															{caseInvalid.machineOnCase && <li className="font-semibold">{t('case.machinesOnCase')}</li>}
															{caseInvalid.openTimeRegistrations && <li className="font-semibold">{t('case.openTimeRegistrations')}</li>}
															{caseInvalid.openMovables && <li className="font-semibold">{t('case.openMovables')}</li>}
															{caseInvalid.openDrivingSlips && <li className="font-semibold">{t('case.openDrivingSlips')}</li>}
															{caseInvalid.activeMovables && <li className="font-semibold">{t('case.activeMovables')}</li>}
															{caseInvalid.activePurchases && <li className="font-semibold">{t('case.activePurchases')}</li>}
															{caseInvalid.activeInvoiceOnCase && <li className="font-semibold">{t('case.activeInvoice')}</li>}
														</ul>
													</div>
												}
												visible={caseInvalid.invalidForClose ? undefined : false}
											>
												<div>
													<FontAwesomeIcon icon={faSquare} size="lg" />
												</div>
											</Popover>
										)}
									</div>
								);
							},

							icon: (operationManagerCaseData?.cases ?? []).length === selectedRows.length ? faCheckSquare : faSquare,

							actionFn: () => toggleAllRows([...(operationManagerCaseData?.cases ?? []).map(c => c.erpNo)]),
						},
					{
						label: 'case.caseNo',
						selectFn: c => <p className="font-medium">{c.erpNo}</p>,
						sortFn: (a, b) => a.erpNo.localeCompare(b.erpNo),
					},
					{
						label: 'common.debitor',
						selectFn: c => (
							<>
								<p className="font-semibold">{c.debitor.company}</p>
								<p>({c.debitor.debitorId})</p>
							</>
						),
						sortFn: (a, b) => (a.debitor.company ?? '').localeCompare(b.debitor.company ?? ''),
					},
					{
						label: 'case.appliedClosedDate',
						selectFn: c => (
							<>
								{ }
								<p>{formatDate(new Date(c.changes.find(ch => ch.after.status === CaseStatus.APPLIED_CLOSED)?.timestamp ?? ''))}</p>
								<p>{formatTimestampOnly(new Date(c.changes.find(ch => ch.after.status === CaseStatus.APPLIED_CLOSED)?.timestamp ?? ''))}</p>
							</>
						),
					},
					{
						label: 'case.appliedClosedBy',
						selectFn: c => <p className="font-medium">{c.changes.find(ch => ch.after.status === CaseStatus.APPLIED_CLOSED)?.user?.name}</p>,
					},
					{
						label: 'common.location',
						selectFn: c => <p>{c.ssgLocation.name}</p>,
						sortFn: (a, b) => (a.ssgLocation.name ?? '').localeCompare(b.ssgLocation.name ?? ''),
					},
					{
						label: 'common.department',
						selectFn: c => <p>{c.ssgDepartment.departmentNumber}</p>,
						sortFn: (a, b) => a.ssgDepartment.departmentNumber - b.ssgDepartment.departmentNumber,
						numeric: true,
					},
					{
						label: 'case.economy.invoiced',
						selectFn: c => <p className="font-medium">{addThousandSeperator(c.caseEconomics?.invoicedTotal ?? 0)}</p>,
					},
					{
						label: 'case.economy.costPrice',
						selectFn: c => <p className="font-medium">{addThousandSeperator(c.caseEconomics?.costTotal ?? 0)}</p>,
					},
					{
						label: 'case.economy.coverage',
						selectFn: c => <p className="font-medium">{c.caseEconomics?.coverageRatio.toFixed(0)}%</p>,
					},
				]}
			/>
			{typeof operationManagerCaseData !== 'undefined' && operationManagerCaseData.cases.length % 20 === 0 && operationManagerCaseData.cases.length !== 0 && (
				<Button
					fullWidth
					text="common.getMore"
					secondary
					loading={fetchMoreLoading}
					className="mt-2"
					onClick={async () => {
						setFetchMoreLoading(true);
						await fetchMore({
							variables: {
								offset: operationManagerCaseData.cases.length,
							},
						});
						setFetchMoreLoading(false);
					}}
				/>
			)}

			<AppliedClosedCasseRejectionModal
				singleCase={operationManagerCaseData?.cases.find(c => c.id === idRejectCaseClose) ?? null}
				visible={idRejectCaseClose !== ''}
				rejectCaseClosing={rejectCaseClosing}
				close={() => setIdRejectCaseClose('')}
			/>
		</Box>
	);
};

export default AppliedClosedCases;
