import React from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import {
	GetWebCaseAdminData,
	GetWebCaseAdminData_damageCategories,
	GetWebCaseAdminData_damageCauses,
	GetWebCaseAdminData_departments,
	GetWebCaseAdminData_locations,
	GetUsersWithCalendars_usersWithCalendars,
} from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileUpload, faPlus, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import Box from '../../Components/Layout/Box';
import Datepicker from '@ssg/common/Components/Datepicker';
import Toggle from '@ssg/common/Components/Toggle';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Input from '@ssg/common/Components/Input';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Checkbox from '@ssg/common/Components/Checkbox';
import Fuse from 'fuse.js';
import classNames from 'classnames';
import FormFieldHeader from '../../Components/Forms/FormFieldHeader';
import Dropdown from '@ssg/common/Components/Dropdown';
import TextButton from '@ssg/common/Components/TextButton';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import DriverCarPicker, { DriverCarInput } from '../SingleCase/DrivingSlipBox/DriverCarPicker';
import { DateTime, Interval } from 'luxon';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

interface Props {
	causeList: GetWebCaseAdminData_damageCauses[];
	categoryList: GetWebCaseAdminData_damageCategories[];
	locations: GetWebCaseAdminData_locations[];
	departments: GetWebCaseAdminData_departments[];
	dsCategories: GetWebCaseAdminData['drivingSlipCategories'];
	sanitizers: GetUsersWithCalendars_usersWithCalendars[];
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
}

interface WatchProps extends Props {
	showHelpText: boolean;
}

const DrivingWantedWatch: React.FC<WatchProps> = ({ register, errors, control, setValue, showHelpText, sanitizers, causeList, locations, departments, dsCategories }): React.ReactElement => {
	const { t } = useTranslation();
	const newPlannerMessageFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_MESSAGE);
	const newPlannerAddDriverCarToDrivingSlipFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_ADD_DRIVER_CAR_TO_DRIVINGSLIP);
	const newPlannerHalfDayFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_HALF_DAY);

	// For new DriverCar add to drivingslips
	const [driversCars, setDriversCars] = React.useState<DriverCarInput[]>([{}]);
	const [shouldFilter, setShouldFilter] = React.useState<boolean>(true);

	const updateDriversCars = (index: number, driverCar: DriverCarInput) => setDriversCars(current => {
		return [
			...current.slice(0, index),
			{
				...driverCar,
			},
			...current.slice(index + 1),
		];
	});

	const removeDriverCar = (index: number) => setDriversCars(current => current.filter((_, i) => i !== index));

	const [driverSearchText, setDriverSearchText] = useDebouncedState('', 100);
	const [drivers, setDrivers] = React.useState<Array<{ id: string; label: string }>>([]);
	React.useEffect(
		() =>
			setValue(
				'DrivingSlipBox.drivingBy',
				drivers.map(({ id }) => id),
				{ shouldValidate: true },
			),
		[drivers, setValue],
	);

	React.useEffect(
		() =>
			setValue(
				'DrivingSlipBox.driverCarList',
				driversCars,
				{ shouldValidate: false },
			),
		[driversCars, setValue],
	);
	register('DrivingSlipBox.driverCarList');

	const [multipleDays, setMultipleDays] = React.useState(false);
	const additionalDaysFromInputRef = React.useRef<HTMLInputElement>(null);
	const additionalDaysToInputRef = React.useRef<HTMLInputElement>(null);
	register('DrivingSlipBox.additionalDays');

	React.useEffect(
		() =>
			setValue('DrivingSlipBox.additionalDays', [], {
				shouldValidate: true,
			}),
		[multipleDays, setValue],
	);

	const searchedUsers: SelectOption[] = React.useMemo(() => {
		let thisSanitizers = sanitizers;
		const fuse = new Fuse(sanitizers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name'],
		});

		if (driverSearchText.length > 0) {
			thisSanitizers = fuse.search(driverSearchText).map(v => v.item);
		}

		const mappedSanitizers = thisSanitizers.map((s): SelectOption => ({ value: s.id, label: s.name }));
		return mappedSanitizers;
	}, [sanitizers, driverSearchText]);

	const searchSelectHandler = (value: string): void => {
		if (value.length === 0 || drivers.some(d => d.id === value)) {
			return;
		}

		const driver = searchedUsers.find(su => su.value === value);
		if (typeof driver !== 'undefined') {
			setDrivers(drivers => [...drivers, { id: driver.value, label: driver.label }]);
		}
	};

	const changeDeadline = (): void => {
		setValue('DrivingSlipBox.deadline', startDateWatch?.substring(0, 10), {
			shouldValidate: true,
		});
		// Substring 0 - 10 is yyyy-MM-dd
	};

	const selectedLocation = useWatch({
		control,
		name: 'DrivingSlipBox.location',
	});
	const selectedDamageCause = useWatch({
		control,
		name: 'DamageBox.damageCause',
	});

	const damagePostalCode: string | undefined = useWatch({
		control,
		name: 'DamageAddressBox.damageZip',
	});

	const drivingWanted = useWatch({
		control,
		name: 'DrivingSlipBox.drivingWanted',
		defaultValue: true,
	});

	const startDateWatch = useWatch<string>({
		control,
		name: 'DrivingSlipBox.drivingDateTime',
	});

	const additionalDays = useWatch<string[]>({
		control,
		name: 'DrivingSlipBox.additionalDays',
		defaultValue: [],
	});

	const locs = React.useMemo<SelectOption[]>(() => [{ label: '', value: '' }, ...locations.map(l => ({ label: l.name, value: l.id }))], [locations]);

	const deps = React.useMemo<SelectOption[]>(
		() => [
			{ label: '', value: '' },
			...departments
				.filter(d => d.location.id === selectedLocation)
				.map(d => ({
					label: `${d.name} (${d.departmentNumber})`,
					value: d.id,
				})),
		],
		[departments, selectedLocation],
	);

	React.useEffect(() => {
		if (causeList) {
			const cause = causeList.find(c => c.id === selectedDamageCause);
			if (cause?.departments) {
				const department = cause.departments.find(d => d?.location.id === selectedLocation);
				if (department) {
					setValue('DrivingSlipBox.department', department.id);
				}
			}
		}
	}, [causeList, selectedDamageCause, selectedLocation, setValue, deps]);

	React.useEffect(() => {
		if (typeof damagePostalCode !== 'undefined') {
			const postalCodeLocation = locations.find(l => l.attachedPostalCodes.includes(damagePostalCode));
			if (typeof postalCodeLocation !== 'undefined') {
				setValue('DrivingSlipBox.location', postalCodeLocation.id);
			}
		}
	}, [damagePostalCode, locations, setValue]);

	const [files, setFiles] = React.useState<File[]>([]);
	register('DrivingSlipBox.files');
	React.useEffect(() => setValue('DrivingSlipBox.files', files, { shouldValidate: true }), [files, setValue]);
	const additionalStartDate = React.useMemo(() => {
		if (typeof startDateWatch === 'undefined') {
			return;
		}
		return DateTime.fromISO(startDateWatch).plus({ days: 1 }).toISODate();

	}, [startDateWatch]);

	const halfDayWatch = useWatch({
		control,
		name: 'DrivingSlipBox.halfDay',
		defaultValue: false,
	});

	React.useEffect(() => {
		if (halfDayWatch) {
			setValue('DrivingSlipBox.drivingTimeEstimate', 3.75, {
				shouldValidate: true,
			});
		} else {
			setValue('DrivingSlipBox.drivingTimeEstimate', undefined, {
				shouldValidate: true,
			});
		}
	}, [halfDayWatch, setValue]);

	return (
		<div className={classNames('w-full', { 'lg:w-3/4': !multipleDays })}>
			{drivingWanted && (
				<>
					<div className="-mt-6 flex flex-row">
						<div className="w-1/2"></div>

						<div className="w-1/2 pl-8">
							<Checkbox title="drivingSlips.multipleDays" name="multipleDays" onChange={e => setMultipleDays(e.target.checked)} />
						</div>
					</div>

					<div className="flex flex-row">
						<div className={multipleDays ? 'w-1/2' : 'w-full'}>
							<Datepicker
								title="case.drivingDateTime"
								name="DrivingSlipBox.drivingDateTime"
								time
								required
								errorMessage={errors.DrivingSlipBox?.drivingDateTime?.message ?? ''}
								innerRef={register}
								helpText="case.helpText.drivingDateTime"
								showHelpText={showHelpText}
								onChange={changeDeadline}
							/>

							<div className="flex flex-row flex-wrap">
								{
									newPlannerAddDriverCarToDrivingSlipFlag
										?
										<div className="w-full">
											{driversCars.map((dc, index) => <DriverCarPicker date={startDateWatch} selectedMaterials={[]} setSelectedMaterials={() => console.log()} locationName={locations.find(l => selectedLocation === l.id)?.name ?? ''} key={index} control={control} index={index} updateDriversCars={updateDriversCars} driverCar={dc} removeDriverCar={removeDriverCar} setShouldFilter={setShouldFilter} shouldFilter={shouldFilter} />)}
											<TextButton icon={faPlus} text='drivingSlips.addDriver' onClick={() => setDriversCars(current => [...current, {}])} />
										</div>
										:
										<div className="w-3/4">
											<SearchableSelect
												control={control}
												name="DrivingSlipBox.drivingBy"
												title="case.drivenBy"
												key="label"
												options={searchedUsers ?? []}
												searchFn={searchText => setDriverSearchText(searchText)}
												onSelect={value => searchSelectHandler(value)}
												onBlur={clearFn => clearFn()}
												minInputLength={2}
												isLoading={false}
												helpText="case.helpText.drivenBy"
												showHelpText={showHelpText}
											/>
										</div>
								}

								<div className="my-1 w-full">
									<Checkbox
										name="DrivingSlipBox.immediatelyPlan"
										title="drivingSlips.immediatelyPlan"
										errorMessage={errors.DrivingSlipBox?.immediatelyPlan?.message}
										innerRef={register}
										className="mt-1"
									/>
								</div>

								<ul className="w-full space-y-1">
									{drivers.map((d, i) => (
										<li key={d.id + i} className="flex items-center">
											<div className="inline-block">
												<TextButton icon={faTimes} onClick={() => setDrivers(current => current.filter(ad => ad.id !== d.id))} />
											</div>
											<span className="ml-1">{d.label}</span>
										</li>
									))}
								</ul>
							</div>
							<div className="w-full flex flex-row items-end">
								<div className="w-full">

									<Input
										title="case.drivingEstimatedTime"
										type="number"
										name="DrivingSlipBox.drivingTimeEstimate"
										innerRef={register}
										errorMessage={errors.DrivingSlipBox?.drivingTimeEstimate?.message ?? ''}
										unit="common.hours"
										step={0.01}
										lang="da"
										inputMode="decimal"
										helpText="case.helpText.drivingEstimatedTime"
										showHelpText={showHelpText}
										readOnly={halfDayWatch}
									/>
								</div>
								{newPlannerHalfDayFlag &&
									<div className="w-32 pb-3 ml-1">
										<Checkbox
											name="DrivingSlipBox.halfDay"
											title="drivingSlips.halfDay"
											innerRef={register}
											className="mt-1"
										/>
									</div>
								}

							</div>

							{newPlannerMessageFlag &&
								<Input
									title="drivingSlips.plannerMessage"
									name="DrivingSlipBox.plannerMessage"
									innerRef={register}
									placeholder="drivingSlips.plannerMessagePlaceholder"
								/>
							}
							<Datepicker title="common.deadline" name="DrivingSlipBox.deadline" errorMessage={errors.DrivingSlipBox?.deadline?.message ?? ''} innerRef={register} required />

							<div
								className={classNames('flex w-full', {
									'lg:w-full': multipleDays,
								})}
							>
								<div className="mr-3 w-1/2">
									<Dropdown required name="DrivingSlipBox.location" title="common.location" data={locs} innerRef={register} />
								</div>
								<div className="w-1/2">
									<Dropdown required name="DrivingSlipBox.department" title="common.department" data={deps} innerRef={register} />
								</div>
							</div>

							<div className="w-full">
								<Dropdown
									name="DrivingSlipBox.category"
									title="common.category"
									data={[
										{ label: '', value: '' },
										...dsCategories.map(({ code, name }) => ({
											label: name,
											value: code,
										})),
									]}
									innerRef={register}
								/>
							</div>

							<div>
								<FormFieldHeader title="common.files" />

								<label className="rounded-default border-1 text-blue cursor-pointer border-gray-300 p-1 focus:outline-none">
									<input
										type="file"
										onChange={e => {
											const file = (e.target.files ?? [])[0];
											if (typeof file !== 'undefined') {
												setFiles(current => [...current.filter(cf => cf.name !== file.name), file]);
											}
										}}
										className="hidden"
									/>

									<span>
										<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
										{t('common.chooseFile')}
									</span>
								</label>

								<ul className="mt-4 space-y-1">
									{files.map(f => (
										<li key={f.name} className="flex items-center">
											<div className="inline-block">
												<TextButton icon={faTimes} onClick={() => setFiles(current => current.filter(ef => ef.name !== f.name))} />
											</div>
											<span className="ml-1">{f.name}</span>
										</li>
									))}
								</ul>
							</div>
						</div>

						{multipleDays && (
							<div className="w-1/2 pl-8">
								<FormFieldHeader title="drivingSlips.drivingDateTimeAddNew" />
								<div className="flex flex-row">
									<div className="w-2/5">
										<Datepicker
											innerRef={additionalDaysFromInputRef}
											title="drivingSlips.drivingDateTimeFrom"
											name="additionalDaysAdd"
											min={additionalStartDate}
											defaultValue={additionalStartDate}
											className="lg:w-full"
										/>
									</div>
									<div className="w-2/5 ml-1">
										<Datepicker
											innerRef={additionalDaysToInputRef}
											title="drivingSlips.drivingDateTimeTo"
											name="additionalDaysAdd"
											min={additionalStartDate}
											defaultValue={additionalStartDate}
											className="lg:w-full"
										/>
									</div>
									<div className="w-1/5">
										<TextButton
											icon={faPlus}
											text="common.add"
											className="mt-7"
											onClick={() => {
												const from = additionalDaysFromInputRef.current?.valueAsDate;
												const to = additionalDaysToInputRef.current?.valueAsDate;
												if (typeof from !== 'undefined' && from !== null && typeof to !== 'undefined' && to !== null) {
													const fromDate = DateTime.fromJSDate(from);
													const toDate = DateTime.fromJSDate(to);
													const numberOfDays = Interval.fromDateTimes(fromDate, toDate).length('days');
													const arr = Array.from({ length: numberOfDays + 1 }, (_, i) => formatDateForInput(fromDate.plus({ days: i }).toJSDate()));
													const value = [...additionalDays, ...arr];
													value.sort((a, b) => a.localeCompare(b));
													setValue('DrivingSlipBox.additionalDays', value, {
														shouldValidate: true,
													});

													if (additionalDaysFromInputRef.current !== null) {
														additionalDaysFromInputRef.current.value = '';
													}
													if (additionalDaysToInputRef.current !== null) {
														additionalDaysToInputRef.current.value = '';
													}
												}
											}}
										/>
									</div>
								</div>

								<ul className="text-blue mt-4 space-y-2">
									<li className="text-sm font-semibold">{t('common.start')}</li>
									{typeof startDateWatch !== 'undefined' && (
										<li>
											<FontAwesomeIcon
												icon={faCalendar}
												style={{
													marginLeft: 1,
													marginRight: 1,
												}}
											/>
											<span className="ml-2">{new Date(startDateWatch).toLocaleString()}</span>
										</li>
									)}

									<li>
										<hr className="border-gray-200" />
									</li>

									<li className="text-sm font-semibold">{t('drivingSlips.additionalDays')}</li>
									{additionalDays.map(d => (
										<li key={d}>
											<div className="inline-block">
												<TextButton icon={faTrash} onClick={() => {
													const value = additionalDays.filter(ad => ad !== d);
													setValue('DrivingSlipBox.additionalDays', value, {
														shouldValidate: true,
													});
												}}
												/>
											</div>
											<span className="ml-1">{dateToDateTimeString(d, true)}</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const DrivingSlipBox: React.FC<Props> = ({ register, errors, control, setValue, sanitizers, causeList, categoryList, locations, departments, dsCategories }): React.ReactElement => {
	const [showHelpText, setShowHelpText] = React.useState(false);

	return (
		<Box form title="common.drivingSlip" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton>
			{showHelpText && <FormHelpText text="case.helpText.drivingSlip" />}

			<hr className="mb-2" />

			<Toggle
				text="case.drivingWanted"
				name="DrivingSlipBox.drivingWanted"
				defaultChecked={true}
				disabled={false}
				innerRef={register}
				helpText="case.helpText.drivingWanted"
				showHelpText={showHelpText}
				className="text-blue"
			/>

			<DrivingWantedWatch
				register={register}
				errors={errors}
				control={control}
				setValue={setValue}
				showHelpText={showHelpText}
				sanitizers={sanitizers}
				causeList={causeList}
				categoryList={categoryList}
				locations={locations}
				departments={departments}
				dsCategories={dsCategories}
			/>
		</Box>
	);
};

export default DrivingSlipBox;
