import React from 'react';
import { faFileUpload, faSearch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
	GetCatalogFile,
	GetCatalogFileVariables,
	Permissions,
	GetWebCatalogCustomers_catalogCustomers,
	GetGlobalCatalogESDHFiles,
	LogCatalogFileChangeVariables,
	LogCatalogFileChange,
	FileActionType,
	UploadCatalogFiles,
	UploadCatalogFilesVariables,
	FileInput,
	GetGlobalCatalogESDHFilesVariables,
	GetGlobalCatalogESDHFiles_globalCatalogFiles,
} from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { toBase64, stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Box from '../../../Components/Layout/Box';
import UserContext from '../../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import TextButton from '@ssg/common/Components/TextButton';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import DeleteGlobalCatalogFilesModal from './DeleteGlobalCatalogFilesModal';
import classNames from 'classnames';
import Pill from '@ssg/common/Components/Pill';

interface FileProps {
	fileName: string;
	folder?: string;
}

interface Props {
	changedBy: string;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
}

const GET_FILE = loader('src/GraphQL/Files/GetCatalogFile.gql');
const UPLOAD_FILES = loader('src/GraphQL/Files/UploadCatalogFiles.gql');
const LOG_FILE_CHANGE = loader('src/GraphQL/Files/LogCatalogFileChange.gql');
const GET_GLOBAL_CATALOG_FILES = loader('src/GraphQL/Files/GetGlobalCatalogESDHFiles.gql');

const GlobalFiles: React.FC<Props> = ({ changedBy, catalogCustomers, catalogCustomer }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);
	const isExternalUser = userContext.user?.external;

	const [filenameToDelete, setFilenameToDelete] = React.useState<string | undefined>(undefined);

	const [customer, setCustomer] = React.useState<GetWebCatalogCustomers_catalogCustomers>();
	const [userSearchString, setUserSearchString] = useDebouncedState<string>('', 100);

	const [customerIds, setCustomerIds] = React.useState<string[] | null>(null);
	const [customerSearchText, setCustomerSearchText] = React.useState('');

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);
	React.useEffect(() => {
		setCustomers(catalogCustomers.filter(ct => ct.name.toLowerCase().includes(customerSearchText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, customerSearchText]);

	const [searchString, setSearchString] = useDebouncedState('', 200);
	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const {
		data: globalFiles,
		loading: globalFilesLoading,
		fetchMore,
	} = useQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>(GET_GLOBAL_CATALOG_FILES, {
		variables: {
			thisCustomerOnly: isExternalUser,
			customerIds: customerIds,
			offset: 0,
			limit: 20,
			searchString: searchString.length === 0 ? null : searchString,
		},
	});

	const [uploadCatalogFiles, { loading: loadingUploadFiles }] = useMutation<UploadCatalogFiles, UploadCatalogFilesVariables>(UPLOAD_FILES);

	const searchedUsers = React.useMemo(
		() => catalogCustomers.filter(cat => cat.name.toLowerCase().includes(userSearchString.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })),
		[catalogCustomers, userSearchString],
	);

	const [fileData, setFileData] = React.useState<FileProps>({
		fileName: '',
		folder: '',
	});

	const { data: file, loading: fileLoading } = useQuery<GetCatalogFile, GetCatalogFileVariables>(GET_FILE, {
		variables: {
			fileName: fileData.fileName,
			folder: fileData.folder,
		},
		skip: fileData.fileName === '',
	});

	const [logFileChange] = useMutation<LogCatalogFileChange, LogCatalogFileChangeVariables>(LOG_FILE_CHANGE);

	async function uploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files || e.target.files.length === 0) {
			alert(t('error.fileMissing'));
			return;
		} else if (!customer) {
			alert(t('catalog.globalFiles.selectCustomer'));
			return;
		}

		const returnValues: FileInput[] = [];

		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files[i];

			const fileAsString = await toBase64(file);

			const result = stringToByteArray(fileAsString);

			returnValues.push({ fileData: result, filename: file.name });
		}

		await uploadCatalogFiles({
			variables: {
				files: returnValues,
				customerId: customer.id,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>({
					query: GET_GLOBAL_CATALOG_FILES,
					variables: {
						thisCustomerOnly: isExternalUser,
						customerIds: customerIds,
						offset: 0,
						limit: 20,
						searchString: searchString.length === 0 ? null : searchString,
					},
				});

				if (cachedRequest === null || cachedRequest.globalCatalogFiles === null) {
					return;
				}

				cache.writeQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>({
					query: GET_GLOBAL_CATALOG_FILES,
					variables: {
						thisCustomerOnly: isExternalUser,
						customerIds: customerIds,
						offset: 0,
						limit: 20,
						searchString: searchString.length === 0 ? null : searchString,
					},
					data: {
						globalCatalogFiles: [...cacheData.uploadCatalogFiles, ...cachedRequest.globalCatalogFiles],
					},
				});
			},
		});

		await logFileChange({
			variables: {
				filename: returnValues.map(val => `${val.filename}`).join(', '),
				customerId: customer ? customer.id : catalogCustomer ? catalogCustomer.id : '',
				globalFile: true,
				action: FileActionType.ADD,
				changedBy: changedBy,
			},
		});
	}

	const { control } = useForm();

	React.useEffect(() => {
		if (file?.catalogFile) {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(file.catalogFile.fileData)]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${file.catalogFile.filename}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
		}
	}, [fileData, file]);

	const getCustomerName = (fileObject: GetGlobalCatalogESDHFiles_globalCatalogFiles): string => {
		return catalogCustomers.find(cc => cc.id === fileObject.metadata.find(m => m.key === 'Kunde')?.value)?.name ?? 'SKAL OPDATERES';
	};

	return (
		<Box title="" full>
			<div className="text flex">
				<div className="mr-3">
					<label className="block text-xs font-medium">{t('common.search')}</label>
					<div className="flex">
						<div className="relative w-full">
							<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<Input name="overview-search" placeholder={t('catalog.globalFiles.searchPlaceholder')} className="pr-8 text-sm lg:w-full" onChange={e => setSearchString(e.target.value)} />
						</div>
					</div>
				</div>
				<div className="mr-3">
					<label className="mb-1 block text-xs font-medium">
						{t('catalog.customer')}
						<SearchableSelect
							key="customer-filter"
							control={control}
							name="customerFilter"
							options={customers}
							searchFn={searchText => setCustomerSearchText(searchText)}
							onSelect={value => {
								if (value !== '') setCustomerIds(ids => [...(ids ?? []), value]);
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={-1}
							isLoading={false}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{customerIds?.map(d => (
							<li key={d}>
								<Pill onClick={() => setCustomerIds(ids => (ids ?? []).filter(id => id !== d))}>{catalogCustomers.filter(cc => cc.id === d)[0].name}</Pill>
							</li>
						))}
					</ul>
				</div>
				{canEdit && (
					<div className="ml-auto mr-0 flex flex-row">
						<div className="mr-3 -mt-2">
							<label className="mb-1 block text-xs font-medium">
								<SearchableSelect
									allowEmpty
									key="userlabel"
									control={control}
									name="customerForUpload"
									title="catalog.selectCustomerToUpload"
									options={searchedUsers ?? []}
									searchFn={value => {
										setUserSearchString(value);
									}}
									onSelect={value => {
										setCustomer(catalogCustomers.find(u => u.id === value));
										setUserSearchString(catalogCustomers.find(u => u.id === value)?.name ?? '');
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={false}
									className="w-full text-sm lg:w-full"
								/>
							</label>
						</div>
						<div className="mt-4 ml-auto">
							<label
								className={classNames('rounded-default bg-blue flex cursor-pointer flex-col items-center justify-center p-2 text-white focus:outline-none', {
									'cursor-not-allowed opacity-50': typeof customer === 'undefined',
								})}
							>
								<span>
									<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
									{t('case.chooseFiles')}
								</span>
								<input type="file" autoComplete="nope" className="hidden" onChange={e => uploadFiles(e)} multiple disabled={typeof customer === 'undefined'} />
							</label>
						</div>
					</div>
				)}
			</div>
			<Table
				data={globalFiles?.globalCatalogFiles ?? []}
				loading={typeof globalFiles === 'undefined' || loadingUploadFiles || globalFilesLoading || fileLoading}
				columns={[
					{
						label: 'catalog.fileName',
						selectFn: f => (
							<div>
								{decodeURI(f.url).includes('Globale filer') ? (
									<TextButton text={f.name} onClick={() => setFileData({ fileName: f.name })} />
								) : (
									<TextButton
										text={f.name}
										onClick={() =>
											setFileData({
												fileName: f.name,
												folder: '',
											})
										}
									/>
								)}
							</div>
						),
					},
					{
						label: 'catalog.customer',
						selectFn: f => <p className="py-1">{getCustomerName(f)}</p>,
						sortFn: (a, b) => getCustomerName(a).localeCompare(getCustomerName(b)),
					},
					{
						label: 'common.updatedAt',
						selectFn: f => <p className="py-1">{dateToDateTimeString(f.metadata.find(m => m.key === 'Modified')?.value ?? '')}</p>,
					},
					{
						label: t('common.remove'),
						classNameTh: 'text-right',
						hideColumn: !canEdit,
						selectFn: f => (
							<div className="text-red flex content-start justify-end text-right">
								<FontAwesomeIcon
									icon={faTrashAlt}
									size="lg"
									onClick={() => {
										setFilenameToDelete(f.name);
									}}
									className="cursor-pointer"
								/>
							</div>
						),
					},
				]}
				keySelector={f => f.name}
			/>
			{typeof globalFiles !== 'undefined' && globalFiles.globalCatalogFiles.length % 20 === 0 && globalFiles.globalCatalogFiles.length !== 0 && (
				<Button
					fullWidth
					text="common.getMore"
					secondary
					loading={fetchMoreLoading}
					className="mt-2"
					onClick={async () => {
						setFetchMoreLoading(true);
						await fetchMore({
							variables: {
								offset: globalFiles.globalCatalogFiles.length,
							},
						});
						setFetchMoreLoading(false);
					}}
				/>
			)}

			{typeof filenameToDelete !== 'undefined' && (
				<DeleteGlobalCatalogFilesModal
					filenameToDelete={filenameToDelete}
					visible={typeof filenameToDelete !== 'undefined'}
					close={() => setFilenameToDelete(undefined)}
					fetchVariables={
						//This is dirty and ugly and should be improved
						typeof globalFiles !== 'undefined' && globalFiles.globalCatalogFiles.length % 20 === 0 && globalFiles.globalCatalogFiles.length !== 0
							? {}
							: {
								thisCustomerOnly: isExternalUser,
								offset: 0,
								limit: 20,
								//customerId: catalogCustomer?.id,
								searchString: searchString || null,
							}
					}
				/>
			)}
		</Box>
	);
};

export default GlobalFiles;
