import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
	totalCount?: number | null;
	loading: boolean;
	quantityText: string;
	entityText: string;
}

const TotalCount: React.FC<Props> = ({ totalCount, loading, quantityText, entityText }): React.ReactElement => {
	return (
		<p className="text-blue text-sm font-semibold">
			{quantityText} <span className="mr-1 lowercase">{entityText}:</span>
			{loading ? <FontAwesomeIcon icon={faSpinner} className="text-blue animate-spin" size="1x" /> : <>{totalCount}</>}
		</p>
	);
};

export default TotalCount;
