import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const CaseViewSSGInfoSchema = yup.object().shape({
	ssgProjectManager: yup.string().nullable(),

	ssgCaseManager: yup.string().nullable(),

	ssgLocation: yup.string(),

	ssgDepartment: yup.string(),

	invoiceType: yup.string(),

	workComplete: yup.boolean(),

	billingReady: yup.boolean(),
});
