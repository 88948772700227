import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const PolicyHolderSchema = yup.object().shape({
	policyCountry: yup.string(),

	policyPhone: yup.string(),

	policyName: yup.string(),

	policyAttention: yup.string(),

	policyAddress: yup.string(),

	policyAddress2: yup.string(),

	policyZip: yup.string(),

	policyCity: yup.string(),

	policyEmail: yup.string().when('noEmail', {
		is: (noEmail: boolean) => noEmail,
		then: yup.string().email(),
		otherwise: yup.string().email(),
	}),

	noEmail: yup.boolean(),

	//policyMobile: yup.number().transform( cv => isNaN(cv) ? undefined : cv).positive().integer(),
});
