import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import React from 'react';
import { MergedDrivingSlip } from './PlannerDrivingSlips';
import DriverCarPicker, { DriverCarInput, IdLabel, driverAndCarIsDefined, getCarLabel, idLabelIsDefined } from '../SingleCase/DrivingSlipBox/DriverCarPicker';
import { useForm, useWatch } from 'react-hook-form';
import { DrivingSlipInput } from '@ssg/common/GraphQL/indexV2';
import { yupResolver } from '@hookform/resolvers/yup';
import { drivingSlipSchema } from '../../Schemas/DrivingSlipSchema';
import { usePlannerDrivingSlips } from './PlannerHelpers';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Checkbox from '@ssg/common/Components/Checkbox';
import Datepicker from '@ssg/common/Components/Datepicker';
import Input from '@ssg/common/Components/Input';
import TextButton from '@ssg/common/Components/TextButton';
import { faCalendar, faExclamationCircle, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import Alert from '@ssg/common/Components/Alert';
import Textarea from '@ssg/common/Components/Textarea';
import Button from '@ssg/common/Components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import { DateTime, Interval } from 'luxon';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';


interface FormInput extends Omit<DrivingSlipInput, 'driver' | 'end'> {
	driver: string[];
	plannedDuration?: number;
	immediatelyPlan: boolean;
}

interface Props {
	drivingSlip: MergedDrivingSlip;
	editId: string;
	visible: boolean;
	close: () => unknown;
}
const getInitialDriverCar = (data?: MergedDrivingSlip['merged'][number]): DriverCarInput => {
	if (typeof data === 'undefined') {
		return {};
	}

	return {
		car: data.car ? { id: data.car.vehicleNumber, label: getCarLabel(data.car) } : undefined,
		driver: data.driver ? { id: data.driver.id, label: data.driver.name } : undefined,
	};
};
const PlannerEditDrivingSlip: React.FC<Props> = ({ drivingSlip, editId, visible, close }): React.ReactElement => {

	const { t } = useTranslation();


	// For new DriverCar add to drivingslips
	const [driversCars, setDriversCars] = React.useState<DriverCarInput[]>([getInitialDriverCar(drivingSlip.merged.find(m => m.id === editId))]);
	const [selectedMaterials, setSelectedMaterials] = React.useState<IdLabel[]>((drivingSlip.merged.find(m => m.id === editId)?.materials ?? []).map(m => ({ id: m.vehicleNumber, label: getCarLabel(m) })));
	const [shouldFilter, setShouldFilter] = React.useState<boolean>(true);
	const { updateSingleDrivingSlip, updateDrivingSlipSubmitting, deleteDrivingSlipSubmitting, deleteSingleDrivingSlip } = usePlannerDrivingSlips();

	const updateDriversCars = (index: number, driverCar: DriverCarInput) => setDriversCars(current => {
		return [
			...current.slice(0, index),
			{
				...driverCar,
			},
			...current.slice(index + 1),
		];
	});

	const removeDriverCar = (index: number) => setDriversCars(current => current.filter((_, i) => i !== index));
	const [multipleDays, setMultipleDays] = React.useState(false);
	const additionalDaysFromInputRef = React.useRef<HTMLInputElement>(null);
	const additionalDaysToInputRef = React.useRef<HTMLInputElement>(null);
	const [additionalDays, setAdditionalDays] = React.useState<string[]>([]);

	//const { adminData: { usersWithCalendars } } = usePlannerAdminData();
	const { handleSubmit, register, setValue, errors, control } = useForm<FormInput>({
		resolver: yupResolver(drivingSlipSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			driver: drivingSlip.driver !== null ? [drivingSlip.driver.id] : [],
			location: drivingSlip.location.id ?? '',
			department: drivingSlip.department.id ?? '',
		},
	});
	const onSubmit = async (data: FormInput) => {
		if (typeof editId === 'undefined') {
			return;
		}

		try {
			const endDate = new Date(data.start);
			endDate.setHours(new Date(data.start).getHours() + (data.plannedDuration ?? 1)); // Default is one hour duration
			const filteredDrivers = driversCars.map(dc => dc.driver).filter(idLabelIsDefined);
			const driverCarList = driverAndCarIsDefined(driversCars);

			await updateSingleDrivingSlip({
				id: editId,
				driver: filteredDrivers.length === 0 ? null : filteredDrivers[0].id,
				car: driverCarList.find(dcl => filteredDrivers[0]?.id === dcl.driver?.id)?.car?.id ?? null,
				start: data.start,
				end: endDate.toISOString(),
				materials: selectedMaterials.map(sm => sm.id),
				urgent: data.urgent,
				comment: data.comment,
				location: data.location,
				department: data.department,
				eventId: null,
				estimatedHours: data.plannedDuration,
				immediatelyPlan: data.immediatelyPlan,
				additionalDays: additionalDays,
				additionalDrivers: filteredDrivers.slice(1).map(driver => driver.id),
				plannerMessage: data.plannerMessage,
				driverCarList: driverAndCarIsDefined(driverCarList).map(dc => ({ car: dc.car.id, driver: dc.driver.id })),
				halfDay: data.halfDay,

			});
			close();

		} catch (e) {
			console.log(e);
		}
	};

	register('location');
	register('department');

	const halfDayWatch = useWatch({
		control,
		name: 'halfDay',
		defaultValue: drivingSlip.halfDay ?? false,
	});

	React.useEffect(() => {
		if (halfDayWatch) {
			setValue('plannedDuration', 3.75, {
				shouldValidate: true,
			});
		} else {
			setValue('plannedDuration', undefined, {
				shouldValidate: true,
			});
		}
	}, [halfDayWatch, setValue]);
	const startDateWatch = useWatch({
		control,
		name: 'start',
		defaultValue: drivingSlip.merged.find(({ id }) => id === editId)?.start,
	});

	const additionalStartDate = React.useMemo(() => {
		if (typeof startDateWatch === 'undefined') {
			return;
		}
		return DateTime.fromISO(startDateWatch).plus({ days: 1 }).toISODate();

	}, [startDateWatch]);
	return (
		<Modal
			title="drivingSlips.update"
			close={() => close()}
			visible={visible}
			size={!multipleDays ? ModalSize.MEDIUM : ModalSize.LARGE}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-row">
						<div className="w-1/2">
							<Checkbox title="common.urgent" name="urgent" innerRef={register} errorMessage={errors.urgent?.message} defaultChecked={drivingSlip.urgent} />
						</div>

						<div className="w-1/2 pl-8">
							<Checkbox title="drivingSlips.multipleDays" name="multipleDays" onChange={e => setMultipleDays(e.target.checked)} />
						</div>
					</div>

					<div className="flex flex-row">
						<div className={multipleDays ? 'w-1/2' : 'w-full'}>
							<Datepicker
								time
								title="case.drivingDateTime"
								name="start"
								required
								errorMessage={errors.start?.message ?? ''}
								innerRef={register}
								defaultValue={formatDateForInput(new Date(drivingSlip.merged.find(m => m.id === editId)?.start ?? 0), true)}
							/>
							<div className="w-full flex flex-row items-end">
								<div className="w-full">

									<Input
										title="case.drivingEstimatedTime"
										type="number"
										name="plannedDuration"
										innerRef={register}
										errorMessage={errors.plannedDuration?.message ?? ''}
										unit="common.hours"
										step={0.01}
										lang="da"
										inputMode="decimal"
										defaultValue={drivingSlip?.estimatedHours ?? undefined}
										readOnly={halfDayWatch}
									/>
								</div>

								<div className="w-32 pb-3 ml-1">
									<Checkbox
										name="halfDay"
										title="drivingSlips.halfDay"
										innerRef={register}
										className="mt-1"
										defaultChecked={drivingSlip.halfDay}
									/>
								</div>

							</div>

							<Input
								title="drivingSlips.plannerMessage"
								name="plannerMessage"
								innerRef={register}
								unitClassName="w-full lg:w-full"
								defaultValue={drivingSlip?.plannerMessage ?? undefined}
								placeholder="drivingSlips.plannerMessagePlaceholder"
							/>


							{/* <SearchableSelect
									control={control}
									name="driver"
									title="case.drivenBy"
									key="label"
									className="mb-2"
									options={searchedUsers ?? []}
									searchFn={(searchText): void => setDriverSearchText(searchText)}
									onSelect={(value): void => searchSelectHandler(value)}
									onBlur={() => undefined}
									minInputLength={2}
									isLoading={false}
									initialSelection={{
										value: drivingSlip.driver?.id ?? '',
										label: drivingSlip.driver?.name ?? '',
									}}
								/> */}
							<div className="w-full">
								{driversCars.map((dc, index) => <DriverCarPicker date={startDateWatch} showAddMaterials={true} selectedMaterials={selectedMaterials} setSelectedMaterials={setSelectedMaterials} locationName={drivingSlip.location.name} key={index} control={control} index={index} updateDriversCars={updateDriversCars} driverCar={dc} removeDriverCar={removeDriverCar} setShouldFilter={setShouldFilter} shouldFilter={shouldFilter} />)}
								<TextButton icon={faPlus} text='drivingSlips.addDriver' onClick={() => setDriversCars(current => [...current, {}])} />
							</div>
							{drivingSlip.merged.length > 1 && (
								<Alert icon={faExclamationCircle} text="drivingSlips.moreOnSeriesWarning" warning />
							)}
							<div className="my-1 w-full">
								<Checkbox
									name="immediatelyPlan"
									title="drivingSlips.immediatelyPlan"
									errorMessage={errors.immediatelyPlan?.message}
									innerRef={register}
									className="mt-1"
								/>
							</div>

							<Textarea
								title="drivingSlips.commentForUser"
								name="comment"
								rows={3}
								innerRef={register}
								errorMessage={errors.comment?.message ?? ''}
								defaultValue={drivingSlip.merged.find(m => m.id === editId)?.comment ?? ''}
							/>
						</div>

						{multipleDays && (
							<div className="w-1/2 pl-8">
								<FormFieldHeader title="drivingSlips.drivingDateTimeAddNew" />
								<div className="flex flex-row">
									<div className="w-2/5">
										<Datepicker
											innerRef={additionalDaysFromInputRef}
											title="drivingSlips.drivingDateTimeFrom"
											name="additionalDaysAdd"
											min={additionalStartDate}
											defaultValue={additionalStartDate}
											className="lg:w-full"
										/>
									</div>
									<div className="w-2/5 ml-1">
										<Datepicker
											innerRef={additionalDaysToInputRef}
											title="drivingSlips.drivingDateTimeTo"
											name="additionalDaysAdd"
											min={additionalStartDate}
											defaultValue={additionalStartDate}
											className="lg:w-full"
										/>
									</div>
									<div className="w-1/5">
										<Button
											icon={faPlus}
											text="common.add"
											className="mt-7"
											onClick={() => {
												const from = additionalDaysFromInputRef.current?.valueAsDate;
												const to = additionalDaysToInputRef.current?.valueAsDate;

												if (typeof from !== 'undefined' && from !== null && typeof to !== 'undefined' && to !== null) {
													const fromDate = DateTime.fromJSDate(from);
													const toDate = DateTime.fromJSDate(to);
													const numberOfDays = Interval.fromDateTimes(fromDate, toDate).length('days');
													const arr = Array.from({ length: numberOfDays + 1 }, (_, i) => formatDateForInput(fromDate.plus({ days: i }).toJSDate()));
													setAdditionalDays(current => {
														const updated = [...new Set([...current, ...arr])];
														updated.sort((a, b) => a.localeCompare(b));
														return updated;
													});

													if (additionalDaysFromInputRef.current !== null) {
														additionalDaysFromInputRef.current.value = '';
													}
													if (additionalDaysToInputRef.current !== null) {
														additionalDaysToInputRef.current.value = '';
													}
												}
											}}
										/>
									</div>
								</div>

								<ul className="mt-4 space-y-2">
									<li className="text-sm font-semibold">{t('common.start')}</li>
									{typeof startDateWatch !== 'undefined' && (
										<li>
											<FontAwesomeIcon
												icon={faCalendar}
												style={{
													marginLeft: 1,
													marginRight: 1,
												}}
											/>
											<span className="ml-2">{startDateWatch.length > 0 ? dateToDateTimeString(startDateWatch) : t('common.noneSpecified')}</span>
										</li>
									)}

									<li>
										<hr className="border-gray-200" />
									</li>

									<li className="text-sm font-semibold">{t('drivingSlips.additionalDays')}</li>
									{additionalDays.map(d => (
										<li key={d}>
											<div className="inline-block">
												<TextButton icon={faTrash} onClick={() => setAdditionalDays(current => current.filter(ad => ad !== d))} />
											</div>
											<span className="ml-1">{dateToDateTimeString(d, true)}</span>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>

					<div className="mt-3 flex flex-row justify-between">
						<Button submit success text="common.update" loading={updateDrivingSlipSubmitting} disabled={deleteDrivingSlipSubmitting} />

						<Button
							danger
							text="common.delete"
							loading={deleteDrivingSlipSubmitting}
							onClick={async () => {
								const id = editId;
								await deleteSingleDrivingSlip({
									caseId: drivingSlip.case.id,
									id,
								});

								close();
							}}
							disabled={updateDrivingSlipSubmitting}
						/>
					</div>
				</form>
			}
		/>

	);
};

export default PlannerEditDrivingSlip;