import React from 'react';
import { useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permissions } from '@ssg/common/GraphQL';
import { formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetUserCalendars, SetUserPlannerHelpText, SetUserPlannerHelpTextVariables } from '../../GraphQL';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import classNames from 'classnames';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';

const SET_USER_HELP_TEXT = loader('src/GraphQL/Users/SetUserPlannerHelpText.gql');

interface Props {
	userId: GetUserCalendars['userCalendars'][number]['user']['id'];
	userHelpText: GetUserCalendars['userCalendars'][number]['user']['plannerHelpText'];
	updatedAt: GetUserCalendars['userCalendars'][number]['user']['plannerHelpTextUpdatedAt'];
}

const UserHelpText: React.FC<Props> = ({ userId, userHelpText, updatedAt }) => {
	const { t } = useTranslation();

	const [helpText, setHelpText] = React.useState(userHelpText ?? '');
	const [modalActive, setModalActive] = React.useState(false);

	const [setUserPlannerHelpText, { loading }] = useMutation<SetUserPlannerHelpText, SetUserPlannerHelpTextVariables>(SET_USER_HELP_TEXT);

	return (
		<>
			{userHelpText}
			<RestrictedArea permissions={[Permissions.PLANNING_EDIT]}>
				<FontAwesomeIcon
					icon={faEdit}
					className={classNames('cursor-pointer', {
						'ml-2': (userHelpText ?? '').length > 0,
					})}
					onClick={() => setModalActive(true)}
				/>
			</RestrictedArea>

			{updatedAt !== null && (
				<>
					<br />
					<small>{`${t('common.updatedAt')} ${formatTimestamp(new Date(updatedAt))}`}</small>
				</>
			)}

			<Modal
				visible={modalActive}
				size={ModalSize.SMALL}
				title="planner.userHelpText"
				body={<Input name="helptext" defaultValue={helpText} onChange={e => setHelpText(e.target.value)} />}
				footer={
					<Button
						primary
						text="common.save"
						onClick={async () => {
							await setUserPlannerHelpText({
								variables: {
									id: userId,
									helpText,
								},
							});
							setModalActive(false);
						}}
						disabled={loading}
					/>
				}
				close={() => setModalActive(false)}
			/>
		</>
	);
};
export default UserHelpText;
