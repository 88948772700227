import React from 'react';
import { DateBooking, DragItemTypes, useCanEdit, Bookings } from './PlannerHelpers';
import { DragObjectWithType, useDrag } from 'react-dnd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faTrailer } from '@fortawesome/pro-regular-svg-icons';
import { VehicleFragment } from '@ssg/common/GraphQL/indexV2';
import { getEmptyImage } from 'react-dnd-html5-backend';
import Popover from '@ssg/common/Components/Popover';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';



export interface DragPropsVehicleItem extends DragObjectWithType {
	vehicle: VehicleFragment,
	bookings: DateBooking[];
}

interface Props {
	vehicle: VehicleFragment;
	dragItemType: DragItemTypes.CAR | DragItemTypes.MATERIAL;
	bookings: DateBooking[];
}



const VehicleCard: React.FC<Props> = ({ vehicle, dragItemType, bookings }): React.ReactElement => {

	const { canEdit } = useCanEdit();

	const [{ isDragging }, dragRef, preview] = useDrag<DragPropsVehicleItem, void, { isDragging: boolean }>({
		item: {
			type: dragItemType,
			vehicle,
			bookings,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	// Used to hide the Drivngslip Card preview on drag
	React.useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const ref = canEdit ? dragRef : null;
	return (
		<div
			ref={ref}
			className={classNames('border-1 rounded-default mb-1 flex-row flex w-full bg-white p-1 text-sm', { 'opacity-50': isDragging })}
		>
			<div className="mr-2 flex w-10 items-center justify-center">
				<div className="border-1 flex h-10 w-10 items-center justify-center rounded-full border-white bg-gray-900 text-lg text-white"><FontAwesomeIcon icon={dragItemType === DragItemTypes.MATERIAL ? faTrailer : faCar} /></div>
			</div>
			<div className="w-full">
				<p className="truncate font-medium">{vehicle.brand} ({vehicle.vehicleNumber})</p>
				<p className="truncate font-medium">{toSentenceCase(vehicle.locationCode)} (Afd. {vehicle.departmentCode})</p>
				{vehicle.username && <p className="truncate font-medium">{vehicle.username}</p>}
				<div className="flex w-full flex-row space-x-px">
					{bookings.map((b, _, arr) =>
						<Popover
							duration={0}
							delay={[200, 0]}
							placement="top"
							visible={isDragging ? false : undefined}
							content={
								<div className="rounded-b-default border-1 shadow-default border-blue bg-white p-2 text-blue">
									<p className="w-full text-center">
										{b.weekDay}
									</p>
									<p className="w-full text-center">
										{b.date}
									</p>
									{b.bookings.map((booking, index) => <p key={index}>{booking.driver?.name}</p>)}
								</div>
							}
							key={b.date + vehicle.vehicleNumber}
						>
							<div className="flex-1 flex flex-col justify-center items-center">
								<div key={b.date + vehicle.vehicleNumber} className={classNames(' w-full h-8 flex flex-col justify-center items-center', {
									'bg-red-calm text-white': b.bookings.length > 0,
									'bg-green-calm text-white': b.bookings.length === 0,
								})}>
									<p className="text-xs -mb-px">{b.weekDay.slice(0, arr.length > 7 ? 1 : 3)}</p>
									<p className="text-xs -mt-px">{1}</p>
								</div>
							</div>
						</Popover>,
					)}
				</div>
			</div>
		</div>
	);
};

export default VehicleCard;