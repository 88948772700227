import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

interface IProps {
	before: string;
	after: string;
}

const CatalogDescriptionChangeLog: React.FC<IProps> = ({ before: textBefore, after: textAfter }) => {
	const { t } = useTranslation();

	const divRefBefore = React.createRef<HTMLDivElement>();
	const divRefAfter = React.createRef<HTMLDivElement>();

	React.useEffect(() => {
		if (divRefBefore.current) {
			divRefBefore.current.innerHTML = sanitizeHtml(textBefore, {
				allowedAttributes: { '*': ['style'] },
			});
		}
	}, [divRefBefore, textBefore]);

	React.useEffect(() => {
		if (divRefAfter.current) {
			divRefAfter.current.innerHTML = sanitizeHtml(textAfter, {
				allowedAttributes: { '*': ['style'] },
			});
		}
	}, [divRefAfter, textAfter]);

	return (
		<div>
			<p className="mt-2">
				<strong>{t('catalog.changedFrom')}</strong>
			</p>
			<div className="flex">
				<div ref={divRefBefore}></div>
			</div>
			<p className="mt-2">
				<strong>{t('catalog.changedTo')}</strong>
			</p>
			<div className="flex">
				<div ref={divRefAfter}></div>
			</div>
		</div>
	);
};

export default CatalogDescriptionChangeLog;
