import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { GetWebCases, GetWebCasesVariables, UserCaseFilterViewType, AddressInput, DrivingSlipStatus, CasePriority, GetWebCaseAdminData } from '../../GraphQL';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useTranslation } from 'react-i18next';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { GraphQLExtensionsData } from '@ssg/common/Components/GraphQLExtensionsContext';
import Header from '@ssg/common/Components/Header';
import CaseOverviewFilter from './CaseOverviewFilter';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import CasesExport from './CasesExport';
import UserContext from '../../UserContext';
import Box from '../../Components/Layout/Box';
import CaseTable from './CaseTable';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Button from '@ssg/common/Components/Button';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';
import { favoriteColors } from '../../Components/FavoriteToggle';

const GET_CASES = loader('src/GraphQL/Cases/GetWebCases.gql');
const GET_CASE_ADMIN_DATA = loader('../../GraphQL/Cases/GetWebCaseAdminData.gql');

const FETCH_LIMIT = 20;

export interface PersonalFilter {
	name?: string | null;
	address: AddressInput | null;
	view: UserCaseFilterViewType;
	awaiting: boolean | null;
	damageServiceCompleted: boolean | null;
	calledBack: boolean | null;
	machinesOnCase: boolean | null;
	showAppliedClosedCases: boolean;
	showClosedCases: boolean;
	showPriorityCustomerCases: boolean;
	debitors: string[] | undefined;
	postalCodeText: string;
	postalCodes: string[] | undefined;
	track: number[] | undefined;
	departments: string[] | undefined;
	locations: string[] | undefined;
	damageCategories: string[] | undefined;
	damageCauses: string[] | undefined;
	priority: CasePriority[] | undefined;
	caseManagers: string[] | undefined;
	projectManagers: string[] | undefined;
	minDate: Date | undefined;
	maxDate: Date | undefined;
	showEconomyField: boolean;
	showBusinessAreaField: boolean;
	showCategoryCause: boolean;
	filterTerms: string;
	favoriteColors: string[];
}

const CaseOverview: React.FC = () => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const [activeFilters, setActiveFilters] = useStorageState<PersonalFilter>(window.sessionStorage, 'activeCaseFilters', {
		name: null,
		address: null,
		view: UserCaseFilterViewType.OWN,
		awaiting: null,
		damageServiceCompleted: null,
		calledBack: null,
		machinesOnCase: null,
		showEconomyField: false,
		showBusinessAreaField: true,
		showCategoryCause: true,
		showAppliedClosedCases: false,
		showClosedCases: false,
		showPriorityCustomerCases: false,
		debitors: undefined,
		track: undefined,
		postalCodeText: '',
		postalCodes: undefined,
		departments: undefined,
		locations: undefined,
		damageCategories: undefined,
		damageCauses: undefined,
		priority: undefined,
		caseManagers: undefined,
		projectManagers: undefined,
		minDate: undefined,
		maxDate: undefined,
		filterTerms: '',
		favoriteColors: [],
	});

	const [searchTerm, setSearchTerm] = useDebouncedState(activeFilters.filterTerms, 50);

	const [activeUserFilter, setActiveUserFilter] = React.useState(activeFilters.name ?? '');
	const selectedUserFilter = React.useMemo(() => userContext?.user?.caseFilters.find(f => f.name === activeUserFilter), [activeUserFilter, userContext?.user?.caseFilters]);

	const [ selectedStars, setSelectedStars ] = React.useState<string[]>([]);

	React.useEffect(() => {
		setActiveFilters(currentFilters => ({
			...currentFilters,
			filterTerms: searchTerm,
		}));

		if (typeof selectedUserFilter === 'undefined') {
			return;
		}

		setActiveFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserFilter.name,
			view: selectedUserFilter.view ?? UserCaseFilterViewType.OWN,
			awaiting: selectedUserFilter.awaiting ?? null,
			damageServiceCompleted: selectedUserFilter.damageServiceCompleted ?? null,
			calledBack: selectedUserFilter.calledBack ?? null,
			machinesOnCase: selectedUserFilter.machinesOnCase ?? null,
			showEconomyField: selectedUserFilter.showEconomyField ?? true,
			showBusinessAreaField: selectedUserFilter.showEconomyField ?? true,
			showAppliedClosedCases: selectedUserFilter.showAppliedClosedCases ?? false,
			showClosedCases: selectedUserFilter.showClosedCases ?? false,
			debitors: selectedUserFilter.debitors ?? [],
			postalCodeText: selectedUserFilter.postalCodeText ?? '',
			postalCodes: selectedUserFilter.postalCodes ?? [],
			departments: selectedUserFilter.departments ?? [],
			locations: selectedUserFilter.locations ?? [],
			damageCategories: selectedUserFilter.damageCategories ?? [],
			damageCauses: selectedUserFilter.damageCauses ?? [],
			caseManagers: selectedUserFilter.caseManagers ?? [],
			projectManagers: selectedUserFilter.projectManagers ?? [],
			priority: selectedUserFilter.priority ?? [],
			track: selectedUserFilter.track ?? [2],
			filterTerms: searchTerm,
			favoriteColors: selectedStars,
		}));
	}, [searchTerm, selectedStars, selectedUserFilter, setActiveFilters]);

	const { data: adminDataRaw } = useQuery<GetWebCaseAdminData>(GET_CASE_ADMIN_DATA);

	const {
		data: cases,
		loading: loadingData,
		fetchMore,
	} = useQuery<GetWebCases, GetWebCasesVariables>(GET_CASES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			searchString: activeFilters.filterTerms,
			address: activeFilters.address,
			awaiting: activeFilters.awaiting,
			damageServiceCompleted: activeFilters.damageServiceCompleted,
			calledBack: activeFilters.calledBack,
			machinesOnCase: activeFilters.machinesOnCase,
			ownCases: activeFilters.view === UserCaseFilterViewType.OWN,
			readyForInvoice: activeFilters.view === UserCaseFilterViewType.INVOICE_READY,
			favoriteCases: activeFilters.view === UserCaseFilterViewType.FAVORITES,
			appliedClosed: activeFilters.showAppliedClosedCases,
			closedCases: activeFilters.showClosedCases,
			priorityCustomers: activeFilters.showPriorityCustomerCases,
			debitor: activeFilters.debitors,
			postalCode: activeFilters.postalCodes,
			track: activeFilters.track,
			department: activeFilters.departments,
			location: activeFilters.locations,
			damageCategory: activeFilters.damageCategories,
			damageCause: activeFilters.damageCauses,
			priority: activeFilters.priority,
			caseManager: activeFilters.caseManagers,
			projectManager: activeFilters.projectManagers,
			favoriteColors: favoriteColors.filter(c => selectedStars?.length > 0 ? selectedStars?.includes(c.value) : true).map(fc => fc.value),
			minDate: activeFilters.minDate ? formatDateForInput(new Date(activeFilters.minDate ?? '')) : undefined,
			maxDate: activeFilters.maxDate ? formatDateForInput(new Date(activeFilters.maxDate ?? '')) : undefined,
			includeEconomy: activeFilters.showEconomyField,
			offset: 0,
			limit: FETCH_LIMIT, //Limit contants
		},
	});

	const casesData = React.useMemo(() => cases?.cases ?? [], [cases]);

	const [filteredData, setFilteredData] = React.useState<GetWebCases['cases']>(casesData.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt)));

	React.useEffect(() => {
		setFilteredData(casesData.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt)));
	}, [casesData]);

	const activeFilteredSwitch = (activeView: UserCaseFilterViewType): GetWebCases['cases'] => {
		switch (activeView) {
			// case UserCaseFilterViewType.ALL:
			//     return filteredData;
			// case UserCaseFilterViewType.INVOICE_READY:
			//     return filteredData.filter(c => activeFilters.view === UserCaseFilterViewType.INVOICE_READY && c.billingReady === true);
			case UserCaseFilterViewType.NOT_PLANNED:
				return filteredData.filter(c => !c.drivingSlipSeries.some(dss => dss.drivingSlips.some(ds => ds.status === DrivingSlipStatus.PLANNED)));
			// case UserCaseFilterViewType.FAVORITES:
			//     return filteredData.filter(c => c.userFavorite?.active === true);
			default:
				return filteredData;
		}
	};

	const activeFilteredCases = activeFilteredSwitch(activeFilters.view);

	const casesTotalCountName = 'casesTotalCount';
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const totalCases = React.useMemo(() => (graphQLExtensionsData[casesTotalCountName] as number | undefined | null) ?? 0, [graphQLExtensionsData]);

	return (
		<div>
			<Header
				title="common.caseOverview"
				actions={
					<>
						{/* <Button
                            link
                            onClick={() => console.log('Vis alle sager på kort')}
                            className="text-sm"
                        >
                            <FontAwesomeIcon icon={faMapMarker} className="mr-2" /> Se alle sager på kort
                        </Button> */}

						<CasesExport
							searchTerm={searchTerm}
							activeFilters={activeFilters}
							showEconomy={activeFilters.showEconomyField}
							showBusinessArea={activeFilters.showBusinessAreaField}
							showCategoryCause={activeFilters.showCategoryCause}
							adminData={adminDataRaw}
						/>
					</>
				}
			/>
			<BoxContainer>
				<Box full loading={loadingData && casesData.length === 0}>
					<CaseOverviewFilter
						setFilterTerm={setSearchTerm}
						onFilter={setActiveFilters}
						activeFilters={activeFilters}
						setActiveUserFilter={setActiveUserFilter}
						activeUserFilter={activeUserFilter}
						adminDataRaw={adminDataRaw}
						selectedStars={selectedStars}
						setSelectedStars={setSelectedStars}
					/>
					<div className="text-blue flex justify-between text-sm">
						<GraphQLExtensionsElement
							name={casesTotalCountName}
							render={value => <TotalCount totalCount={value as number | undefined | null} loading={loadingData} quantityText={t('common.quantity')} entityText={t('case.cases')} />}
						/>

						{loadingData && casesData.length > 0 && (
							<p className="pr-2">
								<FontAwesomeIcon icon={faSpinner} className="text-blue mr-1 animate-spin" size="1x" />
								{t('case.gettingNewCases')}
							</p>
						)}
					</div>

					<CaseTable
						cases={activeFilteredCases}
						showBusinessAreaField={activeFilters.showBusinessAreaField}
						showEconomyField={activeFilters.showEconomyField}
						showCategoryCause={activeFilters.showCategoryCause}
						noDataFound={'caseOverview.noCasesFound'}
						projectManagersFilter={activeFilters.projectManagers ?? []}
						caseManagersFilter={activeFilters.caseManagers ?? []}
					/>
					{typeof cases !== 'undefined' && totalCases > casesData.length && (
						<Button
							fullWidth
							text="common.getMore"
							secondary
							loading={fetchMoreLoading}
							className="mt-2"
							onClick={async () => {
								setFetchMoreLoading(true);
								await fetchMore({
									variables: {
										offset: cases?.cases.length,
									},
								});
								setFetchMoreLoading(false);
							}}
						/>
					)}
				</Box>
			</BoxContainer>
		</div>
	);
};

export default CaseOverview;
