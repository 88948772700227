import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { CollectionItemType } from '@ssg/common/GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { GetWebCase_case_damage_contacts, GetCollectionItems } from '../../GraphQL';
import { DamageContactSchema } from '../../Schemas/DamageContactSchema';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetWebCase_case_damage_contacts;
	setContacts: React.Dispatch<React.SetStateAction<GetWebCase_case_damage_contacts[]>>;
	erase?: boolean;
	contactIndex: number | undefined;
}

const DamageContactModal: FC<Props> = ({ open, close, edit = false, data, setContacts, erase, contactIndex }) => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const { data: collectionItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			type: CollectionItemType.DAMAGE_CONTACTS_ROLE,
		},
	});

	const [contactPhonenumber, setContactPhoneNumber] = React.useState(formatPhoneNumberIntl(data?.phone ?? '').replaceAll(/(\s|-)+/g, ''));

	const [contactCountry, setContactCountry] = React.useState(envVar.defaultCountry ?? '');

	const contactPhoneNumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('phone', phonenumber, { shouldValidate: true });
		setContactPhoneNumber(phonenumber);
	};

	const { handleSubmit, control, setValue, errors, register, trigger } = useForm<GetWebCase_case_damage_contacts>({
		resolver: yupResolver(DamageContactSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			phone: data?.phone,
			role: data?.role,
		},
	});

	const roles = useMemo(
		() =>
			collectionItems?.collectionItems.map(
				(item): SelectOption => ({
					label: item.name,
					value: item.name,
				}),
			) ?? [],
		[collectionItems],
	);

	const onSubmit = (formData: GetWebCase_case_damage_contacts) => {
		if (edit && typeof contactIndex !== 'undefined') {
			setContacts(current => {
				return [...current.slice(0, contactIndex), formData, ...current.slice(contactIndex + 1)];
			});
		} else {
			setContacts(current => [...current, formData]);
		}

		close();
	};

	const setRoleValue = (role: string, onSelect: boolean) => {
		if (!role && onSelect) {
			return;
		}
		setValue('role', role, { shouldValidate: true });
	};

	return (
		<Modal
			title={erase ? t('case.damageContact.deleteContact') : edit ? t('case.damageContact.updateContact') : t('case.damageContact.addContact')}
			size={ModalSize.MEDIUM}
			visible={open}
			close={close}
			body={
				erase ? (
					<div>
						<FormFieldHeader title="case.damageContact.wantToDeleteContact" />
						<div className="flex flex-col">
							<div>
								{`
								${t('common.name')}: ${data?.name},
								${t('common.role')}: ${data?.role}
								${data?.phone ? `, ${t('common.phone')}: ${data?.phone}` : ''}
								${data?.email ?? `, ${t('common.email')}: ${data?.email}`}
								`}
							</div>

							<Button
								danger
								text="case.damageContact.deleteContact"
								onClick={async () => {
									setContacts(prevState => prevState.filter((_, i) => i !== contactIndex));
									close();
								}}
								className="text-blue mt-4"
							/>
						</div>
					</div>
				) : (
					<div>
						<form onSubmit={handleSubmit(onSubmit)} className="pb-3">
							<Input title="common.name" name="name" required innerRef={register} defaultValue={data?.name ?? ''} errorMessage={errors.name?.message} />

							<SearchableSelect
								key="rolelabel"
								title="common.role"
								required
								name="role"
								control={control}
								options={roles}
								searchFn={text => setRoleValue(text, false)}
								onSelect={value => setRoleValue(value, true)}
								onBlur={() => undefined}
								minInputLength={-1}
								isLoading={false}
								errorMessage={errors?.role?.message}
								initialSelection={{
									value: data?.role ?? '',
									label: data?.role ?? '',
								}}
							/>

							<InputPhone
								title="common.phone"
								name="phone"
								required
								innerRef={register}
								errorMessage={errors.phone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => contactPhoneNumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setContactCountry(countrycode ?? '')}
								defaultCountry={contactCountry}
								defaultPhoneNumber={contactPhonenumber}
							/>

							<Input title="common.email" name="email" innerRef={register} defaultValue={data?.email ?? ''} errorMessage={errors.email?.message} />

							<Button submit success text={edit ? 'case.damageContact.updateContact' : 'case.damageContact.addContact'} className="mt-2 pl-4" />
						</form>
					</div>
				)
			}
		/>
	);
};

export default DamageContactModal;
