import React from 'react';
import Header from '@ssg/common/Components/Header';

const Tasks: React.FC = (): React.ReactElement => {
	return (
		<div>
			<Header title="Opgaver">Customized header content here</Header>
			Opgaver
		</div>
	);
};

export default Tasks;
