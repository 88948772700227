import React from 'react';
import { useTranslation } from 'react-i18next';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import { filesToInput, fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { useHistory, useParams } from 'react-router-dom';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Button from '@ssg/common/Components/Button';
import Box from '../../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import CaseImageInput from './Inputs/CaseImageInput';
import { useCaseNo } from './useCaseNo';
import Loading from '@ssg/common/Components/Loading';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { faCheck, faFilePdf, faFileWord, faPlus, faSync, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import TextButton from '@ssg/common/Components/TextButton';
import FormFieldHeader from '../../../Components/Forms/FormFieldHeader';
import TextInput from './Inputs/TextInput';
import MoistureReportObservationsFrom from './Inputs/MoistureReportObservationsFrom';
import MoistureMeassurementForm from './Inputs/MoistureMeassurementForm';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import useCaseImages from './useCaseImages';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { EsdhFileFragmentFragment, FloorMoistureReportFormDataInput, MoistureReportObservationsInput, ReportFileInput, ReportFormatType, useCreateWebMoistureReportMutation, useGetCaseReportsAndDocumentsQuery } from '@ssg/common/GraphQL/indexV2';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface ReportError {
	key: keyof FloorMoistureReportFormDataInput;
	index: number;
}

interface Params {
	id: string;
}

const EMPTY_ROW = (): FloorMoistureReportFormDataInput => ({
	name: '',
	//contacts: '',
	//scope: '',
	//damageCause: '',
	observations: moistureReportObservationsInput,
	floorplanImage: tempFacadeImage,
	//damageCauseImages: [],
	moistureMeassurements: [],
	otherImages: [],
});

const tempFacadeImage: ReportFileInput = {
	fileName: '',
	description: '',
};

const moistureReportObservationsInput: MoistureReportObservationsInput = {
	recommendations: '',
	roomOrApartment: '',
	scope: '',
	ceiling: '',
	construction: '',
	floor: '',
	walls: '',
};

const WaterDamageReport: React.FC = () => {
	const { id: caseId } = useParams<Params>();
	const caseNo = useCaseNo(caseId);
	const { t } = useTranslation();
	const history = useHistory();
	const reportFormat = React.useRef<ReportFormatType>(ReportFormatType.Pdf);
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);

	const { images } = useCaseImages(caseNo ?? '');

	const { loading: caseReportsAndDocumentsLoading } = useGetCaseReportsAndDocumentsQuery({
		variables: {
			id: caseId,
		},
		skip: !caseId || caseId === '',
		onCompleted(data) {
			const formData = removeTypenameGeneric(data.case.caseReports?.moistureReportFormData);
			if (formData) {
				if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));
				if (formData.conclusion) setConclusion(formData.conclusion);
				if (formData.contacts) setContacts(formData.contacts);
				if (formData.damageCause) setDamageCause(formData.damageCause);
				if (formData.scope) setScope(formData.scope);

				if (formData.facadeImage) {
					const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], data.case.documents)[0];
					if (sanitizedFacadeImage) {
						setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, data.case.documents));
					}
				}

				if (formData.floorReport) {
					const floorsData = removeTypenameGeneric(formData.floorReport);
					const sanitizedFloorsData = floorsData.map(f => {
						return {
							...f,
							otherImages: checkFilesExist(f.otherImages ?? [], data.case.documents),
							floorplanImage: checkFilesExist(f.floorplanImage ? [f.floorplanImage] : [], data.case.documents)[0],
						};
					});
					if (sanitizedFloorsData) setFloors(sanitizedFloorsData);
				}

				if (formData.damageCauseImages && formData.damageCauseImages.length > 0) {
					const sanitizedDamageImages = checkFilesExist(formData.damageCauseImages, data.case.documents);

					const damageImages = sanitizedDamageImages.reduce((images: EsdhFileFragmentFragment[], otherImage) => {
						const file = tryGetFile(otherImage.fileName, data.case.documents);
						if (file) {
							images.push(file);
						}
						return images;
					}, []);

					if (damageImages) {
						setDamageCauseImages(damageImages);
					}
				}
			}
		},
	});

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${caseId}-water-${name}`, initialValue);

	// Form values
	const [facadeImage, setFacadeImage] = useLocalStorage<EsdhFileFragmentFragment | null>('facadeImage', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));
	const [conclusion, setConclusion] = useLocalStorage('conclusion', '');
	const [contacts, setContacts] = useLocalStorage('contacts', '');
	const [damageCause, setDamageCause] = useLocalStorage('damageCause', '');
	const [scope, setScope] = useLocalStorage('scope', '');
	//const [floorplanIndexedImage, setFloorplanIndexedImage] = React.useState<Map<number, EsdhFileFragmentFragment | null>>(new Map<number, null>());
	const [damageCauseImages, setDamageCauseImages] = useLocalStorage<EsdhFileFragmentFragment[]>('damageCauseImages', []);
	//const [otherIndexedImages, setOtherIndexedImages] = React.useState<Map<number, EsdhFileFragmentFragment[]>>(new Map<number, EsdhFileFragmentFragment[]>());
	const [activeTab, setActiveTab] = React.useState<number>(0);
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const [floors, setFloors] = useLocalStorage<FloorMoistureReportFormDataInput[]>('floorReport', [EMPTY_ROW()]);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const [damageCauseError, setDamageCauseError] = React.useState(false);
	const [scopeError, setScopeError] = React.useState(false);
	const [contactsError, setContactsError] = React.useState(false);

	// Setup mutation to create water damage report
	const [createMoistureReport, { loading: submitting }] = useCreateWebMoistureReportMutation();

	const handleChange = (idx: number, column: keyof FloorMoistureReportFormDataInput, value: any) =>
		setFloors(current => {
			let originalValue = value;
			if (column === 'floorplanImage') {
				//const flrPlanImages = floorplanIndexedImage;
				//flrPlanImages?.set(idx, value);
				//setFloorplanIndexedImage(flrPlanImages);
				originalValue = fileToInput(value as EsdhFileFragmentFragment);
			}

			if (column === 'otherImages') {
				// const otherImages = otherIndexedImages;
				// otherImages.set(idx, value as EsdhFileFragmentFragment[]);
				// setOtherIndexedImages(otherImages);
				const fileValue = value as EsdhFileFragmentFragment;
				const imageArrToggles = toggleArrayValue(current[idx].otherImages || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			return [
				...current.slice(0, idx),
				{
					...current[idx],
					[column]: originalValue,
				},
				...current.slice(idx + 1),
			];
		});

	function addRow() {
		setFloors(current => [...current, EMPTY_ROW()]);
		setActiveTab(floors.length);
	}

	function removeRow(idx: number) {
		setFloors(current => current.filter((_, i) => i !== idx));
		// setFloorplanIndexedImage(current => {
		//     current.delete(idx);
		//     return current;
		// });
		// setOtherIndexedImages(current => {
		//     current.delete(idx);
		//     return current;
		// });
		setActiveTab(0);
	}

	//React.useEffect(() => console.log(floorplanIndexedImage),[floorplanIndexedImage]);

	const getDescription = (desciptionMap: ReportFileInput[], fileName: string) => {
		return desciptionMap.find(dm => dm.fileName === fileName);
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;

		setSubmitted(true);
		const errors = errorCheck(floors);

		setReportErrors(errors);
		setFacadeImageError(facadeImage == null);
		setDamageCauseError(damageCause.length < 1);
		setScopeError(scope.length < 1);
		setContactsError(contacts.length < 1);

		if (floors.length === 0 || facadeImage == null || errors.length > 0 || damageCause.length < 1 || scope.length < 1 || contacts.length < 1) {
			return;
		}

		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisDamageCauseImages = damageCauseImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			await createMoistureReport({
				variables: {
					caseId,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						conclusion,
						facadeImage: fileToInput(thisFacadeImage),
						contacts,
						damageCause,
						scope,
						damageCauseImages: filesToInput(thisDamageCauseImages),
						floorMoistureReportFormDataInput: theseFloors,
					},
					reportFormat: reportFormat.current,
					onlySave: false,
				},
			});

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}
	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate,
		conclusion,
		facadeImage,
		contacts,
		damageCause,
		scope,
		damageCauseImages,
		floors]);

	async function saveReport() {
		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage?.name) ?? facadeImage;
		const thisDamageCauseImages = damageCauseImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('SAVE');
			await createMoistureReport({
				variables: {
					caseId,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						conclusion,
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						contacts,
						damageCause,
						scope,
						damageCauseImages: filesToInput(thisDamageCauseImages),
						floorMoistureReportFormDataInput: theseFloors,
					},
					reportFormat: reportFormat.current,
					onlySave: true,
				},
			});
			setSaved(true);
		} catch (e) {
			console.error(e);
		}
	}
	const resetForm = () => {
		setConclusion('');
		setFacadeImage(null);
		setConclusion('');
		setContacts('');
		setDamageCause('');
		setScope('');
		setDamageCauseImages([]);
		setFloors([EMPTY_ROW()]);
	};

	const errorCheck = (flrs: FloorMoistureReportFormDataInput[]) => {
		const errors: ReportError[] = [];
		flrs.forEach((f, i) => {
			if (f.name.length < 1) {
				errors.push({ index: i, key: 'name' });
			}
			// if(f.damageCause.length < 1) {
			//     errors.push({index: i, key: 'damageCause'});
			// }
			// if(f.scope.length < 1) {
			//     errors.push({index: i, key: 'scope'});
			// }
			// if(f.contacts.length < 1) {
			//     errors.push({index: i, key: 'contacts'});
			// }
			if (!f.floorplanImage || f.floorplanImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'floorplanImage' });
			}
		});

		return errors;
	};

	const throwErrorMessage = (index: number, key: keyof FloorMoistureReportFormDataInput): string | undefined => {
		const isError = reportErrors.filter(re => re.index === index && re.key === key).length > 0;
		if (isError) return 'error.required';
		return;
	};

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);
			setDamageCauseError(damageCause.length < 1);
			setScopeError(scope.length < 1);
			setContactsError(contacts.length < 1);
			const errors = errorCheck(floors);
			setReportErrors(errors);
		}
	}, [contacts.length, damageCause.length, facadeImage, floors, scope.length, submitted]);

	if (caseReportsAndDocumentsLoading) return <Loading />;

	return (
		<div>
			<Header title="reports.reportTypes.water" backButton />

			<div className="space-y-8 px-10 pb-10 text-sm">
				<ReportStaticInfo caseId={caseId} />

				<Box full icon={faSync} iconText="reports.reset" title="" onClick={() => resetForm()}>
					{caseNo != null && (
						<>
							<CaseImageInput
								caseNo={caseNo}
								headerTitle="reports.facadeImage"
								title={t('reports.uploadFacadeImage')}
								noSelectedImageText={t('reports.noFacadeImage')}
								selectedImagesNames={facadeImage !== null ? [facadeImage.name] : []}
								onImageSelect={img => setFacadeImage(img)}
							/>

							{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
						</>
					)}

					<Datepicker title="reports.inspectionDate" value={inspectionDate} onChange={e => setInspectionDate(e.target.value)} name="reports.inspectionDate" />

					<TextAreaInput
						label="reports.damageCause"
						placeholder="reports.damageCausePlaceholder"
						value={damageCause}
						onChange={setDamageCause}
						required={true}
						errorMessage={damageCauseError ? 'common.required' : undefined}
					/>

					<TextAreaInput
						label={t('reports.scope')}
						placeholder={t('reports.scopePlaceholder')}
						value={scope}
						onChange={setScope}
						errorMessage={scopeError ? 'common.required' : undefined}
						required
					/>

					<TextAreaInput
						label="reports.contacts"
						placeholder="reports.contactsPlaceholder"
						value={contacts}
						onChange={setContacts}
						errorMessage={contactsError ? 'common.required' : undefined}
						required
					/>

					{caseNo != null && (
						<CaseImageInput
							headerTitle="reports.damageCauseImages"
							title="reports.selectDamageCauseImages"
							noSelectedImageText="reports.noDamageCauseImages"
							caseNo={caseNo}
							onImageSelect={image => setDamageCauseImages(current => toggleArrayValue(current, image, img => img.url))}
							selectedImagesNames={damageCauseImages.map(img => img.name)}
						/>
					)}

					<div className="flex flex-wrap">
						<div className="w-full">
							<ul className="mb-0 flex list-none flex-row flex-wrap pt-3 pb-4" role="tablist">
								{floors.map((floor, idx) => (
									<li className="-mb-px mr-2 flex-auto text-center last:mr-0">
										{idx === 0}{' '}
										<a
											className={
												'block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg ' +
												(activeTab === idx ? 'bg-blue-calm text-white' : 'text-blue-calm bg-white')
											}
											onClick={e => {
												e.preventDefault();
												setActiveTab(idx);
											}}
											data-toggle="tab"
											href={'#link' + idx}
											role="tablist"
										>
											{typeof reportErrors.find(re => re.index === idx) !== 'undefined' && <FontAwesomeIcon icon={faExclamationTriangle} className="text-red mr-1" size="lg" />}{' '}
											{t('reports.floor')} - {floor.name}
										</a>
										{idx > 0 && <TextButton text="reports.removeFloor" className="mx-auto" icon={faTimes} iconClassName="text-red" onClick={() => removeRow(idx)} />}
									</li>
								))}

								<li className="-mb-px mr-2 flex-auto text-center last:mr-0">
									<Button primary icon={faPlus} onClick={addRow}>
										{t('reports.addFloor')}
									</Button>
								</li>
							</ul>
							<div className="relative mb-6 flex w-full min-w-0 flex-col break-words rounded bg-white shadow-lg">
								<div className="flex-auto px-4 py-5">
									<div className="tab-content tab-space">
										{floors.map((floor, idx) => (
											<div className={activeTab === idx ? 'block' : 'hidden'} id={'#link' + idx}>
												<FormFieldHeader title={t('reports.moistureReportObservations.title')} />

												<TextInput
													label="common.name"
													value={floor.name}
													onChange={value => handleChange(idx, 'name', value)}
													required={true}
													errorMessage={throwErrorMessage(idx, 'name')}
												/>
												<MoistureReportObservationsFrom
													observations={floor.observations}
													onChange={e => handleChange(idx, 'observations', e)}
												//onChange={setObservations}
												/>

												<MoistureMeassurementForm
													meassurements={floor.moistureMeassurements || []}
													onChange={e => handleChange(idx, 'moistureMeassurements', e)}
												//onChange={setMoistureMeassurements}
												/>

												{caseNo != null && (
													<>
														<CaseImageInput
															caseNo={caseNo}
															headerTitle="reports.floorPlanImage"
															title="reports.uploadFloorplanImage"
															key={idx + '_noOfFloorPlanImage'}
															noSelectedImageText="reports.noFloorplanImage"
															onImageSelect={e => handleChange(idx, 'floorplanImage', e)}
															//selectedImages={images.filter(img => img.name === floor.floorplanImage.fileName)}
															selectedImagesNames={[floor.floorplanImage?.fileName ?? '']}
														/>

														{typeof throwErrorMessage(idx, 'floorplanImage') !== 'undefined' && <FormErrorText text="reports.error.floorplanImage" />}
													</>
												)}

												{caseNo != null && (
													<CaseImageInput
														headerTitle="reports.extraImages"
														title="reports.uploadExtraImages"
														noSelectedImageText={t('reports.noExtraImages')}
														caseNo={caseNo}
														onImageSelect={image => handleChange(idx, 'otherImages', image)}
														//selectedImages={images.filter(img => (floor.otherImages || []).map(oi => oi.fileName).includes(img.name))}
														selectedImagesNames={(floor.otherImages ?? []).map(oi => oi.fileName)}
													/>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<TextAreaInput label={t('reports.conclusion')} placeholder={t('reports.conclusionPlaceholder')} value={conclusion} onChange={setConclusion} />
				</Box>

				<div className="ml-4 my-2 mr-6 flex flex-row space-x-2">
					<Button
						icon={faFilePdf}
						onClick={() => submitReport(ReportFormatType.Pdf)}
						disabled={submitting}
						loading={submitting && reportFormat.current === ReportFormatType.Pdf && loadingType === 'CREATE'}
						success
						text="reports.createPdfReport"
					/>

					<Button
						icon={faFileWord}
						onClick={() => submitReport(ReportFormatType.Word)}
						disabled={submitting}
						loading={submitting && reportFormat.current === ReportFormatType.Word && loadingType === 'CREATE'}
						success
						text="reports.createWordReport"
					/>

					{saveButtonReportsFlag &&
						<Button disabled={submitting} loading={submitting && loadingType === 'SAVE'} primary onClick={() => saveReport()} text={saved ? '' : 'reports.saveReport'} icon={saved ? faCheck : undefined} />
					}
				</div>
			</div>
		</div>
	);
};

export default WaterDamageReport;
