import { createContext, useState } from 'react';

export declare type GraphQLExtensions = Record<string, unknown>;

export const GraphQLExtensionsData = createContext<GraphQLExtensions>({});
export const GraphQLExtensionsApi = createContext<React.Dispatch<React.SetStateAction<GraphQLExtensions>>>(() => undefined);

export const GraphQLExtensionsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const [state, setState] = useState<GraphQLExtensions>({});

	return (
		<GraphQLExtensionsData.Provider value={state}>
			<GraphQLExtensionsApi.Provider value={setState}>{children}</GraphQLExtensionsApi.Provider>
		</GraphQLExtensionsData.Provider>
	);
};
