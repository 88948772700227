import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetWebCase, GetWebCaseVariables } from '../../../GraphQL';

const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');

export function useCaseNo(caseId: string): string | undefined {
	const { data } = useQuery<GetWebCase, GetWebCaseVariables>(GET_CASE, {
		variables: {
			id: caseId,
		},
		fetchPolicy: 'cache-first',
		skip: !caseId, // Handles undefined & empty string scenarios
	});

	return data?.case.erpNo;
}
