import React from 'react';
import { formatDateForInputEU } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useTranslation } from 'react-i18next';
import { GetMachineHistory_machineHistory, GetWebMachineLocations_machineLocations, MachineConsumptionType } from '../../GraphQL';

interface Props {
	historyItem: GetMachineHistory_machineHistory;
	machineLocation: GetWebMachineLocations_machineLocations | undefined;
	consumption: number;
}

const MachineLogItem: React.FC<Props> = ({ historyItem, machineLocation, consumption }) => {
	const { t } = useTranslation();

	return (
		<div>
			{machineLocation && (
				<div>
					<strong>
						{t('machines.onLocationBy')} {formatDateForInputEU(new Date(historyItem.dateStart))} -{' '}
						{historyItem.dateEnd === '0001-01-01' ? t('machines.noEndDate') : formatDateForInputEU(new Date(historyItem.dateEnd))} {historyItem.userName}
					</strong>
					<div className="font-semibold">{`${machineLocation.name.length > 0 ? machineLocation.name : t('common.notSpecified')}`}</div>
					{historyItem.eRPLocationReference === 'INDISP' && historyItem.unavailableReason.length > 0 && <p>{t('machines.unavailableReason')}: <span className="font-semibold">{historyItem.unavailableReason}</span></p>}

					{machineLocation.address && (
						<div>
							<div>{machineLocation.address}</div>
							<div>
								{machineLocation.city} {machineLocation.postCode}
							</div>
							<div>
								{t('common.contact')}: <span className="font-semibold">{machineLocation.contact.length > 0 ? machineLocation.contact : t('common.notSpecified')}</span>
							</div>
						</div>
					)}
				</div>
			)}
			{(historyItem.eRPCaseReference || (historyItem.eRPCaseReference && historyItem.eRPCaseReference !== '')) && (
				<div>
					<strong>
						{t('machines.onCaseBy')} {formatDateForInputEU(new Date(historyItem.dateStart))} -{' '}
						{historyItem.dateEnd === '0001-01-01' ? t('machines.noEndDate') : formatDateForInputEU(new Date(historyItem.dateEnd))} {historyItem.userName}
					</strong>
					<p>
						{t('case.caseNo')}
						<span className="font-semibold">{historyItem.eRPCaseReference}</span>
					</p>
					<div>
						{t('machines.hibernation')}: <span className="font-semibold">{historyItem.hibernate ? t('common.yes') : t('common.no')}</span>
					</div>
				</div>
			)}

			<div>
				{t('machines.consumption')}:{' '}
				<span className="font-semibold">
					{consumption} {historyItem.consumptionType === MachineConsumptionType.NONE ? `(${t('common.missingUnit')})` : historyItem.consumptionType}
				</span>
			</div>
		</div>
	);
};

export default MachineLogItem;
