import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const CatalogCraftsmanSchema = yup.object().shape({
	customer: yup.string().required(),
	type: yup.string().required(),
	priority: yup.string().required(),
	contactName: yup.string().required(),
	email: yup.string().email(),
});
