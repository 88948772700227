import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	GetUserCalendars,
	GetUserCalendarsVariables,
	GetUserCalendars_userCalendars,
	DrivingSlipStatus,
	GetCarsWithCalendars,
	GetCarCalendars,
	GetCarCalendarsVariables,
	GetCarCalendars_carCalendars,
	GetCaseAdminDataPlanner,
	GetDrivingSlipsPlanner,
	GetDrivingSlipsPlannerVariables,
} from '../../GraphQL';
import { DateTime } from 'luxon';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Loading from '@ssg/common/Components/Loading';
import DraggableDrivingSlip, { MergedDrivingSlip } from './DraggableDrivingSlip';
import DrivingSlipFilters from './DrivingSlipFilters';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import CalendarEntity from './CalendarEntity';
import SanitizerFilters from './SanitizerFilters';
import Checkbox from '@ssg/common/Components/Checkbox';
import classNames from 'classnames';
import CarFilters from './CarFilters';
import UserContext from '../../UserContext';
import Scheduler from './Scheduler';
import TextButton from '@ssg/common/Components/TextButton';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';

const GET_DRIVING_SLIPS_PLANNER = loader('src/GraphQL/DrivingSlips/GetDrivingSlipsPlanner.gql');
const GET_CARS_WITH_CALENDARS = loader('src/GraphQL/Planner/GetCarsWithCalendars.gql');
const GET_USER_CALENDARS = loader('src/GraphQL/Planner/GetUserCalendars.gql');
const GET_CAR_CALENDARS = loader('src/GraphQL/Planner/GetCarCalendars.gql');
const GET_CASE_ADMIN_DATA_PLANNER = loader('src/GraphQL/Cases/GetCaseAdminDataPlanner.gql');

export interface DrivingSlipsFilter {
	name?: string | null;
	locations: string[];
	departments: string[];
	debitors: string[];
	damageCauses: string[];
	damageCategories: string[];
	postalCodeText: string;
	postalCodes: string[];
	deadline: string | undefined;
	createdAt: string | undefined;
	projectManagers: string[];
	erpNos: string[];
}

export interface SanitizersFilter {
	name: string;
	locations: string[];
	departments: string[];
	jobFunctions: string[];
	sanitizers: string[];
}

export interface CarsFilter {
	name: string;
	locations: string[];
	cars: string[];
}

enum SortingOptions {
	DATE_PRIORITY = 0,
	PRIORITY_DATE = 1,
}

const Planner: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const [drivingSlipsSearchTerm, setdrivingSlipsSearchTerm] = useDebouncedState('', 100);

	const [selectedUsersId, setSelectedUsersId] = useStorageState<string[]>(window.sessionStorage, 'selectedUsersId', []);
	const [selectedCarsId, setSelectedCarsId] = useStorageState<string[]>(window.sessionStorage, 'selectedCarsId', []);
	const [bgOnIds, setBgOnIds] = useStorageState<string[]>(window.sessionStorage, 'bgOnIds', []);
	const [carView, setCarView] = useStorageState(window.sessionStorage, 'carView', false);
	const [showMiddleFilters, setShowMiddleFilters] = useStorageState(window.sessionStorage, 'showMiddleFilters', false);
	const [showDSFilters, setShowDSFilters] = useStorageState(window.sessionStorage, 'showDSFilters', false);

	const [sanitizerSearchTerm, setSanitizerSearchTerm] = useDebouncedState('', 100);
	const [allSanitizersActive, setAllSanitizersActive] = React.useState<boolean>(false);

	const [carSearchTerm, setCarSearchTerm] = useDebouncedState('', 100);
	const [allCarsActive, setAllCarsActive] = React.useState<boolean>(false);

	const [localStartTime, setLocalStartTime] = useStorageState<string>(window.sessionStorage, 'localStartTime', DateTime.now().toISO());
	const [localEndTime, setLocalEndTime] = useStorageState<string>(window.sessionStorage, 'localEndTime', localStartTime);
	const [startDate, setStartDate] = React.useState(DateTime.fromISO(localStartTime));
	const [endDate, setEndDate] = React.useState(DateTime.fromISO(localEndTime));
	const [selectedCalendars, setSelectedCalendars] = React.useState<GetUserCalendars_userCalendars[]>([]);
	const [selectedCars, setSelectedCars] = React.useState<GetCarCalendars_carCalendars[]>([]);
	const [sortingOption, setSortingOption] = useStorageState<SortingOptions>(window.sessionStorage, 'sortingOption', SortingOptions.DATE_PRIORITY);

	React.useEffect(() => setLocalStartTime(startDate.toISO()), [startDate, setLocalStartTime]);
	React.useEffect(() => setLocalEndTime(endDate.toISO()), [endDate, setLocalEndTime]);

	const bgColors: string[] = ['bg-cyan-calm', 'bg-red-calm', 'bg-blue-calm', 'bg-green-calm', 'bg-blue-lightcalm', 'bg-orange-calm', 'bg-purple-calm'];

	const handleSorting = (e: string) => {
		const selected = parseInt(e);
		switch (selected) {
			case 0:
				setSortingOption(SortingOptions.DATE_PRIORITY);
				break;
			case 1:
				setSortingOption(SortingOptions.PRIORITY_DATE);
				break;
			default:
				break;
		}
	};

	// #region Driving slip filters ---------------------------
	const [activeUserDrivingSlipFilter, setActiveUserDrivingSlipFilter] = useStorageState<string>(window.sessionStorage, 'activeDrivingSlipsFilters', '');

	const selectedUserDrivingSlipFilter = React.useMemo(
		() => userContext?.user?.drivingSlipFilters.find(f => f.name === activeUserDrivingSlipFilter),
		[activeUserDrivingSlipFilter, userContext?.user?.drivingSlipFilters],
	);

	const [activeDrivingSlipsFilters, setActiveDrivingSlipsFilters, clearStorageValue] = useStorageState<DrivingSlipsFilter>(window.sessionStorage, 'activeDrivingSlipsFilters', {
		name: null,
		locations: [],
		departments: [],
		debitors: [],
		damageCauses: [],
		damageCategories: [],
		postalCodeText: '',
		postalCodes: [],
		deadline: undefined,
		createdAt: undefined,
		projectManagers: [],
		erpNos: [],
	});

	React.useEffect(() => {
		if (typeof selectedUserDrivingSlipFilter === 'undefined') {
			return;
		}

		setActiveDrivingSlipsFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserDrivingSlipFilter.name,
			debitors: selectedUserDrivingSlipFilter.debitors ?? [],
			postalCodeText: selectedUserDrivingSlipFilter.postalCodeText ?? '',
			postalCodes: selectedUserDrivingSlipFilter.postalCodes ?? [],
			departments: selectedUserDrivingSlipFilter.departments ?? [],
			locations: selectedUserDrivingSlipFilter.locations ?? [],
			damageCategories: selectedUserDrivingSlipFilter.damageCategories ?? [],
			damageCauses: selectedUserDrivingSlipFilter.damageCauses ?? [],
			erpNos: selectedUserDrivingSlipFilter.erpNos ?? [],
			projectManagers: selectedUserDrivingSlipFilter.projectManagers ?? [],
		}));
	}, [selectedUserDrivingSlipFilter, setActiveDrivingSlipsFilters]);

	const { data: adminDataRaw, loading: adminDataLoading } = useQuery<GetCaseAdminDataPlanner>(GET_CASE_ADMIN_DATA_PLANNER, {
		context: { debatch: true },
	});
	const adminData = React.useMemo(
		() =>
			adminDataRaw ?? {
				locations: [],
				departments: [],
				damageCategories: [],
				damageCauses: [],
				usersWithCalendars: [],
				drivingSlipCategories: [],
			},
		[adminDataRaw],
	);

	const {
		data,
		loading: drivingSlipsLoading,
		error,
		refetch: drivingSlipsRefetch,
	} = useQuery<GetDrivingSlipsPlanner, GetDrivingSlipsPlannerVariables>(GET_DRIVING_SLIPS_PLANNER, {
		context: { debatch: true },
		fetchPolicy: 'no-cache',
		variables: {
			status: DrivingSlipStatus.UNPLANNED,
		},
	});

	const drivingSlipsData = React.useMemo(() => {
		return (data?.drivingSlips ?? []).reduce((arr, ds) => {
			// Bundle driving slips in same series
			const { id, start, end, comment, driver, ...drivingSlip } = ds;
			let existing = arr.find(eds => eds.series === drivingSlip.series);

			if (typeof existing === 'undefined') {
				const index =
					arr.push({
						...drivingSlip,
						driver,
						department: {
							...drivingSlip.department,
							departmentNumberStr: String(drivingSlip.department.departmentNumber),
						},
						merged: [],
						startDate: dateToDateOnlyString(new Date(start)),
						startDateTime: start,
					}) - 1;
				existing = arr[index];
			}

			existing.merged.push({ id, start, end, comment, driver });
			existing.startDate = dateToDateOnlyString(new Date(start));
			existing.startDateTime = start;

			return arr;
		}, [] as Array<MergedDrivingSlip & { department: { departmentNumberStr: string } }>);
	}, [data?.drivingSlips]);

	const activeFilteredDrivingSlips = React.useMemo(
		() =>
			drivingSlipsData
				.filter(d => activeDrivingSlipsFilters.locations.length === 0 || activeDrivingSlipsFilters.locations.includes(d.location.name ?? 'UNKNOWN'))
				.filter(d => activeDrivingSlipsFilters.departments.length === 0 || activeDrivingSlipsFilters.departments.includes(d.department.name ?? 'UNKNOWN'))
				.filter(d => activeDrivingSlipsFilters.debitors.length === 0 || activeDrivingSlipsFilters.debitors.flatMap(d => d).includes(d.case.debitor.company))
				.filter(d => activeDrivingSlipsFilters.damageCauses.length === 0 || activeDrivingSlipsFilters.damageCauses.includes(d.case.damage.cause.id))
				.filter(d => activeDrivingSlipsFilters.damageCategories.length === 0 || activeDrivingSlipsFilters.damageCategories?.includes(d.case.damage.category.id))
				.filter(d => activeDrivingSlipsFilters.postalCodes.length === 0 || activeDrivingSlipsFilters.postalCodes.includes(d.case.damage.contact.address.postalCode))
				.filter(
					d =>
						typeof activeDrivingSlipsFilters.deadline === 'undefined' ||
						d.merged.some(({ start }) => typeof activeDrivingSlipsFilters.deadline !== 'undefined' && activeDrivingSlipsFilters.deadline.includes(formatDateForInput(new Date(start)))),
				)
				.filter(d => typeof activeDrivingSlipsFilters.createdAt === 'undefined' || activeDrivingSlipsFilters.createdAt.includes(formatDateForInput(new Date(d.createdAt))))
				.filter(d => activeDrivingSlipsFilters.projectManagers.length === 0 || activeDrivingSlipsFilters.projectManagers.flatMap(d => d).includes(d.case.projectManager?.id ?? 'UNKNOWN'))
				.filter(d => activeDrivingSlipsFilters.erpNos.length === 0 || activeDrivingSlipsFilters.erpNos.includes(d.case.erpNo)),
		[
			drivingSlipsData,
			activeDrivingSlipsFilters.locations,
			activeDrivingSlipsFilters.departments,
			activeDrivingSlipsFilters.debitors,
			activeDrivingSlipsFilters.damageCauses,
			activeDrivingSlipsFilters.damageCategories,
			activeDrivingSlipsFilters.postalCodes,
			activeDrivingSlipsFilters.deadline,
			activeDrivingSlipsFilters.createdAt,
			activeDrivingSlipsFilters.projectManagers,
			activeDrivingSlipsFilters.erpNos,
		],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(activeFilteredDrivingSlips, {
				shouldSort: true,
				threshold: 0.1,
				keys: [
					'case.debitor.company',
					'address.road',
					'address.houseNumber',
					'address.floor',
					'address.city',
					'address.postalCode',
					'department.name',
					'department.departmentNumberStr',
					'location.name',
					'case.damage.contact.name',
					'case.damage.category.name',
					'case.damage.cause.name',
					'driver.name',
					'driver.email',
					'driver.phone',
					'case.erpNo',
					'case.projectManager.name',
				],
			}),
		[activeFilteredDrivingSlips],
	);

	const filteredDrivingSlips = React.useMemo(() => {
		return drivingSlipsSearchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(drivingSlipsSearchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [
								{ name: searchToken },
								{ 'case.debitor.company': searchToken },
								{ 'address.road': searchToken },
								{ 'address.houseNumber': searchToken },
								{ 'address.floor': searchToken },
								{ 'address.city': searchToken },
								{ 'address.postalCode': searchToken },
								{ 'department.name': searchToken },
								{
									'department.departmentNumberStr': searchToken,
								},
								{ 'location.name': searchToken },
								{ 'case.damage.contact.name': searchToken },
								{ 'case.damage.category.name': searchToken },
								{ 'case.damage.cause.name': searchToken },
								{ 'driver.name': searchToken },
								{ 'driver.email': searchToken },
								{ 'driver.phone': searchToken },
								{ 'case.erpNo': searchToken },
								{ 'case.projectManager.name': searchToken },
							];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: activeFilteredDrivingSlips;
	}, [activeFilteredDrivingSlips, drivingSlipsSearchTerm, fuse]);

	// Sort drivingslips based on sorting dropdown
	const sortedDrivingSlips = React.useMemo(() => {
		let result = filteredDrivingSlips;

		const drivingSlipsCopy = result.slice();

		// Sort the drivingslips by selected sorting option
		switch (sortingOption) {
			case SortingOptions.DATE_PRIORITY:
				drivingSlipsCopy.sort((a, b) => {
					const aDate = new Date(a.startDateTime).getTime();
					const bDate = new Date(b.startDateTime).getTime();

					return aDate - bDate;
				});
				break;

			case SortingOptions.PRIORITY_DATE:
				drivingSlipsCopy.sort((a, b) => (a === b ? 0 : a.urgent ? -1 : 1));
				break;
		}

		result = drivingSlipsCopy;

		return result;
	}, [filteredDrivingSlips, sortingOption]);
	// #endregion Driving slip filters ---------------------------

	// #region Sanitizer filters ------------------------------
	//const [activeUserSanitizerFilter, setActiveUserSanitizerFilter] = useStorageState<string>(window.sessionStorage, 'activeUserSanitizerFilters', '');

	const [activeSanitizersFilters, setActiveSanitizersFilters] = useStorageState<SanitizersFilter>(window.sessionStorage, 'activeUserSanitizerFilters', {
		name: '',
		locations: [],
		departments: [],
		jobFunctions: [],
		sanitizers: [],
	});

	const [openedDates, toggleDate] = useArrayToggler<string>([]);

	const selectedUserSanitizerFilter = React.useMemo(
		() => userContext?.user?.sanitizerFilters.find(f => f.name === activeSanitizersFilters.name),
		[activeSanitizersFilters.name, userContext?.user?.sanitizerFilters],
	);

	React.useEffect(() => {
		if (typeof selectedUserSanitizerFilter === 'undefined') {
			return;
		}

		setActiveSanitizersFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserSanitizerFilter.name,
			departments: selectedUserSanitizerFilter.departments ?? [],
			locations: selectedUserSanitizerFilter.locations ?? [],
			jobFunctions: selectedUserSanitizerFilter.jobFunctions ?? [],
			sanitizers: selectedUserSanitizerFilter.sanitizers ?? [],
		}));
	}, [selectedUserSanitizerFilter, setActiveSanitizersFilters]);

	const filteredSanitizers = React.useMemo(() => {
		let sanitizers = adminData.usersWithCalendars;
		const fuse = new Fuse(sanitizers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name', 'email'],
		});

		if (sanitizerSearchTerm.length > 0) {
			sanitizers = fuse.search(sanitizerSearchTerm).map(v => v.item);
		}

		return sanitizers
			.filter(
				s =>
					typeof activeSanitizersFilters.locations === 'undefined' || activeSanitizersFilters.locations.length === 0 || activeSanitizersFilters.locations.includes(s.department ?? 'UNKNOWN'),
			)
			.filter(
				s =>
					typeof activeSanitizersFilters.departments === 'undefined' || activeSanitizersFilters.departments.length === 0 || activeSanitizersFilters.departments.includes(s.area ?? 'UNKNOWN'),
			)
			.filter(
				s =>
					typeof activeSanitizersFilters.jobFunctions === 'undefined' ||
					activeSanitizersFilters.jobFunctions.length === 0 ||
					activeSanitizersFilters.jobFunctions.includes(s.jobFunction ?? 'UNKNOWN'),
			)
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [adminData.usersWithCalendars, activeSanitizersFilters.locations, activeSanitizersFilters.departments, activeSanitizersFilters.jobFunctions, sanitizerSearchTerm]);
	// #endregion Sanitizer filters ------------------------------

	// #region Car filters ------------------------------------
	const { data: carsData, loading: carsLoading } = useQuery<GetCarsWithCalendars>(GET_CARS_WITH_CALENDARS, {
		skip: !carView,
	});

	const [activeCarFilters, setActiveCarFilters] = useStorageState<CarsFilter>(window.sessionStorage, 'activeUserCarFilters', {
		name: '',
		locations: [],
		cars: [],
	});

	const selectedUserCarFilter = React.useMemo(() => userContext?.user?.carFilters.find(f => f.name === activeCarFilters.name), [activeCarFilters.name, userContext?.user?.carFilters]);

	React.useEffect(() => {
		if (typeof selectedUserSanitizerFilter !== 'undefined' && selectedUserSanitizerFilter?.name !== '') {
			setBgOnIds(current => [...current, ...(selectedUserSanitizerFilter?.sanitizers ?? [])].filter((id, index, arr) => arr.indexOf(id) === index));

			setSelectedUsersId(current => [...current, ...(selectedUserSanitizerFilter?.sanitizers ?? [])].filter((id, index, arr) => arr.indexOf(id) === index));
		}

		if (typeof selectedUserCarFilter !== 'undefined' && selectedUserCarFilter?.name !== '') {
			setBgOnIds(current => [...current, ...(selectedUserCarFilter?.cars ?? [])].filter((id, index, arr) => arr.indexOf(id) === index));

			setSelectedCarsId(current => [...current, ...(selectedUserCarFilter?.cars ?? [])].filter((id, index, arr) => arr.indexOf(id) === index));
		}
	}, [selectedUserCarFilter, selectedUserSanitizerFilter, setBgOnIds, setSelectedCarsId, setSelectedUsersId]);

	React.useEffect(() => {
		setBgOnIds(current => [...current, ...(selectedUsersId ?? []), ...(selectedCarsId ?? [])].filter((id, index, arr) => arr.indexOf(id) === index));
	}, [selectedCarsId, selectedUsersId, setBgOnIds]);

	React.useEffect(() => {
		if (typeof selectedUserCarFilter === 'undefined') {
			return;
		}

		setActiveCarFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserCarFilter.name,
			locations: selectedUserCarFilter.locations ?? [],
		}));
	}, [selectedUserCarFilter, setActiveCarFilters]);

	const filteredCars = React.useMemo(() => {
		let cars = carsData?.carsWithCalendars ?? [];
		const fuse = new Fuse(cars, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name'],
		});

		if (carSearchTerm.length > 0) {
			cars = fuse.search(carSearchTerm).map(v => v.item);
		}

		return cars
			.filter(c => typeof activeCarFilters.locations === 'undefined' || activeCarFilters.locations.length === 0 || activeCarFilters.locations.includes(c.department ?? 'UNKNOWN'))
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [carsData?.carsWithCalendars, carSearchTerm, activeCarFilters.locations]);

	const carsLocationsList = React.useMemo(
		() =>
			Array.from(
				new Set(
					carsData?.carsWithCalendars
						.slice()
						.map(c => c.department)
						.filter((a): a is string => a !== null),
				),
			),
		[carsData?.carsWithCalendars],
	);
	// #endregion Car filters ------------------------------------

	const [departmentsList, locationsList, jobFuncionsList] = React.useMemo(
		() => [
			Array.from(
				new Set(
					adminData.usersWithCalendars
						.slice()
						.map(u => u.area)
						.filter((a): a is string => a !== null),
				),
			),
			Array.from(
				new Set(
					adminData.usersWithCalendars
						.slice()
						.map(u => u.department)
						.filter((a): a is string => a !== null),
				),
			),
			Array.from(
				new Set(
					adminData.usersWithCalendars
						.slice()
						.map(u => u.jobFunction)
						.filter((a): a is string => a !== null),
				),
			),
		],
		[adminData?.usersWithCalendars],
	);

	const {
		data: userCalendarsData,
		loading: userCalendarsLoading,
		refetch: userCalendarsRefetch,
	} = useQuery<GetUserCalendars, GetUserCalendarsVariables>(GET_USER_CALENDARS, {
		// TODO: Combine refetch here with refetch below and driving slips refetch?
		fetchPolicy: 'cache-and-network',
		variables: {
			userIdList: selectedUsersId,
			dateRange: {
				from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
				to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
			},
		},
		skip: selectedUsersId.length === 0,
	});

	const {
		data: carCalendarsData,
		loading: carCalendarsLoading,
		refetch: carCalendarsRefetch,
	} = useQuery<GetCarCalendars, GetCarCalendarsVariables>(GET_CAR_CALENDARS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			carIdList: selectedCarsId,
			dateRange: {
				from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
				to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
			},
		},
		skip: selectedCarsId.length === 0,
	});

	const refetchData = React.useCallback(() => Promise.all([drivingSlipsRefetch(), userCalendarsRefetch(), carCalendarsRefetch()]), [carCalendarsRefetch, drivingSlipsRefetch, userCalendarsRefetch]);

	React.useEffect(() => {
		typeof userCalendarsData?.userCalendars !== 'undefined' && userCalendarsData.userCalendars.length === selectedUsersId.length && setSelectedCalendars(userCalendarsData?.userCalendars);
	}, [userCalendarsData, selectedUsersId]);

	React.useEffect(() => {
		typeof carCalendarsData?.carCalendars !== 'undefined' && carCalendarsData.carCalendars.length === selectedCarsId.length && setSelectedCars(carCalendarsData?.carCalendars);
	}, [carCalendarsData, selectedCarsId]);

	const handleEntityOnClick = (id: string, selectedIdList: string[], setSelectedIdList: React.Dispatch<React.SetStateAction<string[]>>) => {
		if (selectedIdList.includes(id)) {
			setSelectedIdList(selectedIdList.filter(s => s !== id));

			// Modifies an array to keep bg colors consistent
			const index = bgOnIds.findIndex(bgId => bgId === id);
			bgOnIds.splice(index, 1);
			setBgOnIds(bgOnIds);
		} else {
			setSelectedIdList([...selectedIdList, id]);

			// Modifies an array to keep bg colors consistent
			const index = bgOnIds.findIndex(id => id === '');
			if (index === -1) {
				setBgOnIds([...bgOnIds, id]);
			} else {
				bgOnIds.splice(index, 1, id);
				setBgOnIds(bgOnIds);
			}
		}
	};

	const deselectAll = () => {
		setSelectedUsersId([]);
		setSelectedCarsId([]);
		setBgOnIds([]);
		setAllSanitizersActive(false);
	};

	const selectAllSanitizers = () => {
		if (allSanitizersActive) {
			setSelectedUsersId(selectedUsersId.filter(s => !filteredSanitizers.map(e => e.id).includes(s)));
			setBgOnIds(selectedUsersId.filter(s => !filteredSanitizers.map(e => e.id).includes(s)));
		} else {
			const sanitizers = filteredSanitizers.filter(s => !selectedUsersId.includes(s.id));
			setSelectedUsersId([...selectedUsersId, ...sanitizers.map(s => s.id)]);
			setBgOnIds([...bgOnIds, ...sanitizers.map(s => s.id)]);
		}
		setAllSanitizersActive(!allSanitizersActive);
	};

	const selectAllCars = () => {
		if (allCarsActive) {
			setSelectedCarsId(selectedCarsId.filter(s => !filteredCars.map(e => e.id).includes(s)));
			setBgOnIds(selectedCarsId.filter(s => !filteredCars.map(e => e.id).includes(s)));
		} else {
			const sanitizers = filteredCars.filter(s => !selectedCarsId.includes(s.id));
			setSelectedCarsId([...selectedCarsId, ...sanitizers.map(s => s.id)]);
			setBgOnIds([...bgOnIds, ...sanitizers.map(s => s.id)]);
		}
		setAllCarsActive(!allCarsActive);
	};

	const getBGColor = (userId: string): string => {
		return bgColors[bgOnIds.findIndex(id => id === userId) % bgColors.length];
	};

	if (error) {
		console.log('Error getting driving slips: ', error);
	}

	return (
		<div className="flex h-screen overflow-hidden pl-12">
			<div className="flex w-3/12 flex-col bg-white px-1 pt-4" style={{ minWidth: '400px' }}>
				<h2 className="text-blue text-2xl font-bold">{t('drivingSlips.overviewTitle')}</h2>
				<div>
					<DrivingSlipFilters
						showFilters={showDSFilters}
						data={data?.drivingSlips ?? []}
						filters={activeDrivingSlipsFilters}
						setFilters={setActiveDrivingSlipsFilters}
						clearFilters={clearStorageValue}
						setFilterTerm={setdrivingSlipsSearchTerm}
						setActiveUserFilter={setActiveUserDrivingSlipFilter}
						activeUserFilter={activeUserDrivingSlipFilter}
						selectData={adminData}
					/>
				</div>

				<div className="mt-2 flex items-end justify-between">
					<TextButton
						text={showDSFilters ? 'common.hideFilters' : 'common.showFilters'}
						icon={showDSFilters ? faChevronUp : faChevronDown}
						onClick={() => setShowDSFilters(!showDSFilters)}
					/>
					<select className="rounded-default py-1 text-xs" onChange={e => handleSorting(e.target.value)}>
						<option selected={sortingOption === SortingOptions.DATE_PRIORITY} value={SortingOptions.DATE_PRIORITY}>
							Dato &gt; Prioritet
						</option>
						<option selected={sortingOption === SortingOptions.PRIORITY_DATE} value={SortingOptions.PRIORITY_DATE}>
							Prioritet &gt; Dato
						</option>
					</select>
				</div>

				<p className="text-blue mt-2 text-sm font-semibold">
					{t('common.quantity')} <span className="lowercase">{t('drivingSlips.overviewTitle')}:</span> {sortedDrivingSlips.length}
				</p>

				<hr className="mb-2" />

				<div className="relative h-full overflow-y-auto">
					{drivingSlipsLoading && <Loading title="planner.gettingDrivingSlips" />}
					{sortedDrivingSlips.length ? (
						sortedDrivingSlips.map((d, i, arr) => {
							const firstAppearanceOfDate = arr.findIndex(ds => ds.startDate === d.startDate);
							const isFirstAppearance = firstAppearanceOfDate === i;
							const numberOfAppearences = arr.filter(ds => ds.startDate === d.startDate).length;

							return (
								<>
									{isFirstAppearance && (
										<div
											className={classNames('bg-blue sticky top-0 mb-1 flex w-full cursor-pointer justify-between py-1 px-4 text-2xl font-bold text-white', { 'mt-4': i !== 0 })}
											onClick={() => toggleDate(d.startDate)}
										>
											<p>
												{dateToDateTimeString(new Date(d.startDate), true)} ({numberOfAppearences})
											</p>
											<TextButton
												iconClassName="text-white"
												iconSize="2x"
												icon={openedDates.includes(d.startDate) ? faChevronLeft : faChevronDown}
												onClick={() => toggleDate(d.startDate)}
											/>
										</div>
									)}
									{openedDates.includes(d.startDate) && (
										<DraggableDrivingSlip
											key={d.series + d.version}
											drivingSlip={d}
											sanitizers={adminData.usersWithCalendars ?? []}
											categories={adminData.drivingSlipCategories ?? []}
											dataRefetcher={refetchData}
											drivingSlipsRefetch={async () => {
												await drivingSlipsRefetch({
													status: DrivingSlipStatus.UNPLANNED,
												});
											}}
										/>
									)}
								</>
							);
						})
					) : (
						<p className="text-xs">{t('planner.noActiveDrivingSlips')}</p>
					)}
				</div>
			</div>

			<div className="bg-blue flex w-2/12 select-none flex-col px-4 pt-3 pb-1" style={{ minWidth: '300px' }}>
				<div className="-mb-px flex flex-row">
					<div
						className={classNames('bg-blue flex-1 pt-1 pb-2 text-center text-xl text-white', {
							'border-1 rounded-t-default z-10 border-b-0 font-bold': !carView,
							'border-blue border-b-1 cursor-pointer': carView,
						})}
						onClick={() => setCarView(false)}
					>
						{t('planner.sanitizers')}
					</div>
					<div
						className={classNames('bg-blue flex-1 pt-1 pb-2 text-center text-xl text-white', {
							'border-1 rounded-t-default z-10 border-b-0 font-bold': carView,
							'border-blue border-b-1 cursor-pointer': !carView,
						})}
						onClick={() => setCarView(true)}
					>
						{t('planner.cars')}
					</div>
				</div>
				<div
					className="border-1 mb-1 flex h-full flex-col overflow-y-auto border-white px-2 pb-1"
					style={{
						borderTopLeftRadius: carView ? '0.25rem' : '0rem',
						borderTopRightRadius: !carView ? '0.25rem' : '0rem',
					}}
				>
					{!carView ? (
						<>
							<div>
								{showMiddleFilters && (
									<SanitizerFilters
										filters={activeSanitizersFilters}
										setFilters={setActiveSanitizersFilters}
										setActiveUserFilter={setActiveSanitizersFilters}
										setSanitizerSearchTerm={setSanitizerSearchTerm}
										sanitizerSearchTerm={sanitizerSearchTerm}
										activeUserFilter={activeSanitizersFilters}
										departmentsList={departmentsList}
										locationsList={locationsList}
										jobFunctionsList={jobFuncionsList}
										selectedUsers={selectedUsersId}
										setSelectedUsersId={setSelectedUsersId}
									/>
								)}
							</div>

							<div className="mt-3 flex items-center justify-between text-xs font-medium text-white">
								<TextButton
									text={showMiddleFilters ? 'common.hideFilters' : 'common.showFilters'}
									icon={showMiddleFilters ? faChevronUp : faChevronDown}
									onClick={() => setShowMiddleFilters(!showMiddleFilters)}
									textClassName="text-white"
									iconClassName="text-white"
									className="text-hover-white"
								/>
								<label className="flex cursor-pointer">
									{allSanitizersActive ? t('common.deselectAll') : t('common.selectAll')}
									<Checkbox name="all-sanitizers" checked={allSanitizersActive} disabled={filteredSanitizers.length === 0} onClick={selectAllSanitizers} className="ml-2" />
								</label>
							</div>

							<hr className="my-2 text-white" />

							<div className="overflow-y-auto">
								{adminDataLoading && (
									<div className="relative h-40">
										<Loading title="planner.gettingSanitizers" primary={false} />
									</div>
								)}
								{filteredSanitizers.map(s => (
									<CalendarEntity
										key={`${s.id}_usersList`}
										active={selectedUsersId.includes(s.id)}
										entity={s}
										onClick={() => handleEntityOnClick(s.id, selectedUsersId, setSelectedUsersId)}
										bgColor={getBGColor(s.id)}
									/>
								))}
							</div>
						</>
					) : (
						<>
							<div>
								{showMiddleFilters && (
									<CarFilters
										filters={activeCarFilters}
										setFilters={setActiveCarFilters}
										setActiveUserFilter={setActiveCarFilters}
										setCarSearchTerm={setCarSearchTerm}
										carSearchTerm={carSearchTerm}
										activeUserFilter={activeCarFilters}
										carLocationsList={carsLocationsList}
										selectedCars={selectedCarsId}
										departments={departmentsList}
									/>
								)}
							</div>

							<div className="mt-3 flex items-center justify-between text-xs font-medium text-white">
								<TextButton
									text={showMiddleFilters ? 'common.hideFilters' : 'common.showFilters'}
									icon={showMiddleFilters ? faChevronUp : faChevronDown}
									onClick={() => setShowMiddleFilters(!showMiddleFilters)}
									textClassName="text-white"
									iconClassName="text-white"
									className="text-hover-white"
								/>
								<label className="flex cursor-pointer">
									{allCarsActive ? t('common.deselectAll') : t('common.selectAll')}
									<Checkbox name="all-sanitizers" checked={allCarsActive} disabled={filteredCars.length === 0} onClick={selectAllCars} className="ml-2" />
								</label>
							</div>

							<hr className="my-2 text-white" />

							<div className="overflow-y-auto">
								{carsLoading && (
									<div className="relative h-40">
										<Loading title="planner.gettingCars" primary={false} />
									</div>
								)}
								{filteredCars.map(c => (
									<CalendarEntity
										key={`${c.id}_carsList`}
										active={selectedCarsId.includes(c.id)}
										entity={c}
										onClick={() => handleEntityOnClick(c.id, selectedCarsId, setSelectedCarsId)}
										bgColor={getBGColor(c.id)}
									/>
								))}
							</div>
						</>
					)}
				</div>
			</div>

			<Scheduler
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				selectedUserIds={selectedUsersId}
				selectedCarIds={selectedCarsId}
				getBgColor={getBGColor}
				calendarsLoading={userCalendarsLoading || carCalendarsLoading}
				deselectAll={deselectAll}
				userCalendars={selectedCalendars}
				handleOnUserClick={(id: string) => handleEntityOnClick(id, selectedUsersId, setSelectedUsersId)}
				usersWithCalendar={adminData.usersWithCalendars.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? []}
				carCalendars={selectedCars}
				carsWithCalendar={carsData?.carsWithCalendars.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? []}
				handleOnCarClick={(id: string) => handleEntityOnClick(id, selectedCarsId, setSelectedCarsId)}
				drivingSlipsRefetch={async () => {
					await drivingSlipsRefetch({
						status: DrivingSlipStatus.UNPLANNED,
					});
				}}
			/>
		</div>
	);
};

export default Planner;
