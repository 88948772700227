import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetWebDepartments_departments, GetInvoicesForUsersApproval, GetInvoicesForUsersApprovalVariables } from '../../../GraphQL';
import { calculateCostLinePrice, calculateLinePrice } from '../../SingleCase/Invoice/calculateLinePrice';
import Table from '@ssg/common/Components/Table';
import Box from '../../../Components/Layout/Box';
import InvoiceApprovalModal from './InvoiceApprovalModal';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { InvoiceCategory } from '@ssg/common/GraphQL';
import Select from 'react-select';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { getEnvFeeOnInvoice } from '../../../environmentalFeeHelper';

const GET_INVOICES = loader('src/GraphQL/Invoices/GetInvoicesForUsersApproval.gql');

interface Selection {
	caseId: string;
	caseERPno: string;
	totalCaseCost: number;
	invoiceERPReference: string;
	category: InvoiceCategory;
}

interface CalculatedValues {
	totalCost: number;
	totalPrice: number;
	totalEnvFee: number;
}

interface Props {
	departmentData: GetWebDepartments_departments[];
	loadingDepartments: boolean;
}

const InvoiceApprovalSection: React.FC<Props> = ({ departmentData, loadingDepartments }) => {
	const { t } = useTranslation();

	const [selectedInvoice, setSelectedInvoice] = React.useState<null | Selection>(null);
	const [selectedDepartments, setSelectedDepartments] = useStorageState<string[]>(window.localStorage, 'invoiceApprovalSelectedDepartmentsFilter', []);

	const { data, loading, refetch } = useQuery<GetInvoicesForUsersApproval, GetInvoicesForUsersApprovalVariables>(GET_INVOICES, {
		fetchPolicy: 'no-cache',
		variables: {
			department: selectedDepartments.length === 0 ? departmentData.map(d => d.id) : selectedDepartments,
		},
		skip: departmentData.length === 0,
	});

	const rows = React.useMemo(() => data?.invoicesForUsersApproval ?? [], [data]);

	// Memoize the values that are calculated for each row
	const calculatedValues: Record<string, CalculatedValues> = React.useMemo(() => {
		return rows.reduce(
			(acc, row) => {
				acc[row.invoiceToApprove.erpReferenceNo] = {
					totalPrice: row.invoiceToApprove.lines.map(calculateLinePrice).reduce((a, b) => a + b, 0),
					totalCost: row.invoiceToApprove.lines.map(calculateCostLinePrice).reduce((a, b) => a + b, 0),
					totalEnvFee: getEnvFeeOnInvoice(row.invoiceToApprove.lines, row.case.debitor),

				};

				return acc;
			},
			{} as Record<string, CalculatedValues>,
		);
	}, [rows]);

	return (
		<Box full title="myPage.invoiceApproval.title">
			<label className="text-blue block text-xs font-medium">
				{t('common.department')}
				<Select
					placeholder=""
					isClearable
					isMulti
					closeMenuOnSelect={false}
					menuPortalTarget={document.body}
					className="react-select-custom mt-1 mb-3 w-40 text-xs"
					value={
						selectedDepartments.map(v => {
							const d = departmentData.find(department => department.id === v);
							return {
								value: d?.id ?? '',
								label: typeof d !== 'undefined' ? `${d.name} (${d.departmentNumber})` : '',
							};
						}) ?? []
					}
					styles={{
						menuPortal: base => ({ ...base, zIndex: 6667 }),
					}}
					onChange={values => setSelectedDepartments(values?.map(v => v.value) ?? [])}
					options={departmentData.map<SelectOption>(d => ({
						label: `${d.name} (${d.departmentNumber})`,
						value: d.id,
					}))}
				/>
			</label>

			<Table
				loading={loading}
				data={rows}
				keySelector={row => row.invoiceToApprove.erpReferenceNo}
				onRowClick={row =>
					setSelectedInvoice({
						caseId: row.case.id,
						invoiceERPReference: row.invoiceToApprove.erpReferenceNo,
						category: row.invoiceToApprove.category,
						caseERPno: row.case.erpNo,
						totalCaseCost: row.case.caseEconomics?.costTotal ?? 0,
					})
				}
				columns={[
					{
						label: t('myPage.invoiceApproval.caseNo'),
						selectFn: row => row.case.erpNo,
					},
					{
						label: t('myPage.invoiceApproval.invoiceNo'),
						selectFn: row => row.invoiceToApprove.erpReferenceNo,
					},
					{
						label: t('myPage.invoiceApproval.category'),
						selectFn: row => <span>{t(`case.invoice.categories.${row.invoiceToApprove.category}`)}</span>,
					},
					{
						label: t('myPage.invoiceApproval.debitor'),
						selectFn: row => row.case.debitor.company,
					},
					{
						label: t('myPage.invoiceApproval.createdBy'),
						selectFn: row => <span>{row.invoiceToApprove.createdBy?.name ?? t('myPage.invoiceApproval.createdByUnknown')}</span>,
					},
					{
						label: t('myPage.invoiceApproval.projectManager'),
						selectFn: row => <span>{row.case.projectManager?.name ?? t('myPage.invoiceApproval.projectManagerUnknown')}</span>,
					},
					{
						label: t('myPage.invoiceApproval.ssgDepartment'),
						selectFn: row => (
							<span>
								{row.case.ssgDepartment.name} ({row.case.ssgDepartment.departmentNumber})
							</span>
						),
					},
					{
						label: t('myPage.invoiceApproval.totalPrice'),
						selectFn: row => addThousandSeperator(calculatedValues[row.invoiceToApprove.erpReferenceNo].totalPrice),
					},
					{
						label: t('myPage.invoiceApproval.totalCaseCost'),
						selectFn: row => addThousandSeperator(row.case.caseEconomics?.costTotal ?? 0),
					},
					{
						label: t('myPage.invoiceApproval.contributionRatio'),
						selectFn: row => {
							const { totalPrice } = calculatedValues[row.invoiceToApprove.erpReferenceNo];
							return (
								<span>
									{(((totalPrice - (row.case.caseEconomics?.costTotal ?? 0)) / totalPrice) * 100).toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}
									%
								</span>
							);
						},
					},
				]}
			/>

			{selectedInvoice != null && (
				<InvoiceApprovalModal
					caseId={selectedInvoice.caseId}
					caseERPno={selectedInvoice.caseERPno}
					invoiceNo={selectedInvoice.invoiceERPReference}
					isScreening={selectedInvoice.category === InvoiceCategory.SCREENING}
					close={() => setSelectedInvoice(null)}
					refetch={refetch}
					totalCaseCost={selectedInvoice.totalCaseCost}
				/>
			)}
		</Box>
	);
};

export default InvoiceApprovalSection;
