import React from 'react';
import { DateTime } from 'luxon';
import {
	CalendarEventInput,
	CalendarEventType,
	ChangeUserOnCalendarEvent,
	ChangeUserOnCalendarEventVariables,
	CreateUserCalendarEvent,
	CreateUserCalendarEventVariables,
	DeleteUserCalendarEvent,
	DeleteUserCalendarEventVariables,
	GetUserCalendars,
	GetUserCalendars_userCalendars_events,
	GetUsersWithCalendars,
	UpdateUserCalendarEvent,
	UpdateUserCalendarEventVariables,
	GetCarCalendars,
	ChangeCarOnCalendarEvent,
	ChangeCarOnCalendarEventVariables,
	CreateCarCalendarEvent,
	CreateCarCalendarEventVariables,
	DeleteCarCalendarEvent,
	DeleteCarCalendarEventVariables,
	UpdateCarCalendarEvent,
	UpdateCarCalendarEventVariables,
	GetCarsWithCalendars,
	CreateDrivingSlipVariables,
	GetUserCalendarsVariables,
} from '../../GraphQL';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { IUpdateEvent } from '../../Schemas/IUpdateEvent';
import { UpdateEventSchema } from '../../Schemas/UpdateEventSchema';
import TimeSlot from './TimeSlot';
import PushDiv from './PushDiv';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Modal from '@ssg/common/Components/Modal';
import Dropdown from '@ssg/common/Components/Dropdown';
import Datepicker from '@ssg/common/Components/Datepicker';
import Button from '@ssg/common/Components/Button';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import DraggableEventItem from './DraggableEventItemTwo';
import DrivingSlipRequisitionModal from './DrivingSlipRequisitionModal';

const GET_USER_CALENDARS = loader('src/GraphQL/Planner/GetUserCalendars.gql');
const CREATE_USER_CALENDAR_EVENT = loader('src/GraphQL/Planner/CreateUserCalendarEvent.gql');
const UPDATE_USER_CALENDAR_EVENT = loader('src/GraphQL/Planner/UpdateUserCalendarEvent.gql');
const DELETE_USER_CALENDAR_EVENT = loader('src/GraphQL/Planner/DeleteUserCalendarEvent.gql');
const CHANGE_USER_ON_CALENDAR_EVENT = loader('src/GraphQL/Planner/ChangeUserOnCalendarEvent.gql');

const GET_CAR_CALENDARS = loader('src/GraphQL/Planner/GetCarCalendars.gql');
const CREATE_CAR_CALENDAR_EVENT = loader('src/GraphQL/Planner/CreateCarCalendarEvent.gql');
const UPDATE_CAR_CALENDAR_EVENT = loader('src/GraphQL/Planner/UpdateCarCalendarEvent.gql');
const DELETE_CAR_CALENDAR_EVENT = loader('src/GraphQL/Planner/DeleteCarCalendarEvent.gql');
const CHANGE_CAR_ON_CALENDAR_EVENT = loader('src/GraphQL/Planner/ChangeCarOnCalendarEvent.gql');

type EventItem = GetUserCalendars['userCalendars'][number]['events'][number] | GetCarCalendars['carCalendars'][number]['events'][number];

const eventTypes: SelectOption[] = [...enumToSelectOptions(CalendarEventType, 'planner.types.').filter(e => e.value !== CalendarEventType.DRIVING_SLIP && e.value !== CalendarEventType.UNKNOWN)];

interface Props {
	calendar: GetUserCalendars['userCalendars'][number] | GetCarCalendars['carCalendars'][number];
	date: Date;
	startDate: DateTime;
	endDate: DateTime;
	rangeStartTime: number;
	rangeEndTime: number;
	getBgColor(userId: string): string;
	selectedEntitiesId: string[];
	timeSlotClickHandler(timeSlotKey: string): void;
	clickedTimeSlot: string;
	usersWithCalendar: GetUsersWithCalendars['usersWithCalendars'];
	carsWithCalendar: GetCarsWithCalendars['carsWithCalendars'];

	createDrivingSlip: (drivingSlip: CreateDrivingSlipVariables['drivingSlip']['input'], caseId: CreateDrivingSlipVariables['caseId']) => Promise<void>;
	drivingSlipsRefetch(): unknown;
}

export interface EventItemPlus {
	eventItem: EventItem;
	index: number;
	calculatedFrom?: Date;
	calculatedTo?: Date;
}

//Creates an array of events with "row" indexes
const mapEvents = (eventItems: EventItem[], rangeStartDate: DateTime, rangeEndDate: DateTime): EventItemPlus[] => {
	const sortedEventItems = eventItems.slice().sort((a, b) => {
		const aFromTime = new Date(a.timeRange.from).getTime();
		const bFromTime = new Date(b.timeRange.from).getTime();
		if (aFromTime !== bFromTime) {
			return aFromTime - bFromTime;
		}

		return new Date(b.timeRange.to).getTime() - new Date(a.timeRange.to).getTime();
	});
	const arrayOfArrayOfEventItems: EventItemPlus[] = [];

	for (let i = 0; i < sortedEventItems.length; i += 1) {
		arrayOfArrayOfEventItems.push({
			eventItem: sortedEventItems[i],
			calculatedFrom: determineCalculatedFrom(sortedEventItems[i], rangeStartDate),
			calculatedTo: determineCalculatedTo(sortedEventItems[i], rangeEndDate.plus({ hours: 1 })),
			index: determineIndex(arrayOfArrayOfEventItems, sortedEventItems[i]),
		});
	}
	return arrayOfArrayOfEventItems;
};

// Calculates the index of an event to place it in the correct "row" on the timeline
const determineIndex = (eventItemsPlus: EventItemPlus[], eventItem: EventItem): number => {
	if (eventItemsPlus.length === 0) return 0;

	const eventOnThis = eventItemsPlus
		.filter(e => new Date(e.eventItem.timeRange.from) <= new Date(eventItem.timeRange.from) && new Date(eventItem.timeRange.from) < new Date(e.eventItem.timeRange.to))
		.slice()
		.sort((a, b) => a.index - b.index);

	if (eventOnThis.length === 0) return 0;

	const indexarray = eventOnThis.map(e => e.index);

	return Array.from({ length: indexarray[indexarray.length - 1] }, (_, i) => i).find(n => !indexarray.includes(n)) ?? eventOnThis.length;
};

const determineCalculatedFrom = (eventItem: EventItem, rangeStartDate: DateTime): Date | undefined => {
	const fromDateTime = DateTime.fromISO(eventItem.timeRange.from + 'Z');
	const toDateTime = DateTime.fromISO(eventItem.timeRange.to + 'Z');

	if (fromDateTime.toMillis() < rangeStartDate.toMillis() && rangeStartDate.toMillis() < toDateTime.toMillis()) {
		return rangeStartDate.toJSDate();
	}
};

const determineCalculatedTo = (eventItem: EventItem, rangeEndDate: DateTime): Date | undefined => {
	const fromDateTime = DateTime.fromISO(eventItem.timeRange.from + 'Z');
	const toDateTime = DateTime.fromISO(eventItem.timeRange.to + 'Z');

	if (rangeEndDate.toMillis() < toDateTime.toMillis() && fromDateTime.toMillis() < rangeEndDate.toMillis()) {
		return rangeEndDate.toJSDate();
	}
};

const WorkerLine: React.FC<Props> = ({
	calendar,
	date,
	rangeStartTime,
	rangeEndTime,
	startDate,
	endDate,
	getBgColor,
	selectedEntitiesId,
	timeSlotClickHandler,
	clickedTimeSlot,
	usersWithCalendar,
	carsWithCalendar,
	createDrivingSlip,
	drivingSlipsRefetch,
}): React.ReactElement => {
	const rangeStartDate = DateTime.fromJSDate(new Date(date.setHours(rangeStartTime, 0, 0)));
	const rangeEndDate = DateTime.fromJSDate(new Date(date.setHours(rangeEndTime, 0, 0)));
	const [showCopyEvent, setShowCopyEvent] = React.useState<GetUserCalendars_userCalendars_events | undefined>(undefined);
	const [showEditEvent, setShowEditEvent] = React.useState<GetUserCalendars_userCalendars_events | undefined>(undefined);
	const [convertToRequisition, setConvertToRequisition] = React.useState(false);
	const [driverSearchText, setDriverSearchText] = useDebouncedState('user' in calendar ? calendar.user.name : '', 100);
	const [carSearchText, setCarSearchText] = useDebouncedState('car' in calendar ? calendar.car.name : '', 100);
	const [searchedUsers, setSearchedUsers] = React.useState<SelectOption[]>(
		usersWithCalendar.filter(s => driverSearchText.length === 0 || s.name.toLowerCase().includes(driverSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
	);
	const [searchedCars, setSearchedCars] = React.useState<SelectOption[]>(
		carsWithCalendar.filter(s => carSearchText.length === 0 || s.name.toLowerCase().includes(carSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
	);

	const workerEvents = mapEvents(calendar.events, rangeStartDate, rangeEndDate);
	const timeSlots: React.ReactElement[] = [];

	const [createUserCalendarEvent, { loading: createUserEventLoading }] = useMutation<CreateUserCalendarEvent, CreateUserCalendarEventVariables>(CREATE_USER_CALENDAR_EVENT);
	const [updateUserCalendarEvent, { loading: updateUserEventLoading }] = useMutation<UpdateUserCalendarEvent, UpdateUserCalendarEventVariables>(UPDATE_USER_CALENDAR_EVENT);
	const [deleteUserCalendarEvent, { loading: deleteUserEventLoading }] = useMutation<DeleteUserCalendarEvent, DeleteUserCalendarEventVariables>(DELETE_USER_CALENDAR_EVENT);
	const [changeUserOnCalendarEvent, { loading: changeUserLoading }] = useMutation<ChangeUserOnCalendarEvent, ChangeUserOnCalendarEventVariables>(CHANGE_USER_ON_CALENDAR_EVENT);

	const [createCarCalendarEvent, { loading: createCarEventLoading }] = useMutation<CreateCarCalendarEvent, CreateCarCalendarEventVariables>(CREATE_CAR_CALENDAR_EVENT);
	const [updateCarCalendarEvent, { loading: updateCarEventLoading }] = useMutation<UpdateCarCalendarEvent, UpdateCarCalendarEventVariables>(UPDATE_CAR_CALENDAR_EVENT);
	const [deleteCarCalendarEvent, { loading: deleteCarEventLoading }] = useMutation<DeleteCarCalendarEvent, DeleteCarCalendarEventVariables>(DELETE_CAR_CALENDAR_EVENT);
	const [changeCarOnCalendarEvent, { loading: changeCarLoading }] = useMutation<ChangeCarOnCalendarEvent, ChangeCarOnCalendarEventVariables>(CHANGE_CAR_ON_CALENDAR_EVENT);

	// Create events
	const postUserCalendarEvent = async (userId: string, event: CalendarEventInput) => {
		try {
			await createUserCalendarEvent({
				variables: {
					userId: userId,
					event: event,
				},
				optimisticResponse: {
					createUserCalendarEvent: {
						isAllDay: false,
						drivingSlip: null,
						systemCreatedEvent: true,
						referenceId: 'CREATING',
						timeRange: {
							from: event.timeRange.from.slice(0, '2023-04-28T10:00:00'.length - 1),
							to: event.timeRange.to.slice(0, '2023-04-28T10:00:00'.length - 1),
						},
						title: event.title,
						type: event.type,
						__typename: 'CalendarEvent',
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.createUserCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetUserCalendars, GetUserCalendarsVariables>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					cachedRequestCopy[userEntryIndex] = {
						...cachedRequestCopy[userEntryIndex],
						events: [...cachedRequestCopy[userEntryIndex].events, data.createUserCalendarEvent],
					};
					cache.writeQuery<GetUserCalendars, GetUserCalendarsVariables>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							userCalendars: cachedRequestCopy,
						},
					});
				},
			});
			drivingSlipsRefetch();
			timeSlotClickHandler('');
		} catch (e) {
			console.log(e);
		}
	};

	const postCarCalendarEvent = async (carId: string, event: CalendarEventInput) => {
		try {
			await createCarCalendarEvent({
				variables: {
					carId: carId,
					event: event,
				},
				optimisticResponse: {
					createCarCalendarEvent: {
						isAllDay: false,
						drivingSlip: null,
						systemCreatedEvent: true,
						referenceId: 'CREATING',
						timeRange: {
							from: event.timeRange.from.slice(0, '2023-04-28T10:00:00'.length - 1),
							to: event.timeRange.to.slice(0, '2023-04-28T10:00:00'.length - 1),
						},
						title: event.title,
						type: event.type,
						__typename: 'CalendarEvent',
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.createCarCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCarCalendars>({
						query: GET_CAR_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.carCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.carCalendars];
					const carEntryIndex = cachedRequestCopy.findIndex(c => c.car.id === carId);
					cachedRequestCopy[carEntryIndex] = {
						...cachedRequestCopy[carEntryIndex],
						events: [...cachedRequestCopy[carEntryIndex].events, data.createCarCalendarEvent],
					};
					cache.writeQuery<GetCarCalendars>({
						query: GET_CAR_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							carCalendars: cachedRequestCopy,
						},
					});
				},
			});
			timeSlotClickHandler('');
		} catch (e) {
			console.log(e);
		}
	};

	// All funcions concerning updating events
	const { handleSubmit, control, register, errors, setValue } = useForm<IUpdateEvent>({
		resolver: yupResolver(UpdateEventSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			userId: 'user' in calendar ? calendar.user.id : calendar.car.id,
		},
	});

	const searchUserSelectHandler = (value: string) => setValue('userId', value, { shouldValidate: true });
	const searchCarSelectHandler = (value: string) => setValue('carId', value, { shouldValidate: true });

	React.useEffect(() => {
		setSearchedUsers(
			usersWithCalendar.filter(s => driverSearchText.length === 0 || s.name.toLowerCase().includes(driverSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
		);
	}, [driverSearchText, usersWithCalendar]);

	React.useEffect(() => {
		setSearchedCars(
			carsWithCalendar.filter(s => carSearchText.length === 0 || s.name.toLowerCase().includes(carSearchText.toLowerCase())).map((d): SelectOption => ({ value: d.id, label: d.name })),
		);
	}, [carSearchText, carsWithCalendar]);

	const postUserCalendarEventEdit = async (userId: string, event: CalendarEventInput, eventId: string) => {
		try {
			await updateUserCalendarEvent({
				variables: {
					userId: userId,
					eventId: eventId,
					event: event,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.updateUserCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetUserCalendars>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					cachedRequestCopy[userEntryIndex] = {
						...cachedRequestCopy[userEntryIndex],
						events: [...cachedRequestCopy[userEntryIndex].events.filter(e => e.referenceId !== data.updateUserCalendarEvent.referenceId), data.updateUserCalendarEvent],
					};

					cache.writeQuery<GetUserCalendars>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							userCalendars: cachedRequestCopy,
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postCarCalendarEventEdit = async (carId: string, event: CalendarEventInput, eventId: string) => {
		try {
			await updateCarCalendarEvent({
				variables: {
					carId: carId,
					eventId: eventId,
					event: event,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.updateCarCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCarCalendars>({
						query: GET_CAR_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.carCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.carCalendars];
					const carEntryIndex = cachedRequestCopy.findIndex(c => c.car.id === carId);
					cachedRequestCopy[carEntryIndex] = {
						...cachedRequestCopy[carEntryIndex],
						events: [...cachedRequestCopy[carEntryIndex].events.filter(e => e.referenceId !== data.updateCarCalendarEvent.referenceId), data.updateCarCalendarEvent],
					};

					cache.writeQuery<GetCarCalendars>({
						query: GET_CAR_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							carCalendars: cachedRequestCopy,
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postChangeUserOnCalendarEvent = async (userId: string, event: CalendarEventInput, originalEventId: string, originalUserId: string) => {
		try {
			await changeUserOnCalendarEvent({
				variables: {
					userId: userId,
					originalEventId: originalEventId,
					event: event,
					originalUserId: originalUserId,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.changeUserOnCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetUserCalendars>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const originalUserEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === originalUserId);
					cachedRequestCopy[originalUserEntryIndex] = {
						...cachedRequestCopy[originalUserEntryIndex],
						events: [...cachedRequestCopy[originalUserEntryIndex].events.filter(e => e.referenceId !== originalEventId)],
					};

					const newUserEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					if (newUserEntryIndex !== -1) {
						cachedRequestCopy[newUserEntryIndex] = {
							...cachedRequestCopy[newUserEntryIndex],
							events: [...cachedRequestCopy[newUserEntryIndex].events, data.changeUserOnCalendarEvent],
						};
					}

					cache.writeQuery<GetUserCalendars>({
						query: GET_USER_CALENDARS,
						variables: {
							userIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							userCalendars: cachedRequestCopy,
						},
					});
				},
			});
			timeSlotClickHandler('');
			setShowEditEvent(undefined);
		} catch (e) {
			console.log(e);
		}
	};

	const postChangeCarOnCalendarEvent = async (carId: string, event: CalendarEventInput, originalEventId: string, originalCarId: string) => {
		try {
			await changeCarOnCalendarEvent({
				variables: {
					carId: carId,
					originalEventId: originalEventId,
					event: event,
					originalCarId: originalCarId,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.changeCarOnCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCarCalendars>({
						query: GET_USER_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
					});

					if (cachedRequest === null || cachedRequest.carCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.carCalendars];
					const originalCarEntryIndex = cachedRequestCopy.findIndex(c => c.car.id === originalCarId);
					cachedRequestCopy[originalCarEntryIndex] = {
						...cachedRequestCopy[originalCarEntryIndex],
						events: [...cachedRequestCopy[originalCarEntryIndex].events.filter(e => e.referenceId !== originalEventId)],
					};

					const newCarEntryIndex = cachedRequestCopy.findIndex(c => c.car.id === carId);
					if (newCarEntryIndex !== -1) {
						cachedRequestCopy[newCarEntryIndex] = {
							...cachedRequestCopy[newCarEntryIndex],
							events: [...cachedRequestCopy[newCarEntryIndex].events, data.changeCarOnCalendarEvent],
						};
					}

					cache.writeQuery<GetCarCalendars>({
						query: GET_CAR_CALENDARS,
						variables: {
							carIdList: selectedEntitiesId,
							dateRange: {
								from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
								to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
							},
						},
						data: {
							carCalendars: cachedRequestCopy,
						},
					});
				},
			});
			timeSlotClickHandler('');
			setShowEditEvent(undefined);
		} catch (e) {
			console.log(e);
		}
	};

	const onSubmit = (drivingSlipId: string | undefined) => async (data: IUpdateEvent) => {
		if ('user' in calendar) {
			if (typeof showCopyEvent !== 'undefined') {
				await createDrivingSlip(
					{
						series: showCopyEvent.drivingSlip?.series ?? 'error',
						driver: data.userId,
						start: DateTime.fromISO(data.fromDate).toUTC().toISO(),
						end: DateTime.fromISO(data.toDate).toUTC().toISO(),
						urgent: showCopyEvent.drivingSlip?.urgent ?? false,
						comment: showCopyEvent.drivingSlip?.comment,
						deadline: DateTime.fromISO(data.deadline).toUTC().toISO(),
						location: showCopyEvent.drivingSlip?.location.id ?? '',
						department: showCopyEvent.drivingSlip?.department.id ?? '',
					},
					showCopyEvent.drivingSlip?.case.id ?? 'error',
				);
				timeSlotClickHandler('');
				setShowCopyEvent(undefined);
			}
			if (typeof showEditEvent !== 'undefined') {
				await updateUserEventDeciderAndPost(showEditEvent.referenceId, data.type, data.title, data.fromDate, data.toDate, data.userId, calendar.user.id, drivingSlipId);
			}
		}
		if ('car' in calendar) {
			if (typeof showCopyEvent !== 'undefined') {
				await createDrivingSlip(
					{
						series: showCopyEvent.drivingSlip?.series ?? 'error',
						car: data.carId,
						start: DateTime.fromISO(data.fromDate).toUTC().toISO(),
						end: DateTime.fromISO(data.toDate).toUTC().toISO(),
						urgent: showCopyEvent.drivingSlip?.urgent ?? false,
						comment: showCopyEvent.drivingSlip?.comment,
						deadline: DateTime.fromISO(data.deadline).toUTC().toISO(),
						location: showCopyEvent.drivingSlip?.location.id ?? '',
						department: showCopyEvent.drivingSlip?.department.id ?? '',
					},
					showCopyEvent.drivingSlip?.case.id ?? 'error',
				);
				timeSlotClickHandler('');
				setShowCopyEvent(undefined);
			}
			if (typeof showEditEvent !== 'undefined') {
				await updateCarEventDeciderAndPost(showEditEvent.referenceId, data.type, data.title, data.fromDate, data.toDate, data.carId, calendar.car.id, drivingSlipId);
			}
		}
	};

	const updateUserEventDeciderAndPost = async (
		eventId: string,
		eventType: string,
		eventTitle: string,
		eventFromDate: string,
		eventToDate: string,
		newUserId: string,
		oldUserId: string,
		drivingSlipId: string | undefined,
	) => {
		if (newUserId === oldUserId) {
			try {
				await postUserCalendarEventEdit(
					oldUserId,
					{
						title: eventTitle,
						type: eventType as CalendarEventType,
						timeRange: {
							from: DateTime.fromISO(eventFromDate).toUTC().toISO(),
							to: DateTime.fromISO(eventToDate).toUTC().toISO(),
						},
						drivingSlip: drivingSlipId,
					},
					eventId,
				);
				timeSlotClickHandler('');
				setShowEditEvent(undefined);
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await postChangeUserOnCalendarEvent(
					newUserId,
					{
						title: eventTitle,
						type: eventType as CalendarEventType,
						timeRange: {
							from: DateTime.fromISO(eventFromDate).toUTC().toISO(),
							to: DateTime.fromISO(eventToDate).toUTC().toISO(),
						},
						drivingSlip: drivingSlipId,
					},
					eventId,
					oldUserId,
				);
				timeSlotClickHandler('');
				setShowEditEvent(undefined);
			} catch (e) {
				console.log(e);
			}
		}
	};

	const updateCarEventDeciderAndPost = async (
		eventId: string,
		eventType: string,
		eventTitle: string,
		eventFromDate: string,
		eventToDate: string,
		newCarId: string,
		oldCarId: string,
		drivingSlipId: string | undefined,
	) => {
		if (newCarId === oldCarId) {
			try {
				await postCarCalendarEventEdit(
					oldCarId,
					{
						title: eventTitle,
						type: eventType as CalendarEventType,
						timeRange: {
							from: DateTime.fromISO(eventFromDate).toUTC().toISO(),
							to: DateTime.fromISO(eventToDate).toUTC().toISO(),
						},
						drivingSlip: drivingSlipId,
					},
					eventId,
				);
				timeSlotClickHandler('');
				setShowEditEvent(undefined);
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await postChangeCarOnCalendarEvent(
					newCarId,
					{
						title: eventTitle,
						type: eventType as CalendarEventType,
						timeRange: {
							from: DateTime.fromISO(eventFromDate).toUTC().toISO(),
							to: DateTime.fromISO(eventToDate).toUTC().toISO(),
						},
						drivingSlip: drivingSlipId,
					},
					eventId,
					oldCarId,
				);
				timeSlotClickHandler('');
				setShowEditEvent(undefined);
			} catch (e) {
				console.log(e);
			}
		}
	};

	// Delete event
	const postCalendarEventDeletion = async (eventId: string, drivingSlipId: string | undefined) => {
		try {
			if ('user' in calendar) {
				await deleteUserCalendarEvent({
					variables: {
						userId: calendar.user.id,
						eventId: eventId,
						drivingSlipId: drivingSlipId,
					},
					update: (cache, { data }): void => {
						if (typeof data === 'undefined' || data === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetUserCalendars>({
							query: GET_USER_CALENDARS,
							variables: {
								userIdList: selectedEntitiesId,
								dateRange: {
									from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
									to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
								},
							},
						});

						if (cachedRequest === null || cachedRequest.userCalendars === null) {
							return;
						}

						const cachedRequestCopy = [...cachedRequest.userCalendars];
						const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === calendar.user.id);
						cachedRequestCopy[userEntryIndex] = {
							...cachedRequestCopy[userEntryIndex],
							events: [...cachedRequestCopy[userEntryIndex].events.filter(e => e.referenceId !== eventId)],
						};
						cache.writeQuery<GetUserCalendars>({
							query: GET_USER_CALENDARS,
							variables: {
								userIdList: selectedEntitiesId,
								dateRange: {
									from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
									to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
								},
							},
							data: {
								userCalendars: cachedRequestCopy,
							},
						});

						if (data.deleteUserCalendarEvent !== null) {
							drivingSlipsRefetch();
							// const cachedDrivingSlipRequest = cache.readQuery<GetDrivingSlips>({
							//     query: GET_DRIVING_SLIPS,
							//     variables: {
							//         status: DrivingSlipStatus.UNPLANNED,
							//     },
							// });

							// if (cachedDrivingSlipRequest === null || cachedDrivingSlipRequest.drivingSlips === null) {
							//     return;
							// }

							// cache.writeQuery<GetDrivingSlips>({
							//     query: GET_DRIVING_SLIPS,
							//     variables: {
							//         status: DrivingSlipStatus.UNPLANNED,
							//     },
							//     data: {
							//         drivingSlips: [...cachedDrivingSlipRequest.drivingSlips, data.deleteUserCalendarEvent],
							//     },
							// });
						}
					},
				});
			} else if ('car' in calendar) {
				await deleteCarCalendarEvent({
					variables: {
						carId: calendar.car.id,
						eventId: eventId,
						drivingSlipId: drivingSlipId,
					},
					update: (cache, { data }): void => {
						if (typeof data === 'undefined' || data === null) {
							return;
						}
						const cachedRequest = cache.readQuery<GetCarCalendars>({
							query: GET_CAR_CALENDARS,
							variables: {
								carIdList: selectedEntitiesId,
								dateRange: {
									from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
									to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
								},
							},
						});

						if (cachedRequest === null || cachedRequest.carCalendars === null) {
							return;
						}

						const cachedRequestCopy = [...cachedRequest.carCalendars];
						const carEntryIndex = cachedRequestCopy.findIndex(c => c.car.id === calendar.car.id);
						cachedRequestCopy[carEntryIndex] = {
							...cachedRequestCopy[carEntryIndex],
							events: [...cachedRequestCopy[carEntryIndex].events.filter(e => e.referenceId !== eventId)],
						};
						cache.writeQuery<GetCarCalendars>({
							query: GET_CAR_CALENDARS,
							variables: {
								carIdList: selectedEntitiesId,
								dateRange: {
									from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
									to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
								},
							},
							data: {
								carCalendars: cachedRequestCopy,
							},
						});
					},
				});
			}
			setShowEditEvent(undefined);
		} catch (e) {
			console.log(e);
		}
	};

	const renderTimeSlots = (start: number, end: number, date: Date) => {
		const startDate = DateTime.fromJSDate(date).startOf('day');

		for (let i = start; i <= end + 0.75; i += 0.25) {
			const newStart = startDate.plus({ hour: i });
			const newEnd = newStart.plus({ minute: 15 });
			const eventsStartOnThis = workerEvents.filter(e => {
				const eventStart = DateTime.fromISO(e.calculatedFrom?.toISOString() ?? e.eventItem.timeRange.from + 'Z').toLocal();
				return newStart.toJSDate() <= eventStart.toJSDate() && eventStart.toJSDate() < newEnd.toJSDate();
			});
			eventsStartOnThis.sort((a, b) => a.index - b.index);

			// Calculates the amount of push events on the timeline to create a correct grid
			const pushUp = eventsStartOnThis.length > 0 ? Math.min(...eventsStartOnThis.map(e => e.index)) : 0;

			// Crates push divs before events
			const numOfDivBefore = Array.from({ length: pushUp }, () => <PushDiv key={Math.random().toString(36).substring(2)} />);

			timeSlots.push(
				<TimeSlot
					calendar={calendar}
					className="even:border-r-1 border-gray-300 pb-8"
					addEvent={'user' in calendar ? postUserCalendarEvent : postCarCalendarEvent}
					updateEventDeciderAndPost={'user' in calendar ? updateUserEventDeciderAndPost : updateCarEventDeciderAndPost}
					startTime={newStart}
					key={`${'user' in calendar ? calendar.user.id : calendar.car.id}_timeslot_${i}`}
					keyName={`${'user' in calendar ? calendar.user.id : calendar.car.id}_timeslot_${i}`}
					getBgColor={getBgColor}
					timeSlotClickHandler={() => timeSlotClickHandler(`${'user' in calendar ? calendar.user.id : calendar.car.id}_timeslot_${i}`)}
					timeSlotClicked={clickedTimeSlot === `${'user' in calendar ? calendar.user.id : calendar.car.id}_timeslot_${i}`}
					createEventLoading={'user' in calendar ? createUserEventLoading : createCarEventLoading}
				>
					{numOfDivBefore}
					{eventsStartOnThis.map((e, i) => {
						const eventStart = DateTime.fromISO(e.calculatedFrom?.toISOString() ?? e.eventItem.timeRange.from + 'Z').toLocal();
						const eventEnd = DateTime.fromISO(e.calculatedTo?.toISOString() ?? e.eventItem.timeRange.to + 'Z').toLocal();
						const nextEvent = eventsStartOnThis[i + 1];

						//Calculates the amount to push events on the timeline to create a correct grid
						const pushAfter = typeof nextEvent === 'undefined' ? 0 : nextEvent.index - e.index - 1;
						const hoursDiff = eventEnd.diff(eventStart, 'hours').toObject();
						const durationInHours = hoursDiff.hours === undefined ? 0 : hoursDiff.hours;

						//Creates push divs after events
						const numOfDivAfter = Array.from({ length: pushAfter }, () => <PushDiv key={Math.random().toString(36).substring(2)} />);
						// h-12 -> h-5
						return (
							<>
								<div key={('user' in calendar ? calendar.user.id : calendar.car.id) + i} className="relative mb-px h-5 w-full select-none">
									<DraggableEventItem
										clickedTimeSlot={clickedTimeSlot}
										durationInHours={durationInHours}
										e={e}
										setShowCopyEvent={setShowCopyEvent}
										setShowEditEvent={setShowEditEvent}
										setConvertToRequisition={setConvertToRequisition}
										timeSlotClickHandler={timeSlotClickHandler}
										userId={'user' in calendar ? calendar.user.id : calendar.car.id}
									/>

									<Modal
										visible={showCopyEvent?.referenceId === e.eventItem.referenceId || showEditEvent?.referenceId === e.eventItem.referenceId}
										close={() => {
											setShowCopyEvent(undefined);
											setShowEditEvent(undefined);
										}}
										title={typeof showCopyEvent !== 'undefined' ? 'planner.copyEvent' : 'planner.editEvent'}
										body={
											<div className="select-none">
												<form onSubmit={handleSubmit(onSubmit(e.eventItem.drivingSlip?.id))}>
													{'user' in calendar && (
														<SearchableSelect
															control={control}
															name="userId"
															title="planner.sanitizer"
															key="label"
															options={searchedUsers}
															searchFn={searchText => setDriverSearchText(searchText)}
															onSelect={value => searchUserSelectHandler(value)}
															onBlur={() => undefined}
															minInputLength={0}
															isLoading={false}
															errorMessage={errors.userId?.message ?? ''}
															initialSelection={
																typeof showEditEvent !== 'undefined'
																	? {
																		value: calendar.user.id,
																		label: calendar.user.name,
																	}
																	: undefined
															}
														/>
													)}

													{'car' in calendar && (
														<SearchableSelect
															control={control}
															name="carId"
															title="planner.car"
															key="label"
															options={searchedCars}
															searchFn={searchText => setCarSearchText(searchText)}
															onSelect={value => searchCarSelectHandler(value)}
															onBlur={() => undefined}
															minInputLength={0}
															isLoading={false}
															errorMessage={errors.carId?.message ?? ''}
															initialSelection={
																typeof showEditEvent !== 'undefined'
																	? {
																		value: calendar.car.id,
																		label: calendar.car.name,
																	}
																	: undefined
															}
														/>
													)}

													<Dropdown
														data={
															e.eventItem.type === CalendarEventType.DRIVING_SLIP
																? [
																	{
																		label: 'common.drivingSlip',
																		value: CalendarEventType.DRIVING_SLIP,
																	},
																]
																: eventTypes
														}
														name="type"
														title="common.category"
														innerRef={register}
														required
														errorMessage={errors.type?.message}
														defaultValue={e.eventItem.type}
													/>

													<Input name="title" title="common.title" innerRef={register} required errorMessage={errors.title?.message} defaultValue={e.eventItem.title} />

													<Datepicker
														time
														name="fromDate"
														title="common.start"
														defaultValue={DateTime.fromISO(e.eventItem.timeRange.from + 'Z')
															.toLocal()
															.toISO({
																includeOffset: false,
															})}
														innerRef={register}
														required
														errorMessage={errors.fromDate?.message}
													/>

													<Datepicker
														time
														name="toDate"
														title="common.end"
														defaultValue={DateTime.fromISO(e.eventItem.timeRange.to + 'Z')
															.toLocal()
															.toISO({
																includeOffset: false,
															})}
														innerRef={register}
														required
														errorMessage={errors.toDate?.message}
													/>

													<Datepicker
														name="deadline"
														title="common.deadline"
														defaultValue={formatDateForInput(new Date(e.eventItem.drivingSlip?.deadline ?? ''))}
														innerRef={register}
														errorMessage={errors.deadline?.message}
													/>
													<div className="mt-3 flex flex-row justify-between">
														{typeof showEditEvent !== 'undefined' && (
															<>
																<Button
																	submit
																	text="planner.editEvent"
																	success
																	className="mt-3"
																	loading={'user' in calendar ? updateUserEventLoading || changeUserLoading : updateCarEventLoading || changeCarLoading}
																/>

																<Button
																	text="planner.deleteEvent"
																	danger
																	onClick={() => postCalendarEventDeletion(e.eventItem.referenceId, e.eventItem.drivingSlip?.id)}
																	className="mt-3"
																	loading={'user' in calendar ? deleteUserEventLoading : deleteCarEventLoading}
																/>
															</>
														)}
														{typeof showCopyEvent !== 'undefined' && (
															<Button
																submit
																text="planner.copyEvent"
																success
																className="mt-3"
																loading={'user' in calendar ? updateUserEventLoading || changeUserLoading : updateCarEventLoading || changeCarLoading}
															/>
														)}
													</div>
												</form>
											</div>
										}
									/>

									{e.eventItem.drivingSlip !== null && convertToRequisition && (
										<DrivingSlipRequisitionModal
											visible={convertToRequisition}
											close={() => setConvertToRequisition(false)}
											drivingSlipId={e.eventItem.drivingSlip.id}
											comment={e.eventItem.drivingSlip.comment ?? ''}
											caseId={e.eventItem.drivingSlip.case.id}
										/>
									)}
								</div>
								{numOfDivAfter}
							</>
						);
					})}
				</TimeSlot>,
			);
		}
	};

	renderTimeSlots(rangeStartTime, rangeEndTime, date);

	return <div className="flex h-full w-full flex-row text-xs">{timeSlots}</div>;
};

export default WorkerLine;
