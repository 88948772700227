import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
	CreateCaseRequisition,
	CreateCaseRequisitionVariables,
	GetVendors,
	GetVendors_vendors,
	RequisitionType,
	GetWebRequisitions_requisitions,
	/*GetCatalogs,
	GetCatalogsVariables,*/
	GetWebCase,
	GetVendorsVariables,
	GetWebCase_case,
	DrivingSlipStatus,
	SetWebDrivingSlipStatus,
	SetWebDrivingSlipStatusVariables,
	GetCatalogsWithCraftsmen,
	GetCatalogsWithCraftsmenVariables,
	GetWebCaseVariables,
} from '../../GraphQL';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateRequisitionSchema } from '../../Schemas/CreateRequisitionSchema';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Modal from '@ssg/common/Components/Modal';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Button from '@ssg/common/Components/Button';
import Textarea from '@ssg/common/Components/Textarea';
import Radio from '@ssg/common/Components/Radio';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import InputPhone from '@ssg/common/Components/InputPhone';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');
const GET_VENDORS = loader('src/GraphQL/Requisitions/GetVendors.gql');
const GET_CATALOGS_WITH_CRAFTSMEN = loader('src/GraphQL/Catalogs/GetCatalogsWithCraftsmen.gql');
const CREATE_CASE_REQUISITION = loader('src/GraphQL/Requisitions/CreateCaseRequisition.gql');
const SET_DRIVING_SLIPS = loader('src/GraphQL/DrivingSlips/SetWebDrivingSlipStatus.gql');

interface CreateRequisitionVariables {
	requisitionConnectType: string;
	vendor: string;
	email: string;
	phone: string;
	description: string;
	caseId: string;
	departmentId: string;
	drivingSlipId: string;
	requisitionType: string;
	sendEmail: boolean;
}

interface CraftsmanInfo {
	id: string;
	name: string;
	email: string;
	phone: string;
	remark: string;
	type: string;
	phoneCount: number;
}

interface Props {
	visible: boolean;
	close(): void;
	drivingSlipId: string;
	comment: string;
	caseId: string;
}

const DrivingSlipRequisitionModal: React.FC<Props> = ({ visible, close, drivingSlipId, comment, caseId }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const [selectedCase, setSelectedCase] = React.useState<GetWebCase_case | undefined>(undefined);

	const [vendorSearchText, setVendorSearchText] = React.useState('');
	const [selectedVendor, setSelectedVendor] = React.useState<GetVendors_vendors | undefined>(undefined);
	const [selectedCraftsman, setSelectedCraftsman] = React.useState<CraftsmanInfo | undefined>(undefined);
	const [createdRequisition, setCreatedRequisition] = React.useState<GetWebRequisitions_requisitions | undefined>(undefined);
	const [acceptUnknownDebitor, setAcceptUnknownDebitor] = React.useState(true);

	const [country, setCountry] = React.useState(envVar.defaultCountry);
	const [phonenumber, setPhonenumber] = React.useState('');

	const { data: caseData, loading: loadingCase } = useQuery<GetWebCase>(GET_CASE, {
		variables: {
			id: caseId,
		},
	});

	const { data: vendors } = useQuery<GetVendors, GetVendorsVariables>(GET_VENDORS, {
		variables: {
			company: vendorSearchText,
		},
		skip: vendorSearchText.length === 0,
	});

	const { register, handleSubmit, setValue, control, errors, trigger } = useForm<CreateRequisitionVariables>({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(CreateRequisitionSchema),
		defaultValues: {
			requisitionType: 'SUBCONTRACTOR',
			requisitionConnectType: 'case',
			caseId: caseData?.case.erpNo,
			phone: '',
		},
	});

	const phonenumberHandler = React.useCallback(
		(thisPhonenumber: string): void => {
			const countryCodedPhoneNumber = thisPhonenumber.includes('+45') ? thisPhonenumber : '+45' + thisPhonenumber;
			const number = formatPhoneNumberIntl(countryCodedPhoneNumber).replaceAll(/(\s|-)+/g, '');

			setValue('phone', number, {
				shouldValidate: thisPhonenumber.length > 0,
			});
			setPhonenumber(number);
		},
		[setValue],
	);

	const requisitionType: string = useWatch({
		control,
		name: 'requisitionType',
		defaultValue: 'SUBCONTRACTOR',
	});

	const { data: catalogsData, loading: loadingCatalogsData } = useQuery<GetCatalogsWithCraftsmen, GetCatalogsWithCraftsmenVariables>(GET_CATALOGS_WITH_CRAFTSMEN, {
		variables: {
			address: {
				road: selectedCase?.damage.contact.address.road ?? '',
				houseNumber: selectedCase?.damage.contact.address.houseNumber ?? '',
				addressLineAlt: selectedCase?.damage.contact.address.addressLineAlt ?? '',
				postalCode: selectedCase?.damage.contact.address.postalCode ?? '',
				city: selectedCase?.damage.contact.address.city ?? '',
				country: selectedCase?.damage.contact.address.country ?? '',
			},
		},
		skip: typeof selectedCase === 'undefined' || requisitionType !== 'SUBCONTRACTOR',
	});

	const catalogCraftsmen = React.useMemo(() => catalogsData?.catalogs?.flatMap(c => c.craftsmen) ?? [], [catalogsData?.catalogs]);

	const craftsmenInfo: CraftsmanInfo[] = React.useMemo(
		() =>
			catalogCraftsmen.map(cc => {
				const informations = cc.informations.slice();
				const informationsCount = informations.slice().length;

				if (informations.length === 0) {
					informations.push({ phoneNumber: '', remark: '' });
				}

				return {
					id: cc.id,
					name: cc.contactName,
					email: cc.email ?? '',
					phone: informations[0].phoneNumber,
					remark: informations[0].remark ?? '',
					type: cc.type,
					phoneCount: informationsCount,
				};
			}),
		[catalogCraftsmen],
	);

	React.useEffect(() => {
		if (typeof selectedCraftsman === 'undefined') {
			setValue('email', '', { shouldValidate: false });
			setValue('vendor', '', { shouldValidate: false });
			setVendorSearchText('');
			phonenumberHandler('');
		} else {
			setValue('email', selectedCraftsman.email, {
				shouldValidate: true,
			});
			setValue('vendor', selectedCraftsman.name, {
				shouldValidate: true,
			});
			setVendorSearchText(selectedCraftsman.name);
			phonenumberHandler(selectedCraftsman.phone ?? '');
		}
	}, [phonenumberHandler, selectedCraftsman, setValue]);

	const [setDrivingSlipStatus, { loading: updateDrivingSlipSubmitting }] = useMutation<SetWebDrivingSlipStatus, SetWebDrivingSlipStatusVariables>(SET_DRIVING_SLIPS);
	const [createCaseRequisition, { loading: createCaseRequisitionLoading }] = useMutation<CreateCaseRequisition, CreateCaseRequisitionVariables>(CREATE_CASE_REQUISITION);

	React.useEffect(() => {
		setValue('email', selectedVendor?.email);
		phonenumberHandler(selectedVendor?.phone ?? '');
	}, [phonenumberHandler, selectedVendor, setValue]);

	React.useEffect(() => {
		if (!visible) {
			setCreatedRequisition(undefined);
			setSelectedCraftsman(undefined);
		}
	}, [visible]);

	React.useEffect(() => setSelectedCase(caseData?.case), [caseData?.case]);

	React.useEffect(() => setAcceptUnknownDebitor(!selectedCase?.debitor.unknown), [selectedCase]);

	const updateSingleDrivingSlip = async (): Promise<void> => {
		try {
			await setDrivingSlipStatus({
				variables: {
					id: drivingSlipId,
					status: DrivingSlipStatus.CONVERTED_TO_REQUISITION,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postCreateCaseRequisition = async ({ caseId, requisition, shouldSendEmail }: CreateCaseRequisitionVariables) => {
		await createCaseRequisition({
			variables: {
				caseId,
				requisition,
				shouldSendEmail,
			},
			update: (cache, { data }): void => {
				if (typeof caseData !== 'undefined') {
					if (typeof data === 'undefined' || data === null) {
						return;
					}

					setCreatedRequisition(data.createCaseRequisition);

					const cachedRequest = cache.readQuery<GetWebCase, GetWebCaseVariables>({
						query: GET_CASE,
						variables: {
							id: caseId,
						},
					});

					if (cachedRequest === null) {
						return;
					}

					cache.writeQuery({
						query: GET_CASE,
						variables: {
							id: caseId,
						},
						data: {
							case: {
								...cachedRequest.case,
								requisitions: [...cachedRequest.case.requisitions, data.createCaseRequisition],
							},
						},
					});
				}
			},
		});
	};

	const onSubmit = async (data: CreateRequisitionVariables) => {
		try {
			await postCreateCaseRequisition({
				caseId: caseId,
				requisition: {
					description: data.description,
					type: data.requisitionType as RequisitionType,
					vendorName: data.vendor,
					vendorEmail: data.email,
					vendorNo: selectedVendor?.erpReferenceNo,
					vendorPhoneNumber: data.phone,
				},
				shouldSendEmail: data.sendEmail,
			});

			updateSingleDrivingSlip();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="planner.convertToRequisition"
			visible={visible}
			close={close}
			body={
				<>
					{typeof createdRequisition === 'undefined' ? (
						<form onSubmit={handleSubmit(onSubmit)}>
							<Radio
								name="requisitionType"
								title="common.type"
								options={[
									{
										label: 'common.subcontractor',
										value: 'SUBCONTRACTOR',
									},
									{
										label: 'common.materiale',
										value: 'MATERIAL',
									},
								]}
								dValue={'SUBCONTRACTOR'}
								innerRef={register}
							/>

							<Radio
								name="requisitionConnectType"
								title="requisitions.requisitionTo"
								options={[
									{ label: 'common.case', value: 'case' },
									{
										label: 'common.department',
										value: 'department',
									},
								]}
								dValue={'case'}
								innerRef={register}
								disabled={typeof caseData !== 'undefined'}
							/>

							<Input
								title="common.case"
								name="caseId"
								required
								innerRef={register}
								errorMessage={errors.caseId?.message}
								className="w-full bg-gray-300 lg:w-full"
								defaultValue={caseData?.case.erpNo}
								readOnly
							/>

							{selectedCase?.debitor.unknown && (
								<div className="border-1 border-blue rounded-b-default bg-blue z-50 -mt-1 border-t-0 bg-opacity-95 p-1">
									<p className="font-medium text-white">{t('requisitions.caseNoDebitor')}</p>
									<Checkbox
										name="createAnyway"
										title="requisitions.acceptNoDebitor"
										className="text-sm text-white"
										defaultChecked={false}
										onChange={e => setAcceptUnknownDebitor(e.currentTarget.checked)}
										textClassName="text-white"
									/>
								</div>
							)}

							{requisitionType === 'SUBCONTRACTOR' && (
								<div>
									<FormFieldHeader title="requisitions.catalogSuggestions" />
									{loadingCatalogsData ? (
										<FontAwesomeIcon icon={faSpinner} className="text-blue animate-spin" size="lg" />
									) : (
										<>
											{craftsmenInfo?.length > 0 ? (
												<Dropdown
													title=""
													name=""
													data={[
														{
															label: `${craftsmenInfo.length.toString()} ${t('requisitions.options')}`,
															value: '',
														},
														...craftsmenInfo.map(ci => ({
															label: `${ci.name} - ${ci.type} (${ci.phoneCount} ${t('common.tlf')})`,
															value: ci.id,
														})),
													]}
													onChange={e =>
														setSelectedCraftsman(selectedCraftsman?.id !== e.currentTarget.value ? craftsmenInfo.find(ci => ci.id === e.currentTarget.value) : undefined)
													}
												/>
											) : (
												<div>{t('common.none')}</div>
											)}
										</>
									)}
								</div>
							)}

							<SearchableSelect
								name="vendor"
								title="common.subcontractor"
								searchFn={searchText => {
									setVendorSearchText(searchText);
									setValue('vendor', searchText);
								}}
								onSelect={value => {
									setSelectedVendor(vendors?.vendors.find(v => v.erpReferenceNo === value));
									setValue('vendor', vendors?.vendors.find(v => v.erpReferenceNo === value)?.company ?? '', { shouldValidate: true });
								}}
								onBlur={() => undefined}
								minInputLength={2}
								options={
									vendors?.vendors.map(v => ({
										label: v.company ?? '',
										value: v.erpReferenceNo,
									})) ?? []
								}
								isLoading={loadingCase}
								control={control}
								required
								errorMessage={errors.vendor?.message}
								readOnly={typeof selectedCraftsman !== 'undefined'}
							/>

							<InputPhone
								title="requisitions.vendorPhone"
								innerRef={register}
								required
								name="phone"
								errorMessage={errors.phone?.message}
								control={control}
								trigger={trigger}
								searchFn={(number): void => phonenumberHandler(number)}
								onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
								defaultCountry={country}
								defaultPhoneNumber={phonenumber}
							/>

							<Input title="requisitions.vendorEmail" name="email" required innerRef={register} errorMessage={errors.email?.message} className="w-full lg:w-full " />

							<Checkbox name="sendEmail" title="requisitions.sendEmail" innerRef={register} defaultChecked={true} className="mt-2" />

							<Textarea
								title="common.description"
								name="description"
								required
								innerRef={register}
								errorMessage={errors.description?.message}
								className="h-32 w-full lg:w-full"
								defaultValue={comment}
							/>

							<div className="mt-2 flex justify-between">
								<Button
									text="common.convert"
									success
									submit
									disabled={!acceptUnknownDebitor || !control.formState.isValid}
									loading={createCaseRequisitionLoading || updateDrivingSlipSubmitting}
								/>

								<Button danger text="common.cancel" onClick={() => close()} className="mt-4" />
							</div>
						</form>
					) : (
						<div className="text-blue text-center">
							<p className="text-green text-lg font-medium">{t('requisitions.created')}!</p>
							<p>{t('requisitions.requisitionId')}:</p>
							<p className="text-2xl font-medium">{createdRequisition.orderNumber}</p>
						</div>
					)}
				</>
			}
		/>
	);
};

export default DrivingSlipRequisitionModal;
