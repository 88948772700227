import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const DamageAddressSchema = yup.object().shape({
	damageCountry: yup.string().required(),

	damagePhone: yup.string().required(),

	damageName: yup.string().required(),

	damageAttention: yup.string(),

	damageRoad: yup.string().required(),

	damageHouseNumber: yup.string().required(),

	damageFloor: yup.string(),

	damageAddress2: yup.string(),

	damageZip: yup.string().required(),

	damageCity: yup.string().required(),

	damageEmail: yup.string().when('noEmail', {
		is: (noEmail: boolean) => noEmail,
		then: yup.string().email(),
		otherwise: yup.string().email().required(),
	}),

	noEmail: yup.boolean(),

	accessConditions: yup.string(),
});
