import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelope, faPhone, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { GetSingleCase_case, GetWebCase_case_damage_contacts, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { useMutation } from '@apollo/client';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { removeTypename } from '../../helper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Box from '../../Components/Layout/Box';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import DamageContactModal from '../CaseCreation/DamageContactModal';
import TextButton from '@ssg/common/Components/TextButton';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const DamageContactBox: React.FC<Props> = ({ caseData, caseViewExternal }) => {
	const [editActive, setEditActive] = React.useState(false);
	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);
	const [updateContact, setUpdateContact] = React.useState(false);
	const [createContact, setCreateContact] = React.useState(false);
	const [deleteContact, setDeleteContact] = React.useState(false);
	const [contacts, setContacts] = React.useState<GetWebCase_case_damage_contacts[]>(caseData?.damage.contacts ?? []);
	const [contact, setContact] = React.useState<GetWebCase_case_damage_contacts>();
	const [contactIndex, setContactIndex] = React.useState<number | undefined>(undefined);

	React.useEffect(() => {
		if (typeof contactIndex !== 'undefined') {
			setContact(contacts[contactIndex]);
		}
		if (typeof contactIndex === 'undefined') {
			setContact(undefined);
		}
	}, [contactIndex, contacts]);

	const onSubmit = async () => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				damage: {
					category: caseData.damage.category.id,
					cause: caseData.damage.cause.id,
					contact: {
						address: {
							road: caseData.damage.contact.address.road,
							houseNumber: caseData.damage.contact.address.houseNumber,
							floor: caseData.damage.contact.address.floor,
							addressLineAlt: caseData.damage.contact.address.addressLineAlt,
							city: caseData.damage.contact.address.city,
							postalCode: caseData.damage.contact.address.postalCode,
							country: caseData.damage.contact.address.country,
						},
						email: caseData.damage.contact.email,
						name: caseData.damage.contact.name,
						phone: caseData.damage.contact.phone,
					},
					date: caseData.damage.date,
					description: caseData.damage.description,
					businessArea: caseData.damage.businessArea.id,
					contacts: removeTypename(contacts),
				},
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, damage }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id,
				damage,
			},
		});
	};

	return (
		<>
			<Box
				title="case.damageContact.contacts"
				full
				icon={caseViewExternal ? undefined : faEdit}
				onClick={() => {
					setEditActive(true);
					setContacts(caseData?.damage.contacts ?? []);
				}}
				className="mt-8"
			>
				<hr />
				<Table
					data={caseData.damage.contacts}
					columns={[
						{
							label: 'common.name',
							noTruncate: true,
							selectFn: c => <p>{c.name}</p>,
							sortFn: (a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''),
						},
						{
							label: 'common.role',
							noTruncate: true,
							selectFn: c => <p>{c.role}</p>,
							sortFn: (a, b) => (a?.role ?? '').localeCompare(b?.role ?? ''),
						},
						{
							label: 'common.phone',
							selectFn: c => (
								<p>
									{c.phone && (
										<a href={`tel:${c.phone ?? ''}`}>
											<FontAwesomeIcon icon={faPhone} />
											{` ${formatPhoneNumberIntl(c.phone ?? '')}`}
										</a>
									)}
								</p>
							),
						},
						{
							label: 'catalog.contact.email',
							selectFn: c => (
								<p>
									{c?.email && (
										<a href={`mailto:${c.email}`}>
											<FontAwesomeIcon icon={faEnvelope} />
											{` ${c.email}`}
										</a>
									)}
								</p>
							),
							sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
						},
					]}
					keySelector={c => `${c.name}-${c.role}-${c.phone}-${c.email}`}
					noDataFoundText={'case.damageContact.NoContacts'}
				/>
			</Box>
			<Modal
				title="common.editInjuredParty"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.LARGE}
				body={
					<div>
						<div>
							<form>
								<div className="text-blue-calm flex justify-end">
									<TextButton text="case.damageContact.addContact" icon={faPlus} className="mt-7" onClick={() => setCreateContact(true)} />
								</div>
								<Table
									data={contacts}
									columns={[
										{
											label: 'common.name',
											noTruncate: true,
											selectFn: c => <p>{c.name}</p>,
											sortFn: (a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''),
										},
										{
											label: 'common.role',
											noTruncate: true,
											selectFn: c => <p>{c.role}</p>,
											sortFn: (a, b) => (a?.role ?? '').localeCompare(b?.role ?? ''),
										},
										{
											label: 'common.phone',
											selectFn: c => (
												<p>
													{c.phone && (
														<a href={`tel:${c.phone ?? ''}`}>
															<FontAwesomeIcon icon={faPhone} />
															{` ${formatPhoneNumberIntl(c.phone ?? '')}`}
														</a>
													)}
												</p>
											),
										},
										{
											label: 'catalog.contact.email',
											selectFn: c => (
												<p>
													{c?.email && (
														<a href={`mailto:${c.email}`}>
															<FontAwesomeIcon icon={faEnvelope} />
															{` ${c.email}`}
														</a>
													)}
												</p>
											),
											sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
										},
										{
											label: 'common.edit',
											classNameTh: 'text-right',
											selectFn: (_, i) => (
												<div className="flex content-start justify-end text-right">
													<FontAwesomeIcon
														icon={faEdit}
														size="lg"
														onClick={() => {
															setContactIndex(i);
															setUpdateContact(true);
														}}
														className="cursor-pointer"
													/>
												</div>
											),
										},
										{
											label: 'common.remove',
											classNameTh: 'text-right',
											selectFn: (_, i) => (
												<div className="text-red flex content-start justify-end text-right">
													<FontAwesomeIcon
														icon={faTrashAlt}
														size="lg"
														onClick={() => {
															setContactIndex(i);
															setDeleteContact(true);
														}}
														className="cursor-pointer"
													/>
												</div>
											),
										},
									]}
									keySelector={c => `${c.name}-${c.role}-${c.phone}-${c.email}`}
									noDataFoundText={'case.damageContact.NoContacts'}
								/>

								<div className="mt-3">
									<Button success text="common.editInjuredParty" onClick={() => onSubmit()} loading={updateCaseSubmitting} />
								</div>
							</form>
						</div>
						{/* Damage Contact Modal */}
						<div>
							{createContact && (
								<DamageContactModal
									setContacts={setContacts}
									open={createContact}
									close={() => {
										setCreateContact(false);
										setContact(undefined);
									}}
									contactIndex={undefined}
								/>
							)}

							{updateContact && typeof contactIndex !== 'undefined' && typeof contact !== 'undefined' && (
								<DamageContactModal
									setContacts={setContacts}
									open={updateContact}
									data={contact}
									edit
									close={() => {
										setUpdateContact(false);
										setContact(undefined);
										setContactIndex(undefined);
									}}
									contactIndex={contactIndex}
								/>
							)}

							{deleteContact && typeof contactIndex !== 'undefined' && typeof contact !== 'undefined' && (
								<DamageContactModal
									setContacts={setContacts}
									open={deleteContact}
									data={contact}
									erase
									close={() => {
										setDeleteContact(false);
										setContact(undefined);
										setContactIndex(undefined);
									}}
									contactIndex={contactIndex}
								/>
							)}
						</div>
					</div>
				}
			/>
		</>
	);
};

export default DamageContactBox;
