import React from 'react';
import classnames from 'classnames';

interface Props {
	onClick?: () => unknown;
	className?: string;
}

const Pill: React.FC<Props> = ({ onClick, className, children }) => (
	<button
		onClick={onClick}
		className={classnames(
			'bg-blue border-blue bg-blue hover:bg-blue-light rounded-full py-1 px-2 font-semibold text-white no-underline shadow-md hover:text-white focus:outline-none active:shadow-none',
			className,
		)}
	>
		{typeof onClick !== 'undefined' && <span className="mr-2 align-text-bottom font-bold">&times;</span>}
		{children}
	</button>
);
export default Pill;
