import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import useSaveFile from '@ssg/common/Hooks/useSaveFile';
import TextButton from '@ssg/common/Components/TextButton';
import { useTranslation } from 'react-i18next';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import { GetMovablesOverviewExcel, GetMovablesOverviewExcelVariables } from '../GraphQL';

interface Props {
	caseId: string;
	erpNo: string;
}

const GET_OVERVIEW = loader('src/GraphQL/Movables/GetMovablesOverviewExcel.gql');

const MovablesOverviewDownload: React.FC<Props> = ({ caseId, erpNo }) => {
	const { t } = useTranslation();

	const [fetchData, { data, loading }] = useLazyQuery<GetMovablesOverviewExcel, GetMovablesOverviewExcelVariables>(GET_OVERVIEW, { variables: { caseId, includeCompleted: false } });

	useSaveFile(data?.movableExcelOverview, `${t('case.movableOverview')} ${erpNo}.xlsx`);

	return <TextButton text="case.exportMovableOverview" icon={faArchive} onClick={() => fetchData()} loading={loading} disabled={loading} />;
};
export default MovablesOverviewDownload;
