import React from 'react';
import { VehiclesFilter, usePlannerAdminData } from './PlannerHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/pro-regular-svg-icons';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Input from '@ssg/common/Components/Input';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';

import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	activeVehiclesFilter: VehiclesFilter;
	setActiveVehiclesFilter: React.Dispatch<React.SetStateAction<VehiclesFilter>>
	userVehiclesFilters: VehiclesFilter[];
	activeUserFilterName: string;
	setActiveUserVehiclesFilter: React.Dispatch<React.SetStateAction<string>>;
	resetFilters(): void;
	onFilterSave(): Promise<void>;
	handleDeleteUserFilter(name: string): Promise<void>;
	setFilterName: React.Dispatch<React.SetStateAction<string>>;
	filterName:string;
	
}

const VehicleFilters: React.FC<Props> = ({ activeVehiclesFilter, setSearchTerm, setActiveVehiclesFilter, userVehiclesFilters, activeUserFilterName, 
	setActiveUserVehiclesFilter, resetFilters, onFilterSave, handleDeleteUserFilter,setFilterName ,filterName}): React.ReactElement => {
		
	const { t } = useTranslation();
	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	//const [filterSaveName, setFilterSaveName] = React.useState(activeVehiclesFilter.name ?? '');
	const { adminData } = usePlannerAdminData();
	const [showFilters, setShowFilters] = React.useState(false);

	const [departments, locations, drivers] = React.useMemo(() => {
		if (typeof adminData === 'undefined') {
			return [[], [], [], []];
		}

		const departments = adminData.departments
			.filter((d, i, a) => a.findIndex(ad => ad.id === d.id) === i)
			.map(d => ({
				value: d.departmentNumber.toString(),
				label: `${d.name} (${d.departmentNumber})`,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		const locations = adminData.locations
			.filter((l, i, a) => a.findIndex(al => al.id === l.id) === i)
			.map(l => ({ value: l.name, label: l.name }))
			.sort((a, b) => a.label.localeCompare(b.label));


		const drivers = adminData.usersWithCalendars.map(d => ({
			value: d.id,
			label: d.name,
		}));

		return [departments, locations, drivers];
	}, [adminData]);

	return (
		<div className="w-full">
			<div className="relative my-3 w-full">
				<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
				<Input
					name="overview-search"
					placeholder={t('planner.vehicleSearchPlaceholder')}
					className="pr-8 text-sm"
					style={{ height: '36px' }}
					onChange={(e): void => setSearchTerm(e.target.value)}
				/>
			</div>
			<div className="order-2 lg:order-3">
				<label className="text-blue mb-1  text-xs">{t('common.quickFilter')}</label>
				<div className="flex flex-wrap justify-between">
					<Dropdown
						name="drivingslips-quick-filters"
						onChange={e => {
							setActiveUserVehiclesFilter(e.target.value);
							if (e.target.value === '') {
								resetFilters();
							} else {
								const filter = userVehiclesFilters.find(f => f.name === e.target.value);
								if (filter) {
									setActiveVehiclesFilter(filter);
								}
							}
						}}
						data={[
							{
								value: '',
								label: t('caseOverview.filters.pickFilter'),
							},
							...userVehiclesFilters.map(f => ({
								value: f.name ?? '',
								label: f.name ?? '',
							})) ?? [],
						]}
						className="flex-grow-1 mb-3 w-full pr-8 lg:w-auto"
						style={{ maxWidth: '300px', height: 34 }}
						value={activeUserFilterName}
					/>

					<div className="mb-3 flex flex-wrap">
						<Button
							secondary={activeVehiclesFilter.name !== '' || typeof activeVehiclesFilter.name !== 'undefined'}
							disabled={activeVehiclesFilter.name === '' || typeof activeVehiclesFilter.name == 'undefined' || activeVehiclesFilter.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="px-5 py-2 text-xs"
							style={{ height: 34 }}
						/>

						<Button
							primary
							text={activeVehiclesFilter.name === '' || typeof activeVehiclesFilter.name == 'undefined' || activeVehiclesFilter.name == null ? t('caseOverview.filters.saveFilter') : t('caseOverview.filters.updateFilter')}
							onClick={activeVehiclesFilter.name === '' || typeof activeVehiclesFilter.name == 'undefined' || activeVehiclesFilter.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
							className="ml-3 px-5 py-2 text-xs"
							style={{ height: 34 }}
						/>
					</div>
				</div>
			</div>
			{showFilters && (
				<>
					<div className="flex w-full">
						<div className="flex-1">
							<label className="text-blue mb-1 text-xs font-medium w-40">
								{t('common.location')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeVehiclesFilter.locations?.map(v => {
											const l = locations.find(location => location.value === v);
											return {
												value: l?.value ?? '',
												label: l?.value ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveVehiclesFilter(currentFilters => ({
											...currentFilters,
											locations: values?.map(v => v.value) ?? [],
										}))
									}
									options={locations}
								/>
							</label>
						</div>
						<div className="flex-1">
							<label className="text-blue mb-1 text-xs font-medium w-40">
								{t('common.department')}
								<Select
									placeholder=""
									isClearable
									isMulti
									closeMenuOnSelect={false}
									menuPortalTarget={document.body}
									className="react-select-custom w-40 text-xs"
									value={
										activeVehiclesFilter.departments?.map(v => {
											const l = departments.find(department => department.value === v);

											return {
												value: l?.value ?? '',
												label: l?.label ?? '',
											};
										}) ?? []
									}
									styles={{
										menuPortal: base => ({ ...base, zIndex: 6667 }),
									}}
									onChange={values =>
										setActiveVehiclesFilter(currentFilters => ({
											...currentFilters,
											departments: values?.map(v => v.value) ?? [],
										}))
									}
									options={departments}
								/>
							</label>
						</div>
					</div>
					<div className="flex justify-between mt-2">
						<Button secondary text={t('common.resetFilter')} className="px-5 py-2 text-xs" onClick={resetFilters} />
					</div>
				</>
			)}
			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								onFilterSave();
								setSaveFilterActive(false);
							}}
							disabled={filterName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeVehiclesFilter.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeVehiclesFilter?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
			<div className="mt-1 flex items-center justify-between text-xs font-medium">
				<TextButton
					text={showFilters ? 'common.hideFilters' : 'common.showFilters'}
					icon={showFilters ? faChevronUp : faChevronDown}
					onClick={() => setShowFilters(!showFilters)}
				/>
			</div>

			<hr className="my-2 text-blue" />
		</div>
	);
};

export default VehicleFilters;