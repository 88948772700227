import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { RootObject } from '../Catalog/CatalogCreation/DAWATypes';

export interface FullAddressObject {
	data: FullAddres;
	forslagstekst: string;
}
interface FullAddres {
	id: string;
	road: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	floor: string;
	door?: string;
}

export async function addressRequest(addressValue: string): Promise<SelectOption[]> {
	const url = `https://api.dataforsyningen.dk/autocomplete?startfra=adresse&q=${addressValue}&husnr=1`;

	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: '*/*',
		},
	};
	const json: SelectOption[] = [];
	try {
		const res = await fetch(url, requestOptions);
		const resJson: RootObject[] = await res.json();
		const toAddressObject: SelectOption[] = resJson.map(r => ({
			label: r.data.vejnavn,
			value: r.data.vejnavn,
		}));
		json.push(...toAddressObject);

		json.push({ label: addressValue, value: addressValue });
	} catch {
		json.push({ label: addressValue, value: addressValue });
	}

	return getUniqueAddresses(json);
}

export async function fullAddressRequest(addressValue: string, skipSelf?: true): Promise<FullAddressObject[]> {
	const url = `https://api.dataforsyningen.dk/autocomplete?startfra=adresse&q=${addressValue}`;
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: '*/*',
		},
	};
	const json: FullAddressObject[] = [];
	try {
		const res = await fetch(url, requestOptions);
		const resJson: RootObject[] = await res.json();
		const toAddressObject: FullAddressObject[] = resJson.map(r => ({
			forslagstekst: r.forslagstekst,
			data: {
				road: r.data.vejnavn,
				door: r.data.dør,
				floor: r.data.etage,
				houseNumber: r.data.husnr,
				id: r.data.id,
				postalCode: r.data.postnr,
				city: r.data.postnrnavn,
			},
		}));
		json.push(...toAddressObject);
		if (!skipSelf) {
			json.push({
				forslagstekst: addressValue,
				data: {
					road: addressValue,
					door: '',
					floor: '',
					houseNumber: '',
					id: addressValue,
					postalCode: '',
					city: '',
				},
			});
		}
	} catch {
		json.push({
			forslagstekst: addressValue,
			data: {
				road: addressValue,
				door: '',
				floor: '',
				houseNumber: '',
				id: addressValue,
				postalCode: '',
				city: '',
			},
		});
	}

	return json;
}

const getUniqueAddresses = (items: SelectOption[]) => {
	const emptyArray: SelectOption[] = [];
	return items.reduce((accumulator, current) => {
		if (!accumulator.some(x => x.value === current.value)) {
			accumulator.push(current);
		}
		return accumulator;
	}, emptyArray);
};

