import React from 'react';

interface Props {
	address: {
		road: string;
		houseNumber: string;
		postalCode: string;
		city: string;
	};
}

const GoogleMapsLink: React.FC<Props> = ({ address, children }): React.ReactElement => {
	return (
		<a href={`https://www.google.com/maps/search/?api=1&query=${address.road}+${address.houseNumber},+${address.postalCode}+${address.city}`} target="_blank" rel="noreferrer">
			{children}
		</a>
	);
};

export default GoogleMapsLink;
