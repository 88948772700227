import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { DebitorType, GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { caseViewPolicyHolderSchema } from '../../Schemas/CaseViewPolicyHolderSchema';
import { IPolicyHolderBox } from '../../Schemas/ICaseCreation';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Radio from '@ssg/common/Components/Radio';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import { countries } from '@ssg/common/Helpers/countries';
import Checkbox from '@ssg/common/Components/Checkbox';
import { postalCodeToCity } from '@ssg/common/Helpers/postalCodes';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

interface PolicyHolderBoxCustom {
	PolicyHolderBox: IPolicyHolderBox;
	debitorType: DebitorType;
	requisitionerInsuranceExcesses: number;
	requisitionerInsuranceNumber: string;
	CVR: string;
}

const debitorTypes = enumToSelectOptions(DebitorType);

const CustomPolicyHolderBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const [editActive, setEditActive] = React.useState(false);
	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === caseData.policyHolder.address.country)?.label || '');
	const [phonenumber, setPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.policyHolder.phone ?? ''));
	const [country, setCountry] = React.useState<string | undefined>('DK');

	const phonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('PolicyHolderBox.policyPhone', phonenumber, {
			shouldValidate: true,
		});
		setPhonenumber(phonenumber);
	};

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const policyHolderFull: SelectOption[] = [
		{ value: caseData.policyHolder.name, label: 'case.policyHolder' },
		{
			value: caseData.policyHolder.address.road + ' ' + caseData.policyHolder.address.houseNumber + ' ' + (caseData.policyHolder.address.floor ?? ''),
			label: 'common.address',
		},
		{
			value: caseData.policyHolder.address.addressLineAlt ?? '',
			label: '',
		},
		{
			value: caseData.policyHolder.address.postalCode + ' ' + caseData.policyHolder.address.city,
			label: 'common.zipAndCity',
		},
		{
			value: countries.find(c => c.value === caseData.policyHolder.address.country)?.label ?? '',
			label: '',
		},
		{ value: caseData.policyHolder.phone ?? '', label: 'common.tel' },
		{ value: caseData.policyHolder.email ?? '', label: 'common.mail' },
		{
			value: caseData.debitor.policeNumber,
			label: 'case.policyOrClaimNumber',
		},
		{
			value: caseData.debitor.cvrNumber,
			label: 'case.companyRegistrationNumber',
		},
		{
			value: caseData.debitor.excess !== null ? (caseData.debitor.excess as number).toString() + t('common.localCurrency') : '',
			label: 'case.excess',
		},
		{ value: caseData.debitor.type, label: 'case.customerType' },
	];

	const { handleSubmit, errors, register, control, setValue, trigger } = useForm<PolicyHolderBoxCustom>({
		resolver: yupResolver(caseViewPolicyHolderSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			PolicyHolderBox: {
				policyCountry: caseData.policyHolder.address.country ?? '',
				policyPhone: caseData.policyHolder.phone ?? '',
			},
		},
	});

	const countrySelectHandler = (value: string): void => {
		setValue('PolicyHolderBox.policyCountry', value, {
			shouldValidate: true,
		});
	};

	const noEmail = useWatch({
		control,
		name: 'PolicyHolderBox.noEmail',
		defaultValue: false,
	});

	React.useEffect(() => {
		if (noEmail) {
			setValue('PolicyHolderBox.policyEmail', '', {
				shouldValidate: true,
			});
		}
	}, [noEmail, setValue]);

	const onSubmit = async (data: PolicyHolderBoxCustom) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				debitor: {
					debitorId: caseData.debitor.debitorId ?? '',
					company: caseData.debitor.company,
					cvrNumber: data.CVR,
					policeNumber: data.requisitionerInsuranceNumber,
					type: data.debitorType as DebitorType,
					attention: caseData.debitor.attention,
					billingAddress: caseData.debitor.billingAddress && {
						road: caseData.debitor.billingAddress.road,
						houseNumber: caseData.debitor.billingAddress.houseNumber,
						floor: caseData.debitor.billingAddress.floor,
						addressLineAlt: caseData.debitor.billingAddress.addressLineAlt,
						city: caseData.debitor.billingAddress.city,
						country: caseData.debitor.billingAddress.country,
						postalCode: caseData.debitor.billingAddress.postalCode,
					},
					excess: data.requisitionerInsuranceExcesses,
				},
				policyHolder: {
					email: data.PolicyHolderBox.noEmail ? '' : data.PolicyHolderBox.policyEmail,
					name: data.PolicyHolderBox.policyName,
					phone: data.PolicyHolderBox.policyPhone,
					address: {
						road: data.PolicyHolderBox.policyRoad,
						houseNumber: data.PolicyHolderBox.policyHouseNumber,
						floor: data.PolicyHolderBox.policyFloor,
						addressLineAlt: data.PolicyHolderBox.policyAddress2,
						city: data.PolicyHolderBox.policyCity,
						country: data.PolicyHolderBox.policyCountry,
						postalCode: data.PolicyHolderBox.policyZip,
					},
				},
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
		//TODO
	};

	const postCaseUpdate = async ({ id, debitor, policyHolder }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				debitor: debitor,
				policyHolder: policyHolder,
			},
		});
	};
	return (
		<>
			<Box title="case.policyHolder" className="mb-8" full onClick={() => setEditActive(true)} icon={caseViewExternal ? undefined : faEdit}>
				<hr />
				<BoxFormDataViewer data={policyHolderFull} keyId="case.policyHolder" />
			</Box>
			<Modal
				title="case.editPolicyHolder"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.MEDIUM}
				body={
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
						<div className="w-1/2 pr-2">
							<Input
								title="common.name"
								innerRef={register}
								name="PolicyHolderBox.policyName"
								errorMessage={errors.PolicyHolderBox?.policyName?.message ?? ''}
								defaultValue={caseData.policyHolder.name}
							/>

							{/* <Input 
                                title="common.attention"
                                innerRef={register}
                                name="PolicyHolderBox.policyAttention"
                                errorMessage={errors.PolicyHolderBox?.policyAttention?.message ?? ''}
                                defaultValue={"Missing in Backend"}
                            /> */}

							<div className="flex w-full">
								<div className="w-3/4">
									<Input
										title="common.road"
										innerRef={register}
										name="PolicyHolderBox.policyRoad"
										errorMessage={errors.PolicyHolderBox?.policyRoad?.message ?? ''}
										defaultValue={caseData.policyHolder.address.road}
									/>
								</div>
								<div className="ml-4 w-1/4">
									<Input
										title="common.houseNumber"
										innerRef={register}
										name="PolicyHolderBox.policyHouseNumber"
										errorMessage={errors.PolicyHolderBox?.policyHouseNumber?.message ?? ''}
										defaultValue={caseData.policyHolder.address.houseNumber}
									/>
								</div>
								<div className="ml-4 w-1/4">
									<Input
										title="common.floor"
										innerRef={register}
										name="PolicyHolderBox.policyFloor"
										errorMessage={errors.PolicyHolderBox?.policyFloor?.message ?? ''}
										defaultValue={caseData.policyHolder.address.floor ?? ''}
									/>
								</div>
							</div>
							<Input
								title="common.addressLine2"
								innerRef={register}
								name="PolicyHolderBox.policyAddress2"
								errorMessage={errors.PolicyHolderBox?.policyAddress2?.message ?? ''}
								defaultValue={caseData.policyHolder.address.addressLineAlt ?? ''}
							/>

							<div className="flex w-full flex-row">
								<div className="w-28">
									<Input
										title="common.postalcode"
										innerRef={register}
										name="PolicyHolderBox.policyZip"
										errorMessage={errors.PolicyHolderBox?.policyZip?.message ?? ''}
										defaultValue={caseData.policyHolder.address.postalCode}
										onChange={async e => {
											const pc = e.currentTarget.value;
											const city = await postalCodeToCity(country ?? envVar.defaultCountry ?? '', pc);
											if (typeof city !== 'undefined') {
												setValue('PolicyHolderBox.policyCity', city);
											} else {
												setValue('PolicyHolderBox.policyCity', undefined);
											}
										}}
									/>
								</div>
								<div className="flex-grow-default ml-4">
									<Input
										title="common.city"
										innerRef={register}
										name="PolicyHolderBox.policyCity"
										errorMessage={errors.PolicyHolderBox?.policyCity?.message ?? ''}
										className="w-full md:w-full lg:w-full"
										defaultValue={caseData.policyHolder.address.city}
									/>
								</div>
							</div>

							<SearchableSelect
								key="country"
								control={control}
								name="PolicyHolderBox.policyCountry"
								title="common.country"
								required
								options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
								searchFn={(searchText): void => setCountrySearchText(searchText)}
								onSelect={(value): void => countrySelectHandler(value)}
								onBlur={(): void => undefined}
								minInputLength={1}
								isLoading={false}
								errorMessage={errors.PolicyHolderBox?.policyCountry?.message ?? ''}
								initialSelection={countries.find(i => i.value === caseData.policyHolder.address.country)}
							/>

							<InputPhone
								title="common.phone"
								innerRef={register}
								name="PolicyHolderBox.policyPhone"
								errorMessage={errors.PolicyHolderBox?.policyPhone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
								defaultCountry={country}
								defaultPhoneNumber={phonenumber}
								className="w-full md:w-full lg:w-full"
							/>

							<Input
								title="common.email"
								innerRef={register}
								name="PolicyHolderBox.policyEmail"
								errorMessage={errors.PolicyHolderBox?.policyEmail?.message ?? ''}
								defaultValue={caseData.policyHolder.email}
								disabled={noEmail}
							/>

							<Checkbox
								title="case.noEmail"
								name="PolicyHolderBox.noEmail"
								className="mt-1"
								innerRef={register}
								onChange={() => {
									trigger('PolicyHolderBox.policyEmail');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.policyHolder.email === ''}
							/>

							<div className="mt-3">
								<Button submit success text="case.editPolicyHolder" loading={updateCaseSubmitting} />
							</div>
						</div>
						<div className="w-1/2 pl-2">
							<Input
								title="case.excess"
								name="requisitionerInsuranceExcesses"
								type="number"
								innerRef={register}
								errorMessage={errors.requisitionerInsuranceExcesses?.message ?? ''}
								defaultValue={caseData.debitor.excess ?? undefined}
								unit="common.localCurrency"
							/>

							<Input
								title="case.policyOrClaimNumber"
								name="requisitionerInsuranceNumber"
								innerRef={register}
								errorMessage={errors.requisitionerInsuranceNumber?.message ?? ''}
								defaultValue={caseData.debitor.policeNumber}
							/>

							<Input title="case.companyRegistrationNumber" name="CVR" innerRef={register} errorMessage={errors.CVR?.message ?? ''} defaultValue={caseData.debitor.cvrNumber} />

							<Radio
								title="case.customerType"
								options={debitorTypes}
								name="debitorType"
								innerRef={register}
								errorMessage={errors.debitorType?.message ?? ''}
								className="w-full pb-2 lg:w-full"
								dValue={caseData.debitor.type}
							/>
						</div>
					</form>
				}
			/>
		</>
	);
};

export default CustomPolicyHolderBox;
