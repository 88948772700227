import React from 'react';
import { Maybe, CalendarEventInput, CalendarEventType, Permissions, DeleteDrivingSlipMutationVariables, DrivingSlipStatus, GetCaseAdminDataPlannerQuery, GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables, GetCalendarsDocument, GetCalendarsQuery, GetCalendarsQueryVariables, UpdateWebDrivingSlipNewPlannerMutationVariables, useDeleteDrivingSlipMutation, useGetCaseAdminDataPlannerQuery, useGetCalendarsQuery, useUpdateWebDrivingSlipNewPlannerMutation, DrivingSlipNewPlannerFragment, useCreateCalendarEventMutation, useUpdateCalendarEventMutation, useChangeUserOnEventMutation, DeleteUserCalendarEventMutationVariables, useDeleteCalendarEventMutation, GetDrivingSlipsNewPlannerDocument, useSetDrivingSlipStatusNewPlannerMutation, useRemoveCarFromDrivingSlipMutation, useRemoveMaterialFromDrivingSlipMutation, DrivingSlip, useAddCarOrMaterialsMutation, AddCarOrMaterialsMutationVariables, GetVehiclesQueryVariables, VehicleFragment, VehicleFragmentDoc, GetVehiclesQuery, GetVehiclesDocument, useCreateDrivingSlipNewPlannerMutation, CreateDrivingSlipNewPlannerMutationVariables, User } from '@ssg/common/GraphQL/indexV2';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import UserContext from '../../UserContext';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import PlannerContext from './PlannerContext';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';
import { DateTime } from 'luxon';

export interface SelectedUser {
	id: string;
	color: string;
}

export type VehiclesFilter = {
	name?: string | null;
	locations: string[] | undefined;
	//type: string[] | undefined;
	departments: string[] | undefined;
}

export enum DragItemTypes {
	DRIVING_SLIP = 'draggableDrivingSlip',
	EVENT_ITEM = 'draggableEventItem',
	CAR = 'draggableCarItem',
	MATERIAL = 'draggableMaterialItem'
}
type PartialDriver = Maybe<Pick<User, 'id' | 'name'>>;
export type Bookings = (Pick<DrivingSlip, 'id' | 'start' | 'driver'> & { driver?: PartialDriver })[];
export interface DateBooking {
	date: string;
	weekDay: string;
	bookings: Bookings;
}

export interface DrivingSlipsFilter {
	name?: string | null;
	locations: string[] | undefined;
	departments: string[] | undefined;
	damageCauses: string[] | undefined;
	damageCategories: string[] | undefined;
	debitors: string[];
	postalCodeText: string;
	postalCodes: string[];
	projectManagers: string[];
	erpNos: string[];
}
export function getWeekDay(dateIso: string) {
	return toSentenceCase(DateTime.fromISO(dateIso).weekdayLong);
}


// Constants for planner
export const TABS_WIDTH = '380px';

//export const bgColors: string[] = ['bg-cyan-calm', 'bg-blue-calm', 'bg-green-calm', 'bg-blue-lightcalm', 'bg-orange-calm', 'bg-purple-calm'];
export const bgColors: string[] = ['bg-cyan-calm', 'bg-blue-calm'];

export const eventTypes: SelectOption[] = [...enumToSelectOptions(CalendarEventType, 'planner.types.').filter(e => e.value !== CalendarEventType.DrivingSlip && e.value !== CalendarEventType.Unknown)];


// Functions for setting colors on selected users
// export function getEarliestUnusedColor(usedColors: string[]): string {
// 	// If all colors are used, reset the usedColors array
// 	const usedColorsNumbers = bgColors.flatMap(c => usedColors.filter(u => u === c).length);

// 	const indexLowest = usedColorsNumbers.indexOf(getLowestNumber(usedColorsNumbers));

// 	if (indexLowest > -1) {
// 		return bgColors[indexLowest];
// 	}

// 	return bgColors[0];
// }

// export function getEarliestUnusedColor(index: string[]): string {
// 	// If all colors are used, reset the usedColors array
// 	const usedColorsNumbers = bgColors.flatMap(c => usedColors.filter(u => u === c).length);

// 	const indexLowest = usedColorsNumbers.indexOf(getLowestNumber(usedColorsNumbers));

// 	if (indexLowest > -1) {
// 		return bgColors[indexLowest];
// 	}

// 	return bgColors[0];
// }

export function getColorsForAll(userIds: string[]): SelectedUser[] {
	const plannerIds = userIds.slice().filter(id => id.toLowerCase().includes('planner')).sort((a, b) => a.localeCompare(b));
	const otherIds = userIds.slice().filter(id => !id.toLowerCase().includes('planner')).sort((a, b) => a.localeCompare(b));

	return [...plannerIds, ...otherIds].map<SelectedUser>((id, index) => ({ id, color: bgColors[index % bgColors.length] }));

}

function getLowestNumber(numbers: number[]): number {
	if (numbers.length === 0) {
		return 0; // If the array is empty, return null
	}

	let lowest = numbers[0]; // Initialize with the first element

	for (let i = 1; i < numbers.length; i++) {
		if (numbers[i] < lowest) {
			lowest = numbers[i]; // Update lowest if a smaller number is found
		}
	}

	return lowest;
}

// Function to get backroundcolor on sick leave events
export function isOffDay(events: GetCalendarsQuery['userCalendars'][number]['events']) {
	return typeof events.find(e => e.type !== CalendarEventType.DrivingSlip && e.type !== CalendarEventType.Unknown) !== 'undefined';
}

interface datesAndWeekDays {
	date: string;
	weekDay: string;
}
// Function to map booked vehicles
export function mapBookingsOnVehicle(bookings: Bookings, dates: datesAndWeekDays[]) {
	const dateBookings: DateBooking[] = dates.map(date => {
		return {
			date: date.date,
			weekDay: date.weekDay,
			bookings: bookings.filter(b => formatDateForInput(new Date(b.start.replace('Z', '') + 'Z'), true).includes(date.date)),
		};
	});

	return dateBookings;
}

// HOOKS

// Can edit planner boolean hook

export function useCanEdit() {
	const userContext = React.useContext(UserContext);

	const canEdit = arraysHasMatchingValue(userContext.user?.permissions as unknown as Permissions[] ?? [], [Permissions.PlanningEdit]);

	return {
		canEdit,
	};
}
// Gets planner admin data to avoid passing through components
export function usePlannerAdminData(): {
	adminDataLoading: boolean,
	adminData: GetCaseAdminDataPlannerQuery,
} {
	const { data: adminDataRaw, loading: adminDataLoading } = useGetCaseAdminDataPlannerQuery({
		context: { debatch: true },
		fetchPolicy: 'cache-first',
	});

	const adminData = React.useMemo(
		() =>
			adminDataRaw ?? {
				locations: [],
				departments: [],
				damageCategories: [],
				damageCauses: [],
				usersWithCalendars: [],
				drivingSlipCategories: [],
			},
		[adminDataRaw],
	);

	return {
		adminDataLoading: adminDataLoading,
		adminData: adminData,
	};
}

// Get dates for planner
export type NumberOfDays = 7 | 14;


// Used to create and update planner events
export function useCalendarEvents() {

	const { dates, selectedUsers, activeDrivingSlipsFilters } = React.useContext(PlannerContext);


	const getDrivingSlipsPlannerVariables: GetDrivingSlipsNewPlannerQueryVariables = React.useMemo(() => ({
		status: DrivingSlipStatus.Unplanned,
		damageCategories: activeDrivingSlipsFilters.damageCategories,
		damageCauses: activeDrivingSlipsFilters.damageCauses,
		departments: activeDrivingSlipsFilters.departments,
		locations: activeDrivingSlipsFilters.locations,

	}
	), [activeDrivingSlipsFilters.damageCategories, activeDrivingSlipsFilters.damageCauses, activeDrivingSlipsFilters.departments, activeDrivingSlipsFilters.locations]);

	const getCalendarsQueryVariables: GetCalendarsQueryVariables = React.useMemo(() => ({
		userIdList: selectedUsers.map(s => s.id),
		dateRange: {
			from: dates?.fromDate ?? '',
			to: dates?.toDate ?? '',
		},
	}), [dates?.fromDate, dates?.toDate, selectedUsers]);

	const { data: userCalendarsData, loading: userCalendarsLoading } = useGetCalendarsQuery({
		fetchPolicy: 'cache-and-network',
		context: { debatch: true },
		variables: getCalendarsQueryVariables,
		skip: selectedUsers.length === 0 || typeof dates === 'undefined',
	});

	const [usersCalendars, setUsersCalendars] = React.useState<GetCalendarsQuery['userCalendars']>([]);

	React.useEffect(() => {
		typeof userCalendarsData?.userCalendars !== 'undefined' && userCalendarsData.userCalendars.length === selectedUsers.length && setUsersCalendars(userCalendarsData.userCalendars.slice().sort((a, b) => a.user.id.localeCompare(b.user.id)));
	}, [userCalendarsData, selectedUsers.length]);



	const [createCalendarEvent, { loading: postCreateUserCalendarEvenLoading }] = useCreateCalendarEventMutation();
	const [updateCalendarEvent, { loading: updateCalendarEventLoading }] = useUpdateCalendarEventMutation();
	const [deleteCalendarEvent, { loading: postDeleteCalendarEventLoading }] = useDeleteCalendarEventMutation();
	const [changeUserOnEvent, { loading: postChangeUserOnCalendarEventLoading }] = useChangeUserOnEventMutation();


	const postCreateUserCalendarEvent = async (userId: string, event: CalendarEventInput) => {
		try {
			await createCalendarEvent({
				variables: {
					userId: userId,
					event: event,
				},
				optimisticResponse: {
					createUserCalendarEvent: {
						isAllDay: event.isAllDay ?? false,
						drivingSlip: null,
						systemCreatedEvent: true,
						referenceId: 'LOADING',
						timeRange: {
							from: event.timeRange.from,
							to: event.timeRange.to,
						},
						title: event.title,
						type: event.type,
						__typename: 'CalendarEvent',
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.createUserCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					cachedRequestCopy[userEntryIndex] = {
						...cachedRequestCopy[userEntryIndex],
						events: [...cachedRequestCopy[userEntryIndex].events, data.createUserCalendarEvent],
					};
					cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
						data: {
							userCalendars: cachedRequestCopy,
						},
					});

					if (data.createUserCalendarEvent.drivingSlip !== null) {
						const cachedDrivingSlipsRequest = cache.readQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
							query: GetDrivingSlipsNewPlannerDocument,
							variables: getDrivingSlipsPlannerVariables,
						});
						if (cachedDrivingSlipsRequest === null) {
							return;
						}

						cache.writeQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
							query: GetDrivingSlipsNewPlannerDocument,
							variables: getDrivingSlipsPlannerVariables,
							data: {
								plannerDrivingSlips: cachedDrivingSlipsRequest.plannerDrivingSlips.filter(ds => ds.id !== data.createUserCalendarEvent.drivingSlip?.id),
							},
						});
					}

				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postUserCalendarEventEdit = async (userId: string, event: CalendarEventInput, eventId: string) => {
		try {
			await updateCalendarEvent({
				variables: {
					userId: userId,
					eventId: eventId,
					event: event,
				},
				optimisticResponse: {
					updateUserCalendarEvent: {
						drivingSlip: null,
						isAllDay: event.isAllDay ?? false,
						systemCreatedEvent: true,
						referenceId: 'LOADING',
						timeRange: {
							from: event.timeRange.from,
							to: event.timeRange.to,
						},
						title: event.title,
						type: event.type,
						__typename: 'CalendarEvent',
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.updateUserCalendarEvent === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					cachedRequestCopy[userEntryIndex] = {
						...cachedRequestCopy[userEntryIndex],
						events: [...cachedRequestCopy[userEntryIndex].events.filter(e => e.referenceId !== data.updateUserCalendarEvent.referenceId), data.updateUserCalendarEvent],
					};

					cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
						data: {
							userCalendars: cachedRequestCopy,
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postChangeUserOnCalendarEvent = async (userId: string, event: CalendarEventInput, originalEventId: string, originalUserId: string) => {
		try {
			await changeUserOnEvent({
				variables: {
					userId: userId,
					originalEventId: originalEventId,
					event: event,
					originalUserId: originalUserId,
				},
				optimisticResponse: {
					changeUserOnCalendarEvent: {
						isAllDay: event.isAllDay ?? false,
						drivingSlip: null,
						systemCreatedEvent: true,
						referenceId: 'LOADING',
						timeRange: {
							from: event.timeRange.from,
							to: event.timeRange.to,
						},
						title: event.title,
						type: event.type,
						__typename: 'CalendarEvent',
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.changeUserOnCalendarEvent === null) {
						return;
					}


					const cachedRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
					});

					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const originalUserEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === originalUserId);
					cachedRequestCopy[originalUserEntryIndex] = {
						...cachedRequestCopy[originalUserEntryIndex],
						events: [...cachedRequestCopy[originalUserEntryIndex].events.filter(e => e.referenceId !== originalEventId)],
					};

					const newUserEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === userId);
					if (newUserEntryIndex !== -1) {
						cachedRequestCopy[newUserEntryIndex] = {
							...cachedRequestCopy[newUserEntryIndex],
							events: [...cachedRequestCopy[newUserEntryIndex].events, data.changeUserOnCalendarEvent],
						};
					}

					cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
						data: {
							userCalendars: cachedRequestCopy,
						},
					});
				},
			});

		} catch (e) {
			console.log(e);
		}
	};

	const updateUserEventDeciderAndPost = async (
		eventId: string,
		event: CalendarEventInput,
		newUserId: string,
		oldUserId: string,
	) => {
		if (newUserId === oldUserId) {
			try {
				await postUserCalendarEventEdit(
					oldUserId,
					event,
					eventId,
				);
			} catch (e) {
				console.log(e);
			}
		} else {
			try {
				await postChangeUserOnCalendarEvent(
					newUserId,
					event,
					eventId,
					oldUserId,
				);
			} catch (e) {
				console.log(e);
			}
		}
	};



	const postDeleteCalendarEvent = async (variables: DeleteUserCalendarEventMutationVariables) => {
		try {

			await deleteCalendarEvent({
				variables: {
					eventId: variables.eventId,
					userId: variables.userId,
					drivingSlipId: variables.drivingSlipId,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
					});


					if (cachedRequest === null || cachedRequest.userCalendars === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.userCalendars];
					const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === variables.userId);
					cachedRequestCopy[userEntryIndex] = {
						...cachedRequestCopy[userEntryIndex],
						events: [...cachedRequestCopy[userEntryIndex].events.filter(e => e.referenceId !== variables.eventId)],
					};
					cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
						query: GetCalendarsDocument,
						variables: getCalendarsQueryVariables,
						data: {
							userCalendars: cachedRequestCopy,
						},
					});

					if (data.deleteUserCalendarEvent !== null) {

						const cachedDrivingSlipRequest = cache.readQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
							query: GetDrivingSlipsNewPlannerDocument,
							variables: getDrivingSlipsPlannerVariables,
						});

						if (cachedDrivingSlipRequest === null || cachedDrivingSlipRequest.plannerDrivingSlips === null) {
							return;
						}

						cache.writeQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
							query: GetDrivingSlipsNewPlannerDocument,
							variables: getDrivingSlipsPlannerVariables,
							data: {
								plannerDrivingSlips: [...cachedDrivingSlipRequest.plannerDrivingSlips, data.deleteUserCalendarEvent],
							},
						});
					}
				},
			});

		} catch (e) {
			console.log(e);
		}
	};
	return {
		usersCalendars,
		userCalendarsLoading,
		getCalendarsQueryVariables,
		postCreateUserCalendarEvent,
		postCreateUserCalendarEvenLoading,
		updateUserEventDeciderAndPost,
		updateUserEventDeciderAndPostLoading: (updateCalendarEventLoading || postChangeUserOnCalendarEventLoading),
		postDeleteCalendarEvent,
		postDeleteCalendarEventLoading,
	};
}

// Used to update and delete drivingSlips
export function usePlannerDrivingSlips() {
	const { dates, activeDrivingSlipsFilters, selectedUsers, activeCarFilters, activeMaterialFilters } = React.useContext(PlannerContext);


	const getDrivingSlipsPlannerVariables: GetDrivingSlipsNewPlannerQueryVariables = React.useMemo(() => ({
		status: DrivingSlipStatus.Unplanned,
		damageCategories: activeDrivingSlipsFilters.damageCategories,
		damageCauses: activeDrivingSlipsFilters.damageCauses,
		departments: activeDrivingSlipsFilters.departments,
		locations: activeDrivingSlipsFilters.locations,
	}
	), [activeDrivingSlipsFilters]);

	const getCalendarsQueryVariables: GetCalendarsQueryVariables = React.useMemo(() => ({
		userIdList: selectedUsers.map(s => s.id),
		dateRange: {
			from: dates?.fromDate ?? '',
			to: dates?.toDate ?? '',
		},
	}), [dates?.fromDate, dates?.toDate, selectedUsers]);

	const [createDrivingSlip, { loading: copyDrivingSlipSubmitting }] = useCreateDrivingSlipNewPlannerMutation();
	const [updateDrivingSlip, { loading: updateDrivingSlipSubmitting }] = useUpdateWebDrivingSlipNewPlannerMutation();
	const [deleteDrivingSlip, { loading: deleteDrivingSlipSubmitting }] = useDeleteDrivingSlipMutation();
	const [setDrivingSlipStatus, { loading: postSetDrivingSlipStatusLoading }] = useSetDrivingSlipStatusNewPlannerMutation();
	const [addCarOrMaterial] = useAddCarOrMaterialsMutation();
	const [removeCar] = useRemoveCarFromDrivingSlipMutation();
	const [removeMaterial] = useRemoveMaterialFromDrivingSlipMutation();

	const copyDrivingSlip = async (variables: CreateDrivingSlipNewPlannerMutationVariables) => {
		await createDrivingSlip({
			variables,
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}

				const drivingSlips = responseData.createDrivingSlip;
				const cachedUserCalendarsRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
					query: GetCalendarsDocument,
					variables: getCalendarsQueryVariables,
				});

				if (cachedUserCalendarsRequest === null || cachedUserCalendarsRequest.userCalendars === null) {
					return;
				}

				const cachedRequestCopy = [...cachedUserCalendarsRequest.userCalendars];

				drivingSlips.forEach(drivingSlip => {
					if (drivingSlip.driver) {
						const { id } = drivingSlip.driver;
						const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === id);

						if (userEntryIndex === -1) {
							return;
						}
						console.log(drivingSlip);
						const userCalendarEvent: GetCalendarsQuery['userCalendars'][number]['events'][number] = {
							isAllDay: false,
							drivingSlip: drivingSlip as unknown as GetCalendarsQuery['userCalendars'][number]['events'][number]['drivingSlip'],
							systemCreatedEvent: true,
							referenceId: drivingSlip.eventReferenceId ?? 'ugh',
							timeRange: {
								from: drivingSlip.start,
								to: drivingSlip.end,
							},
							title: drivingSlip.case.erpNo,
							type: CalendarEventType.DrivingSlip,
							__typename: 'CalendarEvent',
						};


						cachedRequestCopy[userEntryIndex] = {
							...cachedRequestCopy[userEntryIndex],
							events: [...cachedRequestCopy[userEntryIndex].events, userCalendarEvent],
						};
					}
				});

				cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
					query: GetCalendarsDocument,
					variables: getCalendarsQueryVariables,
					data: {
						userCalendars: cachedRequestCopy,
					},
				});
			},
		});
	};

	const deleteSingleDrivingSlip = async (variables: DeleteDrivingSlipMutationVariables) => {
		await deleteDrivingSlip({
			variables: variables,
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}
				const cachedRequest = cache.readQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
					query: GetDrivingSlipsNewPlannerDocument,
					variables: getDrivingSlipsPlannerVariables,
				});

				if (cachedRequest === null) {
					return;
				}

				cache.writeQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
					query: GetDrivingSlipsNewPlannerDocument,
					variables: getDrivingSlipsPlannerVariables,
					data: {
						plannerDrivingSlips: cachedRequest.plannerDrivingSlips.filter(ds => ds.id !== variables.id),
					},
				});
			},
		});
	};

	const getCarsVariables: GetVehiclesQueryVariables = React.useMemo(() => {
		return {
			isCar: true,
			dateRange: {
				from: dates?.fromDate ?? '',
				to: dates?.toDate ?? '',
			},
			locations: activeCarFilters.locations,
			//type: activeCarFilters.type,
		};
	}, [activeCarFilters.locations, dates?.fromDate, dates?.toDate]);

	const getMaterialsVariables: GetVehiclesQueryVariables = React.useMemo(() => {
		return {
			isCar: false,
			dateRange: {
				from: dates?.fromDate ?? '',
				to: dates?.toDate ?? '',
			},
			locations: activeMaterialFilters.locations,
			//type: activeMaterialFilters.type,
		};
	}, [activeMaterialFilters.locations, dates?.fromDate, dates?.toDate]);

	const postAddCarOrMaterial = async (data: AddCarOrMaterialsMutationVariables, optimisticDrivingSlip: DrivingSlipNewPlannerFragment, oldCarNo?: string): Promise<void> => {
		await addCarOrMaterial({
			variables: data,
			optimisticResponse: {
				addCarOrMaterials: optimisticDrivingSlip,
			},
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}

				const { id, start, driver } = responseData.addCarOrMaterials;

				if (data.car) {
					const cachedRequest = cache.readQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
						query: GetVehiclesDocument,
						variables: getCarsVariables,
					});

					if (cachedRequest === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.vehicles];
					const vehicleEntryIndex = cachedRequestCopy.findIndex(v => v.vehicleNumber === data.car);
					cachedRequestCopy[vehicleEntryIndex] = {
						...cachedRequestCopy[vehicleEntryIndex],
						bookedDays: [...cachedRequestCopy[vehicleEntryIndex].bookedDays, { id, start, driver }],
					};

					if (oldCarNo) {
						const oldVehicleEntryIndex = cachedRequestCopy.findIndex(v => v.vehicleNumber === oldCarNo);
						cachedRequestCopy[oldVehicleEntryIndex] = {
							...cachedRequestCopy[oldVehicleEntryIndex],
							bookedDays: [...cachedRequestCopy[oldVehicleEntryIndex].bookedDays.filter(e => e.id !== id)],
						};
					}

					cache.writeQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
						query: GetVehiclesDocument,
						variables: getCarsVariables,
						data: {
							vehicles: cachedRequestCopy,
						},
					});
				}
				if (data.material) {
					const cachedRequest = cache.readQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
						query: GetVehiclesDocument,
						variables: getMaterialsVariables,
					});

					if (cachedRequest === null) {
						return;
					}

					const cachedRequestCopy = [...cachedRequest.vehicles];
					const vehicleEntryIndex = cachedRequestCopy.findIndex(v => v.vehicleNumber === data.material);
					cachedRequestCopy[vehicleEntryIndex] = {
						...cachedRequestCopy[vehicleEntryIndex],
						bookedDays: [...cachedRequestCopy[vehicleEntryIndex].bookedDays, { id, start, driver }],
					};

					cache.writeQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
						query: GetVehiclesDocument,
						variables: getMaterialsVariables,
						data: {
							vehicles: cachedRequestCopy,
						},
					});
				}
			},
		});
	};

	const updateSingleDrivingSlip = async (data: UpdateWebDrivingSlipNewPlannerMutationVariables, optimisticDrivingSlip?: DrivingSlipNewPlannerFragment): Promise<void> => {
		const optimisticResponsess = (vars: UpdateWebDrivingSlipNewPlannerMutationVariables) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const drivingSlip = optimisticDrivingSlip!;
			return {
				updateDrivingSlip: [drivingSlip],
			};
		};
		const optimisticResponse = optimisticDrivingSlip ? optimisticResponsess : undefined;

		await updateDrivingSlip({
			variables: data,
			optimisticResponse,
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
					query: GetDrivingSlipsNewPlannerDocument,
					variables: getDrivingSlipsPlannerVariables,
				});

				if (cachedRequest === null || responseData.updateDrivingSlip.length === 0) {
					return;
				}
				const newPlannedDrivingSlips = responseData.updateDrivingSlip.filter(ds => ds.status === DrivingSlipStatus.Planned);
				const newNotPlannedDrivingSlips = responseData.updateDrivingSlip.filter(ds => ds.status !== DrivingSlipStatus.Planned);
				const changedOldDrivingSlipsFilteredOut = cachedRequest.plannerDrivingSlips.filter(e => !responseData.updateDrivingSlip.find(uDS => uDS.id === e.id)).filter(ds => ds.status === DrivingSlipStatus.Unplanned);
				const toCache = [...newNotPlannedDrivingSlips, ...changedOldDrivingSlipsFilteredOut];

				cache.writeQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
					query: GetDrivingSlipsNewPlannerDocument,
					variables: getDrivingSlipsPlannerVariables,
					data: {
						plannerDrivingSlips: toCache,
					},
				});

				const drivers = newPlannedDrivingSlips.map(d => d.driver).filter(driver => driver !== null);
				if (drivers.length === 0) {
					return;
				}

				const cachedUserCalendarsRequest = cache.readQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
					query: GetCalendarsDocument,
					variables: getCalendarsQueryVariables,
				});

				if (cachedUserCalendarsRequest === null || cachedUserCalendarsRequest.userCalendars === null) {
					return;
				}

				const cachedRequestCopy = [...cachedUserCalendarsRequest.userCalendars];

				newPlannedDrivingSlips.forEach(drivingSlip => {
					if (drivingSlip.driver) {
						const { id } = drivingSlip.driver;
						const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === id);

						if (userEntryIndex === -1) {
							return;
						}

						const userCalendarEvent: GetCalendarsQuery['userCalendars'][number]['events'][number] = {
							isAllDay: false,
							drivingSlip: drivingSlip as unknown as GetCalendarsQuery['userCalendars'][number]['events'][number]['drivingSlip'],
							systemCreatedEvent: true,
							referenceId: drivingSlip.eventReferenceId ?? 'ugh',
							timeRange: {
								from: drivingSlip.start,
								to: drivingSlip.end,
							},
							title: drivingSlip.case.erpNo,
							type: CalendarEventType.DrivingSlip,
							__typename: 'CalendarEvent',
						};


						cachedRequestCopy[userEntryIndex] = {
							...cachedRequestCopy[userEntryIndex],
							events: [...cachedRequestCopy[userEntryIndex].events, userCalendarEvent],
						};
					}
				});

				cache.writeQuery<GetCalendarsQuery, GetCalendarsQueryVariables>({
					query: GetCalendarsDocument,
					variables: getCalendarsQueryVariables,
					data: {
						userCalendars: cachedRequestCopy,
					},
				});

			},
		});
	};

	const postSetDrivingSlipStatus = async (drivingSlipId: string): Promise<void> => {
		try {
			await setDrivingSlipStatus({
				variables: {
					id: drivingSlipId,
					status: DrivingSlipStatus.ConvertedToRequisition,
				},
				update: (cache, { data: responseData }): void => {
					if (typeof responseData === 'undefined' || responseData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
						query: GetDrivingSlipsNewPlannerDocument,
						variables: getDrivingSlipsPlannerVariables,
					});

					if (cachedRequest === null) {
						return;
					}


					cache.writeQuery<GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables>({
						query: GetDrivingSlipsNewPlannerDocument,
						variables: getDrivingSlipsPlannerVariables,
						data: {
							plannerDrivingSlips: [...cachedRequest.plannerDrivingSlips.filter(ds => ds.id !== responseData.setDrivingSlipStatus.id)],
						},
					});

				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const removeCarFromDrivingSlip = async (id: string, carNo: string, optimisticDrivingSlip: DrivingSlipNewPlannerFragment) => {
		await removeCar({
			variables: {
				id,
			},
			optimisticResponse: {
				removeCar: optimisticDrivingSlip,
			},
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}
				const cachedRequest = cache.readQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
					query: GetVehiclesDocument,
					variables: getCarsVariables,
				});

				if (cachedRequest === null) {
					return;
				}

				const cachedRequestCopy = [...cachedRequest.vehicles];

				const oldVehicleEntryIndex = cachedRequestCopy.findIndex(v => v.vehicleNumber === carNo);
				cachedRequestCopy[oldVehicleEntryIndex] = {
					...cachedRequestCopy[oldVehicleEntryIndex],
					bookedDays: [...cachedRequestCopy[oldVehicleEntryIndex].bookedDays.filter(e => e.id !== id)],
				};


				cache.writeQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
					query: GetVehiclesDocument,
					variables: getCarsVariables,
					data: {
						vehicles: cachedRequestCopy,
					},
				});
			},
		});
	};
	const removeMaterialFromDrivingSlip = async (id: string, materialNo: string, optimisticDrivingSlip: DrivingSlipNewPlannerFragment) => {
		await removeMaterial({
			variables: {
				id,
				material: materialNo,
			},
			optimisticResponse: {
				removeMaterial: optimisticDrivingSlip,
			},
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}
				const cachedRequest = cache.readQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
					query: GetVehiclesDocument,
					variables: getMaterialsVariables,
				});

				if (cachedRequest === null) {
					return;
				}

				const cachedRequestCopy = [...cachedRequest.vehicles];

				const oldVehicleEntryIndex = cachedRequestCopy.findIndex(v => v.vehicleNumber === materialNo);
				cachedRequestCopy[oldVehicleEntryIndex] = {
					...cachedRequestCopy[oldVehicleEntryIndex],
					bookedDays: [...cachedRequestCopy[oldVehicleEntryIndex].bookedDays.filter(e => e.id !== id)],
				};


				cache.writeQuery<GetVehiclesQuery, GetVehiclesQueryVariables>({
					query: GetVehiclesDocument,
					variables: getMaterialsVariables,
					data: {
						vehicles: cachedRequestCopy,
					},
				});
			},
		});
	};

	return {
		updateSingleDrivingSlip,
		updateDrivingSlipSubmitting,
		deleteSingleDrivingSlip,
		deleteDrivingSlipSubmitting,
		postSetDrivingSlipStatus,
		postSetDrivingSlipStatusLoading,
		removeCarFromDrivingSlip,
		removeMaterialFromDrivingSlip,
		postAddCarOrMaterial,
		copyDrivingSlip,
		copyDrivingSlipSubmitting,
	};
}
