import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faEdit, faQuoteRight } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CollectionItemType, GetCollectionItems, GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { IAlternativeContact, IDamageAddressBox } from '../../Schemas/ICaseCreation';
import { countries } from '@ssg/common/Helpers/countries';
import { useTranslation } from 'react-i18next';
import { InjuredPartySchema } from '../../Schemas/InjuredPartySchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import Textarea from '@ssg/common/Components/Textarea';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Popover from '@ssg/common/Components/Popover';
import { postalCodeToCity } from '@ssg/common/Helpers/postalCodes';
import TextButton from '@ssg/common/Components/TextButton';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { removeTypename } from '../../helper';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

interface IInjuredPartyBox {
	DamageAddressBox: IDamageAddressBox;
	AlternativeContactBox: IAlternativeContact;
}

const InjuredPartyBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const [tippy, setTippy] = React.useState<boolean | undefined>(undefined);
	const [editActive, setEditActive] = React.useState(false);

	const { data: accessDropdownItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		variables: {
			type: CollectionItemType.ACCESS_CONDITION,
		},
		skip: !editActive,
	});

	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === caseData.damage.contact.address.country)?.label || '');
	const [phonenumber, setPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.damage.contact.phone ?? ''));
	const [alternativeContactPhonenumber, setAlternativeContactPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.alternativeContact?.phone ?? ''));
	const [country, setCountry] = React.useState<string | undefined>(caseData.damage.contact.address.country);

	const phonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('DamageAddressBox.damagePhone', phonenumber, {
			shouldValidate: true,
		});
		setPhonenumber(phonenumber);
	};

	const alternativeContactPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('AlternativeContactBox.phone', phonenumber, {
			shouldValidate: true,
		});
		setAlternativeContactPhonenumber(phonenumber);
	};

	const { handleSubmit, errors, register, control, setValue, trigger, getValues } = useForm<IInjuredPartyBox>({
		resolver: yupResolver(InjuredPartySchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			DamageAddressBox: {
				damageCountry: caseData.damage.contact.address.country ?? '',
				damagePhone: caseData.damage.contact.phone ?? '',
			},
			AlternativeContactBox: {
				phone: caseData.alternativeContact?.phone ?? '',
			},
		},
	});

	const countrySelectHandler = (value: string): void => {
		setValue('DamageAddressBox.damageCountry', value, {
			shouldValidate: true,
		});
	};

	const alternativeContactBoxNoEmail = useWatch({
		control,
		name: 'AlternativeContactBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.alternativeContact?.email === '',
	});

	const damageAddressBoxNoEmail = useWatch({
		control,
		name: 'DamageAddressBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.damage.contact.email === '',
	});

	React.useEffect(() => {
		if (damageAddressBoxNoEmail) {
			setValue('DamageAddressBox.damageEmail', '', {
				shouldValidate: true,
			});
		}
	}, [damageAddressBoxNoEmail, setValue]);

	React.useEffect(() => {
		if (alternativeContactBoxNoEmail) {
			setValue('AlternativeContactBox.email', '', {
				shouldValidate: true,
			});
		}
	}, [alternativeContactBoxNoEmail, setValue]);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const injuredParty: SelectOption[] = [
		{ value: caseData.damage.contact.name, label: 'case.injuredParty' },
		{
			value: caseData.damage.contact.address.road + ' ' + caseData.damage.contact.address.houseNumber + ' ' + (caseData.damage.contact.address.floor ?? ''),
			label: 'common.address',
		},
		{
			value: caseData.damage.contact.address.addressLineAlt ?? '',
			label: '',
		},
		{
			value: caseData.damage.contact.address.postalCode + ' ' + caseData.damage.contact.address.city,
			label: 'common.zipAndCity',
		},
		{
			value: countries.find(c => c.value === caseData.damage.contact.address.country)?.label ?? '',
			label: '',
		},
		{ value: caseData.damage.contact.phone ?? '', label: 'common.tel' },
		{ value: caseData.damage.contact.email, label: 'common.mail' },
		{
			value: caseData.damage.accessConditions,
			label: caseData.damage.accessConditions.length > 0 ? 'case.accessConditions' : '',
		},
	];

	const alternativeContact: SelectOption[] = [
		{
			value: caseData.alternativeContact?.name ?? '',
			label: 'case.alternativeContact',
		},
		{
			value: caseData.alternativeContact?.phone ?? '',
			label: 'common.tel',
		},
		{
			value: caseData.alternativeContact?.email ?? '',
			label: 'common.mail',
		},
		{
			value: caseData.alternativeContact?.comment ?? '',
			label: 'common.comment',
		},
	];

	const onSubmit = async (data: IInjuredPartyBox) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				damage: {
					category: caseData.damage.category.id,
					cause: caseData.damage.cause.id,
					contact: {
						address: {
							road: data.DamageAddressBox.damageRoad,
							houseNumber: data.DamageAddressBox.damageHouseNumber,
							floor: data.DamageAddressBox.damageFloor,
							addressLineAlt: data.DamageAddressBox.damageAddress2,
							city: data.DamageAddressBox.damageCity,
							postalCode: data.DamageAddressBox.damageZip,
							country: data.DamageAddressBox.damageCountry,
						},
						email: data.DamageAddressBox.noEmail ? '' : data.DamageAddressBox.damageEmail,
						name: data.DamageAddressBox.damageName,
						phone: data.DamageAddressBox.damagePhone,
					},
					date: caseData.damage.date,
					description: caseData.damage.description,
					businessArea: caseData.damage.businessArea.id,
					accessConditions: data.DamageAddressBox.accessConditions,
					contacts: removeTypename(caseData.damage.contacts),
				},
				alternativeContact: data.AlternativeContactBox.name
					? {
						name: data.AlternativeContactBox.name,
						phone: data.AlternativeContactBox.phone,
						email: data.AlternativeContactBox.noEmail ? '' : data.AlternativeContactBox.email,
						comment: data.AlternativeContactBox.comment,
					}
					: null,
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, damage, alternativeContact }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				damage: damage,
				alternativeContact: alternativeContact,
			},
		});
	};

	return (
		<>
			<Box title="case.injuredParty" className="mb-8" full onClick={() => setEditActive(true)} icon={caseViewExternal ? undefined : faEdit}>
				<hr />
				<BoxFormDataViewer data={injuredParty} keyId="case.injuredParty" />
				{caseData.alternativeContact && <BoxFormDataViewer data={alternativeContact} keyId="case.alternativeContact" />}
			</Box>
			<Modal
				title="common.editInjuredParty"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.MEDIUM}
				body={
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
						<div className="w-1/2 pr-2">
							<p className="text-blue text-lg font-medium">{t('case.injuredParty')}</p>
							<Input
								title="common.name"
								required
								innerRef={register}
								name="DamageAddressBox.damageName"
								errorMessage={errors.DamageAddressBox?.damageName?.message ?? ''}
								defaultValue={caseData.damage.contact.name}
							/>

							<div className="flex w-full">
								<div className="w-3/4">
									<Input
										title="common.road"
										required
										innerRef={register}
										name="DamageAddressBox.damageRoad"
										errorMessage={errors.DamageAddressBox?.damageRoad?.message ?? ''}
										defaultValue={caseData.damage.contact.address.road}
									/>
								</div>
								<div className="ml-4 w-1/4">
									<Input
										title="common.houseNumber"
										required
										innerRef={register}
										name="DamageAddressBox.damageHouseNumber"
										errorMessage={errors.DamageAddressBox?.damageHouseNumber?.message ?? ''}
										defaultValue={caseData.damage.contact.address.houseNumber}
									/>
								</div>
								<div className="ml-4 w-1/4">
									<Input
										title="common.floor"
										innerRef={register}
										name="DamageAddressBox.damageFloor"
										errorMessage={errors.DamageAddressBox?.damageFloor?.message ?? ''}
										defaultValue={caseData.damage.contact.address.floor ?? ''}
									/>
								</div>
							</div>
							<Input
								title="common.addressLine2"
								innerRef={register}
								name="DamageAddressBox.damageAddress2"
								errorMessage={errors.DamageAddressBox?.damageAddress2?.message ?? ''}
								defaultValue={caseData.damage.contact.address.addressLineAlt ?? ''}
							/>

							<div className="flex w-full flex-row">
								<div className="w-28">
									<Input
										required
										title="common.postalcode"
										name="DamageAddressBox.damageZip"
										defaultValue={caseData.damage.contact.address.postalCode}
										onChange={async e => {
											const pc = e.currentTarget.value;
											const city = await postalCodeToCity(country ?? envVar.defaultCountry ?? '', pc);
											if (typeof city !== 'undefined') {
												setValue('DamageAddressBox.damageCity', city);
											} else {
												setValue('DamageAddressBox.damageCity', undefined);
											}
										}}
										errorMessage={errors.DamageAddressBox?.damageZip?.message ?? ''}
										innerRef={register}
									/>
								</div>
								<div className="flex-grow-default ml-4">
									<Input
										title="common.city"
										required
										innerRef={register}
										name="DamageAddressBox.damageCity"
										errorMessage={errors.DamageAddressBox?.damageCity?.message ?? ''}
										className="w-full md:w-full lg:w-full"
										defaultValue={caseData.damage.contact.address.city}
									/>
								</div>
							</div>

							<SearchableSelect
								key="country"
								control={control}
								name="DamageAddressBox.damageCountry"
								title="common.country"
								required
								options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
								searchFn={(searchText): void => setCountrySearchText(searchText)}
								onSelect={(value): void => countrySelectHandler(value)}
								onBlur={(): void => undefined}
								minInputLength={1}
								isLoading={false}
								errorMessage={errors.DamageAddressBox?.damageCountry?.message ?? ''}
								initialSelection={countries.find(i => i.value === caseData.damage.contact.address.country)}
							/>

							<InputPhone
								title="common.phone"
								required
								innerRef={register}
								name="DamageAddressBox.damagePhone"
								errorMessage={errors.DamageAddressBox?.damagePhone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
								defaultCountry={country}
								defaultPhoneNumber={phonenumber}
								className="w-full md:w-full lg:w-full"
							/>

							<Input
								title="common.email"
								required
								innerRef={register}
								name="DamageAddressBox.damageEmail"
								errorMessage={errors.DamageAddressBox?.damageEmail?.message ?? ''}
								disabled={damageAddressBoxNoEmail}
								defaultValue={caseData.damage.contact.email}
							/>

							<Checkbox
								title="case.noEmail"
								name="DamageAddressBox.noEmail"
								className="mt-1 w-full lg:w-full"
								innerRef={register}
								onChange={() => {
									trigger('DamageAddressBox.damageEmail');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.damage.contact.email === ''}
							/>

							<FormFieldHeader htmlFor="DamageAddressBox.accessConditions" title="case.accessConditions" />
							<div className="flex">
								<Popover
									placement="top"
									interactive
									clickTrigger
									hideOnClick
									visible={tippy}
									onHide={() => setTippy(undefined)}
									content={
										<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2">
											{accessDropdownItems?.collectionItems.map(temp => (
												<p
													key={temp.id}
													className="cursor-pointer"
													onClick={() => {
														const singleValue = getValues('DamageAddressBox.accessConditions');
														setValue('DamageAddressBox.accessConditions', singleValue === '' ? temp.name : `${singleValue}, ${temp.name}`);
														setTippy(false);
													}}
												>
													"{temp.name}"
												</p>
											))}
										</div>
									}
								>
									<div>
										<TextButton text="case.insertFromTemplate" icon={faQuoteRight} />
									</div>
								</Popover>
							</div>
							<Textarea title="" name="DamageAddressBox.accessConditions" rows={3} innerRef={register} defaultValue={caseData.damage.accessConditions} className="mt-1" />

							<div className="mt-3">
								<Button submit success text="common.editInjuredParty" loading={updateCaseSubmitting} />
							</div>
						</div>
						<div className="w-1/2 pl-2">
							<p className="text-blue text-lg font-medium">{t('case.alternativeContact')}</p>
							<Input
								title="common.name"
								innerRef={register}
								name="AlternativeContactBox.name"
								errorMessage={errors.AlternativeContactBox?.name?.message ?? ''}
								defaultValue={caseData?.alternativeContact?.name}
							/>

							<InputPhone
								title="common.phone"
								innerRef={register}
								name="AlternativeContactBox.phone"
								errorMessage={errors.AlternativeContactBox?.phone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => alternativeContactPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
								defaultCountry={country}
								defaultPhoneNumber={alternativeContactPhonenumber}
							/>

							<Input
								title="common.email"
								innerRef={register}
								name="AlternativeContactBox.email"
								disabled={alternativeContactBoxNoEmail}
								errorMessage={errors.AlternativeContactBox?.email?.message}
								defaultValue={caseData?.alternativeContact?.email}
							/>

							<Checkbox
								title="case.noEmail"
								name="AlternativeContactBox.noEmail"
								className="mt-1 w-full lg:w-full"
								innerRef={register}
								onChange={() => {
									trigger('AlternativeContactBox.email');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.alternativeContact !== null && caseData.alternativeContact.email === ''}
							/>

							<Textarea title="common.comment" name="AlternativeContactBox.comment" rows={3} innerRef={register} defaultValue={caseData?.alternativeContact?.comment ?? ''} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default InjuredPartyBox;
