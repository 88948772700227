import React from 'react';
import { CalendarEventType, GetUserCalendars_userCalendars_events, Permissions } from '../../GraphQL';
import { EventItemPlus } from './WorkerLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faLock } from '@fortawesome/pro-regular-svg-icons';
import { DragObjectWithType, useDrag } from 'react-dnd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import UserContext from '../../UserContext';
import PopoverDrivingSlipInfo from './PopoverDrivingSlipInfo';
import PopoverDrivingSlipActions from './PopoverDrivingSlipActions';
// h-12 -> h-5
interface Props {
	clickedTimeSlot: string;
	e: EventItemPlus;
	durationInHours: number;
	timeSlotClickHandler(timeSlotKey: string): void;
	setShowCopyEvent(event: GetUserCalendars_userCalendars_events | undefined): void;
	setShowEditEvent(event: GetUserCalendars_userCalendars_events | undefined): void;
	setConvertToRequisition: React.Dispatch<React.SetStateAction<boolean>>;
	userId: string;
}

export interface DragPropsEventItem extends DragObjectWithType {
	eventId: string;
	eventType: string;
	eventTitle: string;
	durationInHours: number;
	oldUserId: string;
	drivingSlipId?: string;
}

const DraggableEventItem: React.FC<Props> = ({
	clickedTimeSlot,
	e,
	durationInHours,
	timeSlotClickHandler,
	setShowCopyEvent,
	setShowEditEvent,
	setConvertToRequisition,
	userId,
}): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const canEdit = arraysHasMatchingValue(userContext.user?.permissions ?? [], [Permissions.PLANNING_EDIT]);

	const eventStart = DateTime.fromISO(e.eventItem.timeRange.from + 'Z').toLocal();
	const eventEnd = DateTime.fromISO(e.eventItem.timeRange.to + 'Z').toLocal();
	const realDurationInHours = eventEnd.diff(eventStart, 'hours').toObject().hours ?? 0;

	//Drag & Drop on event
	const [{ isDragging }, dragRef] = useDrag<DragPropsEventItem, void, { isDragging: boolean }>({
		item: {
			type: 'draggableEventItem',
			eventType: e.eventItem.type,
			eventId: e.eventItem.referenceId,
			eventTitle: e.eventItem.title,
			durationInHours: realDurationInHours,
			oldUserId: userId,
			drivingSlipId: e.eventItem.drivingSlip?.id,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	React.useEffect(() => {
		isDragging && timeSlotClickHandler(e.eventItem.type === CalendarEventType.UNKNOWN ? '' : e.eventItem.referenceId);
	}, [isDragging, e.eventItem.referenceId, e.eventItem.type, timeSlotClickHandler]);

	const ref = canEdit ? dragRef : null;

	return (
		<div
			className={classNames('border-t-1 border-b-1 absolute z-20 box-border flex h-5 flex-col bg-white text-xs', {
				'cursor-not-allowed': e.eventItem.type === CalendarEventType.UNKNOWN,
				'border-l-1 rounded-l-default': !e.calculatedFrom && !(clickedTimeSlot === e.eventItem.referenceId),
				'border-r-1 rounded-r-default': !e.calculatedTo && !(clickedTimeSlot === e.eventItem.referenceId),
				'rounded-default border-t-2 border-b-2 border-l-2 border-r-2': clickedTimeSlot === e.eventItem.referenceId,
				'border-blue-light': e.eventItem.type === CalendarEventType.DRIVING_SLIP && clickedTimeSlot === e.eventItem.referenceId,
				'border-cyan': e.eventItem.type === CalendarEventType.EDUCATION && clickedTimeSlot === e.eventItem.referenceId,
				'border-green': e.eventItem.type === CalendarEventType.ON_CALL && clickedTimeSlot === e.eventItem.referenceId,
				'border-red':
					(e.eventItem.type === CalendarEventType.SICK_LEAVE || e.eventItem.type === CalendarEventType.SLEEP || e.eventItem.type === CalendarEventType.VACATION) &&
					clickedTimeSlot === e.eventItem.referenceId,
				'opacity-50': isDragging,
			})}
			style={{
				width: `calc(${400 * (durationInHours ?? 0)}% + ${(durationInHours ?? 0) * 2}px)`,
			}}
			onClick={clickEvent => {
				clickEvent.stopPropagation();
				timeSlotClickHandler(e.eventItem.type === CalendarEventType.UNKNOWN ? '' : e.eventItem.referenceId);
			}}
			onDoubleClick={clickEvent => {
				clickEvent.stopPropagation();
				if (e.eventItem.drivingSlip !== null && canEdit) {
					setShowEditEvent(e.eventItem.type === CalendarEventType.UNKNOWN ? undefined : e.eventItem);
				}
			}}
			ref={e.eventItem.type === CalendarEventType.UNKNOWN ? null : ref}
		>
			{/* {dateToDateTimeString(new Date(e.eventItem.timeRange.from+'Z')).split(' ')[1]}-{dateToDateTimeString(new Date(e.eventItem.timeRange.to+'Z')).split(' ')[1]}({e.index}) */}
			<PopoverDrivingSlipInfo drivingSlip={e.eventItem.drivingSlip} isDragging={isDragging}>
				<div>
					<PopoverDrivingSlipActions
						visible={e.eventItem.drivingSlip !== null && canEdit && !isDragging && clickedTimeSlot === e.eventItem.referenceId}
						onCopyClick={e.eventItem.type === CalendarEventType.DRIVING_SLIP ? () => setShowCopyEvent(e.eventItem) : undefined}
						onEditClick={e.eventItem.type === CalendarEventType.DRIVING_SLIP ? () => setShowEditEvent(e.eventItem) : undefined}
						onChangeClick={e.eventItem.type === CalendarEventType.DRIVING_SLIP ? () => setConvertToRequisition(true) : undefined}
					>
						<div
							className="relative h-4"
							style={
								clickedTimeSlot === e.eventItem.referenceId
									? {
											paddingTop: '0px',
											paddingLeft: '0px',
											paddingRight: '0px',
									  }
									: {
											paddingTop: '1px',
											paddingLeft: '1px',
											paddingRight: '1px',
									  }
								/* 
                                     ? { paddingTop: '3px', paddingLeft: '3px', paddingRight: '3px' }
                                    : { paddingTop: '4px', paddingLeft: '4px', paddingRight: '4px' }
                                    */
							}
						>
							<p className="flex flex-row items-center truncate">
								{e.eventItem.type === CalendarEventType.UNKNOWN && <FontAwesomeIcon icon={faLock} size="sm" className="mr-1" />}
								{e.eventItem.drivingSlip?.urgent && <FontAwesomeIcon title={t('common.urgent')} icon={faExclamationCircle} size="sm" className="text-orange mr-1" />}
								{e.eventItem.drivingSlip?.urgent && <FontAwesomeIcon title={t('case.SKAFOR')} icon={faExclamationCircle} size="sm" className="text-purple mr-1" />}
								{e.eventItem.drivingSlip ? (
									<Link to={`/case/${e.eventItem.drivingSlip.case.id}`} target="_blank" className="hover:underline">
										{e.eventItem.title}
									</Link>
								) : (
									<span>{e.eventItem.title === 'PRIVATE' ? t('common.privat') : e.eventItem.title}</span>
								)}
							</p>
						</div>
					</PopoverDrivingSlipActions>
				</div>
			</PopoverDrivingSlipInfo>
			<div
				className={classNames('rounded-b-default h-1 w-full bg-gray-600', {
					'bg-blue-light': e.eventItem.type === CalendarEventType.DRIVING_SLIP,
					'bg-cyan': e.eventItem.type === CalendarEventType.EDUCATION,
					'bg-green': e.eventItem.type === CalendarEventType.ON_CALL,
					'bg-red': e.eventItem.type === CalendarEventType.SICK_LEAVE || e.eventItem.type === CalendarEventType.SLEEP || e.eventItem.type === CalendarEventType.VACATION,
					'bg-gray-900': e.eventItem.type === CalendarEventType.UNKNOWN,
				})}
				style={{
					marginTop: clickedTimeSlot === e.eventItem.referenceId ? '1px' : '0px',
					borderBottomLeftRadius: !e.calculatedFrom && !(clickedTimeSlot === e.eventItem.referenceId) ? '0.25rem' : 0,
					borderBottomRightRadius: !e.calculatedTo && !(clickedTimeSlot === e.eventItem.referenceId) ? '0.25rem' : 0,
				}}
			/>
		</div>
	);
};

export default DraggableEventItem;
