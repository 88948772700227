import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { GetWebMachineLocations, GetWebMachines, GetWebMachinesVariables, GetWebMachines_machines, MachineReserve, MachineReserveVariables } from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import Table from '@ssg/common/Components/Table';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';

const GET_MACHINES = loader('src/GraphQL/Machines/GetWebMachines.gql');
const GET_MACHINE_LOCATIONS = loader('src/GraphQL/Machines/GetWebMachineLocations.gql');
const MACHINE_RESERVE = loader('src/GraphQL/Machines/MachineReserve.gql');

const MachinesMyReservations: React.FC = () => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const [activeMachine, setActiveMachine] = React.useState<GetWebMachines_machines | undefined>(undefined);
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);

	const currentUser = userContext.user?.email.split('@')[0].toUpperCase() ?? null;

	const { data, loading } = useQuery<GetWebMachines, GetWebMachinesVariables>(GET_MACHINES, {
		fetchPolicy: 'cache-and-network',
		variables: {
			reservationByRessource: currentUser,
		},
	});

	const machines = React.useMemo(() => {
		return data?.machines ? data.machines : [];
	}, [data]);

	const { data: machineLocationsData } = useQuery<GetWebMachineLocations>(GET_MACHINE_LOCATIONS);

	const machineLocations = React.useMemo(() => machineLocationsData?.machineLocations ?? [], [machineLocationsData]);

	const [MachineReserve] = useMutation<MachineReserve, MachineReserveVariables>(MACHINE_RESERVE);

	// Clear reservation on machine
	const clearMachineReservation = async (activeMachine: GetWebMachines_machines | undefined) => {
		if (typeof activeMachine === 'undefined') {
			return;
		}

		try {
			await MachineReserve({
				variables: {
					machineInput: {
						erpReferenceNo: activeMachine.erpReferenceNo ?? '',
						startingDate: activeMachine.dateStart ?? '',
						startingTime: activeMachine.startTime ?? '',
						erpCaseReference: activeMachine.eRPCaseReference ?? '',
						consumption: activeMachine.consumption ?? 0,
						hibernate: activeMachine.hibernate ?? false,
						hibernationReason: activeMachine.hibernationReason ?? '',
						erpLocationReference: activeMachine.eRPLocationReference ?? '',
						allocationType: activeMachine.type ?? '',
						reservationByRessource: null,
						reservationDescription: null,
						reservationOnCase: null,
						reservationStart: null,
						reservationEnd: null,
						unavailableReason: activeMachine.unavailableReason,
					},
				},
			});

			setActiveMachine(undefined);
			setShowConfirmModal(false);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<BoxContainer>
			<Box full>
				<Table
					data={loading ? [] : machines}
					keySelector={m => m.erpReferenceNo}
					loading={loading}
					noDataFoundText={loading ? '' : 'machines.noMachinesFound'}
					columns={[
						{
							label: 'machines.no',
							selectFn: m => <div>{m.erpReferenceNo}</div>,
							sortFn: (a, b) => a.erpReferenceNo.localeCompare(b.erpReferenceNo),
						},
						{
							label: 'machines.hibernation',
							selectFn: m => (
								<div>
									<p>{m.hibernate ? t('common.yes') : t('common.no')}</p>
									<p>{m.hibernationReason}</p>
								</div>
							),
							sortFn: (a, b) => Number(a.hibernate) - Number(b.hibernate),
						},
						{
							label: 'common.type',
							selectFn: m => <div>{m.name}</div>,
							sortFn: (a, b) => a.name.localeCompare(b.name),
						},
						{
							label: 'machines.origin',
							selectFn: m => <div>{toSentenceCase(m.originLocation)}</div>,
							sortFn: (a, b) => a.originLocation.localeCompare(b.originLocation),
						},
						{
							label: 'common.case',
							selectFn: m => <div>{m.eRPCaseReference ?? ''}</div>,
							sortFn: (a, b) => (a.eRPCaseReference ?? '').localeCompare(b.eRPCaseReference ?? ''),
						},
						{
							label: 'common.location',
							selectFn: m => <div>{machineLocations.find(l => l.locationCode === m.eRPLocationReference)?.name ?? ''}</div>,
							sortFn: (a, b) =>
								(machineLocations.find(l => l.locationCode === a.eRPLocationReference)?.name ?? '').localeCompare(
									machineLocations.find(l => l.locationCode === b.eRPLocationReference)?.name ?? '',
								),
						},
						{
							label: 'common.date',
							selectFn: m => <div>{`${m.reservationStart} - ${m.reservationEnd}`}</div>,
							sortFn: (a, b) => (a.reservationStart ?? '').localeCompare(b.reservationStart ?? ''),
						},
						{
							label: 'common.description',
							selectFn: m => <div>{m.reservationDescription}</div>,
						},
						{
							label: 'machines.cancelReserve',
							classNameTh: 'text-right pr-4',
							align: 'RIGHT',
							selectFn: m => (
								<div className="flex w-full justify-end">
									<Button
										secondary
										icon={faCalendarTimes}
										onClick={() => {
											setActiveMachine(m);
											setShowConfirmModal(true);
										}}
									/>
								</div>
							),
						},
					]}
				/>
			</Box>

			<Modal
				title={t('machines.machineAlreadyReserved')}
				close={() => {
					setShowConfirmModal(false);
					setActiveMachine(undefined);
				}}
				visible={showConfirmModal}
				size={ModalSize.SMALL}
				body={
					<div className="text-blue-calm">
						<div className="my-2">
							<p>{t('machines.cancelReserveText')}</p>
							<p className="font-semibold">{activeMachine?.erpReferenceNo}</p>
							<p className="font-semibold">{`${activeMachine?.reservationStart} - ${activeMachine?.reservationEnd}`}</p>
						</div>

						<div className="mt-4 flex">
							<Button success text="machines.cancelReserve" submit onClick={() => clearMachineReservation(activeMachine)} />
							<Button
								danger
								text="common.decline"
								onClick={() => {
									setShowConfirmModal(false);
									setActiveMachine(undefined);
								}}
								className="ml-2"
							/>
						</div>
					</div>
				}
			/>
		</BoxContainer>
	);
};

export default MachinesMyReservations;
