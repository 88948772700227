import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const DrivingSlipCaseCreationSchema = yup.object().shape({
	drivingWanted: yup.boolean(),

	drivingDateTime: yup.date().when('drivingWanted', {
		is: (drivingWanted: boolean) => drivingWanted,
		then: yup.date().required(),
		otherwise: yup.date().transform((val, original) => (original === '' ? undefined : val)),
	}),

	deadline: yup.date().when('drivingWanted', {
		is: (drivingWanted: boolean) => drivingWanted,
		then: yup.date(),
		otherwise: yup.date().transform((val, original) => (original === '' ? undefined : val)),
	}),

	drivingBy: yup.array().of(yup.string()).nullable(),

	immediatelyPlan: yup.boolean(),

	drivingTimeEstimate: yup
		.number()
		.transform(cv => (isNaN(cv) ? null : cv))
		.nullable(),

	additionalDays: yup.array(yup.string()),

	files: yup.array(yup.mixed()),

	location: yup.string().when('drivingWanted', {
		is: (drivingWanted: boolean) => drivingWanted,
		then: yup.string().required(),
		otherwise: yup.string().transform((val, original) => (original === '' ? undefined : val)),
	}),

	department: yup.string().when('drivingWanted', {
		is: (drivingWanted: boolean) => drivingWanted,
		then: yup.string().required(),
		otherwise: yup.string().transform((val, original) => (original === '' ? undefined : val)),
	}),

	category: yup.string(),
});

