import React from 'react';
import { useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { IImportantInfo } from '../../Schemas/IImportantInfo';
import { importantInfoSchema } from '../../Schemas/ImportantInfoSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Textarea from '@ssg/common/Components/Textarea';
import Box from '../../Components/Layout/Box';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface Props {
	caseData: GetSingleCase_case;
}

const ImportantInfoBox: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const [editActive, setEditActive] = React.useState(false);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, errors, register } = useForm<IImportantInfo>({
		resolver: yupResolver(importantInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IImportantInfo) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				importantInfo: data.importantInfo,
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, importantInfo }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				importantInfo: importantInfo,
			},
		});
	};
	return (
		<>
			<Box title="case.importantInfo" className="mt-8" full onClick={() => setEditActive(true)} icon={faEdit}>
				<hr />
				<p className="whitespace-pre-wrap break-words text-xs">{caseData.importantInfo}</p>
			</Box>
			<Modal
				title="case.editImportantInfo"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.SMALL}
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<Textarea
							title="case.importantInfo"
							required
							innerRef={register}
							name="importantInfo"
							rows={3}
							errorMessage={errors.importantInfo?.message ?? ''}
							defaultValue={caseData.importantInfo ?? ''}
						/>

						<div className="mt-3">
							<Button submit success text="case.editImportantInfo" loading={updateCaseSubmitting} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default ImportantInfoBox;
