import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { GetTimedMessageChanges, GetTimedMessageChangesVariables, GetTimedMessageChanges_timedMessage_changes_after, GetTimedMessageChanges_timedMessage_changes_before } from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import Loading from '@ssg/common/Components/Loading';

const GET_TIMED_MESSAGE_CHANGES = loader('src/GraphQL/TimedMessages/GetTimedMessageChanges.gql');

interface IProps {
	timedMessageId: string;
	open: boolean;
	close: () => void;
}

const TimedMessageChangeLog: React.FC<IProps> = ({ timedMessageId, open, close }) => {
	const { t } = useTranslation();

	const { data, loading } = useQuery<GetTimedMessageChanges, GetTimedMessageChangesVariables>(GET_TIMED_MESSAGE_CHANGES, {
		variables: {
			id: timedMessageId,
		},
	});

	const sorted = React.useMemo(() => (data?.timedMessage.changes ?? []).slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [data?.timedMessage.changes]);

	return (
		<>
			<Modal
				title={t('catalog.timedMessage.timedMessagesChanges')}
				size={ModalSize.MEDIUM}
				visible={open}
				close={close}
				body={
					<>
						{loading ? (
							<div className="relative h-28 w-full">
								<Loading />
							</div>
						) : (
							<div className="text-blue space-y-4">
								{sorted.map(c => (
									<div key={c.timestamp}>
										<strong>
											{dateToDateTimeString(c.timestamp)}
											&nbsp;{t('common.by')}&nbsp;
											{c.user.name}&nbsp;({c.user.email})
										</strong>

										<div className="mt-1">{renderChanges(t, c.before, c.after)}</div>
									</div>
								))}
							</div>
						)}
					</>
				}
			/>
		</>
	);
};
export default TimedMessageChangeLog;

function renderChanges(t: TFunction, before: GetTimedMessageChanges_timedMessage_changes_before, after: GetTimedMessageChanges_timedMessage_changes_after): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// StartTime was changed
	if (!(before.startTime === null && after.startTime === null) && before.startTime !== after.startTime) {
		const tValues = {
			before: before.startTime ? formatDate(new Date(before.startTime)) : NO_VALUE,
			after: after.startTime ? formatDate(new Date(after.startTime)) : NO_VALUE,
		};

		result.push(
			<div key="startTime" className="my-2">
				<Trans t={t} i18nKey="catalog.timedMessage.changelog.startTime" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// EndTime was changed
	if (!(before.endTime === null && after.endTime === null) && before.endTime !== after.endTime) {
		const tValues = {
			before: before.endTime ? DateTime.fromISO(before.endTime).setLocale('da').toLocaleString(DateTime.DATE_SHORT) : NO_VALUE,
			after: after.endTime ? DateTime.fromISO(after.endTime).setLocale('da').toLocaleString(DateTime.DATE_SHORT) : NO_VALUE,
		};

		result.push(
			<div key="endTime" className="my-2">
				<Trans t={t} i18nKey="catalog.timedMessage.changelog.endTime" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Message was changed
	if (!(before.message === null && after.message === null) && before.message !== after.message) {
		const tValues = {
			before: before.message ?? NO_VALUE,
			after: after.message ?? NO_VALUE,
		};

		result.push(
			<div key="message" className="my-2">
				<Trans t={t} i18nKey="catalog.timedMessage.changelog.message" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Acitve was changed
	if (!(before.active === null && after.active === null) && before.active !== after.active) {
		const tValues = {
			before: before.active ? t('common.yes') : t('common.no'),
			after: after.active ? t('common.yes') : t('common.no'),
		};

		result.push(
			<div key="active" className="my-2">
				<Trans t={t} i18nKey="catalog.timedMessage.changelog.active" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Global type was changed
	if (!(before.globalType === null && after.globalType === null) && before.globalType !== after.globalType) {
		const tValues = {
			before: t(`catalog.changelog.${before.globalType}`) ?? NO_VALUE,
			after: t(`catalog.changelog.${after.globalType}`) ?? NO_VALUE,
		};

		result.push(
			<div key="globalType" className="my-2">
				<Trans t={t} i18nKey="catalog.changelog.globalType" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Catalogs was changed
	if (!(before.catalogs === null && after.catalogs === null)) {
		if (before.catalogs?.length !== after.catalogs?.length) {
			let beforeString = '';
			before.catalogs?.forEach(afCat => {
				beforeString += `${afCat?.propertyNumber}, `;
			});
			let afterString = '';
			after.catalogs?.forEach(diff => {
				afterString += `${diff?.propertyNumber}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="catalogs" className="my-2">
					<Trans t={t} i18nKey="catalog.timedMessage.changelog.catalogs" values={tValues} components={[<mark />]} />
				</div>,
			);
		} else if (_.difference(before.catalogs, after.catalogs ?? []).length !== 0) {
			let beforeString = '';
			before.catalogs?.forEach(afCat => {
				beforeString += `${afCat?.propertyNumber}, `;
			});
			let afterString = '';
			after.catalogs?.forEach(diff => {
				afterString += `${diff?.propertyNumber}, `;
			});

			const tValues = {
				before: beforeString.trim().endsWith(',') ? beforeString.slice(0, beforeString.length - 2) : beforeString,
				after: afterString.trim().endsWith(',') ? afterString.slice(0, afterString.length - 2) : afterString,
			};

			result.push(
				<div key="catalogs" className="my-2">
					<Trans t={t} i18nKey="catalog.timedMessage.changelog.catalogs" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	return result;
}
