import React from 'react';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { ScreeningLineType } from '../../GraphQL';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

export type BoxFormDataItem = SelectOption & { warning?: boolean; divClassName?: string };

interface Props {
	data: BoxFormDataItem[];
	index?: number;
	keyId: string;
	type?: ScreeningLineType;
}

const BoxFormDataViewer: React.FC<Props> = ({ keyId, data, index, children }): React.ReactElement => {
	const { t, i18n } = useTranslation();

	return (
		<div className="flex flex-col text-blue">
			{data.map((d, i) => {
				const text = i18n.exists(d.value) ? t(d.value) : d.value;
				return (
					<React.Fragment key={d.label + keyId.replace(/\s/g, '-') + i}>
						<div
							className={classNames('flex py-1', {
								'border-t-1 border-gray-300': i !== 0 && d.label !== '',
							})}
						>
							<div className="w-1/3 text-xs font-semibold">{t(d.label)}</div>
							{d.value.length > 0 && (
								<div className={classNames(d.divClassName, 'whitespace-pre-wrap break-words text-xs w-2/3', { 'text-red font-bold': d.warning === true })} title={text}>
									{d.warning === true && <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" />}
									{text}
								</div>
							)}
						</div>
						{i === index && children}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default BoxFormDataViewer;
