import React from 'react';
import { faDotCircle, faEnvelopeOpenDollar, faCheck, faFileAlt, faFileInvoiceDollar, faPhoneAlt, faUserCrown, faShoppingCart, faDiamond } from '@fortawesome/pro-regular-svg-icons';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { GetWebCases_cases, ScalePointStatus, GetWebCases_cases_projectManager, InvoiceStatus } from '../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressIcon, { ProgressColor } from './ProgressIcon';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import UserContext from '../UserContext';

interface Props {
	caseData: GetWebCases_cases;
	compact?: boolean;
}

const AdministrativeProgress: React.FC<Props> = ({ caseData, compact = false }): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const priorityCustomer = useFlag(FeatureFlagEnums.PRIORITYCUSTOMER);

	const getScalePointColor = (scalePointStatus: ScalePointStatus): ProgressColor => {
		switch (scalePointStatus) {
			case ScalePointStatus.APPROVED:
				return ProgressColor.GREEN;
			case ScalePointStatus.CONNECTED:
				return ProgressColor.ORANGE;
			case ScalePointStatus.REJECTED:
				return ProgressColor.RED;
			case ScalePointStatus.ERRORED:
				return ProgressColor.RED;
			default:
				return ProgressColor.TRANSPARRENT;
		}
	};

	const getInvoiceStatusColor = (invoiceStatus: InvoiceStatus): ProgressColor => {
		switch (invoiceStatus) {
			case InvoiceStatus.AWAITING_APPROVAL:
				return ProgressColor.ORANGE;
			case InvoiceStatus.APPROVED:
				return ProgressColor.ORANGE;
			case InvoiceStatus.REJECTED:
				return ProgressColor.RED;
			case InvoiceStatus.POSTED:
				return ProgressColor.GREEN;
			case InvoiceStatus.DRAFT:
				return ProgressColor.BLUE;
			default:
				return ProgressColor.TRANSPARRENT;
		}
	};

	const getProjectManagerColor = (projectManager: GetWebCases_cases_projectManager | null) => {
		if (projectManager === null) {
			return ProgressColor.RED;
		}
		if (projectManager.id.includes('ssgcloud.onmicrosoft')) {
			return ProgressColor.RED;
		}

		return ProgressColor.GREEN;
	};

	const getCallbackIconColor = (calledbackStatus: boolean | null , awaitingStatus : boolean) => {

		if (calledbackStatus === false && awaitingStatus === false) {
			return ProgressColor.RED;
		}

		if (calledbackStatus === false && awaitingStatus === true) {
			return ProgressColor.ORANGE;
		}

		return ProgressColor.GREEN;
	};

	const files = [
		caseData.caseReports?.indoorClimateReportFormData && {
			filename: t('reports.reportTypes.indoorClimate'),
			id: 'indoorClimate',
		},
		caseData.caseReports?.inspectionReportFormData && {
			filename: t('reports.reportTypes.inspection'),
			id: 'inspection',
		},
		caseData.caseReports?.moistureReportFormData && {
			filename: t('reports.reportTypes.water'),
			id: 'water',
		},
		caseData.caseReports?.moistureTrygReportFormData && {
			filename: t('reports.reportTypes.trygWater'),
			id: 'trygWater',
		},
	];

	const reports = files.filter(r => r !== null);

	return (
		<div className="w-32">
			<div className="flex flex-row flex-wrap">
				{/* Called back ( Tilbageringet*/}
				<ProgressIcon icon={faPhoneAlt} popperTitle="case.calledBack" iconColor={ getCallbackIconColor(caseData.visitation.calledBack.value , caseData.visitation.awaiting.value)}>
					<div>
						
						{caseData.visitation.calledBack.value
							? `${t('common.yes')} ${caseData.visitation.calledBack.timestamp ? `(${dateToDateTimeString(caseData.visitation.calledBack.timestamp)})` : ''}`
							: `${t('common.no')}`}
					</div>
					
					{caseData.visitation.calledBack.value  && <div>{caseData.visitation.calledBack.value ? caseData.visitation.calledBack.comment : ''}</div>}
					
					{caseData.visitation.awaiting.value && <>  <div>{`${t('common.awaiting')}` } {caseData.visitation.awaiting.value ? `${t('common.yes')}` :  `${t('common.no')}`}  </div> <br/> <div>
					{caseData.visitation.awaiting.comment}</div></>} 
				</ProgressIcon>

				{/* Case Owner / Sagsejer */}
				<ProgressIcon icon={faUserCrown} popperTitle="case.ssgProjectManager" iconColor={getProjectManagerColor(caseData.projectManager)}>
					{caseData.projectManager !== null ? (
						<div>
							<div>{caseData.projectManager.name}</div>
							<div>{caseData.projectManager.phone}</div>
							<div>{caseData.projectManager.email}</div>
						</div>
					) : (
						<div>{t('common.noneSpecified')}</div>
					)}
				</ProgressIcon>

				{/* Scalepoint status */}
				<ProgressIcon icon={faDotCircle} popperTitle="case.scalePointStatus" iconColor={getScalePointColor(caseData.scalePointStatus)}>
					{t('case.scalePointStatusCodes.' + caseData.scalePointStatus)}
				</ProgressIcon>

				{/* SP 1 Report Created status */}
				<ProgressIcon icon={faDiamond} popperTitle="case.isSpPhaseOneReportCreated" iconColor={caseData.visitation.isSpPhaseOneReportCreated  ? ProgressColor.GREEN : ProgressColor.TRANSPARRENT}>
					{caseData.visitation.isSpPhaseOneReportCreated ? 'Yes' : 'No'}
				</ProgressIcon>

				{/* Reports */}
				<ProgressIcon icon={faFileAlt} popperTitle="common.reports" iconColor={reports.length > 0 ? ProgressColor.BLUE : ProgressColor.TRANSPARRENT}>
					{reports.length > 0 ? (
						reports.map((r, i) => (
							<p
								key={r?.id + 'popper'}
								className={classNames('text-sm', {
									'text-green': i === 0 && caseData.reportSent,
								})}
							>
								{i === 0 && caseData.reportSent && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
								{r?.filename}
							</p>
						))
					) : (
						<p className="text-sm">
							<FontAwesomeIcon icon={faCheck} /> {t('case.reportNone')}
						</p>
					)}
				</ProgressIcon>

				{/* Requisitions */}
				<ProgressIcon icon={faShoppingCart} popperTitle="requisitions.overviewTitle" iconColor={caseData.activeRequisitions ? ProgressColor.RED : ProgressColor.GREEN}>
					{caseData.activeRequisitions ? <div>{t('case.activeRequisitions')}</div> : <div>{t('case.noActiveRequisitions')}</div>}
				</ProgressIcon>

				{/*  Klar til faktura */}
				<ProgressIcon icon={faFileInvoiceDollar} popperTitle="case.billingReady" iconColor={caseData.billingReady ? ProgressColor.GREEN : ProgressColor.RED}>
					{caseData.billingReady ? t('common.yes') : t('common.no')}
				</ProgressIcon>

				{/* Faktura status */}
				<ProgressIcon
					icon={faEnvelopeOpenDollar}
					popperTitle="case.invoice.title"
					iconColor={caseData.invoices.length !== 0 ? getInvoiceStatusColor(caseData.invoices[caseData.invoices.length - 1].status) : ProgressColor.TRANSPARRENT}
				>
					{caseData.invoices.length !== 0 ? (
						<table className="text-left text-xs">
							<thead>
								<tr>
									<th>{t('case.invoice.invoicenr')}</th>
									<th>{t('case.invoice.category')}</th>
									<th>{t('common.status')}</th>
								</tr>
							</thead>
							<tbody>
								{caseData.invoices.map(inv => {
									const iconColor = getInvoiceStatusColor(inv.status);
									return (
										<tr
											key={inv.erpReferenceNo + 'popper'}
											className={classNames({
												'text-orange': iconColor === 'ORANGE',
												'text-blue': iconColor === 'BLUE',
												'text-green': iconColor === 'GREEN',
												'text-red': iconColor === 'RED',
												'text-blue opacity-25': iconColor === 'TRANSPARRENT',
											})}
										>
											<td className="pr-4">{inv.erpReferenceNo}</td>
											<td className="pr-4 ">
												<div>{t('case.invoice.categories.' + inv.category)}</div>
											</td>
											<td className="pr-2">
												<div>{t('case.invoice.statuses.' + inv.status)}</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						<p>{t('case.invoice.noActiveInvoice')}</p>
					)}
				</ProgressIcon>

				{/* Drivingslip */}
				{/* <ProgressIcon icon={faFileCheck} popperTitle="case.completed" iconColor={getStatusColor()}>
					<div>{t(`case.${caseData.status}`)}</div>
				</ProgressIcon> */}

				{/* Priority Customer */}
				{(priorityCustomer && !userContext.user?.external) &&
					<ProgressIcon icon={faTriangle} popperTitle="caseOverview.filters.priorityCustomer" iconColor={caseData.debitor.priorityCustomer ? ProgressColor.RED : ProgressColor.TRANSPARRENT}>
						{caseData.debitor.priorityCustomer ? t('common.yes') : t('common.no')}
					</ProgressIcon>
				}
			</div>
		</div>
	);
};

export default AdministrativeProgress;
