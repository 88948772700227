import React from 'react';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import {
	ChangeOfferStatus,
	ChangeOfferStatusVariables,
	CloseOppertunityCodes,
	HandleOffer,
	HandleOfferVariables,
	JobTasks,
	JobTasksVariables,
	OfferLineInput,
	OfferLineStatus,
	OfferQuoteTypeEnum,
	OfferRessourceType,
	Offers_offers,
	SalesHeaderEnum,
} from '../../../GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Textarea from '@ssg/common/Components/Textarea';
import OfferLine from './OfferLine';
import Loading from '@ssg/common/Components/Loading';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import Toggle from '@ssg/common/Components/Toggle';
import TextButton from '@ssg/common/Components/TextButton';

const HANDLE_OFFER = loader('../../../GraphQL/Offer/HandleOffer.gql');
const CHANGE_STATUS = loader('../../../GraphQL/Offer/ChangeOfferStatus.gql');
const GET_CLOSE_CODES = loader('../../../GraphQL/Offer/GetCloseOppertunityCodes.gql');
const GET_JOBTASKS = loader('../../../GraphQL/Offer/GetJobTask.gql');

interface Props {
	readOnly?: boolean;
	open: boolean;
	close: () => void;
	caseNo: string;
	caseId: string;
	assortmentCode: string;
	offer?: Offers_offers;
	refetchOffers(): Promise<void>;
}

interface Header {
	id: string;
	name: string;
	status: OfferLineStatus;
	lines: Line[];
	hideSum: boolean;
}

interface Line {
	id: string;
	offerLine: OfferLineInput & { totalCost: number };
}

interface StatusChanger {
	reasonCode: string;
	action: SalesHeaderEnum;
}

const OfferModal: React.FC<Props> = ({ readOnly = false, open, close, caseNo, caseId, assortmentCode, offer, refetchOffers }) => {
	const { t } = useTranslation();

	const offerToEditableOffer = (thisOffer: Offers_offers): Header[] => {
		const headerLines = thisOffer.lines.filter(l => l.header);
		const offerLines = thisOffer.lines.filter(l => !l.header);
		const headers: Header[] = headerLines.map(h => ({
			id: h.systemId ?? '',
			name: h.description,
			status: OfferLineStatus.UPDATE,
			hideSum: h.hideSum,
			lines: offerLines
				.filter(o => o.sortingIndex === h.sortingIndex)
				.map(o => ({
					id: o.systemId ?? '',
					offerLine: {
						planningDate: o.planningDate,
						description: o.description,
						quantity: o.quantity,
						unitPrice: o.unitPrice,
						newUnitPrice: o.unitPrice,
						no: o.no,
						workType: o.workType ?? '',
						documentNo: o.documentNo,
						systemId: o.systemId,
						offerLineStatus: OfferLineStatus.UPDATE,
						eRPReferenceTask: o.eRPReferenceTask,
						type: o.type,
						option: o.option,
						hideSum: o.hideSum,
						header: o.header,
						sortingIndex: o.sortingIndex,
						lineNo: o.lineNo,
						caseERPReferenceNo: caseNo,
						totalCost: o.unitCost * o.quantity,
					},
				})),
		}));
		return headers;
	};

	const [headerText, setHeaderText] = React.useState(offer?.headerText ?? '');
	const [footerText, setFooterText] = React.useState(offer?.footerText ?? '');
	const [headers, setHeaders] = React.useState<Header[]>(typeof offer !== 'undefined' ? offerToEditableOffer(offer) : []);
	const [offerType, setOfferType] = React.useState(typeof offer === 'undefined' ? OfferQuoteTypeEnum.ESTIMATE : offer.quoteType ?? OfferQuoteTypeEnum.ESTIMATE);
	const [competitionOffer, setCompetitionOffer] = React.useState(typeof offer !== 'undefined' ? offer.competitionOffer : false);
	const [allOfferLinesOld, setAllOfferLinesOld] = React.useState(typeof offer !== 'undefined');

	const originalSum = typeof offer !== 'undefined' ? offer.lines.reduce((acc, currentObj) => acc + currentObj.unitPrice * currentObj.quantity, 0) : 0;
	const originalCost = typeof offer !== 'undefined' ? offer.lines.reduce((acc, currentObj) => acc + currentObj.unitCost * currentObj.quantity, 0) : 0;
	const originalCoverage = (100 * (originalSum - originalCost)) / originalSum;

	const [offerSum, setOfferSum] = React.useState(originalSum);
	const [offerCost, setOfferCost] = React.useState(originalCost);
	const [offerCoverage, setOfferCoverage] = React.useState(originalCoverage);
	const [offerProfit, setOfferProfit] = React.useState(originalSum - originalCost);
	const [rCode, setReasonCode] = React.useState('LOST');

	const { register } = useForm();

	const { data: jobTaskData, loading: jobTaskLoading } = useQuery<JobTasks, JobTasksVariables>(GET_JOBTASKS, {
		fetchPolicy: 'cache-and-network',
		variables: { erpReferenceNo: caseNo },
	});

	const jobTasks = React.useMemo(() => {
		return (jobTaskData?.jobTasks ?? []).map((w): SelectOption => ({ value: w?.jobTaskNo ?? '', label: w?.description ?? '' } ?? []));
	}, [jobTaskData]);

	//Updates the header array with changes from the offerline
	const handleLineChange = React.useCallback((headerIndex: number, lineIndex: number, lineId: string, offerLine: OfferLineInput & { totalCost: number }) => {
		setHeaders(currentHeaders => {
			const copy = [...currentHeaders];
			const headerLines = copy[headerIndex].lines;
			headerLines.splice(lineIndex, 1, {
				id: lineId,
				offerLine: offerLine,
			});
			copy[headerIndex] = {
				...copy[headerIndex],
				lines: headerLines,
			};

			return copy;
		});
	}, []);

	const { data: closeOppCodes } = useQuery<CloseOppertunityCodes>(GET_CLOSE_CODES);

	const closeOppertunityCodes = React.useMemo(() => {
		return (closeOppCodes?.closeOppertunityCodes ?? []).map((w): SelectOption => ({ value: w.code, label: w.description } ?? []));
	}, [closeOppCodes]);

	// Edits exsisting lines(with doumentNo) for deletion or removes new lines(without documentNo) from array
	const deleteLine = (headerIndex: number, lineIndex: number, lineId: string, offerLine: OfferLineInput & { totalCost: number }) => {
		if (offerLine.documentNo === null) {
			setHeaders(currentHeaders => {
				const copy = [...currentHeaders];
				const headerLines = copy[headerIndex].lines;
				copy[headerIndex] = {
					...copy[headerIndex],
					lines: headerLines.filter(h => h.id !== lineId),
				};

				return copy;
			});
		}

		if (offerLine.documentNo !== null) {
			handleLineChange(headerIndex, lineIndex, lineId, {
				...offerLine,
				offerLineStatus: OfferLineStatus.DELETE,
			});
		}
	};

	// Edits excisting headers for deletion or removes new headers from array.
	const deleteHeader = (headerIndex: number, header: Header) => {
		if (header.id.length === 0) {
			setHeaders(headers.filter((_, index) => index !== headerIndex));
		}

		if (header.id.length > 0) {
			setHeaders(currentHeaders => {
				const copy = [...currentHeaders];
				const headerLines = copy[headerIndex].lines.filter(l => l.offerLine.offerLineStatus !== OfferLineStatus.CREATE);
				copy[headerIndex] = {
					...copy[headerIndex],
					status: OfferLineStatus.DELETE,
					lines: headerLines.map(hl => ({
						...hl,
						offerLine: {
							...hl.offerLine,
							offerLineStatus: OfferLineStatus.DELETE,
						},
					})),
				};

				return copy;
			});
		}
	};

	// Edits excisting headers for deletion or removes new headers from array.
	const hideSumHeader = (headerIndex: number, header: Header, bool: boolean) => {
		setHeaders(currentHeaders => {
			const copy = [...currentHeaders];
			const headerLines = copy[headerIndex].lines;

			copy[headerIndex] = {
				...copy[headerIndex],
				hideSum: bool,
				lines: headerLines.map(hl => ({
					...hl,
					offerLine: { ...hl.offerLine, hideSum: bool },
				})),
			};
			return copy;
		});
	};

	// Creates new header line with empty values except planningdate,description, header and sorting index - type most be 0 and no must be '' (blank)
	const createEmptyLine = (
		sortIndex: number,
		hideSum: boolean,
		description = '',
		header = false,
		no = '',
		type = OfferRessourceType.ITEM,
		offerLineStatus = OfferLineStatus.CREATE,
		documentNo: string | null = null,
		systemId: string | null = null,
	): Line => {
		return {
			id: new Date().toISOString(), //Used as a temp UI id
			offerLine: {
				planningDate: new Date().toISOString().slice(0, 'yyyy-mm-dd'.length),
				description: description,
				quantity: 1,
				unitPrice: 0,
				newUnitPrice: 0,
				no: no,
				workType: '',
				documentNo: documentNo,
				systemId: systemId,
				offerLineStatus: offerLineStatus,
				eRPReferenceTask: '',
				type: type,
				option: false,
				hideSum: hideSum,
				header: header,
				sortingIndex: sortIndex,
				lineNo: 0,
				caseERPReferenceNo: caseNo,
				totalCost: 0,
			},
		};
	};

	const [changeOfferStatus, { loading: changeOfferStatusLoading }] = useMutation<ChangeOfferStatus, ChangeOfferStatusVariables>(CHANGE_STATUS);

	const postChangeOfferStatus = async (reasonCode: string, action: SalesHeaderEnum, documentNo: string) => {
		try {
			await changeOfferStatus({
				variables: {
					caseERPReferenceNo: caseNo,
					offerERPReferenceNo: documentNo,
					reasonCode: reasonCode,
					action: action,
				},
			});
			refetchOffers();
		} catch (e) {
			console.log(e);
		}
	};

	const [handleOffer, { loading }] = useMutation<HandleOffer, HandleOfferVariables>(HANDLE_OFFER);

	const postHandleOffer = async (statusChanger?: StatusChanger) => {
		const offerLines = headers.flatMap(h => h.lines.flatMap(l => l.offerLine));
		const documentNumber = offerLines[0].documentNo ?? '';
		const headerOfferLines = headers.map((h, i) => createEmptyLine(i + 1, h.hideSum, h.name, true, '', OfferRessourceType.TEXT, h.status, documentNumber, h.id).offerLine);

		try {
			const offerHandled = await handleOffer({
				variables: {
					caseId: caseId,
					offerERPReferenceNo: documentNumber,
					offer: {
						competitionOffer: competitionOffer,
						jobNo: caseNo,
						lines: [...headerOfferLines, ...offerLines].map(({ totalCost, ...line }) => line),
						quoteType: offerType,
						footerText: footerText,
						headerText: headerText,
					},
				},
			});

			if (typeof statusChanger !== 'undefined' && offerHandled.data?.handleOffer.lines[0].documentNo) {
				await postChangeOfferStatus(statusChanger.reasonCode, statusChanger.action, offerHandled.data.handleOffer.lines[0].documentNo);
			} else {
				refetchOffers();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const sumLines = (lines: Line[]): number[] => {
		const sumLineSale = lines.reduce((previousValue, currentValue) => previousValue + currentValue.offerLine.newUnitPrice * currentValue.offerLine.quantity, 0);
		const sumLineCost = lines.reduce((previousValue, currentValue) => previousValue + currentValue.offerLine.totalCost, 0);
		const sumLineCoverage = (100 * (sumLineSale - sumLineCost)) / sumLineSale;
		const offerProfit = sumLineSale - sumLineCost;

		return [sumLineSale, sumLineCost, sumLineCoverage, offerProfit];
	};

	const allLinesHaveDocumentNo = (lines: Line[]): boolean => {
		const numberOfLines = lines.length;
		const numberOfLinesWithDocumentNo = lines.filter(l => l.offerLine.documentNo !== null).length;
		return numberOfLines === numberOfLinesWithDocumentNo;
	};

	React.useEffect(() => {
		const lines = headers.flatMap(h => h.lines);
		const [sumLinesSale, sumLinesCost, sumLinesCoverage, sumLineProfit] = sumLines(lines);
		setOfferSum(isFinite(sumLinesSale) ? sumLinesSale : 0);
		setOfferCost(isFinite(sumLinesCost) ? sumLinesCost : 0);
		setOfferCoverage(isFinite(sumLinesCoverage) ? sumLinesCoverage : 0);
		setOfferProfit(isFinite(sumLineProfit) ? sumLineProfit : 0);
		setAllOfferLinesOld(allLinesHaveDocumentNo(lines));
	}, [headers]);

	return (
		<div>
			<Modal
				title={`${typeof offer === 'undefined' ? t('offer.createFor') : t('offer.editFor')} ${offer?.lines[0].documentNo ?? ''} - ${t('case.caseNo')} ${caseNo}`}
				visible={open}
				close={close}
				size={ModalSize.XLARGE}
				body={
					<form>
						<div className="text-blue flex flex-col">
							{((jobTaskLoading && typeof jobTaskData === 'undefined') || loading || changeOfferStatusLoading) && <Loading />}

							<Textarea
								name="offerHeader"
								title="offer.headerText"
								onChange={e => setHeaderText(e.currentTarget.value)}
								value={headerText} readOnly={readOnly}
							/>

							<div className="flex w-1/2 flex-row items-center space-x-4">
								<div>
									<Dropdown
										name=""
										data={enumToSelectOptions(OfferQuoteTypeEnum, 'offer.quoteType.')}
										title="offer.quoteType.overviewTitle"
										onChange={e => setOfferType(e.currentTarget.value as OfferQuoteTypeEnum)}
										value={offerType}
										readonly={readOnly}
									/>
								</div>
								<div className="mt-8">
									<Toggle
										name="competitionToggle"
										text="offer.competitionOffer"
										onChange={e => setCompetitionOffer(e.currentTarget.checked)}
										checked={competitionOffer}
										readOnly={readOnly}
									/>
								</div>
							</div>

							<div className="border-default rounded-default mt-4 overflow-hidden">
								<div className="flex-grow-default overflow-auto pl-4" style={{ height: '55vh' }}>
									{headers.map((header, headerIndex) => (
										<React.Fragment key={'header' + headerIndex}>
											{header.status !== OfferLineStatus.DELETE && (
												<div>
													<div className="flex">
														<div>
															<Input
																title="offer.header"
																name=""
																onChange={e =>
																	setHeaders(currentHeaders => {
																		const copy = [...currentHeaders];

																		copy[headerIndex] = {
																			...copy[headerIndex],
																			name: e.target.value,
																		};

																		return copy;
																	})
																}
																value={header.name}
																readOnly={readOnly}
															/>
														</div>
														<TextButton
															icon={faTimes}
															onClick={() => {
																deleteHeader(headerIndex, header);
															}}
															className="mb-2 self-end"
															iconClassName="text-red"
															disabled={readOnly}
														/>
														<div className="mt-auto mb-1 ml-2 h-full">
															<Checkbox
																name=""
																title="offer.hideSum"
																onChange={e => hideSumHeader(headerIndex, header, e.currentTarget.checked)}
																checked={header.hideSum}
																className="self-end"
																disabled={readOnly}
															/>
														</div>
													</div>
													<div>
														{header.lines.map((line, lineIndex) => (
															<React.Fragment key={line.id + lineIndex + line.offerLine.lineNo}>
																{line.offerLine.offerLineStatus !== OfferLineStatus.DELETE && (
																	<OfferLine
																		readOnly={readOnly}
																		line={line.offerLine}
																		indexString={`${headerIndex + 1}.${lineIndex + 1}`}
																		jobTasks={jobTasks}
																		assortmentCode={assortmentCode}
																		caseNo={caseNo}
																		removeLine={thisLine => deleteLine(headerIndex, lineIndex, line.id, thisLine)}
																		changes={thisLine => handleLineChange(headerIndex, lineIndex, line.id, thisLine)}
																		competitionOffer={competitionOffer}
																	/>
																)}
															</React.Fragment>
														))}
														<TextButton
															icon={faPlus}
															text="common.addLine"
															onClick={() => {
																setHeaders(currentHeaders => {
																	const copy = [...currentHeaders];

																	copy[copy.indexOf(header)] = {
																		...copy[copy.indexOf(header)],
																		lines: [...copy[copy.indexOf(header)].lines, createEmptyLine(headerIndex + 1, header.hideSum)],
																	};

																	return copy;
																});
															}}
															className="ml-3 mt-1"
															disabled={readOnly}
														/>
													</div>
												</div>
											)}
										</React.Fragment>
									))}

									<div>
										<TextButton
											icon={faPlus}
											text="offer.addHeader"
											onClick={() =>
												setHeaders([
													...headers,
													{
														id: '',
														name: '',
														lines: [],
														status: OfferLineStatus.CREATE,
														hideSum: false,
													},
												])
											}
											className="mt-2"
											disabled={readOnly}
										/>
									</div>
								</div>
								<div className="border-t-1 bg-blue mb-0 flex w-full flex-row items-center justify-end py-1  pr-10 text-white">
									<div className="flex flex-row space-x-4">
										<p>
											{t('case.economy.cost')} <span className="mr-2 font-bold">{offerCost.toFixed(2).replace('.', ',')}</span>
										</p>
										<p>
											{t('case.economy.sale')} <span className="mr-2 font-bold">{offerSum.toFixed(2).replace('.', ',')}</span>
										</p>
										<p>
											{t('case.economy.coverage')} <span className="mr-2 font-bold">{offerCoverage.toFixed(2).replace('.', ',')}%</span>
										</p>
										<p>
											{t('Avance')} <span className="mr-2 font-bold">{offerProfit.toFixed(2).replace('.', ',')}</span>
										</p>
									</div>
								</div>
							</div>
							<div>
								<Textarea name="offerFooter" title="offer.footerText" onChange={e => setFooterText(e.currentTarget.value)} value={footerText} readOnly={readOnly} />
							</div>
							<div className="flex flex-col">
								<div className="mt-3 flex flex-row">
									<div className="flex w-1/2">
										<div className="mr-3">
											<Button primary text="offer.ok" onClick={() => postHandleOffer()} loading={loading} disabled={readOnly} />
										</div>
										<div className="mr-3">
											<Button
												success
												text="offer.activate"
												onClick={() =>
													postHandleOffer({
														reasonCode: 'WON',
														action: SalesHeaderEnum.ACTIVATE,
													})
												}
												disabled={readOnly}
											/>
										</div>
									</div>

									<div className="flex w-1/2 justify-end">
										<Dropdown
											name="reasonCode"
											data={closeOppertunityCodes}
											onChange={e => setReasonCode(e.target.value)}
											innerRef={register}
											className="mr-3"
											disabled={!allOfferLinesOld || readOnly}
										/>
										<Button
											danger
											text="offer.cancel"
											onClick={() =>
												postHandleOffer({
													reasonCode: rCode,
													action: SalesHeaderEnum.CANCEL,
												})
											}
											disabled={!allOfferLinesOld || readOnly}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				}
			/>
		</div>
	);
};

export default OfferModal;
