import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { TimeTrackingDayStatus } from '@ssg/common/GraphQL/indexV2';
import { useGetTimeTrackingDaysQueryData } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';

const OpenRegistrationsOverviewNew: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const [dateStart, setDateStart] = React.useState<Date>(yesterday);
	const [dateEnd, setDateEnd] = React.useState<Date>(new Date());

	const { timeTrackingDaysDataRaw, timeTrackingDaysLoading } = useGetTimeTrackingDaysQueryData(dateToDateOnlyString(dateStart), dateToDateOnlyString(dateEnd), userContext.user?.id, TimeTrackingDayStatus.Open);
	const timeTrackingOpenDays = React.useMemo(() => {
		return timeTrackingDaysDataRaw?.timeTrackingDays ?? [];
	}, [timeTrackingDaysDataRaw]);

	return (
		<div>
			<BoxContainer>
				<div className="flex w-full">
					<Box full>
						<div className="order-2 mb-3 mr-3 lg:order-3">
							<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.created')}</label>
							<div className="flex items-center">
								<input
									type="date"
									value={formatDateForInput(dateStart)}
									onChange={e => {
										const minDate = e.target.valueAsDate;
										if (minDate === null || typeof minDate === 'undefined') {
											return;
										}

										setDateStart(new Date(minDate));

										if (minDate > dateEnd) {
											const maxDate = new Date(minDate);
											maxDate.setHours(23, 59, 59, 999);
											setDateEnd(new Date(maxDate));
										}
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '34px',
										maxWidth: '144px',
										minWidth: '100px',
									}}
								/>
								<span className="px-1">&mdash;</span>
								<input
									type="date"
									min={formatDateForInput(dateEnd)}
									value={formatDateForInput(dateEnd)}
									onChange={e => {
										const maxDate = e.target.valueAsDate;
										if (maxDate === null) {
											return;
										}
										maxDate.setHours(23, 59, 59, 999);

										setDateEnd(new Date(maxDate));
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '34px',
										maxWidth: '144px',
										minWidth: '100px',
									}}
								/>
								{typeof dateStart !== 'undefined' && typeof dateEnd !== 'undefined' && (
									<FontAwesomeIcon
										icon={faTimes}
										size="1x"
										className="ml-2 cursor-pointer"
										onClick={() => {
											setDateStart(new Date());
											setDateEnd(new Date());
										}}
									/>
								)}
							</div>
						</div>

						<div>
							<Button />
							<Table
								data={timeTrackingOpenDays ?? []}
								columns={[
									{
										label: 'common.date',
										numeric: true,
										selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
										sortFn: (a, b) => a.date.localeCompare(b.date),
									},
									{
										label: 'common.name',
										selectFn: t => <p>{t.user.name ? t.user.name : ''}</p>,
										sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
									},
									{
										label: 'common.registrations',
										selectFn: t => <p>{t.timeTrackingEntries.length}</p>,
									},
									{
										label: 'common.hours',
										selectFn: t => <p>{t.totalHours ? t.totalHours : ''}</p>,
									},
								]}
								keySelector={t => t.id}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={typeof timeTrackingDaysDataRaw === 'undefined' && timeTrackingDaysLoading}
							/>
						</div>
					</Box>
				</div>
			</BoxContainer>
		</div>
	);
};

export default OpenRegistrationsOverviewNew;
