import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const CustomerSchema = yup.object().shape(
	{
		debitorType: yup.string().required(),

		createInECB: yup.boolean(),

		requisitionerInsuranceName: yup.string().required(),

		requisitionerAttentionName: yup.string(),

		shippingAddressReference: yup.string().nullable(),

		insuranceInvoiceCountry: yup.string(),

		insuranceInvoiceRoad: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceZip', 'insuranceInvoiceHouseNumber'], {
			is: (city: string, zip: string, houseNo: string) => city !== '' || zip !== '' || houseNo !== '',
			then: yup.string().required('error.addressMissing'),
			otherwise: yup.string().nullable(),
		}),

		insuranceInvoiceHouseNumber: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceZip', 'insuranceInvoiceRoad'], {
			is: (city: string, zip: string, road: string) => city !== '' || zip !== '' || (road !== '' && typeof road !== 'undefined' && road !== null),
			then: yup.string().required('error.addressMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceFloor: yup.string(),

		insuranceInvoiceAddress2: yup.string(),

		insuranceInvoiceZip: yup.string().when(['insuranceInvoiceCity', 'insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'], {
			is: (city: string, road: string, houseNo: string) => city !== '' || (road !== '' && typeof road !== 'undefined' && road !== null) || houseNo !== '',
			then: yup.string().required('error.zipMissing'),
			otherwise: yup.string(),
		}),

		insuranceInvoiceCity: yup.string().when(['insuranceInvoiceZip', 'insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'], {
			is: (zip: string, road: string, houseNo: string) => zip !== '' || (road !== '' && typeof road !== 'undefined' && road !== null) || houseNo !== '',
			then: yup.string().required('error.cityMissing'),
			otherwise: yup.string(),
		}),

		requisitionerInsuranceNumber: yup.string().when('noInsuranceNumber', {
			is: (noInsuruanceNum: boolean) => noInsuruanceNum,
			then: yup.string(),
			otherwise: yup.string().required(),
		}),

		noInsuranceNumber: yup.boolean(),

		requisitionerCVR: yup.string().when(['debitorType', 'noCVR'], {
			is: (type: string, noCVR: boolean) => type === 'BUSINESS' && !noCVR,
			then: yup.string().required(),
			otherwise: yup.string(),
		}),

		noCVR: yup.boolean(),
	},
	[
		['insuranceInvoiceRoad', 'insuranceInvoiceZip'],
		['insuranceInvoiceCity', 'insuranceInvoiceZip'],
		['insuranceInvoiceRoad', 'insuranceInvoiceCity'],
		['insuranceInvoiceCity', 'insuranceInvoiceHouseNumber'],
		['insuranceInvoiceRoad', 'insuranceInvoiceHouseNumber'],
		['insuranceInvoiceZip', 'insuranceInvoiceHouseNumber'],
	],
);
