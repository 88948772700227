import React from 'react';
import { faCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Loading from '@ssg/common/Components/Loading';
import classNames from 'classnames';

interface Props {
	setScannedId: React.Dispatch<React.SetStateAction<string | undefined>>;
	multiple?: boolean;
	allowedPrefixes?: string[];
}

const NOT_FOUND = 'SCANNED_TEXT_NOT_FOUND';

const BarcodeScanner: React.FC<Props> = ({ setScannedId, multiple = false, allowedPrefixes }): React.ReactElement => {
	const { t } = useTranslation();

	const [active, setActive] = React.useState(false);
	const [data, setData] = React.useState(NOT_FOUND);
	const [error, setError] = React.useState<string>();
	const [success, setSuccess] = React.useState(false);

	React.useEffect(() => {
		if (data !== NOT_FOUND && (typeof allowedPrefixes === 'undefined' || allowedPrefixes.some(prefix => data.startsWith(prefix)))) {
			if (!multiple) {
				setScannedId(data);
			} else {
				setScannedId(current => {
					if (typeof current === 'undefined' || current.length === 0) {
						return data;
					}

					if (current.includes(data)) {
						return current;
					}

					return `${current},${data}`;
				});

				setSuccess(true);
				window.setTimeout(() => setSuccess(false), 2000);
			}
		}
	}, [data, setScannedId, multiple, allowedPrefixes]);

	return (
		<div className="relative mx-auto" style={{ width: 500, height: 375 }}>
			<div className="absolute z-40">
				<div className={classNames({ hidden: success })}>
					<BarcodeScannerComponent
						onUpdate={(_, result) => {
							if (!active) {
								setActive(true);
							}
							setData(result?.getText() ?? NOT_FOUND);
						}}
						onError={err => {
							if (err instanceof DOMException && err.name === 'NotAllowedError') {
								setError('common.scannerPermissionError');
							} else {
								setError('common.scannerUnknownError');
							}
						}}
					/>
				</div>
			</div>

			{!active && (
				<div className="absolute left-0 top-0 right-0 bottom-0 z-0">
					<Loading />
				</div>
			)}

			{active && !success && typeof error === 'undefined' && (
				<div className="absolute z-50 mx-auto w-full text-center text-lg font-semibold" style={{ bottom: 10 }}>
					<div className="text-white">
						{t('common.scanning')}
						<FontAwesomeIcon icon={faSpinner} className="ml-2 animate-spin" size="lg" />
					</div>
				</div>
			)}

			{success && typeof error === 'undefined' && (
				<div className="absolute z-50 mx-auto w-full text-center text-2xl font-semibold" style={{ top: '50%', transform: 'translateY(-50%)' }}>
					<div className="text-green-calm">
						{t('common.scanned')}
						<FontAwesomeIcon icon={faCheck} className="ml-2" size="lg" />
					</div>
				</div>
			)}

			{typeof error !== 'undefined' && <div className="text-center">{t(error)}</div>}
		</div>
	);
};

export default BarcodeScanner;
