import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required', // Translation key goes here
	},
	date: {
		required: 'error.required',
	},
});

export const movableJobCreationSchema = yup.object().shape(
	{
		description: yup.string().required(),

		deadline: yup.date().required(),

		assignedTo: yup.string().when('location', {
			is: (l: string | undefined) => typeof l === 'undefined' || l.length === 0,
			then: yup.string().required('jobs.eitherAssignedToOrLocation'),
			otherwise: yup.string(),
		}),

		location: yup.string().when('assignedTo', {
			is: (at: string | undefined) => typeof at === 'undefined' || at.length === 0,
			then: yup.string().required('jobs.eitherAssignedToOrLocation'),
			otherwise: yup.string(),
		}),

		case: yup.string(),

		movable: yup.string(),
	},
	[['assignedTo', 'location']],
);
