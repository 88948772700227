import React from 'react';
import { useGetVehiclesQuery, useRemoveUserCarFilterMutation, useSetUserCarFilterMutation } from '@ssg/common/GraphQL/indexV2';
import { DragItemTypes, mapBookingsOnVehicle, Bookings, getWeekDay } from './PlannerHelpers';
import VehicleCard from './VehicleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { P } from '@ssg/common/Components/Text';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import PlannerCustomDragLayer from './PlannerCustomDragLayer';
import PlannerContext from './PlannerContext';
import VehicleFilters from './VehicleFilters';
import Fuse from 'fuse.js';
import { GetWebCurrentUser, RemoveUserCarFilterVariables, SetUserCarFilterVariables } from '../../GraphQL';
import { loader } from 'graphql.macro';
import UserContext from '../../UserContext';
const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');

const PlannerCars: React.FC = (): React.ReactElement => {
	const { dates, activeCarFilters, setActiveCarFilters, clearActiveCarFilters, activeUserCarFilter, setActiveUserCarFilter } = React.useContext(PlannerContext);
	const datesAndWeekDay = React.useMemo(() => (dates?.shownDates ?? []).map(date => ({ date, weekDay: getWeekDay(date) })), [dates]);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const { t } = useTranslation();
	const [filterSaveName, setFilterSaveName] = React.useState<string>(activeCarFilters.name ?? '');
	const userContext = React.useContext(UserContext);
	const carFiltersData = React.useMemo(() => userContext.user?.carFilters ?? [], [userContext.user?.carFilters]);
	const [removeUserFilter] = useRemoveUserCarFilterMutation();
	const [createUserFilter] = useSetUserCarFilterMutation();
	const { data, loading } = useGetVehiclesQuery({
		context: { debatch: true },
		variables: {
			isCar: true,
			dateRange: {
				from: dates?.fromDate ?? '',
				to: dates?.toDate ?? '',
			},
			locations: activeCarFilters.locations,
			//type: activeCarFilters.type,
			departments: activeCarFilters.departments,
		},
		skip: typeof dates === 'undefined',
	});

	const resetFilters = () => {
		clearActiveCarFilters();

		setActiveCarFilters({
			name: undefined,
			locations: undefined,
			//type: undefined,
			departments: undefined,
		});

		//setPostalCodeValues([]);
		setActiveUserCarFilter('');
	};



	const cars = React.useMemo(() => data?.vehicles ?? [], [data?.vehicles]);

	const searchedCars = React.useMemo(() => {
		let thisCars = cars;
		const fuse = new Fuse(cars, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['brand', 'locationCode', 'vehicleNumber', 'registrationNumber'],
		});

		if (searchTerm.length > 0) {
			thisCars = fuse.search(searchTerm).map(v => v.item);
		}

		return thisCars;

	}, [cars, searchTerm]);
	if (typeof dates === 'undefined') return <></>;

	const postUserFilter = async (filterValues: SetUserCarFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserVehicleFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.carFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							carFilters: [cachedRequest.currentUser?.drivingSlipFilters, data.setUserVehicleFilter.carFilters],
						},
					},
				});
			},
		});
		console.log('filter name ', filterValues.filter.name);
		setActiveUserCarFilter(filterValues.filter.name);
		//setFilterSaveName(false);
	};


	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					departments: activeCarFilters.departments ?? [],
					locations: activeCarFilters.locations ?? [],
					cars: [],

				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserCarFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<div>
				<VehicleFilters
					setSearchTerm={setSearchTerm}
					activeVehiclesFilter={activeCarFilters}
					setActiveVehiclesFilter={setActiveCarFilters}
					resetFilters={resetFilters}
					activeUserFilterName={activeUserCarFilter}
					handleDeleteUserFilter={async (name) => handleDeleteUserFilter(name)}
					onFilterSave={async () => onFilterSave()}
					setActiveUserVehiclesFilter={setActiveUserCarFilter}
					userVehiclesFilters={carFiltersData}
					setFilterName={setFilterSaveName}
					filterName={filterSaveName}
				/>
			</div>

			<div className="overflow-y-auto">
				{loading && (
					<div className="h-40 text-blue text-center">
						<FontAwesomeIcon icon={faSpinner} className="animate-spin" size="5x" />
						<P className="text-2xl">{t('planner.gettingCars')}</P>
					</div>
				)}
				<PlannerCustomDragLayer />
				{searchedCars.map(c => {
					return <VehicleCard key={c.vehicleNumber} vehicle={c} dragItemType={DragItemTypes.CAR} bookings={mapBookingsOnVehicle(c.bookedDays as Bookings, datesAndWeekDay)} />;
				})}
			</div>
		</>
	);
};



export default PlannerCars;