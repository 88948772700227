import React from 'react';
import { GetSingleCase_case, Permissions } from '../../../GraphQL';
import Tabs from '@ssg/common/Components/Tabs';
import Box from '../../../Components/Layout/Box';
import EconomyTab from './EconomyTab';
import JobsTable from './JobsTable';
import ReportsTab from './ReportsTab';
import MachinesTable from './MachinesTable';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import UserContext from '../../../UserContext';
import SubProjects from './SubProjects';
import CaseMailsTab from './CaseMailsTab';
import RequisitionsTab from './RequisitionsTab';

const tabs: string[] = ['common.economy', 'case.caseMails', 'common.reports', 'common.machines', 'requisitions.overviewTitle', 'jobs.overviewTitle', 'case.subProjects'];

interface Props {
	caseData: GetSingleCase_case;
}

const MiscBox: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const showEconomy = arraysHasMatchingValue(userPermissions, [Permissions.CASES_ECONOMY_VIEW]);
	const caseViewExternal = arraysHasMatchingValue(userPermissions, [Permissions.CASES_VIEW_EXTERNAL]);

	const filteredTabs = React.useMemo(() => tabs.filter(t => !t.endsWith('economy') || (showEconomy && !caseViewExternal)), [caseViewExternal, showEconomy]);

	const [activeTab, setActiveTab] = React.useState<string>(filteredTabs[0]);

	return (
		<Box title="common.miscellaneous" className="text-blue mb-8" full>
			<Tabs titles={filteredTabs} active={activeTab} onClick={setActiveTab}>
				{activeTab === 'common.economy' && <EconomyTab caseErpId={caseData.erpId} caseErpNo={caseData.erpNo} caseId={caseData.id} fixedPriceOnCase={caseData.fixedPriceOnCase} />}
				{activeTab === 'case.caseMails' && <CaseMailsTab id={caseData.erpNo} />}
				{activeTab === 'common.reports' && <ReportsTab caseData={caseData} />}
				{activeTab === 'common.machines' && <MachinesTable caseData={caseData} caseViewExternal={caseViewExternal} />}
				{activeTab === 'requisitions.overviewTitle' && <RequisitionsTab caseData={caseData} />}
				{activeTab === 'jobs.overviewTitle' && <JobsTable caseData={caseData} />}
				{activeTab === 'case.subProjects' && <SubProjects caseData={caseData} />}
			</Tabs>
		</Box>
	);
};

export default MiscBox;
