import Input from '@ssg/common/Components/Input';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';

interface Props {
	label: string;
	placeholder: string;
	onChange(value: number): void;
	value: number | undefined;
}

const NumberInput: React.FC<Props> = ({ value, onChange, label, placeholder }) => {
	const numberInputNegativeFlag = useFlag(FeatureFlagEnums.NUMBER_INPUT_NEGATIVE);

	if (numberInputNegativeFlag) return (
		<div className="w-1/3">
			<Input defaultValue={value} title={label} name={label} type="number" onChange={e => onChange(e.target.valueAsNumber)} placeholder={placeholder} />
		</div>
	);
	return (
		<div className="w-1/3">
			<Input value={value} title={label} name={label} type="number" onChange={e => onChange(e.target.valueAsNumber)} placeholder={placeholder} />
		</div>
	);
};

export default NumberInput;
