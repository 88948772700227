import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { faFileExcel, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetWebCatalogCustomers, GetWebCatalogCustomersVariables, Permissions } from '../../../GraphQL';
import { CatalogChangedBySchema } from '../../../Schemas/CatalogChangedBySchema';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import classNames from 'classnames';
import Header from '@ssg/common/Components/Header';
import UserContext from '../../../UserContext';
import CatalogCreationWrapper from '../CatalogCreation/CatalogCreationWrapper';
import Contacts from '../Contact/Contacts';
import Craftsmen from '../Craftsman/Craftsmen';
import GlobalFiles from '../GlobalFiles/GlobalFiles';
import TimedMessages from '../TimedMessage/TimedMessages';
import CatalogOverview from './CatalogOverview';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import TextButton from '@ssg/common/Components/TextButton';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const CATALOG_CUSTOMERS = loader('src/GraphQL/CatalogCustomers/GetWebCatalogCustomers.gql');

export interface IChangedBy {
	changedBy: string;
}

const CatalogTabs: React.FC = (): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);
	const { t } = useTranslation();
	const history = useHistory();
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const canView = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VIEW]);
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);
	const userName = userContext.user?.name ?? '';
	const isExternalUser = userContext.user?.external;

	const [tabs, setTabs] = React.useState<string[]>([]);

	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);
	const [createCatalog, setCreateCatalog] = React.useState<boolean>(false);

	const [externalUsername, setExternalUsername] = useStorageState<string>(window.sessionStorage, 'externalUserName', '');

	const { data: customersList } = useQuery<GetWebCatalogCustomers, GetWebCatalogCustomersVariables>(CATALOG_CUSTOMERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			ownCustomers: isExternalUser,
		},
	});

	const catalogCustomers = React.useMemo(() => customersList?.catalogCustomers ?? [], [customersList]);

	const { handleSubmit, register, errors } = useForm<IChangedBy>({
		resolver: yupResolver(CatalogChangedBySchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = (formData: IChangedBy) => {
		setExternalUsername(`${userName} - ${formData.changedBy}`);
	};

	React.useEffect(() => {
		if (userContext) {
			setTabs([]);
			if (canView) {
				setTabs(prevState => [
					...prevState,
					'catalog.overviewTitle',
					'catalog.timedMessage.overviewTitle',
					'catalog.contacts',
					'catalog.craftsman.overviewTitle',
					'catalog.globalFiles.overviewTitle',
				]);
			}
			if (canEdit) {
				setTabs(prevState => [...prevState, 'common.createOrder']);
			}
		}
	}, [userContext, canView, canEdit]);

	React.useEffect(() => {
		if (tabs.length) {
			setActiveTab(tabs[0]);
		}
	}, [tabs]);

	const [exportLoading, setExportLoading] = React.useState(false);

	const getAllCatalogsData = async () => {
		setExportLoading(true);

		const blobResponse = await fetch(envVar.baseUrl + '/_catalogs', {
			headers: {
				'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				catkey: 'YTA/FBQM4cKbfdSNwnyCYvl9FiFAQD86MoaIHL51MSD6OKs7SZDR4gq4l80/hk8p1rKMQNADpjATKYJUTLIRPA',
			},
		}).then(res => res.blob());

		if (blobResponse.size > 0) {
			const url = window.URL.createObjectURL(blobResponse);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${t('catalog.overviewTitle')} ${dateToDateTimeString(new Date())}.xlsx`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
		}

		if (blobResponse.size === 0) {
			alert('Kunne ikke hente forholdsordredata');
		}

		setExportLoading(false);
	};

	return (
		<div>
			{isExternalUser && externalUsername === '' ? (
				<Modal
					title=""
					size={ModalSize.SMALL}
					visible={isExternalUser}
					close={() => history.goBack()}
					body={
						<form onSubmit={handleSubmit(onSubmit)}>
							<Input
								title="catalog.changedBy"
								name="changedBy"
								defaultValue={externalUsername}
								placeholder={t('common.name')}
								innerRef={register}
								errorMessage={errors?.changedBy?.message ?? ''}
								className="mb-3"
							/>

							<Button success submit text="common.approve" className="pl-4" />
						</form>
					}
				/>
			) : (
				<>
					<Header
						title="catalog.overviewTitle"
						actions={
							<>
								<div>
									{canView && !isExternalUser && (
										<TextButton text="catalog.exportAll" icon={faFileExcel} onClick={() => getAllCatalogsData()} className="mr-4" loading={exportLoading} />
									)}
								</div>
								<div>{canEdit && <Button onClick={() => setCreateCatalog(true)} primary text="common.createOrder" icon={faPlus} className="mr-4" />}</div>
							</>
						}
					>
						<div className="flex">
							{tabs.slice(0, tabs.length - 1).map((title, i) => {
								return (
									<div className="mr-3 mt-1" key={i}>
										<button
											type="button"
											onClick={() => setActiveTab(title)}
											className={classNames('text-blue focus:outline-none', {
												'border-blue border-b-2': title === activeTab,
											})}
										>
											{t(title)}
										</button>
									</div>
								);
							})}
						</div>
					</Header>
					<BoxContainer>
						{activeTab === 'catalog.overviewTitle' && <CatalogOverview changedBy={externalUsername} catalogCustomers={catalogCustomers} />}
						{createCatalog && <CatalogCreationWrapper changedBy={externalUsername} catalogCustomers={catalogCustomers} open={createCatalog} close={() => setCreateCatalog(false)} />}
						{activeTab === 'catalog.timedMessage.overviewTitle' && <TimedMessages catalogCustomer={undefined} catalogCustomers={catalogCustomers} changedBy={externalUsername} />}
						{activeTab === 'catalog.contacts' && <Contacts catalogCustomer={undefined} catalogCustomers={catalogCustomers} changedBy={externalUsername} />}
						{activeTab === 'catalog.craftsman.overviewTitle' && <Craftsmen catalogCustomer={undefined} catalogCustomers={catalogCustomers} changedBy={externalUsername} />}
						{activeTab === 'catalog.globalFiles.overviewTitle' && <GlobalFiles changedBy={externalUsername} catalogCustomers={catalogCustomers} catalogCustomer={undefined} />}
					</BoxContainer>
				</>
			)}
		</div>
	);
};

export default CatalogTabs;
