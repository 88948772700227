import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetMachineHistory, GetWebMachineLocations_machineLocations } from '../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Loading from '@ssg/common/Components/Loading';
import MachineLogItem from './MachineLogItem';

interface IProps {
	machineLogdata: GetMachineHistory | undefined;
	loadingLogData: boolean;
	machineId: string;
	open: boolean;
	close: () => void;
	machineLocations: GetWebMachineLocations_machineLocations[];
}

const MachineLog: React.FC<IProps> = ({ machineLogdata, loadingLogData, machineId, open, close, machineLocations }) => {
	const { t } = useTranslation();

	let history = useMemo(() => machineLogdata?.machineHistory ?? [], [machineLogdata]);

	history = history
		.filter(h => h.dateEnd !== '0001-01-01')
		.sort((a, b) => new Date(a.dateEnd).valueOf() - new Date(b.dateEnd).valueOf())
		.sort((a, b) => new Date(a.dateStart).valueOf() - new Date(b.dateStart).valueOf())
		.concat(history.filter(h => h.dateEnd === '0001-01-01'))
		.reverse();
	console.log(history);
	return (
		<Modal
			title={`${t('machines.changes')} ${machineId}`}
			size={ModalSize.MEDIUM}
			visible={open}
			close={close}
			body={
				<div className="text-blue space-y-4">
					{loadingLogData ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<div>
							{history.length === 0
								? t('machines.noHistory')
								: history.map((his, index) => (
									<div key={index} className="mb-3">
										<MachineLogItem
											historyItem={his}
											machineLocation={machineLocations.find(mach => mach.locationCode === his.eRPLocationReference)}
											consumption={index === 0 ? 0 : history[index - 1].consumption - his.consumption}
										/>
									</div>
								))}
						</div>
					)}
				</div>
			}
		/>
	);
};

export default MachineLog;
