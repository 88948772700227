import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { SelectOption } from '../Helpers/Helpers';
import useOnClickOutside from '../Hooks/useOnClickOutside';

import en from './Flags/en.svg';
import da from './Flags/da.svg';
import no from './Flags/no.svg';
import se from './Flags/se.svg';
import Popover from './Popover';
import UserContext from '../UserContext';
import { useMutation } from '@apollo/client';
import { GetCommonCurrentUser, SetUserDefaultLanguage, SetUserDefaultLanguageVariables } from '../../web/src/GraphQL';

const SET_USER_DEFAULT_LANGAUGE = loader('src/GraphQL/Users/SetUserDefaultLanguage.gql');
const GET_CURRENT_USER = loader('../GraphQL/Users/GetCommonCurrentUser.gql');
const LanguageSelect: React.FC = () => {
	const userContext = React.useContext(UserContext);
	const [setUserDefaultLanguage] = useMutation<SetUserDefaultLanguage, SetUserDefaultLanguageVariables>(SET_USER_DEFAULT_LANGAUGE);

	const { i18n } = useTranslation(userContext.user?.defaultLanguage ?? 'da');
	const [showLanguages, setShowLanguages] = useState(false);
	const languageRef = useRef(null);

	useOnClickOutside(languageRef, () => setShowLanguages(false));

	const handleClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		e.preventDefault();
		setShowLanguages(!showLanguages);
	};

	const flags = [da, en, no, se];

	const availableLngs: string[] = i18n.services.languageUtils.supportedLngs;
	const languages: SelectOption[] = [];
	availableLngs.forEach(l => {
		flags.forEach(f => {
			if (f.includes(`/${l}.`)) {
				languages.push({ value: l, label: f });
			}
		});
	});

	const currentLng = i18n.language || window.localStorage.i18nextLng || '';
	const selectedLanguage: SelectOption = {
		value: i18n.language || window.localStorage.i18nextLng || '',
		label: flags.filter(f => f.includes(currentLng))[0],
	};

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		//changeUserDefaultLanguage(lng);
	};

	const lngHandler = (value: string): void => {
		changeLanguage(value);
		setShowLanguages(false);
	};

	const changeUserDefaultLanguage = async (lng: string): Promise<void> => {
		try {
			await setUserDefaultLanguage({
				variables: {
					language: lng,
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.setUserDefaultLanguage === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCommonCurrentUser>({
						query: GET_CURRENT_USER,
					});

					if (cachedRequest === null || cachedRequest.currentUser?.defaultLanguage === null) {
						return;
					}

					cache.writeQuery({
						query: GET_CURRENT_USER,
						data: {
							user: {
								...cachedRequest.currentUser,
								defaultLanguage: [cachedRequest.currentUser?.defaultLanguage, data.setUserDefaultLanguage],
							},
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Popover
			placement="right"
			interactive
			clickTrigger
			hideOnClick
			hideArrow
			content={
				<div ref={languageRef} className="bg-blue flex h-10 w-32 items-center justify-start p-2">
					{languages
						.filter(l => l.label !== selectedLanguage.label)
						.sort((a, b) => a.label.localeCompare(b.label))
						.map(lng => (
							<img key={lng.value} src={lng.label} alt={lng.value} onClick={() => lngHandler(lng.value)} className="mx-1 h-5 cursor-pointer" />
						))}
				</div>
			}
		>
			<img src={selectedLanguage.label} alt={selectedLanguage.value} onClick={e => handleClick(e)} className="mx-1 h-5 cursor-pointer" />
		</Popover>
	);
};

export default LanguageSelect;
