import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { GetSingleCase, GetSingleCaseVariables, Offers, Offers_offers } from '../../../GraphQL';
import { faEdit, faEnvelope, faEye, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from '@ssg/common/Components/Header';
import Table from '@ssg/common/Components/Table';
import classNames from 'classnames';
import OfferModal from './OfferModal';
import Loading from '@ssg/common/Components/Loading';
import TextButton from '@ssg/common/Components/TextButton';
import CaseMail from '../CaseMail';

const GET_CASE = loader('../../../GraphQL/Cases/GetWebCase.gql');
const GET_OFFERS = loader('../../../GraphQL/Offer/GetOffers.gql');

const OfferOverview: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const [createOffer, setCreateOffer] = React.useState(false);

	const [offer, setOffer] = React.useState<Offers_offers | undefined>();
	const [mailDocumentNo, setMailDocumentNo] = React.useState<string | undefined>();
	const [readOnly, setReadOnly] = React.useState(false);

	/* FORKERT GQL */
	const { loading: caseLoading, data: caseData } = useQuery<GetSingleCase, GetSingleCaseVariables>(GET_CASE, {
		variables: {
			id: id,
			includeEconomy: false,
		},
	});

	const { data, loading, refetch } = useQuery<Offers>(GET_OFFERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			caseERPReferenceNo: caseData?.case.erpNo,
		},
		skip: caseLoading,
	});

	const fetchAndClose = async () => {
		await refetch();
		setOffer(undefined);
		setCreateOffer(false);
	};

	if (caseLoading) return <Loading />;

	if (typeof caseData === 'undefined') return <p>Kunne ikke finde sag</p>;

	return (
		<div>
			<Header title={`${t('offer.overview')} ${t('case.caseNo')} ${caseData?.case.erpNo}`} backButton>
				<TextButton text="offer.create" icon={faPlus} onClick={() => setCreateOffer(true)} />
			</Header>
			<div className="text-blue relative mx-14 mb-14 bg-white px-4 pb-4">
				<Table
					data={data?.offers ?? []}
					loading={loading || typeof data === 'undefined'}
					columns={[
						{
							label: t('offer.documentNo'),
							selectFn: o => <p>{o.lines[0]?.documentNo}</p>,
						},
						{
							label: t('offer.lines'),
							selectFn: o => <p>{o.lines.filter(l => !l.header).length}</p>,
						},
						{
							label: t('offer.oppertunityStatus'),
							selectFn: o => <p>{t(`offer.opportunityStatus.${o.opportunityStatus ?? ''}`)}</p>,
						},
						{
							label: t('offer.total'),
							selectFn: o => <p>{o.lines.reduce((acc, currentObj) => acc + (currentObj?.lineAmount ?? 0), 0).toLocaleString()}</p>,
						},
						{
							label: 'offer.sendOffer',
							selectFn: o => <TextButton icon={faEnvelope} onClick={() => setMailDocumentNo(o.lines[0]?.documentNo)} />,
						},
						{
							label: 'common.edit',
							classNameTh: 'text-right',
							selectFn: o => (
								<div
									className={classNames('flex content-start justify-end text-right', {
										// 'hidden': o.opportunityStatus !== 'In Progress',
									})}
								>
									{o.opportunityStatus === 'In Progress' ? (
										<TextButton icon={faEdit} onClick={() => setOffer(o)} />
									) : (
										<TextButton
											icon={faEye}
											onClick={() => {
												setOffer(o);
												setReadOnly(true);
											}}
										/>
									)}
								</div>
							),
						},
					]}
					keySelector={o => o.lines[0]?.documentNo ?? ''}
				/>
			</div>

			{typeof offer !== 'undefined' && (
				<OfferModal
					readOnly={readOnly}
					open={typeof offer !== 'undefined'}
					close={() => {
						setOffer(undefined);
						setReadOnly(false);
					}}
					caseNo={caseData?.case.erpNo ?? ''}
					assortmentCode={caseData?.case.debitor?.assortmentCode ?? ''}
					offer={offer}
					caseId={id}
					refetchOffers={fetchAndClose}
				/>
			)}

			{createOffer && (
				<OfferModal
					open={createOffer}
					close={() => setCreateOffer(false)}
					caseNo={caseData?.case.erpNo ?? ''}
					assortmentCode={caseData?.case.debitor.assortmentCode ?? ''}
					caseId={id}
					refetchOffers={fetchAndClose}
				/>
			)}

			{typeof mailDocumentNo !== 'undefined' && (
				<CaseMail
					open={typeof mailDocumentNo !== 'undefined'}
					close={() => setMailDocumentNo(undefined)}
					data={caseData.case}
					config={{
						defaultTabIndex: 1,
						fileNames: [mailDocumentNo],
						message: 'Tilbudsmail',
						recipients: [
							{
								email: caseData.case.debitor.email ?? '',
								role: '',
								name: caseData.case.debitor.company ?? '',
							},
						],
						subject: `${t('common.offer')} - ${mailDocumentNo}`,
					}}
					quoteMail
				/>
			)}
		</div>
	);
};

export default OfferOverview;
