import React from 'react';
import { useMutation } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { InformationSchema } from '../../Schemas/InformationSchema';
import { regressResolver } from './VisitationBox';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

interface ISkaforBox {
	skafor: boolean;
}

const SkaforBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const [editActive, setEditActive] = React.useState(false);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, register } = useForm<ISkaforBox>({
		resolver: yupResolver(InformationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: ISkaforBox) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				skafor: data.skafor,
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, skafor }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				skafor: skafor,
			},
		});
	};

	const skafor: SelectOption[] = [{ value: regressResolver(caseData.skafor), label: 'case.SKAFOR' }];

	return (
		<>
			<Box title="case.SKAFOR" full className="mb-8" icon={caseViewExternal ? undefined : faEdit} onClick={() => setEditActive(true)}>
				<hr />
				<BoxFormDataViewer data={skafor} index={4} keyId="case.visitation" />
			</Box>
			<Modal
				title="case.editSKAFOR"
				close={() => setEditActive(false)}
				visible={editActive}
				size={ModalSize.SMALL}
				body={
					<form onSubmit={handleSubmit(onSubmit)} className="text-sm">
						<Checkbox name="skafor" title="case.SKAFOR" innerRef={register} defaultChecked={caseData.skafor} className="mt-1" />

						<div className="mt-3">
							<Button submit success text="case.editSKAFOR" loading={updateCaseSubmitting} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default SkaforBox;
