import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetWebCurrentUser, GetJobs_jobs, RemoveUserJobFilter, RemoveUserJobFilterVariables, SetUserJobFilter, SetUserJobFilterVariables } from '../../GraphQL';
import { JobFilters } from './MovableJobs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import UserContext from '../../UserContext';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';
import Button from '@ssg/common/Components/Button';
import Select from 'react-select';
import Toggle from '@ssg/common/Components/Toggle';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const SET_USER_JOB_FILTER = loader('src/GraphQL/Users/SetUserJobFilter.gql');
const REMOVE_USER_JOB_FILTER = loader('src/GraphQL/Users/RemoveUserJobFilter.gql');

interface Props {
	filters: JobFilters;
	setFilters: React.Dispatch<React.SetStateAction<JobFilters>>;
	setFilterTerm: (v: string) => void;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserFilter: string;
	jobs: GetJobs_jobs[];
}

const MovableJobFilter: React.FC<Props> = ({ filters, setFilters, setFilterTerm, setActiveUserFilter, activeUserFilter, jobs }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(filters.name ?? '');

	const [createUserFilter] = useMutation<SetUserJobFilter, SetUserJobFilterVariables>(SET_USER_JOB_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserJobFilter, RemoveUserJobFilterVariables>(REMOVE_USER_JOB_FILTER);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					location: filters.location,
					personalOnly: filters.personalOnly,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const resetFilters = () => {
		setFilters({
			name: null,
			location: '',
			personalOnly: false,
		});
		setFilterTerm('');
		setActiveUserFilter('');
	};

	const postUserFilter = async (filterValues: SetUserJobFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserJobFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.jobFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							caseFilters: [cachedRequest.currentUser?.jobFilters, data.setUserJobFilter.jobFilters],
						},
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserJobFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const getLocations = () => jobs.filter((d, i, a) => a.findIndex(ad => ad.location?.id === d.location?.id) === i).map(l => ({ value: l.location?.id, label: l.location?.name }));

	const location = () => {
		const p = jobs.find(p => p.location?.id === filters.location);
		return {
			value: p?.location?.id ?? '',
			label: p?.location?.name ?? '',
		};
	};

	return (
		<div className="my-2 mx-0">
			<div className="flex flex-wrap">
				<div className="mr-3 mb-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
					<div className="relative flex">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input name="overview-search" className="pr-8 text-sm" style={{ minWidth: '220px', height: '36px' }} onChange={(e): void => setFilterTerm(e.target.value)} />
					</div>
				</div>

				<div className="mr-3 mb-3">
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.location')}</label>
					<Select
						placeholder=""
						isClearable
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={location()}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
							option: base => ({ ...base, fontSize: '0.75rem' }),
							noOptionsMessage: base => ({
								...base,
								fontSize: '0.75rem',
							}),
							control: base => ({ ...base, height: '36px' }),
						}}
						onChange={value =>
							setFilters(currentFilters => ({
								...currentFilters,
								location: value?.value ?? '',
							}))
						}
						options={getLocations()}
					/>
				</div>

				<div className="mr-3 mb-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.quickFilter')}</label>
					<div className="flex">
						<Dropdown
							name="overview-quick-filters"
							placeholder={t('common.quickFilter')}
							value={activeUserFilter}
							onChange={e => {
								setActiveUserFilter(e.target.value);
								e.target.value !== ''
									? setFilters(currentFilters => ({
											...currentFilters,
											name: e.target.value,
									  }))
									: resetFilters();
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...(userContext.user?.jobFilters.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? []),
							]}
							className="w-full pr-8 lg:w-1/2"
							style={{ minWidth: '220px', height: '36px' }}
						/>

						<Button
							primary
							text={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? t('caseOverview.filters.saveFilter') : t('caseOverview.filters.updateFilter')}
							onClick={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
							className="ml-3 px-5 py-2 text-xs"
						/>

						<Button secondary text={t('common.resetFilter')} className="ml-3 px-5 py-2 text-xs" onClick={resetFilters} />

						<Button
							secondary={filters.name !== '' || typeof filters.name !== 'undefined'}
							disabled={filters.name === '' || typeof filters.name == 'undefined' || filters.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="ml-3 px-5 py-2 text-xs"
						/>
					</div>
				</div>

				<div className="mr-3 mb-3 whitespace-nowrap text-sm">
					<label className="text-blue mb-1 block text-xs font-medium">{t('jobs.personalOnly')}</label>
					<Toggle
						name="personalOnly"
						text=""
						onClick={() =>
							setFilters(currentFilters => ({
								...currentFilters,
								personalOnly: !filters.personalOnly,
							}))
						}
						defaultChecked={filters.personalOnly}
					/>
				</div>
			</div>

			{/* <div
				className={classnames('flex flex-row flex-wrap w-full mr-6', {
					'hidden lg:flex': !showAllFilters,
				})}
			>
			</div> */}

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{filters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(filters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default MovableJobFilter;
