import React, { CSSProperties } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import PlannerEvent, { VehicleItem } from './PlannerEvent';
import { CalendarEventType, EventFragment, GetUserCalendarsQuery } from '@ssg/common/GraphQL/indexV2';
import { DragItemTypes } from './PlannerHelpers';
import { DragPropsDrivingSlip } from './PlannerDrivingSlipCard';
import { DragPropsVehicleItem } from './VehicleCard';

const emptyUser = {
	department: '',
	area: '',
	defaultLanguage: '',
	email: '',
	employeeId: '',
	id: '',
	jobFunction: '',
	name: '',
	phone: '',
	plannerHelpText: '',
	plannerHelpTextUpdatedAt: '',
};
const layerStyles: CSSProperties = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

function getItemStyles(
	initialOffset: XYCoord | null,
	clientOffset: XYCoord | null,
) {

	if (!initialOffset || !clientOffset) {
		return {
			display: 'none',
		};
	}

	const { x, y } = clientOffset;


	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
}

const PlannerCustomDragLayer: React.FC = (): React.ReactElement | null => {

	const { itemType, isDragging, item, initialOffset, clientOffset } =
		useDragLayer((monitor) => ({
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			clientOffset: monitor.getClientOffset(),
			initialClientOffset: monitor.getInitialClientOffset(),
			isDragging: monitor.isDragging(),
		}));

	function renderItem() {
		switch (itemType) {
			case DragItemTypes.DRIVING_SLIP:
				if ('drivingSlip' in item) {
					const thisItem = item as DragPropsDrivingSlip;
					const fakeItem = {
						type: CalendarEventType.DrivingSlip,
						referenceId: 'TOBECREATED',
						title: thisItem.drivingSlip.case.erpNo,
						timeRange: {
							from: thisItem.drivingSlip.merged[0].start.replace('Z', ''),
							to: thisItem.drivingSlip.merged[0].end.replace('Z', ''),
						},
						drivingSlip: thisItem.drivingSlip as unknown as GetUserCalendarsQuery['userCalendars'][number]['events'][number]['drivingSlip'],
					} as EventFragment;

					return <div className="w-48"><PlannerEvent event={fakeItem} user={emptyUser} /></div>;
				}
				break;
			case DragItemTypes.CAR:
				if ('vehicle' in item) {
					const thisItem = item as DragPropsVehicleItem;

					return <div className="w-48 text-blue"><VehicleItem isCar vehicle={thisItem.vehicle} /></div>;
				}
				break;
			case DragItemTypes.MATERIAL:
				if ('vehicle' in item) {
					const thisItem = item as DragPropsVehicleItem;

					return <div className="w-48 text-blue"><VehicleItem vehicle={thisItem.vehicle} /></div>;
				}
				break;
		}
	}

	if (!isDragging) {
		return null;
	}

	return (
		<div style={layerStyles}>
			<div style={getItemStyles(initialOffset, clientOffset)}>
				{renderItem()}
			</div>
		</div>
	);
};

export default PlannerCustomDragLayer;