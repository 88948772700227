import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const DamageContactSchema = yup.object().shape({
	name: yup.string().required(),

	role: yup.string().required(),

	phone: yup.string().required(),

	email: yup.string().email(),
});
