import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const CaseViewVisitationSchema = yup.object().shape({
	visitationSocialIssues: yup.boolean(),

	visitationResidentialArea: yup.boolean(),

	visitationLossOfBusiness: yup.boolean(),

	visitationRegress: yup.string().nullable(),

	visitationImidiateAttention: yup.boolean(),

	calledBack: yup.boolean(),

	calledBackDescription: yup.string(),

	awaiting: yup.boolean(),

	awaitingDescription: yup.string(),
});
