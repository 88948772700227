import * as yup from 'yup';

yup.setLocale({
	number: {
		required: 'error.required',
	},
});

export const moveMachineToNewCase = yup.object().shape(
	{
		consumption: yup.number().required(),
		startingDate: yup.string().required(),

		erpCaseReference: yup.string().when('erpLocationReference', {
			is: (erpLocationReference: string | undefined) => typeof erpLocationReference !== 'undefined',
			then: yup.string(),
			otherwise: yup.string().required(),
		}),

		erpLocationReference: yup.string().when('erpCaseReference', {
			is: (erpCaseReference: string | undefined) => typeof erpCaseReference !== 'undefined',
			then: yup.string(),
			otherwise: yup.string().required(),
		}),
	},
	[['erpLocationReference', 'erpCaseReference']],
);

