import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetCarCalendars, GetUserCalendars } from '../../GraphQL';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Popover from '@ssg/common/Components/Popover';

interface Props {
	drivingSlip: GetCarCalendars['carCalendars'][number]['events'][number]['drivingSlip'] | GetUserCalendars['userCalendars'][number]['events'][number]['drivingSlip'] | null;
	children: React.ReactElement;
	isDragging: boolean;
}

const PopoverDrivingSlipInfo: React.FC<Props> = ({ drivingSlip, children, isDragging }): React.ReactElement => {
	const { t } = useTranslation();

	if (drivingSlip === null) {
		return <>{children}</>;
	}

	const [findOldDriver] = drivingSlip.changes.filter(c => c.before.driver !== null).slice(-1);

	if (isDragging) return <>{children}</>;

	return (
		<>
			<Popover
				delay={[200, 0]}
				duration={0}
				placement="right"
				visible={isDragging ? false : undefined}
				content={
					<div className="w-46 rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs text-black">
						<div className="w-full">
							<div className="text-sm font-semibold">{t('case.caseNo') + drivingSlip.case.erpNo}</div>
							<p className="font-semibold">{t('case.ssgProjectManager')}</p>
							<p>{drivingSlip.case.projectManager?.name ?? t('common.noneSpecified')}</p>
							<p>{drivingSlip.case.projectManager?.phone ? t('common.tel') + formatPhoneNumberIntl(drivingSlip.case.projectManager?.phone ?? '') : ''}</p>
							<p className="mt-1 font-semibold">{t('case.damage')}</p>
							<p>{drivingSlip.case.damage.category.name}</p>
							<p>{drivingSlip.case.damage.cause.name}</p>
							<p className="mt-1 font-semibold">{t('case.injuredParty')}</p>
							<p>{drivingSlip.case.damage.contact.name}</p>
							<p>
								{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber} {drivingSlip.case.damage.contact.address.floor ?? ''}
							</p>
							<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
							<a href={`tel:${drivingSlip.case.damage.contact.phone}`}>
								{t('common.tlf')} {drivingSlip.case.damage.contact.phone}
							</a>
							<p className="font-semibold">{t('drivingSlips.formerDriver')}</p>
							<p>{findOldDriver && findOldDriver.before.driver?.name}</p>
							<p>{findOldDriver && findOldDriver.before.driver?.phone}</p>
						</div>
					</div>
				}
			>
				{children}
			</Popover>
		</>
	);
};

export default PopoverDrivingSlipInfo;
