import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { CatalogInput, CreateCatalog_createCatalog, GetWebCatalogCustomers_catalogCustomers, GetWebDebitors, GetWebDebitorsVariables } from '../../../GraphQL';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle as faQuistionCircleFull } from '@fortawesome/pro-solid-svg-icons';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Checkbox from '@ssg/common/Components/Checkbox';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import InputPhone from '@ssg/common/Components/InputPhone';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import UserContext from '../../../UserContext';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_DEBITORS = loader('src/GraphQL/Debitors/GetWebDebitors.gql');

interface Props {
	catalog: CreateCatalog_createCatalog;
	register: UseFormMethods['register'];
	errors: DeepMap<CatalogInput, FieldError>;
	setError: UseFormMethods['setError'];
	clearErrors: UseFormMethods['clearErrors'];
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
	trigger: UseFormMethods['trigger'];
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
}

const CatalogInformationBox: React.FC<Props> = ({ catalog, register, errors, setValue, control, trigger, catalogCustomers, setError, clearErrors }) => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const userContext = React.useContext(UserContext);
	const isExternal = userContext.user?.external;

	const [showHelpText, setShowHelpText] = React.useState(false);

	const [companySearchText, setCompanySearchText] = useDebouncedState('', 500);

	const { data: debitorsList, loading: debitorListLoading } = useQuery<GetWebDebitors, GetWebDebitorsVariables>(GET_DEBITORS, {
		variables: {
			searchText: companySearchText,
		},
		skip: companySearchText === '',
	});

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);

	const [searchUsernameText, setSearchUsernameText] = React.useState<string>('');

	const debitors = React.useMemo(() => {
		if (debitorsList?.debitors) {
			return debitorsList?.debitors.map(
				(d): SelectOption => ({
					value: d.debitorId,
					label: d.company + (d.debitorId !== null ? ` (${d.debitorId})` : ''),
				}),
			);
		}
		return [];
	}, [debitorsList]);

	const setCustomer = (id: string) => {
		setValue('customer', id, { shouldValidate: true });
	};

	React.useEffect(() => {
		setCustomers(catalogCustomers.filter(ct => ct.name.toLowerCase().includes(searchUsernameText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, isExternal, searchUsernameText]);

	return (
		<div>
			<div className="flex">
				<FormFieldHeader title="common.information" />
				<div onClick={() => setShowHelpText(!showHelpText)} className="text-blue mt-1 flex h-8 w-8 cursor-pointer items-center justify-center">
					{showHelpText ? <FontAwesomeIcon icon={faQuistionCircleFull} size="lg" /> : <FontAwesomeIcon icon={faQuestionCircle} size="lg" />}
				</div>
			</div>
			<Input title="catalog.propertyNumbers" name="propertyNumber" required defaultValue={catalog.propertyNumber} innerRef={register} errorMessage={errors?.propertyNumber?.message ?? ''} />

			<SearchableSelect
				key="userlabel"
				control={control}
				name="customer"
				title="catalog.customer"
				helpText="catalog.helpText.username"
				showHelpText={showHelpText}
				allowEmpty
				options={customers ?? []}
				searchFn={searchText => setSearchUsernameText(searchText)}
				onSelect={value => setCustomer(value)}
				onBlur={() => undefined}
				minInputLength={-1}
				isLoading={false}
				required
				errorMessage={errors?.customer?.message ? t('catalog.customerSelectError') : ''}
				initialSelection={{
					value: catalog.customer.id ?? '',
					label: catalog.customer.name ?? '',
				}}
			/>

			<SearchableSelect
				key="debitorlaben"
				control={control}
				name="debitor"
				title="common.debitor"
				helpText="catalog.helpText.debitor"
				showHelpText={showHelpText}
				required
				options={debitors ?? []}
				searchFn={val => {
					setCompanySearchText(val);
					setValue('debitor', '', { shouldValidate: true });
				}}
				onSelect={value => setValue('debitor', value, { shouldValidate: true })}
				onBlur={() => undefined}
				minInputLength={1}
				isLoading={debitorListLoading}
				errorMessage={errors?.debitor?.message ? t('catalog.noDebitorsFromValue') : ''}
			/>

			<Input title="catalog.ssgAccountable" name="ssgAccountable" defaultValue={catalog.ssgAccountable ?? ''} errorMessage={errors?.ssgAccountable?.message ?? ''} innerRef={register} />

			<InputPhone
				title="common.contactNumber"
				innerRef={register}
				name="contactNumber"
				errorMessage={errors?.contactNumber?.message ?? ''}
				helpText="common.contactNumber"
				showHelpText={showHelpText}
				control={control}
				trigger={trigger}
				searchFn={(phoneNumber): void => {
					if (!isValidPhoneNumber(phoneNumber)) {
						setError('phoneNumber', {
							type: 'manual',
							message: t('catalog.contact.phoneError'),
						});
						setValue('phoneNumber', '');
					} else {
						clearErrors('phoneNumber');
						phoneNumber = formatPhoneNumberIntl(phoneNumber).replaceAll(/(\s|-)+/g, '');
						setValue('phoneNumber', phoneNumber, {
							shouldValidate: true,
						});
					}
				}}
				onCountryChange={country => undefined}
				defaultCountry={envVar.defaultCountry ?? ''}
				defaultPhoneNumber={catalog.contactNumber ?? ''}
			/>

			<Input
				title="common.contactHours"
				name="contactHours"
				helpText="common.contactHours"
				showHelpText={showHelpText}
				defaultValue={catalog.contactHours ?? ''}
				errorMessage={errors?.contactHours?.message ?? ''}
				innerRef={register}
			/>

			<Checkbox title="catalog.smsService" name="smsService" defaultChecked={catalog.smsService} errorMessage={errors?.smsService?.message ?? ''} innerRef={register} className="pt-4" />
			{/* </Box> */}
		</div>
	);
};

export default CatalogInformationBox;
