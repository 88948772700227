import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CatalogAddressInput, CatalogInput, GetCatalog, GetCatalogVariables, GetCatalog_catalog_debitor, Permissions, UpdateCatalog, UpdateCatalogVariables } from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import { CatalogChangedBySchema } from '../../../Schemas/CatalogChangedBySchema';
import { IChangedBy } from '../CatalogOverview/CatalogTabs';
import { removeTypename } from '../../../helper';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from '@ssg/common/Components/Loading';
import Header from '@ssg/common/Components/Header';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import CatalogChangeLog from './CatalogChangeLog';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import AddressesBox from './AddressesBox';
import DescriptionBox from './DescriptionBox';
import UserContext from '../../../UserContext';
import CatalogDeleteModal from './CatalogDeleteModal';
import InformationBox from './InformationBox';
import Craftsmen from '../Craftsman/Craftsmen';
import Contacts from '../Contact/Contacts';
import TimedMessages from '../TimedMessage/TimedMessages';
import CatalogTimedMessagesInformation from './CatalogTimedMessagesInformation';
import FileBox from './FileBox/FileBox';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Input from '@ssg/common/Components/Input';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Checkbox from '@ssg/common/Components/Checkbox';

const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');
const UPDATE_CATALOG = loader('src/GraphQL/Catalogs/UpdateCatalog.gql');

interface LocationState {
	changedBy: string;
	catalogCustomer: string;
}

export interface CatalogToCaseState {
	debitor: GetCatalog_catalog_debitor | undefined;
	address: CatalogAddressInput | undefined;
}

const CatalogSingle: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation<LocationState>();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canDelete = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_DELETE]);
	const canVerify = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VERIFY]);
	const canVerifyDebitor = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_DEBITOR_VERIFY]);

	const username = userContext.user?.name ?? '';

	const {
		loading: catalogLoading,
		data: catalogData,
		refetch,
	} = useQuery<GetCatalog, GetCatalogVariables>(GET_CATALOG, {
		fetchPolicy: 'cache-and-network',
		variables: {
			id: id,
		},
	});
	const catalog = React.useMemo(() => catalogData?.catalog, [catalogData]);

	const [updateCatalog, { loading: loadingUpdate }] = useMutation<UpdateCatalog, UpdateCatalogVariables>(UPDATE_CATALOG);

	const [changedBy, setChangedBy] = React.useState<string>(username);

	const addresses: CatalogAddressInput[] = React.useMemo(() => removeTypename(catalogData?.catalog.addresses) ?? [], [catalogData]);

	const [showModal, setShowModal] = React.useState<boolean>(false);

	const { handleSubmit, register, errors } = useForm<IChangedBy>({
		resolver: yupResolver(CatalogChangedBySchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = (formData: IChangedBy) => {
		sessionStorage.setItem('catalogUsername', JSON.stringify(formData.changedBy));
		history.push(`/catalog/${id}`, {
			changedBy: `${username} - ${formData.changedBy}`,
		});
	};

	React.useEffect(() => {
		if (!location.state && !canVerify) {
			setShowModal(true);
		} else if (!location.state && canVerify) {
			history.push(`/catalog/${id}`, {
				changedBy: username,
			});
		} else {
			setChangedBy(username);
		}
	}, [canVerify, history, id, location.state, username]);

	const saveChanges = async (data: CatalogInput) => {
		try {
			await updateCatalog({
				variables: {
					id: id,
					...data,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: id,
							...data,
						},
					});

					if (cachedRequest === null || cachedRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: id,
							...data,
						},
						data: {
							catalog: {
								...cacheData.updateCatalog,
								addresses: [...cacheData.updateCatalog.addresses],
							},
						},
					});
				},
			});

			refetch();
		} catch (error) {
			console.log(error);
		}
	};

	const [debitorCompany, debitorSalesInfo] = React.useMemo(() => {
		if (catalog?.debitor == null) return ['common.notSpecified', 'common.notSpecified'];

		const companyString = `${catalog.debitor.debitorId} - ${catalog.debitor.company}${catalog.debitor.gln ? ` - ${catalog.debitor.gln}` : ''}`;
		const salesSCodetring = `${catalog.debitor.salesPersonCode.length > 0 ? catalog.debitor.salesPersonCode : 'common.notSpecifiedBC'}`;

		return [companyString, salesSCodetring];
	}, [catalog?.debitor]);

	if (catalogLoading && typeof catalogData === 'undefined') return <Loading />;

	return (
		<div>
			{catalog && !showModal ? (
				<div>
					<Header>
						<div className="text-blue flex text-sm lg:flex-row">
							<div className="mr-4 flex whitespace-nowrap">
								<div className="flex flex-col gap-y-1 py-2">
									<div className="flex h-6 flex-row items-center font-bold">
										<h1 className="text-xl">{`${t('catalog.customer')}: ${catalog.customer.name ?? t('common.notSpecified')}`}</h1>
									</div>
									<div className="text-sm">
										{t('catalog.propertyNumber')} <span>{catalog.propertyNumber}</span>
									</div>
								</div>
							</div>

							<div className="mr-4 flex flex-col justify-start gap-y-1 py-3">
								<div className="text-sm">
									<p>
										<strong>{t('common.debitor')}: </strong>
										{t(debitorCompany)}
									</p>
								</div>
								<div className="text-sm">
									<p>
										<strong>{t('common.debitorSalesCode')}: </strong>
										{t(debitorSalesInfo)}
									</p>
								</div>
							</div>

							<div className="mr-4 flex flex-col justify-start gap-y-1 py-3">
								<div className="text-sm">
									<strong>{t('common.updatedAt')}: </strong> <span>{dateToDateTimeString(catalog.updatedAt)}</span>
								</div>
								<div className="text-sm">
									<strong>{t('catalog.updatedBy')}: </strong> <span>{catalog.changedBy}</span>
								</div>
							</div>
							<div className="mr-4 flex flex-col justify-start gap-y-1 py-3">
								<div>
									<Checkbox
										name="verifiedToggle"
										title="catalog.verified"
										onClick={async e => {
											await updateCatalog({
												variables: {
													id: id,
													customer: catalog.customer.id,
													changedBy: changedBy,
													propertyNumber: catalog.propertyNumber,
													smsService: catalog.smsService,
													description: catalog.description,
													verified: !catalog.verified,
													debitor: catalog.debitor?.debitorId,
													debitorVerified: catalog.debitorVerified,
												},
												update: (cache, { data: cacheData }): void => {
													if (typeof cacheData === 'undefined' || cacheData === null) {
														return;
													}
													const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
														query: GET_CATALOG,
														variables: {
															id: id,
														},
													});

													if (cachedRequest === null || cachedRequest.catalog === null) {
														return;
													}

													cache.writeQuery<GetCatalog, GetCatalogVariables>({
														query: GET_CATALOG,
														variables: {
															id: id,
														},
														data: {
															catalog: {
																...cacheData.updateCatalog,
															},
														},
													});
												},
											});
										}}
										defaultChecked={catalog.verified}
										disabled={!canVerify}
									/>
								</div>
								<div>
									<Checkbox
										name="verifiedDebitorToggle"
										title="catalog.debitorVerified"
										onClick={async e => {
											await updateCatalog({
												variables: {
													id: id,
													customer: catalog.customer.id,
													changedBy: changedBy,
													propertyNumber: catalog.propertyNumber,
													smsService: catalog.smsService,
													description: catalog.description,
													verified: catalog.verified,
													debitor: catalog.debitor?.debitorId,
													debitorVerified: catalog.debitorVerified ? false : true,
												},
												update: (cache, { data: cacheData }): void => {
													if (typeof cacheData === 'undefined' || cacheData === null) {
														return;
													}

													const cachedRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
														query: GET_CATALOG,
														variables: {
															id: id,
														},
													});

													if (cachedRequest === null || cachedRequest.catalog === null) {
														return;
													}
													cache.writeQuery<GetCatalog, GetCatalogVariables>({
														query: GET_CATALOG,
														variables: {
															id: id,
														},
														data: {
															catalog: {
																...cacheData.updateCatalog,
															},
														},
													});
												},
											});
										}}
										defaultChecked={catalog.debitorVerified}
										disabled={!canVerifyDebitor}
									/>
								</div>
							</div>

							<div className="flex flex-col items-start gap-y-1 py-3">
								{catalog.version > 1 && <CatalogChangeLog catalogId={catalog.id} />}
								{canDelete && <CatalogDeleteModal catalog={catalog} changedBy={changedBy} />}
							</div>
						</div>
					</Header>

					<BoxContainer>
						<CatalogTimedMessagesInformation timedMessages={catalog.timedMessages} />

						<InformationBox key={catalog.updatedAt} saveChanges={saveChanges} loadingUpdate={loadingUpdate} data={catalog} changedBy={changedBy} />
						<DescriptionBox saveChanges={saveChanges} loadingUpdate={loadingUpdate} data={catalog} changedBy={changedBy} />

						<AddressesBox addresses={addresses} saveChanges={saveChanges} loadingUpdate={loadingUpdate} data={catalog} changedBy={changedBy} />

						<Contacts
							catalogCustomer={catalog.customer}
							catalogCustomers={[]}
							catalogId={id}
							changedBy={changedBy}
							contactsData={catalog.contacts.map(c => ({
								...c,
								catalogs: null,
							}))}
						/>

						<Craftsmen
							catalogCustomer={catalog.customer}
							catalogCustomers={[]}
							catalogId={id}
							changedBy={changedBy}
							craftsmenData={catalog.craftsmen.map(c => ({
								...c,
								catalogs: null,
							}))}
						/>

						<TimedMessages
							catalogCustomer={catalog.customer}
							catalogCustomers={[]}
							catalogId={id}
							changedBy={changedBy}
							timedMessagesData={catalog.timedMessages.map(t => ({
								...t,
								catalogs: null,
							}))}
						/>

						<FileBox catalogData={catalog} changedBy={changedBy} />
					</BoxContainer>
				</div>
			) : (
				<Modal
					title=""
					size={ModalSize.SMALL}
					visible={showModal}
					close={() => history.goBack()}
					body={
						<form onSubmit={handleSubmit(onSubmit)}>
							<Input
								title="catalog.changedBy"
								name="changedBy"
								defaultValue={changedBy}
								placeholder={t('common.name')}
								innerRef={register}
								errorMessage={errors?.changedBy?.message ?? ''}
								className="mb-3"
							/>

							<Button success submit text="common.approve" className="pl-4" />
						</form>
					}
				/>
			)}
		</div>
	);
};

export default CatalogSingle;
