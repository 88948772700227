import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldHeader from '../../../../Components/Forms/FormFieldHeader';
import { IndoorClimateMeassurementInput } from '../../../../GraphQL';

interface Props {
	indoorClimateMeassurements: IndoorClimateMeassurementInput[];
	onChange(indoorClimateMeassurements: IndoorClimateMeassurementInput[]): void;
}

const EMPTY_ROW = (): IndoorClimateMeassurementInput => ({
	room: '',
	construction: '',
	material: '',
	description: '',
	instrument: '',
	value: '',
	referenceValue: '',
	calculatedValue: '',
});

const TABLE_COLUMNS: Array<keyof IndoorClimateMeassurementInput> = ['room', 'construction', 'material', 'description', 'instrument', 'value', 'referenceValue', 'calculatedValue'];

const IndoorClimateMeassurementForm: React.FC<Props> = ({ indoorClimateMeassurements, onChange }) => {
	const { t } = useTranslation();

	function handleChange(idx: number, column: keyof IndoorClimateMeassurementInput, value: string) {
		const copy = indoorClimateMeassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	function addRow() {
		// if (indoorClimateMeassurements.length === 0) {
		//     onChange([...indoorClimateMeassurements, EMPTY_ROW(), EMPTY_ROW()]);

		// } else {
		//     onChange([...indoorClimateMeassurements, EMPTY_ROW()]);
		// }
		onChange([...indoorClimateMeassurements, EMPTY_ROW()]);
	}

	function removeRow(idx: number) {
		onChange(indoorClimateMeassurements.filter((_, i) => i !== idx));
	}

	return (
		<div className="w-full">
			<div className="flex gap-2">
				{TABLE_COLUMNS.map(column => (
					<div className="flex-1">
						<FormFieldHeader title={t(`reports.indoorClimateMeassurement.${column}`)} />
					</div>
				))}
			</div>

			{indoorClimateMeassurements.map((meassurement, idx) => (
				<div key={idx} className="flex gap-2 pb-2">
					{TABLE_COLUMNS.map((column, i) => (
						<div className="flex flex-1">
							<Input
								name={column}
								//placeholder={idx === 0 ? 'Dato' : ''}
								value={meassurement[column] ?? ''}
								onChange={e => handleChange(idx, column, e.target.value)}
							/>
							{i === TABLE_COLUMNS.length - 1 && (
								<p className="text-red pl-2 pt-2 text-xl font-bold" onClick={() => removeRow(idx)}>
									x
								</p>
							)}
						</div>
					))}
				</div>
			))}

			<Button onClick={addRow} primary>
				{t('reports.addIndoorClimateMeassurement')}
			</Button>
		</div>
	);
};

export default IndoorClimateMeassurementForm;
