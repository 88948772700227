import React from 'react';
import TextButton from '@ssg/common/Components/TextButton';
import newGuid from '@ssg/common/Helpers/guidHelper';

import './FileImage.css';

interface Props {
	image: {
		fileName: string;
		src: string;
		comments?: string | null;
	};
	canDelete: boolean;
	canSelect: boolean;
	loadingDeleteFile: boolean;
	selected: boolean;
	onClick: () => void;
	onDelete: () => void;
	onSelect: (checked: boolean) => void;
}

export const FileImage: React.FC<Props> = ({ image, canDelete, canSelect, loadingDeleteFile, selected, onClick, onDelete, onSelect }) => {
	const id = newGuid();
	return (
		<div>
			<div className="w-40 mr-4">
				<div className="relative w-40 h-40 overflow-hidden border-gray-300 checkbox-circle-container border-1" onClick={onClick}>
					<img src={image.src} alt={image.fileName} className="object-cover object-left-top w-40 h-40 cursor-pointer" />
					{canSelect && (
						<div className="absolute top-0 right-0 checkbox-circle-hidden-child">
							<div
								className="checkbox-circle"
								onClick={e => {
									e.stopPropagation();
								}}
							>
								<input
									type="checkbox"
									id={id}
									checked={selected}
									onChange={e => {
										onSelect(e.target.checked);
									}}
								/>
								<label htmlFor={id} />
							</div>
						</div>
					)}
				</div>
				<TextButton text={image.fileName} textClassName="w-40 truncate text-left font-normal" />
				<p className="text-sm truncate">{image.comments}</p>
			</div>
			{canDelete && <TextButton text="common.remove" textClassName="w-40 truncate text-left font-normal text-red" disabled={loadingDeleteFile} onClick={onDelete} />}
		</div>
	);
};
