import React from 'react';
import { faBan, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { CaseStatus, GetSingleCase_case, Permissions } from '../../GraphQL';
import { isCaseValidToClose } from '../../Components/Helpers/isCaseValidToClose';
import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';
import Popover from '@ssg/common/Components/Popover';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import TextButton from '@ssg/common/Components/TextButton';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	caseObj: GetSingleCase_case;
	caseStatus: CaseStatus;
	caseOrProjectManager: boolean;
	changeCaseStatus(status: CaseStatus): void;
	loading: boolean;
}

const CloseCaseButton: React.FC<Props> = ({ caseObj, caseStatus, caseOrProjectManager, changeCaseStatus, loading }): React.ReactElement => {
	const { t } = useTranslation();

	const [statusToChange, setStatusToChange] = React.useState<CaseStatus | undefined>(undefined);
	const activePurchaseDocumentsFlag = useFlag(FeatureFlagEnums.ACTIVE_PURCHASE_DOCUMENTS);
	const caseInvalidForClose = isCaseValidToClose(caseObj, activePurchaseDocumentsFlag);

	return (
		<>
			<Popover
				placement="bottom"
				content={
					<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
						<p>{t('case.cannotCloseReason')}:</p>
						<ul className="list-disc pl-4">
							{caseInvalidForClose.machineOnCase && <li className="font-semibold">{t('case.machinesOnCase')}</li>}
							{caseInvalidForClose.openTimeRegistrations && <li className="font-semibold">{t('case.openTimeRegistrations')}</li>}
							{caseInvalidForClose.openMovables && <li className="font-semibold">{t('case.openMovables')}</li>}
							{caseInvalidForClose.openDrivingSlips && <li className="font-semibold">{t('case.openDrivingSlips')}</li>}
							{caseInvalidForClose.activeMovables && <li className="font-semibold">{t('case.activeMovables')}</li>}
							{caseInvalidForClose.activePurchases && <li className="font-semibold">{t('case.activePurchases')}</li>}
							{caseInvalidForClose.activeInvoiceOnCase && <li className="font-semibold">{t('case.activeInvoice')}</li>}
						</ul>
					</div>
				}
				visible={caseInvalidForClose.invalidForClose ? undefined : false}
			>
				<div>
					<RestrictedArea
						permissions={[Permissions.CASES_CLOSE]}
						fallback={
							caseStatus === CaseStatus.OPEN &&
							caseOrProjectManager && (
								<TextButton
									icon={faBan}
									text="case.closeRequest"
									onClick={() => setStatusToChange(CaseStatus.APPLIED_CLOSED)}
									disabled={caseInvalidForClose.invalidForClose}
									loading={loading}
								/>
							)
						}
					>
						<TextButton icon={faBan} text="case.close" onClick={() => setStatusToChange(CaseStatus.CLOSED)} disabled={caseInvalidForClose.invalidForClose} loading={loading} />

						{caseStatus === CaseStatus.APPLIED_CLOSED && <TextButton icon={faCircle} text="case.rejectClose" onClick={() => changeCaseStatus(CaseStatus.OPEN)} />}
					</RestrictedArea>
				</div>
			</Popover>
			{typeof statusToChange !== 'undefined' && (
				<Modal
					title={statusToChange === CaseStatus.CLOSED ? 'case.confirmClose' : 'case.confirmCloseRequest'}
					close={() => setStatusToChange(undefined)}
					visible={typeof statusToChange !== 'undefined'}
					body={<div>{t(statusToChange === CaseStatus.CLOSED ? 'case.confirmCloseMessage' : 'case.confirmCloseRequestMessage')}</div>}
					footer={
						<div className="flex space-x-2">
							<Button
								text={statusToChange === CaseStatus.CLOSED ? 'case.close' : 'case.closeRequest'}
								onClick={() => {
									changeCaseStatus(statusToChange);
									setStatusToChange(undefined);
								}}
								success
							/>
							<Button text="common.cancel" onClick={() => setStatusToChange(undefined)} secondary />
						</div>
					}
				/>
			)}
		</>
	);
};

export default CloseCaseButton;
