import React from 'react';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { MovableActionButtonProps } from './MovablesTabs';
import { useTranslation } from 'react-i18next';
import MovableFilter from './MovableFilter';
import Table from '@ssg/common/Components/Table';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import MovableChangeLog from './MovableChangeLog';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import MovableReprint, { movableLabelColors } from './MovableReprint';
import Button from '@ssg/common/Components/Button';
import ComponentToPrint from './ComponentToPrint';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';
import { getCurrentOffset } from '../../helper';
import { GraphQLExtensionsData } from '@ssg/common/Components/GraphQLExtensionsContext';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { GetMovablesQuery, GetMovablesQueryVariables, useGetMovablesQuery } from '@ssg/common/GraphQL/indexV2';

const FETCH_LIMIT = 20;

interface Props {
	editButton: React.FC<MovableActionButtonProps>;
	completeButton: React.FC<MovableActionButtonProps>;
	setMovablesVariables: React.Dispatch<React.SetStateAction<GetMovablesQueryVariables | undefined>>;
	reviveButton: React.FC<MovableActionButtonProps>;
}

export interface Label {
	value: string;
	background: string;
	title?: string;
}

export interface MovableFilters {
	name?: string | null;
	erpNo: string;
	location: string;
	status: string;
	includeCompleted: boolean;
	debitors: string[] | null;
	fromDate: Date | null;
	toDate: Date | null;
}

const Movables: React.FC<Props> = ({ editButton, completeButton, reviveButton, setMovablesVariables }): React.ReactElement => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 200);

	const labelColorsTranslated = React.useMemo(
		() =>
			Object.entries(movableLabelColors).reduce(
				(map, [key, val]) => {
					map.push({ text: t(key), ...val });
					return map;
				},
				[] as Array<{ text: string; qrColor: string; qrColorText: string }>,
			),
		[t],
	);

	const getLabelReprintBtn = React.useCallback(
		(movable: GetMovablesQuery['movables'][number]) => {
			const labelInfo =
				labelColorsTranslated.find(opt => movable.status.toUpperCase() === opt.text.toUpperCase()) ??
				labelColorsTranslated.find(opt => opt.text.toUpperCase().startsWith(movable.status.toUpperCase()));

			if (typeof labelInfo === 'undefined') {
				return null;
			}

			return <ComponentToPrint hideButtonText movable={movable} className="bg-blue px-3 py-2 text-white" qrText={labelInfo.text} {...labelInfo} />;
		},
		[labelColorsTranslated],
	);

	// Filters
	const [activeMovableFiltersName, setActiveMovableFiltersName] = useStorageState<string>(window.sessionStorage, 'activeUserFilter', '');

	const selectedUserFilter = React.useMemo(() => userContext?.user?.movableFilters.find(f => f.name === activeMovableFiltersName), [activeMovableFiltersName, userContext?.user?.movableFilters]);

	//Removed stupid local storrage - TODO: Maybe change back later
	const [activeMovableFilters, setActiveMovableFilters] = React.useState<MovableFilters>({
		name: null,
		erpNo: '',
		location: '',
		status: '',
		includeCompleted: false,
		debitors: [],
		fromDate: null,
		toDate: null,
	});

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	React.useEffect(() => {
		if (typeof selectedUserFilter === 'undefined') {
			return;
		}

		setActiveMovableFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserFilter.name,
			erpNo: selectedUserFilter.erpNo,
			location: selectedUserFilter.location,
			status: selectedUserFilter.status,
			includeCompleted: selectedUserFilter.includeCompleted ?? false,
			debitors: selectedUserFilter.debitors,
		}));
	}, [selectedUserFilter, setActiveMovableFilters]);

	const { data, loading, fetchMore } = useGetMovablesQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			case: activeMovableFilters.erpNo,
			location: activeMovableFilters.location,
			status: activeMovableFilters.status,
			includeCompleted: activeMovableFilters.includeCompleted,
			debitors: activeMovableFilters.debitors,
			fromDate: activeMovableFilters.fromDate ? formatDateForInput(new Date(activeMovableFilters.fromDate ?? '')) : null,
			toDate: activeMovableFilters.toDate ? formatDateForInput(new Date(activeMovableFilters.toDate ?? '')) : null,

			offset: 0,
			limit: FETCH_LIMIT,
			searchString: searchTerm,
		},
	});

	const [offset, setOffset] = React.useState(getCurrentOffset((data?.movables ?? []).length, FETCH_LIMIT));

	// Used for updating cache in create movable modal
	React.useEffect(() => {
		setMovablesVariables({
			...activeMovableFilters,
			fromDate: activeMovableFilters.fromDate ? formatDateForInput(new Date(activeMovableFilters.fromDate ?? '')) : null,
			toDate: activeMovableFilters.toDate ? formatDateForInput(new Date(activeMovableFilters.toDate ?? '')) : null,
			offset: offset,
			limit: FETCH_LIMIT,
			searchString: searchTerm,
		});
	}, [activeMovableFilters, offset, searchTerm, setMovablesVariables]);

	const movables = React.useMemo(
		() =>
			(data?.movables ?? []).map(m => ({
				...m,
				labelReprintBtn: getLabelReprintBtn(m),
			})),
		[data?.movables, getLabelReprintBtn],
	);

	const movablesTotalCountName = 'movablesTotalCount';
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const totalMovables = React.useMemo(() => (graphQLExtensionsData[movablesTotalCountName] as number | undefined | null) ?? 0, [graphQLExtensionsData]);

	return (
		<BoxContainer>
			<Box full loading={loading}>
				<MovableFilter
					activeMovableFilters={activeMovableFilters}
					setActiveMovableFilters={setActiveMovableFilters}
					setFilterTerm={setSearchTerm}
					setActiveMovableFiltersName={setActiveMovableFiltersName}
					activeMovableFiltersName={activeMovableFiltersName}
					movables={movables}
				/>

				<GraphQLExtensionsElement
					name={movablesTotalCountName}
					render={value => <TotalCount totalCount={value as number | undefined | null} loading={loading} quantityText={t('common.quantity')} entityText={t('common.movables')} />}
				/>

				<Table
					data={movables}
					keySelector={m => m.id}
					noDataFoundText="movable.noMovablesFound"
					columns={[
						{
							label: 'common.printLabel',
							selectFn: m => (m.labelReprintBtn === null ? <MovableReprint movable={m} popoverPlacement="right" className="bg-blue px-3 py-2 text-white" /> : <>{m.labelReprintBtn}</>),
						},
						{
							label: 'common.debitor',
							selectFn: m => m.case.debitor.company,
							sortFn: (a, b) => a.case.debitor.company.localeCompare(b.case.debitor.company),
						},
						{
							label: 'common.case',
							selectFn: m => m.case.erpNo,
							sortFn: (a, b) => a.case.erpNo.localeCompare(b.case.erpNo),
						},
						{
							label: 'common.description',
							selectFn: m => m.description,
							sortFn: (a, b) => a.description.localeCompare(b.description),
						},
						{
							label: 'movable.volume',
							selectFn: m => <span>{m.volume}&nbsp;m&sup3;</span>,
							numeric: true,
							sortFn: (a, b) => a.volume - b.volume,
						},
						{
							label: 'common.placement',
							selectFn: m => (
								<>
									<span className="font-semibold">{m.placement.location.name}</span>
									<br />
									<span>{m.placement.name}</span>
								</>
							),
							sortFn: (a, b) => {
								const locationCompare = a.placement.location.name.localeCompare(a.placement.location.name);
								if (locationCompare !== 0) {
									return locationCompare;
								}

								return a.placement.name.localeCompare(b.placement.name);
							},
						},
						{
							label: 'common.status',
							selectFn: m => m.status,
							sortFn: (a, b) => a.status.localeCompare(b.status),
						},
						{
							label: 'common.changes',
							selectFn: m => <MovableChangeLog version={m.version} changes={m.changes} />,
							sortFn: (a, b) => Number(a.version > 1) - Number(b.version > 1),
						},
						{
							label: 'common.edit',
							selectFn: m =>
								editButton({
									id: m.id,
									status: m.status,
									className: 'px-3 py-2 my-1',
								}) ?? '',
						},
						{
							label: 'common.complete',
							selectFn: m =>
								completeButton({
									id: m.id,
									status: m.status,
									className: 'px-3 py-2 my-1',
								}) ?? '',
						},
						// TODO: Implement CR#150 when CR embargo is removed
						// {
						//     label: 'common.complete',
						//     selectFn: m => (m.completed
						//         ? reviveButton({ id: m.id, status: m.status, className: 'px-3 py-2 my-1' }) ?? ''
						//         : completeButton({ id: m.id, status: m.status, className: 'px-3 py-2 my-1' }) ?? ''
						//     ),
						// },
					]}
				/>

				{typeof data !== 'undefined' && totalMovables > movables.length && (
					<Button
						fullWidth
						text="common.getMore"
						secondary
						loading={fetchMoreLoading}
						className="mt-2"
						onClick={async () => {
							setOffset(movables.length);
							setFetchMoreLoading(true);
							await fetchMore({
								variables: {
									offset: movables.length,
								},
							});
							setFetchMoreLoading(false);
						}}
					/>
				)}
			</Box>
		</BoxContainer>
	);
};

export default Movables;
