import * as yup from 'yup';
import { PolicyHolderSchema } from './PolicyHolderSchema';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const caseViewPolicyHolderSchema = yup.object().shape({
	PolicyHolderBox: PolicyHolderSchema,

	requisitionerInsuranceExcesses: yup.number().transform(cv => (isNaN(cv) ? undefined : cv)),

	requisitionerInsuranceNumber: yup.string(),

	CVR: yup.string(),

	debitorType: yup.string().required(),
});
