import * as yup from 'yup';
import { AdviserSchema } from './AdviserSchema';
import { ReferrerSchema } from './ReferrerSchema';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		min: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const AdviserReferrerSchema = yup.object().shape({
	AdviserBox: AdviserSchema,

	ReferrerBox: ReferrerSchema,
});
