import React from 'react';
import {
	CalendarEventType,
	CreateDrivingSlip,
	CreateDrivingSlipVariables,
	GetCarCalendars,
	GetCarCalendars_carCalendars,
	GetCarsWithCalendars,
	GetUserCalendars,
	GetUserCalendars_userCalendars,
	GetUserCalendars_userCalendars_events,
	GetUsersWithCalendars,
} from '../../GraphQL';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariableSizeGrid as Grid, GridChildComponentProps } from 'react-window';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import WorkerLine from './WorkerLine';
import Loading from '@ssg/common/Components/Loading';
import Dropdown from '@ssg/common/Components/Dropdown';
import useElementSize from '@ssg/common/Hooks/useElementSize';
import classNames from 'classnames';
import DateRangePicker from './DateRangePicker';
import UserHelpText from './UserHelpText';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';

const CREATE_DRIVING_SLIP = loader('src/GraphQL/DrivingSlips/CreateDrivingSlip.gql');
const GET_USER_CALENDARS = loader('src/GraphQL/Planner/GetUserCalendars.gql');

interface Props {
	startDate: DateTime;
	setStartDate(date: DateTime): void;
	endDate: DateTime;
	setEndDate(date: DateTime): void;
	getBgColor(userId: string): string;
	calendarsLoading: boolean;
	deselectAll(): void;

	selectedUserIds: string[];
	userCalendars?: GetUserCalendars['userCalendars'];
	handleOnUserClick(id: GetUsersWithCalendars['usersWithCalendars'][number]['id']): void;
	usersWithCalendar: GetUsersWithCalendars['usersWithCalendars'];

	selectedCarIds: string[];
	carCalendars?: GetCarCalendars['carCalendars'];
	handleOnCarClick(id: GetCarsWithCalendars['carsWithCalendars'][number]['id']): void;
	carsWithCalendar: GetCarsWithCalendars['carsWithCalendars'];

	drivingSlipsRefetch(): unknown;
}

interface WindowData {
	userCalendars: GetUserCalendars_userCalendars[];
	carsCalendars: GetCarCalendars_carCalendars[];
	datesArr: DateTime[];
}

enum TimeRange {
	WORKDAY = 'WORKDAY',
	ALLDAY = 'ALLDAY',
}

const timeRangeOptions: SelectOption[] = [
	{ label: 'planner.workDay', value: 'WORKDAY' },
	{ label: 'planner.allDay', value: 'ALLDAY' },
];

const Scheduler: React.FC<Props> = ({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	getBgColor,
	selectedUserIds,
	selectedCarIds,
	calendarsLoading,
	deselectAll,
	userCalendars = [],
	handleOnUserClick,
	usersWithCalendar,
	carCalendars = [],
	handleOnCarClick,
	carsWithCalendar,
	drivingSlipsRefetch,
}): React.ReactElement => {
	const { t } = useTranslation();

	const [startTime, setStartTime] = React.useState<number>(6);
	const [endTime, setEndTime] = React.useState<number>(16);
	const [timeRange, setTimeRange] = React.useState(TimeRange.WORKDAY);
	const [clickedTimeSlot, setClickedTimeSlot] = React.useState('');
	const durationInDays = React.useMemo(() => Math.ceil(endDate.diff(startDate, 'day').toObject().days ?? 0), [endDate, startDate]);
	const datesArr = React.useMemo(() => Array.from({ length: durationInDays + 1 }, (_, i) => startDate.plus({ days: i })), [durationInDays, startDate]);

	React.useEffect(() => {
		if (timeRange === TimeRange.ALLDAY) {
			setStartTime(0);
			setEndTime(23);
		} else if (timeRange === TimeRange.WORKDAY) {
			setStartTime(6);
			setEndTime(16);
		}
	}, [timeRange]);

	const [dataRefetcherLoading, setDataRefetcherLoading] = React.useState(false);
	const [createDrivingSlipFn] = useMutation<CreateDrivingSlip, CreateDrivingSlipVariables>(CREATE_DRIVING_SLIP);
	const createDrivingSlip = async (drivingSlip: CreateDrivingSlipVariables['drivingSlip']['input'], caseId: CreateDrivingSlipVariables['caseId']) => {
		setDataRefetcherLoading(true);
		await createDrivingSlipFn({
			variables: {
				drivingSlip: {
					input: drivingSlip,
					immediatelyPlan: true,
					additionalDays: [],
					additionalDrivers: [],
					driverCars: [],
				},
				caseId,
				includeCase: true,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.createDrivingSlip === null) {
					return;
				}
				const createdDrivingSlip = data.createDrivingSlip[0];
				const cachedRequest = cache.readQuery<GetUserCalendars>({
					query: GET_USER_CALENDARS,
					variables: {
						userIdList: selectedUserIds,
						dateRange: {
							from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
							to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
						},
					},
				});

				if (cachedRequest === null || cachedRequest.userCalendars === null) {
					return;
				}
				const newDrivingSlipEvent: GetUserCalendars_userCalendars_events = {
					type: CalendarEventType.DRIVING_SLIP,
					drivingSlip: createdDrivingSlip,
					referenceId: createdDrivingSlip.eventReferenceId ?? '',
					systemCreatedEvent: true,
					title: createdDrivingSlip.case.erpNo,
					timeRange: {
						from: drivingSlip.start,
						to: drivingSlip.end,
					},
					isAllDay: false,
					__typename: 'CalendarEvent',
				};

				const cachedRequestCopy = [...cachedRequest.userCalendars];
				const userEntryIndex = cachedRequestCopy.findIndex(c => c.user.id === createdDrivingSlip.driver?.id);
				cachedRequestCopy[userEntryIndex] = {
					...cachedRequestCopy[userEntryIndex],
					events: [...cachedRequestCopy[userEntryIndex].events, newDrivingSlipEvent],
				};
				cache.writeQuery<GetUserCalendars>({
					query: GET_USER_CALENDARS,
					variables: {
						userIdList: selectedUserIds,
						dateRange: {
							from: dateToDateOnlyString(startDate.minus({ days: 1 }).toJSDate()),
							to: dateToDateOnlyString(endDate.plus({ days: 1 }).toJSDate()),
						},
					},
					data: {
						userCalendars: cachedRequestCopy,
					},
				});
			},
		});

		setDataRefetcherLoading(false);
	};

	const renderColumns = React.useCallback((start: number, end: number, showTime = true) => {
		const timeColumns: React.ReactElement[] = [];
		for (let i = start; i <= end; i++) {
			timeColumns.push(
				<div key={i + (showTime ? 'top' : 'bottom')} className="border-r-1 flex-grow-default truncate border-gray-300 py-1 pl-px">
					{showTime && `${i < 10 ? '0' : ''}${i}:00`}
				</div>,
			);
		}
		return timeColumns;
	}, []);

	const selectedUserCalendars = React.useMemo(
		() =>
			userCalendars
				.slice()
				.sort((a, b) => a.user.name.localeCompare(b.user.name))
				.filter(c => selectedUserIds.includes(c.user.id)),
		[userCalendars, selectedUserIds],
	);

	const selectedCarCalendars = React.useMemo(
		() =>
			carCalendars
				.slice()
				.sort((a, b) => a.car.name.localeCompare(b.car.name))
				.filter(c => selectedCarIds.includes(c.car.id)),
		[carCalendars, selectedCarIds],
	);

	const Item = React.memo(({ columnIndex, rowIndex, style, data }: GridChildComponentProps<WindowData>) => {
		const userCalendarsCount = data.userCalendars.length;
		const carCalendarsCount = data.carsCalendars.length;
		const userCalendar = data.userCalendars[rowIndex % userCalendarsCount];
		const carCalendar = data.carsCalendars[(rowIndex - userCalendarsCount) % carCalendarsCount];
		const datesArray = data.datesArr;
		const date = datesArray[columnIndex];

		return (
			<div style={style}>
				{rowIndex < userCalendarsCount ? (
					<div
						className={classNames('bg-opacity-10', getBgColor(userCalendar.user.id))}
						style={{
							height: 220,
							marginLeft: columnIndex === 0 ? 200 : 0,
						}}
					>
						<WorkerLine
							key={`${userCalendar.user.id}_scheduler_${date.toISO()}`}
							calendar={userCalendar}
							date={date.toJSDate()}
							rangeStartTime={startTime}
							rangeEndTime={endTime}
							startDate={startDate}
							endDate={endDate}
							getBgColor={getBgColor}
							selectedEntitiesId={selectedUserIds}
							timeSlotClickHandler={setClickedTimeSlot}
							clickedTimeSlot={clickedTimeSlot}
							usersWithCalendar={usersWithCalendar}
							carsWithCalendar={carsWithCalendar}
							createDrivingSlip={createDrivingSlip}
							drivingSlipsRefetch={drivingSlipsRefetch}
						/>
					</div>
				) : (
					<div
						className={classNames('bg-opacity-10', getBgColor(carCalendar.car.id))}
						style={{
							height: 220,
							marginLeft: columnIndex === 0 ? 200 : 0,
						}}
					>
						<WorkerLine
							key={`${carCalendar.car.id}_scheduler_${date.toISO()}`}
							calendar={carCalendar}
							date={date.toJSDate()}
							rangeStartTime={startTime}
							rangeEndTime={endTime}
							startDate={startDate}
							endDate={endDate}
							getBgColor={getBgColor}
							selectedEntitiesId={selectedCarIds}
							timeSlotClickHandler={setClickedTimeSlot}
							clickedTimeSlot={clickedTimeSlot}
							usersWithCalendar={usersWithCalendar}
							carsWithCalendar={carsWithCalendar}
							createDrivingSlip={createDrivingSlip}
							drivingSlipsRefetch={drivingSlipsRefetch}
						/>
					</div>
				)}
			</div>
		);
	});

	const [squareRef, { width }] = useElementSize();

	return (
		<div className="relative flex h-full w-full flex-col bg-white" ref={squareRef} style={{ minWidth: '825px' }}>
			<div className="flex">
				<DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
				<div className="my-2 ml-auto mr-4 w-40">
					<Dropdown
						name="selecttimerange"
						data={timeRangeOptions}
						className="text-blue w-full lg:w-full"
						onChange={e => setTimeRange(e.currentTarget.value as TimeRange)}
						value={timeRange}
					/>
				</div>
			</div>
			{(calendarsLoading || dataRefetcherLoading) && <Loading title="planner.gettingCalendars" />}
			{selectedUserCalendars.length === 0 && selectedCarCalendars.length === 0 ? (
				<div className="text-blue h-full w-full pt-14 text-center font-semibold">{t('planner.pickCalendarEntities')}</div>
			) : (
				<Grid
					columnCount={datesArr.length}
					columnWidth={index => (index === 0 ? 1300 : 1100)}
					height={1500}
					rowCount={selectedUserCalendars.length + selectedCarCalendars.length}
					rowHeight={index => 220}
					width={width}
					itemData={{
						userCalendars: selectedUserCalendars,
						carsCalendars: selectedCarCalendars,
						datesArr: datesArr,
					}}
					innerElementType={React.forwardRef(({ children, ...rest }, ref) => (
						<div ref={ref} {...rest} style={{ width: 200 + datesArr.length * 1100 }}>
							<div className="sticky top-0 z-50 flex flex-row items-end bg-white" style={{ height: 51 }}>
								<div className="sticky top-0 left-0 flex flex-row bg-white" style={{ width: 200 }}>
									<div className="border-b-1 text-blue w-full px-2 pb-px">
										<div className="flex w-full cursor-pointer items-center justify-between text-sm" onClick={() => deselectAll()}>
											<p className="font-medium">{t('common.deselectAll')}</p>
											<div className="flex w-4 cursor-pointer items-center justify-center">
												<FontAwesomeIcon icon={faTimes} />
											</div>
										</div>
									</div>
								</div>

								{datesArr.map(date => (
									<div key={date.toISO()} className="flex flex-col bg-white" style={{ width: 1100 }}>
										<div className="w-full text-center">{dateToDateTimeString(date.toJSDate()).split(' ')[0]}</div>
										<div className="border-b-1 flex flex-row">
											<div className="flex h-full w-full flex-row text-left text-xs">{renderColumns(startTime, endTime)}</div>
										</div>
									</div>
								))}
							</div>

							<div className="relative">
								{selectedUserCalendars.map(userCalendar => (
									<div
										key={userCalendar.user.id}
										className={classNames('sticky left-0 z-30 flex flex-col px-2 pt-2 pb-4 text-sm text-white', getBgColor(userCalendar.user.id))}
										style={{ width: 200, height: 220 }}
									>
										<div className="flex justify-end">
											<div className="flex w-4 cursor-pointer items-center justify-center" onClick={() => handleOnUserClick(userCalendar.user.id)}>
												<FontAwesomeIcon icon={faTimes} />
											</div>
										</div>
										<p className="line-clamp-2 font-medium">{userCalendar.user.name}</p>
										<div>
											<a href={`tel:${userCalendar.user.phone}`} className="truncate text-xs hover:underline">
												{userCalendar.user.phone}
											</a>
										</div>
										<div>
											<a href={`mailto:${userCalendar.user.email}`} className="truncate text-xs hover:underline">
												{userCalendar.user.email}
											</a>
										</div>
										<div className="mt-2 italic">
											<UserHelpText userId={userCalendar.user.id} userHelpText={userCalendar.user.plannerHelpText} updatedAt={userCalendar.user.plannerHelpTextUpdatedAt} />
										</div>
									</div>
								))}

								{selectedCarCalendars.map(carCalendar => (
									<div
										key={carCalendar.car.id}
										className={classNames('sticky left-0 z-30 flex flex-col px-2 pt-2 pb-4 text-sm text-white', getBgColor(carCalendar.car.id))}
										style={{ width: 200, height: 220 }}
									>
										<div className="flex justify-end">
											<div className="flex w-4 cursor-pointer items-center justify-center" onClick={() => handleOnCarClick(carCalendar.car.id)}>
												<FontAwesomeIcon icon={faTimes} />
											</div>
										</div>
										<p className="line-clamp-2 font-medium">{carCalendar.car.name}</p>
										<a href={`mailto:${carCalendar.car.email}`} className="truncate text-xs hover:underline">
											{carCalendar.car.email}
										</a>
									</div>
								))}

								{children}
							</div>
						</div>
					))}
				>
					{Item}
				</Grid>
			)}
		</div>
	);
};

export default Scheduler;
