import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faEdit, faQuoteRight } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CasePriority, GetSingleCase_case, GetCauseQuestions, GetCauseQuestionsVariables, GetCollectionItems, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { CaseViewVisitationSchema } from '../../Schemas/CaseViewVisitaionSchema';
import { IVisitationBox } from '../../Schemas/ICaseCreation';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import Radio from '@ssg/common/Components/Radio';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import Popover from '@ssg/common/Components/Popover';
import classNames from 'classnames';
import Textarea from '@ssg/common/Components/Textarea';
import { CollectionItemType } from '@ssg/common/GraphQL';
import TextButton from '@ssg/common/Components/TextButton';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const GET_CAUSE_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetCauseQuestions.gql');
const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

export const regressResolver = (boolOrNull: boolean | null): string => {
	if (boolOrNull === null) return 'common.dontKnow';
	if (boolOrNull) return 'common.yes';

	return 'common.no';
};

export const yesNoMaybeResolver = (boolOrNull: boolean | null): string => {
	if (boolOrNull === null) return 'Dont know';
	if (boolOrNull) return 'yes';
	return 'no';
};

const visitationRegresss: SelectOption[] = [
	{ label: 'common.yes', value: 'yes' },
	{ label: 'common.no', value: 'no' },
	{ label: 'common.dontKnow', value: 'Dont know' },
];

const moistureReportRequiredOptions: SelectOption[] = [
	{ label: 'common.yes', value: 'yes' },
	{ label: 'common.no', value: 'no' },
	{ label: 'common.dontKnow', value: 'Dont know' },
];

const isSpPhaseOneReportCreatedOptions: SelectOption[] = [
	{ label: 'common.yes', value: 'yes' },
	{ label: 'common.no', value: 'no' },

];

const yesNoMaybe = (boolOrNull: string): boolean | null => {
	if (boolOrNull === 'yes') return true;
	if (boolOrNull === 'no') return false;

	return null;
};

const VisitationBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();
	const [editActive, setEditActive] = React.useState(false);
	const [tippy, setTippy] = React.useState<boolean | undefined>(undefined);

	const { data: visitationData } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		skip: !editActive,
	});

	const visitationCallBackTexts = React.useMemo(
		() => visitationData?.collectionItems.filter(f => f.type === CollectionItemType.VISITATION_DESCRIPTION_CALL_BACK).map(item => item.name) ?? [],
		[visitationData],
	);
	const visitationAwaitTexts = React.useMemo(
		() => visitationData?.collectionItems.filter(f => f.type === CollectionItemType.VISITATION_DESCRIPTION_AWAIT).map(item => item.name) ?? [],
		[visitationData],
	);

	const caseRegress = regressResolver(caseData.visitation.regress);

	const caseCalledBack = (): string => {
		if (!caseData.visitation.calledBack.value) {
			return 'common.no';
		}
		return `${t('common.yes')}${caseData.visitation.calledBack.timestamp !== null ? ` (${dateToDateTimeString(caseData.visitation.calledBack.timestamp)})` : ''}`;
	};

	const caseAwaiting = (): string => {
		if (!caseData.visitation.awaiting.value) {
			return 'common.no';
		}
		return `${t('common.yes')}${caseData.visitation.awaiting.timestamp !== null ? ` (${dateToDateTimeString(caseData.visitation.awaiting.timestamp)})` : ''}`;
	};

	const { data: questions, loading } = useQuery<GetCauseQuestions, GetCauseQuestionsVariables>(GET_CAUSE_QUESTIONS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			damageCause: caseData.damage.cause.id,
		},
	});

	const questionsMap = new Map();

	questions?.causeQuestions.forEach(q => {
		questionsMap.set(q.id, { id: q.id, question: q.question, answer: '' });
	});

	questionsMap.forEach(qm => {
		caseData.visitation.answers?.forEach(a => {
			if (a.question === qm.id) {
				questionsMap.set(qm.id, {
					id: qm.id,
					question: qm.question,
					answer: a.answer,
				});
			}
		});
	});

	const questionsArray = Array.from(questionsMap.values());

	const visitation: SelectOption[] = [
		{
			value: caseData.visitation.urgent ? 'common.yes' : 'common.no',
			label: 'common.urgent',
		},
		{ value: caseData.visitation.isMoistureReportRequired !== null ? caseData.visitation.isMoistureReportRequired ? 'common.yes' : 'common.no' : 'common.notSpecified', label: 'case.isMoistureReportRequired' },
		{ value: caseData.visitation.isSpPhaseOneReportCreated !== null ? caseData.visitation.isSpPhaseOneReportCreated ? 'common.yes' : 'common.no' : 'common.notSpecified', label: 'case.isSpPhaseOneReportCreated' },
		{ value: caseCalledBack(), label: 'case.calledBack' },
		{ value: caseData.visitation.calledBack.comment ?? '', label: '' },
		{ value: caseAwaiting(), label: 'common.awaiting' },
		{ value: caseData.visitation.awaiting.comment ?? '', label: '' },
		{ value: caseRegress, label: 'case.visitationRegress' },
	];

	questionsArray.forEach(item =>
		visitation.push({
			value: item.answer !== '' ? item.answer : t('common.notSpecified'),
			label: item.question,
		}),
	);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, errors, register, setValue, getValues } = useForm<IVisitationBox>({
		resolver: yupResolver(CaseViewVisitationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IVisitationBox) => {
		try {
			const casePriorities: CasePriority[] = [];
			data.visitationSocialIssues && casePriorities.push(CasePriority.SOCIAL_IMPACT);
			data.visitationResidentialArea && casePriorities.push(CasePriority.RESIDENTIAL_AREA);
			data.visitationLossOfBusiness && casePriorities.push(CasePriority.LOSS_OF_BUSINESS_EARNING_CAPACITY);

			const caseRegress = yesNoMaybe(data.visitationRegress);
			const isMoistureReportRequired = yesNoMaybe(data.isMoistureReportRequired);

			const isSpPhaseOneReportCreated = yesNoMaybe(data.isSpPhaseOneReportCreated);
			await postCaseUpdate({
				id: caseData.id,
				visitation: {
					urgent: data.visitationImidiateAttention,
					priorities: casePriorities as CasePriority[],
					regress: caseRegress,
					calledBack: {
						value: data.calledBack,
						comment: data.calledBack ? data.calledBackDescription : null,
					},
					awaiting: {
						value: data.awaiting,
						comment: data.awaiting ? data.awaitingDescription : null,
					},
					answers: data.answers,
					isMoistureReportRequired: isMoistureReportRequired,
					isSpPhaseOneReportCreated: isSpPhaseOneReportCreated,
				},
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, visitation }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				visitation: visitation,
			},
		});
	};

	return (
		<>
			<Box title="case.visitation" full className="mb-8" icon={caseViewExternal ? undefined : faEdit} onClick={() => setEditActive(true)}>
				<hr />
				<BoxFormDataViewer data={visitation} index={4} keyId="case.visitation">
					<div className="border-t-1 flex border-gray-100 py-1">
						<div className="w-1/3 text-xs font-semibold">{t('case.priority')}</div>
						<div className="text-xs">
							{caseData.visitation.priorities.map(p => (
								<div key={p}>{t(`case.${p}`)}</div>
							))}
						</div>
					</div>
				</BoxFormDataViewer>
			</Box>
			<Modal
				title="case.editVisitation"
				close={() => setEditActive(false)}
				visible={editActive}
				size={questions?.causeQuestions.length ? ModalSize.MEDIUM : ModalSize.SMALL}
				body={
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap text-sm">
						<div
							className={classNames({
								'w-1/2 pr-2': questions?.causeQuestions.length,
								'w-full': !questions?.causeQuestions.length,
							})}
						>
							<FormFieldHeader title="case.call" />

							<Checkbox name="visitationImidiateAttention" title="case.visitationImidiateAttention" innerRef={register} defaultChecked={caseData.visitation.urgent} />
							<FormFieldHeader title="case.priority" />
							<div className="my-2 flex flex-col space-y-4">
								<Checkbox
									name="visitationSocialIssues"
									title="case.SOCIAL_IMPACT"
									innerRef={register}
									defaultChecked={caseData.visitation.priorities.includes(CasePriority.SOCIAL_IMPACT)}
								/>
								<Checkbox
									name="visitationResidentialArea"
									title="case.RESIDENTIAL_AREA"
									innerRef={register}
									defaultChecked={caseData.visitation.priorities.includes(CasePriority.RESIDENTIAL_AREA)}
								/>
								<Checkbox
									name="visitationLossOfBusiness"
									title="case.LOSS_OF_BUSINESS_EARNING_CAPACITY"
									innerRef={register}
									defaultChecked={caseData.visitation.priorities.includes(CasePriority.LOSS_OF_BUSINESS_EARNING_CAPACITY)}
								/>
							</div>

							<Radio
								title="case.visitationRegress"
								options={visitationRegresss}
								name="visitationRegress"
								innerRef={register}
								errorMessage={errors.visitationRegress?.message ?? ''}
								dValue={yesNoMaybeResolver(caseData.visitation.regress)}
							/>

							<Radio
								title="case.isMoistureReportRequired"
								options={moistureReportRequiredOptions}
								name="isMoistureReportRequired"
								innerRef={register}
								errorMessage={errors.isMoistureReportRequired?.message ?? ''}
								dValue={yesNoMaybeResolver(caseData.visitation.isMoistureReportRequired)}
							/>

							<Radio
								title="case.isSpPhaseOneReportCreated"
								options={isSpPhaseOneReportCreatedOptions}
								name="isSpPhaseOneReportCreated"
								innerRef={register}
								errorMessage={errors.isSpPhaseOneReportCreated?.message ?? ''}
								dValue={yesNoMaybeResolver(caseData.visitation.isSpPhaseOneReportCreated)}
							/>

							<div className="mt-4">
								<Checkbox name="calledBack" title="case.calledBack" innerRef={register} defaultChecked={caseData.visitation.calledBack.value} />
								{caseData.visitation.calledBack.timestamp !== null && caseData.visitation.calledBack.value && (
									<p className="text-blue text-sm font-medium">{dateToDateTimeString(caseData.visitation.calledBack.timestamp)}</p>
								)}
								<FormFieldHeader htmlFor="calledBackDescription" title="case.hyphenDescription" />
								<div className="flex">
									<Popover
										placement="top"
										interactive
										clickTrigger
										hideOnClick
										visible={tippy}
										onHide={() => setTippy(undefined)}
										content={
											<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2">
												{visitationAwaitTexts.map(temp => (
													<p
														key={temp}
														className="cursor-pointer"
														onClick={() => {
															const singleValue = getValues('calledBackDescription');
															setValue('calledBackDescription', singleValue === '' ? temp : `${singleValue}, ${temp}`);
															setTippy(false);
														}}
													>
														"{temp}"
													</p>
												))}
											</div>
										}
									>
										<div>
											<TextButton text="case.insertFromTemplate" icon={faQuoteRight} />
										</div>
									</Popover>
								</div>
								<Textarea title="" name="calledBackDescription" rows={3} innerRef={register} defaultValue={caseData.visitation.calledBack.comment ?? ''} className="mt-2" />
							</div>

							<div className="mt-4">
								<Checkbox name="awaiting" title="common.awaiting" innerRef={register} defaultChecked={caseData.visitation.awaiting.value} />
								{caseData.visitation.awaiting.timestamp !== null && <p className="text-blue text-sm font-medium">{dateToDateTimeString(caseData.visitation.awaiting.timestamp)}</p>}
								<FormFieldHeader htmlFor="awaitingDescription" title="case.hyphenDescription" />
								<div className="flex">
									<Popover
										placement="top"
										interactive
										clickTrigger
										hideOnClick
										visible={tippy}
										onHide={() => setTippy(undefined)}
										content={
											<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2">
												{visitationCallBackTexts.map(temp => (
													<p
														key={temp}
														className="cursor-pointer"
														onClick={() => {
															const singleValue = getValues('awaitingDescription');
															setValue('awaitingDescription', singleValue === '' ? temp : `${singleValue}, ${temp}`);
															setTippy(false);
														}}
													>
														"{temp}"
													</p>
												))}
											</div>
										}
									>
										<div>
											<TextButton text="case.insertFromTemplate" icon={faQuoteRight} />
										</div>
									</Popover>
								</div>
								<Textarea title="" name="awaitingDescription" rows={3} innerRef={register} defaultValue={caseData.visitation.awaiting.comment ?? ''} className="mt-2" />
							</div>

							<div className="mt-3">
								<Button submit success text="case.editVisitation" loading={updateCaseSubmitting} />
							</div>
						</div>
						<div
							className={classNames('w-1/2 pl-2', {
								hidden: !questions?.causeQuestions.length,
							})}
						>
							{!loading && typeof questions !== 'undefined' && (
								<div>
									<FormFieldHeader title="case.visitationQuestions" />
									{questionsArray.map((q, i) => {
										return (
											<div key={q.id}>
												<Input name={`answers[${i}].question`} className="hidden" innerRef={register} value={q.id} readOnly />
												<Input name={`answers[${i}].answer`} title={q.question} innerRef={register} defaultValue={q.answer} />
											</div>
										);
									})}
								</div>
							)}
						</div>
					</form>
				}
			/>
		</>
	);
};

export default VisitationBox;
