import React from 'react';
import { loader } from 'graphql.macro';
import { FavoriteCase, FavoriteCaseVariables } from '../GraphQL';
import { useMutation } from '@apollo/client';
import { PersonalFilter } from '../Pages/CaseOverview/CaseOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as starFilled } from '@fortawesome/pro-solid-svg-icons';
import useOnClickOutside from '@ssg/common/Hooks/useOnClickOutside';
import classNames from 'classnames';

const FAVORITE_CASE = loader('../GraphQL/Cases/FavoriteCase.gql');

interface Props {
	caseId: string;
	userFavoriteColor?: string;
	wrapperClass?: string;
	activeFilters?: PersonalFilter;
	disabledClick?: boolean;
}

export const favoriteColors = [
	{ value: '#FF6900' }, // Orange
	{ value: '#FCB900' }, // Yellow
	{ value: '#00D084' }, // Green
	{ value: '#0693E3' }, // Blue
	{ value: '#ABB8C3' }, // Gray
	{ value: '#EB144C' }, // Red
	{ value: '#9900EF' }, // Purple
	{ value: '#555555' }, // Black
];

const FavoriteToggle: React.FC<Props> = ({ caseId, userFavoriteColor = '', wrapperClass, disabledClick = false }): React.ReactElement => {
	const [showColorPicker, setShowColorPicker] = React.useState(false);
	const favoriteRef = React.useRef(null);

	const [favoriteCase] = useMutation<FavoriteCase, FavoriteCaseVariables>(FAVORITE_CASE);

	useOnClickOutside(favoriteRef, () => setShowColorPicker(false));

	const handleFavoriteClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		setShowColorPicker(!showColorPicker);
	};

	const createFavoriteCase = async ({ color, active }: Omit<FavoriteCaseVariables, 'id'>): Promise<void> => {
		if (color === '' && active) {
			return;
		}

		await favoriteCase({
			variables: {
				id: caseId,
				color,
				active,
			},
		});

		setShowColorPicker(false);
	};

	return (
		<div className={classNames('relative', wrapperClass)}>
			<FontAwesomeIcon
				icon={userFavoriteColor !== '' ? starFilled : faStar}
				onClick={e => {
					e.stopPropagation();
					if(!disabledClick) {
						handleFavoriteClick(e);
					}
				}}
				style={{
					fontSize: '18px',
					color: userFavoriteColor !== '' ? userFavoriteColor : 'inherit',
				}}
				className={classNames({
					'cursor-pointer': !disabledClick,
					'cursor-default': disabledClick,
				})}
			/>

			{showColorPicker && (
				<div ref={favoriteRef} className="absolute z-10" style={{ top: '-4px', left: '-5px' }}>
					<div className="rounded-default border-default flex items-center justify-center border-gray-100 bg-white p-1">
						<FontAwesomeIcon
							icon={faStar}
							className="hover-scale cursor-pointer"
							style={{ fontSize: '18px' }}
							onClick={e => {
								e.stopPropagation();
								createFavoriteCase({
									color: '',
									active: false,
								});
							}}
						/>

						<div className="border-r-default mx-1 block h-full w-full text-gray-100">&nbsp;</div>

						{favoriteColors.map(f => (
							<FontAwesomeIcon
								key={f.value}
								icon={starFilled}
								className="hover-scale mx-1 cursor-pointer"
								style={{ fontSize: '18px', color: f.value }}
								onClick={e => {
									e.stopPropagation();
									createFavoriteCase({
										color: f.value,
										active: true,
									});
								}}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
export default FavoriteToggle;
