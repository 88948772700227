import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTimestamp, formatTimestampOnly } from '@ssg/common/Helpers/Helpers';
import { CasePriority, GetWebCaseAdminData, GetCasesForExport, GetCasesForExportVariables, Permissions, GetCasesForExport_cases, UserCaseFilterViewType } from '../../GraphQL';
import { CSVLink } from 'react-csv';
import { faFileCsv, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { PersonalFilter } from './CaseOverview';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import UserContext from '../../UserContext';
import TextButton from '@ssg/common/Components/TextButton';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';

const GET_CASES = loader('src/GraphQL/Cases/GetCasesForExport.gql');

interface Props {
	searchTerm: string;
	activeFilters: PersonalFilter;
	showEconomy: boolean;
	showBusinessArea: boolean;
	showCategoryCause: boolean;
	adminData: GetWebCaseAdminData | undefined;
}

interface ExportProps extends Props {
	setDownload(): unknown;
}

const CasesExportFunc: React.FC<ExportProps> = ({ searchTerm, activeFilters, showEconomy, showBusinessArea, showCategoryCause, adminData, setDownload }): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const [csvData, setCsvData] = React.useState<CSV[] | undefined>(undefined);
	const csvRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

	const hasEconomyPermission = arraysHasMatchingValue([Permissions.CASES_ECONOMY_VIEW], userPermissions);

	const { data } = useQuery<GetCasesForExport, GetCasesForExportVariables>(GET_CASES, {
		fetchPolicy: 'no-cache',
		variables: {
			searchString: searchTerm.length > 0 ? searchTerm : null,
			address: activeFilters.address,
			awaiting: activeFilters.awaiting,
			damageServiceCompleted: activeFilters.damageServiceCompleted,
			calledBack: activeFilters.calledBack,
			machinesOnCase: activeFilters.machinesOnCase,
			ownCases: activeFilters.view === UserCaseFilterViewType.OWN,
			favoriteCases: activeFilters.view === UserCaseFilterViewType.FAVORITES,
			appliedClosed: activeFilters.showAppliedClosedCases,
			closedCases: activeFilters.showClosedCases,
			debitor: activeFilters.debitors,
			postalCode: activeFilters.postalCodes,
			track: activeFilters.track,
			department: activeFilters.departments,
			location: activeFilters.locations,
			damageCategory: activeFilters.damageCategories,
			damageCause: activeFilters.damageCauses,
			priority: activeFilters.priority,
			caseManager: activeFilters.caseManagers,
			projectManager: activeFilters.projectManagers,
			minDate: activeFilters.minDate ? formatDateForInput(new Date(activeFilters.minDate ?? '')) : undefined,
			maxDate: activeFilters.maxDate ? formatDateForInput(new Date(activeFilters.maxDate ?? '')) : undefined,
			includeEconomy: activeFilters.showEconomyField,
			favoriteColors: activeFilters.favoriteColors,
		},
		context: { debatch: true },
	});

	console.log('Export data', data);

	const csvheader = [
		{ label: t('case.caseNo'), key: 'caseNo' },
		{ label: t('common.debitor'), key: 'debitor' },
		{ label: t('caseOverview.policenr'), key: 'policenr' },
		{ label: t('common.address'), key: 'address' },
		{ label: t('common.postalcode'), key: 'postalCode' },
		{ label: t('common.city'), key: 'city' },
		{ label: t('case.damageCause'), key: 'damageCause' },
		{ label: t('common.damageCategory'), key: 'damageCategory' },
		{ label: t('case.track'), key: 'track' },
		{ label: t('common.priority'), key: 'priority' },
		{ label: t('common.department'), key: 'department' },
		{ label: t('common.location'), key: 'location' },
		{ label: t('case.ssgCaseManager'), key: 'caseManager' },
		{ label: t('case.ssgProjectManager'), key: 'ssgProjectManager' },
		{ label: t('common.created'), key: 'created' },
		{ label: t('common.arrived'), key: 'arrived' },
		{ label: t('case.economy.sale'), key: 'sale' },
		{ label: t('case.economy.invoiced'), key: 'invoiced' },
		{ label: t('common.area'), key: 'businessArea' },

		{ label: t('common.awaiting'), key: 'awaiting' },
		{ label: t('caseOverview.callback'), key: 'callback' },
		{ label: t('caseOverview.callbackComments'), key: 'callbackComments' },
		{ label: t('caseOverview.requisitions'), key: 'requisitions' },
		{ label: t('caseOverview.reports'), key: 'reports' },
		{ label: t('caseOverview.isMoistureReportRequired'), key: 'isMoistureReportRequired' },
		{ label: t('caseOverview.isSpPhaseOneReportCreated'), key: 'isSpPhaseOneReportCreated' },
		{ label: t('caseOverview.activeFilters'), key: 'filters' },
	];

	interface CSV {
		caseNo: string;
		debitor: string;
		policenr: string;
		address: string;
		postalCode: string;
		city: string;
		damageCause: string | null;
		damageCategory: string | null;
		track: number;
		priority: string;
		department: string;
		location: string;
		caseManager: string;
		ssgProjectManager: string;
		created: string;
		arrived: string;
		sale: string | null;
		invoiced: string | null;
		businessArea: string | null;
		filters: string;
		awaiting: string | null;
		callback: string | null;
		callbackComments: string | null;
		requisitions: string | null;
		reports: string | null;
		isMoistureReportRequired: string | null;
		isSpPhaseOneReportCreated: string | null;
	}

	const translatePriorities = React.useCallback(
		(priorities: CasePriority[]): string => {
			const priority: string[] = [];
			priorities.forEach(p => {
				switch (p) {
					case CasePriority.LOSS_OF_BUSINESS_EARNING_CAPACITY:
						return priority.push(t(`case.${p}`));
					case CasePriority.RESIDENTIAL_AREA:
						return priority.push(t(`case.${p}`));
					case CasePriority.SOCIAL_IMPACT:
						return priority.push(t(`case.${p}`));
					default:
						return;
				}
			});
			return priority.join(', ');
		},
		[t],
	);

	const filterData = React.useCallback((): string => {
		let filterFacory = '';

		const translateView = (tableView: string): string => {
			switch (tableView) {
				case 'OWN':
					return t('common.ownCases');
				case 'ALL':
					return t('caseOverview.filters.allCases');
				case 'INVOICE_READY':
					return t('caseOverview.filters.readyFor5C');
				case 'NOT_PLANNED':
					return t('common.notPlanned');
				case 'FAVORITES':
					return t('caseOverview.filters.favorites');
				default:
					return '';
			}
		};

		const locations: string[] = [];
		activeFilters.locations?.forEach(l => {
			adminData?.locations.forEach(location => {
				if (l === location.id) {
					locations.push(location.name);
				}
			});
		});

		const departments: string[] = [];
		activeFilters.departments?.forEach(d => {
			adminData?.departments.forEach(department => {
				if (d === department.id) {
					departments.push(department.name);
				}
			});
		});

		const categories: string[] = [];
		activeFilters.damageCategories?.forEach(c => {
			adminData?.damageCategories.forEach(category => {
				if (c === category.id) {
					categories.push(category.name);
				}
			});
		});

		const causes: string[] = [];
		activeFilters.damageCauses?.forEach(c => {
			adminData?.damageCauses.forEach(cause => {
				if (c === cause.id) {
					causes.push(cause.name);
				}
			});
		});

		filterFacory = translateView(activeFilters.view);
		filterFacory += activeFilters.minDate && activeFilters.maxDate ? ' | Dato: ' + formatDate(new Date(activeFilters.minDate)) + ' - ' + formatDate(new Date(activeFilters.maxDate)) : '';
		filterFacory += activeFilters.awaiting ? ' | ' + t('common.awaiting') : '';
		filterFacory += activeFilters.damageServiceCompleted ? ' | ' + t('caseOverview.filters.damageServiceCompleted') : '';
		filterFacory += activeFilters.calledBack ? ' | ' + t('case.calledBack') : '';
		filterFacory += activeFilters.machinesOnCase ? ' | ' + t('caseOverview.filters.machinesOnCase') : '';
		filterFacory += activeFilters.showAppliedClosedCases ? ' | ' + t('caseOverview.filters.showAppliedClosedCases') : '';
		filterFacory += activeFilters.showClosedCases ? ' | ' + t('caseOverview.filters.showBlockedCases') : '';
		filterFacory += activeFilters.debitors?.length ? ' | ' + t('common.debitor') + ': ' + activeFilters.debitors : '';
		filterFacory += activeFilters.postalCodes?.length ? ' | ' + t('common.postalcode') + ': ' + activeFilters.postalCodes : '';
		filterFacory += activeFilters.damageCategories?.length ? ' | ' + t('common.damageCategory') + ': ' + categories : '';
		filterFacory += activeFilters.damageCauses?.length ? ' | ' + t('case.damageCause') + ': ' + causes : '';
		filterFacory += activeFilters.track?.length ? ' | ' + t('case.track') + ': ' + activeFilters.track : '';
		filterFacory += activeFilters.priority?.length ? ' | ' + t('common.priority') + ': ' + translatePriorities(activeFilters.priority) : '';
		filterFacory += activeFilters.departments?.length ? ' | ' + t('common.department') + ': ' + departments : '';
		filterFacory += activeFilters.locations?.length ? ' | ' + t('common.location') + ': ' + locations : '';
		filterFacory += activeFilters.caseManagers?.length ? ' | ' + t('case.ssgCaseManager') + ': ' + activeFilters.caseManagers : '';
		filterFacory += activeFilters.projectManagers?.length ? ' | ' + t('case.ssgProjectManager') + ': ' + activeFilters.projectManagers : '';
		filterFacory += activeFilters.showCategoryCause ? ' | ' + t('caseOverview.filters.showCategoryCause') : '';
		filterFacory += hasEconomyPermission && activeFilters.showEconomyField ? ' | ' + t('caseOverview.filters.showEconomyField') : '';
		filterFacory += activeFilters.showBusinessAreaField ? ' | ' + t('caseOverview.filters.showBusinessAreaField') : '';
		filterFacory += activeFilters.filterTerms ? ' | filterTerms: ' + activeFilters.filterTerms : '';

		return filterFacory;
	}, [
		activeFilters.awaiting,
		activeFilters.calledBack,
		activeFilters.caseManagers,
		activeFilters.damageCategories,
		activeFilters.damageCauses,
		activeFilters.damageServiceCompleted,
		activeFilters.debitors,
		activeFilters.departments,
		activeFilters.filterTerms,
		activeFilters.locations,
		activeFilters.machinesOnCase,
		activeFilters.maxDate,
		activeFilters.minDate,
		activeFilters.postalCodes,
		activeFilters.priority,
		activeFilters.projectManagers,
		activeFilters.showAppliedClosedCases,
		activeFilters.showBusinessAreaField,
		activeFilters.showCategoryCause,
		activeFilters.showClosedCases,
		activeFilters.showEconomyField,
		activeFilters.track,
		activeFilters.view,
		adminData?.damageCategories,
		adminData?.damageCauses,
		adminData?.departments,
		adminData?.locations,
		hasEconomyPermission,
		t,
		translatePriorities,
	]);

	const filteredCsvHeader = csvheader
		.filter(l => {
			return hasEconomyPermission && showEconomy ? true : l.key !== 'economy';
		})
		.filter(l => {
			return showBusinessArea ? true : l.key !== 'businessArea';
		})
		.filter(l => {
			return showCategoryCause ? true : l.key !== 'damageCause';
		})
		.filter(l => {
			return showCategoryCause ? true : l.key !== 'damageCategory';
		});

	React.useEffect(() => {
		if (typeof data !== 'undefined') {
			const tempCSVs: CSV[] = data.cases.map((d, i) => {
				let generatedReports = '';

				// Implementing the logic of generatedReportName directly here
				if (d.caseReports?.indoorClimateReportFormData) {
					generatedReports += '|' + t('reports.reportTypes.indoorClimate');
				}
				if (d.caseReports?.inspectionReportFormData) {
					generatedReports += '|' + t('reports.reportTypes.inspection');
				}
				if (d.caseReports?.moistureReportFormData) {
					generatedReports += '|' + t('reports.reportTypes.water');
				}
				if (d.caseReports?.moistureTrygReportFormData) {
					generatedReports += '|' + t('reports.reportTypes.trygWater');
				}
				const yes = t('case.yes');
				const no = t('case.no');

				return {
					caseNo: d.erpNo,
					debitor: d.debitor.company,
					policenr: d.debitor.policeNumber,
					address: d.damage.contact.address.road + ' ' + d.damage.contact.address.houseNumber + ' ' + (d.damage.contact.address.floor ?? ''),
					postalCode: d.damage.contact.address.postalCode,
					city: d.damage.contact.address.city,
					damageCause: showCategoryCause ? d.damage.cause.name : null,
					damageCategory: showCategoryCause ? d.damage.category.name : null,
					track: d.track,
					priority: translatePriorities(d.visitation.priorities),
					department: `${d.ssgDepartment.name} (${d.ssgDepartment.departmentNumber})`,
					location: d.ssgLocation.name,
					caseManager: d.caseManager?.name ?? '',
					ssgProjectManager: d.projectManager?.name ?? '',
					created: formatTimestamp(new Date(d.createdAt)),
					arrived: d.arrived && d.arrivedDate !== null ? `${formatDate(new Date(d.arrivedDate))} - ${formatTimestampOnly(new Date(d.arrivedDate))}` : '',
					sale: hasEconomyPermission && showEconomy ? addThousandSeperator(d.caseEconomics?.salesTotal ?? 0) : null,
					invoiced: hasEconomyPermission && showEconomy ? addThousandSeperator(d.caseEconomics?.invoicedTotal ?? 0) : null,
					businessArea: showBusinessArea ? d.damage.businessArea.name : null,
					awaiting: d.visitation.awaiting.value ? yes : no,
					callback: d.visitation.calledBack.value ? yes : no,
					callbackComments: d.visitation.calledBack.value ? d.visitation.calledBack.comment : '',
					requisitions: d.activeRequisitions ? yes : no,
					reports: generatedReports,
					isMoistureReportRequired: d.visitation.isMoistureReportRequired !== null ? d.visitation.isMoistureReportRequired ? yes : no : '#',
					isSpPhaseOneReportCreated: d.visitation.isSpPhaseOneReportCreated !== null ? d.visitation.isSpPhaseOneReportCreated ? yes : no : '#',
					filters: i === 0 ? filterData() : '',




				};
			});
			setCsvData(tempCSVs);
		}
	}, [activeFilters, data, filterData, hasEconomyPermission, showBusinessArea, showCategoryCause, showEconomy, translatePriorities]);

	React.useLayoutEffect(() => {
		if (typeof csvData !== 'undefined') {
			window.setTimeout(() => {
				csvRef.current?.link.click();
				setDownload();
			});
		}
	}, [csvData, setDownload]);

	return (
		<>
			<TextButton link icon={faSpinner} text="common.exportForExcel" iconClassName="animate-spin" disabled />
			<div className="hidden">
				<CSVLink
					data={csvData ?? []}
					headers={filteredCsvHeader}
					separator={';'}
					ref={csvRef}
					filename={`${t('common.caseOverview')} - ${formatDate(new Date())}.csv`}
					target="_blank"
				/>
			</div>
		</>
	);
};

const CasesExport: React.FC<Props> = ({ searchTerm, activeFilters, showEconomy, showBusinessArea, showCategoryCause, adminData }): React.ReactElement => {
	const [download, setDownload] = React.useState(false);

	return (
		<>
			{download ? (
				<CasesExportFunc
					searchTerm={searchTerm}
					activeFilters={activeFilters}
					showEconomy={showEconomy}
					showBusinessArea={showBusinessArea}
					showCategoryCause={showCategoryCause}
					adminData={adminData}
					setDownload={() => setDownload(false)}
				/>
			) : (
				<TextButton link icon={faFileCsv} text="common.exportForExcel" onClick={() => setDownload(true)} />
			)}
		</>
	);
};



export default CasesExport;


