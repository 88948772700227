import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string;
	required?: boolean;
}

const FormFieldHeader: React.FC<Props> = ({ title, required = false }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<div className="flex-grow-default text-blue mt-2 mb-1 text-base font-semibold">
			{t(title)}
			{required && <span className="text-orange">*</span>}
		</div>
	);
};

export default FormFieldHeader;
