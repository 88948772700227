import React from 'react';
import arraysHasMatchingValue from '../Helpers/arraysHasMatchingValue';

export default function useArrayToggler<T>(defaultValue: T | T[]): [T[], (v: T) => void, (a: T[]) => void, () => void] {
	const [valueArray, setValueArray] = React.useState<T[]>(Array.isArray(defaultValue) ? defaultValue : [defaultValue]);

	const setValueToggler = (v: T) => {
		if (valueArray.includes(v)) {
			setValueArray(valueArray.filter(r => r !== v));
		} else {
			setValueArray([...valueArray, v]);
		}
	};

	const toggleAll = (a: T[]) => {
		const arraysAreTheSame = valueArray.length > 0 && valueArray.filter(v => a.includes(v)).length === a.length;
		if (arraysAreTheSame) {
			setValueArray(valueArray.filter(v => !a.includes(v)));
		} else {
			setValueArray(Array.from(new Set([...valueArray, ...a])));
		}
	};

	const clear = () => {
		setValueArray([]);
	};

	React.useEffect(() => {
		//console.log(valueArray.length);
	}, [valueArray.length]);

	return [valueArray, setValueToggler, toggleAll, clear];
}

