import React from 'react';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import { faEdit, faQuoteRight } from '@fortawesome/pro-regular-svg-icons';
import { useForm, useWatch } from 'react-hook-form';
import { IDamageRequisitioner } from '../../Schemas/ICaseCreation';
import { yupResolver } from '@hookform/resolvers/yup';
import { CaseViewDamageRequisitionerSchema } from '../../Schemas/CaseViewDamageRequisitionerSchema';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import TextButton from '@ssg/common/Components/TextButton';
import { loader } from 'graphql.macro';
import { CollectionItemType, GetSingleCase_case, GetCollectionItems, GetDamageAdminData, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { useMutation, useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Textarea from '@ssg/common/Components/Textarea';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Checkbox from '@ssg/common/Components/Checkbox';
import Popover from '@ssg/common/Components/Popover';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { removeTypename } from '../../helper';

const GET_DAMAGE_ADMIN_DATA = loader('../../GraphQL/Cases/GetDamageAdminData.gql');
const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const DamageBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const [businessArea, setBusinessArea] = React.useState(caseData.damage.businessArea.name);
	const [editActive, setEditActive] = React.useState(false);

	const { data, loading } = useQuery<GetDamageAdminData>(GET_DAMAGE_ADMIN_DATA, { skip: !editActive });

	const { data: collectionItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		skip: !editActive,
	});

	const relationValues = React.useMemo(
		() =>
			collectionItems?.collectionItems
				.filter(f => f.type === CollectionItemType.DAMAGE_BOX_REQUESTER_RELATIONSHIP)
				.map(
					(item): SelectOption => ({
						label: item.name,
						value: item.name,
					}),
				) ?? [],
		[collectionItems],
	);

	const descriptionValues = React.useMemo(() => collectionItems?.collectionItems.filter(f => f.type === CollectionItemType.DAMAGE_BOX_DESCRIPTION).map(item => item.name) ?? [], [collectionItems]);

	const [tippy, setTippy] = React.useState<boolean | undefined>(undefined);

	const [requisitionerPhonenumber, setRequisitionerPhonenumber] = React.useState(formatPhoneNumberIntl(caseData.requisitioner.phone ?? '').replaceAll(/(\s|-)+/g, ''));

	const [requisitionercountry, setRequisitionerCountry] = React.useState<string | undefined>('');

	const requisitionerPhonenumberHandler = (phonenumber: string): void => {
		phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
		setValue('requisitionerPhone', phonenumber, { shouldValidate: true });
		setRequisitionerPhonenumber(phonenumber);
	};

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, errors, register, control, setValue, trigger, getValues } = useForm<IDamageRequisitioner>({
		resolver: yupResolver(CaseViewDamageRequisitionerSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			requisitionerPhone: caseData.requisitioner.phone ?? '',
		},
	});

	const damageLocation = useWatch({
		control,
		name: 'damageLocation',
		defaultValue: caseData?.damage.category.id ?? null,
	});

	const noEmail = useWatch({
		control,
		name: 'requisitionerNoEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.requisitioner.email === '',
	});

	React.useEffect(() => {
		if (noEmail) {
			setValue('requisitionerEmail', '', { shouldValidate: true });
		}
	}, [noEmail, setValue]);

	const filteredLocations: SelectOption[] | undefined = React.useMemo(() => {
		return data?.damageCategories.map(c => ({
			value: c.id,
			label: c.name,
		}));
	}, [data]);

	const filteredCauses: SelectOption[] | undefined = React.useMemo(() => {
		return data?.damageCauses.filter(a => a.category.id === damageLocation).map(c => ({ value: c.id, label: c.name })) ?? [];
	}, [data, damageLocation]);

	const getBusinessArea = (causeId: string) => {
		const cause = data?.damageCauses.find(item => item.id === causeId);

		return cause?.businessArea.id ?? '';
	};

	React.useEffect(() => {
		setValue('damageDescription', caseData.damage.description);
	}, [caseData, setValue]);

	const onSubmit = async (data: IDamageRequisitioner) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				damage: {
					category: data.damageLocation,
					cause: data.damageCause,
					contact: {
						address: {
							road: caseData.damage.contact.address.road,
							houseNumber: caseData.damage.contact.address.houseNumber,
							floor: caseData.damage.contact.address.floor,
							addressLineAlt: caseData.damage.contact.address.addressLineAlt,
							city: caseData.damage.contact.address.city,
							postalCode: caseData.damage.contact.address.postalCode,
							country: caseData.damage.contact.address.country,
						},
						email: caseData.damage.contact.email,
						name: caseData.damage.contact.name,
						phone: caseData.damage.contact.phone,
					},
					date: formatDateForInput(data.damageDate),
					description: data.damageDescription,
					businessArea: getBusinessArea(data.damageCause),
					contacts: removeTypename(caseData.damage.contacts),
				},
				requisitioner: {
					email: data.requisitionerNoEmail ? '' : data.requisitionerEmail,
					name: data.requisitionerName,
					relation: data.requisitionerRelation,
					phone: data.requisitionerPhone,
				},
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, damage, requisitioner }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id,
				damage,
				requisitioner,
			},
		});
	};
	const damage: SelectOption[] = [
		{ value: caseData.damage.businessArea.name, label: 'common.area' },
		{
			value: caseData.damage.category.name,
			label: 'common.damageCategory',
		},
		{ value: caseData.damage.cause.name, label: 'case.damageCause' },
		{
			value: dateToDateTimeString(caseData.damage.date, true),
			label: 'case.damageDate',
		},
		{ value: caseData.damage.description, label: 'case.damageDescription' },
		{ value: caseData.requisitioner.name, label: 'case.requisitioner' },
		{
			value: caseData.requisitioner.phone ?? '',
			label: 'case.requisitionerPhone',
		},
		{
			value: caseData.requisitioner.email ?? '',
			label: 'case.requisitionerEmail',
		},
		{
			value: caseData.requisitioner.relation,
			label: 'case.requisitionerRelation',
		},
	];

	return (
		<>
			<Box title="case.damage" full className="mt-8" icon={caseViewExternal ? undefined : faEdit} onClick={() => setEditActive(true)}>
				<hr />
				<BoxFormDataViewer data={damage} keyId="case.ssgInformation" />
			</Box>
			<Modal
				title="common.editInjuredParty"
				close={() => setEditActive(false)}
				visible={editActive && !loading}
				size={ModalSize.SMALL}
				body={
					<div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormFieldHeader title="common.area" />
							<p className="text-blue">{businessArea}</p>

							<Dropdown
								title="common.damageCategory"
								required
								name="damageLocation"
								data={filteredLocations ?? []}
								innerRef={register}
								errorMessage={errors.damageLocation?.message ?? ''}
								defaultValue={caseData.damage.category.id}
							/>

							<Dropdown
								title="case.damageCause"
								required
								name="damageCause"
								errorMessage={errors.damageCause?.message ?? ''}
								data={filteredCauses}
								innerRef={register}
								defaultValue={caseData.damage.cause.id}
								onChange={e => setBusinessArea(data?.damageCauses.find(item => item.id === e.currentTarget.value)?.businessArea.name ?? '')}
							/>

							<Datepicker
								title="case.damageDate"
								name="damageDate"
								required
								innerRef={register}
								errorMessage={errors.damageDate?.message ?? ''}
								defaultValue={formatDateForInput(new Date(caseData.damage.date))}
							/>

							<FormFieldHeader htmlFor="damageDescription" title="case.damageDescription" />
							<div className="flex">
								<Popover
									placement="top"
									interactive
									clickTrigger
									hideOnClick
									visible={tippy}
									onHide={() => setTippy(undefined)}
									content={
										<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2">
											{descriptionValues.map(temp => (
												<p
													key={temp}
													className="cursor-pointer"
													onClick={() => {
														const singleValue = getValues('damageDescription');
														setValue('damageDescription', singleValue === '' ? temp : `${singleValue}, ${temp}`);
														setTippy(false);
													}}
												>
													"{temp}"
												</p>
											))}
										</div>
									}
								>
									<div>
										<TextButton text="case.insertFromTemplate" icon={faQuoteRight} />
									</div>
								</Popover>
							</div>
							<Textarea title="" name="damageDescription" rows={3} innerRef={register} defaultValue={caseData.damage.description ?? ''} className="mt-2 w-full lg:w-full" />

							<Input
								title="case.requisitionerName"
								required
								innerRef={register}
								name="requisitionerName"
								errorMessage={errors.requisitionerName?.message ?? ''}
								defaultValue={caseData.requisitioner.name}
							/>

							<InputPhone
								title="case.requisitionerPhone"
								required
								innerRef={register}
								name="requisitionerPhone"
								errorMessage={errors.requisitionerPhone?.message ?? ''}
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => requisitionerPhonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setRequisitionerCountry(countrycode ?? '')}
								defaultCountry={requisitionercountry}
								defaultPhoneNumber={requisitionerPhonenumber}
								className="w-full md:w-full lg:w-full"
							/>

							<Input
								title="case.requisitionerEmail"
								innerRef={register}
								name="requisitionerEmail"
								errorMessage={errors.requisitionerEmail?.message ?? ''}
								defaultValue={caseData.requisitioner.email}
								disabled={noEmail}
							/>

							<Checkbox
								title="case.noEmail"
								name="requisitionerNoEmail"
								className="mt-1"
								innerRef={register}
								onChange={() => {
									trigger('requisitionerEmail');
								}}
								defaultChecked={typeof caseData !== 'undefined' && caseData.requisitioner.email === ''}
							/>

							<Dropdown
								title="case.requisitionerRelation"
								name="requisitionerRelation"
								errorMessage={errors.requisitionerRelation?.message ?? ''}
								data={relationValues}
								innerRef={register}
								defaultValue={relationValues.find(r => r.label === caseData.requisitioner.relation)?.value}
							/>

							<div className="mt-3">
								<Button submit success text="common.editInjuredParty" loading={updateCaseSubmitting} />
							</div>
						</form>
					</div>
				}
			/>
		</>
	);
};

export default DamageBox;
