import React from 'react';
import { faSearch, faSlidersH, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { TrackingFilters } from './AllRegistrationsOverviewNew';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useSearchUsersQuery } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useForm } from 'react-hook-form';
import UserContext from '../../UserContext';
import { Permissions } from '@ssg/common/GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	activeFilters: TrackingFilters;
	setActiveFilters: React.Dispatch<React.SetStateAction<TrackingFilters>>;
	setFilterTerm: (v: string) => void;
}

const AllRegsOverviewFilter: React.FC<Props> = ({ activeFilters, setActiveFilters, setFilterTerm }) => {
	const { t } = useTranslation();

	const allRegsOverviewSearchWorker = useFlag(FeatureFlagEnums.ALL_REGS_OVERVEW_SEARCH_WORKER);

	const userContext = React.useContext(UserContext);
	const canPickWorker = userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER) ?? false;

	const dummyForm = useForm();

	const [showAllFilters, setShowAllFilters] = React.useState(false);
	const [searchUserText, setSearchUserText] = React.useState('');
	
	const onSelectUser = (value: string): void => {
		setActiveFilters(currentFilters => ({
			...currentFilters,
			user: value,
		}));
	};

	const { usersDataRaw, usersLoading } = useSearchUsersQuery(searchUserText, true, true);
	const searchedUsers = React.useMemo(() => usersDataRaw?.searchUsers.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [], [usersDataRaw]);

	return (
		<div className="my-2 mx-0">
			<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
				{(allRegsOverviewSearchWorker && canPickWorker) &&
					<div className="order-3 mb-3 mr-3 lg:order-2">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.employee')}</label>
						<SearchableSelect
							control={dummyForm.control}
							name="user"
							placeholder={t('common.searchEmployee')}
							options={searchedUsers.filter(u => u.label.toLowerCase().includes(searchUserText.toLowerCase()))}
							searchFn={searchText => setSearchUserText(searchText)}
							onSelect={value => onSelectUser(value)}
							required
							onBlur={() => undefined}
							minInputLength={-1}
							isLoading={usersLoading}
							className="text-sm"
							style={{ width: '220px' }}
						/>
					</div>
				}

				<div className="order-3 mb-3 mr-3 lg:order-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
					<div className="flex justify-between">
						<div className="relative flex">
							<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
							<Input
								name="overview-search"
								placeholder={t('timeRegistration.filters.filterSearchPlaceholder')}
								className="pr-8 text-sm"
								style={{ minWidth: '220px' }}
								onChange={(e): void => setFilterTerm(e.target.value)}
							/>
						</div>
						<Button
							primary
							text={!showAllFilters ? t('caseOverview.filters.showAllFilters') : t('common.hideFilters')}
							icon={faSlidersH}
							className="ml-3 inline-block px-5 py-2 text-xs lg:hidden xl:hidden"
							style={{ height: '36px' }}
							onClick={() => setShowAllFilters(!showAllFilters)}
						/>
					</div>
				</div>

				<div className="order-2 mb-3 mr-3 lg:order-4">
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.created')}</label>
					<div className="flex items-center">
						<input
							type="date"
							value={formatDateForInput(new Date(activeFilters.minDate))}
							onChange={e => {
								const minDate = e.target.valueAsDate;
								if (minDate === null || typeof minDate === 'undefined') {
									return;
								}

								const minDateString = dateToDateOnlyString(minDate);

								setActiveFilters(currentFilters => ({
									...currentFilters,
									minDate: minDateString,
								}));

								if (minDateString > activeFilters.maxDate) {
									const maxDate = new Date(minDate);
									maxDate.setHours(23, 59, 59, 999);
									const maxDateString = dateToDateOnlyString(maxDate);
									setActiveFilters(currentFilters => ({
										...currentFilters,
										maxDate: maxDateString,
									}));
								}
							}}
							className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
							style={{
								height: '34px',
								maxWidth: '144px',
								minWidth: '100px',
							}}
						/>
						<span className="px-1">&mdash;</span>
						<input
							type="date"
							min={formatDateForInput(new Date(activeFilters.minDate))}
							value={formatDateForInput(new Date(activeFilters.maxDate))}
							onChange={e => {
								const maxDate = e.target.valueAsDate;
								if (maxDate === null) {
									return;
								}
								maxDate.setHours(23, 59, 59, 999);

								const maxDateString = dateToDateOnlyString(maxDate);

								setActiveFilters(currentFilters => ({
									...currentFilters,
									maxDate: maxDateString,
								}));
							}}
							className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
							style={{
								height: '34px',
								maxWidth: '144px',
								minWidth: '100px',
							}}
						/>
						{typeof activeFilters.minDate !== 'undefined' && typeof activeFilters.maxDate !== 'undefined' && (
							<FontAwesomeIcon
								icon={faTimes}
								size="1x"
								className="ml-2 cursor-pointer"
								onClick={() =>
									setActiveFilters(currentFilters => ({
										...currentFilters,
										minDate: dateToDateOnlyString(new Date()),
										maxDate: dateToDateOnlyString(new Date()),
									}))
								}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllRegsOverviewFilter;
