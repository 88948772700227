import 'react-app-polyfill/stable';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { FlagProvider } from '@unleash/proxy-client-react';
import { MicrosoftAuthProvider } from '@ssg/common/Components/MicrosoftAuth';
import ApolloAuthenticationWrapper from '@ssg/common/Components/ApolloAuthenticationWrapper';
import { GraphQLExtensionsProvider } from '@ssg/common/Components/GraphQLExtensionsContext';
import EnvironmentVariableContext, { EnvironmentVariableContextProvider } from '@ssg/common/EnvironmentVariableContext';
import { UserContextProvider } from './UserContext';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import reportWebVitals from './reportWebVitals';
import App from './App';
import useDependencyConfiguration from '@ssg/common/Hooks/useDependencyConfiguration';

import './i18n';
import './index.css';

const AppWrapper: React.FC = (): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const [configured, { unleashConfig, telemetryPlugin, appInsights }] = useDependencyConfiguration({});

	if (!(configured && unleashConfig && telemetryPlugin && appInsights)) {
		return <></>;
	}

	return (
		<FlagProvider config={unleashConfig}>
			<MicrosoftAuthProvider
				msalConfig={{
					auth: {
						clientId: envVar.msClientId,
						authority: envVar.msAuthority,
						redirectUri: window.location.origin,
					},
				}}
				msalScopesApp={[envVar.msScopeApp]}
				msalScopesBC={[envVar.msScopeBc]}
				autoLogin={true}
			>
				<AppInsightsContext.Provider value={telemetryPlugin}>
					<GraphQLExtensionsProvider>
						<ApolloAuthenticationWrapper
							graphUrl={envVar.baseGraphUrl}
							onError={(errorMessage: string) => {
								appInsights.trackException({
									exception: new Error(errorMessage),
								});
							}}
						>
							<UserContextProvider>
								<App telemetryPlugin={telemetryPlugin} />
							</UserContextProvider>
						</ApolloAuthenticationWrapper>
					</GraphQLExtensionsProvider>
				</AppInsightsContext.Provider>
			</MicrosoftAuthProvider>
		</FlagProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<EnvironmentVariableContextProvider name="web">
			<AppWrapper />
		</EnvironmentVariableContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
