import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import Button from '@ssg/common/Components/Button';

interface Props {
	title?: string;
	actions?: React.ReactNode;
	textClassNames?: string;
	backButton?: boolean;
}

const Header: React.FC<Props> = ({ title, actions, textClassNames, backButton = false, children }): React.ReactElement => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<header className={classNames('bg-blue-xlight pl-18 mb-8 py-4', textClassNames)}>
			{title && (
				<div>
					{backButton && <Button text="common.back" icon={faArrowLeft} className="text-blue" onClick={() => history.goBack()} />}
					<div className="text-blue flex flex-wrap items-center pr-2 font-bold sm:justify-start md:justify-between lg:h-12 lg:flex-row">
						<h1 className="text-2xl md:mb-3">{t(title)}</h1>
						{actions && <div className="flex flex-row flex-wrap">{actions}</div>}
					</div>
				</div>
			)}
			{children}
		</header>
	);
};

export default Header;
