import React from 'react';
import { faCommentAltPlus } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCaseAgreementSchema } from '../../../Schemas/CreateCaseAgreementSchema';
import { useTranslation } from 'react-i18next';
import { CaseAgreementCategory, CaseAgreementInput, CreateCaseAgreement, CreateCaseAgreementVariables, GetSingleCase_case, Permissions } from '../../../GraphQL';
import Tabs from '@ssg/common/Components/Tabs';
import Box from '../../../Components/Layout/Box';
import CaseAgreementItem from './CaseAgreementItem';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Dropdown from '@ssg/common/Components/Dropdown';
import Textarea from '@ssg/common/Components/Textarea';
import Button from '@ssg/common/Components/Button';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const caseAgreementCategories = enumToSelectOptions(CaseAgreementCategory, 'case.');

const tabs: string[] = [
	'case.all',
	'case.' + CaseAgreementCategory.CAUSE,
	'case.' + CaseAgreementCategory.SCOPE,
	'case.' + CaseAgreementCategory.WORK_COMPLETED,
	'case.' + CaseAgreementCategory.FURTHER_ACTION,
	'case.' + CaseAgreementCategory.OTHER,
	'case.oldAgreements',
	'case.' + CaseAgreementCategory.PRIVATE,
];

const CREATE_CASE_AGREEMENT = loader('../../../GraphQL/Cases/CreateCaseAgreement.gql');

const CaseAgreementsBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const showPrivate = arraysHasMatchingValue(userContext.user?.permissions ?? [], [Permissions.CASES_AGREEMENTS_PRIVATE]);
	const editAllowed = arraysHasMatchingValue(userContext.user?.permissions ?? [], [Permissions.CASES_AGREEMENTS_EDIT]);
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);
	const [openModal, setOpenModal] = React.useState(false);

	const [oldAgreements, setOldAgreements] = React.useState('');

	// Sort Case Agreements in reverse date
	const sortedCaseAgreements = caseData.caseAgreements.slice().sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));

	const [createCaseAgreement, { loading: createCaseAgreementSubmitting }] = useMutation<CreateCaseAgreement, CreateCaseAgreementVariables>(CREATE_CASE_AGREEMENT);

	const { handleSubmit, register, errors } = useForm<CaseAgreementInput>({
		resolver: yupResolver(createCaseAgreementSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const submit = async (data: CaseAgreementInput) => {
		try {
			await postCaseAgreement({
				id: caseData.id,
				caseAgreement: {
					category: data.category as CaseAgreementCategory,
					content: data.content,
					shouldSaveInScalePoint: true,
				},
			});
			setOpenModal(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseAgreement = async ({ id, caseAgreement }: CreateCaseAgreementVariables) => {
		await createCaseAgreement({
			variables: {
				id: id,
				caseAgreement: caseAgreement,
			},
		});
	};

	const [filteredTabs, filteredCategories] = React.useMemo(
		() => [
			tabs.filter(t => (caseData.erpNo.startsWith('DK') ? t !== 'case.oldAgreements' : t)).filter(t => !t.endsWith(CaseAgreementCategory.PRIVATE) || showPrivate),
			caseAgreementCategories.filter(cac => cac.value !== CaseAgreementCategory.PRIVATE || showPrivate),
		],
		[caseData.erpNo, showPrivate],
	);

	const filteredCaseAgreements = React.useMemo(
		() => sortedCaseAgreements.filter(ca => 'case.' + ca.category === activeTab || (activeTab === filteredTabs[0] && ca.category !== CaseAgreementCategory.PRIVATE)),
		[activeTab, filteredTabs, sortedCaseAgreements],
	);

	// If old case, fetch agreements from old 5C
	React.useEffect(() => {
		if (caseData.erpNo.startsWith('DK')) {
			return;
		}

		const getOldAgreements = async () => {
			const url =
				'https://prod-80.westeurope.logic.azure.com:443/workflows/9f059a45bdb64b1ea8b7a861a3cf05d0/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=vTpAjy0OJ9nRZBLLfhz_10-68bwa1Z9_-VTwhCqCJB0';

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ erpNo: caseData.erpNo }),
			};

			const res = await fetch(url, requestOptions);
			const json = await res.text();

			if (res.ok) {
				setOldAgreements(json);
			}
		};

		getOldAgreements();
	}, [caseData.erpNo]);

	return (
		<>
			<Box title="case.caseAgreements" icon={editAllowed && !caseViewExternal ? faCommentAltPlus : undefined} full onClick={() => setOpenModal(editAllowed)} className="mb-8">
				<Tabs titles={filteredTabs} active={activeTab} onClick={setActiveTab} pushLast={showPrivate}>
					{filteredCaseAgreements.map((c, i) => (
						<CaseAgreementItem caseAgreement={c} key={c.category + c.timestamp + 'caseAgreement_' + i} />
					))}
					{activeTab === 'case.oldAgreements' && <div className="p-2 text-sm" dangerouslySetInnerHTML={{ __html: oldAgreements }} />}
					{sortedCaseAgreements.length === 0 && activeTab !== 'case.oldAgreements' && <div className="text-blue text-center font-medium">{t('case.caseAgreementNone')}</div>}
				</Tabs>
			</Box>
			<Modal
				close={() => setOpenModal(false)}
				title="case.caseAgreementCreate"
				visible={openModal}
				size={ModalSize.MEDIUM}
				body={
					<form onSubmit={handleSubmit(submit)}>
						<Dropdown required name="category" title="common.category" data={filteredCategories} defaultValue="OTHER" innerRef={register} errorMessage={errors.category?.message ?? ''} />

						<Textarea required name="content" title="common.message" rows={6} className="h-84 w-full lg:w-full" innerRef={register} errorMessage={errors.content?.message ?? ''} />

						<div className="mt-3">
							<Button submit success text="case.caseAgreementCreate" loading={createCaseAgreementSubmitting} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default CaseAgreementsBox;
