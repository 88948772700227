import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faArrowAltLeft, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { DebitorType } from '@ssg/common/GraphQL';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	GetSingleCase_case,
	GetWebDebitors,
	GetDebitorShipmentAddresses,
	GetDebitorShipmentAddressesVariables,
	GetWebDebitors_debitors,
	UpdateCase,
	UpdateCaseVariables,
	UpdateDebitor,
	UpdateDebitorVariables,
} from '../../GraphQL';
import { caseViewDebitorSchema } from '../../Schemas/CaseViewDebitorSchema';
import { IDebitorBox } from '../../Schemas/ICaseCreation';
import { IUpdateDebitor } from '../../Schemas/IUpdateDebitor';
import { UpdateDebitorSchema } from '../../Schemas/UpdateDebitorSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import { countries } from '@ssg/common/Helpers/countries';
import Dropdown from '@ssg/common/Components/Dropdown';
import classNames from 'classnames';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const GET_DEBITORS = loader('../../GraphQL/Debitors/GetWebDebitors.gql');
const UPDATE_DEBITOR = loader('../../GraphQL/Debitors/UpdateDebitor.gql');
const GET_DEBITOR_SHIPMENT_ADDRESSES = loader('../../GraphQL/Debitors/GetDebitorShipmentAddresses.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const DebitorBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();

	const [companySearchText, setCompanySearchText] = useDebouncedState(caseData.debitor.company, 100);
	const [selectedDebitor, setSelectedDebitor] = React.useState<GetWebDebitors_debitors | undefined>(undefined);
	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === caseData.debitor.billingAddress?.country)?.label || '');

	const [editActive, setEditActive] = React.useState(false);
	const [editDebitorData, setEditDebitorData] = React.useState(false);
	const [updateResponse, setUpdateResponse] = React.useState<string>('');

	const countrySelectHandler = (value: string): void => {
		setValue('insuranceInvoiceCountry', value, { shouldValidate: true });
	};

	const { handleSubmit, register, control, setValue, errors } = useForm<IDebitorBox>({
		resolver: yupResolver(caseViewDebitorSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			requisitionerInsuranceId: caseData.debitor.debitorId,
			insuranceInvoiceCountry: caseData.debitor.billingAddress?.country ?? '',
			shippingAddressReference: caseData.debitor.shippingAddress?.id ?? '',
		},
	});

	const {
		handleSubmit: updateSubmit,
		register: registerUpdate,
		errors: errorsUpdate,
	} = useForm<IUpdateDebitor>({
		resolver: yupResolver(UpdateDebitorSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			company: selectedDebitor?.company,
			address: selectedDebitor?.address?.addressLine,
			address2: selectedDebitor?.address?.addressLineAlt,
			zip: selectedDebitor?.address?.postalCode,
			city: selectedDebitor?.address?.city,
		},
	});

	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: {
			searchText: companySearchText,
		},
		skip: !editActive,
	});
	React.useEffect(() => {
		setSelectedDebitor(debitorsData?.debitors.find(d => d.debitorId === caseData.debitor.debitorId));
	}, [debitorsData?.debitors, caseData]);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);
	const [updateDebitor, { loading: updateDebitorSubmitting }] = useMutation<UpdateDebitor, UpdateDebitorVariables>(UPDATE_DEBITOR);

	const { data: addressesData, loading: loadingAddressesData } = useQuery<GetDebitorShipmentAddresses, GetDebitorShipmentAddressesVariables>(GET_DEBITOR_SHIPMENT_ADDRESSES, {
		variables: {
			id: selectedDebitor?.debitorId ?? '',
		},
		skip: typeof selectedDebitor === 'undefined',
	});

	const debitorShipmentAddresses = React.useMemo(
		() =>
			(addressesData?.debitorShipmentAddresses ?? []).map(
				(a): SelectOption => ({
					label: `${a.name} (${a.gln})`,
					value: a.id,
				}),
			),
		[addressesData?.debitorShipmentAddresses],
	);

	//No need to change debitor on case?
	const searchSelectHandler = (value: string): void => {
		setValue('requisitionerInsuranceId', value, { shouldValidate: true });
		setValue('shippingAddressReference', '');
		setSelectedDebitor(debitorsData?.debitors.find(d => d.debitorId === value));
	};

	const searchedDebitors = React.useMemo(() => {
		return debitorsData?.debitors.map(
			(d): SelectOption => ({
				value: d.debitorId,
				label: d.company + (d.debitorId !== null ? ` (${d.debitorId})` : ''),
			}),
		);
	}, [debitorsData]);

	//Maps debitor data
	const debitorBillingAddress: SelectOption[] = [
		{
			value: caseData?.debitor.attention ?? '',
			label: 'case.debitorAlternateAddress',
		},
		{
			value: caseData?.debitor.billingAddress?.road + ' ' + caseData?.debitor.billingAddress?.houseNumber + ' ' + (caseData.debitor.billingAddress?.floor ?? '') ?? '',
			label: '',
		},
		{
			value: caseData?.debitor.billingAddress?.addressLineAlt ?? '',
			label: '',
		},
		{
			value: (caseData?.debitor.billingAddress?.postalCode ?? '') + ' ' + (caseData?.debitor.billingAddress?.city ?? ''),
			label: '',
		},
		{
			value: countries.find(c => c.value === caseData.debitor.billingAddress?.country)?.label ?? '',
			label: '',
		},
	];

	//Maps debitor data
	const debitorShippingAddress: SelectOption[] = [
		{
			value: caseData.debitor.shippingAddress?.gln ?? '',
			label: 'case.debitorShipmentAddress',
		},
		{
			value: caseData.debitor.shippingAddress?.address.addressLine ?? '',
			label: '',
		},
		{
			value: caseData.debitor.shippingAddress?.address.addressLineAlt ?? '',
			label: '',
		},
		{
			value: (caseData.debitor.shippingAddress?.address.postalCode ?? '') + ' ' + (caseData.debitor.shippingAddress?.address.city ?? ''),
			label: '',
		},
		{
			value: caseData.debitor.shippingAddress?.address.country ?? '',
			label: '',
		},
	];

	const onSubmit = async (data: IDebitorBox) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				debitor: {
					debitorId: data.requisitionerInsuranceId,
					company: caseData.debitor.company,
					cvrNumber: caseData.debitor.cvrNumber,
					policeNumber: caseData.debitor.policeNumber,
					type: caseData.debitor.type as DebitorType,
					attention: data.requisitionerAttentionName,
					excess: caseData.debitor.excess,
					shippingAddressReference: (data.shippingAddressReference?.length ?? 0) > 0 ? data.shippingAddressReference : undefined,
					billingAddress: {
						road: data.insuranceInvoiceRoad,
						houseNumber: data.insuranceInvoiceHouseNumber,
						floor: data.insuranceInvoiceFloor,
						addressLineAlt: data.insuranceInvoiceAddress2,
						postalCode: data.insuranceInvoiceZip,
						city: data.insuranceInvoiceCity,
						country: data.insuranceInvoiceCountry,
					},
				},
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, debitor }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				debitor: debitor,
			},
		});
	};

	const onUpdateSubmit = async (data: IUpdateDebitor) => {
		try {
			await postDebitorUpdate({
				no: selectedDebitor?.debitorId ?? '',
				company: data.company,
				addressLine: data.address,
				addressLineAlt: data.address2,
				postalCode: data.zip,
				city: data.city,
			});
			setUpdateResponse('case.debitorUpdateRequestSend');
			setEditDebitorData(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postDebitorUpdate = async ({ no, addressLine, addressLineAlt, company, country }: UpdateDebitorVariables): Promise<void> => {
		await updateDebitor({
			variables: {
				no,
				addressLine,
				addressLineAlt,
				company,
				country,
			},
		});
	};

	return (
		<>
			<Box title="common.debitor" full onClick={() => setEditActive(true)} icon={caseViewExternal ? undefined : faEdit}>
				<hr />
				<div className="text-blue my-3 text-sm">
					<div className="flex flex-row">
						<p className={classNames('font-semibold', { 'text-red': caseData.debitor.company === 'OBS! Debitor ikke fundet i BC' })}>
							{caseData.debitor.company} ({caseData.debitor.debitorId})
						</p>
					</div>
					<p className="font-semibold">{caseData.debitor.address?.addressLine}</p>
					<p className="font-semibold">{caseData.debitor.address?.addressLineAlt}</p>
					<p className="font-semibold">
						{caseData.debitor.address?.postalCode} {caseData.debitor.address?.city}
					</p>
					{caseData.debitor.gln && <p className="font-semibold">EAN: {caseData.debitor.gln}</p>}
					<p className="font-semibold">{caseData.debitor.phone}</p>
					<p className="font-semibold">{caseData.debitor.email}</p>
				</div>
				{caseData.debitor.shippingAddress !== null && <BoxFormDataViewer data={debitorShippingAddress} keyId="case.debitor" />}
				{caseData.debitor.billingAddress !== null && <BoxFormDataViewer data={debitorBillingAddress} keyId="case.debitor" />}
			</Box>
			<Modal
				title={editDebitorData ? 'case.requestDebitorChange' : 'case.editDebitor'}
				close={() => {
					setEditActive(false);
					setEditDebitorData(false);
				}}
				visible={editActive}
				size={ModalSize.SMALL}
				body={
					<>
						{!editDebitorData && (
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<SearchableSelect
										key="label"
										control={control}
										name="requisitionerInsuranceId"
										title="case.companyOrInsuranceCompany"
										required
										options={searchedDebitors ?? []}
										searchFn={(searchText): void => setCompanySearchText(searchText)}
										onSelect={(value): void => searchSelectHandler(value)}
										onBlur={(): void => undefined}
										minInputLength={2}
										isLoading={debitorsLoading}
										errorMessage={errors.requisitionerInsuranceId?.message ?? ''}
										initialSelection={{
											value: caseData.debitor.debitorId,
											label: caseData.debitor.company,
										}}
									/>

									<p className="text-green text-center text-sm">{t(updateResponse)}</p>
									{selectedDebitor ? (
										<div className="text-blue my-3 px-2 text-sm">
											<div className="flex flex-row">
												<p className="font-semibold">
													{selectedDebitor.company} {selectedDebitor.debitorId}
												</p>
												<div className="flex-grow-default flex items-center justify-end">
													<button className="text-blue focus-within:ring-offset-blue-light w-8 cursor-pointer focus:ring-0" onClick={() => setEditDebitorData(true)}>
														{<FontAwesomeIcon icon={faEdit} className="mx-auto" />}
													</button>
												</div>
											</div>
											<p className="font-semibold">{selectedDebitor.address?.addressLine}</p>
											<p className="font-semibold">{selectedDebitor.address?.addressLineAlt}</p>
											<p className="font-semibold">
												{selectedDebitor.address?.postalCode} {selectedDebitor.address?.city}
											</p>
											{selectedDebitor.gln && <p className="font-semibold">EAN: {selectedDebitor.gln}</p>}
											<p className="font-semibold">{selectedDebitor.phone}</p>
											<p className="font-semibold">{selectedDebitor.email}</p>
											{debitorShipmentAddresses.length > 0 && (
												<Dropdown
													title="case.debitorShipmentAddress"
													name="shippingAddressReference"
													data={[
														{
															label: 'case.selectDebitorShipmentAddress',
															value: '',
														},
														...debitorShipmentAddresses,
													]}
													disableSortLabelAlphabetically
													innerRef={register}
													errorMessage={errors.shippingAddressReference?.message ?? ''}
													loading={loadingAddressesData}
												/>
											)}
										</div>
									) : (
										<p>{t('case.chooseInsurance')}</p>
									)}

									<Input
										name="requisitionerAttentionName"
										title="common.attention"
										innerRef={register}
										errorMessage={errors.requisitionerAttentionName?.message ?? ''}
										defaultValue={caseData.debitor.attention ?? ''}
									/>

									<div className="flex w-full">
										<div className="w-3/4">
											<Input
												title="common.road"
												name="insuranceInvoiceRoad"
												innerRef={register}
												errorMessage={errors.insuranceInvoiceRoad?.message ?? ''}
												defaultValue={caseData.debitor.billingAddress?.road}
											/>
										</div>
										<div className="ml-4 w-1/4">
											<Input
												title="common.houseNumber"
												name="insuranceInvoiceHouseNumber"
												innerRef={register}
												errorMessage={errors.insuranceInvoiceHouseNumber?.message ?? ''}
												defaultValue={caseData.debitor.billingAddress?.houseNumber}
											/>
										</div>
										<div className="ml-4 w-1/4">
											<Input
												title="common.floor"
												name="insuranceInvoiceFloor"
												innerRef={register}
												errorMessage={errors.insuranceInvoiceFloor?.message ?? ''}
												defaultValue={caseData.debitor.billingAddress?.floor ?? ''}
											/>
										</div>
									</div>

									<Input
										title="common.addressLine2"
										name="insuranceInvoiceAddress2"
										innerRef={register}
										errorMessage={errors.insuranceInvoiceAddress2?.message ?? ''}
										defaultValue={caseData.debitor.billingAddress?.addressLineAlt ?? ''}
									/>

									<div className="flex w-full flex-row lg:w-full">
										<div>
											<Input
												title="common.postalcode"
												name="insuranceInvoiceZip"
												innerRef={register}
												errorMessage={errors.insuranceInvoiceZip?.message ?? ''}
												defaultValue={caseData.debitor.billingAddress?.postalCode}
											/>
										</div>
										<div className="flex-grow-default ml-4">
											<Input
												title="common.city"
												name="insuranceInvoiceCity"
												innerRef={register}
												errorMessage={errors.insuranceInvoiceCity?.message ?? ''}
												className="w-full md:w-full lg:w-full"
												defaultValue={caseData.debitor.billingAddress?.city}
											/>
										</div>
									</div>

									<SearchableSelect
										key="country"
										control={control}
										name="insuranceInvoiceCountry"
										title="common.country"
										options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
										searchFn={(searchText): void => setCountrySearchText(searchText)}
										onSelect={(value): void => countrySelectHandler(value)}
										onBlur={(): void => undefined}
										minInputLength={1}
										isLoading={false}
										initialSelection={countries.find(i => i.value === caseData.debitor.billingAddress?.country)}
										errorMessage={errors.insuranceInvoiceCountry?.message ?? ''}
									/>

									<div className="mt-3 flex flex-row items-center">
										<Button submit success text="case.editDebitor" loading={updateCaseSubmitting} />
									</div>
								</div>
							</form>
						)}

						{editDebitorData && selectedDebitor && (
							<form onSubmit={updateSubmit(onUpdateSubmit)}>
								<div className="text-blue my-3 text-sm">
									<button className="text-blue focus-within:ring-offset-blue-light w-5 cursor-pointer focus:ring-0" onClick={() => setEditDebitorData(false)}>
										{<FontAwesomeIcon icon={faArrowAltLeft} className="mx-auto" />}
									</button>

									<Input
										title="common.name"
										name="company"
										required
										innerRef={registerUpdate}
										defaultValue={selectedDebitor?.company}
										errorMessage={errorsUpdate.company?.message ?? ''}
										className="w-full text-sm lg:w-full"
									/>

									<Input
										title="common.addressLine"
										name="address"
										required
										innerRef={registerUpdate}
										defaultValue={selectedDebitor?.address?.addressLine ?? ''}
										errorMessage={errorsUpdate.address?.message ?? ''}
										className="w-full text-sm lg:w-full"
									/>

									<Input
										title="common.addressLine2"
										name="address2"
										innerRef={registerUpdate}
										defaultValue={selectedDebitor?.address?.addressLineAlt ?? ''}
										errorMessage={errorsUpdate.address2?.message ?? ''}
										className="w-full text-sm lg:w-full"
									/>

									<div className="flex flex-row">
										<div className="w-1/3 lg:w-1/3">
											<Input
												title="common.postalcode"
												name="zip"
												required
												innerRef={registerUpdate}
												defaultValue={selectedDebitor?.address?.postalCode}
												errorMessage={errorsUpdate.zip?.message ?? ''}
												className="w-11/12 text-sm lg:w-11/12"
											/>
										</div>

										<div className="w-2/3 lg:w-2/3">
											<Input
												title="common.city"
												name="city"
												required
												innerRef={registerUpdate}
												defaultValue={selectedDebitor?.address?.city}
												errorMessage={errorsUpdate.city?.message ?? ''}
												className="w-full text-sm lg:w-full"
											/>
										</div>
									</div>
								</div>
								<div className="mt-3 flex flex-row items-center">
									<Button submit success text="case.requestDebitorChange" loading={updateDebitorSubmitting} />
								</div>
							</form>
						)}
					</>
				}
			/>
		</>
	);
};

export default DebitorBox;
