import * as yup from 'yup';
import { AlternativeContactSchema } from './AlternativeContactSchema';
import { DamageAddressSchema } from './DamageAddressSchema';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const InjuredPartySchema = yup.object().shape({
	DamageAddressBox: DamageAddressSchema,

	AlternativeContactBox: AlternativeContactSchema,
});
