import { useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import React from 'react';
import Select from 'react-select';
import { GetWebLocations, GetWebLocationsVariables } from '../../GraphQL';

const GET_LOCATIONS = loader('src/GraphQL/Locations/GetWebLocations.gql');

interface Props {
	value: string;
	onSelect(option: SelectOption): void;
}

const MovableLocationsSelect: React.FC<Props> = ({ value, onSelect }) => {
	const { data } = useQuery<GetWebLocations, GetWebLocationsVariables>(GET_LOCATIONS, {
		variables: { movableLocationsOnly: true },
	});

	const locationOptions = React.useMemo(() => data?.locations.map(l => ({ value: l.id, label: l.name })) ?? [], [data?.locations]);

	return (
		<Select
			placeholder=""
			isClearable
			closeMenuOnSelect={false}
			menuPortalTarget={document.body}
			className="react-select-custom w-40 text-xs"
			value={{
				label: locationOptions.find(l => l.value === value)?.label ?? '',
				value,
			}}
			styles={{
				menuPortal: base => ({ ...base, zIndex: 6667 }),
				option: base => ({ ...base, fontSize: '0.75rem' }),
				noOptionsMessage: base => ({ ...base, fontSize: '0.75rem' }),
				control: base => ({ ...base, height: '36px' }),
			}}
			onChange={option => {
				onSelect({
					label: option?.label ?? '',
					value: option?.value ?? '',
				});
			}}
			options={locationOptions}
		/>
	);
};

export default MovableLocationsSelect;
