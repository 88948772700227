import React from 'react';
import { useMutation } from '@apollo/client';
import { DeleteCatalogFile, DeleteCatalogFileVariables, GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables } from '../../../GraphQL';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';

const DELETE_FILE = loader('src/GraphQL/Files/DeleteCatalogFile.gql');
const GET_GLOBAL_CATALOG_FILES = loader('src/GraphQL/Files/GetGlobalCatalogESDHFiles.gql');

interface Props {
	filenameToDelete: string;
	visible: boolean;
	close(): void;
	fetchVariables: GetGlobalCatalogESDHFilesVariables;
}

const DeleteGlobalCatalogFilesModal: React.FC<Props> = ({ filenameToDelete, visible, close, fetchVariables }) => {
	const { t } = useTranslation();

	const [deleteCatalogFile, { loading: loadingDeleteFile }] = useMutation<DeleteCatalogFile, DeleteCatalogFileVariables>(DELETE_FILE);

	async function deleteFile(fileName: string) {
		try {
			await deleteCatalogFile({
				variables: {
					fileName: fileName,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>({
						query: GET_GLOBAL_CATALOG_FILES,
						variables: fetchVariables,
					});

					if (cachedRequest === null || cachedRequest.globalCatalogFiles === null) {
						return;
					}

					cache.writeQuery<GetGlobalCatalogESDHFiles, GetGlobalCatalogESDHFilesVariables>({
						query: GET_GLOBAL_CATALOG_FILES,
						variables: fetchVariables,
						data: {
							globalCatalogFiles: cachedRequest.globalCatalogFiles.filter(file => file.name !== fileName),
						},
					});
				},
			});
			close();
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Modal
			title="catalog.file.delete"
			size={ModalSize.SMALL}
			close={() => close()}
			visible={visible}
			body={
				<div>
					<div className="flex flex-col">
						<p>
							{t('catalog.file.deleteConfirm')} {filenameToDelete}?
						</p>

						<Button danger text="catalog.file.delete" onClick={() => deleteFile(filenameToDelete)} className="mt-4" loading={loadingDeleteFile} />
					</div>
				</div>
			}
		/>
	);
};

export default DeleteGlobalCatalogFilesModal;
