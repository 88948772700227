import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
	to: string;
	text: string;
	collapsed: boolean;
	onClick?: () => Promise<void>;
	className?: string;
	beta?: boolean;
}

const MenuLink: React.FC<Props> = ({ to, text, collapsed, children, onClick, className, beta = false }): React.ReactElement => {
	const { t } = useTranslation();
	const param = useLocation();

	return (
		<Link
			to={to}
			title={t(text)}
			className={classNames('mb-10 flex flex-row items-center font-medium tracking-wide', className, {
				'text-cyan': param.pathname === to && to !== '/',
			})}
			onClick={onClick}
		>
			<div className="flex w-10 justify-center">
				<div className="flex w-10 justify-center text-center">{children}</div>
			</div>
			<div className="line-clamp-2 ml-6 text-base">{t(text)} {beta && <span className={classNames('text-xs p-1 rounded-default text-blue', {
				'bg-cyan': param.pathname === to && to !== '/',
				'bg-white': param.pathname !== to,
			})}>Beta</span>}</div>
		</Link>
	);
};

export default MenuLink;
