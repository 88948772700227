import React from 'react';
import { useQuery } from '@apollo/client';
import { faCalendarAlt, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permissions } from '@ssg/common/GraphQL';
import { formatDate, SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	GetWebCases,
	GetWebCasesVariables,
	GetWebDepartments,
	GetWebDepartmentsVariables,
	GetJobs,
	GetJobsVariables,
	GetManagerClosedDayUserList,
} from '../../GraphQL';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import { useFlag } from '@unleash/proxy-client-react';
import Input from '@ssg/common/Components/Input';
import Table from '@ssg/common/Components/Table';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import Box from '../../Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import Header from '@ssg/common/Components/Header';
import UserContext from '../../UserContext';
import CaseTable from '../CaseOverview/CaseTable';
import JobsTable from '../Movables/JobsTable';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import InvoiceApprovalSection from './InvoiceApproval/InvoiceApprovalSection';
import Button from '@ssg/common/Components/Button';
import AppliedClosedCases from './AppliedClosedCases';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useGetManagerTimeTrackingClosedDayUserListQuery, useGetManagerTimeTrackingRejectedDayUserListQuery } from '@ssg/common/GraphQL/indexV2';
import ManagerFlexOverview from './ManagerFlexOverview';

const GET_JOBS = loader('src/GraphQL/Jobs/GetJobs.gql');
const GET_CASES = loader('src/GraphQL/Cases/GetWebCases.gql');
const GET_MANAGER_CLOSED_DAY_USERS = loader('src/GraphQL/TimeTracking/GetManagerClosedDayUserList.gql');
const GET_DEPARTMENTS = loader('src/GraphQL/Departments/GetWebDepartments.gql');

const MyPageOverview: FC = () => {
	const { t } = useTranslation();
	const userContext = useContext(UserContext);
	const envVar = useContext(EnvironmentVariableContext);

	const managerRejectedHoursView = useFlag(FeatureFlagEnums.MANAGER_REJECTED_HOURS_VIEW);
	const newTimeregistration = useFlag(FeatureFlagEnums.TIMEREG2);
	const managerWorkerFlexView = useFlag(FeatureFlagEnums.MANAGER_WORKER_FLEX_VIEW);

	const userId = userContext.user?.id ?? '';
	const userName = userContext.user?.name ?? '';

	const userText: SelectOption[] = [
		{
			label: t('common.name'),
			value: userContext.user?.name ?? t('common.notSpecified'),
		},
		{
			label: t('common.employeeId'),
			value: userContext.user?.employeeId ?? t('common.notSpecified'),
		},
		{
			label: t('common.department'),
			value: userContext.user?.department ?? t('common.notSpecified'),
		},
		{
			label: t('common.area'),
			value: userContext.user?.area ?? t('common.notSpecified'),
		},
		{
			label: t('common.jobFunction'),
			value: userContext.user?.jobFunction ?? t('common.notSpecified'),
		},
		{
			label: t('common.email'),
			value: userContext.user?.email ?? t('common.notSpecified'),
		},
		{
			label: t('common.phone'),
			value: userContext.user?.phone ?? t('common.notSpecified'),
		},
	];

	const [searchJobTerm, setSearchJobTerm] = useDebouncedState('', 100);
	const [searchCaseTerm, setSearchCaseTerm] = useDebouncedState('', 0);

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const { data: jobData, loading: loadingJobs } = useQuery<GetJobs, GetJobsVariables>(GET_JOBS, {
		variables: {
			personalOnly: true,
			completed: false,
		},
	});

	const {
		data: caseData,
		loading: loadingData,
		fetchMore,
	} = useQuery<GetWebCases, GetWebCasesVariables>(GET_CASES, {
		variables: {
			anyManager: userId,
			includeEconomy: true,
			offset: 0,
			limit: 20,
			closedCases: false,
		},
	});

	const { data: departmentData, loading: loadingDepartments } = useQuery<GetWebDepartments, GetWebDepartmentsVariables>(GET_DEPARTMENTS, {
		variables: { operationManager: userId },
	});

	// filtered cases
	const casesData = useMemo(() => {
		let cases = caseData?.cases ?? [];

		cases = cases.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt));

		const fuse = new Fuse(cases, {
			shouldSort: true,
			threshold: 0.1,
			keys: [
				'erpNo',
				'debitor.company',
				'debitor.debitorId',
				'debitor.policeNumber',
				'damage.contact.address.road',
				'damage.contact.address.houseNumber',
				'damage.contact.address.floor',
				'damage.contact.address.postalCode',
				'damage.contact.address.city',
				'damage.cause.name',
				'damage.category.name',
				'track',
				'ssgDepartment.name',
				'ssgDepartment.departmentNumber',
				'ssgLocation.name',
				'projectManager.name',
				'caseManager.name',
				'damage.businessArea.name',
			],
		});

		if (searchCaseTerm.length > 0) {
			cases = fuse
				.search({
					$and: tokenizeStringWithQuotesBySpaces(searchCaseTerm).map((searchToken: string) => {
						const orFields: Fuse.Expression[] = [
							{ erpNo: searchToken },
							{ 'debitor.company': searchToken },
							{ 'debitor.debitorId': searchToken },
							{ 'debitor.policeNumber': searchToken },
							{ 'damage.contact.address.road': searchToken },
							{
								'damage.contact.address.houseNumber': searchToken,
							},
							{ 'damage.contact.address.floor': searchToken },
							{
								'damage.contact.address.postalCode': searchToken,
							},
							{ 'damage.contact.address.city': searchToken },
							{ 'damage.cause.name': searchToken },
							{ 'damage.category.name': searchToken },
							{ track: searchToken },
							{ 'ssgDepartment.name': searchToken },
							{
								'ssgDepartment.departmentNumber': searchToken,
							},
							{ 'ssgLocation.name': searchToken },
							{ 'projectManager.name': searchToken },
							{ 'caseManager.name': searchToken },
							{ 'damage.businessArea.name': searchToken },
						];

						return {
							$or: orFields,
						};
					}),
				})
				.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
				.map(v => v.item);
		}

		return cases;
	}, [caseData, searchCaseTerm]);

	// filtered jobs
	const filteredJobs = useMemo(() => {
		let jobs = jobData?.jobs ?? [];
		const fuse = new Fuse(jobs, {
			shouldSort: true,
			threshold: 0.1,
			keys: ['description', 'location.name', 'assignedTo.name', 'assignedTo.email', 'case.erpNo', 'movable.description', 'movable.status', 'movable.placement.name'],
		});

		if (searchJobTerm.length > 0) {
			jobs = fuse
				.search({
					$and: tokenizeStringWithQuotesBySpaces(searchJobTerm).map((searchToken: string) => {
						const orFields: Fuse.Expression[] = [
							{ description: searchToken },
							{ 'location.name': searchToken },
							{ 'assignedTo.name': searchToken },
							{ 'assignedTo.email': searchToken },
							{ 'case.erpNo': searchToken },
							{ 'movable.description': searchToken },
							{ 'movable.status': searchToken },
							{ 'movable.placement.name': searchToken },
						];

						return {
							$or: orFields,
						};
					}),
				})
				.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
				.map(v => v.item);
		}

		return jobs;
	}, [jobData, searchJobTerm]);

	const { data: closedDayUserList } = useQuery<GetManagerClosedDayUserList>(GET_MANAGER_CLOSED_DAY_USERS, {
		skip: !newTimeregistration,
	});
	const closedDayUserListSorted = useMemo(() => {
		const list = closedDayUserList?.closedDayManagerList.slice() ?? [];
		list.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

		return list;
	}, [closedDayUserList?.closedDayManagerList]);

	const {
		data: closedDayUserListDataRaw,
		loading: closedDayUserListLoading,
	} = useGetManagerTimeTrackingClosedDayUserListQuery();
	const newClosedDayUserListSorted = useMemo(() => {
		const list = closedDayUserListDataRaw?.timeTrackingClosedDayManagerList.slice() ?? [];
		list.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

		return list;
	}, [closedDayUserListDataRaw?.timeTrackingClosedDayManagerList]);

	const {
		data: rejectedDayUserListDataRaw,
		loading: rejectedDayUserListLoading,
	} = useGetManagerTimeTrackingRejectedDayUserListQuery({
		skip: !managerRejectedHoursView,
	});
	const rejrectedDayUserListSorted = useMemo(() => {
		const list = rejectedDayUserListDataRaw?.timeTrackingRejectedDaysManagerList.slice() ?? [];
		list.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

		return list;
	}, [rejectedDayUserListDataRaw?.timeTrackingRejectedDaysManagerList]);

	return (
		<div>
			<Header title={`${t('myPage.overviewTitle')} - ${userName}`} />

			<BoxContainer>
				<Box title="myPage.userInformation" className="text-blue mb-8">
					<hr className="mb-1" />
					<BoxFormDataViewer data={userText} keyId="myPage.userInformation" />
				</Box>

				<Box title="timeRegistration.approveTime">
					<hr className="mb-1" />
					<a href={envVar.timeApproveUrl} rel="noreferrer" target="_blank" className="group mb-3 mr-8 flex flex-row items-center font-medium tracking-wide">
						<FontAwesomeIcon icon={faCalendarAlt} />
						<div className="line-clamp-2 ml-3 cursor-pointer text-base group-hover:underline">{t('myPage.timeApproval')}</div>
					</a>
				</Box>

				<RestrictedArea permissions={[Permissions.MY_PAGE_VIEW]}>
					<InvoiceApprovalSection loadingDepartments={loadingDepartments} departmentData={departmentData?.departments ?? []} />

					<Box title="common.ownCases" full className="text-blue my-8">
						<div className="flex w-full flex-row flex-wrap">
							<div className="mr-3 mb-3">
								<label className="block text-xs font-medium">{t('common.search')}</label>
								<div className="flex">
									<div className="relative w-full">
										<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
											<FontAwesomeIcon icon={faSearch} />
										</div>
										<Input
											name="overview-search"
											placeholder={t('myPage.case.searchPlaceholder')}
											className="pr-8 text-sm lg:w-full"
											onChange={e => {
												setSearchCaseTerm(e.target.value);
											}}
											value={searchCaseTerm}
										/>
									</div>
								</div>
							</div>
						</div>

						<CaseTable cases={casesData} loading={loadingData} noDataFound={'common.noCasesFound'} />
						{typeof caseData !== 'undefined' && caseData.cases.length % 20 === 0 && caseData.cases.length !== 0 && (
							<Button
								fullWidth
								text="common.getMore"
								secondary
								loading={fetchMoreLoading}
								className="mt-2"
								onClick={async () => {
									setFetchMoreLoading(true);
									await fetchMore({
										variables: {
											offset: caseData.cases.length,
										},
									});
									setFetchMoreLoading(false);
								}}
							/>
						)}
					</Box>
					<RestrictedArea permissions={[Permissions.CASES_CLOSE]}>
						<AppliedClosedCases loadingDepartments={loadingDepartments} departmentData={departmentData} />
					</RestrictedArea>
					<Box title="myPage.tasks" full className="text-blue mb-8">
						<div className="flex w-full flex-row flex-wrap">
							<div className="mr-3 mb-3">
								<label className="block text-xs font-medium">{t('common.search')}</label>
								<div className="flex">
									<div className="relative w-full">
										<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
											<FontAwesomeIcon icon={faSearch} />
										</div>
										<Input
											name="overview-search"
											placeholder={t('myPage.task.searchPlaceholder')}
											className="pr-8 text-sm lg:w-full"
											onChange={e => {
												setSearchJobTerm(e.target.value);
											}}
											value={searchJobTerm}
										/>
									</div>
								</div>
							</div>
						</div>

						<JobsTable
							jobs={filteredJobs}
							queryProps={{
								personalOnly: true,
								completed: false,
							}}
							loading={loadingJobs}
							noDataText={'myPage.noJobsFound'}
						/>
					</Box>

					{(!newTimeregistration && closedDayUserListSorted.length > 0) && (
						<Box full title="myPage.timeRegistrations" className="text-blue mb-8">
							<Table
								data={closedDayUserListSorted}
								keySelector={({ user }) => user.id}
								columns={[
									{
										label: 'common.user',
										selectFn: ({ user }) => user.name,
										sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
									},
									{
										label: 'common.email',
										selectFn: ({ user }) => <a href={`mailto:${user.email}`}>{user.email}</a>,
										sortFn: (a, b) => a.user.email.localeCompare(b.user.email),
									},
									{
										label: 'common.phone',
										selectFn: ({ user }) => (user.phone !== null && user.phone.length > 0 ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ''),
										sortFn: (a, b) => (a.user.phone ?? '').localeCompare(b.user.phone ?? ''),
									},
									{
										label: 'timeRegistration.latestClose',
										selectFn: ({ date }) => formatDate(new Date(date)),
										sortFn: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
									},
								]}
								rowIsInvalid={({ date }) => {
									const yesterday = new Date();
									yesterday.setDate(yesterday.getDate() - 1);
									yesterday.setHours(0, 0, 0, 0);

									return new Date(date).getTime() < yesterday.getTime();
								}}
							/>
						</Box>
					)}

					{(newTimeregistration && newClosedDayUserListSorted.length > 0) && (
						<Box full title="myPage.timeRegistrations" className="text-blue mb-8">
							<Table
								data={newClosedDayUserListSorted}
								keySelector={({ user }) => user.id}
								columns={[
									{
										label: 'common.user',
										selectFn: ({ user }) => user.name,
										sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
									},
									{
										label: 'common.email',
										selectFn: ({ user }) => <a href={`mailto:${user.email}`}>{user.email}</a>,
										sortFn: (a, b) => a.user.email.localeCompare(b.user.email),
									},
									{
										label: 'common.phone',
										selectFn: ({ user }) => (user.phone !== null && user.phone.length > 0 ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ''),
										sortFn: (a, b) => (a.user.phone ?? '').localeCompare(b.user.phone ?? ''),
									},
									{
										label: 'timeRegistration.latestClose',
										selectFn: ({ date }) => formatDate(new Date(date)),
										sortFn: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
									},
								]}
								loading={closedDayUserListLoading}
								rowIsInvalid={({ date }) => {
									const yesterday = new Date();
									yesterday.setDate(yesterday.getDate() - 1);
									yesterday.setHours(0, 0, 0, 0);

									return new Date(date).getTime() < yesterday.getTime();
								}}
							/>
						</Box>
					)}

					{(managerRejectedHoursView && rejrectedDayUserListSorted.length > 0) && (
						<Box full title="myPage.rejectedTimeRegistrations" className="text-blue mb-8">
							<Table
								data={rejrectedDayUserListSorted}
								keySelector={({ user }) => user.id}
								columns={[
									{
										label: 'common.user',
										selectFn: ({ user }) => user.name,
										sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
									},
									{
										label: 'common.email',
										selectFn: ({ user }) => <a href={`mailto:${user.email}`}>{user.email}</a>,
										sortFn: (a, b) => a.user.email.localeCompare(b.user.email),
									},
									{
										label: 'common.phone',
										selectFn: ({ user }) => (user.phone !== null && user.phone.length > 0 ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ''),
										sortFn: (a, b) => (a.user.phone ?? '').localeCompare(b.user.phone ?? ''),
									},
									{
										label: 'common.date',
										selectFn: ({ date }) => formatDate(new Date(date)),
										sortFn: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
									},
								]}
								loading={rejectedDayUserListLoading}
								rowIsInvalid={() => true}
							/>
						</Box>
					)}

					{managerWorkerFlexView &&
						<div className="w-full">
							<ManagerFlexOverview />
						</div>
					}

				</RestrictedArea>
			</BoxContainer>
		</div>
	);
};

export default MyPageOverview;
