import { GetDebitor_debitor, GetInvoicesForUsersApproval_invoicesForUsersApproval_case_debitor, GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_lines } from './GraphQL';
import { EditableInvoiceLine } from './Pages/SingleCase/Invoice/InvoiceModal';

export interface SumFeeResult {
	sum: number;
	fee: number;
}

export const DEFAULT_ENVFEE_NAME = 'ENVFEE';

export const defaultSumFeeResult: SumFeeResult = { sum: 0, fee: 0 };

export function shouldCalculateEnvironmentalFeeForDebitor(environmentFeePercentage: GetDebitor_debitor['environmentFeePercentage']): boolean {
	return environmentFeePercentage > 0;
}

export function hasMaximumEnvironmentalFeeAmountForDebitor(maxEnvironmentFeeAmount: GetDebitor_debitor['maxEnvironmentFeeAmount']): boolean {
	return maxEnvironmentFeeAmount > 0;
}

export const shouldCalculateEnvFeeForCategory = (category: string | undefined) => {
	return category === 'FINAL' || category === 'SUB' || category === 'SCREENING';
};

export function calculateEnvironmentalFeeForLines(
	lines:
		| {
				price: number;
				value: number;
				excludeFromEnvFee: boolean | null;
		  }[]
		| undefined,
	debitor: GetDebitor_debitor,
): SumFeeResult {
	if (!lines) {
		return { ...defaultSumFeeResult };
	}

	return lines.reduce(
		(sumFee, line) => {
			const value = line.value === null ? 0 : isNaN(line.value) ? 0 : line.value;
			const result = (isNaN(line.price) ? 0 : line.price) * value;
			sumFee.sum += result;

			if (!line.excludeFromEnvFee) {
				sumFee.fee = calculateEnvironmentFee(result, sumFee.fee, debitor);
			}

			return sumFee;
		},
		{ ...defaultSumFeeResult },
	);
}

function calculateEnvironmentFee(price: number, previousFee: number, debitor: GetDebitor_debitor): number {
	const currentFee = calculatePercentage(price, debitor);
	const enforceMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);
	const newFee = previousFee + currentFee;

	if (enforceMaxEnvFeeAmount && newFee > debitor.maxEnvironmentFeeAmount) {
		return debitor.maxEnvironmentFeeAmount;
	}

	return roundToTwoDecimalPlaces(newFee);
}

export function calculateEnvironmentFeeScreening(price: number, debitor: GetDebitor_debitor): number {
	const currentFee = calculatePercentage(price, debitor);
	console.log('🚀 ~ calculateEnvironmentFeeScreening ~ currentFee:', currentFee);
	const enforceMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);
	console.log('🚀 ~ calculateEnvironmentFeeScreening ~ enforceMaxEnvFeeAmount:', enforceMaxEnvFeeAmount);

	if (enforceMaxEnvFeeAmount && currentFee > debitor.maxEnvironmentFeeAmount) {
		return debitor.maxEnvironmentFeeAmount;
	}
	return roundToTwoDecimalPlaces(currentFee);
}

function calculateEnvironmentalFeeForEditableInvoiceLine(line: EditableInvoiceLine, debitor: GetDebitor_debitor): number {
	let fee = 0;
	if (!line.excludeInEnvFee && line.newUnitPrice) {
		fee += calculatePercentage(line.newUnitPrice * (line.quantity ?? 0), debitor);
	}

	return roundToTwoDecimalPlaces(fee);
}

function calculatePercentage(num: number, debitor: GetDebitor_debitor) {
	return (num * debitor.environmentFeePercentage) / 100;
}

function roundToTwoDecimalPlaces(num: number): number {
	return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function calculateTotalEnvFee(lines: EditableInvoiceLine[], shouldCalculateEnvFeeForDebitor: boolean, debitor: GetDebitor_debitor, category: string | undefined): number {
	if (!shouldCalculateEnvFeeForDebitor) {
		return 0;
	}
	const enforceMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);

	let envFee = 0;

	if (category) {
		if (category === 'SCREENING') {
			const feeLines = lines.filter(l => l.no === DEFAULT_ENVFEE_NAME);
			feeLines.forEach((line: EditableInvoiceLine) => {
				envFee += line.unitPrice ?? line.newUnitPrice ?? 0;
			});
		} else {
			const feeLines = lines.filter(l => l.no !== DEFAULT_ENVFEE_NAME);
			feeLines.forEach((line: EditableInvoiceLine) => {
				envFee += calculateEnvironmentalFeeForEditableInvoiceLine(line, debitor);
			});
		}
	}

	const result = enforceMaxEnvFeeAmount && envFee > debitor.maxEnvironmentFeeAmount ? debitor.maxEnvironmentFeeAmount : envFee;
	return result;
}

export function getEnvFeeOnInvoice(
	lines: GetInvoicesForUsersApproval_invoicesForUsersApproval_invoiceToApprove_lines[],
	debitor: GetInvoicesForUsersApproval_invoicesForUsersApproval_case_debitor,
): number {
	let envFee = 0;
	const enforceMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);

	const feeLines = lines.filter(l => l.no === DEFAULT_ENVFEE_NAME);

	feeLines.forEach(line => {
		envFee += line.unitPrice ?? line.newUnitPrice ?? 0;
	});

	const result = enforceMaxEnvFeeAmount && envFee > debitor.maxEnvironmentFeeAmount ? debitor.maxEnvironmentFeeAmount : envFee;
	return result;
}

