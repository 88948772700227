import { CalendarEventType, EventFragment, VehicleFragment } from '@ssg/common/GraphQL/indexV2';
import React from 'react';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCopy, faEdit, faLock, faSpinner, faTimes, faTrailer } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { DragItemTypes, isOffDay, useCanEdit, usePlannerDrivingSlips } from './PlannerHelpers';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';
import { DragPropsVehicleItem } from './VehicleCard';
import EventActionModal, { EventAction } from './EventActionModal';
import { UserCalendarAndColor } from './PlannerSchedule';
import { DateTime, Interval } from 'luxon';
import { Link } from 'react-router-dom';

interface Props {
	event: EventFragment;
	user: UserCalendarAndColor['user'];
}

function toTimeOnly(dateTime: string) {
	const d = new Date(dateTime + 'Z');
	const hour = `${('0' + d.getHours()).slice(-2)}`;
	const minutes = `${('0' + d.getMinutes()).slice(-2)}`;
	return `${hour}:${minutes}`;
}

function eventHours(from: string, to: string) {
	const fromTime = DateTime.fromISO(from + 'Z');
	const toTime = DateTime.fromISO(to + 'Z');

	const hours = Interval.fromDateTimes(fromTime, toTime).length('hours');

	return hours;

}

function dateOfDrivingSlip(dateTime: string) {
	const d = new Date(dateTime + 'Z');
	const day = `${('0' + d.getDate()).slice(-2)}`;
	const month = `${('0' + (d.getMonth() + 1)).slice(-2)}`;
	const year = d.getFullYear();
	return `${year}-${month}-${day}`;
}

export interface DragPropsEventItem extends DragObjectWithType {
	event: EventFragment;
	oldUserId: string;
}

const PlannerEvent: React.FC<Props> = ({ event, user }): React.ReactElement => {
	const { t } = useTranslation();
	const { canEdit } = useCanEdit();
	const [moved, setMoved] = React.useState(false);
	const [action, setAction] = React.useState<EventAction>();
	const [{ isDragging }, dragRef] = useDrag<DragPropsEventItem, void, { isDragging: boolean }>({
		item: {
			type: DragItemTypes.EVENT_ITEM,
			event,
			oldUserId: user.id,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		end(draggedItem, monitor) {
			const didDrop = monitor.didDrop();
			const dropResult = monitor.getDropResult() as { result: 'success' | 'denied' } | null;
			if (dropResult && dropResult.result === 'success') {
				console.log('dropResult', monitor.getDropResult());
				setMoved(didDrop);
			}
		},
	});
	const ref = (event.referenceId !== 'LOADING' && event.type !== CalendarEventType.Unknown && canEdit) ? dragRef : null;

	const offDay = React.useMemo(() => isOffDay([event]), [event]);

	return (
		<>
			<PopoverDrivingSlipInfo drivingSlip={event.drivingSlip} isDragging={isDragging}>
				<div ref={event.type === CalendarEventType.DrivingSlip && event.drivingSlip === null ? undefined : ref} className={classNames('bg-white select-none p-1 text-sm', {
					'opacity-50': isDragging,
					'cursor-not-allowed': ref === null,
					'-mx-1 -mt-1': event.isAllDay,
					'bg-red border-blue': offDay,
					'border-b-2': !offDay,
					'rounded-default border-2 border-blue text-blue': !event.isAllDay,
					'hidden': moved,
				})
				}>
					<PopoverDrivingSlipActions visible={true} onCopyClick={() => setAction('COPY')} onEditClick={() => setAction('EDIT')}>
						<div>
							<EventDropZone eventType={event.type} drivingSlip={event.drivingSlip}>
								<div className="flex flex-row justify-between">
									<p className={classNames('break-all', { 'text-center font-bold w-full': event.isAllDay })}>{event.type === CalendarEventType.Unknown && <FontAwesomeIcon icon={faLock} size="1x" className="mr-1" />}{
										event.drivingSlip
											?
											<Link to={`/case/${event.drivingSlip.case.id}`} target="_blank" className="hover:underline">
												{event.title}
											</Link>
											:
											<>{event.title}</>
									}</p>
									{!event.isAllDay && <p>{toTimeOnly(event.timeRange.from.replace('Z', ''))} ({eventHours(event.timeRange.from.replace('Z', ''), event.timeRange.to.replace('Z', ''))})</p>}
								</div>
								{event.drivingSlip &&
									<p>{event.drivingSlip.case.damage.contact.address.postalCode} {event.drivingSlip.case.damage.contact.address.city} </p>
								}
								{event.referenceId === 'LOADING' &&
									<p className="text-xs text-blue text-center"><FontAwesomeIcon icon={faSpinner} className='animate-spin mr-1' />{t('planner.outlook')}</p>
								}
								{event.referenceId !== 'LOADING' && event.type === CalendarEventType.DrivingSlip && event.drivingSlip === null &&
									<p className="text-xs text-red text-center">{t('planner.noDrivingSlip')}</p>
								}
							</EventDropZone>
						</div>
					</PopoverDrivingSlipActions>
				</div>
			</PopoverDrivingSlipInfo>
			{typeof action !== 'undefined' &&
				<EventActionModal nameUser={user.name} event={event} userId={user.id} action={action} close={() => setAction(undefined)} visible={typeof action !== 'undefined'} />
			}
		</>
	);
};


interface EventDropZoneProps {
	eventType: CalendarEventType;
	children: React.ReactNode;
	drivingSlip: EventFragment['drivingSlip'];
}

const EventDropZone: React.FC<EventDropZoneProps> = ({ children, eventType, drivingSlip }): React.ReactElement => {
	const { canEdit } = useCanEdit();
	const { t } = useTranslation();
	const { postAddCarOrMaterial, removeCarFromDrivingSlip, removeMaterialFromDrivingSlip } = usePlannerDrivingSlips();

	const [{ isOver, canDrop, getItem }, dropRef] = useDrop<
		DragPropsVehicleItem,
		void,
		{
			isOver: boolean;
			canDrop: boolean;
			getItem: DragPropsVehicleItem;
		}
	>({
		accept: [DragItemTypes.CAR, DragItemTypes.MATERIAL],
		canDrop(item, monitor) {
			if (eventType !== CalendarEventType.DrivingSlip) {
				return false;
			}
			if (drivingSlip) {
				if (item.type === DragItemTypes.MATERIAL && typeof drivingSlip.materials.find(m => m.vehicleNumber === item.vehicle.vehicleNumber) !== 'undefined') {
					return false;
				}

				return true;
			}
			return false;
		},
		drop: item => {
			if (drivingSlip !== null) {
				const date = dateOfDrivingSlip(drivingSlip.start.replace('Z', ''));
				const bookedOnDay = item.bookings.find(b => b.date === date && b.bookings.length > 0);
				if (bookedOnDay) {
					const notThisDriver = bookedOnDay.bookings.find(b => b.driver?.id !== drivingSlip.driver?.id);
					if (notThisDriver) {
						const bookAnyway = window.confirm(t('planner.confirmAlreadyBooked'));
						if (!bookAnyway) return;
					}
				}
				if (item.type === DragItemTypes.CAR) {
					postAddCarOrMaterial({
						id: drivingSlip.id,
						car: item.vehicle.vehicleNumber,
					},
						{ ...drivingSlip, car: item.vehicle },
						drivingSlip.car?.vehicleNumber,
					);
				}
				if (item.type === DragItemTypes.MATERIAL) {
					const mats = drivingSlip.materials;
					postAddCarOrMaterial({
						id: drivingSlip.id,
						material: item.vehicle.vehicleNumber,
					},
						{ ...drivingSlip, materials: [...mats, item.vehicle] });
				}
			}
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			getItem: monitor.getItem(),
		}),
	});
	if (drivingSlip === null) {
		return <div>{children}</div>;
	}
	const ref = canEdit ? dropRef : null;

	return (
		<div ref={ref}>
			{children}
			<div className="space-y-1">

				{drivingSlip.car &&
					<>
						{isOver && canDrop && getItem.type === DragItemTypes.CAR
							?
							<div className="border-2 border-dashed text-center rounded-md">
								{t('planner.changeCar')}
							</div>
							: <VehicleItem vehicle={drivingSlip.car} isCar removeFn={() => removeCarFromDrivingSlip(drivingSlip.id, drivingSlip.car?.vehicleNumber ?? '', {
								...drivingSlip,
								car: null,
							})} />

						}
					</>
				}
				{drivingSlip.car === null && isOver && canDrop && getItem.type === DragItemTypes.CAR &&
					<div className="border-2 border-dashed text-center rounded-md">
						{t('planner.addCar')}
					</div>}
				{drivingSlip.materials.map((m, index, all) => {
					return <VehicleItem key={m.vehicleNumber} vehicle={m} removeFn={() => removeMaterialFromDrivingSlip(drivingSlip.id, m.vehicleNumber, {
						...drivingSlip,
						materials: all.filter(a => a.vehicleNumber !== m.vehicleNumber),
					})} />;
				})}
				{isOver && canDrop && getItem.type === DragItemTypes.MATERIAL &&
					<div className="border-2 border-dashed text-center rounded-md">
						{t('planner.addMaterial')}
					</div>}
			</div>
		</div>
	);
};

interface VehicleItemProps {
	vehicle: VehicleFragment;
	isCar?: boolean;
	removeFn?(): void;
}

export const VehicleItem: React.FC<VehicleItemProps> = ({ vehicle, isCar = false, removeFn }): React.ReactElement => {
	return (
		<div className="bg-white border-2 text-sm rounded-md border-blue px-1 justify-between flex flex-row group"><span><FontAwesomeIcon size='lg' className='mr-1' icon={isCar ? faCar : faTrailer} />{vehicle.brand} ({vehicle.vehicleNumber})</span>{removeFn && <TextButton onClick={removeFn} className='text-red hidden group-hover:block' icon={faTimes} />}</div>
	);
};

interface PopOverProps {
	drivingSlip: EventFragment['drivingSlip'];
	isDragging: boolean;
	children: React.ReactElement;
}

const PopoverDrivingSlipInfo: React.FC<PopOverProps> = ({ drivingSlip, children, isDragging }): React.ReactElement => {
	const { t } = useTranslation();

	if (drivingSlip === null) {
		return <>{children}</>;
	}

	//const [findOldDriver] = drivingSlip.changes ? drivingSlip.changes.filter(c => c.before.driver !== null).slice(-1) : [];

	if (isDragging) return <>{children}</>;

	return (
		<>
			<Popover
				duration={0}
				placement="right"
				visible={isDragging ? false : undefined}
				content={
					<div className="w-46 rounded-b-default border-1 shadow-default border-blue bg-white p-2 text-xs text-blue">
						<div className="w-full">
							<p className="font-semibold">{t('drivingSlips.plannerMessage')}</p>
							<p>{drivingSlip.plannerMessage ? drivingSlip.plannerMessage : t('common.none')}</p>
							<p className="font-semibold">{t('drivingSlips.commentForUser')}</p>
							<p>{drivingSlip.comment ? drivingSlip.comment : t('common.none')}</p>

							{/* <div className="text-sm font-semibold">{t('case.caseNo') + drivingSlip.case.erpNo}</div>
							<p className="font-semibold">{t('case.ssgProjectManager')}</p>
							<p>{drivingSlip.case.projectManager?.name ?? t('common.noneSpecified')}</p>
							<p>{drivingSlip.case.projectManager?.phone ? t('common.tel') + formatPhoneNumberIntl(drivingSlip.case.projectManager?.phone ?? '') : ''}</p>
							<p className="mt-1 font-semibold">{t('case.damage')}</p>
							<p>{drivingSlip.case.damage.category.name}</p>
							<p>{drivingSlip.case.damage.cause.name}</p>
							<p className="mt-1 font-semibold">{t('case.injuredParty')}</p>
							<p>{drivingSlip.case.damage.contact.name}</p>
							<p>
								{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber} {drivingSlip.case.damage.contact.address.floor ?? ''}
							</p>
							<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
							<a href={`tel:${drivingSlip.case.damage.contact.phone}`}>
								{t('common.tlf')} {drivingSlip.case.damage.contact.phone}
							</a> */}
							{/* <p className="font-semibold">{t('drivingSlips.formerDriver')}</p>
							<p>{findOldDriver && findOldDriver.before.driver?.name}</p>
							<p>{findOldDriver && findOldDriver.before.driver?.phone}</p> */}
						</div>
					</div>
				}
			>
				{children}
			</Popover>
		</>
	);
};

interface ActionsProps {
	visible: boolean;
	children: React.ReactElement;
	onCopyClick: () => unknown;
	onEditClick: () => unknown;
	onChangeClick?: () => unknown;
}

const PopoverDrivingSlipActions: React.FC<ActionsProps> = ({ visible, children, onCopyClick, onEditClick, onChangeClick }): React.ReactElement => {
	// if (!visible || typeof onCopyClick === 'undefined' || typeof onEditClick === 'undefined' || typeof onChangeClick === 'undefined') {
	// 	return <>{children}</>;
	// }

	return (
		<>
			<Popover
				interactive
				clickTrigger
				hideOnClick
				delay={[100, 0]}
				duration={0}
				placement="bottom"
				zIndex={20}
				content={
					<div className="rounded-default border-1 shadow-default border-black bg-white p-1 text-xs text-black">
						<div className="flex flex-row justify-between space-x-1">
							<TextButton icon={faCopy} text="common.copy" onClick={onCopyClick} />
							<TextButton icon={faEdit} text="common.edit" onClick={onEditClick} />
						</div>
					</div>
				}
			>
				{children}
			</Popover>
		</>
	);
};
export default PlannerEvent;