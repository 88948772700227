import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DrivingSlipInput,
	UpdateWebDrivingSlip,
	UpdateWebDrivingSlipVariables,
	Permissions,
	GetUsersWithCalendars_usersWithCalendars,
	GetRiskEvaluationQuestions,
	GetRiskEvaluationCategories,
	DrivingSlipStatus,
	GetCauseQuestions,
	GetCauseQuestionsVariables,
	GetDrivingSlipsPlanner,
	//GetDrivingSlipsPlannerVariables,
	DeleteDrivingSlip,
	DeleteDrivingSlipVariables,
	GetWebCaseAdminData_drivingSlipCategories,
	GetWebDrivingSlips,
	GetWebDrivingSlipsVariables,
	GetRiskEvalutaionAnswersVariables,
	GetRiskEvalutaionAnswers,
} from '../../GraphQL';
import { DragObjectWithType, useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faEdit, faExclamationCircle, faEye, faPlus, faTrash, faExchange } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { drivingSlipSchema } from '../../Schemas/DrivingSlipSchema';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { isPlannedDateExceeded } from '@ssg/common/Helpers/drivingSlipsHelper';
import { getRiskEvaluationCategories } from '@ssg/common/Helpers/riskEvaluationHelper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import UserContext from '../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import Checkbox from '@ssg/common/Components/Checkbox';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Input from '@ssg/common/Components/Input';
import Textarea from '@ssg/common/Components/Textarea';
import Fuse from 'fuse.js';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Popover from '@ssg/common/Components/Popover';
import DrivingSlipRequisitionModal from './DrivingSlipRequisitionModal';
import TextButton from '@ssg/common/Components/TextButton';
import Loading from '@ssg/common/Components/Loading';

const UPDATE_DRIVINGSLIP = loader('src/GraphQL/DrivingSlips/UpdateWebDrivingSlip.gql');
const DELETE_DRIVINGSLIP = loader('src/GraphQL/DrivingSlips/DeleteDrivingSlip.gql');
const GET_DRIVING_SLIPS = loader('src/GraphQL/DrivingSlips/GetWebDrivingSlips.gql');
const GET_RISK_EVALUATION_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');
const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');
const GET_RISK_EVALUATION_ANSWERS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationAnswers.gql');
const GET_CAUSE_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetCauseQuestions.gql');

interface FormInput extends Omit<DrivingSlipInput, 'driver' | 'end'> {
	driver: string[];
	plannedDuration?: number;
}

export type MergedDrivingSlip = Omit<GetDrivingSlipsPlanner['drivingSlips'][number], 'id' | 'end' | 'start' | 'comment'> & {
	merged: Array<Pick<GetDrivingSlipsPlanner['drivingSlips'][number], 'id' | 'end' | 'start' | 'comment' | 'driver'>>;
	startDate: string;
	startDateTime: string;
};

interface Props {
	drivingSlip: MergedDrivingSlip;
	sanitizers: GetUsersWithCalendars_usersWithCalendars[];
	dataRefetcher: () => Promise<unknown[]>;
	categories: GetWebCaseAdminData_drivingSlipCategories[];
	drivingSlipsRefetch(): unknown;
}

export interface DragPropsDrivingSlip extends DragObjectWithType {
	drivingSlip: MergedDrivingSlip;
}

const DraggableDrivingSlip: React.FC<Props> = ({ drivingSlip, sanitizers, dataRefetcher, categories, drivingSlipsRefetch }): React.ReactElement => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const canEdit = arraysHasMatchingValue(userContext.user?.permissions ?? [], [Permissions.PLANNING_EDIT]);

	const [multipleDays, setMultipleDays] = React.useState(false);
	const additionalDaysInputRef = React.useRef<HTMLInputElement>(null);
	const [additionalDays, setAdditionalDays] = React.useState<Date[]>([]);

	React.useEffect(() => setAdditionalDays([]), [multipleDays]);

	const [editId, setEditId] = React.useState<string>();
	const [seeRiskEval, setSeeRiskEval] = React.useState(false);
	const [seeVisitation, setSeeVisitation] = React.useState(false);
	const [driverSearchText, setDriverSearchText] = React.useState(drivingSlip.driver?.name ?? '');
	const [convertToRequisition, setConvertToRequisition] = React.useState(false);

	const [drivingSlipId, setDrivingSlipId] = React.useState('');

	const searchSelectHandler = (value: string): void => {
		setValue('driver', [value], { shouldValidate: true });
	};

	const searchedUsers: SelectOption[] = React.useMemo(() => {
		let thisSanitizers = sanitizers;
		const fuse = new Fuse(sanitizers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name'],
		});

		if (driverSearchText.length > 0) {
			thisSanitizers = fuse.search(driverSearchText).map(v => v.item);
		}

		const mappedSanitizers = thisSanitizers.map((s): SelectOption => ({ value: s.id, label: s.name }));
		return mappedSanitizers;
	}, [sanitizers, driverSearchText]);

	const [{ isDragging }, dragRef] = useDrag<DragPropsDrivingSlip, void, { isDragging: boolean }>({
		item: {
			type: 'draggableDrivingSlip',
			drivingSlip: drivingSlip,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const ref = canEdit ? dragRef : null;

	const [updateDrivingSlip, { loading: updateDrivingSlipSubmitting }] = useMutation<UpdateWebDrivingSlip, UpdateWebDrivingSlipVariables>(UPDATE_DRIVINGSLIP);
	const [deleteDrivingSlip, { loading: deleteDrivingSlipSubmitting }] = useMutation<DeleteDrivingSlip, DeleteDrivingSlipVariables>(DELETE_DRIVINGSLIP);

	const { handleSubmit, register, setValue, errors, control } = useForm<FormInput>({
		resolver: yupResolver(drivingSlipSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			driver: drivingSlip.driver !== null ? [drivingSlip.driver.id] : [],
			location: drivingSlip.location.id ?? '',
			department: drivingSlip.department.id ?? '',
		},
	});

	register('location');
	register('department');

	const onSubmit = async (data: FormInput) => {
		if (typeof editId === 'undefined') {
			return;
		}

		try {
			const endDate = new Date(data.start);
			endDate.setHours(new Date(data.start).getHours() + (data.plannedDuration ?? 1)); // Default is one hour duration

			await updateSingleDrivingSlip({
				id: editId,
				driver: data.driver.length === 0 ? null : data.driver[0],
				start: data.start,
				end: endDate.toISOString(),
				urgent: data.urgent,
				comment: data.comment,
				location: data.location,
				department: data.department,

				eventId: null,
				immediatelyPlan: data.driver.length === 0 ? false : true,
				additionalDays: additionalDays.map(d => formatDateForInput(d)),
			});

			if (data.driver.length > 0) {
				await dataRefetcher();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const startDateWatch = useWatch({
		control,
		name: 'start',
		defaultValue: drivingSlip.merged.find(({ id }) => id === editId)?.start,
	});

	const plannedDateExceeded = React.useMemo(() => drivingSlip.merged.some(({ start }) => isPlannedDateExceeded(start)), [drivingSlip.merged]);

	const updateSingleDrivingSlip = async (data: UpdateWebDrivingSlipVariables): Promise<void> => {
		await updateDrivingSlip({
			variables: data,
			update: (cache, { data: responseData }): void => {
				if (typeof responseData === 'undefined' || responseData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebDrivingSlips, GetWebDrivingSlipsVariables>({
					query: GET_DRIVING_SLIPS,
					variables: { status: DrivingSlipStatus.UNPLANNED },
				});

				if (cachedRequest === null || responseData.updateDrivingSlip.length === 0) {
					return;
				}

				const addedDrivingSlips = responseData.updateDrivingSlip.filter(uds => !cachedRequest.drivingSlips.some(ds => ds.id === uds.id));

				cache.writeQuery<GetWebDrivingSlips, GetWebDrivingSlipsVariables>({
					query: GET_DRIVING_SLIPS,
					variables: { status: DrivingSlipStatus.UNPLANNED },
					data: {
						drivingSlips: [...cachedRequest.drivingSlips, ...addedDrivingSlips].filter(ds => ds.status !== DrivingSlipStatus.UNPLANNED),
					},
				});
			},
		});
		setEditId(undefined);
	};

	const { data: categoriesResult, loading: riskCategoriesLoading } = useQuery<GetRiskEvaluationCategories>(GET_RISK_EVALUATION_CATEGORIES, {
		skip: !seeRiskEval,
	});
	const { data: questionsResult, loading: riskQuestionsLoading } = useQuery<GetRiskEvaluationQuestions>(GET_RISK_EVALUATION_QUESTIONS, {
		skip: !seeRiskEval,
	});
	const { data: answersResult, loading: answersLoading } = useQuery<GetRiskEvalutaionAnswers, GetRiskEvalutaionAnswersVariables>(GET_RISK_EVALUATION_ANSWERS, {
		skip: !seeRiskEval,
		variables: {
			id: drivingSlip.case.id,
		},
	});
	const riskEvaluationCategories = getRiskEvaluationCategories({
		categories: categoriesResult?.riskEvaluationCategories,
		questions: questionsResult?.riskEvaluationQuestions,
		riskEvaluationAnswers: answersResult?.case.riskEvaluationAnswers,
	});

	const { data: visitationQuestions, loading: visitationQuestionsLoading } = useQuery<GetCauseQuestions, GetCauseQuestionsVariables>(GET_CAUSE_QUESTIONS, {
		variables: {
			damageCause: drivingSlip.case.damage.cause.id,
		},
		skip: !seeVisitation,
	});

	const questionsMap = new Map();

	visitationQuestions?.causeQuestions.forEach(q => {
		questionsMap.set(q.id, { id: q.id, question: q.question, answer: '' });
	});

	questionsMap.forEach(qm => {
		if (drivingSlip.case.visitation.answers && drivingSlip.case.visitation.answers.length > 0) {
			drivingSlip.case.visitation.answers?.forEach(a => {
				if (a.question === qm.id) {
					questionsMap.set(qm.id, {
						id: qm.id,
						question: qm.question,
						answer: a.answer,
					});
				}
			});
		}
	});

	const questionsArray = Array.from(questionsMap.values());

	const category = React.useMemo(() => categories.find(c => c.code === drivingSlip.category ?? 'somethingRandom'), [categories, drivingSlip.category]);

	return (
		<>
			<div
				ref={ref}
				key={drivingSlip.series}
				className={classNames('border-1 mb-2 flex flex-wrap bg-white p-4 pb-1 text-xs', {
					'border-orange border-2 py-3': drivingSlip.urgent && !plannedDateExceeded,
					'border-purple border-2 py-3': drivingSlip.case.skafor && !drivingSlip.urgent && !plannedDateExceeded,
					'border-red border-2 py-3': plannedDateExceeded,
					'opacity-50': isDragging,
					'cursor-move': canEdit,
				})}
			>
				{(drivingSlip.urgent || drivingSlip.case.skafor || plannedDateExceeded) && (
					<div className="flex w-full">
						{plannedDateExceeded && (
							<div className="text-red mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('common.overdue')}</p>
							</div>
						)}

						{drivingSlip.urgent && (
							<div className="text-orange mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('common.urgent')}</p>
							</div>
						)}

						{drivingSlip.case.skafor && (
							<div className="text-purple mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('case.SKAFOR')}</p>
							</div>
						)}
					</div>
				)}
				<div className="w-full md:w-1/2">
					<p className="font-semibold">{t('common.debitor')}</p>
					<p>{drivingSlip.case.debitor.company}</p>
					<p>{drivingSlip.case.debitor.address?.addressLine}</p>
					<p>{`${drivingSlip.case.debitor.address?.postalCode} ${drivingSlip.case.debitor.address?.city}`}</p>
					<a
						href={`tel:${drivingSlip.case.policyHolder.phone}`}
						className={classNames('hover:underline', {
							hidden: !drivingSlip.case.policyHolder.phone,
						})}
					>
						{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.policyHolder.phone)}
					</a>
					<br />
					<a
						href={`mailto:${drivingSlip.case.policyHolder.email}`}
						className={classNames('hover:underline', {
							hidden: !drivingSlip.case.policyHolder.email,
						})}
					>
						{t('common.mail')} {drivingSlip.case.policyHolder.email}
					</a>
				</div>

				<div className="w-full md:w-1/2">
					<p className="font-semibold">{t('case.injuredParty')}</p>
					<p>{drivingSlip.case.damage.contact.name}</p>
					<p>
						{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber} {drivingSlip.case.damage.contact.address.floor ?? ''}
					</p>
					<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
					<a
						href={`tel:${drivingSlip.case.damage.contact.phone}`}
						className={classNames('hover:underline', {
							hidden: !drivingSlip.case.damage.contact.phone,
						})}
					>
						{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.damage.contact.phone)}
					</a>
					<br />
					<a
						href={`mailto:${drivingSlip.case.damage.contact.email}`}
						className={classNames('hover:underline', {
							hidden: !drivingSlip.case.damage.contact.email,
						})}
					>
						{t('common.mail')} {drivingSlip.case.damage.contact.email}
					</a>
				</div>

				<div className="mt-3 w-full md:w-1/2">
					<p>
						<span className="font-medium">{t('common.created')}:</span> {dateToDateTimeString(drivingSlip.createdAt)}
					</p>
					<Link to={`/case/${drivingSlip.case.id}`} target="_blank" className="hover:underline">
						<p>
							<span className="font-medium hover:no-underline">{t('planner.projectNo')}:</span> {drivingSlip.case.erpNo}
						</p>
					</Link>
					<p>
						<span className="font-medium">{t('common.damageCategory')}:</span> {drivingSlip.case.damage.category.name}
					</p>
					<p>
						<span className="font-medium">{t('case.damageCause')}:</span> {drivingSlip.case.damage.cause.name}
					</p>
					{typeof category !== 'undefined' && (
						<p>
							<span className="font-medium">{t('common.category')}:</span> {category.name}
						</p>
					)}
				</div>

				<div className="mt-3 w-full md:w-1/2">
					<p>
						<span className="font-medium">{t('case.ssgProjectManager')}:</span> {drivingSlip.case.projectManager?.name}
						<br />
						<a
							href={`tel:${drivingSlip.case.damage.contact.phone}`}
							className={classNames('hover:underline', {
								hidden: !drivingSlip.case.projectManager?.phone,
							})}
						>
							{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.projectManager?.phone ?? '')}
						</a>
					</p>
					<p>
						<span className="font-medium">{t('common.department')}:</span> {`${drivingSlip.department.name} (${drivingSlip.department.departmentNumber})`}
					</p>
					<p>
						<span className="font-medium">{t('common.location')}:</span> {drivingSlip.location.name}
					</p>
					<p>
						<span className="font-medium">{t('case.ssgCaseManager')}:</span> {drivingSlip.case.caseManager?.name}
						<br />
						<a
							href={`tel:${drivingSlip.case.caseManager?.phone}`}
							className={classNames('hover:underline', {
								hidden: !drivingSlip.case.caseManager?.phone,
							})}
						>
							{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.caseManager?.phone ?? '')}
						</a>
					</p>
				</div>

				<div className="mt-3 w-full md:w-1/2">
					<p>
						<span className="font-medium">{t('common.deadline')}:</span>
					</p>
					{drivingSlip.deadline ? dateToDateTimeString(drivingSlip.deadline) : t('common.noneSpecified')}
				</div>

				<div className="mt-3 w-full md:w-1/2">
					<p>
						<span className="font-medium">{t('drivingSlips.requestedDriver')}:</span>
					</p>
					{/* {drivingSlip.driver !== null ? drivingSlip.driver.name : t('common.noneSpecified')} */}
					{drivingSlip.merged.map(({ id, driver }) => (
						<p key={id}>{driver !== null ? driver.name : t('common.noneSpecified')}</p>
					))}
				</div>

				<div className="mt-3 w-full">
					<p>
						<span className="font-medium">{t('planner.preferredTo')}:</span>
					</p>
					{drivingSlip.merged
						.slice()
						.reverse()
						.map(({ id, start, comment }) => (
							<div key={id} className="mb-1">
								<Popover
									placement="bottom"
									content={
										<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
											{/* <p className="font-medium text-sm">{t('case.drivenBy')}:</p>
                                        {drivingSlip.merged.find(m => m.id === id)?.driver?.name ?? ''} */}
											<p className=" text-sm font-medium">{t('common.comment')}:</p>
											{comment ?? ''}
										</div>
									}
								>
									<span>{dateToDateTimeString(start)}</span>
								</Popover>

								{drivingSlip.status !== DrivingSlipStatus.COMPLETED && drivingSlip.status !== DrivingSlipStatus.OBSOLETE && (
									<div className="inline-flex">
										<TextButton icon={faEdit} text="common.edit" onClick={() => setEditId(canEdit ? id : undefined)} className="ml-2 mr-2" textClassName="text-xs" iconSize="sm" />
										<TextButton
											text="common.convert"
											icon={faExchange}
											onClick={() => {
												setDrivingSlipId(id);
												setConvertToRequisition(true);
											}}
											textClassName="text-xs"
											iconSize="sm"
										/>
									</div>
								)}
							</div>
						))}
				</div>

				<div className="mt-2 flex w-full flex-row flex-wrap items-center justify-end">
					<TextButton
						icon={faEye}
						onClick={() => setSeeVisitation(true)}
						className={classNames('mr-4', {
							hidden: !drivingSlip.case.visitation.answers && drivingSlip.case.visitation.priorities.length < 1,
						})}
						text="case.visitation"
					/>

					<TextButton icon={faEye} onClick={() => setSeeRiskEval(true)} className="mr-4" text="case.riskEvaluation" />
				</div>
			</div>

			{typeof editId !== 'undefined' && (
				<Modal
					title="drivingSlips.update"
					close={() => setEditId(undefined)}
					visible={typeof editId !== 'undefined'}
					size={!multipleDays ? ModalSize.SMALL : ModalSize.MEDIUM}
					body={
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex flex-row">
								<div className="w-1/2">
									<Checkbox title="common.urgent" name="urgent" innerRef={register} errorMessage={errors.urgent?.message} defaultChecked={drivingSlip.urgent} />
								</div>

								<div className="w-1/2 pl-8">
									<Checkbox title="drivingSlips.multipleDays" name="multipleDays" onChange={e => setMultipleDays(e.target.checked)} />
								</div>
							</div>

							<div className="flex flex-row">
								<div className={multipleDays ? 'w-1/2' : 'w-full'}>
									<Datepicker
										time
										title="case.drivingDateTime"
										name="start"
										required
										errorMessage={errors.start?.message ?? ''}
										innerRef={register}
										defaultValue={formatDateForInput(new Date(drivingSlip.merged.find(m => m.id === editId)?.start ?? 0), true)}
									/>

									<Input
										title="case.drivingEstimatedTime"
										type="number"
										name="plannedDuration"
										innerRef={register}
										errorMessage={errors.plannedDuration?.message ?? ''}
										unit="common.hours"
										step={0.01}
										lang="da"
										inputMode="decimal"
										defaultValue={drivingSlip?.estimatedHours ?? undefined}
									/>

									<SearchableSelect
										control={control}
										name="driver"
										title="case.drivenBy"
										key="label"
										options={searchedUsers ?? []}
										searchFn={(searchText): void => setDriverSearchText(searchText)}
										onSelect={(value): void => searchSelectHandler(value)}
										onBlur={() => undefined}
										minInputLength={2}
										isLoading={false}
										initialSelection={{
											value: drivingSlip.driver?.id ?? '',
											label: drivingSlip.driver?.name ?? '',
										}}
									/>

									<Textarea
										title="common.comment"
										name="comment"
										rows={3}
										innerRef={register}
										errorMessage={errors.comment?.message ?? ''}
										defaultValue={drivingSlip.merged.find(m => m.id === editId)?.comment ?? ''}
									/>
								</div>

								{multipleDays && (
									<div className="w-1/2 pl-8">
										<div className="flex flex-row">
											<div className="w-3/4">
												<Datepicker
													innerRef={additionalDaysInputRef}
													title="drivingSlips.drivingDateTimeAddNew"
													name="additionalDaysAdd"
													min={startDateWatch?.substring(0, 'yyyy-MM-dd'.length)}
													className="lg:w-full"
												/>
											</div>

											<div className="w-1/4">
												<Button
													icon={faPlus}
													text="common.add"
													className="mt-7"
													onClick={() => {
														const d = additionalDaysInputRef.current?.valueAsDate;
														if (typeof d !== 'undefined' && d !== null) {
															setAdditionalDays(current => {
																const updated = [...current, d];
																updated.sort((a, b) => a.getTime() - b.getTime());
																return updated;
															});

															if (additionalDaysInputRef.current !== null) {
																additionalDaysInputRef.current.value = '';
															}
														}
													}}
												/>
											</div>
										</div>

										<ul className="mt-4 space-y-2">
											<li className="text-sm font-semibold">{t('common.start')}</li>
											{typeof startDateWatch !== 'undefined' && (
												<li>
													<FontAwesomeIcon
														icon={faCalendar}
														style={{
															marginLeft: 1,
															marginRight: 1,
														}}
													/>
													<span className="ml-2">{new Date(startDateWatch).toLocaleString()}</span>
												</li>
											)}

											<li>
												<hr className="border-gray-200" />
											</li>

											<li className="text-sm font-semibold">{t('drivingSlips.additionalDays')}</li>
											{additionalDays.map(d => (
												<li key={d.toISOString()}>
													<div className="inline-block">
														<TextButton icon={faTrash} onClick={() => setAdditionalDays(current => current.filter(ad => ad.getTime() !== d.getTime()))} />
													</div>
													<span className="ml-1">{d.toLocaleDateString()}</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>

							<div className="mt-3 flex flex-row justify-between">
								<Button submit success text="common.update" loading={updateDrivingSlipSubmitting} disabled={deleteDrivingSlipSubmitting} />

								<Button
									danger
									text="common.delete"
									loading={deleteDrivingSlipSubmitting}
									onClick={async () => {
										const id = editId;
										await deleteDrivingSlip({
											variables: {
												caseId: drivingSlip.case.id,
												id,
											},
										});

										drivingSlipsRefetch();
										setEditId(undefined);
									}}
									disabled={updateDrivingSlipSubmitting}
								/>
							</div>
						</form>
					}
				/>
			)}
			{seeRiskEval && (
				<Modal
					title="case.riskEvaluation"
					visible={seeRiskEval}
					close={() => setSeeRiskEval(false)}
					size={ModalSize.XLARGE}
					body={
						<div>
							{riskCategoriesLoading || riskQuestionsLoading || answersLoading ? (
								<div className="relative h-40">
									<Loading />
								</div>
							) : (
								<div className="flex flex-wrap">
									{riskEvaluationCategories.length > 0 ? (
										riskEvaluationCategories.map(c => {
											return (
												<div className="w-1/3" key={c.categoryName}>
													<FormFieldHeader title={c.categoryName} />
													{c.questions.map((q, i) => {
														return (
															<div key={i}>
																<p>
																	{q.question} - <span className="font-semibold">{q.answer ? t('common.yes') : t('common.no')}</span>
																</p>
															</div>
														);
													})}
												</div>
											);
										})
									) : (
										<div>{t('case.noRiskEvaluation')}</div>
									)}
								</div>
							)}
						</div>
					}
				/>
			)}
			{seeVisitation && (
				<Modal
					title="case.visitation"
					visible={seeVisitation}
					close={() => setSeeVisitation(false)}
					size={ModalSize.SMALL}
					body={
						<div>
							{visitationQuestionsLoading ? (
								<div className="relative h-40">
									<Loading />
								</div>
							) : (
								<div className="text-blue">
									{drivingSlip.case.visitation.priorities && (
										<div className="mb-2">
											<FormFieldHeader title="common.priority" />
											{drivingSlip.case.visitation.priorities.map(p => {
												return t(`case.${p}`);
											})}
										</div>
									)}

									{drivingSlip.case.visitation.answers && (
										<div>
											<FormFieldHeader title="case.visitationQuestions" />
											{questionsArray.map((q, i) => {
												return (
													<div key={i}>
														<p>
															<span className="font-semibold">{q.question}</span> {q.answer}
														</p>
														{/* <Input
                                                name={`answers[${i}].answer`}
                                                key={`${i}-a`}
                                                title={q.question}
                                                innerRef={register}
                                                defaultValue={q.answer}
                                            /> */}
													</div>
												);
											})}
										</div>
									)}
								</div>
							)}
						</div>
					}
				/>
			)}
			{drivingSlipId !== '' && convertToRequisition && (
				<DrivingSlipRequisitionModal
					visible={convertToRequisition}
					close={() => {
						setConvertToRequisition(false);
						setDrivingSlipId('');
					}}
					drivingSlipId={drivingSlipId}
					comment={drivingSlip.merged.find(d => d.id === drivingSlipId)?.comment ?? ''}
					caseId={drivingSlip.case.id}
				/>
			)}
		</>
	);
};

export default DraggableDrivingSlip;
