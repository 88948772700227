import React from 'react';
import { faDotCircle, faEnvelope, faFile, faFileInvoice, faHistory, faImages, faMapMarkerAlt, faPause, faPlay, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CaseStatus, GetSingleCase, GetSingleCaseVariables, UpdateCase, UpdateCaseVariables, Permissions, CreateWebDamageReport, CreateWebDamageReportVariables } from '../../GraphQL';
import { DateTime } from 'luxon';
import GoogleMapsLink from '@ssg/common/Components/GoogleMapsLink';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Header from '@ssg/common/Components/Header';
import MovablesOverviewDownload from '../../Components/MovablesOverviewDownload';
import VisitationBox from './VisitationBox';
import CaseAgreementsBox from './CaseAgreementBox/CaseAgreementsBox';
import DebitorBox from './DebitorBox';
import DrivingSlipBox from './DrivingSlipBox/DrivingSlipBox';
import InjuredPartyBox from './InjuredPartyBox';
import MiscBox from './MiscBox/MiscBox';
import RiskEvaluationBox from './RiskEvaluation/RiskEvaluationBox';
import SSGInformationBox from './SSGInformationBox';
import Loading from '@ssg/common/Components/Loading';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import CaseMail from './CaseMail';
import CreateJobModal from './CreateJobModal';
import CustomPolicyHolderBox from './CustomPolicyHolderBox';
import DamageBox from './DamageBox';
import ViewGuides from './ViewGuides';
import ScreeningBox from './Screening/ScreeningBox';
import AdministrativeProgress from '../../Components/AdministrativeProgress';
import FavoriteToggle from '../../Components/FavoriteToggle';
import ChangeTrack from './ChangeTrack';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import UserContext from '../../UserContext';
import CaseChangeLog from './CaseChangeLog';
import ImportantInfoBox from './ImportantInfoBox';
import SkaforBox from './SkaforBox';
import AdviserReferrerBox from './AdviserReferrerBox';
import RelatedCatalogs from './RelatedCatalogs';
import RequisitionModal from '../Requisitions/RequisitionModal';
import CaseUsageBox from './CaseUsage/CaseUsageBox';
import MovablesOverviewModal from './MovablesOverviewModal';
import InvoiceModal from './Invoice/InvoiceModal';
import TextButton from '@ssg/common/Components/TextButton';
import DamageContactBox from './DamageContactBox';
import NoUser from '../NoUser';
import CloseCaseButton from './CloseCaseButton';
import ReopenCaseButton from './ReopenCaseButton';
import OperationProgress from '../../Components/OperationProgress';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import { useFlag } from '@unleash/proxy-client-react';
import Popover from '@ssg/common/Components/Popover';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

// GraphQL quries
const GET_CASE = loader('../../GraphQL/Cases/GetSingleCase.gql');
const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const CREATE_DAMAGE_REPORT = loader('../../GraphQL/Reports/CreateWebDamageReport.gql');

const SingleCase: React.FC = (): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const caseViewExternal = arraysHasMatchingValue(userPermissions, [Permissions.CASES_VIEW_EXTERNAL]);

	const inspectionMovablesReportFlag = useFlag(FeatureFlagEnums.INSPECTION_MOVABLES_REPORT);
	const enableOfferModule = useFlag(FeatureFlagEnums.OFFER_MODULE);

	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);
	const [createDamageReport, { loading: creatingReport }] = useMutation<CreateWebDamageReport, CreateWebDamageReportVariables>(CREATE_DAMAGE_REPORT, {
		onCompleted: data => {
			window.open(data.createDamageReport.url, '_blank');
		},
		onError: error => {
			console.log(error);
		},
	});

	const [editMail, setEditMail] = React.useState(false);
	const [createJob, setCreateJob] = React.useState(false);
	const [viewGuides, setViewGuides] = React.useState(false);
	const [changeTrack, setChangeTrack] = React.useState(false);
	const [showCreateRequisitionModal, setShowCreateRequisitionModal] = React.useState(false);
	const [showBillingModal, setShowBillingModal] = React.useState(false);
	const [showChangesModal, setShowChangesModal] = React.useState(false);

	const { loading, data: caseData } = useQuery<GetSingleCase, GetSingleCaseVariables>(GET_CASE, {
		context: { debatch: true },
		fetchPolicy: 'cache-and-network',
		variables: {
			id: id,
			includeEconomy: true,
		},
		skip: !id, // Handles undefined & empty string scenarios
	});

	if (loading && typeof caseData === 'undefined') {
		return <Loading title="case.gettingCase" />;
	}

	if (typeof caseData === 'undefined') {
		return <p>Error</p>;
	}

	const changeCaseStatus = async (status: CaseStatus) => {
		await updateCase({
			variables: {
				id: caseData.case.id,
				status: status,
			},
		});
	};

	if (userContext.user === null) {
		return <NoUser />;
	}

	return (
		<div>
			<Header>
				<div className="text-blue flex text-sm lg:flex-row">
					<div className="mr-14 flex whitespace-nowrap">
						<div className="mt-2 flex h-6 flex-row items-center">
							<FavoriteToggle
								userFavoriteColor={caseData.case.userFavorite?.active === true ? caseData.case.userFavorite?.color ?? '' : ''}
								caseId={caseData.case.id}
								wrapperClass="mx-2"
							/>
						</div>
						<div className="flex flex-col gap-y-1 py-2">
							<div className="flex h-6 flex-row items-center font-bold">
								<h1 className="text-xl">{t('case.caseNo') + caseData.case.erpNo}</h1>
							</div>
							<div className="text-sm">
								{t('common.created')} <span>{dateToDateTimeString(caseData.case.createdAt)}</span>
							</div>
							<div className="flex flex-row text-sm">
								<button onClick={() => setChangeTrack(true)}>
									{t('case.track')}: <span className="font-semibold">{caseData.case.track}</span>
								</button>
							</div>
							<div>
								<AdministrativeProgress
									caseData={{
										...caseData.case,
										caseEconomics: null,
									}}
								/>
								<hr />
								<OperationProgress
									caseData={{
										...caseData.case,
										caseEconomics: null,
									}}
								/>
							</div>
						</div>
					</div>

					<div>
						<div className="mr-4 flex flex-row flex-wrap items-baseline gap-y-3 py-3">
							<RestrictedArea permissions={[Permissions.CASES_CREATE]}>
								{!caseViewExternal && <TextButton className="mr-3" link text="case.duplicate" icon={faPlus} onClick={() => history.push(`/casecreation/${id}`)} />}
							</RestrictedArea>

							<RestrictedArea permissions={[Permissions.CASES_EMAIL_EDIT]}>
								<TextButton className="mr-3" text="case.sendCaseMail" icon={faEnvelope} onClick={() => setEditMail(true)} />
							</RestrictedArea>

							<TextButton className="mr-3" link text="case.documentsAndPictures" icon={faImages} onClick={() => history.push(`/case/${id}/files`)} />

							<TextButton className="mr-3" link text="case.seeGuides" icon={faFile} onClick={() => setViewGuides(true)} />

							<GoogleMapsLink address={caseData.case.damage.contact.address}>
								<TextButton link text="case.seeCaseOnMap" icon={faMapMarkerAlt} />
							</GoogleMapsLink>
						</div>

						<div className="mr-4 flex flex-row flex-wrap items-baseline gap-y-3 py-3">
							{enableOfferModule && <TextButton className="mr-3" link text="common.offer" icon={faPlus} onClick={() => history.push(`/case/${id}/offers`)} />}
							{!caseViewExternal && (
								<>
									<TextButton
										className="mr-3"
										text="requisitions.createRequisition"
										icon={faPlus}
										onClick={() => setShowCreateRequisitionModal(true)}
										disabled={caseData.case.status !== CaseStatus.OPEN}
										tooltip={caseData.case.status !== CaseStatus.OPEN ? 'case.featureTurnedOff' : undefined}
									/>

									<TextButton className="mr-3" text="common.createTask" icon={faPlus} onClick={() => setCreateJob(true)} />
								</>
							)}

							<RestrictedArea permissions={[Permissions.MOVABLES_VIEW]}>
								<MovablesOverviewModal caseId={caseData.case.id} erpNo={caseData.case.erpNo} />
								<MovablesOverviewDownload caseId={caseData.case.id} erpNo={caseData.case.erpNo} />
							</RestrictedArea>
						</div>

						<div className="mr-4 flex flex-row flex-wrap items-baseline gap-y-3 py-3">
							<RestrictedArea permissions={[Permissions.CASES_ECONOMY_INVOICES_EDIT]}>
								{!caseViewExternal && !caseData.case.debitor.automaticTrackOneInvoicing && (
									<Popover
										placement="bottom"
										content={
											<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
												<p>
													{caseData.case.debitor.company} {t('case.invoice.noPoliceNo')}
												</p>
											</div>
										}
										visible={!caseData.case.debitor.allowInvoicingWithoutReqNo && caseData.case.debitor.policeNumber.length === 0 ? undefined : false}
									>
										<div>
											<TextButton
												className="mr-3"
												text="case.invoice.link"
												icon={faFileInvoice}
												disabled={
													(!caseData.case.debitor.allowInvoicingWithoutReqNo && caseData.case.debitor.policeNumber.length === 0) ||
													caseData.case.status !== CaseStatus.OPEN || caseData.case.debitor.blocked
												}
												tooltip={caseData.case.status !== CaseStatus.OPEN ? 'case.featureTurnedOff' : undefined}
												onClick={() => setShowBillingModal(true)}
											/>
										</div>
									</Popover>
								)}
							</RestrictedArea>
							{!caseViewExternal && (
								<TextButton
									className="mr-3"
									text={caseData.case.timeRegistration ? t('case.stopTimeRegistration') : t('case.startTimeRegistration')}
									icon={caseData.case.timeRegistration ? faPause : faPlay}
									onClick={() =>
										updateCase({
											variables: {
												id: caseData.case.id,
												timeRegistration: !caseData.case.timeRegistration,
											},
											update: (cache, { data: cacheData }): void => {
												if (typeof cacheData === 'undefined' || cacheData === null || cacheData.updateCase === null) {
													return;
												}

												const cachedRequest = cache.readQuery<GetSingleCase, GetSingleCaseVariables>({
													query: GET_CASE,
													variables: {
														id: caseData.case.id,
														includeEconomy: true,
													},
												});

												if (cachedRequest === null || cachedRequest.case === null) {
													return;
												}

												cache.writeQuery<GetSingleCase, GetSingleCaseVariables>({
													query: GET_CASE,
													variables: {
														id: caseData.case.id,
														includeEconomy: true,
													},
													data: {
														case: {
															...cachedRequest.case,
															...cacheData.updateCase,

															debitor: {
																...cachedRequest.case.debitor,
																...cacheData.updateCase.debitor,
																automaticTrackOneInvoicing: caseData.case.debitor.automaticTrackOneInvoicing,
															},
														},
													},
												});
											},
										})
									}
								/>
							)}
							<CloseCaseButton
								caseObj={caseData.case}
								caseStatus={caseData.case.status}
								caseOrProjectManager={caseData.case.projectManager?.id === userContext.user.id || caseData.case.caseManager?.id === userContext.user.id}
								changeCaseStatus={changeCaseStatus}
								loading={updateCaseSubmitting}
							/>

							<RestrictedArea permissions={[Permissions.CASES_OPEN]}>
								{caseData.case.status === CaseStatus.CLOSED && <ReopenCaseButton changeCaseStatus={changeCaseStatus} loading={updateCaseSubmitting} />}
							</RestrictedArea>

							{caseData.case.scalePointUrl !== null && (
								<TextButton className="ml-3" link text="case.scalePoint" icon={faDotCircle} onClick={() => window.open(caseData.case.scalePointUrl ?? '', '_blank')} />
							)}

							<TextButton className="ml-3" link text="case.incendium" onClick={() => window.open('https://ssg.incidentshare.com/', '_blank')} />

							{!caseData.case.erpNo.startsWith('DK') && (
								<div className="ml-3 flex self-end">
									<a href={`https://ssg5c.dk/sagsoversigt/showcase.asp?id=${caseData.case.erpNo}`} target="_blank" rel="noreferrer" className="underline">
										{t('case.caseInOld5C')}
									</a>
								</div>
							)}
						</div>
						{!caseViewExternal && (
							<div className="mr-4 flex flex-row flex-wrap gap-y-3 py-3">
								<TextButton
									className="mr-3"
									text="common.createDamageReport"
									disabled={creatingReport}
									loading={creatingReport}
									icon={faPlus}
									onClick={() =>
										createDamageReport({
											variables: { id },
										})
									}
								/>

								<TextButton className="mr-3" text="common.createInspectionReport" icon={faPlus} link onClick={() => history.push(`/case/${id}/inspection-report`)} />
								{inspectionMovablesReportFlag && <TextButton className="mr-3" text="common.createInspectionMovablesReport" icon={faPlus} link onClick={() => history.push(`/case/${id}/inspection-report-movables`)} />}

								<TextButton className="mr-3" text="common.createTrygWaterDamageReport" icon={faPlus} link onClick={() => history.push(`/case/${id}/tryg-water-report`)} />

								<TextButton className="mr-3" text="common.createWaterDamageReport" icon={faPlus} link onClick={() => history.push(`/case/${id}/water-report`)} />

								<TextButton className="mr-3" text="common.createIndoorClimateReport" icon={faPlus} link onClick={() => history.push(`/case/${id}/indoor-climate-report`)} />
								{(caseData.case.version > 1 || caseData.case.changes.length > 0) && (
									<TextButton text={t('case.caseChangesView')} icon={faHistory} className="mr-3" onClick={() => setShowChangesModal(true)} />
								)}

								<RelatedCatalogs
									caseData={{
										...caseData.case,
										caseEconomics: null,
										caseReports: null,
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</Header>

			{caseData.case.status === CaseStatus.APPLIED_CLOSED && <div className="bg-orange mb-8 -mt-8 w-full py-1 text-center text-white">{t('case.caseAppliedComplete')}</div>}

			{caseData.case.status === CaseStatus.CLOSED && <div className="bg-green mb-8 -mt-8 w-full py-1 text-center text-white">{t('case.caseComplete')}</div>}

			{caseData.case.status !== CaseStatus.CLOSED &&
				caseData.case.changes.some(({ before, after }) => before.debitor !== null && after.debitor !== null && before.debitor.debitorId !== after.debitor.debitorId) && (
					<div className="bg-orange mb-8 -mt-8 w-full py-1 text-center text-white">{t('case.debitorChanged')}</div>
				)}

			{caseData.case.debitor.blocked && <div className="bg-orange mb-8 -mt-8 w-full py-1 text-center text-white">Debitor er spærret eller udgået</div>}

			<BoxContainer>
				<div className="w-full lg:w-2/3">
					<div className="mb-8 flex w-full flex-row">
						<div className="w-full lg:w-1/2 lg:flex-col">
							<InjuredPartyBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

							<CustomPolicyHolderBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

							<AdviserReferrerBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

							{!userContext.user?.external && <ImportantInfoBox caseData={caseData.case} />}
						</div>

						<div className=" w-full lg:w-1/2 lg:flex-col">
							<DebitorBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

							<DamageBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

							<DamageContactBox caseData={caseData.case} caseViewExternal={caseViewExternal} />
						</div>
					</div>
				</div>

				<div className="mb-8 flex w-full flex-row lg:w-1/3 lg:flex-col">
					<SSGInformationBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

					<SkaforBox caseData={caseData.case} caseViewExternal={caseViewExternal} />

					<VisitationBox caseData={caseData.case} caseViewExternal={caseViewExternal} />
				</div>

				<div className="w-1/2">
					<CaseAgreementsBox caseData={caseData.case} caseViewExternal={caseViewExternal} />
				</div>

				<div className="w-1/2">
					<DrivingSlipBox caseData={caseData.case} caseViewExternal={caseViewExternal} />
				</div>

				<ScreeningBox
					debitor={caseData.case.debitor} // There Should be a global state for the application to prevent props drilling
					caseId={caseData.case.id}
					caseErpNo={caseData.case.erpNo}
					defaultTemplate={caseData.case.debitor.defaultScreeningTemplate ?? undefined}
					caseViewExternal={caseViewExternal}
					caseBlocked={caseData.case.debitor.blocked}
				/>

				<MiscBox caseData={caseData.case} />

				{!caseViewExternal && <CaseUsageBox caseErpNo={caseData.case.erpNo} caseViewExternal={caseViewExternal} drivingSlipsWithMaterials={caseData.case.drivingSlipSeries.flatMap(series => series.drivingSlips).filter(ds => ds.materials.length > 0)} />}

				<RiskEvaluationBox caseData={caseData.case} caseViewExternal={caseViewExternal} />
			</BoxContainer>

			{editMail && (
				<CaseMail
					open={editMail}
					close={() => setEditMail(false)}
					data={caseData.case}
					config={{
						subject: `${t('mail.defaultSubject', {
							erpNo: caseData.case.erpNo,
							policeNumber: caseData.case.debitor.policeNumber,
						})}`,
						recipients: [
							{
								email: caseData.case.damage.contact.email,
								role: 'case.injuredParty',
								name: caseData.case.damage.contact.name,
							},
							{
								email: caseData.case.debitor.email ?? '',
								role: 'common.debitor',
								name: caseData.case.debitor.company,
							},
							{
								email: caseData.case.referrer?.email ?? '',
								role: 'case.referrer',
								name: caseData.case.referrer?.name ?? '',
							},
							{
								email: caseData.case.policyHolder.email,
								role: 'case.policyHolder',
								name: caseData.case.policyHolder.name,
							},
							{
								email: caseData.case.requisitioner.email,
								role: 'case.requisitioner',
								name: caseData.case.requisitioner.name,
							},
							{
								email: caseData.case.adviser?.email ?? '',
								role: 'case.adviser',
								name: caseData.case.adviser?.name ?? '',
							},
							{
								email: caseData.case.projectManager?.email ?? '',
								role: 'case.ssgProjectManager',
								name: caseData.case.projectManager?.email ?? '',
							},
							{
								email: caseData.case.caseManager?.email ?? '',
								role: 'case.ssgCaseManager',
								name: caseData.case.caseManager?.email ?? '',
							},
							...caseData.case.damage.contacts.map(c => {
								return {
									email: c.email ?? '',
									role: '',
									name: c.name,
								};
							}),
						],
						message: `\nMVH.\n\n${userContext.user.name}`,
						defaultTabIndex: 0,
						fileNames: [],
					}}
				/>
			)}
			{createJob && <CreateJobModal open={createJob} close={() => setCreateJob(false)} caseData={caseData.case} />}
			{viewGuides && <ViewGuides open={viewGuides} close={() => setViewGuides(false)} />}
			{changeTrack && <ChangeTrack caseId={caseData.case.id} currentTrack={caseData.case.track} open={changeTrack} close={() => setChangeTrack(false)} />}

			{showBillingModal && (
				<InvoiceModal
					caseId={caseData.case.id}
					caseNo={caseData.case.erpNo}
					caseCreated={DateTime.fromISO(caseData.case.createdAt)}
					debitor={caseData.case.debitor}
					track={caseData.case.track}
					assortmentCode={caseData.case.debitor?.assortmentCode ?? ''}
					visible={showBillingModal}
					close={() => setShowBillingModal(false)}
				/>
			)}

			{showChangesModal && <CaseChangeLog version={caseData.case.version} changes={caseData.case.changes} visible={showChangesModal} close={() => setShowChangesModal(false)} />}
			{showCreateRequisitionModal && <RequisitionModal visible={showCreateRequisitionModal} close={() => setShowCreateRequisitionModal(false)} caseData={caseData.case} />}
		</div>
	);
};

export default SingleCase;
