import React from 'react';
import { useDrop } from 'react-dnd';
import { DragPropsDrivingSlip } from './DraggableDrivingSlip';
import { DateTime, Interval } from 'luxon';
import { CalendarEventInput, CalendarEventType, GetCarCalendars, GetUserCalendars, Permissions } from '../../GraphQL';
import { useForm, useWatch } from 'react-hook-form';
import { IEvent } from '../../Schemas/IEvent';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateEventSchema } from '../../Schemas/CreateEventSchema';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { DragPropsEventItem } from './DraggableEventItem';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Datepicker from '@ssg/common/Components/Datepicker';
import Button from '@ssg/common/Components/Button';
import UserContext from '../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
// h-12 -> h-5
interface Props {
	calendar: GetUserCalendars['userCalendars'][number] | GetCarCalendars['carCalendars'][number];
	className?: string;
	addEvent(refId: string, event: CalendarEventInput): Promise<void>;
	startTime: DateTime;
	getBgColor(refId: string): string;
	timeSlotClickHandler(timeSlotKey: string): void;
	keyName: string;
	timeSlotClicked: boolean;
	createEventLoading: boolean;
	updateEventDeciderAndPost(
		eventId: string,
		eventType: string,
		eventTitle: string,
		eventFromDate: string,
		eventToDate: string,
		newRefId: string,
		oldRefId: string,
		drivingSlipId: string | undefined,
	): void;
}

const eventTypes: SelectOption[] = [...enumToSelectOptions(CalendarEventType, 'planner.types.').filter(e => e.value !== CalendarEventType.DRIVING_SLIP && e.value !== CalendarEventType.UNKNOWN)];

//test comment
const TimeSlot: React.FC<Props> = ({
	calendar,
	children,
	className,
	addEvent,
	startTime,
	getBgColor,
	timeSlotClickHandler,
	keyName,
	timeSlotClicked,
	createEventLoading,
	updateEventDeciderAndPost,
}): React.ReactElement => {
	const userContext = React.useContext(UserContext);
	const canEdit = arraysHasMatchingValue(userContext.user?.permissions ?? [], [Permissions.PLANNING_EDIT]);
	const { t } = useTranslation();

	const [showModal, setShowModal] = React.useState(false);

	const { handleSubmit, control, register, errors } = useForm<IEvent>({
		resolver: yupResolver(CreateEventSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IEvent) => {
		try {
			await addEvent('user' in calendar ? calendar.user.id : calendar.car.id, {
				title: data.title,
				type: data.type as CalendarEventType,
				timeRange: {
					from: DateTime.fromISO(data.fromDate).toUTC().toISO(),
					to: DateTime.fromISO(data.toDate).toUTC().toISO(),
				},
			});
			timeSlotClickHandler('');
			setShowModal(false);
		} catch (e) {
			console.log(e);
		}
	};

	const chosenType = useWatch({
		control,
		name: 'type',
		defaultValue: '',
	});

	const [{ isOver, canDrop, getItem }, dropRef] = useDrop<
		DragPropsDrivingSlip | DragPropsEventItem,
		void,
		{
			isOver: boolean;
			canDrop: boolean;
			getItem: DragPropsDrivingSlip | DragPropsEventItem;
		}
	>({
		accept: ['draggableDrivingSlip', 'draggableEventItem'],
		drop: item => {
			if ('drivingSlip' in item) {
				const dateLength = 'yyyy-MM-dd'.length;

				// Find out if drivingslip series has multiple drivers
				const drivers = item.drivingSlip.merged.map(d => d.driver?.id);
				const uniqueDriveres = drivers.filter((d, i, a) => a.indexOf(d) === i);
				const hasMultipleDrivers = uniqueDriveres.length > 1;

				for (const { id, start, end, driver } of item.drivingSlip.merged) {
					const startDaysOffset = Interval.fromISO(`${item.drivingSlip.merged[0].start.substring(0, dateLength)}/${start.substring(0, dateLength)}`).length('days');
					const adjustedStartTime = startTime.plus({
						days: startDaysOffset,
					});

					const endTime = adjustedStartTime.plus({
						hours: Interval.fromISO(`${start}/${end}`).length('hours'),
					});

					// If drivingslip has multiple drivers, set each individual driver, else use calendar user
					const setDriver = 'user' in calendar ? (hasMultipleDrivers ? (typeof driver?.id !== 'undefined' ? driver?.id : calendar.user.id) : calendar.user.id) : calendar.car.id;

					addEvent(setDriver, {
						title: item.drivingSlip.case.erpNo,
						type: CalendarEventType.DRIVING_SLIP,
						timeRange: {
							from: adjustedStartTime.toUTC().toISO(),
							to: endTime.toUTC().toISO(),
						},
						drivingSlip: id,
					});
				}
			} else if ('eventId' in item) {
				const endTime = startTime.plus({ hours: item.durationInHours });
				updateEventDeciderAndPost(
					item.eventId,
					item.eventType,
					item.eventTitle,
					startTime.toUTC().toISO(),
					endTime.toUTC().toISO(),
					'user' in calendar ? calendar.user.id : calendar.car.id,
					item.oldUserId,
					item.drivingSlipId,
				);
			}
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			getItem: monitor.getItem(),
		}),
	});

	const ref = canEdit ? dropRef : null;

	return (
		<div
			ref={ref}
			className={classNames('relative flex-1 py-1', className, (isOver || timeSlotClicked) && getBgColor('user' in calendar ? calendar.user.id : calendar.car.id))}
			onClick={eventClick => {
				timeSlotClickHandler(keyName);
				eventClick.stopPropagation();
			}}
			onDoubleClick={() => setShowModal(canEdit)}
		>
			{getItem !== null && 'drivingSlip' in getItem && (
				<div
					className={classNames('rounded-default border-1 absolute z-50 mt-px h-5 opacity-75', getBgColor('user' in calendar ? calendar.user.id : calendar.car.id), {
						hidden: !isOver,
						'block bg-white': isOver && canDrop,
					})}
					//Lagacy code DO NOT REMOVE
					style={{
						width: `calc(${400 * Interval.fromISO(`${getItem.drivingSlip.merged[0].start}/${getItem.drivingSlip.merged[0].end}`).length('hours')}% + ${Interval.fromISO(
							`${getItem.drivingSlip.merged[0].start}/${getItem.drivingSlip.merged[0].end}`,
						).length('hours')}px)`,
					}}
				>
					<p className="mt-2 ml-1 text-sm text-white">{dateToDateTimeString(new Date(startTime.toJSDate())).split(' ')[1]}</p>
				</div>
			)}
			{getItem !== null && 'eventId' in getItem && (
				<div
					className={classNames('rounded-default border-1 absolute z-50 mt-px h-5 opacity-75', getBgColor('user' in calendar ? calendar.user.id : calendar.car.id), {
						hidden: !isOver,
						'block bg-white': isOver && canDrop,
					})}
					//Lagacy code DO NOT REMOVE
					style={{
						width: `calc(${400 * getItem.durationInHours}% + ${getItem.durationInHours}px)`,
					}}
				>
					<p className="mt-2 ml-1 text-sm text-white">{dateToDateTimeString(new Date(startTime.toJSDate())).split(' ')[1]}</p>
				</div>
			)}

			{children}

			<Modal
				visible={showModal}
				close={() => setShowModal(false)}
				title="planner.addEvent"
				size={ModalSize.SMALL}
				body={
					<div className="select-none">
						<form onSubmit={handleSubmit(onSubmit)}>
							{'user' in calendar && (
								<>
									<p className="text-blue text-base font-medium">{calendar.user.name}</p>
									<p className="text-blue truncate text-base">{calendar.user.phone}</p>
									<p className="text-blue truncate text-base">{calendar.user.email}</p>
								</>
							)}

							{'car' in calendar && (
								<>
									<p className="text-blue text-base font-medium">{calendar.car.name}</p>
									<p className="text-blue truncate text-base">{calendar.car.email}</p>
								</>
							)}

							<Dropdown data={eventTypes} name="type" title="common.category" innerRef={register} required errorMessage={errors.type?.message} />

							<Input
								name="title"
								title="common.title"
								innerRef={register}
								required
								errorMessage={errors.title?.message}
								defaultValue={chosenType !== '' ? t('planner.types.' + chosenType).toString() : ''}
							/>

							<Datepicker
								time
								name="fromDate"
								title="common.start"
								defaultValue={startTime.toISO().substring(0, 'yyyy-MM-ddTHH:mm'.length)}
								innerRef={register}
								required
								errorMessage={errors.fromDate?.message}
							/>

							<Datepicker
								time
								name="toDate"
								title="common.end"
								defaultValue={startTime.plus({ hours: 1 }).toISO().substring(0, 'yyyy-MM-ddTHH:mm'.length)}
								innerRef={register}
								required
								errorMessage={errors.toDate?.message}
							/>

							<Button submit text="planner.addEvent" success className="mt-3" loading={createEventLoading} />
						</form>
					</div>
				}
			/>
		</div>
	);
};

export default TimeSlot;
