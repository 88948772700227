import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm, useWatch } from 'react-hook-form';
import { GetSingleCase_case, GetSSGInfoAdminData, ScalePointStatus, SearchWebUsers, SearchWebUsersVariables, UpdateCase, UpdateCaseVariables } from '../../GraphQL';
import { CaseViewSSGInfoSchema } from '../../Schemas/CaseViewSSGInformationSchema';
import { ISSGInformation } from '../../Schemas/ICaseCreation';
import { regressResolver } from './VisitationBox';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Dropdown from '@ssg/common/Components/Dropdown';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Box from '../../Components/Layout/Box';
import BoxFormDataViewer, { BoxFormDataItem } from '../../Components/Layout/BoxFormDataViewer';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const UPDATE_CASE = loader('../../GraphQL/Cases/UpdateCase.gql');
const SEARCH_USERS = loader('../../GraphQL/Users/SearchWebUsers.gql');
const GET_SSGINFO_ADMIN_DATA = loader('../../GraphQL/Cases/GetSSGInfoAdminData.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

const SSGInformationBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const [editActive, setEditActive] = React.useState(false);
	const [projectManagerSearchText, setProjectManagerSearchText] = useDebouncedState(caseData.projectManager?.name ?? '', 100);
	const [caseWorkerSearchText, setCaseWorkerSearchText] = useDebouncedState(caseData.caseManager?.name ?? '', 100);

	const { data, loading } = useQuery<GetSSGInfoAdminData>(GET_SSGINFO_ADMIN_DATA, { skip: !editActive });

	const searchProjectManagerSelectHandler = (value: string): void => {
		setValue('ssgProjectManager', value, { shouldValidate: true });
	};

	const searchCaseWorkerSelectHandler = (value: string): void => {
		setValue('ssgCaseManager', value, { shouldValidate: true });
	};

	const { loading: pmLoading, data: pmData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: projectManagerSearchText,
			onlyEmployees: true,
		},
		skip: projectManagerSearchText === '' || !editActive,
	});

	const { loading: cwLoading, data: cwData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: caseWorkerSearchText,
			onlyEmployees: true,
		},
		skip: caseWorkerSearchText === '' || !editActive,
	});

	const pmUsers = React.useMemo(() => {
		return pmData?.searchUsers.map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [pmData]);

	const cmUsers = React.useMemo(() => {
		return cwData?.searchUsers.map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [cwData]);

	const [updateCase, { loading: updateCaseSubmitting }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, errors, register, control, setValue } = useForm<ISSGInformation>({
		resolver: yupResolver(CaseViewSSGInfoSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	React.useEffect(() => {
		if (editActive) {
			setValue('ssgProjectManager', caseData.projectManager?.id);
			setValue('ssgCaseManager', caseData.caseManager?.id);
		}
	}, [caseData.caseManager?.id, caseData.projectManager?.id, editActive, setValue]);

	const onSubmit = async (data: ISSGInformation) => {
		try {
			await postCaseUpdate({
				id: caseData.id,
				ssgDepartment: data.ssgDepartment,
				ssgLocation: data.ssgLocation,
				workComplete: data.workComplete,
				projectManager: data.ssgProjectManager,
				caseManager: data.ssgCaseManager,
				billingReady: data.billingReady,
			});
			setEditActive(false);
		} catch (e) {
			console.log(e);
		}
	};

	const postCaseUpdate = async ({ id, ssgDepartment, ssgLocation, workComplete, projectManager, caseManager, billingReady }: UpdateCaseVariables): Promise<void> => {
		await updateCase({
			variables: {
				id: id,
				ssgDepartment: ssgDepartment,
				ssgLocation: ssgLocation,
				workComplete: workComplete,
				projectManager: projectManager,
				caseManager: caseManager,
				billingReady: billingReady,
			},
		});
	};

	const selectedLocation = useWatch({
		control,
		name: 'ssgLocation',
		defaultValue: caseData.ssgLocation.id,
	});

	const locList = React.useMemo(() => (data?.locations ?? []).map((d): SelectOption => ({ value: d.id, label: d.name })), [data]);

	const depList = React.useMemo(
		() =>
			(data?.departments ?? [])
				.filter(l => selectedLocation === l.location.id)
				.map(
					(d): SelectOption => ({
						value: d.id,
						label: `${d.name} (${d.departmentNumber})`,
					}),
				),
		[data, selectedLocation],
	);

	const createScalepointInformation = (): BoxFormDataItem[] => {
		if (caseData.scalePointStatus === ScalePointStatus.NOT_CONNECTED) {
			return [];
		}

		const info: BoxFormDataItem[] = [
			{
				value: `case.scalePointStatusCodes.${caseData.scalePointStatus}`,
				label: 'case.scalePointStatus',
				warning: caseData.scalePointStatus === ScalePointStatus.ERRORED || caseData.scalePointStatus === ScalePointStatus.REJECTED,
			},
		];

		if (caseData.scalePointStatus === ScalePointStatus.ERRORED && caseData.scalePointErrorMessage) {
			info.push({ value: caseData.scalePointErrorMessage, label: 'case.scalePointError', divClassName: 'line-clamp-2' });
		}

		return info;
	};
	const newPlannerVehiclesFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_VEHICLES);

	const materialsOnCase = (): BoxFormDataItem[] => {
		if (!newPlannerVehiclesFlag) {
			return [];
		}

		const materials = caseData.drivingSlipSeries.flatMap(series => series.drivingSlips.flatMap(ds => ds.materials));
		const info: BoxFormDataItem = {
			label: 'case.materialOnCase',
			value: 'common.no',
		};

		if (materials.length > 0) {
			info.value = 'common.yes';
		}

		return [info];
	};

	const ssgInformation: BoxFormDataItem[] = [
		{
			value: caseData.projectManager?.name ?? '',
			label: 'case.ssgProjectManager',
		},
		{
			value: (caseData.projectManager?.phone ?? '').replaceAll(/(\s|-)+/g, ''),
			label: '',
		},
		{
			value: caseData.caseManager?.name ?? '',
			label: 'case.ssgCaseManager',
		},
		{
			value: (caseData.caseManager?.phone ?? '').replaceAll(/(\s|-)+/g, ''),
			label: '',
		},
		{ value: caseData.ssgLocation.name, label: 'common.location' },
		{
			value: `${caseData.ssgDepartment.name} (${caseData.ssgDepartment.departmentNumber})`,
			label: 'common.department',
		},
		{
			value: regressResolver(caseData.workComplete),
			label: 'case.workComplete',
		},
		{
			value: regressResolver(caseData.billingReady),
			label: 'case.billingReady',
		},
		...materialsOnCase(),
		{
			value: regressResolver(caseData.machineNotification),
			label: 'case.machineNotifications',
		},
		...createScalepointInformation(),
		{
			value: caseData.createdBy?.name ?? '',
			label: 'common.createdBy',
		},
		{
			value: dateToDateTimeString(caseData.createdAt),
			label: '',
		},
	];

	return (
		<>
			<Box title="case.ssgInformation" className="mb-8" icon={caseViewExternal ? undefined : faEdit} full onClick={() => setEditActive(true)}>
				<hr />
				<BoxFormDataViewer data={ssgInformation} keyId="case.ssgInformation" />
			</Box>
			<Modal
				title="case.editSSGInformation"
				close={() => setEditActive(false)}
				visible={editActive && !loading}
				size={ModalSize.SMALL}
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<SearchableSelect
							control={control}
							name="ssgProjectManager"
							title="case.ssgProjectManager"
							options={pmUsers ?? []}
							searchFn={(searchText): void => setProjectManagerSearchText(searchText)}
							onSelect={(value): void => searchProjectManagerSelectHandler(value)}
							onBlur={(clearFn): void => undefined}
							minInputLength={2}
							isLoading={pmLoading}
							errorMessage={errors.ssgProjectManager?.message ?? ''}
							initialSelection={
								caseData.projectManager
									? {
										value: caseData.projectManager.id,
										label: caseData.projectManager.name,
									}
									: undefined
							}
						/>

						<SearchableSelect
							control={control}
							name="ssgCaseManager"
							title="case.ssgCaseManager"
							options={cmUsers ?? []}
							searchFn={(searchText): void => setCaseWorkerSearchText(searchText)}
							onSelect={(value): void => searchCaseWorkerSelectHandler(value)}
							onBlur={(clearFn): void => undefined}
							minInputLength={2}
							isLoading={cwLoading}
							errorMessage={errors.ssgCaseManager?.message ?? ''}
							initialSelection={
								caseData.caseManager
									? {
										value: caseData.caseManager.id,
										label: caseData.caseManager.name,
									}
									: undefined
							}
						/>

						<Dropdown
							title="common.location"
							name="ssgLocation"
							errorMessage={errors.ssgLocation?.message ?? ''}
							data={locList}
							innerRef={register}
							defaultValue={caseData.ssgLocation.id}
						/>

						<Dropdown
							title="common.department"
							name="ssgDepartment"
							errorMessage={errors.ssgDepartment?.message ?? ''}
							data={depList}
							innerRef={register}
							defaultValue={caseData.ssgDepartment.id}
						/>

						<div className="flex flex-col my-3 space-y-3">
							<Checkbox name="workComplete" title="case.workComplete" innerRef={register} defaultChecked={caseData.workComplete} />

							<Checkbox name="billingReady" title="case.billingReady" innerRef={register} defaultChecked={caseData.billingReady} />
						</div>

						<div className="flex flex-row items-center mt-3">
							<Button submit success text="case.editSSGInformation" loading={updateCaseSubmitting} />
						</div>
					</form>
				}
			/>
		</>
	);
};

export default SSGInformationBox;
