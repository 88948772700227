import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { loader } from 'graphql.macro';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
	GetWebCurrentUser,
	RemoveUserDrivingSlipOverviewFilter,
	RemoveUserDrivingSlipOverviewFilterVariables,
	SetUserDrivingSlipOverviewFilter,
	SetUserDrivingSlipOverviewFilterVariables,
	DrivingSlipStatus,
	GetCaseAdminDataPlanner,
	GetDrivingSlipsOverview,
	GetWebDebitors,
} from '../../GraphQL';
import UserContext from '../../UserContext';
import { DrivingSlipFilter } from './DrivingSlipOverview';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import CompleteDrivingSlipsModal from './CompleteDrivingSlipsModal';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { useForm } from 'react-hook-form';
import Pill from '@ssg/common/Components/Pill';

const GET_CASE_ADMIN_DATA_PLANNER = loader('src/GraphQL/Cases/GetCaseAdminDataPlanner.gql');
const SET_USER_DRIVING_SLIP_OVERVIEW_FILTER = loader('../../GraphQL/Users/SetUserDrivingSlipOverviewFilter.gql');
const REMOVE_USER_DRIVING_SLIP_OVERVIEW_FILTER = loader('../../GraphQL/Users/RemoveUserDrivingSlipOverviewFilter.gql');
const GET_CURRENT_USER = loader('../../GraphQL/Users/GetWebCurrentUser.gql');
const GET_DEBITORS = loader('../../GraphQL/Debitors/GetWebDebitors.gql');

interface Props {
	onFilter: React.Dispatch<React.SetStateAction<DrivingSlipFilter>>;
	activeFilters: DrivingSlipFilter;
	setFilterTerm: (v: string) => void;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserFilter: string;
	searchTerm: string;
	refetch: () => Promise<ApolloQueryResult<GetDrivingSlipsOverview>>;
}

const DrivingSlipOverviewFilter: React.FC<Props> = ({ onFilter, activeFilters, setFilterTerm, setActiveUserFilter, activeUserFilter, searchTerm, refetch }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const closeDrivingSlipsFlag = useFlag(FeatureFlagEnums.CLOSE_DRIVING_SLIPS);
	const priorityCustomer = useFlag(FeatureFlagEnums.PRIORITYCUSTOMER);

	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(activeFilters.name ?? '');

	const dummyForm = useForm();

	const { data: adminDataRaw } = useQuery<GetCaseAdminDataPlanner>(GET_CASE_ADMIN_DATA_PLANNER, { context: { debatch: true } });
	const adminData = React.useMemo(() => adminDataRaw ?? undefined, [adminDataRaw]);

	const [createUserFilter] = useMutation<SetUserDrivingSlipOverviewFilter, SetUserDrivingSlipOverviewFilterVariables>(SET_USER_DRIVING_SLIP_OVERVIEW_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserDrivingSlipOverviewFilter, RemoveUserDrivingSlipOverviewFilterVariables>(REMOVE_USER_DRIVING_SLIP_OVERVIEW_FILTER);

	useEffect(() => {
		setFilterSaveName(activeUserFilter);
	}, [activeUserFilter]);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					status: activeFilters.status,
					urgent: activeFilters.urgent,
					exceeded: activeFilters.exceeded,
					departments: activeFilters.departments ?? [],
					locations: activeFilters.locations ?? [],
					damageCauses: activeFilters.damageCauses ?? [],
					damageCategories: activeFilters.damageCategories ?? [],
					drivers: activeFilters.drivers ?? [],
					noDriver: activeFilters.noDriver,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const hasFilter = (filter: DrivingSlipFilter, search: string) => {
		if (filter.status) return true;
		if (filter.urgent) return true;
		if (filter.exceeded) return true;
		if (filter.departments) return true;
		if (filter.locations) return true;
		if (filter.damageCauses) return true;
		if (filter.damageCategories) return true;
		if ((filter.drivers ?? []).length > 0) return true;
		if (filter.minStartDate) return true;
		if (filter.maxStartDate) return true;
		if (filter.minEndDate) return true;
		if (filter.maxEndDate) return true;
		if (filter.noDriver) return true;
		if (search) return true;

		return false;
	};

	const postUserFilter = async (filterValues: SetUserDrivingSlipOverviewFilterVariables) => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserDrivingSlipOverviewFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.drivingSlipOverviewFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							drivingSlipOverviewFilters: [cachedRequest.currentUser?.drivingSlipOverviewFilters, data.setUserDrivingSlipOverviewFilter.drivingSlipOverviewFilters],
						},
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserDrivingSlipOverviewFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const debitorMapRef = React.useRef<Record<string, string>>({});
	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: { searchText: debitorSearchText },
		skip: debitorSearchText.length === 0,
		onCompleted(data) {
			data.debitors.forEach(d => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
	});

	const [departments, locations, damageCauses, damageCategories, drivers] = React.useMemo(() => {
		if (typeof adminData === 'undefined') {
			return [[], [], [], []];
		}

		const departments = adminData.departments
			.filter((d, i, a) => a.findIndex(ad => ad.id === d.id) === i)
			.map(d => ({
				value: d.id,
				label: `${d.name} (${d.departmentNumber})`,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		const locations = adminData.locations
			.filter((l, i, a) => a.findIndex(al => al.id === l.id) === i)
			.map(l => ({ value: l.id, label: l.name }))
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCauses = adminData.damageCauses
			.reduce(
				(allCauses, dc) => {
					let cause = allCauses.find(ac => ac.label === dc.name);
					if (typeof cause === 'undefined') {
						const index = allCauses.push({ label: dc.name, value: '' }) - 1;
						cause = allCauses[index];
					}

					cause.value += dc.id + ',';
					return allCauses;
				},
				[] as Array<{ label: string; value: string }>,
			)
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCategories = adminData.damageCategories.map(dc => ({ value: dc.id, label: dc.name })).sort((a, b) => a.label.localeCompare(b.label));

		const drivers = adminData.usersWithCalendars.map(d => ({
			value: d.id,
			label: d.name,
		}));

		return [departments, locations, damageCauses, damageCategories, drivers];
	}, [adminData]);

	const resetFilters = () => {
		onFilter({
			status: null,
			urgent: null,
			exceeded: null,
			departments: undefined,
			locations: undefined,
			damageCauses: undefined,
			damageCategories: undefined,
			drivers: undefined,
			debitors: undefined,
			minStartDate: undefined,
			maxStartDate: undefined,
			minEndDate: undefined,
			maxEndDate: undefined,
			noDriver: null,
			showPriorityCustomerCases: null,
		});
		setActiveUserFilter('');
	};

	const [showCloseModal, setShowCloseModal] = React.useState(false);
	return (
		<div>
			<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
				<div className="mb-3 mr-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.tableView')}</label>
					<ButtonGroup
						items={[
							{
								title: t('drivingSlips.filters.allDrivingSlips'),
								isActive: activeFilters.status === null,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: null,
									})),
							},
							{
								title: t('common.notPlanned'),
								isActive: activeFilters.status === 'UNPLANNED',
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: DrivingSlipStatus.UNPLANNED,
									})),
							},
							{
								title: t('drivingSlips.status.PLANNED'),
								isActive: activeFilters.status === 'PLANNED',
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: DrivingSlipStatus.PLANNED,
									})),
							},
							{
								title: t('drivingSlips.status.STARTED'),
								isActive: activeFilters.status === 'STARTED',
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: DrivingSlipStatus.STARTED,
									})),
							},
							{
								title: t('drivingSlips.status.ON_HOLD'),
								isActive: activeFilters.status === 'ON_HOLD',
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: DrivingSlipStatus.ON_HOLD,
									})),
							},
							{
								title: t('drivingSlips.status.COMPLETED'),
								isActive: activeFilters.status === 'COMPLETED',
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										status: DrivingSlipStatus.COMPLETED,
									})),
							},
						]}
					/>
				</div>

				<div className="mb-3 mr-3">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
					<div className="relative flex">
						<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
						<Input
							name="overview-search"
							placeholder={t('drivingSlips.filters.searchPlaceholder')}
							className="pr-8 text-sm"
							style={{ minWidth: '220px' }}
							onChange={(e): void => setFilterTerm(e.target.value)}
						/>
					</div>
				</div>

				<div className="mb-3 mr-6">
					<label className="text-blue mb-1 block text-xs font-medium">{t('common.quickFilter')}</label>
					<div className="flex">
						<Dropdown
							name="overview-quick-filters"
							onChange={e => {
								setActiveUserFilter(e.target.value);
								e.target.value !== ''
									? onFilter(currentFilters => ({
										...currentFilters,
										name: e.target.value,
									}))
									: resetFilters();
							}}
							data={[
								{
									value: '',
									label: t('caseOverview.filters.pickFilter'),
								},
								...(userContext.user?.drivingSlipOverviewFilters.map(f => ({
									value: f.name,
									label: f.name,
								})) ?? []),
							]}
							className="w-full pr-8 lg:w-1/2"
							style={{ minWidth: '220px', height: 34 }}
							value={activeUserFilter}
						/>

						<Button
							secondary={activeFilters.name !== '' || typeof activeFilters.name !== 'undefined'}
							disabled={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null}
							text={t('caseOverview.filters.deleteFilter')}
							onClick={() => setDeleteFilter(true)}
							className="ml-3 px-5 py-2 text-xs"
						/>

						<Button
							primary
							text={
								activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null
									? t('caseOverview.filters.saveFilter')
									: t('caseOverview.filters.updateFilter')
							}
							onClick={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
							className="ml-3 px-5 py-2 text-xs"
						/>

						<Button secondary text="common.resetFilter" className="ml-3 px-5 py-2 text-xs" onClick={resetFilters} />
						{closeDrivingSlipsFlag &&
							<Button secondary text="drivingSlips.complete" className="ml-3 text-xs" onClick={() => setShowCloseModal(true)} tooltip={!hasFilter(activeFilters, searchTerm) ? 'common.filterRequired' : undefined} disabled={!hasFilter(activeFilters, searchTerm)} />
						}
					</div>
				</div>

				<div className="mr-3 flex w-full flex-row flex-wrap">
					<div className="mr-3 mb-3">
						<label className="text-blue block text-xs font-medium">{t('common.urgent')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.urgent === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											urgent: activeFilters.urgent !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.urgent === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											urgent: activeFilters.urgent !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<div className="mr-3 mb-3">
						<label className="text-blue block text-xs font-medium">{t('common.overdue')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.exceeded === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											exceeded: activeFilters.exceeded !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.exceeded === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											exceeded: activeFilters.exceeded !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					{priorityCustomer &&
						<div className="mr-3 mb-3 whitespace-nowrap text-sm">
							<label className="text-blue block text-xs font-medium">{t('caseOverview.filters.priorityCustomer')}</label>
							<ButtonGroup
								unselectable
								items={[
									{
										title: t('common.yes'),
										isActive: activeFilters.showPriorityCustomerCases === true,
										onClick: (): void =>
											onFilter(currentFilters => ({
												...currentFilters,
												showPriorityCustomerCases: activeFilters.showPriorityCustomerCases !== true ? true : null,
											})),
									},
									{
										title: t('common.no'),
										isActive: activeFilters.showPriorityCustomerCases === false,
										onClick: (): void =>
											onFilter(currentFilters => ({
												...currentFilters,
												showPriorityCustomerCases: activeFilters.showPriorityCustomerCases !== false ? false : null,
											})),
									},
								]}
							/>
						</div>
					}

					<div className="mb-3">
						<label className="text-blue mr-3 mb-1 block text-xs font-medium">
							{t('common.debitor')}
							<SearchableSelect
								key="debitor-filter"
								control={dummyForm.control}
								name="debitorFilter"
								options={(debitorsData?.debitors ?? [])
									.filter(d => d.debitorId !== null)
									.filter((d, i, a) => a.indexOf(d) === i)
									.map(d => ({
										value: d.debitorId ?? '?',
										label: d.company,
									}))}
								searchFn={searchText => setDebitorSearchText(searchText)}
								onSelect={value => {
									if (value !== '')
										onFilter(currentFilters => ({
											...currentFilters,
											debitors: [...(activeFilters.debitors ?? []), value],
										}));
								}}
								onBlur={clearFn => clearFn()}
								minInputLength={2}
								isLoading={debitorsLoading}
								className="lg:w-full"
								style={{ height: '36px' }}
							/>
						</label>

						<ul className="text-xs">
							{activeFilters.debitors?.map(d => (
								<li key={d}>
									<Pill
										onClick={() =>
											onFilter(() => ({
												...activeFilters,
												debitors: activeFilters.debitors?.filter(ed => ed !== d),
											}))
										}
									>
										{debitorMapRef.current[d]} ({d})
									</Pill>
								</li>
							))}
						</ul>
					</div>
				</div>

				<div className="flex w-full flex-row flex-wrap">
					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.department')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.departments?.map(v => {
									const d = adminData?.departments?.find(department => department.id === v);
									return {
										value: d?.id ?? '',
										label: d?.name ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values => {
								onFilter(currentFilters => ({
									...currentFilters,
									departments: values?.map(v => v.value) ?? [],
								}));
							}}
							options={departments}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.location')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.locations?.map(v => {
									const l = adminData?.locations?.find(location => location.id === v);
									return {
										value: l?.id ?? '',
										label: l?.name ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								onFilter(currentFilters => ({
									...currentFilters,
									locations: values?.map(v => v.value) ?? [],
								}))
							}
							options={locations}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('common.damageCategory')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.damageCategories?.map(v => {
									const l = adminData?.damageCategories?.find(dc => dc.id === v);
									return {
										value: l?.id ?? '',
										label: l?.name ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								onFilter(currentFilters => ({
									...currentFilters,
									damageCategories: values?.map(v => v.value) ?? [],
								}))
							}
							options={damageCategories}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('case.damageCause')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.damageCauses
									?.map(v => {
										const l = damageCauses.find(dc => dc.value.includes(v));
										return {
											value: l?.value ?? '',
											label: l?.label ?? '',
										};
									})
									.filter((v, i, a) => a.findIndex(av => av.value === v.value) === i) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								onFilter(currentFilters => ({
									...currentFilters,
									damageCauses: values?.flatMap(v => v.value.split(',').filter(v => v !== '')) ?? [],
								}))
							}
							options={damageCauses}
						/>
					</label>

					<label className="text-blue mr-3 block text-xs font-medium">
						{t('drivingSlips.driver')}
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-40 text-xs"
							value={
								activeFilters.drivers?.map(v => {
									const d = adminData?.usersWithCalendars?.find(driver => driver.id === v);
									return {
										value: d?.id ?? '',
										label: d?.name ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								onFilter(currentFilters => ({
									...currentFilters,
									drivers: values?.map(v => v.value) ?? [],
								}))
							}
							options={drivers}
						/>
					</label>

					<div className="mr-3 mb-3">
						<label className="text-blue block text-xs font-medium">{t('drivingSlips.filters.noDriver')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.noDriver === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											noDriver: activeFilters.noDriver !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.noDriver === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											noDriver: activeFilters.noDriver !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<label className="text-blue mr-3 mb-3 text-xs font-medium">
						{t('drivingSlips.plannedStart')}
						<div className="flex items-center">
							<input
								type="date"
								value={formatDateForInput(new Date(activeFilters.minStartDate ?? ''))}
								onChange={e => {
									const minStartDate = e.target.valueAsDate;
									if (minStartDate === null || typeof minStartDate === 'undefined') {
										return;
									}
									minStartDate.setHours(0, 0, 0, 0);

									onFilter(currentFilters => ({
										...currentFilters,
										minStartDate,
									}));

									if (typeof activeFilters.maxStartDate === 'undefined') return;

									if (minStartDate > activeFilters.maxStartDate) {
										const maxStartDate = new Date(minStartDate);
										maxStartDate.setHours(23, 59, 59, 999);
										onFilter(currentFilters => ({
											...currentFilters,
											maxStartDate,
										}));
									}
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							<span className="px-1">&mdash;</span>
							<input
								type="date"
								min={formatDateForInput(new Date(activeFilters.minStartDate ?? ''))}
								value={formatDateForInput(new Date(activeFilters.maxStartDate ?? ''))}
								onChange={e => {
									const maxStartDate = e.target.valueAsDate;
									if (maxStartDate === null) {
										return;
									}
									maxStartDate.setHours(23, 59, 59, 999);

									onFilter(currentFilters => ({
										...currentFilters,
										maxStartDate,
									}));
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
						</div>
					</label>

					<label className="text-blue mr-3 mb-3 text-xs font-medium">
						{t('drivingSlips.plannedEnd')}
						<div className="flex items-center">
							<input
								type="date"
								value={formatDateForInput(new Date(activeFilters.minEndDate ?? ''))}
								onChange={e => {
									const minEndDate = e.target.valueAsDate;
									if (minEndDate === null || typeof minEndDate === 'undefined') {
										return;
									}
									minEndDate.setHours(0, 0, 0, 0);

									onFilter(currentFilters => ({
										...currentFilters,
										minEndDate,
									}));

									if (typeof activeFilters.maxEndDate === 'undefined') return;

									if (minEndDate > activeFilters.maxEndDate) {
										const maxEndDate = new Date(minEndDate);
										maxEndDate.setHours(23, 59, 59, 999);
										onFilter(currentFilters => ({
											...currentFilters,
											maxEndDate,
										}));
									}
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							<span className="px-1">&mdash;</span>
							<input
								type="date"
								min={formatDateForInput(new Date(activeFilters.minEndDate ?? ''))}
								value={formatDateForInput(new Date(activeFilters.maxEndDate ?? ''))}
								onChange={e => {
									const maxEndDate = e.target.valueAsDate;
									if (maxEndDate === null) {
										return;
									}
									maxEndDate.setHours(23, 59, 59, 999);

									onFilter(currentFilters => ({
										...currentFilters,
										maxEndDate,
									}));
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
						</div>
					</label>
				</div>
			</div>

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('common.save')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeFilters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeFilters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
			{
				showCloseModal &&
				<CompleteDrivingSlipsModal refetchDrivingSlips={refetch} close={() => setShowCloseModal(false)} visible={showCloseModal} activeFilters={activeFilters} searchTerm={searchTerm} />
			}
		</div>
	);
};

export default DrivingSlipOverviewFilter;
