import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { Placement } from '@popperjs/core';
import Button from '@ssg/common/Components/Button';
import Popover from '@ssg/common/Components/Popover';
import React from 'react';
import ComponentToPrint from './ComponentToPrint';
import { GetMovableQuery, useGetMovableQuery } from '@ssg/common/GraphQL/indexV2';

interface PropsBase {
	popoverPlacement: Placement;
	callback?: () => unknown;
	className?: string;
}

interface PropsObject extends PropsBase {
	movable: GetMovableQuery['movable'];
}

interface PropsId extends PropsBase {
	movableId: GetMovableQuery['movable']['id'];
}

export const movableLabelColors = {
	'movable.labels.gdprValuables': {
		qrColor: '#FCD361',
		qrColorText: '#FFFFFF',
	},
	'movable.labels.discarded': { qrColor: '#D34E65', qrColorText: '#FFFFFF' },
	'movable.labels.storage': { qrColor: '#52D28F', qrColorText: '#FFFFFF' },
	'movable.labels.washing': { qrColor: '#FFFFFF', qrColorText: '#000000' },
	'movable.labels.cleaning': { qrColor: '#64B2FF', qrColorText: '#FFFFFF' },
	'movable.labels.woodworker': { qrColor: '#BA5BEF', qrColorText: '#FFFFFF' },
	'movable.labels.clothesCleaning': {
		qrColor: '#EB9459',
		qrColorText: '#FFFFFF',
	},
	'common.subcontractor': { qrColor: '#000000', qrColorText: '#FFFFFF' },
};

type Props = PropsObject | PropsId;

const MovableReprint: React.FC<Props> = ({ popoverPlacement, callback, className, ...restProps }) => {
	const { data } = useGetMovableQuery({
		variables: {
			id: 'movableId' in restProps ? restProps.movableId : 'error',
		},
		skip: !('movableId' in restProps),
	});

	const [movable, setMovable] = React.useState(() => {
		if ('movable' in restProps) {
			return restProps.movable;
		}
	});
	React.useEffect(() => {
		if (typeof data !== 'undefined') {
			setMovable(data.movable);
		}
	}, [data]);

	const button = (
		<div className="inline-block">
			<Button icon={faPrint} className={className} />
		</div>
	);

	if (typeof movable === 'undefined') {
		return button;
	}

	return (
		<Popover
			interactive
			clickTrigger
			hideOnClick
			placement={popoverPlacement}
			content={
				<div className="border-1 space-y-2 rounded-md border-gray-500 bg-white p-2 shadow-sm">
					<ComponentToPrint
						movable={movable}
						className="border-1 bg-yellow w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.gdprValuables"
						{...movableLabelColors['movable.labels.gdprValuables']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 bg-red-calm w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.discarded"
						{...movableLabelColors['movable.labels.discarded']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 bg-green w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.storage"
						{...movableLabelColors['movable.labels.storage']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 w-full border-gray-300 bg-white px-4 py-2 text-black"
						qrText="movable.labels.washing"
						{...movableLabelColors['movable.labels.washing']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 bg-blue-lightcalm w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.cleaning"
						{...movableLabelColors['movable.labels.cleaning']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 bg-purple-calm w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.woodworker"
						{...movableLabelColors['movable.labels.woodworker']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 bg-orange-calm w-full border-transparent px-4 py-2 text-white"
						qrText="movable.labels.clothesCleaning"
						{...movableLabelColors['movable.labels.clothesCleaning']}
					/>

					<ComponentToPrint
						movable={movable}
						className="border-1 w-full border-transparent bg-black px-4 py-2 text-white"
						qrText="common.subcontractor"
						{...movableLabelColors['common.subcontractor']}
					/>
				</div>
			}
			onHide={() => callback?.()}
		>
			{button}
		</Popover>
	);
};
export default MovableReprint;
