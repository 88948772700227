import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
	AddressFragment,
	CaseStatus,
	GetWebCase_case_changes,
	GetWebCase_case_changes_after_visitation_answers,
	GetWebCase_case_changes_before_visitation_answers,
	GetQuestions,
	GetQuestions_questions,
} from '../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import CaseDrivingSlipChangeLog from './CaseDrivingSlipChangeLog';
import _ from 'lodash';
import CaseFilesChangeLog from './CaseFilesChangeLog';

const GET_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetQuestions.gql');

interface Props {
	version: number;
	changes: GetWebCase_case_changes[];
	visible: boolean;
	close(): unknown;
}

const CaseChangeLog: React.FC<Props> = ({ version, changes, visible, close }) => {
	const { t } = useTranslation();

	const { data: questions } = useQuery<GetQuestions>(GET_QUESTIONS, {
		fetchPolicy: 'cache-and-network',
	});

	const sorted = React.useMemo(() => changes.slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [changes]);

	return (
		<Modal
			title={t('case.caseChanges')}
			size={ModalSize.MEDIUM}
			visible={visible}
			close={close}
			body={
				<div className="text-blue space-y-4">
					{sorted.map(c => (
						<div key={c.timestamp}>
							<strong>
								{dateToDateTimeString(c.timestamp)}
								&nbsp;{t('common.by')}&nbsp;
								{c.user?.name}&nbsp;({c.user?.email})
							</strong>

							<div className="mt-1">{renderChanges(t, c.before, c.after, questions?.questions ?? [])}</div>
						</div>
					))}
				</div>
			}
		/>
	);
};
export default CaseChangeLog;

function renderChanges(t: TFunction, before: Props['changes'][number]['before'], after: Props['changes'][number]['after'], questions: GetQuestions_questions[]): React.ReactElement[] {
	const NO_VALUE = '\u00A0\u2014\u00A0';
	const result: React.ReactElement[] = [];

	// Status was changed
	if (!(before.status === CaseStatus._NULL_ && after.status === CaseStatus._NULL_) && before.status !== after.status) {
		const tValues = {
			before: before.status !== CaseStatus._NULL_ ? t(`case.${before.status}`) : NO_VALUE,
			after: after.status !== CaseStatus._NULL_ ? t(`case.${after.status}`) : NO_VALUE,
		};

		result.push(
			<div key="status" className="my-2">
				<Trans t={t} i18nKey="case.changelog.status" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Track was changed
	if (!(before.track === null && after.track === null)) {
		if (before.track !== after.track) {
			const tValues = {
				before: before.track,
				after: after.track,
			};

			result.push(
				<div key="track" className="my-2">
					<Trans t={t} i18nKey="case.changelog.track" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Debitor was changed
	if (!(before.debitor === null && after.debitor === null)) {
		if (before.debitor?.debitorId !== after.debitor?.debitorId) {
			const tValues = {
				before: before.debitor !== null ? t(before.debitor.debitorId) : NO_VALUE,
				after: after.debitor !== null ? t(after.debitor.debitorId) : NO_VALUE,
			};

			result.push(
				<div key="debitor-debitorId" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.debitorId" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.debitor?.type !== after.debitor?.type) {
			const tValues = {
				before: before.debitor !== null ? t(before.debitor.type) : NO_VALUE,
				after: after.debitor !== null ? t(after.debitor.type) : NO_VALUE,
			};

			result.push(
				<div key="debitor-type" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.type" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.debitor?.attention !== after.debitor?.attention) {
			const tValues = {
				before: before.debitor?.attention ?? NO_VALUE,
				after: after.debitor?.attention ?? NO_VALUE,
			};

			result.push(
				<div key="debitor-attention" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.attention" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (
			before.debitor?.billingAddress?.road !== after.debitor?.billingAddress?.road ||
			before.debitor?.billingAddress?.houseNumber !== after.debitor?.billingAddress?.houseNumber ||
			before.debitor?.billingAddress?.floor !== after.debitor?.billingAddress?.floor ||
			before.debitor?.billingAddress?.addressLineAlt !== after.debitor?.billingAddress?.addressLineAlt ||
			before.debitor?.billingAddress?.postalCode !== after.debitor?.billingAddress?.postalCode ||
			before.debitor?.billingAddress?.city !== after.debitor?.billingAddress?.city
		) {
			const tValues = {
				before: before.debitor !== null && before.debitor?.billingAddress !== null ? getAddressString(before.debitor.billingAddress) : NO_VALUE,
				after: after.debitor !== null && after.debitor?.billingAddress !== null ? getAddressString(after.debitor.billingAddress) : NO_VALUE,
			};

			result.push(
				<div key="debitor-billingAddress" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.billingAddress" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.debitor?.excess !== after.debitor?.excess) {
			const tValues = {
				before: before.debitor?.excess ?? NO_VALUE,
				after: after.debitor?.excess ?? NO_VALUE,
			};

			result.push(
				<div key="debitor-excess" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.excess" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.debitor?.policeNumber !== after.debitor?.policeNumber) {
			const tValues = {
				before: before.debitor?.policeNumber ?? NO_VALUE,
				after: after.debitor?.policeNumber ?? NO_VALUE,
			};

			result.push(
				<div key="debitor-policeNumber" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.policeNumber" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.debitor?.cvrNumber !== after.debitor?.cvrNumber) {
			const tValues = {
				before: before.debitor?.cvrNumber ?? NO_VALUE,
				after: after.debitor?.cvrNumber ?? NO_VALUE,
			};

			result.push(
				<div key="debitor-cvrNumber" className="my-2">
					<Trans t={t} i18nKey="case.changelog.debitor.cvrNumber" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Damage was changed
	if (!(before.damage === null && after.damage === null)) {
		if (before.damage?.businessArea !== after.damage?.businessArea) {
			const tValues = {
				before: before.damage?.businessArea.name ?? NO_VALUE,
				after: after.damage?.businessArea.name ?? NO_VALUE,
			};

			result.push(
				<div key="damage-businessArea" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.businessArea" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.category !== after.damage?.category) {
			const tValues = {
				before: before.damage?.category.name ?? NO_VALUE,
				after: after.damage?.category.name ?? NO_VALUE,
			};

			result.push(
				<div key="damage-category" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.category" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.cause !== after.damage?.cause) {
			const tValues = {
				before: before.damage?.cause.name ?? NO_VALUE,
				after: after.damage?.cause.name ?? NO_VALUE,
			};

			result.push(
				<div key="damage-cause" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.cause" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.date !== after.damage?.date) {
			const tValues = {
				before: typeof before.damage?.date === 'string' ? formatDate(new Date(before.damage?.date)) : NO_VALUE,
				after: typeof after.damage?.date === 'string' ? formatDate(new Date(after.damage?.date)) : NO_VALUE,
			};

			result.push(
				<div key="damage-date" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.date" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.description !== after.damage?.description) {
			const tValues = {
				before: before.damage?.description ?? NO_VALUE,
				after: after.damage?.description ?? NO_VALUE,
			};

			result.push(
				<div key="damage-description" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.description" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.contact.name !== after.damage?.contact.name) {
			const tValues = {
				before: before.damage?.contact.name ?? NO_VALUE,
				after: after.damage?.contact.name ?? NO_VALUE,
			};

			result.push(
				<div key="damage-contact-name" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.contact.name" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.contact.email !== after.damage?.contact.email) {
			const tValues = {
				before: before.damage?.contact.email ?? NO_VALUE,
				after: after.damage?.contact.email ?? NO_VALUE,
			};

			result.push(
				<div key="damage-contact-email" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.contact.email" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.contact.phone !== after.damage?.contact.phone) {
			const tValues = {
				before: before.damage?.contact.phone ?? NO_VALUE,
				after: after.damage?.contact.phone ?? NO_VALUE,
			};

			result.push(
				<div key="damage-contact-phone" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.contact.phone" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.accessConditions !== after.damage?.accessConditions) {
			const tValues = {
				before: before.damage?.accessConditions ?? NO_VALUE,
				after: after.damage?.accessConditions ?? NO_VALUE,
			};

			result.push(
				<div key="damage-access-conditions" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.accessConditions" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (
			before.damage?.contact.address.road !== after.damage?.contact.address.road ||
			before.damage?.contact.address.houseNumber !== after.damage?.contact.address.houseNumber ||
			before.damage?.contact.address.floor !== after.damage?.contact.address.floor ||
			before.damage?.contact.address.addressLineAlt !== after.damage?.contact.address.addressLineAlt ||
			before.damage?.contact.address.postalCode !== after.damage?.contact.address.postalCode ||
			before.damage?.contact.address.city !== after.damage?.contact.address.city
		) {
			const tValues = {
				before: before.damage !== null ? getAddressString(before.damage.contact.address) : NO_VALUE,
				after: after.damage !== null ? getAddressString(after.damage.contact.address) : NO_VALUE,
			};

			result.push(
				<div key="damage-contact-address" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.contact.address" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.damage?.contacts !== after.damage?.contacts) {
			const tValues = {
				before: before.damage?.contacts.map(bef => `${bef.name}, ${bef.role}${bef.phone ?? `, ${bef.phone}`}${bef.email ?? `, ${bef.email}`}`).join(', ') ?? NO_VALUE,
				after: after.damage?.contacts.map(aft => `${aft.name}, ${aft.role}${aft.phone ?? `, ${aft.phone}`}${aft.email ?? `, ${aft.email}`}`).join(', ') ?? NO_VALUE,
			};

			result.push(
				<div key="damage-contacts" className="my-2">
					<Trans t={t} i18nKey="case.changelog.damage.contacts" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Policy holder was changed
	if (!(before.policyHolder === null && after.policyHolder === null)) {
		if (before.policyHolder?.name !== after.policyHolder?.name) {
			const tValues = {
				before: before.policyHolder?.name ?? NO_VALUE,
				after: after.policyHolder?.name ?? NO_VALUE,
			};

			result.push(
				<div key="policyHolder-name" className="my-2">
					<Trans t={t} i18nKey="case.changelog.policyHolder.name" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.policyHolder?.phone !== after.policyHolder?.phone) {
			const tValues = {
				before: before.policyHolder?.phone ?? NO_VALUE,
				after: after.policyHolder?.phone ?? NO_VALUE,
			};

			result.push(
				<div key="policyHolder-phone" className="my-2">
					<Trans t={t} i18nKey="case.changelog.policyHolder.phone" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.policyHolder?.email !== after.policyHolder?.email) {
			const tValues = {
				before: before.policyHolder?.email ?? NO_VALUE,
				after: after.policyHolder?.email ?? NO_VALUE,
			};

			result.push(
				<div key="policyHolder-email" className="my-2">
					<Trans t={t} i18nKey="case.changelog.policyHolder.email" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.policyHolder?.address !== after.policyHolder?.address) {
			const tValues = {
				before: before.policyHolder !== null ? getAddressString(before.policyHolder.address) : NO_VALUE,
				after: after.policyHolder !== null ? getAddressString(after.policyHolder.address) : NO_VALUE,
			};

			result.push(
				<div key="policyHolder-address" className="my-2">
					<Trans t={t} i18nKey="case.changelog.policyHolder.address" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Visitation was changed
	if (!(before.visitation === null && after.visitation === null)) {
		if (before.visitation?.urgent !== after.visitation?.urgent) {
			const tValues = {
				before: typeof before.visitation?.urgent === 'boolean' ? t(`common.${getBoolString(before.visitation.urgent)}`) : NO_VALUE,
				after: typeof after.visitation?.urgent === 'boolean' ? t(`common.${getBoolString(after.visitation.urgent)}`) : NO_VALUE,
			};

			result.push(
				<div key="visitation-urgent" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.urgent" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (!isArrayEqual(before.visitation?.answers ?? [], after.visitation?.answers ?? [])) {
			const tValues = {
				before:
					before.visitation !== null && before.visitation.answers
						? before.visitation.answers
								.map(
									bef =>
										`${
											!questions.find(q => q.id === bef.question)?.question
												? t('common.questionRemoved')
												: `${questions.find(q => q.id === bef.question)?.question} ${bef.answer ? bef.answer : t('common.notSpecified')}`
										}`,
								)
								.join(', ')
						: NO_VALUE,
				after:
					after.visitation !== null && after.visitation.answers
						? after.visitation.answers
								.map(
									aft =>
										`${
											!questions.find(q => q.id === aft.question)?.question
												? t('common.questionRemoved')
												: `${questions.find(q => q.id === aft.question)?.question} ${aft.answer ? aft.answer : t('common.notSpecified')}`
										}`,
								)
								.join(', ')
						: NO_VALUE,
			};

			result.push(
				<div key="visitation-answers" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.answers" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (
			!(before.visitation?.priorities ?? []).every(bp => after.visitation?.priorities.includes(bp) ?? false) ||
			!(after.visitation?.priorities ?? []).every(ap => before.visitation?.priorities.includes(ap) ?? false)
		) {
			const tValues = {
				before: before.visitation !== null && before.visitation.priorities.length > 0 ? before.visitation.priorities.map(p => t(`case.${p}`)).join(', ') : NO_VALUE,
				after: after.visitation !== null && after.visitation.priorities.length > 0 ? after.visitation.priorities.map(p => t(`case.${p}`)).join(', ') : NO_VALUE,
			};

			result.push(
				<div key="visitation-priorities" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.priorities" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.visitation?.regress !== after.visitation?.regress) {
			const tValues = {
				before: typeof before.visitation?.regress === 'boolean' ? t(`common.${getBoolString(before.visitation.regress)}`) : NO_VALUE,
				after: typeof after.visitation?.regress === 'boolean' ? t(`common.${getBoolString(after.visitation.regress)}`) : NO_VALUE,
			};

			result.push(
				<div key="visitation-regress" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.regress" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.visitation?.calledBack.value !== after.visitation?.calledBack.value || before.visitation?.calledBack.comment !== after.visitation?.calledBack.comment) {
			const tValues = {
				beforeValue: typeof before.visitation?.calledBack.value === 'boolean' ? t(`common.${getBoolString(before.visitation.calledBack.value)}`) : NO_VALUE,
				beforeComment: before.visitation?.calledBack.comment ?? NO_VALUE,
				afterValue: typeof after.visitation?.calledBack.value === 'boolean' ? t(`common.${getBoolString(after.visitation.calledBack.value)}`) : NO_VALUE,
				afterComment: after.visitation?.calledBack.comment ?? NO_VALUE,
			};

			result.push(
				<div key="visitation-calledBack" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.calledBack" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.visitation?.awaiting.value !== after.visitation?.awaiting.value || before.visitation?.awaiting.comment !== after.visitation?.awaiting.comment) {
			const tValues = {
				beforeValue: typeof before.visitation?.awaiting.value === 'boolean' ? t(`common.${getBoolString(before.visitation.awaiting.value)}`) : NO_VALUE,
				beforeComment: before.visitation?.awaiting.comment ?? NO_VALUE,
				afterValue: typeof after.visitation?.awaiting.value === 'boolean' ? t(`common.${getBoolString(after.visitation.awaiting.value)}`) : NO_VALUE,
				afterComment: after.visitation?.awaiting.comment ?? NO_VALUE,
			};

			result.push(
				<div key="visitation-awaiting" className="my-2">
					<Trans t={t} i18nKey="case.changelog.visitation.awaiting" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Requisitioner was changed
	if (!(before.requisitioner === null && after.requisitioner === null)) {
		if (before.requisitioner?.name !== after.requisitioner?.name) {
			const tValues = {
				before: before.requisitioner?.name ?? NO_VALUE,
				after: after.requisitioner?.name ?? NO_VALUE,
			};

			result.push(
				<div key="requisitioner-name" className="my-2">
					<Trans t={t} i18nKey="case.changelog.requisitioner.name" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.requisitioner?.phone !== after.requisitioner?.phone) {
			const tValues = {
				before: before.requisitioner?.phone ?? NO_VALUE,
				after: after.requisitioner?.phone ?? NO_VALUE,
			};

			result.push(
				<div key="requisitioner-phone" className="my-2">
					<Trans t={t} i18nKey="case.changelog.requisitioner.phone" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.requisitioner?.email !== after.requisitioner?.email) {
			const tValues = {
				before: before.requisitioner?.email ?? NO_VALUE,
				after: after.requisitioner?.email ?? NO_VALUE,
			};

			result.push(
				<div key="requisitioner-email" className="my-2">
					<Trans t={t} i18nKey="case.changelog.requisitioner.email" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.requisitioner?.relation !== after.requisitioner?.relation) {
			const tValues = {
				before: before.requisitioner?.relation ?? NO_VALUE,
				after: after.requisitioner?.relation ?? NO_VALUE,
			};

			result.push(
				<div key="requisitioner-relation" className="my-2">
					<Trans t={t} i18nKey="case.changelog.requisitioner.relation" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Project manager was changed
	if (!(before.projectManager === null && after.projectManager === null) && before.projectManager?.id !== after.projectManager?.id) {
		const tValues = {
			before: before.projectManager?.name ?? NO_VALUE,
			after: after.projectManager?.name ?? NO_VALUE,
		};

		result.push(
			<div key="projectManager" className="my-2">
				<Trans t={t} i18nKey="case.changelog.projectManager" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Case manager was changed
	if (!(before.caseManager === null && after.caseManager === null) && before.caseManager?.id !== after.caseManager?.id) {
		const tValues = {
			before: before.caseManager?.name ?? NO_VALUE,
			after: after.caseManager?.name ?? NO_VALUE,
		};

		result.push(
			<div key="caseManager" className="my-2">
				<Trans t={t} i18nKey="case.changelog.caseManager" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// SSG location was changed
	if (!(before.ssgLocation === null && after.ssgLocation === null) && before.ssgLocation !== after.ssgLocation) {
		const tValues = {
			before: before.ssgLocation?.name ?? NO_VALUE,
			after: after.ssgLocation?.name ?? NO_VALUE,
		};

		result.push(
			<div key="ssgLocation" className="my-2">
				<Trans t={t} i18nKey="case.changelog.ssgLocation" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// SSG department was changed
	if (!(before.ssgDepartment === null && after.ssgDepartment === null) && before.ssgDepartment !== after.ssgDepartment) {
		const tValues = {
			before: before.ssgDepartment?.name ?? NO_VALUE,
			after: after.ssgDepartment?.name ?? NO_VALUE,
		};

		result.push(
			<div key="ssgDepartment" className="my-2">
				<Trans t={t} i18nKey="case.changelog.ssgDepartment" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Case emails was changed
	if (!(before.caseEmails === null && after.caseEmails === null) && before.caseEmails?.length !== after.caseEmails?.length) {
		// Find the case emails that are only in the "after" array
		const afterAddedCaseEmails =
			after.caseEmails
				?.filter(ace => !before.caseEmails?.some(bce => ace.timestamp === bce.timestamp && ace.content === bce.content))
				.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()) ?? [];
		afterAddedCaseEmails.forEach(ce => {
			const tValues = {
				timestamp: dateToDateTimeString(ce.timestamp),
			};

			result.push(
				// Add components={[<mark />]} to Trans component if something needs to be highlighted in the future
				<div key={`caseEmails-${ce.timestamp}`} className="my-2">
					<Trans t={t} i18nKey="case.changelog.caseEmail" values={tValues} />
				</div>,
			);
		});
	}

	// Case machine usages was changed
	if (!(before.caseMachineUsages === null && after.caseMachineUsages === null) && before.caseMachineUsages?.length !== after.caseMachineUsages?.length) {
		const tValues = {};

		result.push(
			<div key="caseMachineUsages" className="my-2">
				<Trans t={t} i18nKey="case.changelog.caseMachineUsage" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Important info was changed
	if (!(before.importantInfo === null && after.importantInfo === null) && before.importantInfo !== after.importantInfo) {
		const tValues = {
			before: before.importantInfo ?? NO_VALUE,
			after: after.importantInfo ?? NO_VALUE,
		};

		result.push(
			<div key="importantInfo" className="my-2">
				<Trans t={t} i18nKey="case.changelog.importantInfo" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// SKAFOR was changed
	if (!(before.skafor === null && after.skafor === null) && before.skafor !== after.skafor) {
		const tValues = {
			before: typeof before.skafor === 'boolean' ? t(`common.${getBoolString(before.skafor)}`) : NO_VALUE,
			after: typeof after.skafor === 'boolean' ? t(`common.${getBoolString(after.skafor)}`) : NO_VALUE,
		};
		result.push(
			<div key="skafor" className="my-2">
				<Trans t={t} i18nKey="case.changelog.skafor" values={tValues} components={[<mark />]} />
			</div>,
		);
	}

	// Alternative contact was changed
	if (!(before.alternativeContact === null && after.alternativeContact === null)) {
		if (before.alternativeContact?.comment !== after.alternativeContact?.comment) {
			const tValues = {
				before: before.alternativeContact?.comment ?? NO_VALUE,
				after: after.alternativeContact?.comment ?? NO_VALUE,
			};

			result.push(
				<div key="alternativeContact-comment" className="my-2">
					<Trans t={t} i18nKey="case.changelog.alternativeContact.comment" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.alternativeContact?.name !== after.alternativeContact?.name) {
			const tValues = {
				before: before.alternativeContact?.name ?? NO_VALUE,
				after: after.alternativeContact?.name ?? NO_VALUE,
			};

			result.push(
				<div key="alternativeContact-name" className="my-2">
					<Trans t={t} i18nKey="case.changelog.alternativeContact.name" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.alternativeContact?.email !== after.alternativeContact?.email) {
			const tValues = {
				before: before.alternativeContact?.email ?? NO_VALUE,
				after: after.alternativeContact?.email ?? NO_VALUE,
			};

			result.push(
				<div key="alternativeContact-email" className="my-2">
					<Trans t={t} i18nKey="case.changelog.alternativeContact.email" values={tValues} components={[<mark />]} />
				</div>,
			);
		}

		if (before.alternativeContact?.phone !== after.alternativeContact?.phone) {
			const tValues = {
				before: before.alternativeContact?.phone ?? NO_VALUE,
				after: after.alternativeContact?.phone ?? NO_VALUE,
			};

			result.push(
				<div key="alternativeContact-phone" className="my-2">
					<Trans t={t} i18nKey="case.changelog.alternativeContact.phone" values={tValues} components={[<mark />]} />
				</div>,
			);
		}
	}

	// Driving slip was changed
	if (!(before.drivingSlips === null && after.drivingSlips === null)) {
		result.push(
			<div key="drivingSlips" className="my-2">
				<CaseDrivingSlipChangeLog before={before.drivingSlips} after={after.drivingSlips} />
			</div>,
		);
	}

	// Risk evaluation was changed
	if (!(before.riskEvaluationAnswers === null && after.riskEvaluationAnswers === null)) {
		const afterRiskEvaluation = after.riskEvaluationAnswers?.filter(a => before.riskEvaluationAnswers?.some(b => a.question.id === b.question.id && a.answer !== b.answer));
		afterRiskEvaluation?.forEach(re => {
			const tValues = {
				question: re.question.question,
				before: t(`common.${getBoolString(!re.answer)}`),
				after: t(`common.${getBoolString(re.answer)}`),
			};

			result.push(
				<div key="riskEvaluation" className="my-2">
					<Trans t={t} i18nKey="case.changelog.riskEvaluation" values={tValues} components={[<mark />]} />
				</div>,
			);
		});
	}

	// Files was changed
	if (before.fileNames !== null || after.fileNames !== null) {
		result.push(
			<div key="filenames" className="my-2">
				<CaseFilesChangeLog before={before.fileNames} after={after.fileNames} />
			</div>,
		);
	}

	return result;
}

function getAddressString(address: AddressFragment): string {
	const addressLineAltPart = typeof address.addressLineAlt === 'string' && address.addressLineAlt.length > 0 ? `, ${address.addressLineAlt}` : '';

	return `${address.road} ${address.houseNumber} ${address.floor ?? ''}${addressLineAltPart}, ${address.postalCode} ${address.city}`;
}

function getBoolString(bool: boolean): string {
	return bool ? 'yes' : 'no';
}

const isArrayEqual = function (x: GetWebCase_case_changes_before_visitation_answers[], y: GetWebCase_case_changes_after_visitation_answers[]) {
	const isSameSize = _.size(x) === _.size(y);
	return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty();
};
