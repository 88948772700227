import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldHeader from '../../../../Components/Forms/FormFieldHeader';
import { MoistureMeassurementInput } from '../../../../GraphQL';

interface Props {
	meassurements: MoistureMeassurementInput[];
	onChange(meassurements: MoistureMeassurementInput[]): void;
}

const EMPTY_ROW = (): MoistureMeassurementInput => ({
	value: '',
	construction: '',
	controlValue: '',
	description: '',
	expectedFinalValue: '',
	material: '',
	method: '',
	referenceValue: '',
	referenceDate: '',
	controlDate: '',
});

const TABLE_COLUMNS: Array<keyof MoistureMeassurementInput> = [
	'construction',
	'material',
	'description',
	'method',
	'value',
	'expectedFinalValue',
	'referenceValue',
	'referenceDate',
	'controlValue',
	'controlDate',
];

const MoistureMeassurementForm: React.FC<Props> = ({ meassurements, onChange }) => {
	const { t } = useTranslation();

	function handleChange(idx: number, column: keyof MoistureMeassurementInput, value: string) {
		const copy = meassurements.map(m => ({ ...m }));
		copy[idx][column] = value;
		onChange(copy);
	}

	function addRow() {
		onChange([...meassurements, EMPTY_ROW()]);
	}

	function removeRow(idx: number) {
		onChange(meassurements.filter((_, i) => i !== idx));
	}

	return (
		<div className="w-full">
			<FormFieldHeader title={t('reports.moistureMeassurement.title')} />

			<div className="flex gap-2">
				{TABLE_COLUMNS.map(column => (
					<div className="flex-1">
						<FormFieldHeader title={t(`reports.moistureMeassurement.${column}`)} />
					</div>
				))}
			</div>

			{meassurements.map((meassurement, idx) => (
				<div className="flex gap-2 py-2" key={idx}>
					{TABLE_COLUMNS.map((column, i) => (
						<div className="flex flex-1">
							<Input name={column} value={meassurement[column] ?? ''} onChange={e => handleChange(idx, column, e.target.value)} />
							{i === TABLE_COLUMNS.length - 1 && (
								<p className="text-red cursor-pointer pl-2 pt-2 text-xl font-bold" onClick={() => removeRow(idx)}>
									x
								</p>
							)}
						</div>
					))}
				</div>
			))}

			<Button className="mt-2" onClick={addRow} primary>
				{t('reports.addMoistureMeassurement')}
			</Button>
		</div>
	);
};

export default MoistureMeassurementForm;
