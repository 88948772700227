import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AirMeassurementDetailsInput } from '../../../../GraphQL';
import NumberInput from './NumberInput';

interface Props {
	meassurements: AirMeassurementDetailsInput;
	onChange(meassurements: AirMeassurementDetailsInput): void;
}

const AirMeassurementDetailsForm: React.FC<Props> = ({ meassurements, onChange }) => {
	const { t } = useTranslation();

	function handleChange(updateFn: (copy: AirMeassurementDetailsInput) => void) {
		const copy = { ...meassurements };
		updateFn(copy);
		onChange(copy);
	}

	return (
		<>
			<FormFieldHeader title={t('reports.airMeassurement.title')} required />

			<div className="flex gap-2">
				<NumberInput
					label={t('reports.airMeassurement.temperatureInside')}
					placeholder={t('reports.airMeassurement.temperatureInsidePlaceholder')}
					value={meassurements.temperatureInside}
					onChange={value => handleChange(copy => (copy.temperatureInside = value))}
				/>

				<NumberInput
					label={t('reports.airMeassurement.relativeHumidityInside')}
					placeholder={t('reports.airMeassurement.relativeHumidityInsidePlaceholder')}
					value={meassurements.relativeHumidityInside}
					onChange={value => handleChange(copy => (copy.relativeHumidityInside = value))}
				/>

				<NumberInput
					label={t('reports.airMeassurement.waterAmountInside')}
					placeholder={t('reports.airMeassurement.waterAmountInsidePlaceholder')}
					value={meassurements.waterAmountInside}
					onChange={value => handleChange(copy => (copy.waterAmountInside = value))}
				/>
			</div>

			<div className="flex gap-2">
				<NumberInput
					label={t('reports.airMeassurement.temperatureOutside')}
					placeholder={t('reports.airMeassurement.temperatureOutsidePlaceholder')}
					value={meassurements.temperatureOutside}
					onChange={value => handleChange(copy => (copy.temperatureOutside = value))}
				/>

				<NumberInput
					label={t('reports.airMeassurement.relativeHumidityOutside')}
					placeholder={t('reports.airMeassurement.relativeHumidityOutsidePlaceholder')}
					value={meassurements.relativeHumidityOutside}
					onChange={value => handleChange(copy => (copy.relativeHumidityOutside = value))}
				/>

				<NumberInput
					label={t('reports.airMeassurement.waterAmountOutside')}
					placeholder={t('reports.airMeassurement.waterAmountOutsidePlaceholder')}
					value={meassurements.waterAmountOutside}
					onChange={value => handleChange(copy => (copy.waterAmountOutside = value))}
				/>
			</div>
		</>
	);
};

export default AirMeassurementDetailsForm;
