import React from 'react';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permissions } from '@ssg/common/GraphQL';
import { CatalogAddressInput, CatalogInput, GetCatalog_catalog, HouseNumbers } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';
import { Link /*, useHistory*/ } from 'react-router-dom';
//import { CatalogToCaseState } from './CatalogSingle';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Table from '@ssg/common/Components/Table';
import Box from '../../../Components/Layout/Box';
import UserContext from '../../../UserContext';
import CatalogAddressBoxModal from './CatalogAddressBoxModal';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	data: GetCatalog_catalog;
	changedBy: string;
	saveChanges: (data: CatalogInput) => Promise<void>;
	loadingUpdate: boolean;
	addresses: CatalogAddressInput[];
}

const AddressesBox: React.FC<Props> = ({ data, changedBy, saveChanges, loadingUpdate, addresses }): React.ReactElement => {
	const { t } = useTranslation();
	//const history = useHistory<CatalogToCaseState>();

	const addressesWithIndex = React.useMemo(() => addresses.map((a, i) => ({ ...a, index: i })), [addresses]);

	const userPermissions = React.useContext(UserContext).user?.permissions ?? [];
	const canEdit = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_EDIT]);

	const [edit, setEdit] = React.useState(false);
	const [create, setCreate] = React.useState(false);
	const [erase, setErase] = React.useState(false);

	const [addressIndex, setAddressIndex] = React.useState<number | undefined>(undefined);
	const [address, setAddress] = React.useState<CatalogAddressInput | undefined>(undefined);

	React.useEffect(() => {
		if (typeof addressIndex !== 'undefined') {
			setAddress(addresses[addressIndex]);
		} else {
			setAddress(undefined);
		}
	}, [addressIndex, addresses]);

	return (
		<>
			<Box title="" className="text-blue mb-8" full>
				<div className="text flex justify-between">
					<FormFieldHeader title="catalog.addresses" labelClass="text-lg" titleClass="" />
					{canEdit && (
						<TextButton
							icon={faPlus}
							text="catalog.address.addAddress"
							onClick={() => {
								setAddress({
									addressLine: address ? address.addressLine : '',
									zipCode: address ? address.addressLine : '',
									city: address ? address.city : '',
									startNumber: '',
									endNumber: '',
									houseNumbers: HouseNumbers.BOTH,
									comment: '',
								});
								setCreate(true);
							}}
						/>
					)}
				</div>
				<Table
					data={addressesWithIndex}
					columns={[
						{
							label: t('common.newCase'),
							// hideColumn: typeof data?.debitor === undefined,
							selectFn: c => (
								<Link
									to={{
										pathname: `/casecreation/catalog/${data.id}/${c.index}`,
									}}
									target="_blank"
								>
									<FontAwesomeIcon
										icon={faPlus}
										size="lg"
										// onClick={() => history.push('/casecreation/', {
										// 	debitor: data?.debitor ? data.debitor : undefined,
										// 	address: addresses[c.index],
										// })}
										className="cursor-pointer"
									/>
								</Link>
							),
						},
						{
							label: 'common.address',
							selectFn: a => (
								<p>
									{a.addressLine} <br />
									{a.zipCode} {a.city}
								</p>
							),
							sortFn: (a, b) => (a?.addressLine).localeCompare(b?.addressLine),
						},
						{
							label: 'catalog.address.startNumber',
							selectFn: a => <p>{a?.startNumber}</p>,
							sortFn: (a, b) => (a?.startNumber).localeCompare(b?.startNumber),
						},
						{
							label: 'catalog.address.endNumber',
							selectFn: a => <p>{a?.endNumber}</p>,
							sortFn: (a, b) => (a?.endNumber).localeCompare(b?.endNumber),
						},
						{
							label: 'catalog.address.houseNumbers',
							selectFn: a => <p>{t(`catalog.address.${a?.houseNumbers}`)}</p>,
							sortFn: (a, b) => t(`catalog.address.${a?.houseNumbers}`).localeCompare(t(`catalog.address.${b?.houseNumbers}`)),
						},
						{
							label: 'common.comment',
							selectFn: a => <p>{a.comment}</p>,
							//sortFn: (a, b) => (t(`catalog.address.${a?.houseNumbers}`)).localeCompare(t(`catalog.address.${b?.houseNumbers}`)),
						},
						{
							label: t('common.edit'),
							classNameTh: 'text-right',
							hideColumn: !canEdit,
							selectFn: a => (
								<div className="flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faEdit}
										size="lg"
										onClick={() => {
											setAddressIndex(a.index);
											setEdit(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
						{
							label: t('common.remove'),
							classNameTh: 'text-right',
							hideColumn: !canEdit,
							selectFn: a => (
								<div className="text-red flex content-start justify-end text-right">
									<FontAwesomeIcon
										icon={faTrashAlt}
										size="lg"
										onClick={() => {
											setAddressIndex(a.index);
											setErase(true);
										}}
										className="cursor-pointer"
									/>
								</div>
							),
						},
					]}
					keySelector={(a, i) => a.addressLine + i + a.endNumber + a.startNumber}
				/>
			</Box>

			{/* Catalog Address Modal */}
			<div>
				{create && (
					<CatalogAddressBoxModal
						changedBy={changedBy}
						catalogData={data}
						saveChanges={saveChanges}
						loadingUpdate={loadingUpdate}
						addresses={addresses}
						open={create}
						close={() => {
							setCreate(false);
							setAddress(undefined);
						}}
						data={address}
					/>
				)}

				{edit && typeof address !== 'undefined' && (
					<CatalogAddressBoxModal
						changedBy={changedBy}
						catalogData={data}
						saveChanges={saveChanges}
						loadingUpdate={loadingUpdate}
						addresses={addresses}
						addressIndex={addressIndex}
						open={edit}
						close={() => {
							setEdit(false);
							setAddressIndex(undefined);
						}}
						edit
						data={address}
					/>
				)}

				{erase && typeof address !== 'undefined' && (
					<CatalogAddressBoxModal
						changedBy={changedBy}
						catalogData={data}
						saveChanges={saveChanges}
						loadingUpdate={loadingUpdate}
						addresses={addresses}
						addressIndex={addressIndex}
						erase
						open={erase}
						close={() => {
							setErase(false);
							setAddressIndex(undefined);
						}}
						data={address}
					/>
				)}
			</div>
		</>
	);
};

export default AddressesBox;
