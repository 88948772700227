import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { countries } from '@ssg/common/Helpers/countries';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import { CaseStatus, GetWebCasesByErpNoQuery, useGetWebCasesByErpNoQuery } from '@ssg/common/GraphQL/indexV2';

interface Props {
	setSelected: (id: { id: string; erpNo: string } | undefined) => unknown;
	helpText?: string;
	closedFilter: boolean;
}

const CaseSearch: React.FC<Props> = ({ setSelected, helpText, closedFilter, children }) => {
	const { t } = useTranslation();

	const [casesSearchText, setCasesSearchText] = React.useState('');
	const [selectedCase, setSelectedCase] = React.useState<GetWebCasesByErpNoQuery['cases'][number] | undefined>(undefined);
	const { data: cases, loading } = useGetWebCasesByErpNoQuery({
		variables: { erpReferenceNo: casesSearchText },
		skip: casesSearchText.length < 2,
	});

	React.useEffect(() => {
		if (typeof selectedCase !== 'undefined') {
			setSelected({ id: selectedCase.id, erpNo: selectedCase.erpNo });
		} else {
			setSelected(undefined);
		}
	}, [selectedCase, setSelected]);

	const { control, setValue } = useForm();

	//Map case damage data
	const injuredParty: SelectOption[] =
		typeof selectedCase !== 'undefined'
			? [
				{
					value: selectedCase.damage.contact.name,
					label: 'case.injuredParty',
				},
				{
					value: selectedCase.damage.contact.address.road + ' ' + selectedCase.damage.contact.address.houseNumber + ' ' + selectedCase.damage.contact.address.floor,
					label: '',
				},
				{
					value: selectedCase.damage.contact.address.addressLineAlt ?? '',
					label: '',
				},
				{
					value: selectedCase.damage.contact.address.postalCode + ' ' + selectedCase.damage.contact.address.city,
					label: '',
				},
				{
					value: countries.find(c => c.value === selectedCase.damage.contact.address.country)?.label ?? '',
					label: '',
				},
				{
					value: selectedCase.damage.contact.phone ?? '',
					label: '',
				},
				{ value: selectedCase.damage.contact.email, label: '' },
			]
			: [];

	const damage: SelectOption[] =
		typeof selectedCase !== 'undefined'
			? [
				{
					value: selectedCase.damage.businessArea.name,
					label: 'common.area',
				},
				{
					value: selectedCase.damage.category.name,
					label: 'common.damageCategory',
				},
				{
					value: selectedCase.damage.cause.name,
					label: 'case.damageCause',
				},
			]
			: [];

	const description: SelectOption[] =
		typeof selectedCase !== 'undefined'
			? [
				{
					value: selectedCase.damage.description,
					label: selectedCase.damage.description.length > 0 ? 'case.damageDescription' : '',
				},
			]
			: [];

	const projectManager: SelectOption[] =
		typeof selectedCase !== 'undefined' && selectedCase.projectManager !== null && selectedCase.projectManager !== undefined
			? [
				{
					value: selectedCase.projectManager.name,
					label: 'case.ssgProjectManager',
				},
			]
			: [];

	const caseManager: SelectOption[] =
		typeof selectedCase !== 'undefined' && selectedCase.caseManager !== null && selectedCase.caseManager !== undefined
			? [
				{
					value: selectedCase.caseManager.name,
					label: 'case.ssgCaseManager',
				},
			]
			: [];

	return (
		<div>
			<SearchableSelect
				name="case"
				title="common.case"
				searchFn={searchText => setCasesSearchText(searchText)}
				onSelect={value => {
					setValue('case', value, { shouldValidate: true });
					setSelectedCase(cases?.cases.find(c => c.id === value));
				}}
				onBlur={() => undefined}
				minInputLength={2}
				options={(cases?.cases ?? []).filter(c => (closedFilter ? c.status === CaseStatus.Open : true)).map(c => ({ label: c.erpNo, value: c.id }))}
				isLoading={loading}
				control={control}
				className="mb-6"
			/>

			{typeof selectedCase === 'undefined' ? (
				<div className="text-blue ml-1 h-48 font-semibold">{typeof helpText === 'string' ? t(helpText) : undefined}</div>
			) : (
				<div className="flex flex-row">
					<div className="text-blue w-3/5">
						<p className="text-blue mb-4 font-medium">{selectedCase.erpNo}</p>

						<Link to={`/case/${selectedCase.id}`} target="_blank" className="hover:underline">
							<span className="font-medium hover:no-underline">
								<FontAwesomeIcon icon={faLink} className="mr-2" />
								<span>{t('case.linkToCase')}</span>
							</span>
						</Link>

						<div className="mt-4 flex flex-row flex-wrap">
							<div className="w-1/2">
								<BoxFormDataViewer data={injuredParty} keyId="case.injuredParty" />
							</div>

							<div className="w-1/2">
								<BoxFormDataViewer data={damage} keyId="case.damage" />
							</div>

							<div className="mt-4 w-full">
								<BoxFormDataViewer data={description} keyId="case.description" />
							</div>

							<div className="mt-4 w-full">
								<BoxFormDataViewer data={projectManager} keyId="case.ssgProjectManager" />
							</div>

							<div className="mt-4 w-full">
								<BoxFormDataViewer data={caseManager} keyId="case.ssgCaseManager" />
							</div>
						</div>
					</div>

					<div className="w-2/5">{children}</div>
				</div>
			)}
		</div>
	);
};
export default CaseSearch;
