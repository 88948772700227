import React from 'react';
import { GraphQLExtensionsData } from './GraphQLExtensionsContext';

interface Props {
	name: string;
	render: (value: unknown) => React.ReactElement;
}

const GraphQLExtensionsElement: React.FC<Props> = ({ name, render }): React.ReactElement => {
	const graphQLExtensionsData = React.useContext(GraphQLExtensionsData);
	const value = graphQLExtensionsData[name];

	return render(value);
};

export default GraphQLExtensionsElement;
