import { faChevronDown, faChevronUp, faEdit, faEnvelope, faPhone, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import classNames from 'classnames';
import React from 'react';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
/*import { useTranslation } from 'react-i18next';*/
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Box from '../../Components/Layout/Box';
import { GetWebCase_case, GetWebCase_case_damage_contacts } from '../../GraphQL';
import { ICaseCreation } from '../../Schemas/ICaseCreation';

interface Props {
	caseData: GetWebCase_case | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	trigger: UseFormMethods['trigger'];
	setValue: UseFormMethods['setValue'];
	setUpdateContact: React.Dispatch<React.SetStateAction<boolean>>;
	setCreateContact: React.Dispatch<React.SetStateAction<boolean>>;
	setDeleteContact: React.Dispatch<React.SetStateAction<boolean>>;
	setContactIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
	contacts: GetWebCase_case_damage_contacts[];
}

const DamageContactBox: React.FC<Props> = ({ caseData, register, errors, control, trigger, setValue, setUpdateContact, setCreateContact, setDeleteContact, setContactIndex, contacts }) => {
	//const { t } = useTranslation();

	const [showDamageContact, setShowDamageContact] = React.useState(typeof caseData !== 'undefined' && caseData.damage.contacts !== null);

	return (
		<Box form title="case.damageContact.contacts">
			<hr />
			<div className="w-full lg:w-3/4">
				<TextButton
					text={!showDamageContact ? 'case.showDamageContacts' : 'case.hideDamageContacts'}
					icon={!showDamageContact ? faChevronDown : faChevronUp}
					onClick={() => setShowDamageContact(!showDamageContact)}
				/>

				<div
					className={classNames({
						hidden: !showDamageContact,
					})}
				>
					<TextButton text="case.damageContact.addContact" icon={faPlus} className="mt-7 ml-auto mr-0" onClick={() => setCreateContact(true)} />

					<Table
						data={contacts}
						columns={[
							{
								label: 'common.name',
								noTruncate: true,
								selectFn: c => <p>{c.name}</p>,
								sortFn: (a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''),
							},
							{
								label: 'common.role',
								noTruncate: true,
								selectFn: c => <p>{c.role}</p>,
								sortFn: (a, b) => (a?.role ?? '').localeCompare(b?.role ?? ''),
							},
							{
								label: 'common.phone',
								selectFn: c => (
									<p>
										{c.phone ? (
											<>
												<a href={`tel:${c.phone ?? ''}`}>
													<FontAwesomeIcon icon={faPhone} />
												</a>
												{`${formatPhoneNumberIntl(c.phone ?? '')}`}
											</>
										) : (
											<></>
										)}
									</p>
								),
							},
							{
								label: 'catalog.contact.email',
								selectFn: c => (
									<p>
										{c?.email ? (
											<>
												<a href={`mailto:${c.email}`}>
													<FontAwesomeIcon icon={faEnvelope} />
												</a>{' '}
												{c.email}
											</>
										) : (
											<></>
										)}
									</p>
								),
								sortFn: (a, b) => (a?.email ?? '').localeCompare(b?.email ?? ''),
							},
							{
								label: 'common.edit',
								classNameTh: 'text-right',
								selectFn: (_, i) => (
									<div className="flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faEdit}
											size="lg"
											onClick={() => {
												setContactIndex(i);
												setUpdateContact(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'common.remove',
								classNameTh: 'text-right',
								selectFn: (_, i) => (
									<div className="text-red flex content-start justify-end text-right">
										<FontAwesomeIcon
											icon={faTrashAlt}
											size="lg"
											onClick={() => {
												setContactIndex(i);
												setDeleteContact(true);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
						]}
						keySelector={c => `${c.name}-${c.role}-${c.phone}-${c.email}`}
						noDataFoundText={'case.damageContact.NoContacts'}
					/>
				</div>
			</div>
		</Box>
	);
};

export default DamageContactBox;
