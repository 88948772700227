import { GraphQLError } from 'graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faExclamationCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { tryGetMessageFromGraphQLError } from '../Helpers/Helpers';
import toast, { Toast } from 'react-hot-toast';
import classNames from 'classnames';
import { useState } from 'react';
import Textarea from '../Components/Textarea';

const toastDurationInMilliseconds = 10_000;

interface Props {
	error: GraphQLError;
	t: Toast;
}

const ErrorToast: React.FC<Props> = ({ error, t }) => {
	const [showDetails, setShowDetails] = useState<boolean>(true);

	// Unable to use translation here as we are inside a common component
	const friendlyErrorMessage = tryGetMessageFromGraphQLError(error) ?? 'Noget gik galt.';

	return (
		<div
			className={classNames('pointer-events-auto flex w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5', {
				'animate-enter': t.visible,
				'animate-leave': !t.visible,
			})}
		>
			<div className="w-0 flex-1 p-4">
				<div className="flex items-center">
					<div className="flex-shrink-0 pt-0.5">
						<FontAwesomeIcon icon={faExclamationCircle} className="h-5 w-5 rounded-full text-red-500" />
					</div>
					<div className="ml-3 flex-1 overflow-x-auto">
						<p className="text-sm font-medium text-gray-900">{friendlyErrorMessage}</p>
						<p
							className="cursor-pointer text-sm font-medium text-gray-500"
							onClick={() => {
								setShowDetails(current => !current);
							}}
						>
							{showDetails ? 'Skjul' : 'Vis'} alle oplysninger <FontAwesomeIcon icon={showDetails ? faCaretUp : faCaretDown} className="ml-1" />
						</p>
						{showDetails && <Textarea title={''} name="error-details" rows={6} defaultValue={error.message} />}
					</div>
				</div>
			</div>
			<div className="flex border-l border-gray-200">
				<div onClick={() => toast.dismiss(t.id)} className="flex w-full cursor-pointer items-center justify-center p-4">
					<FontAwesomeIcon icon={faTimes} className="h-4 w-4 rounded-full text-gray-400 hover:text-gray-300" />
				</div>
			</div>
		</div>
	);
};

export default function useErrorHotToaster(): (error: GraphQLError) => void {
	return (error: GraphQLError): void => {
		toast.custom(t => <ErrorToast error={error} t={t} />, {
			duration: toastDurationInMilliseconds,
		});
	};
}
