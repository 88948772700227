import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { faCheckSquare, faExchange, faInfo, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { GetMachineHistory, GetMachineHistoryVariables, GetWebMachineLocations, GetWebMachines, GetWebMachinesVariables, GetWebMachines_machines, MachineStatus } from '../../GraphQL';
import { Modify } from '../../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Box from '../../Components/Layout/Box';
import MachineOverviewFilter from './MachineOverviewFilter';
import PrintMachineLabel from '@ssg/common/Components/PrintMachineLabel';
import MachineLog from './MachineLog';
import MachineInformation from './MachineInformation';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import UserContext from '../../UserContext';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

export interface MachineFilters {
	name?: string | null;
	status: MachineStatus | null;
	cases: string[] | undefined;
	locations: string[] | undefined;
	types: string[] | undefined;
	unavailable: boolean;
	hibernation: boolean | null;
}

export type MachineType = Modify<
	GetWebMachines_machines,
	{
		dateAsString: string;
		locationName: string;
	}
>;

const GET_MACHINES = loader('src/GraphQL/Machines/GetWebMachines.gql');
const GET_MACHINE_LOCATIONS = loader('src/GraphQL/Machines/GetWebMachineLocations.gql');
const GET_MACHINE_HISTORY = loader('src/GraphQL/Machines/GetMachineHistory.gql');

const MachinesOverview: React.FC = () => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useDebouncedState('', 200);

	const userContext = React.useContext(UserContext);

	const filterOutDiscontinuedMachines = useFlag(FeatureFlagEnums.FILTER_OUT_DISCONTINUED_MACHINES);

	const [activeUserFilter, setActiveUserFilter] = React.useState('');
	const selectedUserFilter = React.useMemo(() => userContext?.user?.machineFilters?.find(f => f.name === activeUserFilter), [activeUserFilter, userContext?.user?.machineFilters]);

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const [activeFilters, setActiveFilters] = useStorageState<MachineFilters>(window.sessionStorage, 'activeMachineFilters', {
		name: null,
		status: MachineStatus.ALL,
		cases: undefined,
		locations: undefined,
		types: undefined,
		unavailable: false,
		hibernation: null,
	});

	React.useEffect(() => {
		if (typeof selectedUserFilter === 'undefined') {
			return;
		}

		setActiveFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserFilter.name,
			status: selectedUserFilter.status,
			cases: selectedUserFilter.cases ?? undefined,
			locations: selectedUserFilter.locations ?? undefined,
			types: selectedUserFilter.types ?? undefined,
			unavailable: selectedUserFilter.unavailable ?? false,
			hibernation: selectedUserFilter.hibernation ?? null,
		}));
	}, [selectedUserFilter, setActiveFilters]);

	const { data, loading, fetchMore } = useQuery<GetWebMachines, GetWebMachinesVariables>(GET_MACHINES, {
		variables: {
			status: activeFilters.status,
			cases: activeFilters.cases,
			locations: activeFilters.locations,
			types: activeFilters.types,
			unavailable: activeFilters.unavailable,
			hibernation: activeFilters.hibernation,
			discontinued: filterOutDiscontinuedMachines ? false : undefined,
			offset: 0,
			limit: 20,
			searchString: searchTerm.length === 0 ? null : searchTerm,
		},
	});

	const { data: machineLocationsData } = useQuery<GetWebMachineLocations>(GET_MACHINE_LOCATIONS);

	const [bulkPrint, setBulkPrint] = React.useState(false);
	const [selectedRows, toggleSelectedRows, toggleAllRows] = useArrayToggler<string>([]);

	const machineLocations = React.useMemo(() => machineLocationsData?.machineLocations ?? [], [machineLocationsData]);

	const [machineId, setMachineId] = React.useState<string>('');
	const [showMachineLog, setShowMachineLog] = React.useState<boolean>(false);
	const [showMachineInformation, setShowMachineInformation] = React.useState<boolean>(false);

	const [fetchMachineLog, { data: machineLogData, loading: machineLogLoading }] = useLazyQuery<GetMachineHistory, GetMachineHistoryVariables>(GET_MACHINE_HISTORY, {
		variables: {
			machineERPReferenceNo: machineId,
		},
		fetchPolicy: 'no-cache',
	});

	const machines = React.useMemo(() => {
		return data?.machines ? data.machines : [];
	}, [data]);

	const getLocationName = (eRPLocationReference: string): string => {
		return machineLocations.find(l => l.locationCode === eRPLocationReference)?.name ?? '';
	};

	const isAllSelected = React.useMemo((): boolean => {
		const filteredMachinesLength = machines.length;
		const filteredMachinesErpReferenceNo = machines.map(m => m.erpReferenceNo);
		const filteredMachinesInSelectedRowsLength = selectedRows.filter(r => filteredMachinesErpReferenceNo.includes(r)).length;

		return filteredMachinesLength === filteredMachinesInSelectedRowsLength;
	}, [machines, selectedRows]);

	return (
		<BoxContainer>
			<Box full>
				<MachineOverviewFilter
					setFilterTerm={setSearchTerm}
					onFilter={setActiveFilters}
					activeFilters={activeFilters}
					setActiveUserFilter={setActiveUserFilter}
					activeUserFilter={activeUserFilter}
					setBulkPrint={setBulkPrint}
					bulkPrint={bulkPrint}
					machineLocations={machineLocations}
				/>
				{bulkPrint && (
					<PrintMachineLabel
						machines={machines.filter(m => selectedRows.includes(m.erpReferenceNo))}
						buttonText={`${t('machines.printSelected')} (${selectedRows.length})`}
						disabled={selectedRows.length === 0}
					/>
				)}
				<Table
					data={machines}
					keySelector={m => m.erpReferenceNo}
					loading={loading}
					noDataFoundText={loading ? '' : 'machines.noMachinesFound'}
					onRowClick={bulkPrint ? r => toggleSelectedRows(r.erpReferenceNo) : undefined}
					rowIsHighlighted={r => selectedRows.includes(r.erpReferenceNo) && bulkPrint}
					rowIsDisabled={r => r.blocked}
					columns={[
						!bulkPrint
							? {
									label: 'common.printLabel',
									selectFn: m => <PrintMachineLabel machines={[m]} />,
									align: 'LEFT',
							  }
							: {
									label: '',
									selectFn: m => (
										<div className="">
											{selectedRows.includes(m.erpReferenceNo) ? <FontAwesomeIcon icon={faCheckSquare} size="lg" /> : <FontAwesomeIcon icon={faSquare} size="lg" />}
										</div>
									),

									icon: isAllSelected ? faCheckSquare : faSquare,

									actionFn: () => toggleAllRows([...machines.map(m => m.erpReferenceNo)]),
							  },
						{
							label: 'machines.machineNo',
							selectFn: m => <div>{m.erpReferenceNo}</div>,
							sortFn: (a, b) => a.erpReferenceNo.localeCompare(b.erpReferenceNo),
						},
						{
							label: 'common.type',
							selectFn: m => <div>{m.name}</div>,
							sortFn: (a, b) => a.name.localeCompare(b.name),
						},
						{
							label: 'case.caseNo',
							selectFn: m => <div>{m.eRPCaseReference ?? ''}</div>,
							sortFn: (a, b) => (a.eRPCaseReference ?? '').localeCompare(b.eRPCaseReference ?? ''),
						},
						{
							label: 'common.location',
							selectFn: m => <div>{getLocationName(m.eRPLocationReference ?? '')}</div>,
							sortFn: (a, b) => getLocationName(a.eRPLocationReference ?? '').localeCompare(getLocationName(b.eRPLocationReference ?? '')),
						},
						{
							label: 'machines.moved',
							selectFn: m => (m.dateStart !== '0001-01-01' ? dateToDateTimeString(new Date(m.dateStart), true) : '-'),
							sortFn: (a, b) => dateToDateTimeString(new Date(a.dateStart), true).localeCompare(dateToDateTimeString(new Date(b.dateStart), true)),
						},
						{
							label: 'machines.origin',
							selectFn: m => <div>{toSentenceCase(m.originLocation)}</div>,
							sortFn: (a, b) => a.originLocation.localeCompare(b.originLocation),
						},
						{
							label: 'machines.hibernation',
							selectFn: m => (
								<div>
									<p>{m.hibernate ? t('common.yes') : t('common.no')}</p>
									<p>{m.hibernate ? m.hibernationReason : ''}</p>
								</div>
							),
							sortFn: (a, b) => Number(a.hibernate) - Number(b.hibernate),
						},
						// Removed for now
						// {
						//     label: 'machines.nextService',
						//     numeric: true,
						//     selectFn: m => (
						//         <div>
						//             {m.serviceDate !== '0001-01-01T00:00:00' && new Date(m.serviceDate) < new Date() && (
						//                 <div>
						//                     <p className="flex py-1 text-red">
						//                         <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" />
						//                         <p className="font-medium">{t('machines.serviceOverdue')}</p>
						//                     </p>
						//                 </div>
						//             )}
						//             {m.serviceDate !== '0001-01-01T00:00:00'
						//                 ?
						//                     dateToDateOnlyString(new Date(m.serviceDate))
						//                 :
						//                     <p>{t('machines.noServiceOverdue')}</p>
						//             }
						//         </div>
						//     ),
						//     sortFn: (a, b) => (a.serviceDate ?? new Date().toISOString()).localeCompare(b.serviceDate ?? new Date().toISOString()),
						// },
						{
							label: 'common.overView',
							classNameTh: 'text-right pr-4',
							align: 'RIGHT',
							selectFn: c => (
								<div className="flex w-full justify-end">
									<Button
										icon={faInfo}
										onClick={() => {
											setMachineId(c.erpReferenceNo);
											setShowMachineInformation(true);
										}}
										secondary
										disabled={bulkPrint}
									/>
								</div>
							),
						},
						{
							label: 'common.history',
							classNameTh: 'text-right pr-4',
							align: 'RIGHT',
							selectFn: c => (
								<div className="flex w-full justify-end">
									<Button
										icon={faExchange}
										onClick={() => {
											setMachineId(c.erpReferenceNo);
											fetchMachineLog();
											setShowMachineLog(true);
										}}
										secondary
										disabled={bulkPrint}
									/>
								</div>
							),
						},
					]}
				/>
				{typeof data !== 'undefined' && data.machines.length % 20 === 0 && data.machines.length !== 0 && (
					<Button
						fullWidth
						text="common.getMore"
						secondary
						loading={fetchMoreLoading}
						className="mt-2"
						onClick={async () => {
							setFetchMoreLoading(true);
							await fetchMore({
								variables: {
									offset: data?.machines.length,
								},
							});
							setFetchMoreLoading(false);
						}}
					/>
				)}
			</Box>
			<div>
				<MachineLog
					machineLogdata={machineLogData}
					loadingLogData={machineLogLoading}
					machineLocations={machineLocations}
					machineId={machineId}
					open={showMachineLog}
					close={() => {
						setShowMachineLog(false);
						setMachineId('');
					}}
				/>

				<MachineInformation
					machineId={machineId}
					open={showMachineInformation}
					close={() => {
						setShowMachineInformation(false);
						setMachineId('');
					}}
				/>
			</div>
		</BoxContainer>
	);
};

export default MachinesOverview;
