import React, { useContext } from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { faExclamationCircle, faMapMarkedAlt, faQuoteRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { postalCodeToCity } from '@ssg/common/Helpers/postalCodes';
import { CollectionItemType, GetWebCases, GetWebCasesVariables, GetWebCase_case, GetCollectionItems, GetCatalogCaseCreation } from '../../GraphQL';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/client';
import { countries } from '@ssg/common/Helpers/countries';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { FullAddressObject, fullAddressRequest } from './AddressRequest';
import Box from '../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import InputPhone from '@ssg/common/Components/InputPhone';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Checkbox from '@ssg/common/Components/Checkbox';
import Textarea from '@ssg/common/Components/Textarea';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

const GET_CASES = loader('../../GraphQL/Cases/GetWebCases.gql');
const COLLECTION_ITEMS = loader('src/GraphQL/CollectionItems/GetCollectionItems.gql');

interface Props {
	caseData: GetWebCase_case | undefined;
	catalogAddress: GetCatalogCaseCreation['catalog']['addresses'][number] | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
	getValues: UseFormMethods['getValues'];
	trigger: UseFormMethods['trigger'];
}

const DamageAddressBox: React.FC<Props> = ({ caseData, catalogAddress, register, errors, control, setValue, trigger, getValues }): React.ReactElement => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);
	const [tippy, setTippy] = React.useState<boolean | undefined>(undefined);

	const { data: accessDropdownItems } = useQuery<GetCollectionItems>(COLLECTION_ITEMS, {
		variables: {
			type: CollectionItemType.ACCESS_CONDITION,
		},
	});

	async function request(phonenumber: string, country: string) {
		const noCountryPhone = formatPhoneNumber(phonenumber);

		setError('');
		setLoading(true);

		let tempUrl = '',
			url = 'https://live.intouch.no/tk/search.php?username=SSG_DK&password=kjmhnj&charset=UTF-8';
		// SSGDK'75857526' SSGSE'8999924' SSGNO'69949800' Use https://www.krak.dk/, https://www.eniro.se/ or https://www.gulesider.no/ to test

		if (noCountryPhone) {
			if (country === 'DK') {
				url += '&qry=0045';
			} else if (country === 'SE') {
				url += '&qry=';
			} else if (country === 'NO') {
				url += '&qry=0047';
			}

			url += noCountryPhone + '&format=json';

			if (country === 'DK') {
				url += '&Qland=dk&Qpriv=';
				tempUrl = url + '0';
			} else if (country === 'SE') {
				url += '&Qland=se&Qpriv=';
				tempUrl = url + '0';
			} else if (country === 'NO') {
				tempUrl = url;
			}

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: '*/*',
				},
			};

			let res = await fetch(tempUrl, requestOptions);
			let json = await res.json();

			if (typeof json.result[1] == 'undefined') {
				if (country === 'DK' || country === 'SE') {
					tempUrl = url + '1';
				}

				res = await fetch(tempUrl, requestOptions);
				json = await res.json();
			}

			if (json.result[1]) {
				const data = json.result[1].listing.duplicates[0];

				const fullname = data.fornavn ? data.fornavn + ' ' + data.etternavn : data.etternavn;
				setLoading(false);

				setValue('DamageAddressBox.damageName', fullname);
				setValue('DamageAddressBox.damageRoad', data.veinavn);
				if (data.husnr.includes(',')) {
					setValue('DamageAddressBox.damageHouseNumber', data.husnr.substring(0, data.husnr.indexOf(',')));
					setValue('DamageAddressBox.damageFloor', data.husnr.substring(data.husnr.indexOf(',') + 1).trim());
				} else {
					setValue('DamageAddressBox.damageHouseNumber', data.husnr);
					setValue('DamageAddressBox.damageFloor', '');
				}
				setRoad(data.veinavn);
				setHouseNumber(data.husnr.substring(0, data.husnr.indexOf(',')));
				setValue('DamageAddressBox.damageZip', data.postnr);
				setPostalCode(data.postnr);
				setValue('DamageAddressBox.damageCity', data.poststed);
				setCity(data.poststed);

				setAddress({ label: data.veinavn, value: data.veinavn });
				trigger(['DamageAddressBox.damageName', 'DamageAddressBox.damageRoad', 'DamageAddressBox.damageHouseNumber', 'DamageAddressBox.damageZip', 'DamageAddressBox.damageCity']);
			} else {
				setLoading(false);
				setError('error.noAddressFound');
			}
		} else {
			setLoading(false);
			setError('error.phoneMissing');
		}
	}

	const [showHelpText, setShowHelpText] = React.useState(false);
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [phonenumber, setPhonenumber] = React.useState(caseData?.damage.contact.phone ?? '');
	const [country, setCountry] = React.useState(caseData?.damage.contact.address.country ?? envVar.defaultCountry ?? '');
	const [countrySearchText, setCountrySearchText] = React.useState(countries.find(c => c.value === country)?.label ?? '');
	const [distinctItems, setDistinctItems] = React.useState<SelectOption[]>([]);
	const [loadingDistinctItems, setLoadingDistinctItems] = React.useState<boolean>(false);
	const [address, setAddress] = React.useState<SelectOption>({
		label: (caseData?.damage.contact.address.road || (catalogAddress && catalogAddress.addressLine)) ?? '',
		value: (caseData?.damage.contact.address.road || (catalogAddress && catalogAddress.addressLine)) ?? '',
	});

	const countrySelectHandler = React.useCallback(
		(value: string): void => {
			setCountry(value);
			//const country = countries.find(c => c.value === value)?.label || '';
			setValue('DamageAddressBox.damageCountry', value, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	React.useEffect(() => {
		countrySelectHandler(country);
	}, [country, countrySelectHandler]);

	const noEmail = useWatch({
		control,
		name: 'DamageAddressBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.damage.contact.email === '',
	});

	React.useEffect(() => {
		noEmail &&
			setValue('DamageAddressBox.damageEmail', '', {
				shouldValidate: true,
			});
	}, [noEmail, setValue]);

	React.useEffect(() => setValue('DamageAddressBox.damageRoad', address.label), [address, setValue]);

	const phonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('DamageAddressBox.damagePhone', phonenumber, {
				shouldValidate: true,
			});
			setPhonenumber(phonenumber);
		},
		[setValue],
	);

	React.useEffect(() => {
		phonenumber !== '' && phonenumberHandler(phonenumber);
	}, [phonenumber, phonenumberHandler]);

	//Address lines for searching cases
	const [road, setRoad] = useDebouncedState(
		caseData ? caseData?.damage.contact.address.road + ' ' + caseData?.damage.contact.address.houseNumber : catalogAddress && catalogAddress.addressLine,
		100,
	);

	const [houseNumber, setHouseNumber] = useDebouncedState(caseData ? caseData?.damage.contact.address.houseNumber : catalogAddress && catalogAddress.startNumber, 100);

	const [floor, setFloor] = useDebouncedState(caseData?.damage.contact.address.floor, 100);

	const [city, setCity] = useDebouncedState(caseData ? caseData?.damage.contact.address.city : catalogAddress && catalogAddress.city, 100);

	const [postalCode, setPostalCode] = useDebouncedState(caseData ? caseData?.damage.contact.address.postalCode : catalogAddress && catalogAddress.zipCode, 100);

	const [getSameAddressCases, { data: sameAddressCases }] = useLazyQuery<GetWebCases, GetWebCasesVariables>(GET_CASES);

	const [fullAddresses, setFullAddresses] = React.useState<FullAddressObject[]>([]);

	const getFullAddresses = async (addressText: string) => {
		setLoadingDistinctItems(true);
		const addresses = await fullAddressRequest(addressText);
		setDistinctItems(addresses.map(a => ({ label: a.forslagstekst, value: a.data.id })));
		setFullAddresses(addresses);
		setLoadingDistinctItems(false);
	};

	React.useEffect(() => {
		const tempRoad = road;
		const tempHouseNumber = houseNumber;
		const tempCity = city;
		const tempPostalCode = postalCode;
		const tempCountry = country;
		const tempFloor = floor;
		if (
			typeof tempRoad !== 'undefined' &&
			typeof tempHouseNumber !== 'undefined' &&
			typeof tempCity !== 'undefined' &&
			typeof tempPostalCode !== 'undefined' &&
			typeof tempCountry !== 'undefined'
		) {
			getSameAddressCases({
				variables: {
					address: {
						road: tempRoad,
						houseNumber: tempHouseNumber,
						floor: tempFloor,
						city: tempCity,
						postalCode: tempPostalCode,
						country: country,
					},
					includeEconomy: false,
					excludeTotalCount: true,
				},
			});
		}
	}, [city, country, floor, getSameAddressCases, houseNumber, postalCode, road]);

	return (
		<Box form title="common.damageAddress" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} helpButton noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.damageAddressHeader" />}

				<SearchableSelect
					key="country"
					control={control}
					name="DamageAddressBox.damageCountry"
					title="common.country"
					helpText="case.helpText.customerCountry"
					showHelpText={showHelpText}
					required
					options={countries.filter(c => c.label.toLowerCase().includes(countrySearchText.toLowerCase())) ?? []}
					searchFn={(searchText): void => setCountrySearchText(searchText)}
					onSelect={(value): void => countrySelectHandler(value)}
					onBlur={(): void => undefined}
					minInputLength={1}
					isLoading={false}
					errorMessage={errors.DamageAddressBox?.damageCountry?.message ?? ''}
					initialSelection={countries.find(c => c.value === country)}
				/>

				<InputPhone
					title="common.phone"
					required
					innerRef={register}
					name="DamageAddressBox.damagePhone"
					errorMessage={errors.DamageAddressBox?.damagePhone?.message ?? ''}
					helpText="case.helpText.damagePhone"
					showHelpText={showHelpText}
					control={control}
					trigger={trigger}
					searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
					onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
					defaultCountry={country}
					defaultPhoneNumber={phonenumber}
				/>

				<TextButton icon={faMapMarkedAlt} text="case.getAddressKrak" loading={loading} className="text-blue mt-2" onClick={() => request(phonenumber, country)} />

				<FormErrorText text={error} />

				<Input
					title="common.email"
					required
					innerRef={register}
					disabled={noEmail}
					name="DamageAddressBox.damageEmail"
					errorMessage={errors.DamageAddressBox?.damageEmail?.message ?? ''}
					helpText="case.helpText.damageEmail"
					showHelpText={showHelpText}
					defaultValue={caseData?.damage.contact.email}
				/>

				<Checkbox
					title="case.noEmail"
					name="DamageAddressBox.noEmail"
					className="mt-1"
					innerRef={register}
					onChange={() => {
						trigger('DamageAddressBox.damageEmail');
					}}
					defaultChecked={typeof caseData !== 'undefined' && caseData.damage.contact.email === ''}
				/>

				<Input
					title="common.name"
					required
					innerRef={register}
					name="DamageAddressBox.damageName"
					errorMessage={errors.DamageAddressBox?.damageName?.message ?? ''}
					helpText="case.helpText.damageName"
					showHelpText={showHelpText}
					defaultValue={caseData?.damage.contact.name}
				/>

				<div className="flex">
					<div className="w-5/6">
						<SearchableSelect
							key={address.label}
							placeholder="F.eks. Knapholm 4 2730 Herlev"
							control={control}
							name="DamageAddressBox.damageRoad"
							title="common.road"
							helpText="case.helpText.damageAddress"
							showHelpText={showHelpText}
							required
							addressSlice
							options={[address, ...distinctItems]}
							searchFn={searchText => {
								getFullAddresses(searchText);
							}}
							onSelect={value => {
								const fullAddress = fullAddresses.find(fa => fa.data.id === value);
								if (fullAddress) {
									const { city, floor, houseNumber, postalCode, road, door } = fullAddress.data;
									console.log(fullAddress);
									const doorFloor = door ? floor + '. ' + door : floor;
									setValue('DamageAddressBox.damageRoad', road);
									setRoad(road);
									setValue('DamageAddressBox.damageHouseNumber', houseNumber);
									setHouseNumber(houseNumber);
									setValue('DamageAddressBox.damageCity', city);
									setCity(city);
									setValue('DamageAddressBox.damageFloor', doorFloor);
									setFloor(floor);
									setValue('DamageAddressBox.damageZip', postalCode);
									setPostalCode(postalCode);
								}
							}}
							onBlur={() => undefined}
							minInputLength={2}
							isLoading={loadingDistinctItems}
							errorMessage={errors.DamageAddressBox?.damageRoad?.message ?? ''}
							initialSelection={address}
						/>
					</div>
					<div className="ml-4 h-full w-1/6">
						<Input
							title="common.houseNumber"
							required
							innerRef={register}
							name="DamageAddressBox.damageHouseNumber"
							errorMessage={errors.DamageAddressBox?.damageHouseNumber?.message ?? ''}
							onChange={v => setHouseNumber(v.currentTarget.value)}
							defaultValue={caseData?.damage.contact.address.houseNumber}
						/>
					</div>
					<div className="ml-4 h-full w-1/6">
						<Input
							title="common.floor"
							innerRef={register}
							name="DamageAddressBox.damageFloor"
							errorMessage={errors.DamageAddressBox?.damageFloor?.message ?? ''}
							onBlur={v => setFloor(v.currentTarget.value)}
							defaultValue={caseData?.damage.contact.address.floor ?? ''}
						/>
					</div>
				</div>

				<Input
					title="common.addressLine2"
					innerRef={register}
					name="DamageAddressBox.damageAddress2"
					errorMessage={errors.DamageAddressBox?.damageAddress2?.message ?? ''}
					helpText="case.helpText.damageAddress2"
					showHelpText={showHelpText}
					defaultValue={caseData?.damage.contact.address.addressLineAlt ?? ''}
				/>

				<div className="flex">
					<div className="w-28">
						<Input
							title="common.postalcode"
							required
							innerRef={register}
							name="DamageAddressBox.damageZip"
							errorMessage={errors.DamageAddressBox?.damageZip?.message ?? ''}
							helpText="case.helpText.damageZip"
							showHelpText={showHelpText}
							onBlur={async e => {
								const pc = e.currentTarget.value;
								setPostalCode(pc);

								const city = await postalCodeToCity(country, pc);
								if (typeof city !== 'undefined') {
									setValue('DamageAddressBox.damageCity', city);
									setCity(city);
								} else {
									setValue('DamageAddressBox.damageCity', city);
									setCity(undefined);
								}
							}}
							defaultValue={caseData?.damage.contact.address.postalCode || (catalogAddress && catalogAddress.zipCode)}
						/>
					</div>
					<div className="flex-grow-default ml-4">
						<Input
							title="common.city"
							required
							innerRef={register}
							name="DamageAddressBox.damageCity"
							errorMessage={errors.DamageAddressBox?.damageCity?.message ?? ''}
							helpText="case.helpText.damageCity"
							showHelpText={showHelpText}
							onBlur={v => setCity(v.currentTarget.value)}
							defaultValue={caseData?.damage.contact.address.city || (catalogAddress && catalogAddress.city)}
						/>
					</div>
				</div>

				{sameAddressCases && sameAddressCases.cases.length > 0 && (
					<div className="text-blue mt-1">
						<p>
							<FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
							{t('case.alreadyExists')}
						</p>
						<ul className="list-disc pl-5">
							{sameAddressCases.cases.map(c => (
								<li key={c.id}>
									<div className="flex h-6 items-center font-semibold underline">
										<Link to={`/case/${c.id}`} target="_blank">
											{t('case.caseNo') + c.erpNo}
										</Link>
									</div>
								</li>
							))}
						</ul>
					</div>
				)}

				<FormFieldHeader htmlFor="DamageAddressBox.accessConditions" title="case.accessConditions" />
				<div className="flex">
					<Popover
						placement="top"
						interactive
						clickTrigger
						hideOnClick
						visible={tippy}
						onHide={() => setTippy(undefined)}
						content={
							<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2">
								{accessDropdownItems?.collectionItems.map((temp, i) => (
									<p
										key={i}
										className="cursor-pointer"
										onClick={() => {
											const singleValue = getValues('DamageAddressBox.accessConditions');
											setValue('DamageAddressBox.accessConditions', singleValue === '' ? temp.name : `${singleValue}, ${temp.name}`);
											setTippy(false);
										}}
									>
										"{temp.name}"
									</p>
								))}
							</div>
						}
					>
						<div>
							<TextButton text="case.insertFromTemplate" icon={faQuoteRight} />
						</div>
					</Popover>
				</div>
				<Textarea title="" name="DamageAddressBox.accessConditions" rows={3} innerRef={register} className="-mt-1" />
			</div>
		</Box>
	);
};

export default DamageAddressBox;
