import React from 'react';
import { useQuery } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, BarChart } from 'recharts';
import { GetMachineHistory, GetMachineHistoryVariables, GetMachineHistory_machineHistory } from '../../GraphQL';
import { dateDiffInDays } from '../../helper';
import { formatDateForInput, formatDateForInputEU } from '@ssg/common/Helpers/dateToDateOnlyString';
import { SelectOption, dynamicSort } from '@ssg/common/Helpers/Helpers';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Toggle from '@ssg/common/Components/Toggle';
import classNames from 'classnames';
import CustomTooltipPeriod from './CustomToolTipPeriod';
import CustomTooltipUsage from './CustomToolTipUsage';
import Select from 'react-select';

const GET_MACHINE_HISTORY = loader('src/GraphQL/Machines/GetMachineHistory.gql');

const addTimeToDate = (date: string, time: string) => {
	return new Date(`${new Date(date).toDateString()} ${time}`);
};

interface IChartData {
	name: string;
	value: number;
	tickText?: string;
	dateWithTime?: string;
	totalConsumption?: number;
	totalConsumptionCase?: number;
	isCase?: boolean;
}

export interface IConsumption {
	caseOrLocationName: string;
	consumption: number;
	isCase: boolean;
}

const getDataForLineChart = (history: GetMachineHistory_machineHistory[], activeFilters: MachineFilter) => {
	const returnValues: IChartData[] = [];
	let usageOnCase = 0;
	history.forEach((his, index) => {
		if (activeFilters.maxDate && index === history.length - 1) return;
		if (activeFilters.excludeLocations) {
			if (!activeFilters.caseLocation && his.eRPCaseReference) {
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date(), false, '/') : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
					totalConsumption: index === history.length - 1 ? his.consumption : his.usageMeterEnd,
					isCase: !his.eRPLocationReference,
				});
			} else if (activeFilters.caseLocation === his.eRPCaseReference) {
				usageOnCase += his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption;
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date(), false, '/') : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
					totalConsumption: index === history.length - 1 ? his.consumption : his.usageMeterEnd,
					totalConsumptionCase: usageOnCase,
					isCase: !his.eRPLocationReference,
				});
			}
		} else {
			if (!activeFilters.caseLocation && (his.eRPCaseReference || his.eRPLocationReference)) {
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date(), false, '/') : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
					totalConsumption: index === history.length - 1 ? his.consumption : his.usageMeterEnd,
					isCase: !his.eRPLocationReference,
				});
			} else if (activeFilters.caseLocation === (his.eRPCaseReference || his.eRPLocationReference)) {
				usageOnCase += his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption;
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: his.dateEnd === '0001-01-01' ? 0 : his.usageMeterEnd - his.consumption,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date(), false, '/') : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
					totalConsumption: index === history.length - 1 ? his.consumption : his.usageMeterEnd,
					totalConsumptionCase: usageOnCase,
					isCase: !his.eRPLocationReference,
				});
			}
		}
	});

	return returnValues;
};

const getDataForBarChart = (history: GetMachineHistory_machineHistory[], activeFilters: MachineFilter) => {
	const returnValues: IChartData[] = [];
	let days = 0;

	history.forEach((his, index) => {
		if (activeFilters.maxDate && index === history.length - 1) return;
		if (activeFilters.excludeLocations) {
			if (!activeFilters.caseLocation && his.eRPCaseReference) {
				days = dateDiffInDays(
					addTimeToDate(his.dateStart, his.startTime),
					his.dateEnd === '0001-01-01' ? addTimeToDate(formatDateForInput(new Date()), his.startTime) : addTimeToDate(his.dateEnd, his.startTime),
				);
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: days,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date()) : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
				});
			} else if (activeFilters.caseLocation === his.eRPCaseReference) {
				days = dateDiffInDays(
					addTimeToDate(his.dateStart, his.startTime),
					his.dateEnd === '0001-01-01' ? addTimeToDate(formatDateForInput(new Date()), his.startTime) : addTimeToDate(his.dateEnd, his.startTime),
				);
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: days,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date()) : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
				});
			}
		} else {
			if (!activeFilters.caseLocation && (his.eRPCaseReference || his.eRPLocationReference)) {
				days = dateDiffInDays(
					addTimeToDate(his.dateStart, his.startTime),
					his.dateEnd === '0001-01-01' ? addTimeToDate(formatDateForInput(new Date()), his.startTime) : addTimeToDate(his.dateEnd, his.startTime),
				);
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: days,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date()) : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
				});
			} else if (activeFilters.caseLocation === (his.eRPCaseReference || his.eRPLocationReference)) {
				days = dateDiffInDays(
					addTimeToDate(his.dateStart, his.startTime),
					his.dateEnd === '0001-01-01' ? addTimeToDate(formatDateForInput(new Date()), his.startTime) : addTimeToDate(his.dateEnd, his.startTime),
				);
				returnValues.push({
					name: `${his.eRPCaseReference ? his.eRPCaseReference : his.eRPLocationReference}`,
					value: days,
					tickText: `${formatDateForInputEU(new Date(his.dateStart), false, '/')}-${
						his.dateEnd === '0001-01-01' ? formatDateForInputEU(new Date()) : formatDateForInputEU(new Date(his.dateEnd), false, '/')
					}`,
				});
			}
		}
	});

	return returnValues;
};

export interface MachineFilter {
	minDate: Date | undefined;
	maxDate: Date | undefined;
	caseLocation: string | undefined;
	excludeLocations: boolean;
}

interface Props {
	machineId: string;
	open: boolean;
	close: () => void;
}

const tabs: string[] = ['machines.consumption', 'common.days'];

const MachineInformation: React.FC<Props> = ({ machineId, open, close }) => {
	const { t } = useTranslation();

	const getWindowWidth = window.innerWidth * 0.85;

	const [activeFilters, setActiveFilters] = React.useState<MachineFilter>({
		minDate: undefined,
		maxDate: undefined,
		caseLocation: undefined,
		excludeLocations: false,
	});

	const { data, loading } = useQuery<GetMachineHistory, GetMachineHistoryVariables>(GET_MACHINE_HISTORY, {
		skip: machineId === '' || !open,
		fetchPolicy: 'no-cache',
		variables: {
			machineERPReferenceNo: machineId,
			minDate: activeFilters.minDate ? formatDateForInput(activeFilters.minDate) : undefined,
			maxDate: activeFilters.maxDate ? formatDateForInput(activeFilters.maxDate) : undefined,
		},
	});

	const history = React.useMemo(
		() =>
			data?.machineHistory
				.filter(mach => mach.eRPLocationReference !== '' && mach.eRPCaseReference !== '')
				.filter(h => h.dateEnd !== '0001-01-01')
				.sort((a, b) => new Date(a.dateEnd).valueOf() - new Date(b.dateEnd).valueOf())
				.sort((a, b) => new Date(a.dateStart).valueOf() - new Date(b.dateStart).valueOf())
				.concat(data?.machineHistory.filter(h => h.dateEnd === '0001-01-01')) ?? [],
		[data],
	);

	const [caseList, setCaseList] = React.useState<SelectOption[]>([]);

	React.useEffect(() => {
		if (activeFilters.caseLocation === undefined && activeFilters.maxDate === undefined && activeFilters.minDate === undefined) {
			setCaseList(
				history
					.filter(mach => (activeFilters.excludeLocations ? mach.eRPCaseReference : true))
					.map(m => {
						const val = m.eRPCaseReference ? m.eRPCaseReference : m.eRPLocationReference ? m.eRPLocationReference : '';
						const item: SelectOption = {
							value: val,
							label: val,
						};

						return item;
					})
					.filter((v, i, a) => a.findIndex(t => t.label === v.label) === i)
					.sort(dynamicSort('label')),
			);
		}
	}, [setCaseList, activeFilters, history]);
	const consumptionBarChartData = React.useMemo(() => getDataForLineChart(history, activeFilters), [activeFilters, history]);
	const barChartData = React.useMemo(() => getDataForBarChart(history, activeFilters), [activeFilters, history]);

	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	return (
		<Modal
			title={`${t('common.overView')} ${machineId}`}
			size={ModalSize.XLARGE}
			visible={open}
			close={() => {
				setActiveFilters(currentFilters => ({
					...currentFilters,
					minDate: undefined,
					maxDate: undefined,
					caseLocation: undefined,
				}));
				setActiveTab(tabs[0]);
				close();
			}}
			body={
				<div className="text-blue space-y-4">
					<div className="flex">
						<div className="ml-10 flex w-3/4 justify-start">
							{tabs.slice(0, tabs.length).map((title, i) => {
								return (
									<div className="mr-3 mt-1" key={i}>
										<button
											type="button"
											onClick={() => setActiveTab(title)}
											className={classNames('text-blue focus:outline-none', {
												'border-blue border-b-2': title === activeTab,
											})}
										>
											{t(title)}
										</button>
									</div>
								);
							})}
						</div>
						<div className="flex w-1/4 justify-end">
							<label className="text-blue mb-1 mr-3 text-xs font-medium" style={{ minWidth: '100px' }}>
								{t('machines.caseOnly')}
								<Toggle
									name="caseOnly"
									text=""
									onClick={() =>
										setActiveFilters(currentFilters => ({
											...currentFilters,
											excludeLocations: !currentFilters.excludeLocations,
											caseLocation: undefined,
										}))
									}
									defaultChecked={activeFilters.excludeLocations}
									className="mt-2 ml-4"
								/>
							</label>
							<label key={activeFilters.excludeLocations.toString()} className="text-blue mb-1 mr-3 text-xs font-medium">
								{activeFilters.excludeLocations ? t('common.case') : `${t('common.case')}/${t('common.location')}`}
								<Select
									options={caseList ?? []}
									className="react-select-custom w-40 text-xs"
									isClearable
									value={caseList.find(c => activeFilters.caseLocation === c.value)}
									onChange={value =>
										setActiveFilters({
											...activeFilters,
											caseLocation: value?.value ?? '',
										})
									}
									menuPortalTarget={document.body}
									styles={{
										menuPortal: base => ({
											...base,
											zIndex: 6667,
										}),
									}}
								/>
							</label>
							<label className="text-blue mb-1 mr-3 text-xs font-medium">
								{t('common.period')}
								<div className="flex items-center">
									<input
										type="date"
										name="minDate"
										value={formatDateForInput(activeFilters.minDate)}
										onChange={e => {
											const minDate = e.target.valueAsDate;
											if (minDate === null || typeof minDate === 'undefined') {
												return;
											}
											minDate.setHours(0, 0, 0, 0);

											setActiveFilters(currentFilters => ({
												...currentFilters,
												minDate,
											}));

											if (typeof activeFilters.maxDate === 'undefined') return;

											if (minDate > activeFilters.maxDate) {
												const maxDate = new Date(minDate);
												maxDate.setHours(23, 59, 59, 999);
												setActiveFilters(currentFilters => ({
													...currentFilters,
													maxDate,
												}));
											}
										}}
										className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
										style={{
											height: '34px',
											maxWidth: '144px',
											minWidth: '100px',
										}}
									/>
									<span className="px-1">&mdash;</span>
									<input
										type="date"
										name="maxDate"
										min={formatDateForInput(activeFilters.minDate)}
										value={formatDateForInput(activeFilters.maxDate)}
										onChange={e => {
											const maxDate = e.target.valueAsDate;
											if (maxDate === null) {
												return;
											}
											maxDate.setHours(23, 59, 59, 999);

											setActiveFilters(currentFilters => ({
												...currentFilters,
												maxDate,
											}));
										}}
										className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
										style={{
											height: '34px',
											maxWidth: '144px',
											minWidth: '100px',
										}}
									/>
									{(typeof activeFilters.minDate !== 'undefined' || typeof activeFilters.maxDate !== 'undefined') && (
										<FontAwesomeIcon
											icon={faTimes}
											size="1x"
											className="ml-2 cursor-pointer"
											onClick={() =>
												setActiveFilters(currentFilters => ({
													...currentFilters,
													minDate: undefined,
													maxDate: undefined,
												}))
											}
										/>
									)}
								</div>
							</label>
						</div>
					</div>
					<div>
						{activeTab === 'machines.consumption' && (
							<div className="relative">
								{!loading && consumptionBarChartData.length === 0 && (
									<div className="absolute mb-auto flex h-full w-full items-center justify-center">
										<p className="text-blue text-center text-2xl">{t('machines.noHistoryFound')}</p>
									</div>
								)}
								<BarChart
									data={consumptionBarChartData}
									width={getWindowWidth}
									height={700}
									margin={{
										top: 20,
										right: 10,
										left: 25,
										bottom: 150,
									}}
								>
									<Tooltip content={<CustomTooltipUsage />} />
									<CartesianGrid strokeDasharray="5 5" />
									<XAxis dataKey="name" interval={0} angle={-90} textAnchor="end" fontWeight={500} fontSize={14} stroke="#264D64" />
									<YAxis
										dataKey="value"
										tickCount={20}
										label={{
											value: t('machines.consumption'),
											angle: -90,
											position: 'left',
										}}
										stroke="#264D64"
									/>
									<Legend
										layout="vertical"
										verticalAlign="top"
										wrapperStyle={{
											top: '5px',
										}}
									/>
									<Bar dataKey="value" fill="#BA5BEF" stroke="#BA5BEF" name={t('machines.consumption')}></Bar>
								</BarChart>
							</div>
						)}
						{activeTab === 'common.days' && (
							<BarChart
								data={barChartData}
								height={700}
								width={getWindowWidth}
								margin={{
									top: 20,
									right: 10,
									left: 25,
									bottom: 150,
								}}
							>
								<Tooltip content={<CustomTooltipPeriod />} />
								<CartesianGrid strokeDasharray="5 5" />
								<XAxis dataKey="name" interval={0} angle={-90} textAnchor="end" fontWeight={500} fontSize={14} stroke="#264D64" />
								<YAxis
									dataKey="value"
									tickCount={20}
									label={{
										value: t('common.days'),
										angle: -90,
										position: 'left',
									}}
									stroke="#264D64"
								/>
								<Legend
									layout="vertical"
									verticalAlign="top"
									wrapperStyle={{
										top: '5px',
									}}
								/>
								<Bar dataKey="value" fill="#BA5BEF" stroke="#BA5BEF" name={t('common.days')}></Bar>
							</BarChart>
						)}
					</div>
				</div>
			}
		/>
	);
};

export default MachineInformation;
