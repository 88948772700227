import React from 'react';
import { faPrint, faBox, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { useReactToPrint } from 'react-to-print';
import { GetCaseForPrint, GetWebCasesByErpNo } from '../../GraphQL';
import QRCode from 'react-qr-code';
import Button from '@ssg/common/Components/Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetMovablesQuery, useGenerateMovableIdListMutation, useGetCaseForPrintQuery } from '@ssg/common/GraphQL/indexV2';


interface BaseProps {
	className?: string;
	disabled?: boolean;
	qrColor: string;
	qrColorText: string;
	qrText: string;
	hideButtonText?: boolean;
}

interface MovableProps extends BaseProps {
	movable: GetMovablesQuery['movables'][number];
}

interface CaseProps extends BaseProps {
	caseId: GetWebCasesByErpNo['cases'][number]['id'];
	type: 'BOX' | 'OTHER';
}

type Props = MovableProps | CaseProps;

const PREPRINT_COUNT = 24;

const ComponentToPrint: React.FC<Props> = ({ className, disabled = false, children, ...restProps }): React.ReactElement => {
	const { t } = useTranslation();

	const [generateMovableIdList] = useGenerateMovableIdListMutation({
		variables: { count: PREPRINT_COUNT },
	});
	const { data: caseData } = useGetCaseForPrintQuery({
		variables: { id: 'caseId' in restProps ? restProps.caseId : '' },
		skip: !('caseId' in restProps),
	});

	const typeRef = React.useRef('movable' in restProps ? 'm' : restProps.type === 'BOX' ? 'b' : 'o');

	const componentRef = React.useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onBeforeGetContent: async () => {
			if (typeof caseData !== 'undefined') {
				const idList = await generateMovableIdList().then(({ data }) => data?.generateMovableIdList ?? []);
				setPrintData(
					idList.map(id => ({
						case: caseData.case,
						qrValue: `c:${caseData.case.id}|${typeRef.current}:${id}|s:${t(restProps.qrText)}`,
						qrType: typeRef.current,
					})),
				);
			}
		},
	});

	const [printData, setPrintData] = React.useState<
		Array<{
			case: GetCaseForPrint['case'] | GetMovablesQuery['movables'][number]['case'];
			qrValue: string;
			qrType: string;
		}>
	>(() => {
		if ('movable' in restProps) {
			return [
				{
					case: restProps.movable.case,
					qrValue: `c:${restProps.movable.case.id}|m:${restProps.movable.id}|s:${t(restProps.qrText)}`,
					qrType: typeRef.current,
				},
			];
		}

		return [];
	});

	return (
		<div>
			<div className="hidden">
				{typeof printData !== 'undefined' && (
					<div ref={componentRef} className="flex flex-row flex-wrap justify-between py-1">
						{printData.map((pd, i) => (
							<div key={pd.qrValue} className="w-1/3">
								<div
									className="mx-3 flex flex-row justify-between"
									style={{
										marginTop: '0.475rem',
										marginBottom: '0.475rem',
									}}
								>
									<div
										className="text-2xs mr-2 flex max-h-full p-2 text-left font-semibold tracking-widest"
										style={{
											backgroundColor: restProps.qrColor,
											color: restProps.qrColorText,
											writingMode: 'vertical-rl',
											textOrientation: 'mixed',
										}}
									>
										<div className="self-center py-px">{pd.qrType === 'b' ? <FontAwesomeIcon icon={faBox} /> : <FontAwesomeIcon icon={faQuestionCircle} />}</div>
										<div className="h-24 py-1">
											{typeof restProps?.qrText !== 'undefined' ? t(restProps.qrText) : undefined}
											<br />
											<small>{pd.qrType === 'b' ? t('movable.labels.box') : t('common.miscellaneous')}</small>
										</div>
									</div>
									<div className="flex w-full flex-row justify-between">
										<div className="w-2/5">
											<h3 className="text-base font-semibold">{pd.case.erpNo}</h3>

											<div className="text-xs">
												<p>({pd.case.debitor.debitorId})</p>
											</div>
										</div>

										<QRCode value={pd.qrValue} size={100} className={classNames('self-center', { 'mr-2': (i + 1) % 3 === 0 })} />
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			{/* Old size w case info */}
			{/* <div className="hidden">
                {typeof printData !== 'undefined' && (
                    <div ref={componentRef} className="flex flex-row flex-wrap justify-between">
                        {printData.map(pd => (
                            <div className="w-1/2">
                                <div
                                    key={pd.qrValue}
                                    className="flex flex-row justify-between mx-2"
                                    style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                                >
                                    <div
                                        className="flex p-2 mr-2 max-h-full text-sm tracking-widest font-semibold text-left"
                                        style={{
                                            backgroundColor: restProps.qrColor,
                                            color: restProps.qrColorText,
                                            writingMode: 'vertical-rl',
                                            textOrientation: 'mixed',
                                        }}
                                    >
                                        <div>
                                            {pd.qrType === 'b' ? <FontAwesomeIcon icon={faBox} /> : <FontAwesomeIcon icon={faQuestionCircle} />}
                                        </div>
                                        <div className="py-1">
                                            {typeof restProps?.qrText !== 'undefined' ? t(restProps.qrText) : undefined}
                                            <br />
                                            <small>{pd.qrType === 'b' ? t('movable.labels.box') : t('common.miscellaneous')}</small>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row justify-between">
                                        <div className="w-1/2 space-y-1">
                                            <h3 className="font-semibold text-lg">{pd.case.erpNo}</h3>

                                            <div>
                                                <h4 className="font-semibold text-sm">{t('common.debitor')}</h4>

                                                <div className="text-xs">
                                                    {pd.case.debitor.company} ({pd.case.debitor.debitorId})
                                                </div>
                                            </div>

                                            <div>
                                                <h4 className="font-semibold text-sm">{t('common.damageAddress')}</h4>

                                                <div className="text-xs">
                                                    {pd.case.damage.contact.address.road} {pd.case.damage.contact.address.houseNumber}{' '}
                                                    {pd.case.damage.contact.address.floor ?? ''}
                                                </div>
                                                {pd.case.damage.contact.address.addressLineAlt !== null && (
                                                    <div className="text-xs">{pd.case.damage.contact.address.addressLineAlt}</div>
                                                )}
                                                <div className="text-xs">
                                                    {pd.case.damage.contact.address.postalCode} {pd.case.damage.contact.address.city}
                                                </div>
                                                <div className="text-xs">{pd.case.damage.contact.address.country}</div>
                                            </div>

                                            <div className="w-full h-4 border-b-1 border-black" />
                                        </div>

                                        <QRCode value={pd.qrValue} size={150} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div> */}

			<Button icon={'type' in restProps ? (restProps.type === 'BOX' ? faBox : faQuestionCircle) : faPrint} className={classNames(className)} onClick={handlePrint} disabled={disabled}>
				{restProps.hideButtonText !== true && (
					<div className="ml-2 text-left leading-tight">
						{t(restProps.qrText)}
						{'type' in restProps && (
							<>
								<br />
								<small>{t(`movable.labels.${restProps.type === 'BOX' ? 'box' : 'other'}`)}</small>
							</>
						)}
					</div>
				)}
			</Button>
		</div>
	);
};
export default ComponentToPrint;
