import { FC } from 'react';
import ReactQuill from 'react-quill';

// import 'react-quill/dist/quill.snow.css';

interface IProps {
	value: string;
	handleChange: (content: string) => void;
	editorId: string;
}

const TextEditor: FC<IProps> = ({ value, handleChange, editorId }) => {
	const modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			['blockquote', 'code-block'],

			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
			[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
			[{ direction: 'rtl' }], // text direction

			[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
			[{ header: [1, 2, 3, 4, 5, 6, false] }],

			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			[{ font: [] }],
			[{ align: [] }],

			['link'],
			['clean'],
		],
	};

	return (
		<div>
			<ReactQuill style={{ height: 400 }} id={editorId} defaultValue={value} onChange={handleChange} modules={modules} />
		</div>
	);
};

export default TextEditor;
