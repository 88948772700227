import React from 'react';
import { faBan, faCheck, faExclamationTriangle, faQuestion, faTimes, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ValidateInvoice } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';

interface Props {
	invoiceValidation: ValidateInvoice['validateInvoice'][number];
}

interface ValidationUI {
	icon: IconDefinition;
	color: 'text-green' | 'text-red' | 'text-yellow';
}

function getIconAndColor(invoiceValidationRule: ValidateInvoice['validateInvoice'][number]['value']): ValidationUI {
	const { valid, bypassAllowed } = invoiceValidationRule;

	if (valid) {
		return {
			icon: faCheck,
			color: 'text-green',
		};
	}

	if (bypassAllowed) {
		return {
			icon: faExclamationTriangle,
			color: 'text-yellow',
		};
	}

	return {
		icon: faBan,
		color: 'text-red',
	};
}

const dots =
	'........................................................................................................................................................................................................................................................................................................................................................................';

const InvoiceValidationLine: React.FC<Props> = ({ invoiceValidation }): React.ReactElement => {
	const { t } = useTranslation();

	const iconAndColor = getIconAndColor(invoiceValidation.value);

	return (
		<>
			<div className={classNames('flex w-full items-center justify-between', iconAndColor.color)}>
				<p className="mr-1 whitespace-nowrap">{t(`case.invoice.${invoiceValidation.key}`)}</p>
				<p className="flex-shrink-default overflow-hidden">{dots}</p>
				<FontAwesomeIcon icon={iconAndColor.icon} className="ml-1" />
			</div>
			{!invoiceValidation.value.valid && invoiceValidation.value.expectedResult && invoiceValidation.value.actualResult && (
				<>
					<p className="mt-0 ml-8 text-sm">
						<FontAwesomeIcon icon={faQuestion} className={classNames('mr-2', iconAndColor.color)} />
						<span>{t('common.expected')}:</span> {invoiceValidation.value.expectedResult}
					</p>
					<p className="mt-0 ml-8 text-sm">
						<FontAwesomeIcon icon={faTimes} className={classNames('text-red mr-2')} />
						<span>{t('common.actual')}:</span> {invoiceValidation.value.actualResult}
					</p>
				</>
			)}
		</>
	);
};

export default InvoiceValidationLine;
