import React from 'react';
import Table from '@ssg/common/Components/Table';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { useTranslation } from 'react-i18next';
import { calculateLinePrice } from './calculateLinePrice';
import { InvoiceLine } from './InvoiceModal';
import { DraftInvoiceFragment } from '@ssg/common/GraphQL';
import { DEFAULT_ENVFEE_NAME } from '../../../environmentalFeeHelper';

interface Props {
	lines: (InvoiceLine & { uom?: string })[];
	isScreening: boolean;
}

function lineTypeIsText(line: InvoiceLine): boolean {
	return line.type === 'Text'; // TODO: Make dynamic
}

const SummaryTable: React.FC<Props> = ({ lines, isScreening }) => {
	const { t } = useTranslation();

	return (
		<Table
			data={lines.filter(l => l.no !== DEFAULT_ENVFEE_NAME)}
			style={{ maxHeight: '400px' }}
			keySelector={(_, idx) => idx.toString()}
			groupSelector={isScreening ? _ => _.screeningTitle : undefined}
			columns={[
				{
					label: t('case.invoice.line.jobTaskNo'),
					selectFn: line => <span>{line.erpReferenceTask}</span>,
				},
				{
					label: t('case.invoice.line.typeCamelCase'),
					selectFn: line => <span>{line.type}</span>,
				},
				{
					label: t('case.invoice.line.no'),
					selectFn: line => <span>{lineTypeIsText(line) ? '' : line.no}</span>,
				},
				{
					label: t('case.invoice.line.description'),
					selectFn: line => <span>{line.description}</span>,
				},
				...(isScreening
					? [
						{
							label: t('case.invoice.line.screeningPosition'),
							selectFn: (line: DraftInvoiceFragment['lines'][number]) => <span>{line.screeningLinePosition}</span>,
						},
					]
					: []),
				{
					label: t('case.invoice.line.unitPrice'),
					selectFn: line => <span>{lineTypeIsText(line) ? '' : addThousandSeperator(line.unitPrice ?? 0)}</span>,
					align: 'RIGHT',
				},
				{
					label: t('case.invoice.line.quantity'),
					selectFn: line => <span>{lineTypeIsText(line) ? '' : line.quantity}</span>,
					align: 'RIGHT',
				},
				{
					label: t('case.invoice.line.uom'),
					selectFn: line => <span>{line.uom ?? ''}</span>,
					align: 'LEFT',
				},
				{
					label: t('case.invoice.line.total'),
					selectFn: line => <span>{lineTypeIsText(line) ? '' : addThousandSeperator(calculateLinePrice(line))}</span>,
					align: 'RIGHT',
				},
			]}
		/>
	);
};

export default SummaryTable;
