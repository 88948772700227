import React from 'react';
import { useQuery } from '@apollo/client';
import { faArchive, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetMovableInvoiceableStatusOptions, GetMovableInvoiceableStatusOptionsVariables, GetMovables, GetMovablesVariables } from '../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	caseId: string;
	erpNo: string;
}

const GET_MOVABLES = loader('src/GraphQL/Movables/GetMovables.gql');
const GET_MOVABLE_INVOICEABLE_STATUSES = loader('src/GraphQL/Movables/GetMovableInvoiceableStatusOptions.gql');

const MovablesOverviewModal: React.FC<Props> = ({ caseId, erpNo }) => {
	const { t } = useTranslation();

	const [showModal, setShowModal] = React.useState(false);
	const { data } = useQuery<GetMovables, GetMovablesVariables>(GET_MOVABLES, {
		variables: { case: caseId, includeCompleted: false },
		skip: !showModal,
	});
	const { data: statuses } = useQuery<GetMovableInvoiceableStatusOptions, GetMovableInvoiceableStatusOptionsVariables>(GET_MOVABLE_INVOICEABLE_STATUSES, {
		variables: { erpNo },
		skip: !showModal,
	});

	return (
		<>
			<TextButton className="mr-3" text="case.viewMovableOverview" icon={faArchive} onClick={() => setShowModal(true)} />

			<Modal
				title="case.movableOverview"
				size={ModalSize.SMALL}
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<table className="-mx-1 w-full">
						<thead>
							<tr>
								<th className="border-1 w-3/5 border-gray-300 px-1 text-left">{t('common.status')}</th>
								<th className="border-1 w-1/5 border-gray-300 px-1 text-left">{t('movable.volumeUnit')}</th>
								<th className="border-1 w-1/5 border-gray-300 px-1 text-left">{t('movable.chargeable')}</th>
							</tr>
						</thead>

						<tbody>
							{statuses?.movableInvoiceableStatusOptions.map(({ key: status, value: invoiceable }) => (
								<tr key={status}>
									<td className="border-1 w-3/5 border-gray-300 px-1">{status}</td>
									<td className="border-1 w-1/5 border-gray-300 px-1">{data?.movables.reduce((sum, movable) => sum + (movable.status === status ? movable.volume : 0), 0)}</td>
									<td className="border-1 w-1/5 border-gray-300 px-1">{invoiceable && <FontAwesomeIcon icon={faCheck} />}</td>
								</tr>
							))}

							<tr>
								<td className="border-1 w-3/5 border-gray-300 px-1">&nbsp;</td>
								<td className="border-1 w-1/5 border-gray-300 px-1">&nbsp;</td>
								<td className="border-1 w-1/5 border-gray-300 px-1">&nbsp;</td>
							</tr>
							<tr>
								<td className="border-1 w-3/5 border-gray-300 px-1 font-semibold">{t('movable.chargeable')}</td>
								<td className="border-1 w-1/5 border-gray-300 px-1 font-semibold">
									{data?.movables.reduce(
										(sum, movable) =>
											sum +
											(statuses?.movableInvoiceableStatusOptions.some(({ key: status, value: invoiceable }) => movable.status === status && invoiceable) ? movable.volume : 0),
										0,
									)}
								</td>
								<td className="border-1 w-1/5 border-gray-300 px-1">&nbsp;</td>
							</tr>
							<tr>
								<td className="border-1 w-3/5 border-gray-300 px-1 font-semibold">{t('movable.nonChargeable')}</td>
								<td className="border-1 w-1/5 border-gray-300 px-1 font-semibold">
									{data?.movables.reduce(
										(sum, movable) =>
											sum +
											(statuses?.movableInvoiceableStatusOptions.some(({ key: status, value: invoiceable }) => movable.status === status && !invoiceable) ? movable.volume : 0),
										0,
									)}
								</td>
								<td className="border-1 w-1/5 border-gray-300 px-1">&nbsp;</td>
							</tr>
							<tr>
								<td className="border-1 w-3/5 border-gray-300 px-1 font-bold">{t('movable.totalInStorage')}</td>
								<td className="border-1 w-1/5 border-gray-300 px-1 font-bold">{data?.movables.reduce((sum, movable) => sum + movable.volume, 0)}</td>
								<td className="border-1 w-1/5 border-gray-300 px-1">&nbsp;</td>
							</tr>
						</tbody>
					</table>
				}
			/>
		</>
	);
};
export default MovablesOverviewModal;
