import { DrivingSlipStatus, GetDrivingSlipsNewPlannerQuery, GetDrivingSlipsNewPlannerQueryVariables, useGetDrivingSlipsNewPlannerQuery } from '@ssg/common/GraphQL/indexV2';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Fuse from 'fuse.js';
import React from 'react';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import TextButton from '@ssg/common/Components/TextButton';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import Loading from '@ssg/common/Components/Loading';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import PlannerDrivingSlipFilter from './PlannerDrivingSlipFilter';
import PlannerDrivingSlipCard from './PlannerDrivingSlipCard';
import useArrayToggler from '@ssg/common/Hooks/useArrayToggler';
import PlannerCustomDragLayer from './PlannerCustomDragLayer';
import PlannerContext from './PlannerContext';
import { getWeekDay } from './PlannerHelpers';

export type MergedDrivingSlip = Omit<GetDrivingSlipsNewPlannerQuery['plannerDrivingSlips'][number], 'id' | 'end' | 'start' | 'comment'> & {
	merged: Array<Pick<GetDrivingSlipsNewPlannerQuery['plannerDrivingSlips'][number], 'id' | 'end' | 'start' | 'comment' | 'driver' | 'status' | 'estimatedHours' | 'car' | 'materials'>>;
	startDate: string;
	startDateTime: string;
};

enum SortingOptions {
	DATE_PRIORITY = 0,
	PRIORITY_DATE = 1,
}

const getDateAndDay = (date: string): string => {
	const dateString = dateToDateTimeString(date, true);
	const weekDay = getWeekDay(date);
	return `${weekDay} ${dateString}`;
};

const PlannerDrivingSlips: React.FC = (): React.ReactElement => {

	const { t } = useTranslation();

	const [drivingSlipsSearchTerm, setdrivingSlipsSearchTerm] = useDebouncedState('', 100);
	const [showFilters, setShowFilters] = React.useState(false);
	const [openedDates, toggleDate, toggleAll, clear] = useArrayToggler<string>([]);
	const [openAllDates, setOpenAllDates] = React.useState(false);
	const [halfDay, setHalfDay] = React.useState(false);
	const { activeDrivingSlipsFilters } = React.useContext(PlannerContext);

	const getDrivingSlipsPlannerVariables: GetDrivingSlipsNewPlannerQueryVariables = React.useMemo(() => ({
		status: DrivingSlipStatus.Unplanned,
		damageCategories: activeDrivingSlipsFilters.damageCategories,
		damageCauses: activeDrivingSlipsFilters.damageCauses,
		departments: activeDrivingSlipsFilters.departments,
		locations: activeDrivingSlipsFilters.locations,
	}
	), [activeDrivingSlipsFilters.damageCategories, activeDrivingSlipsFilters.damageCauses, activeDrivingSlipsFilters.departments, activeDrivingSlipsFilters.locations]);

	const {
		data,
		loading: drivingSlipsDataLoading,
	} = useGetDrivingSlipsNewPlannerQuery({
		fetchPolicy: 'cache-and-network',
		context: { debatch: true },
		variables: getDrivingSlipsPlannerVariables,
	});

	const [isDroppedArray, setIsDroppedArray] = React.useState<string[]>([]);

	React.useEffect(() => {
		setIsDroppedArray([]);
	}, [data]);

	const drivingSlipsData = React.useMemo(() => {
		return (data?.plannerDrivingSlips ?? []).filter(ds => !isDroppedArray.includes(ds.id)).reduce((arr, ds) => {
			// Bundle driving slips in same series
			const { id, start, end, comment, driver, car, materials, ...drivingSlip } = ds;
			let existing = arr.find(eds => eds.series === drivingSlip.series);

			if (typeof existing === 'undefined') {
				const index =
					arr.push({
						...drivingSlip,
						driver,
						department: {
							...drivingSlip.department,
							departmentNumberStr: String(drivingSlip.department.departmentNumber),
						},
						merged: [],
						startDate: dateToDateOnlyString(new Date(start)),
						startDateTime: start,
						car,
						materials,
					}) - 1;
				existing = arr[index];
			}

			existing.merged.push({ id, start, end, comment, driver, status: ds.status, estimatedHours: ds.estimatedHours, car, materials });
			existing.merged.sort((a, b) => a.start.localeCompare(b.start)).filter(m => m.status !== DrivingSlipStatus.Unplanned);
			existing.startDate = dateToDateOnlyString(new Date(start));
			existing.startDateTime = start;

			return arr;
		}, [] as Array<MergedDrivingSlip & { department: { departmentNumberStr: string } }>);
	}, [data?.plannerDrivingSlips, isDroppedArray]);

	const [sortingOption, setSortingOption] = useStorageState<SortingOptions>(window.sessionStorage, 'sortingOption', SortingOptions.DATE_PRIORITY);

	const handleSorting = (e: string) => {
		const selected = parseInt(e);
		switch (selected) {
			case 0:
				setSortingOption(SortingOptions.DATE_PRIORITY);
				break;
			case 1:
				setSortingOption(SortingOptions.PRIORITY_DATE);
				break;
			default:
				break;
		}
	};



	const activeFilteredDrivingSlips = React.useMemo(
		() =>
			drivingSlipsData
				.filter(d => halfDay ? d.halfDay : true)
				.filter(d => activeDrivingSlipsFilters.debitors.length === 0 || activeDrivingSlipsFilters.debitors.flatMap(d => d).includes(d.case.debitor.company))
				.filter(d => activeDrivingSlipsFilters.postalCodes.length === 0 || activeDrivingSlipsFilters.postalCodes.includes(d.case.damage.contact.address.postalCode))
				.filter(d => activeDrivingSlipsFilters.projectManagers.length === 0 || activeDrivingSlipsFilters.projectManagers.flatMap(d => d).includes(d.case.projectManager?.id ?? 'UNKNOWN'))
				.filter(d => activeDrivingSlipsFilters.erpNos.length === 0 || activeDrivingSlipsFilters.erpNos.includes(d.case.erpNo)),
		[drivingSlipsData, halfDay, activeDrivingSlipsFilters.debitors, activeDrivingSlipsFilters.postalCodes, activeDrivingSlipsFilters.projectManagers, activeDrivingSlipsFilters.erpNos],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(activeFilteredDrivingSlips, {
				shouldSort: true,
				threshold: 0.1,
				keys: [
					'case.debitor.company',
					'case.damage.contact.address.road',
					'case.damage.contact.address.houseNumber',
					'case.damage.contact.address.floor',
					'case.damage.contact.address.city',
					'case.damage.contact.address.postalCode',
					'department.name',
					'department.departmentNumberStr',
					'location.name',
					'case.damage.contact.name',
					'case.damage.category.name',
					'case.damage.cause.name',
					'driver.name',
					'driver.email',
					'driver.phone',
					'case.erpNo',
					'case.projectManager.name',
				],
			}),
		[activeFilteredDrivingSlips],
	);

	const filteredDrivingSlips = React.useMemo(() => {
		return drivingSlipsSearchTerm.length > 0
			? fuse
				.search({
					$and: tokenizeStringWithQuotesBySpaces(drivingSlipsSearchTerm).map((searchToken: string) => {
						const orFields: Fuse.Expression[] = [
							{ name: searchToken },
							{ 'case.debitor.company': searchToken },
							{ 'case.damage.contact.address.road': searchToken },
							{ 'case.damage.contact.address.houseNumber': searchToken },
							{ 'case.damage.contact.address.floor': searchToken },
							{ 'case.damage.contact.address.city': searchToken },
							{ 'case.damage.contact.address.postalCode': searchToken },
							// { 'department.name': searchToken },
							// {
							// 	'department.departmentNumberStr': searchToken,
							// },
							{ 'case.damage.contact.name': searchToken },
							{ 'driver.name': searchToken },
							{ 'driver.email': searchToken },
							{ 'driver.phone': searchToken },
							{ 'case.erpNo': searchToken },
							{ 'case.projectManager.name': searchToken },
						];

						return {
							$or: orFields,
						};
					}),
				})
				.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
				.map(v => v.item)
			: activeFilteredDrivingSlips;
	}, [activeFilteredDrivingSlips, drivingSlipsSearchTerm, fuse]);

	// Sort drivingslips based on sorting dropdown
	const sortedDrivingSlips = React.useMemo(() => {
		let result = filteredDrivingSlips;

		const drivingSlipsCopy = result.slice();

		// Sort the drivingslips by selected sorting option
		switch (sortingOption) {
			case SortingOptions.DATE_PRIORITY:
				drivingSlipsCopy.sort((a, b) => {
					const aDate = new Date(a.startDateTime).getTime();
					const bDate = new Date(b.startDateTime).getTime();

					return aDate - bDate;
				});
				break;

			case SortingOptions.PRIORITY_DATE:
				drivingSlipsCopy.sort((a, b) => (a === b ? 0 : a.urgent ? -1 : 1));
				break;
		}

		result = drivingSlipsCopy;

		return result;
	}, [filteredDrivingSlips, sortingOption]);

	const triggerOpenAllDates = (open: boolean) => {
		const dates = sortedDrivingSlips.map(ds => ds.startDate);
		if (open) {
			toggleAll(dates);
			console.log('ADD ALL', open, dates);
		} else {
			clear();
			console.log('REMOVE ALL', open, dates);
		}
		setOpenAllDates(open);
	};
	return (
		<>
			<PlannerDrivingSlipFilter
				drivingSlipsData={data?.plannerDrivingSlips ?? []}
				showFilters={showFilters}
				setFilterTerm={setdrivingSlipsSearchTerm}
				halfDay={halfDay}
				setHalfDay={setHalfDay}
			/>

			<div className="mt-px flex items-end justify-between">
				<TextButton
					text={showFilters ? 'common.hideFilters' : 'common.showFilters'}
					icon={showFilters ? faChevronUp : faChevronDown}
					onClick={() => setShowFilters(!showFilters)}
				/>
				<select className="rounded-default py-1 text-xs" onChange={e => handleSorting(e.target.value)}>
					<option selected={sortingOption === SortingOptions.DATE_PRIORITY} value={SortingOptions.DATE_PRIORITY}>
						Dato &gt; Prioritet
					</option>
					<option selected={sortingOption === SortingOptions.PRIORITY_DATE} value={SortingOptions.PRIORITY_DATE}>
						Prioritet &gt; Dato
					</option>
				</select>
			</div>

			<div className="text-blue mt-2 text-sm flex flex-row justify-between">
				<p className="font-semibold">{t('common.quantity')} <span className="lowercase">{t('drivingSlips.overviewTitle')}:</span> {sortedDrivingSlips.length}</p>
				<TextButton icon={openAllDates ? faChevronUp : faChevronDown} onClick={() => triggerOpenAllDates(!openAllDates)} text={openAllDates ? 'drivingSlips.closeAllDates' : 'drivingSlips.openAllDates'} />
			</div>

			<hr className="mb-2" />

			<div className="relative h-full overflow-y-auto">
				<PlannerCustomDragLayer />
				{drivingSlipsDataLoading && <Loading title="planner.gettingDrivingSlips" />}
				{sortedDrivingSlips.length ? (
					sortedDrivingSlips.map((d, i, arr) => {
						const firstAppearanceOfDate = arr.findIndex(ds => ds.startDate === d.startDate);
						const isFirstAppearance = firstAppearanceOfDate === i;
						const numberOfAppearences = arr.filter(ds => ds.startDate === d.startDate).length;
						return (
							<div className="w-full" key={d.series + d.merged[0].id}>
								{isFirstAppearance && (
									<div
										className={classNames('bg-blue sticky top-0 mb-1 flex w-full cursor-pointer justify-between py-1 px-4 font-bold text-white', { 'mt-4': i !== 0 })}
										onClick={() => toggleDate(d.startDate)}
									>
										<p>
											{getDateAndDay(d.startDate)} ({numberOfAppearences})
										</p>
										<TextButton
											iconClassName="text-white"
											iconSize="lg"
											icon={openedDates.includes(d.startDate) ? faChevronUp : faChevronDown}
											onClick={() => toggleDate(d.startDate)}
										/>
									</div>
								)}
								{openedDates.includes(d.startDate) && (
									<PlannerDrivingSlipCard
										setIsDroppedArray={setIsDroppedArray}
										key={d.series + d.merged[0].id}
										drivingSlip={d}
									/>
								)}
							</div>
						);
					})
				) : (
					<p className="text-xs">{t('planner.noActiveDrivingSlips')}</p>
				)}
			</div>
		</>
	);
};

export default PlannerDrivingSlips;