import React from 'react';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GetWebCatalogCustomers_catalogCustomers, GetWebDebitors } from '../../../GraphQL';
import { loader } from 'graphql.macro';
import { useForm /*, useWatch*/ } from 'react-hook-form';
import { CatalogFilter } from './CatalogOverview';
import Button from '@ssg/common/Components/Button';
import Pill from '@ssg/common/Components/Pill';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';

const GET_DEBITORS = loader('src/GraphQL/Debitors/GetWebDebitors.gql');

interface Props {
	onFilter: React.Dispatch<React.SetStateAction<CatalogFilter>>;
	activeFilters: CatalogFilter;
	setFilterTerm: (v: string) => void;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
}

const CatalogOverviewFilter: React.FC<Props> = ({ onFilter, activeFilters, setFilterTerm, catalogCustomers }) => {
	const { t } = useTranslation();

	const dummyForm = useForm();

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const [customerSearchText, setCustomerSearchText] = React.useState('');

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);
	React.useEffect(() => {
		setCustomers(catalogCustomers.filter(ct => ct.name.toLowerCase().includes(customerSearchText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, customerSearchText]);

	const debitorMapRef = React.useRef<Record<string, string>>({});
	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: {
			searchText: debitorSearchText,
		},
		skip: debitorSearchText.length === 0,
		onCompleted(data) {
			data.debitors.forEach(d => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
	});

	const resetFilters = () => {
		onFilter({
			debitors: null,
			smsService: null,
			verified: null,
			debitorVerified: null,
			noDebitor: null,
			customerIds: null,
		});
	};

	return (
		<div className="text-blue my-2 mx-0">
			<div className="flex w-full flex-row flex-wrap">
				<div className="mr-3">
					<label className="block text-xs font-medium">{t('common.search')}</label>
					<div className="flex">
						<div className="relative w-full">
							<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<Input name="overview-search" placeholder={t('catalog.searchPlaceholder')} className="pr-8 text-sm lg:w-full" onChange={e => setFilterTerm(e.target.value)} />
						</div>
					</div>
				</div>

				<div className="mr-3">
					<label className="mb-1 block text-xs font-medium">
						{t('catalog.customer')}
						<SearchableSelect
							key="customer-filter"
							control={dummyForm.control}
							name="customerFilter"
							options={customers}
							searchFn={searchText => setCustomerSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									onFilter(currentFilters => ({
										...currentFilters,
										customerIds: [...(activeFilters.customerIds ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={-1}
							isLoading={false}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.customerIds?.map(d => (
							<li key={d}>
								<Pill
									onClick={() =>
										onFilter(() => ({
											...activeFilters,
											customerIds: activeFilters.customerIds?.filter(ed => ed !== d) ?? [],
										}))
									}
								>
									{catalogCustomers.filter(cc => cc.id === d)[0]?.name ?? ''}
								</Pill>
							</li>
						))}
					</ul>
				</div>

				<div className="mr-3">
					<label className="mb-1 block text-xs font-medium">
						{t('common.debitor')}
						<SearchableSelect
							key="debitor-filter"
							control={dummyForm.control}
							name="debitorFilter"
							options={(debitorsData?.debitors ?? [])
								.filter(d => d.debitorId !== null)
								.filter((d, i, a) => a.indexOf(d) === i)
								.map(d => ({
									value: d.debitorId ?? '?',
									label: d.company,
								}))}
							searchFn={searchText => setDebitorSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									onFilter(currentFilters => ({
										...currentFilters,
										debitors: [...(activeFilters.debitors ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={2}
							isLoading={debitorsLoading}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.debitors?.map(d => (
							<li key={d}>
								<Pill
									onClick={() =>
										onFilter(() => ({
											...activeFilters,
											debitors: activeFilters.debitors?.filter(ed => ed !== d) ?? [],
										}))
									}
								>
									{debitorMapRef.current[d]} ({d})
								</Pill>
							</li>
						))}
					</ul>
				</div>

				<div className="mr-3">
					<label className="block text-xs font-medium">{t('catalog.smsService')}</label>
					<ButtonGroup
						unselectable
						items={[
							{
								title: t('common.yes'),
								isActive: activeFilters.smsService === true,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										smsService: activeFilters.smsService !== true ? true : null,
									})),
							},
							{
								title: t('common.no'),
								isActive: activeFilters.smsService === false,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										smsService: activeFilters.smsService !== false ? false : null,
									})),
							},
						]}
					/>
				</div>

				<div className="mr-3">
					<label className="block text-xs font-medium">{t('catalog.verified')}</label>
					<ButtonGroup
						unselectable
						items={[
							{
								title: t('common.yes'),
								isActive: activeFilters.verified === true,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										verified: activeFilters.verified !== true ? true : null,
									})),
							},
							{
								title: t('common.no'),
								isActive: activeFilters.verified === false,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										verified: activeFilters.verified !== false ? false : null,
									})),
							},
						]}
					/>
				</div>

				<div className="mr-3">
					<label className="block text-xs font-medium">{t('catalog.debitorVerified')}</label>
					<ButtonGroup
						unselectable
						items={[
							{
								title: t('common.yes'),
								isActive: activeFilters.debitorVerified === true,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										debitorVerified: activeFilters.debitorVerified !== true ? true : null,
									})),
							},
							{
								title: t('common.no'),
								isActive: activeFilters.debitorVerified === false,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										debitorVerified: activeFilters.debitorVerified !== false ? false : null,
									})),
							},
						]}
					/>
				</div>

				<div className="mr-3">
					<label className="block text-xs font-medium">{t('catalog.filters.noDebitor')}</label>
					<ButtonGroup
						unselectable
						items={[
							{
								title: t('common.yes'),
								isActive: activeFilters.noDebitor === true,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										noDebitor: activeFilters.noDebitor !== true ? true : null,
									})),
							},
							{
								title: t('common.no'),
								isActive: activeFilters.noDebitor === false,
								onClick: (): void =>
									onFilter(currentFilters => ({
										...currentFilters,
										noDebitor: activeFilters.noDebitor !== false ? false : null,
									})),
							},
						]}
					/>
				</div>

				<div className="mt-4">
					<Button secondary text={t('common.resetFilter')} className="text-xs" onClick={resetFilters} />
				</div>
			</div>
		</div>
	);
};

export default CatalogOverviewFilter;
