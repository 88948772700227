import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import React from 'react';
import { DrivingSlipFilter } from './DrivingSlipOverview';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { CompleteDrivingSlips, CompleteDrivingSlipsVariables, GetDrivingSlipIDs, GetDrivingSlipIDsVariables, GetDrivingSlipsOverview } from '../../GraphQL';
import { loader } from 'graphql.macro';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Alert from '@ssg/common/Components/Alert';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import Button from '@ssg/common/Components/Button';

interface Props {
	visible: boolean;
	close(): void;
	activeFilters: DrivingSlipFilter;
	searchTerm: string;
	refetchDrivingSlips: () => Promise<ApolloQueryResult<GetDrivingSlipsOverview>>;
}

const GET_DRIVING_SLIPS = loader('src/GraphQL/DrivingSlips/GetDrivingSlipsOverview.gql');
const COMPLETE_DRIVING_SLIPS = loader('src/GraphQL/DrivingSlips/CompleteDrivingSlips.gql');


const CompleteDrivingSlipsModal: React.FC<Props> = ({ visible, close, activeFilters, searchTerm, refetchDrivingSlips }): React.ReactElement => {

	const { t } = useTranslation();

	const { data, loading } = useQuery<GetDrivingSlipIDs, GetDrivingSlipIDsVariables>(GET_DRIVING_SLIPS, {
		fetchPolicy: 'no-cache',
		context: { debatch: true },
		variables: {
			urgent: activeFilters.urgent,
			exceeded: activeFilters.exceeded,
			departments: activeFilters.departments,
			locations: activeFilters.locations,
			damageCauses: activeFilters.damageCauses,
			damageCategories: activeFilters.damageCategories,
			drivers: activeFilters.drivers,
			minStartDate: activeFilters.minStartDate ? formatDateForInput(new Date(activeFilters.minStartDate ?? '')) : undefined,
			maxStartDate: activeFilters.maxStartDate ? formatDateForInput(new Date(activeFilters.maxStartDate ?? '')) : undefined,
			minEndDate: activeFilters.minEndDate ? formatDateForInput(new Date(activeFilters.minEndDate ?? '')) : undefined,
			maxEndDate: activeFilters.maxEndDate ? formatDateForInput(new Date(activeFilters.maxEndDate ?? '')) : undefined,
			noDriver: activeFilters.noDriver,
			status: activeFilters.status,
			searchString: searchTerm || null,
		},
	});

	const [completeDrivingSlips, { loading: completeLoading }] = useMutation<CompleteDrivingSlips, CompleteDrivingSlipsVariables>(COMPLETE_DRIVING_SLIPS);
	return (
		<Modal
			visible={visible}
			size={ModalSize.MEDIUM}
			title="drivingSlips.complete"
			body={
				<div className="text-blue">
					<Alert icon={faExclamationCircle} warning text="drivingSlips.completeWarning" />
					{loading &&
						<p className="pr-2">
							<FontAwesomeIcon icon={faSpinner} className="text-blue mr-1 animate-spin" size="1x" />
							{t('drivingSlips.completePrepare')}
						</p>
					}
					{typeof data !== 'undefined' && <p>{t('drivingSlips.completeConfirm', { count: data.drivingSlips.length })}</p>}
				</div>
			}
			footer={
				<div className="flex flex-row space-x-2">
					<Button
						primary
						loading={completeLoading}
						text="drivingSlips.complete"
						disabled={loading}
						onClick={async () => {
							if (data) {
								await completeDrivingSlips({
									variables: {
										ids: data.drivingSlips.map(ds => ds.id),
									},
								});
								await refetchDrivingSlips();
								close();
							}
						}} />
					<Button secondary text="common.cancel" onClick={close} />
				</div>
			}
			close={close}
		/>
	);
};

export default CompleteDrivingSlipsModal;