import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetPaymentSupplements_paymentSupplements, GetTimeTrackings_timeTrackings_addonLines, SupplementType } from '../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { UseFormMethods } from 'react-hook-form';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { ITimeRegistration } from '../../Schemas/ITimeRegistration';
import Dropdown from '@ssg/common/Components/Dropdown';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';
import Input from '@ssg/common/Components/Input';

interface TimeRegGuide {
	workTypeKey: string;
	desc: string;
}

const fakeTimeRegGuides: TimeRegGuide[] = [
	{ workTypeKey: '510', desc: 'Vagtleder tillæg anvendes når...' },
	{ workTypeKey: '600', desc: 'Vagtmand tillæg anvendes når...' },
	{ workTypeKey: '8004', desc: 'Smuds tillæg anvendes når...' },
];

interface SupLine {
	lineIndex: number;
	data?: GetTimeTrackings_timeTrackings_addonLines | null;
	paymentSupplements: (GetPaymentSupplements_paymentSupplements | null)[];
	paymentSupplementsSelect: SelectOption[];
	removeLine: () => void;
	subForm: UseFormMethods<ITimeRegistration>;
	timeRegistration: boolean;
}

const SupplementLine: React.FC<SupLine> = ({ lineIndex, data, paymentSupplements, paymentSupplementsSelect, removeLine, subForm, timeRegistration }) => {
	const { t } = useTranslation();

	const [activeSupplementCode, setActiveSupplementCode] = React.useState(data?.paymentSupplementCode);

	const { register, getValues, setValue, setError, errors } = subForm;

	const convertTypeToEnum = (type: string): SupplementType | undefined => {
		switch (type) {
			case 'Stand-Alone':
				return SupplementType.STAND_ALONE;
			case 'Surcharge':
				return SupplementType.SURCHARGE;
			case 'Piecework':
				return SupplementType.PIECEWORK;
			default:
				return undefined;
		}
	};

	React.useEffect(() => {
		setValue(`addonLines[${lineIndex}].paymentSupplementName`, paymentSupplements.find(s => s?.code === activeSupplementCode)?.description ?? '');
		setValue(`addonLines[${lineIndex}].supplementUom`, paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM ?? '');
		setValue(`addonLines[${lineIndex}].supplementType`, convertTypeToEnum(paymentSupplements.find(s => s?.code === activeSupplementCode)?.type ?? ''));
		setValue(`addonLines[${lineIndex}].workTypeCode`, paymentSupplements.find(s => s?.code === activeSupplementCode)?.workTypeCode ?? '');
	}, [activeSupplementCode, lineIndex, paymentSupplements, setValue]);

	return (
		<div className="flex flex-row">
			<div className="mr-3 w-full">
				<Dropdown
					name={`addonLines[${lineIndex}].paymentSupplementCode`}
					className="mt-2"
					data={[{ label: '', value: '' }, ...paymentSupplementsSelect]}
					onChange={e => setActiveSupplementCode(e.currentTarget.value)}
					innerRef={register}
					defaultValue={data?.paymentSupplementCode ?? ''}
					disabled={!timeRegistration}
					errorMessage={errors.addonLines ? errors.addonLines[lineIndex]?.paymentSupplementCode?.message : ''}
				/>
			</div>

			<Input name={`addonLines[${lineIndex}].paymentSupplementName`} defaultValue={data?.paymentSupplementName ?? ''} className="hidden" innerRef={register()} />

			<Input name={`addonLines[${lineIndex}].supplementUom`} defaultValue={data?.supplementUom ?? ''} className="hidden" innerRef={register()} />

			<Input name={`addonLines[${lineIndex}].supplementType`} defaultValue={data?.supplementType ?? ''} className="hidden" innerRef={register()} />

			<Input name={`addonLines[${lineIndex}].workTypeCode`} defaultValue={data?.workTypeCode ?? ''} className="hidden" innerRef={register} />

			<div className="mr-3">
				<Input
					name={`addonLines[${lineIndex}].hours`}
					type="number"
					step={0.01}
					lang="da"
					inputMode="decimal"
					className="mt-2"
					unit={paymentSupplements.find(s => s?.code === activeSupplementCode)?.uOM}
					innerRef={register({ valueAsNumber: true })}
					onChange={() => {
						if (paymentSupplements.find(s => s?.code === activeSupplementCode)?.type !== 'Surcharge') {
							return;
						}

						const lineTotal = getValues()
							.addonLines.filter(l => paymentSupplements.find(s => s?.code === l.paymentSupplementCode)?.type === 'Surcharge')
							.reduce((acc, curr) => acc + (!isNaN(curr.hours) ? curr.hours : 0), 0);

						const totalhours = getValues().hours;

						if (lineTotal > totalhours) {
							setError(`addonLines[${lineIndex}].hours`, {
								type: 'max',
								message: t('timeRegistration.supplementHoursHigher'),
							});
						}
					}}
					onWheel={e => e.currentTarget.blur()} // Used for disabling scroll to change number value
					defaultValue={data?.hours}
					errorMessage={errors.addonLines ? errors.addonLines[lineIndex]?.hours?.message : ''}
				/>
			</div>

			<div className="mt-5 self-start">
				<TextButton icon={faTimes} onClick={() => removeLine()} iconClassName="text-red" />
			</div>

			{typeof paymentSupplementsSelect.find(s => s.value === activeSupplementCode)?.label !== 'undefined' && (
				<div className="ml-2 mt-4 self-start">
					<Popover
						placement="right"
						content={
							<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
								<p className="font-bold">{paymentSupplementsSelect.find(s => s.value === activeSupplementCode)?.label}</p>
								{fakeTimeRegGuides.find(g => g.workTypeKey === activeSupplementCode)?.desc}
							</div>
						}
					>
						<div>
							<FontAwesomeIcon icon={faInfoCircle} />
						</div>
					</Popover>
				</div>
			)}
		</div>
	);
};

export default SupplementLine;
