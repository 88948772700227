import React from 'react';
import { useQuery } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetVendors } from '../../GraphQL';
import { tokenizeStringWithQuotesBySpaces } from '../../helper';
import Table from '@ssg/common/Components/Table';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';
import Fuse from 'fuse.js';
import Box from '../../Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import VendorsOverviewFilter from './VendorsOverviewFilter';

const GET_VENDORS = loader('src/GraphQL/Requisitions/GetVendors.gql');

interface Props {
	locationOptions: SelectOption[];
}

export interface VendorFilter {
	type?: string;
	locations?: string[];
}

const VendorsOverview: React.FC<Props> = ({ locationOptions }): React.ReactElement => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = React.useState('');
	const [activeFilter, setActiveFilter] = React.useState<VendorFilter>({
		type: undefined,
		locations: undefined,
	});

	const { data: vendors, loading: vendorsLoading } = useQuery<GetVendors>(GET_VENDORS);

	const typeOptions = React.useMemo(() => {
		if (typeof vendors !== 'undefined') {
			const allTypes = Array.from(new Set(vendors.vendors.map(v => v.type)))
				.sort((a, b) => a.localeCompare(b))
				.map(t => ({ label: t, value: t }));
			return allTypes;
		} else {
			return [];
		}
	}, [vendors]);

	const filteredVendors = React.useMemo(
		() =>
			vendors?.vendors
				.slice()
				.filter(v => {
					if (typeof activeFilter.type !== 'undefined') {
						return activeFilter.type === v.type;
					} else {
						return true;
					}
				})
				.filter(v => {
					if (typeof activeFilter.locations !== 'undefined') {
						console.log(activeFilter.locations);
						return activeFilter.locations.includes(v.ssgLocation.charAt(0) + v.ssgLocation.substring(1).toLowerCase());
					} else {
						return true;
					}
				}) ?? [],
		[activeFilter.locations, activeFilter.type, vendors?.vendors],
	);

	const fuse = React.useMemo(
		() =>
			new Fuse(filteredVendors, {
				shouldSort: true,
				threshold: 0.1,
				keys: ['company', 'address.addressLine', 'address.postalCode'],
			}),
		[filteredVendors],
	);

	const searchFilteredVendors =
		searchTerm.length > 0
			? fuse
					.search({
						$and: tokenizeStringWithQuotesBySpaces(searchTerm).map((searchToken: string) => {
							const orFields: Fuse.Expression[] = [{ company: searchToken }, { 'address.postalCode': searchToken }, { 'address.addressLine': searchToken }];

							return {
								$or: orFields,
							};
						}),
					})
					.sort((a, b) => (a.score ?? Number.MAX_SAFE_INTEGER) - (b.score ?? Number.MAX_SAFE_INTEGER))
					.map(v => v.item)
			: filteredVendors;

	return (
		<BoxContainer>
			<Box full>
				<VendorsOverviewFilter setSearchTerm={setSearchTerm} activeFilter={activeFilter} setActiveFilter={setActiveFilter} typeOptions={typeOptions} locationOptions={locationOptions} />
				<Table
					data={searchFilteredVendors}
					keySelector={v => v.erpReferenceNo}
					noDataFoundText={'requisitions.noVendors'}
					loading={vendorsLoading}
					columns={[
						{
							label: 'common.name',
							selectFn: v => v.company ?? '',
							sortFn: (a, b) => (a.company ?? '').localeCompare(b.company ?? ''),
						},
						{
							label: 'common.type',
							selectFn: v => <p>{toSentenceCase(v.type)}</p>,
							sortFn: (a, b) => a.type.localeCompare(a.type),
						},
						{
							label: 'common.address',
							selectFn: v => (
								<p>
									<p>{v.address?.addressLine}</p>
									<p>
										{v.address?.postalCode} {v.address?.city}
									</p>
								</p>
							),
							sortFn: (a, b) => (a.address?.postalCode ?? '').localeCompare(b.address?.postalCode ?? ''),
						},
						{
							label: 'common.email',
							selectFn: v => (
								<p>
									<a href={`mailto:${v.email?.toLowerCase()}`} className="hover:underline">
										{v.email?.toLowerCase()}
									</a>
								</p>
							),
							sortFn: (a, b) => (a.email ?? '').localeCompare(b.email ?? ''),
						},
						{
							label: 'common.phone',
							numeric: true,
							selectFn: v => (
								<p>
									<a href={`tel:${v.phone}`} className="hover:underline">
										{v.phone}
									</a>
								</p>
							),
							sortFn: (a, b) => (a.phone ?? '').localeCompare(b.phone ?? ''),
						},
						{
							label: 'common.location',
							selectFn: v => <p>{v.ssgLocation.length > 0 ? v.ssgLocation.charAt(0) + v.ssgLocation.substring(1).toLowerCase() : t('requisitions.wholeCountry')}</p>,
							sortFn: (a, b) => a.ssgLocation.localeCompare(b.ssgLocation),
						},
					]}
				/>
			</Box>
		</BoxContainer>
	);
};

export default VendorsOverview;
