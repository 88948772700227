import { useQuery } from '@apollo/client';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React from 'react';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html';
import Box from '../../Components/Layout/Box';
import { GetRiskEvaluationCategories, GetRiskEvaluationQuestions } from '../../GraphQL';
import { ICaseCreation } from '../../Schemas/ICaseCreation';

const GET_RISK_EVALUATION_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');
const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');

interface Props {
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
}

interface Question {
	questionId: string;
	question: string;
	helpText: string;
	defaultChecked: boolean;
}

interface Category {
	categoryName: string;
	questions: Question[];
}

const RiskEvaluationBox: React.FC<Props> = ({ register, errors, control }): React.ReactElement => {
	const { data: questions } = useQuery<GetRiskEvaluationQuestions>(GET_RISK_EVALUATION_QUESTIONS);
	const { data: categories } = useQuery<GetRiskEvaluationCategories>(GET_RISK_EVALUATION_CATEGORIES);

	const categoryMap = new Map<string, Category>();

	categories?.riskEvaluationCategories.forEach(c => {
		questions?.riskEvaluationQuestions.forEach(q => {
			if (q.category.id === c.id) {
				if (categoryMap.has(c.id)) {
					categoryMap.set(c.id, {
						categoryName: c.categoryName,
						questions: [
							...(categoryMap.get(c.id)?.questions ?? []),
							{
								questionId: q.id,
								question: q.question,
								helpText: q.helpText,
								defaultChecked: q.defaultChecked,
							},
						],
					});
				} else {
					categoryMap.set(c.id, {
						categoryName: c.categoryName,
						questions: [
							{
								questionId: q.id,
								question: q.question,
								helpText: q.helpText,
								defaultChecked: q.defaultChecked,
							},
						],
					});
				}
			}
		});
	});

	const categoryArray = Array.from(categoryMap.values());

	return (
		<Box title="case.riskEvaluation" form className="mb-8">
			<div
				className={classNames('flex flex-wrap', {
					'border-red rounded-default border-2 px-1': errors.RiskEvaluationBox,
				})}
			>
				{categoryArray.map((c, index) => {
					return (
						<div className="w-1/3" key={index}>
							<FormFieldHeader title={c.categoryName} />
							{c.questions.map((q, i) => {
								return (
									<div key={i}>
										<Checkbox
											name={`RiskEvaluationBox.answers[${index + 1}${i}].answer`}
											title={q.question}
											labelWeight={'NONE'}
											popoverInteractive
											labelInfoPopover={
												<div
													dangerouslySetInnerHTML={{
														__html: sanitizeHtml(q.helpText, {
															allowedAttributes: {
																'*': ['style', 'href', 'rel', 'target'],
															},
														}),
													}}
												></div>
											}
											innerRef={register}
											defaultChecked={q.defaultChecked}
										/>
										<Input name={`RiskEvaluationBox.answers[${index + 1}${i}].question`} value={q.questionId} innerRef={register} readOnly className="hidden" />
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
			{errors.RiskEvaluationBox?.answers && <FormErrorText text="error.minOneSelected" />}
		</Box>
	);
};

export default RiskEvaluationBox;
