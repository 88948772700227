import React from 'react';
import { Permissions } from '@ssg/common/GraphQL';
import { formatDate, formatTimestampOnly } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GetWebCases_cases } from '../../GraphQL';
import Table from '@ssg/common/Components/Table';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import FavoriteToggle from '../../Components/FavoriteToggle';
import AdministrativeProgress from '../../Components/AdministrativeProgress';
import UserContext from '../../UserContext';
import CaseVisitation from './CaseVisitation';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import OperationProgress from '../../Components/OperationProgress';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';

interface Props {
	cases: GetWebCases_cases[];
	showEconomyField?: boolean;
	showBusinessAreaField?: boolean;
	showCategoryCause?: boolean;
	loading?: boolean;
	noDataFound: string;
	projectManagersFilter?: string[];
	caseManagersFilter?: string[];
}

const CaseTable: React.FC<Props> = ({ cases, showBusinessAreaField, showEconomyField, showCategoryCause, loading, noDataFound, projectManagersFilter = [], caseManagersFilter = [] }) => {
	const { t } = useTranslation();

	const managerFavoritesFlag = useFlag(FeatureFlagEnums.MANAGER_FAVORITES);

	const history = useHistory();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const favoriteRender = (c: GetWebCases_cases): JSX.Element => {
		if (managerFavoritesFlag) {

			if (projectManagersFilter.length > 0 && caseManagersFilter.length <= 0) {
				return <FavoriteToggle userFavoriteColor={c.projectManagerFavorite?.active === true ? c.projectManagerFavorite.color ?? '' : ''} caseId={c.id} disabledClick />;

			} else if (projectManagersFilter.length <= 0 && caseManagersFilter.length > 0) {
				return <FavoriteToggle userFavoriteColor={c.caseManagerFavorite?.active === true ? c.caseManagerFavorite.color ?? '' : ''} caseId={c.id} disabledClick />;

			} else if (projectManagersFilter.length > 0 && caseManagersFilter.length > 0) {
				return <></>;

			} else {
				return <FavoriteToggle userFavoriteColor={c.userFavorite?.active === true ? c.userFavorite.color ?? '' : ''} caseId={c.id} />;
			}

		} else {
			return <FavoriteToggle userFavoriteColor={c.userFavorite?.active === true ? c.userFavorite.color ?? '' : ''} caseId={c.id} />;
		}
	};

	return (
		<Table
			loading={loading}
			data={cases ?? []}
			columns={[
				// {
				//     label: '',
				//     selectFn: c => <div className={classNames({
				//         'bg-green': c.priority.green,
				//         'bg-red': c.priority.red,
				//     },
				//     "bg-blue h-16 w-1 -m-2")}></div>,
				// },
				{
					label: '',
					noTruncate: true,
					selectFn: c => favoriteRender(c),
				},
				{
					label: 'case.caseNo',
					numeric: true,
					selectFn: c => <p className="font-medium">{c.erpNo}</p>,
					sortFn: (a, b) => a.erpNo.localeCompare(b.erpNo),
				},
				{
					label: 'caseOverview.progression',
					noTruncate: true,
					selectFn: c => <AdministrativeProgress caseData={c} compact />,
				},
				{
					label: 'common.debitor',
					selectFn: c => (
						<>
							<p className="font-semibold">{c.debitor.company}</p>
							<p>({c.debitor.debitorId})</p>
						</>
					),
					sortFn: (a, b) => (a.debitor.company ?? '').localeCompare(b.debitor.company ?? ''),
				},
				{
					label: 'caseOverview.policenr',
					numeric: true,
					selectFn: c => <p className="font-medium">{c.debitor.policeNumber}</p>,
					sortFn: (a, b) => a.debitor.policeNumber.localeCompare(b.debitor.policeNumber),
				},
				{
					label: 'case.injuredParty',
					numeric: true,
					selectFn: c => (
						<p>
							<span className="font-semibold">{c.damage.contact.name}</span>
							<br />
							{c.damage.contact.address?.road} {c.damage.contact.address.houseNumber} {c.damage.contact.address.floor} <br /> {c.damage.contact.address?.postalCode}{' '}
							{c.damage.contact.address?.city}
						</p>
					),
					sortFn: (a, b) => (a.damage.contact.address?.postalCode ?? '').localeCompare(b.damage.contact.address?.postalCode ?? ''),
				},
				{
					label: `${t('common.damageCategory')} ~ ${t('case.damageCause')}`,
					selectFn: c => (
						<>
							<p className="font-medium">{c.damage.category.name}</p>
							<p>{c.damage.cause.name}</p>
						</>
					),
					sortFn: (a, b) => (a.damage.category.name ?? '').localeCompare(b.damage.category.name ?? ''),
					hideColumn: showCategoryCause !== undefined && !showCategoryCause,
				},
				{
					label: 'case.track',
					selectFn: c => (
						<p className="font-medium">
							{t('case.track')} {c.track}
						</p>
					),
					sortFn: (a, b) => (a.track.toString() ?? '').localeCompare(b.track.toString() ?? ''),
					numeric: true,
				},
				{
					label: 'common.priority',
					selectFn: c => (
						<div className="flex">
							<div className="self-center">
								{c.visitation.priorities.map((p, i) => {
									return <p key={p + i}>{t(`case.${p}`)}</p>;
								})}
							</div>
							<div className="self-center">
								<CaseVisitation caseData={c} />
							</div>
						</div>
					),
				},
				{
					label: `${t('common.location')} ~ ${t('common.department')}`,
					selectFn: c => (
						<>
							<p>{c.ssgLocation.name}</p>
							<p>({c.ssgDepartment.departmentNumber})</p>
						</>
					),
					sortFn: (a, b) => (a.ssgLocation.name ?? '').localeCompare(b.ssgLocation.name ?? ''),
				},
				{
					label: 'common.created',
					numeric: true,
					selectFn: c => (
						<>
							<p>{formatDate(new Date(c.createdAt))}</p>
							<p>{formatTimestampOnly(new Date(c.createdAt))}</p>
						</>
					),
					sortFn: (a, b) => (a.createdAt ?? '').localeCompare(b.createdAt ?? ''),
				},
				{
					label: 'common.arrived',
					selectFn: c => {
						if (c.arrived && c.arrivedDate) {
							return (
								<>
									<p>{formatDate(new Date(c.arrivedDate))}</p>
									<p>{formatTimestampOnly(new Date(c.arrivedDate))}</p>
								</>
							);
						}

						if (c.arrived && !c.arrivedDate) {
							// Extremely unlikely scenario
							return <p>{t('common.arrivedDateUnknown')}</p>;
						}

						return <p>{t('common.notArrived')}</p>;
					},
					sortFn: (a, b) => (a.arrivedDate ?? '').localeCompare(b.arrivedDate ?? ''),
				},
				{
					label: 'caseOverview.operation',
					noTruncate: true,
					selectFn: c => <OperationProgress caseData={c} compact />,
				},
				{
					label: `${t('caseOverview.ssgProjectManager')} ~ ${t('caseOverview.ssgCaseManager')}`,
					selectFn: c => (
						<>
							<p className="font-medium">{c.projectManager?.name}</p>
							<p>{c.caseManager?.name}</p>
						</>
					),
					sortFn: (a, b) => (a.projectManager?.name ?? '').localeCompare(b.projectManager?.name ?? ''),
				},
				{
					label: 'common.economy',
					numeric: true,
					selectFn: c => (
						<div>
							<p>
								{t('case.economy.sale')}: <span className="font-bold">{addThousandSeperator(c.caseEconomics?.salesTotal ?? 0)}</span>
							</p>
							<p>
								{t('case.economy.invoiced')}: <span className="font-bold">{addThousandSeperator(c.caseEconomics?.invoicedTotal ?? 0)}</span>
							</p>
						</div>
					),
					hideColumn: !arraysHasMatchingValue([Permissions.CASES_ECONOMY_VIEW], userPermissions) || (showEconomyField !== undefined && !showEconomyField),
					sortFn: (a, b) => (a.caseEconomics?.salesTotal ?? 0) - (b.caseEconomics?.salesTotal ?? 0),
				},
				{
					label: 'common.area',
					selectFn: c => <p className="font-medium">{c.damage.businessArea.name}</p>,
					sortFn: (a, b) => (a.damage.businessArea.name ?? '').localeCompare(b.damage.businessArea.name ?? ''),
					hideColumn: showBusinessAreaField !== undefined && !showBusinessAreaField,
				},
			]}
			keySelector={c => c.id}
			onRowClick={c => history.push(`/case/${c.id}`)}
			noDataFoundText={noDataFound}
		/>
	);
};

export default CaseTable;
