import * as yup from 'yup';

const reservationDateObject = yup
	.object()
	.shape({
		reservationStart: yup.date().required(), // Set to mixed so we can control error messages for invalid date
		reservationEnd: yup.date().required(),
	})
	.test('Both required', 'Both dates are required', (obj): boolean => obj.reservationStart instanceof Date && obj.reservationEnd instanceof Date);

export const machineReservationSchema = yup.object().shape({
	reservationOnCase: yup.string().required(),
	reservationDates: reservationDateObject,
});
