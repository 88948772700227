import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required',
	},
});

export const CreateRequisitionSchema = yup.object().shape({
	requisitionType: yup.string().required(),

	requisitionConnectType: yup.string().required(),

	caseId: yup.string().when('requisitionConnectType', {
		is: (requisitionConnectType: string) => requisitionConnectType === 'case',
		then: yup.string().required(),
		otherwise: yup.string(),
	}),
	departmentId: yup.string().when('requisitionConnectType', {
		is: (requisitionConnectType: string) => requisitionConnectType === 'location',
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	drivingSlipId: yup.string().when('requisitionConnectType', {
		is: (requisitionConnectType: string) => requisitionConnectType === 'drivingSlip',
		then: yup.string().required(),
		otherwise: yup.string(),
	}),

	vendor: yup.string().required(),

	sendEmail: yup.boolean(),

	email: yup.string().when('sendEmail', {
		is: (sendEmail: boolean) => sendEmail,
		then: yup.string().email().required(),
		otherwise: yup.string().email(),
	}),

	phone: yup.string().required(),

	description: yup.string().required(),
});
