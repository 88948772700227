import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { DrivingSlipStatus, GetDrivingSlipsOverview, GetDrivingSlipsOverviewVariables, GetDrivingSlipsOverview_drivingSlips } from '../../GraphQL';
import { loader } from 'graphql.macro';
import { formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange, faExclamationCircle, faImages, faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { isPlannedDateExceeded } from '@ssg/common/Helpers/drivingSlipsHelper';
import { useHistory } from 'react-router-dom';
import { getDriverWhoCompleted } from '../SingleCase/DrivingSlipBox/DrivingSlipSeries';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Box from '../../Components/Layout/Box';
import Header from '@ssg/common/Components/Header';
import Table from '@ssg/common/Components/Table';
import DrivingSlipOverviewFilter from './DrivingSlipOverviewFilter';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import UserContext from '../../UserContext';
import DrivingSlipChangeLog from './DrivingSlipChangeLog';
import DrivingSlipFileBox from './DrivingSlipFileBox';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Button from '@ssg/common/Components/Button';
import GraphQLExtensionsElement from '@ssg/common/Components/GraphQLExtensionsElement';
import TotalCount from '@ssg/common/Components/TotalCount';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';

const GET_DRIVING_SLIPS = loader('src/GraphQL/DrivingSlips/GetDrivingSlipsOverview.gql');

export interface DrivingSlipFilter {
	name?: string | null;
	status: DrivingSlipStatus | null;
	urgent: boolean | null;
	exceeded: boolean | null;
	departments: string[] | undefined;
	locations: string[] | undefined;
	damageCauses: string[] | undefined;
	damageCategories: string[] | undefined;
	drivers: string[] | undefined;
	debitors: string[] | undefined;
	minStartDate: Date | undefined;
	maxStartDate: Date | undefined;
	minEndDate: Date | undefined;
	maxEndDate: Date | undefined;
	noDriver: boolean | null;
	showPriorityCustomerCases: boolean | null;
}

const DrivingSlipOverview: React.FC = () => {
	const { t } = useTranslation();

	const history = useHistory();

	const userContext = React.useContext(UserContext);

	const priorityCustomer = useFlag(FeatureFlagEnums.PRIORITYCUSTOMER);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	const [activeUserFilter, setActiveUserFilter] = React.useState('');

	const selectedUserFilter = React.useMemo(
		() => userContext?.user?.drivingSlipOverviewFilters.find(f => f.name === activeUserFilter),
		[activeUserFilter, userContext?.user?.drivingSlipOverviewFilters],
	);

	const [activeFilters, setActiveFilters] = useStorageState<DrivingSlipFilter>(window.sessionStorage, 'activeDrivingslipFilters', {
		name: null,
		status: null,
		urgent: null,
		exceeded: null,
		departments: undefined,
		locations: undefined,
		damageCauses: undefined,
		damageCategories: undefined,
		drivers: undefined,
		debitors: undefined,
		minStartDate: undefined,
		maxStartDate: undefined,
		minEndDate: undefined,
		maxEndDate: undefined,
		noDriver: null,
		showPriorityCustomerCases: null,
	});

	const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false);

	const [showFilesModal, setShowFilesModal] = React.useState(false);
	const [series, setSeries] = React.useState<string>('');
	const [caseErpNo, setCaseErpNo] = React.useState<string>('');

	React.useEffect(() => {
		if (typeof selectedUserFilter === 'undefined') {
			return;
		}

		setActiveFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserFilter.name,
			status: selectedUserFilter.status,
			urgent: selectedUserFilter.urgent ?? null,
			exceeded: selectedUserFilter.exceeded ?? null,
			departments: selectedUserFilter.departments ?? undefined,
			locations: selectedUserFilter.locations ?? undefined,
			damageCauses: selectedUserFilter.damageCauses ?? undefined,
			damageCategories: selectedUserFilter.damageCategories ?? undefined,
			drivers: selectedUserFilter.drivers ?? undefined,
			noDriver: selectedUserFilter.noDriver ?? null,
		}));
	}, [setActiveFilters, selectedUserFilter]);
	const [drivingSlips, setDrivingSlips] = React.useState<GetDrivingSlipsOverview_drivingSlips[]>([]);

	const { data, loading, fetchMore, refetch } = useQuery<GetDrivingSlipsOverview, GetDrivingSlipsOverviewVariables>(GET_DRIVING_SLIPS, {
		fetchPolicy: 'cache-and-network',
		context: { debatch: true },
		variables: {
			urgent: activeFilters.urgent,
			exceeded: activeFilters.exceeded,
			departments: activeFilters.departments,
			locations: activeFilters.locations,
			damageCauses: activeFilters.damageCauses,
			damageCategories: activeFilters.damageCategories,
			drivers: activeFilters.drivers,
			debitor: activeFilters.debitors,
			minStartDate: activeFilters.minStartDate ? formatDateForInput(new Date(activeFilters.minStartDate ?? '')) : undefined,
			maxStartDate: activeFilters.maxStartDate ? formatDateForInput(new Date(activeFilters.maxStartDate ?? '')) : undefined,
			minEndDate: activeFilters.minEndDate ? formatDateForInput(new Date(activeFilters.minEndDate ?? '')) : undefined,
			maxEndDate: activeFilters.maxEndDate ? formatDateForInput(new Date(activeFilters.maxEndDate ?? '')) : undefined,
			noDriver: activeFilters.noDriver,
			priorityCustomers: activeFilters.showPriorityCustomerCases,
			status: activeFilters.status,
			offset: 0,
			limit: 20,
			searchString: searchTerm || null,
		},
	});

	React.useEffect(() => {
		if (data) {
			setDrivingSlips(data.drivingSlips);
		}
	}, [data]);

	const [viewDrivingSlipChanges, setViewDrivingSlipChanges] = React.useState<boolean>(false);
	const [drivingSlip, setDrivingSlip] = React.useState<GetDrivingSlipsOverview_drivingSlips>();

	// const activeFilteredDrivingSlips = drivingSlips.map(ds => ({
	//     ...ds,
	//     department: {
	//         ...ds.department,
	//         departmentNumberStr: String(ds.department.departmentNumber),
	//     },
	// }));

	return (
		<div className="text-blue">
			<Header title="drivingSlips.overviewTitle" />
			<BoxContainer>
				<Box full loading={loading}>
					<DrivingSlipOverviewFilter
						setFilterTerm={setSearchTerm}
						onFilter={setActiveFilters}
						activeFilters={activeFilters}
						setActiveUserFilter={setActiveUserFilter}
						activeUserFilter={activeUserFilter}
						searchTerm={searchTerm}
						refetch={refetch}
					/>

					<GraphQLExtensionsElement
						name="drivingSlipsTotalCount"
						render={value => (
							<TotalCount totalCount={value as number | undefined | null} loading={loading} quantityText={t('common.quantity')} entityText={t('drivingSlips.overviewTitle')} />
						)}
					/>

					<Table
						data={drivingSlips ?? []}
						columns={[
							{
								label: t('common.deadline'),
								selectFn: d => (
									<div>
										{d.urgent && (
											<div className="flex py-1">
												<FontAwesomeIcon icon={faExclamationCircle} className="text-orange mr-1" size="lg" />
												<p className="font-medium">{t('common.urgent')}</p>
											</div>
										)}
										{isPlannedDateExceeded(d.start) && (d.status === DrivingSlipStatus.UNPLANNED || d.status === DrivingSlipStatus.PLANNED) && (
											<div className="flex py-1">
												<FontAwesomeIcon icon={faExclamationCircle} className="text-red mr-1" size="lg" />
												<p className="font-medium">{t('common.overdue')}</p>
											</div>
										)}
									</div>
								),
							},
							{
								label: t('case.caseNo'),
								numeric: true,
								selectFn: d => (
									<p onClick={() => history.push(`/case/${d.case.id}`)} className="cursor-pointer hover:underline">
										{d.case.erpNo}
									</p>
								),
								sortFn: (a, b) => a.case.erpNo.localeCompare(b.case.erpNo),
							},
							{
								label: t('common.address'),
								selectFn: d => (
									<div>
										{d.case.damage.contact.address.road} {d.case.damage.contact.address.houseNumber} {d.case.damage.contact.address.floor} <br />
										{d.case.damage.contact.address.postalCode} {d.case.damage.contact.address.city}
									</div>
								),
								sortFn: (a, b) => a.case.debitor.company.localeCompare(b.case.debitor.company),
							},
							{
								label: `${t('common.damageCategory')} ~ ${t('case.damageCause')}`,
								selectFn: d => (
									<div>
										<p>{d.case.damage.category.name}</p>
										<p>{d.case.damage.cause.name}</p>
									</div>
								),
								sortFn: (a, b) => (a.case.damage.cause.name ?? '').localeCompare(b.case.damage.cause.name ?? ''),
							},
							{
								label: `${t('common.status')}`,
								selectFn: d => (
									<p>
										{t(`drivingSlips.status.${d.status}`)} {getDriverWhoCompleted(d.changes) !== '' ? `(${getDriverWhoCompleted(d.changes)})` : ''}
									</p>
								),
							},
							{
								label: 'drivingSlips.drivingStarted',
								selectFn: d => <p>{d.drivingStarted ? dateToDateTimeString(d.drivingStarted) : t('common.no')}</p>,
							},
							{
								label: t('drivingSlips.driver'),
								selectFn: d => <p>{d.driver?.name ?? t('common.notSpecified')}</p>,
								sortFn: (a, b) => (a.driver?.name ?? '').localeCompare(b.driver?.name ?? ''),
							},
							{
								label: `${t('common.location')} ~ ${t('common.department')}`,
								selectFn: d => (
									<div>
										<p>{d.location.name}</p>
										<p>({d.department.departmentNumber})</p>
									</div>
								),
								sortFn: (a, b) => a.location.name.localeCompare(b.location.name),
							},
							{
								label: t('common.start'),
								numeric: true,
								selectFn: d => <p>{formatTimestamp(new Date(d.start))}</p>,
								sortFn: (a, b) => a.start.localeCompare(b.start),
							},
							{
								label: t('common.end'),
								selectFn: d => <p>{formatTimestamp(new Date(d.end))}</p>,
								sortFn: (a, b) => a.end.localeCompare(b.end),
							},
							{
								label: t('common.files'),
								classNameTh: 'text-center',
								selectFn: c => {
									return (
										<div className="flex content-start justify-center pr-2">
											<FontAwesomeIcon
												icon={faImages}
												size="lg"
												onClick={() => {
													setSeries(c.series);
													setCaseErpNo(c.case.erpNo);
													setShowFilesModal(true);
												}}
												className="cursor-pointer"
											/>
										</div>
									);
								},
							},
							{
								label: 'common.register',
								classNameTh: 'text-center',
								hideColumn: true,
								selectFn: d => (
									<div className="flex content-start justify-center pr-2">
										<FontAwesomeIcon
											icon={faStopwatch}
											size="lg"
											onClick={() => {
												history.push(`/timeregistration/${d.id}`);
											}}
											className="cursor-pointer"
										/>
									</div>
								),
							},
							{
								label: 'caseOverview.filters.priorityCustomer',
								classNameTh: 'text-center',
								hideColumn: !priorityCustomer,
								selectFn: d => (
									<div className="flex content-start justify-center pr-2">
										<FontAwesomeIcon
											icon={faTriangle}
											size="lg"
											className={classNames({
												'text-red': d.case.debitor.priorityCustomer,
												'text-blue opacity-25': !d.case.debitor.priorityCustomer,
											})}
										/>
									</div>
								),
							},
							{
								label: 'common.changes',
								classNameTh: 'text-center',
								selectFn: c => {
									if (c.changes && c.changes?.length > 0) {
										return (
											<div className="flex content-start justify-center pr-2">
												<FontAwesomeIcon
													icon={faExchange}
													size="lg"
													onClick={() => {
														setDrivingSlip(c);
														setViewDrivingSlipChanges(true);
													}}
													className="cursor-pointer"
												/>
											</div>
										);
									}
									return <></>;
								},
							},
						]}
						keySelector={d => d.id}
						noDataFoundText="common.noDrivingSlipsFound"
					/>
					{typeof data !== 'undefined' && data.drivingSlips.length % 20 === 0 && data.drivingSlips.length !== 0 && (
						<Button
							fullWidth
							text="common.getMore"
							secondary
							loading={fetchMoreLoading}
							className="mt-2"
							onClick={async () => {
								setFetchMoreLoading(true);
								await fetchMore({
									variables: {
										offset: data.drivingSlips.length,
									},
								});
								setFetchMoreLoading(false);
							}}
						/>
					)}
				</Box>
			</BoxContainer>

			{/* Contact Log */}
			{drivingSlip && (
				<DrivingSlipChangeLog version={drivingSlip.version ?? 0} changes={drivingSlip.changes ?? []} open={viewDrivingSlipChanges} close={() => setViewDrivingSlipChanges(false)} />
			)}

			{showFilesModal &&
				<DrivingSlipFileBox open={showFilesModal} caseErpNo={caseErpNo} close={() => setShowFilesModal(false)} series={series} />
			}

		</div>
	);
};

export default DrivingSlipOverview;
