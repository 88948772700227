import React, { Dispatch, SetStateAction, useState } from 'react';
import { faBan, faCopy, faEdit, faExchange, faExclamationCircle, faEye, faImages, faMapMarkerAlt, faStopwatch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
	DrivingSlipStatus,
	GetSingleCase_case,
	GetWebCase_case_drivingSlipSeries_drivingSlips,
	GetWebCase_case_drivingSlipSeries_drivingSlips_changes,
	GetWebDrivingSlips_drivingSlips,
	GetWebDrivingSlips_drivingSlips_driver,
	QuestionnaireCompletionStatus,
	SetWebDrivingSlipStatus,
	SetWebDrivingSlipStatusVariables,
	BaseQuestionnaireTemplateFragment_sections as Section,
	GetWebCaseAdminData_drivingSlipCategories,
	Permissions,
} from '../../../GraphQL';
import { CompletionEntry, createContentForCompletionEntry } from '@ssg/common/Helpers/Questionnaire/CompletionEntry';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { getQuestionnaireCompletionEntriesForSection } from '@ssg/common/Helpers/Questionnaire/QuestionnaireCompletionEntriesBuilder';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import GoogleMapsDirections from '@ssg/common/Components/GoogleMapsDirections';
import DrivingSlipFileBox from '../../DrivingSlips/DrivingSlipFileBox';
import DrivingSlipChangeLog from '../../DrivingSlips/DrivingSlipChangeLog';
import DrivingSlipDriverBox from '../../DrivingSlips/DrivingSlipDriverBox';
import TextButton from '@ssg/common/Components/TextButton';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { getWeekDay } from '../../NewPlanner/PlannerHelpers';

const SET_DRIVINGSLIP_STATUS = loader('src/GraphQL/DrivingSlips/SetWebDrivingSlipStatus.gql');

interface Props {
	caseData: GetSingleCase_case;
	drivingSlips: GetWebCase_case_drivingSlipSeries_drivingSlips[];
	setEdit: () => void;
	setDelete: () => void;
	setCopy: () => void;
	setData: Dispatch<SetStateAction<GetWebDrivingSlips_drivingSlips | undefined>>;
	categories: GetWebCaseAdminData_drivingSlipCategories[];
}

export const getDriverWhoCompleted = (changes: GetWebCase_case_drivingSlipSeries_drivingSlips_changes[]): string => {
	const statusCompleteChanges = changes.filter(c => c.after.status !== null && c.after.status === DrivingSlipStatus.COMPLETED);
	const changesCount = statusCompleteChanges.length;
	if (changesCount > 0) {
		return statusCompleteChanges[changesCount - 1].user.name;
	}

	return '';
};

const getDateAndDay = (date: string): string => {
	const dateString = dateToDateTimeString(date);
	const weekDay = getWeekDay(date);
	return `${weekDay} ${dateString}`;
};

// TODO: Whole component should be refactored to be more simple. Series and single drivingslips should be one component
const DrivingSlipSeries: React.FC<Props> = ({ caseData, drivingSlips, setEdit, setCopy, setDelete, setData, categories }): React.ReactElement => {
	const { t } = useTranslation();
	const mobileTrackLocationDataFlag = useFlag(FeatureFlagEnums.MOBILE_TRACK_LOCATION_DATA);
	const newPlannerVehiclesDataFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_VEHICLES);

	const history = useHistory();
	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const caseViewExternal = arraysHasMatchingValue(userPermissions, [Permissions.CASES_VIEW_EXTERNAL]);

	const [drivingslipData, setDrivingslipData] = useState<GetWebDrivingSlips_drivingSlips>();
	const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
	const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
	const [showDriverModal, setShowDriverModal] = useState<boolean>(false);
	const [driverData, setDriverData] = useState<GetWebDrivingSlips_drivingSlips_driver>();

	const urgent = drivingSlips.some(ds => ds.urgent === true);
	const [setDrivingSlipStatus] = useMutation<SetWebDrivingSlipStatus, SetWebDrivingSlipStatusVariables>(SET_DRIVINGSLIP_STATUS);

	const questionnaireEntry = (id: string, drivingSlip: GetWebDrivingSlips_drivingSlips) => {
		const rootIdPrefix = `${id}_`;

		if (drivingSlip.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED && drivingSlip.questionnaire) {
			const picturesTranslation = t('common.pictures');
			return drivingSlip.questionnaire.template.sections.reduce((entries: CompletionEntry[], section: Section) => {
				entries = entries.concat(getQuestionnaireCompletionEntriesForSection(section, rootIdPrefix, undefined, picturesTranslation, false));
				return entries;
			}, []);
		}
	};

	const category = React.useMemo(() => categories.find(c => c.code === drivingSlips[0].category ?? 'somethingRandom'), [categories, drivingSlips]);

	return (
		<div className="bg-white">
			<div
				className={classNames('flex flex-col py-3 md:flex-row', {
					'border-orange border-2': urgent,
					'border-purple border-2': !urgent && caseData.skafor,
				})}
			>
				<div className="mx-3 flex w-full flex-col">
					{urgent && (
						<div className="text-orange mb-1 flex flex-row items-center font-bold">
							<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
							<p className="ml-2">{t('common.urgent')}</p>
						</div>
					)}
					{caseData.skafor && <div className="text-purple mb-1 flex flex-row items-center font-bold">{t('case.SKAFOR')}</div>}
					<div className="text-blue mb-1 flex w-full flex-row">
						<div className="mr-5">
							<div className="text-xs">{t('common.department')}</div>
							<div className="text-sm font-medium">{t(drivingSlips[0].department.name)}</div>
						</div>
						<div className="mr-5">
							<div className="text-xs">{t('common.location')}</div>
							<div className="text-sm font-medium">{t(drivingSlips[0].location.name)}</div>
						</div>
						{typeof category !== 'undefined' && (
							<div className="mr-5">
								<div className="text-xs">{t('common.category')}</div>
								<div className="text-sm font-medium">{category.name}</div>
							</div>
						)}
						<div className="ml-auto mr-0">
							<GoogleMapsDirections fromAdr={caseData.ssgLocation.address} toAdr={caseData.damage.contact.address}>
								<TextButton link text="drivingSlips.getDirections" icon={faMapMarkerAlt} />
							</GoogleMapsDirections>
							<TextButton link text="drivingSlips.showFiles" icon={faImages} onClick={() => setShowFilesModal(true)} />
						</div>
					</div>

					<div className="divide-y-1 space-y-2">
						{drivingSlips.map(ds => (
							<div key={ds.series + ds.id} className="border-gray-300">
								<div className="text-blue my-4 flex w-full flex-row text-xs">
									<div>
										<div className="flex flex-row">
											<div className="mr-3">
												<div className="font-semibold">{ds.status === DrivingSlipStatus.UNPLANNED ? t('drivingSlips.requestedDriver') : t('drivingSlips.driver')}</div>
												<div className="flex">
													{ds.driver?.name ?? t('common.notSpecified')}
													{ds.driver?.name && (
														<TextButton
															icon={faEye}
															onClick={() => {
																setDriverData(ds.driver ?? undefined);
																setShowDriverModal(true);
															}}
														/>
													)}
												</div>
												{newPlannerVehiclesDataFlag && <div><p><span className="font-semibold">{t('planner.car')}:</span> {ds.car ? `${ds.car.brand} (${ds.car.vehicleNumber})` : t('common.notSpecified')}</p></div>}
												{newPlannerVehiclesDataFlag && <div><p><span className="font-semibold">{t('planner.materials')}:</span> {ds.materials.length > 0 ? ds.materials.map(m => `${m.brand} (${m.vehicleNumber})`).join(', ') : t('common.notSpecified')}</p></div>}
											</div>
											<div>
												<p>
													<span className="font-semibold">{t('common.status')}:</span>
													{` ${t('drivingSlips.status.' + ds.status)} ${getDriverWhoCompleted(ds.changes) !== '' ? '(' + getDriverWhoCompleted(ds.changes) + ')' : ''}`}
													{ds.status === DrivingSlipStatus.PLANNED && ds.eventReferenceId === null && <span className="text-red"> ({t('drivingSlips.noEventReferenceId')})</span>}
												</p>
											</div>
										</div>
										{!caseViewExternal && mobileTrackLocationDataFlag && !userContext.user?.external &&
											<div>
												<div className="flex flex-row space-x-2 mt-2">
													<div>
														<p className="font-semibold">{t('drivingSlips.starDrivenKM')}</p>
														{ds.passenger
															? <></>
															: <>{ds.status !== DrivingSlipStatus.COMPLETED ? <p>-</p> : <p>{ds.starDrivenKM} km</p>}</>
														}
													</div>
													<div>
														<p className="font-semibold">{t('drivingSlips.actualDrivenKM')}</p>
														{ds.passenger
															? <></>
															: <>{ds.status !== DrivingSlipStatus.COMPLETED ? <p>-</p> : <p>{ds.actualDrivenKM} km</p>}</>
														}
													</div>
												</div>
												{ds.passenger
													? <p>{t('drivingSlips.drivingNotRegistered')}</p>
													: <></>
												}
											</div>
										}
									</div>
									<div className="ml-auto mr-0">
										<p>
											<span className="font-semibold">{t('drivingSlips.drivingStarted')}: </span>
											{ds.drivingStarted ? dateToDateTimeString(ds.drivingStarted) : t('common.no')}
										</p>
										{ds.status === DrivingSlipStatus.UNPLANNED || ds.status === DrivingSlipStatus.PLANNED ? (
											<p>
												<span className="font-semibold">{t('drivingSlips.plannedStart')}:</span> {getDateAndDay(ds.start)}
											</p>
										) : (
											<p>
												<span className="font-semibold">{t('common.arrived')}:</span> {getDateAndDay(ds.start)}
											</p>
										)}
										{ds.status === DrivingSlipStatus.COMPLETED && (
											<p>
												<span className="font-semibold">{t('common.end')}:</span> {getDateAndDay(ds.end)}
											</p>
										)}
										{ds.deadline && (
											<p>
												<span className="font-semibold">{t('common.deadline')}:</span> {getDateAndDay(ds.deadline)}
											</p>
										)}
										<p>
											<span className="font-semibold">{t('common.created')}:</span> {getDateAndDay(ds.createdAt)}
										</p>
									</div>
								</div>

								<div className="text-blue flex w-full flex-row">
									<div className="w-3/5">
										<div className="text-xs font-medium">{t('drivingSlips.commentForUser')}</div>
										<div className="whitespace-pre-wrap break-words text-sm">{ds.comment ? ds.comment : t('drivingSlips.noComment')}</div>
									</div>
									{!caseViewExternal && (
										<div className="mr-3 flex w-2/5 flex-row justify-end text-right">
											<div className="mr-3 text-center">
												<div className="text-xs">{t('common.register')}</div>
												<FontAwesomeIcon
													icon={faStopwatch}
													onClick={() => {
														history.push(`/timeregistration/${ds.id}`);
													}}
													className="cursor-pointer"
												/>
											</div>
											{ds.status !== DrivingSlipStatus.COMPLETED && (
												<div className="mr-3 text-center">
													<div className="text-xs">{t('common.complete')}</div>
													<FontAwesomeIcon
														icon={faBan}
														onClick={() => {
															setDrivingSlipStatus({
																variables: {
																	id: ds.id,
																	status: DrivingSlipStatus.COMPLETED,
																},
															});
														}}
														className="cursor-pointer"
													/>
												</div>
											)}
											{ds.changes.length > 0 && (
												<div className="mr-3 text-center">
													<div className="text-xs">{t('common.changes')}</div>
													<FontAwesomeIcon
														icon={faExchange}
														onClick={() => {
															setShowChangesModal(true);
															setDrivingslipData({
																...ds,
																case: {
																	...caseData,
																},
															});
														}}
														className="cursor-pointer"
													/>
												</div>
											)}
											<div className="mr-3 text-center">
												<div className="text-xs">{t('common.copy')}</div>
												<FontAwesomeIcon
													icon={faCopy}
													onClick={() => {
														setCopy();
														const end = new Date(ds.start);
														end.setHours(end.getHours() + (ds.estimatedHours ?? 1));
														setData({
															...ds,
															end: end.toISOString(),
															case: {
																...caseData,
															},
														});
													}}
													className="cursor-pointer"
												/>
											</div>
											{ds.status !== DrivingSlipStatus.COMPLETED && ds.status !== DrivingSlipStatus.OBSOLETE && (
												<div className="mr-3 text-center">
													<div className="text-xs">{t('common.edit')}</div>
													<FontAwesomeIcon
														icon={faEdit}
														onClick={() => {
															setEdit();
															setData({
																...ds,
																case: {
																	...caseData,
																},
															});
														}}
														className="cursor-pointer"
													/>
												</div>
											)}

											{ds.status === DrivingSlipStatus.UNPLANNED && (
												<div className="text-center">
													<div className="text-xs">{t('common.delete')}</div>
													<FontAwesomeIcon
														icon={faTrashAlt}
														onClick={() => {
															setDelete();
															setData({
																...ds,
																case: {
																	...caseData,
																},
															});
														}}
														className="text-red cursor-pointer"
													/>
												</div>
											)}
										</div>
									)}
								</div>
								<div className="text-blue mt-2 flex w-full flex-row justify-between">
									<div className="flex w-full text-xs">
										<p className="font-semibold">{t('questionnaire.messageFromUser')}</p>
										{ds.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED && ds.questionnaire && (
											<p className="ml-1">
												| <span className="font-semibold">{t('questionnaire.completedDate')}:</span> {formatDate(new Date(ds.questionnaire.updatedAt))}
											</p>
										)}
									</div>
								</div>
								<div className="text-blue flex w-full flex-row text-xs">
									{ds.questionnaireCompletionStatus === QuestionnaireCompletionStatus.COMPLETED && ds.questionnaire ? (
										<ul className="ml-3 list-disc">
											{ds &&
												questionnaireEntry(ds.id, ds as GetWebDrivingSlips_drivingSlips)?.map(entry => {
													const content = createContentForCompletionEntry(entry);
													return (
														<li key={entry.sectionName}>
															<b>{entry.sectionName}</b>
															<br></br>
															{content}
														</li>
													);
												})}
										</ul>
									) : (
										<div className="w-full">
											<p>{t('questionnaire.notYetComplete')}</p>
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{showFilesModal && <DrivingSlipFileBox open={showFilesModal} caseErpNo={caseData.erpNo} close={() => setShowFilesModal(false)} series={drivingSlips[0].series} />}
			<DrivingSlipChangeLog version={drivingslipData?.version ?? 0} changes={drivingslipData?.changes ?? []} open={showChangesModal} close={() => setShowChangesModal(false)} />
			<DrivingSlipDriverBox open={showDriverModal} close={() => setShowDriverModal(false)} data={driverData} />
		</div>
	);
};

export default DrivingSlipSeries;
