import React from 'react';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { GetWebCase_case_changes_after_drivingSlips, GetWebCase_case_changes_before_drivingSlips } from '../../GraphQL';

interface IProps {
	before: GetWebCase_case_changes_before_drivingSlips | null;
	after: GetWebCase_case_changes_after_drivingSlips | null;
}

const CaseDrivingSlipChangeLog: React.FC<IProps> = ({ before, after }) => {
	const { t } = useTranslation();

	return (
		<div>
			{!after && before && (
				<div>
					<strong>
						{t('common.drivingSlip')} {t('common.added')}:
					</strong>
					{before.urgent && (
						<div>
							{t('common.urgent')}: {before.urgent ? t('common.yes') : t('common.no')}
						</div>
					)}
					{before.status && (
						<div>
							{t('common.status')}: {t(`drivingSlips.status.${before.status}`)}
						</div>
					)}
					{before.start && (
						<div>
							{t('catalog.timedMessage.startTime')}: {formatDate(new Date(before.start))}
						</div>
					)}
					{before.end && (
						<div>
							{t('catalog.timedMessage.endTime')}: {formatDate(new Date(before.end))}
						</div>
					)}
					{before.locked && (
						<div>
							{t('drivingSlips.locked')}: {before.locked}
						</div>
					)}
					{before.driver && (
						<div>
							{t('drivingSlips.driver')}: {before.driver.name}
						</div>
					)}
					{before.car && (
						<div>
							{t('drivingSlips.car')}: {before.car.vehicleNumber}
						</div>
					)}
					{before.comment && (
						<div>
							{t('common.comment')}: {before.comment}
						</div>
					)}
				</div>
			)}
			{after && !before && (
				<div>
					<strong>
						{t('common.drivingSlip')} {t('common.removed')}:
					</strong>
					{after.urgent && (
						<div>
							{t('common.urgent')}: {after.urgent ? t('common.yes') : t('common.no')}
						</div>
					)}
					{after.status && (
						<div>
							{t('common.status')}: {t(`drivingSlips.status.${after.status}`)}
						</div>
					)}
					{after.start && (
						<div>
							{t('catalog.timedMessage.startTime')}: {formatDate(new Date(after.start))}
						</div>
					)}
					{after.end && (
						<div>
							{t('catalog.timedMessage.endTime')}: {formatDate(new Date(after.end))}
						</div>
					)}
					{after.locked && (
						<div>
							{t('drivingSlips.locked')}: {after.locked}
						</div>
					)}
					{after.driver && (
						<div>
							{t('drivingSlips.driver')}: {after.driver.name}
						</div>
					)}
					{after.car && (
						<div>
							{t('drivingSlips.car')}: {after.car.vehicleNumber}
						</div>
					)}
					{after.comment && (
						<div>
							{t('common.comment')}: {after.comment}
						</div>
					)}
				</div>
			)}
			{after && before && (
				<div>
					<strong>
						{t('common.drivingSlip')} {t('common.changed')}:
					</strong>
					{before.urgent !== after.urgent && (
						<div className="flex flex-row">
							{t('common.urgent')} {t('common.changedFrom')} {before.urgent ? t('common.yes') : t('common.no')} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.urgent ? t('common.yes') : t('common.no')}</div>
						</div>
					)}
					{before.status !== after.status && (
						<div className="flex flex-row">
							{t('common.status')} {t('common.changedFrom')} {t(`drivingSlips.status.${before.status}`)} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{t(`drivingSlips.status.${after.status}`)}</div>
						</div>
					)}
					{before.start !== after.start && (
						<div className="flex flex-row">
							{t('catalog.timedMessage.startTime')} {t('common.changedFrom')} {DateTime.fromISO(before.start ?? '').toFormat('dd-MM-yyyy - hh:mm')} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{DateTime.fromISO(after.start ?? '').toFormat('dd-MM-yyyy - hh:mm')}</div>
						</div>
					)}
					{before.end !== after.end && (
						<div className="flex flex-row">
							{t('catalog.timedMessage.endTime')} {t('common.changedFrom')} {DateTime.fromISO(before.end ?? '').toFormat('dd-MM-yyyy - hh:mm')} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{DateTime.fromISO(after.end ?? '').toFormat('dd-MM-yyyy - hh:mm')}</div>
						</div>
					)}
					{before.locked !== after.locked && (
						<div className="flex flex-row">
							{t('drivingSlips.locked')} {t('common.changedFrom')} {before.locked} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.locked}</div>
						</div>
					)}
					{before.driver?.name !== after.driver?.name && (
						<div className="flex flex-row">
							{t('drivingSlips.driver')} {t('common.changedFrom')} {before.driver?.name} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.driver?.name}</div>
						</div>
					)}
					{before.car?.vehicleNumber !== after.car?.vehicleNumber && (
						<div className="flex flex-row">
							{t('drivingSlips.car')} {t('common.changedFrom')} {before.car?.vehicleNumber} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.car?.vehicleNumber}</div>
						</div>
					)}
					{before.comment !== after.comment && (
						<div className="flex flex-row">
							{t('common.comment')} {t('common.changedFrom')} {before.comment} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.comment}</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CaseDrivingSlipChangeLog;
