import Input from '@ssg/common/Components/Input';
import React from 'react';

interface Props {
	label: string;
	placeholder?: string;
	onChange(value: string): void;
	value?: string | null;
	required?: boolean;
	errorMessage?: string;
}

const TextInput: React.FC<Props> = ({ value, onChange, label, placeholder, required = false, errorMessage }) => (
	<div>
		<Input value={value ?? ''} title={label} name={label} onChange={e => onChange(e.target.value)} placeholder={placeholder} required={required} errorMessage={errorMessage} />
	</div>
);

export default TextInput;
