import { useMutation } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldHeader from '../../../Components/Forms/FormFieldHeader';
import { DeleteDrivingSlip, DeleteDrivingSlipVariables, GetWebCase, GetWebCaseVariables, GetWebCase_case_drivingSlipSeries, GetWebDrivingSlips_drivingSlips } from '../../../GraphQL';

const DELETE_DRIVINGSLIP = loader('src/GraphQL/DrivingSlips/DeleteDrivingSlip.gql');
const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');

interface Props {
	caseId: string;
	drivingSlip: GetWebDrivingSlips_drivingSlips;
	close(): void;
	visible: boolean;
}

const DeleteDrivingSlipModal: React.FC<Props> = ({ caseId, drivingSlip, close, visible }): React.ReactElement => {
	const { t } = useTranslation();

	const [deleteDrivingSlip, { loading: deleteDrivingSlipSubmitting }] = useMutation<DeleteDrivingSlip, DeleteDrivingSlipVariables>(DELETE_DRIVINGSLIP);

	const postDeleteDrivingSlip = async () => {
		try {
			await deleteDrivingSlip({
				variables: {
					id: drivingSlip.id,
					caseId: caseId,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetWebCase, GetWebCaseVariables>({
						query: GET_CASE,
						variables: {
							id: caseId,
						},
					});

					if (cachedRequest === null || cachedRequest.case === null) {
						return;
					}

					cache.writeQuery<GetWebCase, GetWebCaseVariables>({
						query: GET_CASE,
						variables: {
							id: caseId,
						},
						data: {
							case: {
								...cachedRequest.case,
								drivingSlipSeries: [
									...cachedRequest.case.drivingSlipSeries.map(driv => {
										return {
											id: driv.id,
											completed: driv.completed,
											drivingSlips: driv.drivingSlips.filter(slips => drivingSlip.id !== slips.id),
										} as GetWebCase_case_drivingSlipSeries;
									}),
								],
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<Modal
			title="drivingSlips.delete"
			close={close}
			visible={visible}
			size={ModalSize.LARGE}
			body={
				<div>
					<FormFieldHeader title="drivingSlips.wantToDelete" />
					<div className="flex flex-col">
						<div className="mt-2">
							{t('drivingSlips.status.' + drivingSlip.status)} <span className="lowercase">{t('common.drivingSlip')}</span>
						</div>
						<div>
							<p className="mt-2 text-sm">{t('common.arrived')}</p>
							{dateToDateTimeString(drivingSlip.start ?? '')}
						</div>
						<div>
							<p className="mt-2 text-sm">{t('drivingSlips.driver')}</p> {drivingSlip.driver?.name ?? t('common.noneSpecified')}
						</div>

						<Button danger text="drivingSlips.delete" loading={deleteDrivingSlipSubmitting} onClick={() => postDeleteDrivingSlip()} className="mt-4" />
					</div>
				</div>
			}
		/>
	);
};

export default DeleteDrivingSlipModal;
