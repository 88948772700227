import React from 'react';
import PlannerSchedule, { UserCalendarAndColor } from './PlannerSchedule';
import PlannerLine from './PlannerLine';
import { DateTime } from 'luxon';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { getDates } from './PlannerContext';
import { NumberOfDays, SelectedUser, getColorsForAll } from './PlannerHelpers';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Button from '@ssg/common/Components/Button';
import { GetCalendarsQuery, useGetCalendarsQuery, useGetWebLocationsQuery } from '@ssg/common/GraphQL/indexV2';
import { useLocation, useParams } from 'react-router-dom';
import Loading from '@ssg/common/Components/Loading';
import { useTranslation } from 'react-i18next';
import PlannerEmployees from './PlannerEmployees';
import TextButton from '@ssg/common/Components/TextButton';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterFull } from '@fortawesome/pro-solid-svg-icons';
import SmallLogo from '@ssg/common/Components/Logo/SmallLogo';
import Dropdown from '@ssg/common/Components/Dropdown';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
const fakeuserCalendarAndColor: UserCalendarAndColor = {
	events: [],
	user: {
		department: '',
		area: '',
		defaultLanguage: '',
		email: '',
		employeeId: '',
		id: '',
		jobFunction: '',
		name: '',
		phone: '',
		plannerHelpText: '',
		plannerHelpTextUpdatedAt: '',
	},
	color: 'bg-white',
};

const PlannerLocationScreen: React.FC = (): React.ReactElement => {

	const { t } = useTranslation();
	const [showEmployees, setShowEmployees] = useStorageState<boolean>(window.localStorage, 'plannerLocationEmployess', true);
	const [date, setDate] = React.useState<string>(DateTime.now().toISODate());
	const [numberOfDays, setNumberOfDaysStore] = useStorageState<NumberOfDays>(window.localStorage, 'plannerDays-location', 7);
	const [datesStore, setDateStore] = useStorageState<string[]>(window.localStorage, 'plannerDates-location', getDates(numberOfDays, DateTime.now()));
	const [selectedUsers, setSelectedUsers] = useStorageState<SelectedUser[]>(window.localStorage, 'plannerSelectedUsers-location', []);
	const [location, setLocation] = useStorageState<string>(window.localStorage, 'location-location', '');

	// const location = useLocation();

	// React.useEffect(() => {

	// 	const users = Array.from(new URLSearchParams(location.search).values())[0].split(',').sort((a, b) => a.localeCompare(b));
	// 	setSelectedUsers(getColorsForAll(users));
	// }, [location.search, setSelectedUsers]);

	const { data: locationData } = useGetWebLocationsQuery();

	const locations: SelectOption[] = React.useMemo(() => [{ label: '', value: '' }, ...(locationData?.locations ?? []).map<SelectOption>(l => ({ label: l.name, value: l.name }))], [locationData]);

	const dates = React.useMemo(() => {
		if (datesStore.length > 0) {
			const fromDate = datesStore[0];
			const toDate = datesStore[datesStore.length - 1];
			const shownDates = datesStore.slice(1, datesStore.length - 1);
			return {
				fromDate, toDate, shownDates,
			};
		}
		return undefined;
	}, [datesStore]);
	React.useEffect(() => {
		setDateStore(getDates(numberOfDays, DateTime.fromISO(date)));
	}, [date, numberOfDays, setDateStore]);

	const { data: userCalendarsData, loading: userCalendarsLoading } = useGetCalendarsQuery({
		fetchPolicy: 'cache-and-network',
		context: { debatch: true },
		variables: {
			dateRange: {
				from: dates?.fromDate ?? '',
				to: dates?.toDate ?? '',
			},
			userIdList: selectedUsers.map(su => su.id),
		},
		pollInterval: 30000,
		skip: selectedUsers.length === 0 || typeof dates === 'undefined',
	});

	const [usersCalendars, setUsersCalendars] = React.useState<GetCalendarsQuery['userCalendars']>([]);

	React.useEffect(() => {
		typeof userCalendarsData?.userCalendars !== 'undefined' && userCalendarsData.userCalendars.length === selectedUsers.length && setUsersCalendars(userCalendarsData.userCalendars.slice().sort((a, b) => a.user.id.localeCompare(b.user.id)));
	}, [userCalendarsData, selectedUsers.length]);

	const scrollContainerRef = React.useRef<HTMLDivElement>(null);


	// React.useEffect(() => {
	// 	let scrollAmount = 100; // Adjust this value as needed
	// 	const scrollInterval = 2000; // Adjust this value as needed (in milliseconds)
	// 	const scrollIntervalId = setInterval(() => {

	// 		const div = scrollContainerRef.current;
	// 		if (div) {
	// 			div.scrollTop += scrollAmount;
	// 			// If we've reached the bottom, start scrolling back up
	// 			if (div.scrollHeight - div.scrollTop === div.clientHeight) {
	// 				scrollAmount = -scrollAmount;
	// 			}
	// 			// If we've reached the top, start scrolling back down
	// 			else if (div.scrollTop === 0) {
	// 				scrollAmount = -scrollAmount;
	// 			}
	// 		}
	// 	}, scrollInterval);

	// 	// Clean up the interval on unmount
	// 	return () => clearInterval(scrollIntervalId);
	// }, []);

	// React.useEffect(() => {
	// 	let animationFrameId;
	// 	const scrollAmount = 1; // Adjust this value as needed
	// 	const scrollInterval = 50; // Adjust this value as needed (in milliseconds)
	// 	let scrollDirection = 1; // 1 for down, -1 for up
	// 	const scrollSmoothly = () => {
	// 		const div = scrollContainerRef.current;
	// 		if (div) {
	// 			div.scrollTop += scrollAmount * scrollDirection;
	// 			// If we've reached the bottom, start scrolling back up
	// 			if (div.scrollHeight - div.scrollTop === div.clientHeight) {
	// 				scrollDirection = -1;
	// 			}
	// 			// If we've reached the top, start scrolling back down
	// 			else if (div.scrollTop === 0) {
	// 				scrollDirection = 1;
	// 			}
	// 		}

	// 		animationFrameId = window.requestAnimationFrame(scrollSmoothly);

	// 	};

	// 	animationFrameId = window.requestAnimationFrame(scrollSmoothly);

	// 	// Clean up the animation frame on unmount
	// 	return () => window.cancelAnimationFrame(animationFrameId);
	// }, []);

	React.useEffect(() => {
		let intervalId;
		const scrollAmount = 1; // Adjust this value as needed
		const scrollInterval = 50; // Adjust this value as needed (in milliseconds)
		let scrollDirection = 1; // 1 for down, -1 for up
		const scrollSmoothly = () => {
			const div = scrollContainerRef.current;
			if (div) {
				div.scrollTop += scrollAmount * scrollDirection;
				// If we've reached the bottom, start scrolling back up
				if (div.scrollHeight - div.scrollTop === div.clientHeight) {
					scrollDirection = -1;
				}
				// If we've reached the top, start scrolling back down
				else if (div.scrollTop === 0) {
					scrollDirection = 1;
					const newDate = DateTime.now().toISODate();
					if (newDate !== date) {
						setDate(newDate);
					}
				}
			}
		};

		// eslint-disable-next-line prefer-const
		intervalId = setInterval(scrollSmoothly, scrollInterval);

		// Clean up the interval on unmount
		return () => clearInterval(intervalId);
	}, []);
	return (
		<div className="flex h-screen flex-row overflow-hidden absolute left-0 top-0 w-screen">
			{showEmployees &&
				<div className="px-2 flex flex-col bg-white h-full overflow-y-hidden ">
					<div>
						<Dropdown
							name=''
							title='Vælg lokation'
							data={locations}
							onChange={(e) => setLocation(e.currentTarget.value)}
							defaultValue={location}
						/>
					</div>
					<FormFieldHeader title='Vælg medarbejdere' />
					<PlannerEmployees locationScreenSetSelectedUsers={setSelectedUsers} locationScreenSelectedUsers={selectedUsers ?? []} />
				</div>
			}
			<div className="relative h-screen flex-1 overflow-auto" ref={scrollContainerRef}>
				<div>
					<PlannerLine
						top
						numberOfDays={numberOfDays}
						dates={dates ? dates.shownDates : []}
						userCalendarAndColor={fakeuserCalendarAndColor}
						leftBoxElement={
							<div className="flex items-center h-full">
								{/* <div className="flex justify-between items-end mb-1">
								<div>
									<label className="text-blue block text-xs font-medium">{t('planner.daysShow')}</label>
									<ButtonGroup
										items={[
											{
												isActive: numberOfDays === 7,
												onClick: () => setNumberOfDaysStore(7),
												title: '7',
											},
											{
												isActive: numberOfDays === 14,
												onClick: () => setNumberOfDaysStore(14),
												title: '14',
											},
										]}
									/>
								</div>
								<Button
									secondary
									text="planner.showToday"
									className="py-1 px-2 text-sm h-9"
									onClick={() => setDate(DateTime.now().toISODate())}
								/>
							</div> */}
								{/* <div className="flex flex-row items-center justify-between"> */}
								{/* <TextButton icon={showEmployees ? faFilterFull : faFilter} onClick={() => setShowEmployees(current => !current)} /> */}
								<div className="w-full pl-2 text-center flex flex-row items-center cursor-pointer" onClick={() => setShowEmployees(current => !current)}>
									<SmallLogo black className="h-12 w-12 object-contain" />
									<h1 className="ml-2"><span className="font-bold">BELFOR</span> - {location}</h1>
								</div>
								{/* </div> */}
							</div>}
					/>

					{userCalendarsLoading && <Loading title="planner.gettingCalendars" />}
					{selectedUsers.length === 0 && !userCalendarsLoading && <p className="text-blue text-center mt-20">Tryk på BELFOR logoet for at vælge medarbejdere og lokation</p>}

					{usersCalendars.filter(uc => typeof selectedUsers.find(su => uc.user.id === su.id) !== 'undefined').map((u, index) => {
						const userCalendarAndColor: UserCalendarAndColor = {
							...u,
							color: selectedUsers.find(s => s.id === u.user.id)?.color ?? 'bg-white',
						};

						return (
							<PlannerLine
								numberOfDays={numberOfDays}
								key={u.user.id + index}
								dates={dates ? dates.shownDates : []}
								userCalendarAndColor={userCalendarAndColor}
								leftBoxElement={
									<div className="p-2 text-white">
										<div className="flex flex-row justify-between items-start">
											<p>{u.user.name}</p>
											{/* <TextButton icon={faTimes} iconClassName="text-white text-xl" onClick={() => setSelectedUsers(current => current.filter(c => c.id !== u.user.id))} /> */}
										</div>
										<p>{u.user.phone}</p>
									</div>
								}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PlannerLocationScreen;