import * as yup from 'yup';

yup.setLocale({
	string: {
		required: 'error.required', // Translation key goes here
	},
});

export const movableEditSchema = yup.object().shape({
	description: yup.string().required(),

	status: yup.string().required(),

	location: yup.string().required(),

	placement: yup.string().required(),
});
