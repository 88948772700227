import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import TextButton from '@ssg/common/Components/TextButton';
import { GetVehiclesQuery, VehicleFragment, useGetUsersWithCalendarsQuery, useGetVehiclesQuery } from '@ssg/common/GraphQL/indexV2';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Fuse from 'fuse.js';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Checkbox from '@ssg/common/Components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { getDates } from '../../NewPlanner/PlannerContext';
import { DateTime } from 'luxon';
import classNames from 'classnames';


export interface DriverCarInput {
	driver?: IdLabel;
	car?: IdLabel;
}

export interface DriverCar {
	driver: IdLabel;
	car: IdLabel;
}

export interface IdLabel {
	id: string;
	label: string
}

export function idLabelIsDefined<T>(value: T | undefined): value is T {
	return value !== undefined;
}

// function idLabelIsDefined<T>(value: T | undefined): value is T {
// 	return value !== undefined;
// }

export const driverAndCarIsDefined = (driverCarInput: DriverCarInput[]): DriverCar[] => {
	const driverCars: DriverCar[] = [];
	driverCarInput.forEach(dci => {
		const driver = dci.driver;
		if (typeof driver !== 'undefined') {
			const car = dci.car;
			if (typeof car !== 'undefined') {
				driverCars.push({ car, driver });
			}
		}
	});

	return driverCars;
};



interface Props {
	control: UseFormMethods['control'];
	index: number;
	updateDriversCars: (index: number, driverCar: DriverCarInput) => void;
	driverCar: DriverCarInput;
	removeDriverCar: (index: number) => void;
	shouldFilter: boolean;
	setShouldFilter: React.Dispatch<React.SetStateAction<boolean>>;
	locationName: string;
	showAddMaterials?: boolean;
	setSelectedMaterials: React.Dispatch<React.SetStateAction<IdLabel[]>>;
	selectedMaterials: IdLabel[];
	date: string | undefined;
}

export const getCarLabel = (car: VehicleFragment) => {
	return `${car.brand ?? ''} (${car.vehicleNumber}) - ${car.locationCode} ${car.username ?? ''}`;
};

const DriverCarPicker: React.FC<Props> = ({ control, index, updateDriversCars, driverCar, removeDriverCar, shouldFilter, setShouldFilter, locationName, showAddMaterials = false, setSelectedMaterials, selectedMaterials, date }): React.ReactElement => {
	const [driverSearchText, setDriverSearchText] = React.useState(driverCar.driver ? driverCar.driver.label : '');
	const [carSearchText, setCarSearchText] = React.useState(driverCar.car ? driverCar.car.label : '');
	const [materialSearchText, setMaterialSearchText] = React.useState('');

	const toFromDates = React.useMemo(() => {
		if (typeof date === 'undefined') return undefined;
		if (date === '') return undefined;
		const dates = getDates(1, DateTime.fromISO(date));

		return {
			from: dates[1],
			to: dates[2],
		};

	}, [date]);

	const { data: usersData, loading: usersLoading } = useGetUsersWithCalendarsQuery();
	const { data: carsData, loading: carsDataLoading } = useGetVehiclesQuery({
		variables: {
			isCar: true,
			dateRange: {
				from: toFromDates?.from ?? '',
				to: toFromDates?.to ?? '',
			},
		},
		skip: typeof toFromDates === 'undefined',
		fetchPolicy: 'network-only',
	});
	const { data: materialsData, loading: materialsDataLoading } = useGetVehiclesQuery({
		variables: {
			isCar: false,
			dateRange: {
				from: toFromDates?.from ?? '',
				to: toFromDates?.to ?? '',
			},
		},
		skip: !showAddMaterials || typeof toFromDates === 'undefined',
		fetchPolicy: 'network-only',
	});
	const drivers = React.useMemo(() => usersData?.usersWithCalendars ?? [], [usersData]);
	const cars = React.useMemo(() => carsData?.vehicles ?? [], [carsData?.vehicles]);
	const materials = React.useMemo(() => materialsData?.vehicles ?? [], [materialsData?.vehicles]);
	const searchedUsers: SelectOption[] = React.useMemo(() => {
		let thisDrivers = drivers;
		const fuse = new Fuse(drivers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name'],
		});

		if (driverSearchText.length > 0) {
			thisDrivers = fuse.search(driverSearchText).map(v => v.item);
		}

		const mappedDrivers = thisDrivers.map((s): SelectOption => ({ value: s.id, label: s.name }));
		return mappedDrivers;
	}, [drivers, driverSearchText]);

	const searchedCars: SelectOption[] = React.useMemo(() => {
		let thisCars = cars;
		const fuse = new Fuse(cars, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['brand', 'locationCode', 'vehicleNumber'],
		});

		if (carSearchText.length > 0) {
			thisCars = fuse.search(carSearchText).map(v => v.item);
		}
		if (shouldFilter) {
			thisCars = thisCars.filter(car => car.locationCode.toUpperCase() === locationName.toUpperCase());
		}
		const mappedCars = thisCars.map((s): SelectOption => ({ value: s.vehicleNumber, label: `${(s.bookedDays.length > 0 ? ' Booket på dag! ' : ' ')}${getCarLabel(s)}` }));
		return mappedCars;
	}, [cars, carSearchText, shouldFilter, locationName]);

	const searchedMaterials: SelectOption[] = React.useMemo(() => {
		let thisMaterials = materials;
		const fuse = new Fuse(materials, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['brand', 'locationCode', 'vehicleNumber'],
		});

		if (materialSearchText.length > 0) {
			thisMaterials = fuse.search(materialSearchText).map(v => v.item);
		}
		if (shouldFilter) {
			thisMaterials = thisMaterials.filter(car => car.locationCode.toUpperCase() === locationName.toUpperCase());
		}
		const mappedMaterials = thisMaterials.map((s): SelectOption => ({ value: s.vehicleNumber, label: `${(s.bookedDays.length > 0 ? ' Booket på dag! ' : ' ')}${getCarLabel(s)}` }));
		return mappedMaterials;
	}, [materials, materialSearchText, shouldFilter, locationName]);

	const driverSearchSelectHandler = (index: number, value: string): void => {
		if (value.length === 0) {
			updateDriversCars(index, {});
			return;
		}
		const driver = drivers.find(su => su.id === value);
		if (typeof driver !== 'undefined') {

			const carForDriver = cars.find(c => c.user === driver.employeeId);

			updateDriversCars(index, {
				...driverCar,
				driver: {
					id: driver.id,
					label: driver.name,
				},
				car: carForDriver ? { id: carForDriver.vehicleNumber, label: getCarLabel(carForDriver) } : driverCar.car,
			});

		}
	};

	const carSearchSelectHandler = (index: number, value: string): void => {
		if (value.length === 0) {
			updateDriversCars(index, {
				...driverCar,
				car: undefined,
			});
			return;
		}
		const car = cars.find(su => su.vehicleNumber === value);
		if (typeof car !== 'undefined') {
			updateDriversCars(index, {
				...driverCar,
				car: { id: car.vehicleNumber, label: getCarLabel(car) },
			});

		}
	};

	const materialSearchSelectHandler = (index: number, value: string): void => {
		if (value.length === 0) {
			return;
		}
		const material = materials.find(su => su.vehicleNumber === value);
		if (typeof material !== 'undefined') {
			if (typeof selectedMaterials.find(m => m.id === material.vehicleNumber) === 'undefined') {
				setSelectedMaterials(current => [...current, { id: material.vehicleNumber, label: getCarLabel(material) }]);
			}
			setMaterialSearchText('');
		}
	};


	const carError = React.useMemo(() => {
		const car = driverCar.car;
		const driver = driverCar.driver;
		if (typeof car === 'undefined' || typeof driver === 'undefined') return;

		const selectedCar = cars.find(c => c.vehicleNumber === car.id);
		if (typeof selectedCar === 'undefined') return;
		if (selectedCar.bookedDays.length === 0) return;
		if (selectedCar.bookedDays.some(d => d.driver?.id !== driver.id)) return 'Booket på en anden chauffør';
	}, [cars, driverCar.car, driverCar.driver]);

	const materialError = (materialNo: string) => {
		const driver = driverCar.driver;
		if (typeof driver === 'undefined') return;

		const selectedMaterial = materials.find(c => c.vehicleNumber === materialNo);
		if (typeof selectedMaterial === 'undefined') return;
		if (selectedMaterial.bookedDays.length === 0) return;
		if (selectedMaterial.bookedDays.some(d => d.driver?.id !== driver.id)) return 'Booket på en anden chauffør';
	};

	return (
		<div className="w-full mb-2">
			{showAddMaterials && index === 0 &&
				<div className="flex flex-row space-x-3">
					<div className="w-1/2">
						<div className="flex flex-row items-end">
							<FormFieldHeader htmlFor={'DrivingSlipBox.materials'} title="planner.materials" />
							<Checkbox key={shouldFilter ? '1' : '0'} name='materialFilter' title='Filtrer på lokation' className="text-sm mb-1 ml-2" checked={shouldFilter} onChange={() => setShouldFilter(current => !current)} />
						</div>
						<SearchableSelect
							control={control}
							name="DrivingSlipBox.materials"
							title=""
							key="label"
							options={searchedMaterials ?? []}
							searchFn={searchText => setMaterialSearchText(searchText)}
							onSelect={value => materialSearchSelectHandler(index, value)}
							onBlur={clearFn => clearFn()}
							// clearCallback={isCleared => {
							// 	if (isCleared) {
							// 		materialSearchSelectHandler(index, '');
							// 	}
							// }}
							minInputLength={2}
							isLoading={materialsDataLoading}
						/>
					</div>
					<div className="w-1/2 flex flex-wrap space-x-2 items-end pb-px">
						{selectedMaterials.map((material, index) => {
							const booked = materialError(material.id);

							return (<div>
								<button
									onClick={() => setSelectedMaterials(current => current.filter(c => c.id !== material.id))}
									className={classNames('text-white text-xs h-8 px-2 py-1 rounded-md', { 'bg-red': typeof booked !== 'undefined', 'bg-blue': typeof booked === 'undefined' })}
									key={material.id}
								>
									{material.label}
									<FontAwesomeIcon
										className='ml-1'
										icon={faTimes}
									/>
								</button>
								{booked && <p className="text-red text-xs">{booked}</p>}
							</div>);
						})}
					</div>
				</div>
			}
			<div className="flex flex-row space-x-3">
				<div className="w-1/2" key={driverCar.driver?.id}>
					<SearchableSelect
						control={control}
						name={`selectDriver.[${index}]`}
						title={index === 0 ? 'case.drivenBy' : ''}
						key="label"
						options={searchedUsers ?? []}
						searchFn={searchText => setDriverSearchText(searchText)}
						onSelect={value => driverSearchSelectHandler(index, value)}
						onBlur={clearFn => undefined}
						minInputLength={2}
						isLoading={usersLoading}
						initialSelection={
							driverCar.driver
								? {
									value: driverCar.driver.id,
									label: driverCar.driver.label,
								}
								: undefined
						}
					/>
				</div>
				<div className="w-1/2 flex-row flex items-center">
					<div className="flex-grow-default" key={driverCar.car?.id}>
						{index === 0 && <div className="flex flex-row items-end justify-between">
							<FormFieldHeader htmlFor={'DrivingSlipBox.car'} title="planner.car" />
							<Checkbox key={shouldFilter ? '1' : '0'} name='carFilter' title='Filtrer på lokation' className="text-sm mb-1" checked={shouldFilter} onChange={() => setShouldFilter(current => !current)} />
						</div>}
						<SearchableSelect
							control={control}
							name="DrivingSlipBox.car"
							title=""
							key="label"
							options={searchedCars ?? []}
							searchFn={searchText => setCarSearchText(searchText)}
							onSelect={value => carSearchSelectHandler(index, value)}
							onBlur={clearFn => {
								if (typeof driverCar.car === 'undefined') {
									clearFn();
								}
							}}
							clearCallback={isCleared => {
								if (isCleared) {
									carSearchSelectHandler(index, '');
								}
							}}
							minInputLength={2}
							isLoading={carsDataLoading}
							errorMessage={carError}
							initialSelection={
								driverCar.car
									? {
										value: driverCar.car.id,
										label: driverCar.car.label,
									}
									: undefined
							}
						/>
					</div>
					<div className={index === 0 ? 'hidden' : undefined}>
						<TextButton icon={faTimes} iconClassName="text-red text-lg" onClick={() => removeDriverCar(index)} />
					</div>
				</div>
			</div>

		</div>
	);
};

export default DriverCarPicker;