import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { CreateJobVariables, CreateJob, JobInput, GetWebCase, SearchWebUsers, JobType, GetWebCaseVariables, SearchWebUsersVariables, GetSingleCase_case } from '../../GraphQL';
import { createJobSchema } from '../../Schemas/CreateJobSchema';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';

const CREATE_JOB = loader('../../GraphQL/Jobs/CreateJob.gql');
const GET_CASE = loader('../../GraphQL/Cases/GetWebCase.gql');
const SEARCH_USERS = loader('../../GraphQL/Users/SearchWebUsers.gql');

interface Props {
	open: boolean;
	close: () => void;
	caseData: GetSingleCase_case;
}

const CreateJobModal: React.FC<Props> = ({ open, close, caseData }): React.ReactElement => {
	const [assignedToSearchText, setAssignedToSearchText] = useDebouncedState('', 100);

	const [createJob, { loading: createJobSubmitting }] = useMutation<CreateJob, CreateJobVariables>(CREATE_JOB);

	const { loading, data } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: assignedToSearchText,
			onlyEmployees: true,
		},
		skip: assignedToSearchText.length === 0,
	});

	const searchedUsers = React.useMemo(() => {
		return data?.searchUsers.map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [data]);

	const searchSelectHandler = (value: string): void => {
		setValue('assignedToId', value, { shouldValidate: true });
	};

	const { handleSubmit, control, setValue, register, errors } = useForm<JobInput>({
		resolver: yupResolver(createJobSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: JobInput) => {
		try {
			await postJob({
				job: {
					type: JobType.CASE,
					caseId: caseData.id,
					description: data.description,
					assignedToId: data.assignedToId,
					deadline: data.deadline,
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const postJob = async ({ job }: CreateJobVariables): Promise<void> => {
		await createJob({
			variables: {
				job: job,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.createJob === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCase, GetWebCaseVariables>({
					query: GET_CASE,
					variables: {
						id: caseData.id,
					},
				});

				if (cachedRequest === null || cachedRequest.case.jobs === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CASE,
					variables: {
						id: caseData.id,
					},
					data: {
						case: {
							...cachedRequest.case,
							jobs: [...cachedRequest.case.jobs, data.createJob],
						},
					},
				});
			},
		});
	};

	return (
		<Modal
			title="common.createTask"
			visible={open}
			close={close}
			size={ModalSize.SMALL}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input name="description" title="common.description" innerRef={register} required errorMessage={errors.description?.message ?? ''} />

					<SearchableSelect
						control={control}
						name="assignedToId"
						title="jobs.assignedTo"
						key="label"
						required
						options={searchedUsers ?? []}
						searchFn={(searchText): void => setAssignedToSearchText(searchText)}
						onSelect={(value): void => searchSelectHandler(value)}
						onBlur={(): void => undefined}
						minInputLength={2}
						isLoading={loading}
						errorMessage={errors.assignedToId?.message ?? ''}
					/>

					<Datepicker title="common.deadline" name="deadline" time required errorMessage={errors.deadline?.message ?? ''} innerRef={register} />

					<div className="mt-3">
						<Button submit success text="common.createTask" loading={createJobSubmitting} />
					</div>
				</form>
			}
		/>
	);
};

export default CreateJobModal;
