import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { BaseTimeTrackingEntryFragment, GetrejectedTimeTrackingsDayForUserQuery, TimeTrackingDay, TimeTrackingStatus } from '@ssg/common/GraphQL/indexV2';
import { ApolloQueryResult } from '@apollo/client';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import TimeRegistrationForm from '@ssg/common/TimeRegistration/TimeRegistrationForm';
import DayScheduler from '@ssg/common/TimeRegistration/DayScheduler';
interface Props {
	open: boolean;
	close: () => void;
	data: TimeTrackingDay | undefined;
	date: string;
	userContext: {
		userId: string;
		salaryType: string | null;
		hireDate: string | null;
	};
	refetchRejected: () => Promise<ApolloQueryResult<GetrejectedTimeTrackingsDayForUserQuery>>;
}

const RejectedRegistrationModalNew: React.FC<Props> = ({ open, close, data, userContext, date, refetchRejected }) => {

	const timeregPermissions = {
		approver: false,
		responsible: false,
		datePicker: false,
	};

	const [selectedLine, setSelectedLine] = React.useState<BaseTimeTrackingEntryFragment | undefined>(undefined);

	const handleSelectedLine = (value: BaseTimeTrackingEntryFragment) => {
		setSelectedLine(value);
	};

	return (
		<Modal
			title="timeRegistration.overviewTitle"
			visible={open}
			close={close}
			size={ModalSize.LARGE}
			body={
				<div>
					<div className="w-full">
						{(selectedLine?.timeTrackingStatus === TimeTrackingStatus.Rejected && selectedLine?.rejectMessage) && (
							<div>
								{selectedLine?.rejectMessage !== '' && (
									<div className="bg-red border-red border-1 mb-2 rounded-md bg-opacity-25 p-2">
										<p className="text-sm">
											<FontAwesomeIcon icon={faExclamationCircle} className="text-red mr-3" />
											{selectedLine?.rejectMessage}
										</p>
									</div>
								)}
							</div>
						)}
						{(selectedLine?.addonLines.some(a => a.timeTrackingStatus === TimeTrackingStatus.Rejected) && selectedLine?.addonLines?.some(a => a.rejectMessage)) && (
							<div>
								{selectedLine?.addonLines?.map(a => {
									if (a?.rejectMessage === '') {
										return null;
									}
									return (
										<div key={a?.id} className="bg-red border-red border-1 mb-2 rounded-md bg-opacity-25 p-2">
											<p className="text-sm">
												<FontAwesomeIcon icon={faExclamationCircle} className="text-red mr-3" />
												{`(${a?.paymentSupplementCode}) ${a?.rejectMessage ?? ''}`}
											</p>
										</div>
									);
								})}
							</div>
						)}
					</div>
					<div className="flex">
						<div className="w-1/2">
							<TimeRegistrationForm
								userContext={{
									id: userContext.userId,
									permissions: timeregPermissions,
									salaryType: userContext.salaryType ?? null,
									hireDate: userContext.hireDate ?? null,
								}}
								fullWidth={true}
								editMode={true}
								setShowEditTimereg={() => null}
								rejectMode={true}
								timeTrackingsDayDataRaw={data as TimeTrackingDay}
								key={selectedLine ? selectedLine.id : 'none'}
								entryForEditing={selectedLine}
								timeTrackingsDayLoading={false}
								closeRejectModal={close}
								refetchRejected={refetchRejected}
								userId={{
									set: () => null,
									value: userContext.userId,
								}}
								substitutionReg={{
									set: () => null,
									value: false,
								}}
								selectedDate={{
									set: () => null,
									value: date,
								}}
							/>
						</div>
						<div className="w-1/2">
							<DayScheduler
								dayId={data?.id ?? ''}
								entries={data?.timeTrackingEntries ?? []}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={false}
								setShowEditTimereg={() => null}
								setActiveTimeEntry={handleSelectedLine}
								compactMode={true}
							/>
							<DayScheduler
								dayId={data?.id ?? ''}
								entries={data?.timeTrackingEntries ?? []}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={false}
								setShowEditTimereg={() => null}
								setActiveTimeEntry={handleSelectedLine}
								compactMode={true}
								standAloneMode={true}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default RejectedRegistrationModalNew;
