import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { caseCreationSchema } from '../../Schemas/CaseCreationSchema';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
	CreateCase,
	CreateCaseVariables,
	CasePriority,
	DebitorType,
	CreateCase_createCase,
	GetWebCase_case,
	DrivingSlipHelperInput,
	UploadDrivingSlipFile,
	UploadDrivingSlipFileVariables,
	GetWebCase_case_damage_contacts,
	ScalePointStatus,
	GetCaseAdminDataPlanner,
	//GetCatalogCaseCreation,
	GetCatalogCaseCreation_catalog,
	GetWebDebitors_debitors,
	ContactInput,
} from '../../GraphQL';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import DamageBox from './DamageBox';
import Button from '@ssg/common/Components/Button';
import CustomerBox from './CustomerBox';
import DamageAddressBox from './DamageAddressBox';
import PolicyHolderBox from './PolicyHolderBox';
import DrivingSlipBox from './DrivingSlipBox';
import SSGInformationBox from './SSGInformationBox';
import RequsitionerInformationBox from './RequsitionerInformationBox';
import VisitationBox from './VisitationBox';
import AdviserBox from './AdviserBox';
import ReferrerBox from './ReferrerBox';
import SkaforBox from './SkaforBox';
import AlternativeContactBox from './AlternativeContactBox';
import Loading from '@ssg/common/Components/Loading';
import RiskEvaluationBox from './RiskEvaluationBox';
import { toBase64, stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import DamageContactModal from './DamageContactModal';
import { removeTypename } from '../../helper';
import DamageContactBox from './DamageContactBox';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { useTranslation } from 'react-i18next';
import Box from '../../Components/Layout/Box';
import Input from '@ssg/common/Components/Input';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { driverAndCarIsDefined, idLabelIsDefined } from '../SingleCase/DrivingSlipBox/DriverCarPicker';

const CREATE_CASE = loader('src/GraphQL/Cases/CreateCase.gql');
const UPLOAD_FILE = loader('src/GraphQL/Files/UploadDrivingSlipFile.gql');
const GET_CASE_ADMIN_DATA_PLANNER = loader('src/GraphQL/Cases/GetCaseAdminDataPlanner.gql');

interface Props {
	caseData: GetWebCase_case | undefined;
	catalogData: GetCatalogCaseCreation_catalog | undefined;
	showMigrationDataBox?: boolean;
}

const CaseCreationFormWrapper: React.FC<Props> = ({ caseData, catalogData, showMigrationDataBox = false }): React.ReactElement => {
	const { t } = useTranslation();
	const setPolicyHolderAsDamageContactFlag = useFlag(FeatureFlagEnums.SET_POLICY_HOLDER_AS_DAMAGE_CONTACT);
	const newPlannerAddDriverCarToDrivingSlipFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_ADD_DRIVER_CAR_TO_DRIVINGSLIP);

	const history = useHistory();
	const { index } = useParams<{ id: string; catId: string; index: string }>();

	const [showECBModal, setShowECBModal] = React.useState(typeof caseData !== 'undefined' && caseData.scalePointStatus !== ScalePointStatus.NOT_CONNECTED);

	const [createCase, { loading: createCaseSubmitting }] = useMutation<CreateCase, CreateCaseVariables>(CREATE_CASE);
	const [uploadDrivingSlipFile] = useMutation<UploadDrivingSlipFile, UploadDrivingSlipFileVariables>(UPLOAD_FILE);

	const { data } = useQuery<GetCaseAdminDataPlanner>(GET_CASE_ADMIN_DATA_PLANNER);

	const { handleSubmit, getValues, control, setValue, errors, register, trigger } = useForm<ICaseCreation>({
		resolver: yupResolver(caseCreationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const [updateContact, setUpdateContact] = useState(false);
	const [createContact, setCreateContact] = useState(false);
	const [deleteContact, setDeleteContact] = useState(false);
	const [contacts, setContacts] = useState<GetWebCase_case_damage_contacts[]>(caseData?.damage.contacts ?? []);
	const [contact, setContact] = useState<GetWebCase_case_damage_contacts>();

	const [contactIndex, setContactIndex] = React.useState<number | undefined>(undefined);

	React.useEffect(() => {
		if (typeof contactIndex !== 'undefined') {
			setContact(contacts[contactIndex]);
		}
		if (typeof contactIndex === 'undefined') {
			setContact(undefined);
		}
	}, [contactIndex, contacts]);

	const getBusinessArea = (causeId: string) => {
		const cause = data?.damageCauses.find(item => item.id === causeId);

		return cause?.businessArea.id ?? '';
	};

	// Create case and driving slip and navigate to case view
	const onSubmit = async (data: ICaseCreation) => {
		try {
			//Pushes case priority ENUMS into an array if checked
			const casePriorities: CasePriority[] = [];
			data.VisitationBox.visitationSocialIssues && casePriorities.push(CasePriority.SOCIAL_IMPACT);
			data.VisitationBox.visitationResidentialArea && casePriorities.push(CasePriority.RESIDENTIAL_AREA);
			data.VisitationBox.visitationLossOfBusiness && casePriorities.push(CasePriority.LOSS_OF_BUSINESS_EARNING_CAPACITY);

			const damageContact: ContactInput = {
				address: {
					road: data.DamageAddressBox.damageRoad,
					houseNumber: data.DamageAddressBox.damageHouseNumber,
					floor: data.DamageAddressBox.damageFloor,
					city: data.DamageAddressBox.damageCity,
					postalCode: data.DamageAddressBox.damageZip,
					addressLineAlt: data.DamageAddressBox.damageAddress2,
					country: data.DamageAddressBox.damageCountry,
				},
				email: data.DamageAddressBox.noEmail ? '' : data.DamageAddressBox.damageEmail,
				name: data.DamageAddressBox.damageName,
				phone: data.DamageAddressBox.damagePhone,
			};

			const policyHolder: ContactInput =
				setPolicyHolderAsDamageContactFlag && data.PolicyHolderBox.policyName === ''
					? damageContact
					: {
						address: {
							road: data.PolicyHolderBox.policyRoad ?? '',
							houseNumber: data.PolicyHolderBox.policyHouseNumber,
							floor: data.PolicyHolderBox.policyFloor,
							city: data.PolicyHolderBox.policyCity,
							postalCode: data.PolicyHolderBox.policyZip,
							addressLineAlt: data.PolicyHolderBox.policyAddress2,
							country: data.PolicyHolderBox.policyCountry ?? '',
						},
						email: data.PolicyHolderBox.noEmail ? '' : data.PolicyHolderBox.policyEmail,
						name: data.PolicyHolderBox.policyName,
						phone: data.PolicyHolderBox.policyPhone,
					};

			const newCase = await postCase({
				migrationInput: !showMigrationDataBox
					? null
					: {
						erpNo: data.MigrationBox.erpNo,
						createdAt: data.MigrationBox.createdAt,
						createdBy: data.MigrationBox.createdBy,
					},

				case: {
					debitor: {
						debitorId: data.CustomerBox.requisitionerInsuranceName,
						company: data.CustomerBox.requisitionerInsuranceName,
						type: data.CustomerBox.debitorType as DebitorType,
						policeNumber: data.CustomerBox.noInsuranceNumber ? '' : data.CustomerBox.requisitionerInsuranceNumber,
						cvrNumber: data.CustomerBox.requisitionerCVR && !data.CustomerBox.noCVR ? data.CustomerBox.requisitionerCVR : '',
						attention: data.CustomerBox.requisitionerAttentionName,
						shippingAddressReference: (data.CustomerBox.shippingAddressReference?.length ?? 0) > 0 ? data.CustomerBox.shippingAddressReference : undefined,
						billingAddress: {
							road: data.CustomerBox.insuranceInvoiceRoad ?? '',
							houseNumber: data.CustomerBox.insuranceInvoiceHouseNumber,
							floor: data.CustomerBox.insuranceInvoiceFloor,
							city: data.CustomerBox.insuranceInvoiceCity,
							postalCode: data.CustomerBox.insuranceInvoiceZip,
							addressLineAlt: data.CustomerBox.insuranceInvoiceAddress2,
							country: data.CustomerBox.insuranceInvoiceCountry,
						},
					},
					damage: {
						category: data.DamageBox.damageLocation,
						date: formatDateForInput(data.DamageBox.damageDate),
						description: data.DamageBox.damageDescription,
						businessArea: getBusinessArea(data.DamageBox.damageCause),
						cause: data.DamageBox.damageCause,
						contact: damageContact,
						accessConditions: data.DamageAddressBox.accessConditions,
						contacts: removeTypename(contacts),
					},
					visitation: {
						urgent: data.VisitationBox.visitationImidiateAttention,
						priorities: casePriorities as CasePriority[],
						calledBack: {
							value: data.VisitationBox.calledBack,
							comment: data.VisitationBox.calledBack ? data.VisitationBox.calledBackDescription : null,
						},
						awaiting: {
							value: false,
							comment: null,
						},
						answers: data.VisitationBox.answers,
					},
					policyHolder: policyHolder,
					requisitioner: {
						name: data.RequisitionerInformationBox.requisitionerName,
						phone: data.RequisitionerInformationBox.requisitionerPhone,
						email: data.RequisitionerInformationBox.noEmail ? '' : data.RequisitionerInformationBox.requisitionerEmail,
						relation: data.RequisitionerInformationBox.requisitionerRelation,
					},
					adviser: data.AdviserBox.name
						? {
							name: data.AdviserBox.name,
							phone: data.AdviserBox.phone,
							email: data.AdviserBox.noEmail ? '' : data.AdviserBox.email,
							companyName: data.AdviserBox.companyName,
							companyPhone: data.AdviserBox.companyPhone,
						}
						: null,
					referrer: data.ReferrerBox.name
						? {
							name: data.ReferrerBox.name,
							phone: data.ReferrerBox.phone,
							email: data.ReferrerBox.noEmail ? '' : data.ReferrerBox.email,
							companyName: data.ReferrerBox.companyName,
							companyPhone: data.ReferrerBox.companyPhone,
						}
						: null,
					ssgDepartment: data.SSGInformationBox.ssgDepartment,
					ssgLocation: data.SSGInformationBox.ssgLocation,
					//TODO: Fix in backend
					caseManager: data.SSGInformationBox.ssgCaseManager,
					projectManager: data.SSGInformationBox.ssgProjectManager,
					skafor: data.InformationBox.skafor,
					alternativeContact: data.AlternativeContactBox.name
						? {
							name: data.AlternativeContactBox.name,
							phone: data.AlternativeContactBox.phone,
							email: data.AlternativeContactBox.noEmail ? '' : data.AlternativeContactBox.email,
							comment: data.AlternativeContactBox.comment,
						}
						: null,
					riskEvaluationAnswers: data.DrivingSlipBox.drivingWanted
						? data.RiskEvaluationBox.answers
							? data.RiskEvaluationBox.answers.filter(a => a !== null && typeof a !== 'undefined')
							: []
						: [],
				},
				drivingSlip: createDrivingSlipInput(data),
				saveInScalePoint: data.CustomerBox.createInECB ?? false,
			});
			if (newCase) {
				const drivingSlipSeries = newCase.drivingSlipSeries[0];
				if (typeof drivingSlipSeries !== 'undefined') {
					await Promise.all(
						(data.DrivingSlipBox.files ?? []).map(async file => {
							const fileAsString = await toBase64(file);
							const fileAsByteArray = stringToByteArray(fileAsString);

							await uploadDrivingSlipFile({
								variables: {
									caseNo: newCase.erpNo,
									drivingSlipId: drivingSlipSeries.id,
									file: fileAsByteArray,
									fileName: file.name,
								},
							});
						}),
					);
				}

				history.push(`/case/${newCase.id}`);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const postCase = async (input: CreateCaseVariables): Promise<CreateCase_createCase | void> => {
		const newCase = await createCase({ variables: input });

		return newCase.data?.createCase;
	};

	const createDrivingSlipInput = (data: ICaseCreation): DrivingSlipHelperInput | null => {
		if (!data.DrivingSlipBox.drivingWanted) {
			return null;
		}

		const estimatedHours = data.DrivingSlipBox.drivingTimeEstimate ?? null;

		const plannedDuration = estimatedHours ?? 1; // Default is one hour duration
		const driversCars = data.DrivingSlipBox.driverCarList;
		const drivers = (data.DrivingSlipBox.drivingBy ?? []).map(d => ({ id: d, label: d }));
		const endDate = new Date(data.DrivingSlipBox.drivingDateTime);
		endDate.setHours(data.DrivingSlipBox.drivingDateTime.getHours() + plannedDuration);
		const filteredDrivers = driversCars.map(dc => dc.driver).filter(idLabelIsDefined);
		const driversToPost = newPlannerAddDriverCarToDrivingSlipFlag ? filteredDrivers : drivers;
		const driverCarList = newPlannerAddDriverCarToDrivingSlipFlag ? driversCars : [];

		return {
			input: {
				driver: driversToPost.length > 0 ? driversToPost[0].id : null,
				estimatedHours: estimatedHours,
				start: data.DrivingSlipBox.drivingDateTime.toISOString(),
				end: endDate.toISOString(),
				urgent: data.VisitationBox.visitationImidiateAttention,
				deadline: data.DrivingSlipBox.deadline.toISOString(),
				location: data.DrivingSlipBox.location,
				department: data.DrivingSlipBox.department,
				category: data.DrivingSlipBox.category.length > 0 ? data.DrivingSlipBox.category : null,
				comment: data.DamageBox.damageDescription,
				plannerMessage: data.DrivingSlipBox.plannerMessage,
				halfDay: data.DrivingSlipBox.halfDay,
			},
			driverCars: driverAndCarIsDefined(driverCarList).map(dc => ({ car: dc.car.id, driver: dc.driver.id })),
			immediatelyPlan: data.DrivingSlipBox.immediatelyPlan,
			additionalDays: data.DrivingSlipBox.additionalDays,
			additionalDrivers: driversToPost.slice(1).map(driver => driver.id),
		};
	};
	const [selectedDebitor, setSelectedDebitor] = React.useState<GetWebDebitors_debitors | undefined>(undefined);

	if (!data) return <Loading />;

	return (
		<div>
			<form autoComplete="nope" className="space-y-8 px-10 pb-10 text-sm" onSubmit={handleSubmit(onSubmit)}>
				{/* REMOVE AFTER MIGRATION */}
				{showMigrationDataBox && (
					<div className="mx-4">
						<Box form title="MIGRERINGSDATA - FJERNES EFTER DATAMIGRERING" noPadding>
							<hr />

							<Input id="migration-erpNo" title="Sagsnummer" required innerRef={register} name="MigrationBox.erpNo" errorMessage={errors.MigrationBox?.erpNo?.message ?? ''} />

							<Input
								id="migration-createdAt"
								title="Oprettelsestidspunkt"
								required
								innerRef={register}
								name="MigrationBox.createdAt"
								errorMessage={errors.MigrationBox?.createdAt?.message ?? ''}
							/>

							<Input
								id="migration-createdBy"
								title="Oprettet af"
								required
								innerRef={register}
								name="MigrationBox.createdBy"
								errorMessage={errors.MigrationBox?.createdBy?.message ?? ''}
							/>
						</Box>
					</div>
				)}

				<SkaforBox caseData={caseData} register={register} />

				<div className="mb-8 flex flex-col lg:mx-4 lg:flex-row lg:flex-wrap">
					<div className="w-full lg:mr-8 lg:flex-1">
						<DamageBox
							caseData={caseData}
							categoryList={data?.damageCategories ?? []}
							causeList={data?.damageCauses ?? []}
							errors={errors}
							register={register}
							control={control}
							setValue={setValue}
						/>
					</div>
					<div className="w-full lg:flex-1">
						<CustomerBox
							caseData={caseData}
							selectedDebitor={selectedDebitor}
							setSelectedDebitor={setSelectedDebitor}
							catalogDebitor={catalogData?.debitor}
							errors={errors}
							register={register}
							control={control}
							setValue={setValue}
							trigger={trigger}
						/>
					</div>
				</div>

				<div className="mb-8 flex flex-col lg:mx-4 lg:flex-row lg:flex-wrap">
					<div className="w-full lg:mr-8 lg:flex-1">
						<DamageAddressBox
							caseData={caseData}
							catalogAddress={catalogData?.addresses.find((_, i) => i === parseInt(index))}
							errors={errors}
							register={register}
							control={control}
							setValue={setValue}
							trigger={trigger}
							getValues={getValues}
						/>
					</div>
					<div className="w-full lg:flex-1">
						<PolicyHolderBox
							caseData={caseData}
							selectedDebitor={selectedDebitor}
							errors={errors}
							register={register}
							control={control}
							getValues={getValues}
							setValue={setValue}
							trigger={trigger}
						/>
					</div>
					<div className="mt-8 flex w-full flex-col lg:flex-row">
						<div className="w-full lg:mr-8 lg:flex-1">
							<AlternativeContactBox caseData={caseData} errors={errors} register={register} control={control} trigger={trigger} setValue={setValue} getValues={getValues} />
						</div>
						<div className="w-full lg:flex-1">
							<RequsitionerInformationBox
								caseData={caseData}
								selectedDebitor={selectedDebitor}
								errors={errors}
								register={register}
								control={control}
								trigger={trigger}
								setValue={setValue}
								getValues={getValues}
							/>
						</div>
					</div>
				</div>

				{/* <CustomerBox caseData={caseData} errors={errors} register={register} control={control} setValue={setValue} trigger={trigger} /> */}
				<DamageContactBox
					caseData={caseData}
					errors={errors}
					register={register}
					control={control}
					setValue={setValue}
					trigger={trigger}
					setCreateContact={setCreateContact}
					setDeleteContact={setDeleteContact}
					setUpdateContact={setUpdateContact}
					setContactIndex={setContactIndex}
					contacts={contacts}
				/>

				<VisitationBox caseData={caseData} causeList={data?.damageCauses ?? []} register={register} control={control} setValue={setValue} />

				<div className="flex flex-col lg:flex-row">
					<DrivingSlipBox
						errors={errors}
						register={register}
						control={control}
						setValue={setValue}
						sanitizers={data.usersWithCalendars}
						causeList={data.damageCauses}
						categoryList={data.damageCategories}
						locations={data.locations}
						departments={data.departments}
						dsCategories={data.drivingSlipCategories}
					/>

					<SSGInformationBox
						caseData={caseData}
						causeList={data?.damageCauses ?? []}
						departmentsList={data?.departments ?? []}
						locationsList={data?.locations ?? []}
						errors={errors}
						register={register}
						control={control}
						setValue={setValue}
					/>
				</div>

				<div className="flex flex-col lg:mx-4 lg:flex-row">
					<div className="w-full lg:mr-8 lg:flex-1">
						<AdviserBox caseData={caseData} errors={errors} register={register} control={control} trigger={trigger} setValue={setValue} />
					</div>

					<div className="w-full lg:flex-1">
						<ReferrerBox caseData={caseData} errors={errors} register={register} control={control} trigger={trigger} setValue={setValue} />
					</div>
				</div>

				<div className="hidden">
					<RiskEvaluationBox register={register} errors={errors} control={control} />
				</div>

				<div className="pl-4">
					<Button submit success text="case.create" loading={createCaseSubmitting} />
				</div>
			</form>
			{/* Damage Contact Modal */}
			<div>
				{createContact && (
					<DamageContactModal
						setContacts={setContacts}
						open={createContact}
						close={() => {
							setCreateContact(false);
							setContact(undefined);
						}}
						contactIndex={undefined}
					/>
				)}

				{updateContact && typeof contactIndex !== 'undefined' && typeof contact !== 'undefined' && (
					<DamageContactModal
						setContacts={setContacts}
						open={updateContact}
						data={contact}
						edit
						close={() => {
							setUpdateContact(false);
							setContact(undefined);
							setContactIndex(undefined);
						}}
						contactIndex={contactIndex}
					/>
				)}

				{deleteContact && typeof contactIndex !== 'undefined' && typeof contact !== 'undefined' && (
					<DamageContactModal
						setContacts={setContacts}
						open={deleteContact}
						data={contact}
						erase
						close={() => {
							setDeleteContact(false);
							setContact(undefined);
							setContactIndex(undefined);
						}}
						contactIndex={contactIndex}
					/>
				)}
			</div>

			<Modal
				visible={showECBModal}
				size={ModalSize.SMALL}
				close={() => setShowECBModal(false)}
				title="case.scalePoint"
				body={<p>{t('case.scalepointDuplicateWarning')}</p>}
				footer={
					<>
						<Button
							secondary
							text="common.yes"
							onClick={() => {
								setValue('CustomerBox.createInECB', true, {
									shouldValidate: true,
								});
								setShowECBModal(false);
							}}
						/>

						<Button
							secondary
							text="common.no"
							onClick={() => {
								setValue('CustomerBox.createInECB', false, {
									shouldValidate: true,
								});
								setShowECBModal(false);
							}}
						/>
					</>
				}
			/>
		</div>
	);
};

export default CaseCreationFormWrapper;
