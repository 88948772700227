import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
	array: {
		required: 'error.minOneSelected',
	},
});

export const CaseViewRiskEvaluationSchema = yup.object().shape({
	answers: yup
		.array()
		.of(
			yup.object().shape({
				question: yup.string(),
				answer: yup.boolean(),
			}),
		)
		.test('atLeastOne', 'errors.minOneSelected', (value: any[] | undefined = []) => {
			const arr = value.filter(v => v !== null);
			return arr.filter(a => a.answer).length >= 1;
		})
		.required(),
});
