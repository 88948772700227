import TextButton from '@ssg/common/Components/TextButton';
import React from 'react';
import CalendarEntity from '../Planner/CalendarEntity';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { SanitizersFilter } from '../Planner/Planner';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import UserContext from '../../UserContext';
import Fuse from 'fuse.js';
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import Checkbox from '@ssg/common/Components/Checkbox';
import { SelectedUser, bgColors, getColorsForAll, usePlannerAdminData } from './PlannerHelpers';
import PlannerEmployeeFilter from './PlannerEmployeeFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { P } from '@ssg/common/Components/Text';
import PlannerContext from './PlannerContext';
import EmployeeCard from './EmployeeCard';

interface Props {
	locationScreenSetSelectedUsers?: React.Dispatch<React.SetStateAction<SelectedUser[]>>;
	locationScreenSelectedUsers?: SelectedUser[];
}

const PlannerEmployees: React.FC<Props> = ({ locationScreenSelectedUsers, locationScreenSetSelectedUsers }): React.ReactElement => {
	const { t } = useTranslation();
	const { selectedUsers: contextSelectedUsers, setSelectedUsers: contextSetSelectedUsers } = React.useContext(PlannerContext);

	const { selectedUsers, setSelectedUsers } = React.useMemo(() => {
		return {
			selectedUsers: locationScreenSelectedUsers ?? contextSelectedUsers,
			setSelectedUsers: locationScreenSetSelectedUsers ?? contextSetSelectedUsers,
		};
	}, [contextSelectedUsers, contextSetSelectedUsers, locationScreenSelectedUsers, locationScreenSetSelectedUsers]);
	const [showFilters, setShowFilters] = React.useState(false);
	const { user } = React.useContext(UserContext);
	const { adminDataLoading, adminData: { usersWithCalendars } } = usePlannerAdminData();

	const [sanitizerSearchTerm, setSanitizerSearchTerm] = useDebouncedState('', 100);
	const [allSanitizersActive, setAllSanitizersActive] = React.useState<boolean>(false);
	const [onlyPlannerLocations, setOnlyPlannerLocations] = React.useState<boolean>(false);
	const [activeSanitizersFilters, setActiveSanitizersFilters] = useStorageState<SanitizersFilter>(window.sessionStorage, 'activeUserSanitizerFilters', {
		name: '',
		locations: [],
		departments: [],
		jobFunctions: [],
		sanitizers: [],
	});

	const selectedUserSanitizerFilter = React.useMemo(
		() => user?.sanitizerFilters.find(f => f.name === activeSanitizersFilters.name),
		[activeSanitizersFilters.name, user?.sanitizerFilters],
	);

	React.useEffect(() => {
		if (typeof selectedUserSanitizerFilter === 'undefined') {
			return;
		}
		const selectableEmployess = usersWithCalendars.map(u => u.id);
		const filterEmployees = selectedUserSanitizerFilter.sanitizers.filter(s => selectableEmployess.includes(s));
		setActiveSanitizersFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserSanitizerFilter.name,
			departments: selectedUserSanitizerFilter.departments ?? [],
			locations: selectedUserSanitizerFilter.locations ?? [],
			jobFunctions: selectedUserSanitizerFilter.jobFunctions ?? [],
			sanitizers: filterEmployees ?? [],
		}));

		if (filterEmployees.length > 0 && selectedUsers.length === 0) {
			setSelectedUsers(getColorsForAll(filterEmployees));
		}

	}, [selectedUserSanitizerFilter, selectedUsers.length, setActiveSanitizersFilters, setSelectedUsers, usersWithCalendars]);

	const filteredSanitizers = React.useMemo(() => {
		let sanitizers = usersWithCalendars;
		const fuse = new Fuse(sanitizers, {
			shouldSort: true,
			threshold: 0.2,
			keys: ['name', 'email'],
		});

		if (sanitizerSearchTerm.length > 0) {
			sanitizers = fuse.search(sanitizerSearchTerm).map(v => v.item);
		}

		if (onlyPlannerLocations) {
			sanitizers = sanitizers.filter(s => s.id.includes('planner'));
		}

		return sanitizers
			.filter(
				s =>
					typeof activeSanitizersFilters.locations === 'undefined' || activeSanitizersFilters.locations.length === 0 || activeSanitizersFilters.locations.includes(s.department ?? 'UNKNOWN'),
			)
			.filter(
				s =>
					typeof activeSanitizersFilters.departments === 'undefined' || activeSanitizersFilters.departments.length === 0 || activeSanitizersFilters.departments.includes(s.area ?? 'UNKNOWN'),
			)
			.filter(
				s =>
					typeof activeSanitizersFilters.jobFunctions === 'undefined' ||
					activeSanitizersFilters.jobFunctions.length === 0 ||
					activeSanitizersFilters.jobFunctions.includes(s.jobFunction ?? 'UNKNOWN'),
			)
			.sort((a, b) => a.id.localeCompare(b.id));
	}, [usersWithCalendars, sanitizerSearchTerm, onlyPlannerLocations, activeSanitizersFilters.locations, activeSanitizersFilters.departments, activeSanitizersFilters.jobFunctions]);
	const [departmentsList, locationsList, jobFuncionsList] = React.useMemo(
		() => [
			Array.from(
				new Set(
					usersWithCalendars
						.slice()
						.map(u => u.area)
						.filter((a): a is string => a !== null),
				),
			),
			Array.from(
				new Set(
					usersWithCalendars
						.slice()
						.map(u => u.department)
						.filter((a): a is string => a !== null),
				),
			),
			Array.from(
				new Set(
					usersWithCalendars
						.slice()
						.map(u => u.jobFunction)
						.filter((a): a is string => a !== null),
				),
			),
		],
		[usersWithCalendars],
	);


	const handleEntityOnClick2 = (id: string) => {
		const selectedUser = selectedUsers.find(s => s.id === id);
		if (selectedUser) {
			setSelectedUsers(current => current.filter(c => c.id !== id));
		} else {
			setSelectedUsers(current => getColorsForAll([...current, { id, color: 'bg-white' }].map(s => s.id)));
		}
	};



	const selectAllSanitizers = () => {
		if (allSanitizersActive) {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(getColorsForAll(filteredSanitizers.map(s => s.id)));
		}
		setAllSanitizersActive(!allSanitizersActive);
	};

	return (
		<>
			<div>
				<PlannerEmployeeFilter
					showFilters={showFilters}
					filters={activeSanitizersFilters}
					setFilters={setActiveSanitizersFilters}
					setActiveUserFilter={setActiveSanitizersFilters}
					setSanitizerSearchTerm={setSanitizerSearchTerm}
					sanitizerSearchTerm={sanitizerSearchTerm}
					activeUserFilter={activeSanitizersFilters}
					departmentsList={departmentsList}
					locationsList={locationsList}
					jobFunctionsList={jobFuncionsList}
					selectedUserIds={selectedUsers.map(s => s.id)}
					clearSelectedUsers={() => setSelectedUsers([])}
				/>
			</div>
			<Checkbox
				name="plannerlocations"
				title="drivingSlips.plannerLocations"
				className="font-normal -mt-2 ml-px"
				textClassName='text-sm mt-px text-blue'
				labelWeight='NONE'
				checkedControlled={onlyPlannerLocations}
				onChange={() => setOnlyPlannerLocations(current => !current)}
			//defaultChecked={data?.halfDay}
			/>

			<div className="mt-3 flex items-center justify-between text-xs font-medium">
				<TextButton
					text={showFilters ? 'common.hideFilters' : 'common.showFilters'}
					icon={showFilters ? faChevronUp : faChevronDown}
					onClick={() => setShowFilters(!showFilters)}
				/>
				<label className="flex cursor-pointer">
					{allSanitizersActive ? t('common.deselectAll') : t('common.selectAll')}
					<Checkbox name="all-sanitizers" checked={allSanitizersActive} disabled={filteredSanitizers.length === 0} onClick={selectAllSanitizers} className="ml-2" />
				</label>
			</div>

			<hr className="my-2 text-blue" />

			<div className="overflow-y-auto">
				{adminDataLoading && (
					<div className="h-40 text-blue text-center">
						<FontAwesomeIcon icon={faSpinner} className="animate-spin" size="5x" />
						<P className="text-2xl">{t('planner.gettingSanitizers')}</P>
					</div>
				)}
				{filteredSanitizers.map((s, index) => (
					<EmployeeCard
						key={`${s.id}_usersList`}
						active={typeof selectedUsers.find(su => su.id === s.id) !== 'undefined'}
						employee={s}
						onClick={() => handleEntityOnClick2(s.id)}
						bgColor={bgColors[index % 2]}
					/>
				))}
			</div>
		</>
	);
};

export default PlannerEmployees;