import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CreateCatalogContact_createCatalogContact_informations, CreateCatalogCraftsman_createCatalogCraftsman_informations } from '../../../GraphQL';
import { CatalogCraftsmanInformationSchema } from '../../../Schemas/CatalogCraftsmanInformationSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import InputPhone from '@ssg/common/Components/InputPhone';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

interface Props {
	informations: CreateCatalogCraftsman_createCatalogCraftsman_informations[] | CreateCatalogContact_createCatalogContact_informations[];
	setInformations: React.Dispatch<React.SetStateAction<CreateCatalogCraftsman_createCatalogCraftsman_informations[]>>;
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: CreateCatalogCraftsman_createCatalogCraftsman_informations | CreateCatalogContact_createCatalogContact_informations;
	erase?: boolean;
	index?: number;
}

const CatalogPhoneInformationModal: React.FC<Props> = ({ open, close, edit = false, data, erase, informations, setInformations, index }) => {
	const { t } = useTranslation();
	const envVar = useContext(EnvironmentVariableContext);

	const [phonenumber, setPhonenumber] = React.useState(data?.phoneNumber ?? '');

	const { handleSubmit, control, setValue, errors, register, trigger } = useForm<CreateCatalogCraftsman_createCatalogCraftsman_informations | CreateCatalogContact_createCatalogContact_informations>(
		{
			resolver: yupResolver(CatalogCraftsmanInformationSchema),
			mode: 'onSubmit',
			reValidateMode: 'onChange',
		},
	);

	const [country, setCountry] = React.useState(envVar.defaultCountry ?? '');

	const phonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('phoneNumber', phonenumber, {
				shouldValidate: true,
			});
			setPhonenumber(phonenumber);
		},
		[setValue],
	);

	React.useEffect(() => {
		phonenumber !== '' && phonenumberHandler(phonenumber);
	}, [phonenumber, phonenumberHandler]);

	const onSubmit = (formData: CreateCatalogCraftsman_createCatalogCraftsman_informations | CreateCatalogContact_createCatalogContact_informations) => {
		console.log(formData);
		if (edit && typeof index !== 'undefined') {
			const newInformations = [...informations];
			newInformations[index] = formData;
			setInformations(newInformations);
		} else {
			setInformations([...informations, formData]);
		}

		close();
	};

	return (
		<Modal
			title=""
			size={ModalSize.MEDIUM}
			visible={open}
			close={close}
			body={
				erase ? (
					<div>
						<FormFieldHeader title="catalog.craftsman.wantToDeleteInformation" />
						<div className="flex flex-col">
							<div>
								{`
								${t('common.phone')}: ${data?.phoneNumber},
								${data?.remark ?? `, ${t('catalog.contact.remark')}: ${data?.remark}`}
								`}
							</div>

							<Button
								danger
								text="catalog.craftsman.deleteInformation"
								onClick={() => {
									setInformations(informations.filter(info => info !== data));
									close();
								}}
								className="mt-4"
							/>
						</div>
					</div>
				) : (
					<div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<InputPhone
								title="common.phone"
								innerRef={register}
								name="phoneNumber"
								errorMessage={errors.phoneNumber?.message ?? ''}
								required
								control={control}
								trigger={trigger}
								searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
								onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
								defaultCountry={country}
								defaultPhoneNumber={formatPhoneNumberIntl(data?.phoneNumber ?? '').replaceAll(/(\s|-)+/g, '')}
							/>

							<Input title="catalog.contact.remark" name="remark" innerRef={register} defaultValue={data?.remark ?? ''} errorMessage={errors.remark?.message} />

							<Button submit success text={edit ? 'catalog.craftsman.updateInformation' : 'catalog.craftsman.addInformation'} className="mt-2 pl-4" />
						</form>
					</div>
				)
			}
		/>
	);
};

export default CatalogPhoneInformationModal;
