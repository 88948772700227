import React from 'react';
import { useQuery } from '@apollo/client';
import { useMicrosoftAuth } from '@ssg/common/Components/MicrosoftAuth';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetWebCase, GetWebCaseVariables } from '../../../GraphQL';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../../Components/Layout/Box';

interface Props {
	caseId: string;
}

const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');

const ReportStaticInfo: React.FC<Props> = ({ caseId }) => {
	const { t } = useTranslation();
	const { account } = useMicrosoftAuth();
	const { data: caseData } = useQuery<GetWebCase, GetWebCaseVariables>(GET_CASE, {
		variables: { id: caseId },
		skip: !caseId, // Handles undefined & empty string scenarios
	});

	return (
		<Box full title="reports.caseInfo">
			<div className="flex py-2">
				<div className="w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.costumer')}:</p>
					<p>{caseData?.case.policyHolder.name}</p>
					<p>{caseData?.case.policyHolder.address.addressLine}</p>
					<p>
						{caseData?.case.policyHolder.address.postalCode} {caseData?.case.policyHolder.address.city}
					</p>
				</div>
				<div className="w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.orderNr')}:</p>
					<p>{caseData?.case.erpNo}</p>
				</div>
			</div>

			<div className="flex py-2">
				<div className="inline-block w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.requester')}</p>
					<p>{caseData?.case.requisitioner?.name}</p>
					<p>{caseData?.case.requisitioner?.email}</p>
					<p>{caseData?.case.requisitioner?.phone}</p>
				</div>
				<div className="inline-block w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.createdBy')}:</p>
					<p>{account?.name}</p>
					<p>{account?.username}</p>
				</div>
			</div>

			<div className="flex py-2">
				<div className="inline-block w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.policeNr')}:</p>
					<p>{caseData?.case.debitor.policeNumber}</p>
				</div>
				<div className="inline-block w-1/2">
					<p className="text-blue text-base font-semibold">{t('reports.dateCreated')}:</p>
					<p>{dateToDateTimeString(Date())}</p>
				</div>
			</div>

			<div className="inline-block w-1/2 py-2">
				<p className="text-blue text-base font-semibold">{t('reports.dateChanged')}:</p>
				<p>{dateToDateTimeString(Date())}</p>
			</div>
		</Box>
	);
};

export default ReportStaticInfo;
