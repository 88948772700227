import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import classNames from 'classnames';
import Header from '@ssg/common/Components/Header';
import EditMachine from './EditMachine';
import MachinesOverview from './MachineOverview';
import MachinesMyReservations from './MachinesMyReservations';

export interface TempMachine {
	id: string;
	description: string;
	case: string | null;
	status: string | null | undefined;
	placement: {
		road: string;
		houseNumber: string;
		postalCode: string;
		city: string;
	};
	origin: string;
	location: string;
	available: boolean;
	changelog: string;
	rent: boolean;
	type: string;
}

const tabs = ['machines.myReservations', 'machines.findMachine', 'machines.overview'];

const Machines: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useStorageState(window.localStorage, 'machinesView', tabs[0]);

	return (
		<div>
			<Header title="common.machines">
				<div className="flex">
					{tabs.map(title => (
						<div key={title} className="mr-3 mt-1">
							<button
								type="button"
								onClick={() => setActiveTab(title)}
								className={classNames('text-blue focus:outline-none', {
									'border-blue border-b-2': title === activeTab,
								})}
							>
								{t(title)}
							</button>
						</div>
					))}
				</div>
			</Header>
			{activeTab === 'machines.myReservations' && <MachinesMyReservations />}
			{activeTab === 'machines.overview' && <MachinesOverview />}
			{activeTab === 'machines.findMachine' && <EditMachine />}
		</div>
	);
};

export default Machines;
