import React from 'react';
import { faFile, faFileDownload, faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getExtensionIcon } from '@ssg/common/Helpers/fileExtensionHelper';
import { GetCaseESDHFiles_case_documents } from '../../../GraphQL';

interface Props {
	file: GetCaseESDHFiles_case_documents;
	setFileData(): void;
	setFileToDelete(): void;
	loading: boolean;
	canDelete?: boolean;
	isExternalUser: boolean;
}

const FileItem: React.FC<Props> = ({ file, setFileData, setFileToDelete, loading, canDelete = true, isExternalUser }): React.ReactElement => {
	return (
		<button
			className="group relative my-2 mx-4 flex h-32 w-48 flex-col items-center justify-center focus:outline-none"
			onClick={() => {
				if (!isExternalUser) {
					window.open(file.url, '_blank');
				} else {
					setFileData();
				}
			}}
			disabled={loading}
		>
			{canDelete && (
				<button
					className="text-red hover:bg-red absolute top-0 right-0 -mt-1 hidden h-6 w-6 items-center justify-center rounded-full hover:text-white group-hover:flex"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setFileToDelete();
					}}
					disabled={loading}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
				</button>
			)}

			{!loading && (
				<>
					<FontAwesomeIcon icon={getExtensionIcon(file.extension)} size="4x" className="block group-hover:hidden" />
					<FontAwesomeIcon icon={faFileDownload} size="4x" className="hidden group-hover:block" />
				</>
			)}

			{loading && (
				<div style={{ height: '64px', width: '48px' }} className="relative">
					<FontAwesomeIcon icon={faFile} size="4x" />
					<FontAwesomeIcon icon={faSpinner} className="absolute animate-spin" style={{ top: '45%', left: '27%' }} size="lg" />
				</div>
			)}

			<p className="mt-2 break-all text-xs group-hover:underline">{file.name.replace('Sales Quote - ', '')}</p>
		</button>
	);
};

export default FileItem;
