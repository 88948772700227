import React from 'react';
import { useQuery } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetSingleCase_case, GetRiskEvaluationCategories, GetRiskEvaluationQuestions } from '../../../GraphQL';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../../Components/Layout/Box';
import RiskEvaluationModal from './RiskEvaluationModal';

const GET_RISK_EVALUATION_QUESTIONS = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationQuestions.gql');
const GET_RISK_EVALUATION_CATEGORIES = loader('src/GraphQL/RiskEvaluation/GetRiskEvaluationCategories.gql');

interface Props {
	caseData: GetSingleCase_case;
	caseViewExternal: boolean;
}

interface Question {
	questionId: string;
	question: string;
	helpText: string;
	answer: boolean;
}

interface Category {
	categoryName: string;
	questions: Question[];
}

const RiskEvaluationBox: React.FC<Props> = ({ caseData, caseViewExternal }): React.ReactElement => {
	const { t } = useTranslation();

	const [showModal, setShowModal] = React.useState<boolean>(false);

	const { data: questions } = useQuery<GetRiskEvaluationQuestions>(GET_RISK_EVALUATION_QUESTIONS);
	const { data: categories } = useQuery<GetRiskEvaluationCategories>(GET_RISK_EVALUATION_CATEGORIES);

	const categoryMap = new Map<string, Category>();

	categories?.riskEvaluationCategories.forEach(c => {
		questions?.riskEvaluationQuestions.forEach(q => {
			if (q.category.id === c.id) {
				if (caseData.riskEvaluationAnswers && caseData.riskEvaluationAnswers?.length > 0) {
					caseData.riskEvaluationAnswers.forEach(a => {
						if (a.question.id === q.id) {
							if (categoryMap.has(c.id)) {
								categoryMap.set(c.id, {
									categoryName: c.categoryName,
									questions: [
										...(categoryMap.get(c.id)?.questions ?? []),
										{
											questionId: q.id,
											question: q.question,
											helpText: q.helpText,
											answer: a.answer,
										},
									],
								});
							} else {
								categoryMap.set(c.id, {
									categoryName: c.categoryName,
									questions: [
										{
											questionId: q.id,
											question: q.question,
											helpText: q.helpText,
											answer: a.answer,
										},
									],
								});
							}
						}
					});
				} else {
					if (categoryMap.has(c.id)) {
						categoryMap.set(c.id, {
							categoryName: c.categoryName,
							questions: [
								...(categoryMap.get(c.id)?.questions ?? []),
								{
									questionId: q.id,
									question: q.question,
									helpText: q.helpText,
									answer: false,
								},
							],
						});
					} else {
						categoryMap.set(c.id, {
							categoryName: c.categoryName,
							questions: [
								{
									questionId: q.id,
									question: q.question,
									helpText: q.helpText,
									answer: false,
								},
							],
						});
					}
				}
			}
		});
	});

	const categoryArray = Array.from(categoryMap.values());

	const sortedChanges = caseData.changes.slice().sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

	const latestChange = sortedChanges.find(c => c.before.riskEvaluationAnswers !== null && c.after.riskEvaluationAnswers !== null);

	return (
		<Box full title="case.riskEvaluation" icon={caseViewExternal ? undefined : faEdit} onClick={() => setShowModal(true)} className="text-blue">
			<div className="flex flex-wrap">
				{caseData.riskEvaluationAnswers && caseData.riskEvaluationAnswers?.length > 0 ? (
					categoryArray.map((c, index) => {
						return (
							<div className="w-1/3" key={`${c.categoryName}_${index}`}>
								<FormFieldHeader title={c.categoryName} />
								{c.questions.map((q, i) => {
									return (
										<div key={i}>
											<p>
												{q.question} - <span className="font-semibold">{q.answer ? t('common.yes') : t('common.no')}</span>
											</p>
											<Input name={`answers[${index + 1}${i}].question`} value={q.questionId} readOnly className="hidden" />
										</div>
									);
								})}
							</div>
						);
					})
				) : (
					<div>{t('case.noRiskEvaluation')}</div>
				)}
			</div>
			{typeof latestChange !== 'undefined' && (
				<div className="text-right">
					<p>
						<span className="font-semibold">{t('common.latestChange')}:&nbsp;</span>
						{dateToDateTimeString(latestChange?.timestamp ?? '')}
						&nbsp;{t('common.by')}&nbsp;{latestChange?.user?.name}
					</p>
				</div>
			)}

			{showModal && <RiskEvaluationModal open={showModal} close={() => setShowModal(false)} data={categoryArray} caseData={caseData} />}
		</Box>
	);
};

export default RiskEvaluationBox;
