import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { PersonalFilter } from './CaseOverview';
import { useForm } from 'react-hook-form';
import {
	GetWebCurrentUser,
	SetUserCaseFilter,
	SetUserCaseFilterVariables,
	RemoveUserCaseFilter,
	RemoveUserCaseFilterVariables,
	UserCaseFilterViewType,
	GetWebDebitors,
	SearchWebUsers,
	GetWebCaseAdminData,
	Permissions,
	CasePriority,
	SearchWebUsersVariables,
} from '../../GraphQL';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import UserContext from '../../UserContext';
import classnames from 'classnames';
import Button from '@ssg/common/Components/Button';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Input from '@ssg/common/Components/Input';
import Dropdown from '@ssg/common/Components/Dropdown';
import Toggle from '@ssg/common/Components/Toggle';
import Select from 'react-select';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Pill from '@ssg/common/Components/Pill';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';
import dk from '@ssg/common/Assets/postalcodes/DK.json';
import no from '@ssg/common/Assets/postalcodes/NO.json';
import se from '@ssg/common/Assets/postalcodes/SE.json';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { favoriteColors } from '../../Components/FavoriteToggle';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as starFilled } from '@fortawesome/pro-solid-svg-icons';

const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');
const SET_USER_CASE_FILTER = loader('src/GraphQL/Users/SetUserCaseFilter.gql');
const REMOVE_USER_CASE_FILTER = loader('src/GraphQL/Users/RemoveUserCaseFilter.gql');
const GET_DEBITORS = loader('../../GraphQL/Debitors/GetWebDebitors.gql');
const SEARCH_USERS = loader('../../GraphQL/Users/SearchWebUsers.gql');

interface Props {
	onFilter: React.Dispatch<React.SetStateAction<PersonalFilter>>;
	activeFilters: PersonalFilter;
	setFilterTerm: (v: string) => void;
	setActiveUserFilter: React.Dispatch<React.SetStateAction<string>>;
	activeUserFilter: string;
	adminDataRaw: GetWebCaseAdminData | undefined;
	selectedStars: string[];
	setSelectedStars: React.Dispatch<React.SetStateAction<string[]>>;

}

const CaseOverviewFilter: React.FC<Props> = ({ onFilter, activeFilters, setFilterTerm, setActiveUserFilter, activeUserFilter, adminDataRaw, selectedStars, setSelectedStars }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];

	const managerFavoritesFlag = useFlag(FeatureFlagEnums.MANAGER_FAVORITES);
	const priorityCustomer = useFlag(FeatureFlagEnums.PRIORITYCUSTOMER);

	const dummyForm = useForm();
	const [saveFilterActive, setSaveFilterActive] = React.useState(false);
	const [deleteFilter, setDeleteFilter] = React.useState(false);
	const [filterSaveName, setFilterSaveName] = React.useState(activeFilters.name ?? '');

	const [showAllFilters, setShowAllFilters] = React.useState(false);

	const adminData = React.useMemo(() => adminDataRaw ?? undefined, [adminDataRaw]);

	const [createUserFilter] = useMutation<SetUserCaseFilter, SetUserCaseFilterVariables>(SET_USER_CASE_FILTER);
	const [removeUserFilter] = useMutation<RemoveUserCaseFilter, RemoveUserCaseFilterVariables>(REMOVE_USER_CASE_FILTER);

	React.useEffect(() => {
		setFilterSaveName(activeUserFilter);
	}, [activeUserFilter]);

	const [debitorSearchText, setDebitorSearchText] = useDebouncedState('', 100);
	const debitorMapRef = React.useRef<Record<string, string>>({});
	const { loading: debitorsLoading, data: debitorsData } = useQuery<GetWebDebitors>(GET_DEBITORS, {
		variables: { searchText: debitorSearchText },
		skip: debitorSearchText.length === 0,
		onCompleted(data) {
			data.debitors.forEach(d => {
				if (d.debitorId !== null) {
					debitorMapRef.current[d.debitorId] = d.company;
				}
			});
		},
	});

	const [projectManagerSearchText, setProjectManagerSearchText] = useDebouncedState('', 100);
	const projectManagerMapRef = React.useRef<Record<string, string>>({});
	const { loading: projectManagersLoading, data: projectManagersData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: projectManagerSearchText,
			onlyEmployees: true,
		},
		skip: projectManagerSearchText.length === 0,
		onCompleted(data) {
			data.searchUsers.forEach(pm => {
				projectManagerMapRef.current[pm.id] = pm.name;
			});
		},
	});

	const [caseManagerSearchText, setCaseManagerSearchText] = useDebouncedState('', 100);
	const caseManagerMapRef = React.useRef<Record<string, string>>({});
	const { loading: caseManagersLoading, data: caseManagersData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: { searchText: caseManagerSearchText, onlyEmployees: true },
		skip: caseManagerSearchText.length === 0,
		onCompleted(data) {
			data.searchUsers.forEach(pm => {
				caseManagerMapRef.current[pm.id] = pm.name;
			});
		},
	});

	const pmUsers = React.useMemo(() => {
		return projectManagersData?.searchUsers.map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [projectManagersData?.searchUsers]);

	const cmUsers = React.useMemo(() => {
		return caseManagersData?.searchUsers.map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [caseManagersData?.searchUsers]);

	const onFilterSave = async () => {
		try {
			await postUserFilter({
				filter: {
					name: filterSaveName,
					view: activeFilters.view,
					awaiting: activeFilters.awaiting,
					damageServiceCompleted: activeFilters.damageServiceCompleted,
					calledBack: activeFilters.calledBack,
					machinesOnCase: activeFilters.machinesOnCase,
					showEconomyField: activeFilters.showEconomyField,
					showBusinessAreaField: activeFilters.showBusinessAreaField,
					showAppliedClosedCases: activeFilters.showAppliedClosedCases,
					showClosedCases: activeFilters.showClosedCases,
					debitors: activeFilters.debitors ?? [],
					postalCodeText: activeFilters.postalCodeText ?? '',
					postalCodes: activeFilters.postalCodes ?? [],
					damageCategories: activeFilters.damageCategories ?? [],
					damageCauses: activeFilters.damageCauses ?? [],
					track: activeFilters.track ?? [],
					priority: activeFilters.priority ?? [],
					departments: activeFilters.departments ?? [],
					locations: activeFilters.locations ?? [],
					caseManagers: activeFilters.caseManagers ?? [],
					projectManagers: activeFilters.projectManagers ?? [],
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const resetFilters = () => {
		onFilter({
			name: null,
			address: null,
			view: UserCaseFilterViewType.OWN,
			awaiting: null,
			damageServiceCompleted: null,
			calledBack: null,
			machinesOnCase: null,
			showEconomyField: true,
			showBusinessAreaField: true,
			showCategoryCause: true,
			showAppliedClosedCases: false,
			showClosedCases: false,
			showPriorityCustomerCases: false,
			debitors: undefined,
			postalCodeText: '',
			postalCodes: undefined,
			damageCategories: undefined,
			damageCauses: undefined,
			track: undefined,
			priority: undefined,
			departments: undefined,
			locations: undefined,
			caseManagers: undefined,
			projectManagers: undefined,
			minDate: undefined,
			maxDate: undefined,
			filterTerms: '',
			favoriteColors: [],
		});
		setActiveUserFilter('');
	};

	const postUserFilter = async (filterValues: SetUserCaseFilterVariables): Promise<void> => {
		await createUserFilter({
			variables: {
				...filterValues,
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null || data.setUserCaseFilter === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetWebCurrentUser>({
					query: GET_CURRENT_USER,
				});

				if (cachedRequest === null || cachedRequest.currentUser?.caseFilters === null) {
					return;
				}

				cache.writeQuery({
					query: GET_CURRENT_USER,
					data: {
						user: {
							...cachedRequest.currentUser,
							caseFilters: [cachedRequest.currentUser?.caseFilters, data.setUserCaseFilter.caseFilters],
						},
					},
				});
			},
		});
		setActiveUserFilter(filterValues.filter.name);
		setSaveFilterActive(false);
	};

	const handleDeleteUserFilter = async (filterName: string) => {
		const filterKeyUppercase = filterName.toUpperCase();
		try {
			await deleteUserFilter({
				filterKey: filterKeyUppercase,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const deleteUserFilter = async ({ filterKey }: RemoveUserCaseFilterVariables): Promise<void> => {
		try {
			await removeUserFilter({
				variables: {
					filterKey,
				},
			});
			resetFilters();
		} catch (e) {
			console.log(e);
		}
	};

	const [departments, locations, damageCauses, damageCategories] = React.useMemo(() => {
		if (typeof adminData === 'undefined') {
			return [[], [], [], []];
		}

		const departments = adminData.departments
			.filter((d, i, a) => a.findIndex(ad => ad.id === d.id) === i)
			.map(d => ({
				value: d.id,
				label: `${d.name} (${d.departmentNumber})`,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		const locations = adminData.locations
			.filter((l, i, a) => a.findIndex(al => al.id === l.id) === i)
			.map(l => ({ value: l.id, label: l.name }))
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCauses = adminData.damageCauses
			.reduce(
				(allCauses, dc) => {
					let cause = allCauses.find(ac => ac.label === dc.name);
					if (typeof cause === 'undefined') {
						const index = allCauses.push({ label: dc.name, value: '' }) - 1;
						cause = allCauses[index];
					}

					cause.value += dc.id + ',';
					return allCauses;
				},
				[] as Array<{ label: string; value: string }>,
			)
			.sort((a, b) => a.label.localeCompare(b.label));

		const damageCategories = adminData.damageCategories.map(dc => ({ value: dc.id, label: dc.name })).sort((a, b) => a.label.localeCompare(b.label));
		return [departments, locations, damageCauses, damageCategories];
	}, [adminData]);

	const priorities = React.useMemo(() => {
		const priority: string[] = [];
		for (const p in CasePriority) {
			priority.push(p);
		}
		return priority.map(
			(p): SelectOption => ({
				value: p as CasePriority,
				label: t(`case.${p}`),
			}),
		);
	}, [t]);

	const tracks: SelectOption[] = [
		{
			label: `${t('case.track')} 0`,
			value: '0',
		},
		{
			label: `${t('case.track')} 1`,
			value: '1',
		},
		{
			label: `${t('case.track')} 2`,
			value: '2',
		},
		{
			label: `${t('case.track')} 3`,
			value: '3',
		},
		{
			label: `${t('case.track')} 4`,
			value: '4',
		},
		{
			label: `${t('case.track')} 5`,
			value: '5',
		},
		{
			label: `${t('case.track')} 6`,
			value: '6',
		},
		{
			label: `${t('case.track')} 7`,
			value: '7',
		},
		{
			label: `${t('case.track')} 8`,
			value: '8',
		},
		{
			label: `${t('case.track')} 9`,
			value: '9',
		},
		{
			label: `${t('case.track')} 10`,
			value: '10',
		},
	];

	const resolvePostalCodes = (postalCodes: string[]) => {
		const resolvedPostalCodesList: string[] = [];

		const postalCodesListDK = Object.keys(dk);
		const postalCodesListNO = Object.keys(no);
		const postalCodesListSE = Object.keys(se);
		const postalCodesListAsNumbersDK = postalCodesListDK.map(p => parseInt(p));
		const postalCodesListAsNumbersNO = postalCodesListNO.map(p => parseInt(p));
		const postalCodesListAsNumbersSE = postalCodesListSE.map(p => parseInt(p.replace(' ', '')));

		postalCodes?.forEach(p => {
			if (p.includes('-')) {
				const splitP = p.split('-');
				const postalCodesBetweenP = postalCodesListAsNumbersDK.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString());
				postalCodesBetweenP.concat(postalCodesListAsNumbersNO.filter(p => parseInt(splitP[0]) <= p && p <= parseInt(splitP[1])).map(p => p.toString()));
				postalCodesBetweenP.concat(
					postalCodesListAsNumbersSE
						.filter(p => parseInt(splitP[0].replace(' ', '')) <= p && p <= parseInt(splitP[1].replace(' ', '')))
						.map(p => {
							const pString = p.toString();
							return pString.substring(0, 3) + ' ' + pString.substring(3);
						}),
				);
				resolvedPostalCodesList.push(...postalCodesBetweenP);
			} else {
				resolvedPostalCodesList.push(p);
			}
		});

		const uniqueAndSortedPostalCodes = Array.from(new Set(resolvedPostalCodesList)).sort((a, b) =>
			a.localeCompare(b, undefined, {
				numeric: true,
				sensitivity: 'base',
			}),
		);

		return uniqueAndSortedPostalCodes;
	};

	return (
		<div className="my-2 mx-0">
			<div>
				<div className="mr-6 flex flex-col flex-wrap lg:flex-row">
					<div className="order-1 mb-3 mr-6">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.tableView')}</label>
						<ButtonGroup
							items={[
								{
									title: t('common.ownCases'),
									isActive: activeFilters.view === 'OWN',
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											view: UserCaseFilterViewType.OWN,
										})),
								},
								{
									title: t('caseOverview.filters.allCases'),
									isActive: activeFilters.view === 'ALL',
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											view: UserCaseFilterViewType.ALL,
										})),
									hide: arraysHasMatchingValue([Permissions.CASES_OWN_CASES_ONLY], userPermissions),
								},
								{
									title: t('caseOverview.filters.readyFor5C'),
									isActive: activeFilters.view === UserCaseFilterViewType.INVOICE_READY,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											view: UserCaseFilterViewType.INVOICE_READY,
										})),
								},
								{
									title: t('common.notPlanned'),
									isActive: activeFilters.view === UserCaseFilterViewType.NOT_PLANNED,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											view: UserCaseFilterViewType.NOT_PLANNED,
										})),
								},
								{
									title: t('caseOverview.filters.favorites'),
									isActive: activeFilters.view === UserCaseFilterViewType.FAVORITES,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											view: UserCaseFilterViewType.FAVORITES,
										})),
								},
							]}
						/>
					</div>

					<div className="order-3 mb-3 mr-3 lg:order-2">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
						<div className="flex justify-between">
							<div className="relative flex">
								<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
								<Input
									name="overview-search"
									placeholder={t('caseOverview.filters.searchPlaceholder')}
									className="pr-8 text-sm"
									style={{ minWidth: '220px' }}
									defaultValue={activeFilters.filterTerms}
									//value={activeFilters.filterTerms}
									onChange={(e): void => setFilterTerm(e.target.value)}
								/>
							</div>

							<Button
								primary
								text={!showAllFilters ? t('caseOverview.filters.showAllFilters') : t('common.hideFilters')}
								icon={faSlidersH}
								className="ml-3 inline-block px-5 py-2 text-xs lg:hidden xl:hidden"
								style={{ height: '36px' }}
								onClick={() => setShowAllFilters(!showAllFilters)}
							/>
						</div>
					</div>

					<div className="order-2 mb-3 mr-6 lg:order-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.quickFilter')}</label>
						<div className="flex">
							<Dropdown
								name="overview-quick-filters"
								onChange={e => {
									setActiveUserFilter(e.target.value);
									e.target.value !== ''
										? onFilter(currentFilters => ({
											...currentFilters,
											name: e.target.value,
										}))
										: resetFilters();
								}}
								data={[
									{
										value: '',
										label: t('caseOverview.filters.pickFilter'),
									},
									...(userContext.user?.caseFilters.map(f => ({
										value: f.name,
										label: f.name,
									})) ?? []),
								]}
								className="w-full pr-8 lg:w-1/2"
								style={{ minWidth: '220px', height: 34 }}
								defaultValue={activeUserFilter}
							/>

							<Button
								secondary={activeFilters.name !== '' || typeof activeFilters.name !== 'undefined'}
								disabled={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null}
								text={t('caseOverview.filters.deleteFilter')}
								onClick={() => setDeleteFilter(true)}
								className="ml-3 px-5 py-2 text-xs"
							/>

							<Button
								primary
								text={
									activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null
										? t('caseOverview.filters.saveFilter')
										: t('caseOverview.filters.updateFilter')
								}
								onClick={activeFilters.name === '' || typeof activeFilters.name == 'undefined' || activeFilters.name == null ? () => setSaveFilterActive(true) : () => onFilterSave()}
								className="ml-3 px-5 py-2 text-xs"
							/>

							<Button secondary text={t('common.resetFilter')} className="ml-3 px-5 py-2 text-xs" onClick={resetFilters} />
						</div>
					</div>
				</div>

				<div
					className={classnames('mr-6 flex w-full flex-row flex-wrap', {
						'hidden lg:flex': !showAllFilters,
					})}
				>
					<div className="mr-6 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.awaiting')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.awaiting === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											awaiting: activeFilters.awaiting !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.awaiting === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											awaiting: activeFilters.awaiting !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<div className="mr-6 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('case.calledBack')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.calledBack === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											calledBack: activeFilters.calledBack !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.calledBack === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											calledBack: activeFilters.calledBack !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<div className="mr-6 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.damageServiceCompleted')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.damageServiceCompleted === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											damageServiceCompleted: activeFilters.damageServiceCompleted !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.damageServiceCompleted === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											damageServiceCompleted: activeFilters.damageServiceCompleted !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					<div className="mr-6 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.machinesOnCase')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: t('common.yes'),
									isActive: activeFilters.machinesOnCase === true,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											machinesOnCase: activeFilters.machinesOnCase !== true ? true : null,
										})),
								},
								{
									title: t('common.no'),
									isActive: activeFilters.machinesOnCase === false,
									onClick: (): void =>
										onFilter(currentFilters => ({
											...currentFilters,
											machinesOnCase: activeFilters.machinesOnCase !== false ? false : null,
										})),
								},
							]}
						/>
					</div>

					{/* <div className="mr-6 mb-3">
                        <label className="block text-blue font-medium text-xs mb-1">Ankommet (mangler)</label>
                        <ButtonGroup
                            items={[
                                { title: 'Ja', isActive: activeFilters.calledBack === true, onClick: (): void => onFilter(activeFilters => ({...activeFilters, calledBack: activeFilters.calledBack !== true ? true : null})) },
                                { title: 'Nej', isActive: activeFilters.calledBack === false, onClick: (): void => onFilter(activeFilters => ({...activeFilters, calledBack: activeFilters.calledBack !== true ? true : null})) },
                            ]}
                        />
                    </div> */}

					{/* <div className="mr-6 mb-3">
                        <label className="block text-blue font-medium text-xs mb-1">Mangler afslutning (mangler)</label>
                        <ButtonGroup
                            items={[
                                { title: 'Ja', isActive: activeFilters.machinesOnCase === true, onClick: (): void => onFilter(activeFilters => ({...activeFilters, machinesOnCase: activeFilters.machinesOnCase !== true ? true : null})) },
                                { title: 'Nej', isActive: activeFilters.machinesOnCase === false, onClick: (): void => onFilter(activeFilters => ({...activeFilters, machinesOnCase: activeFilters.machinesOnCase !== true ? true : null})) },
                            ]}
                        />
                    </div> */}

					<div className="flex flex-row">
						<div className="mr-6 mb-3 whitespace-nowrap text-sm">
							<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.showAppliedClosedCases')}</label>
							<Toggle
								name="show-closed"
								text=""
								onClick={() =>
									onFilter(currentFilters => ({
										...currentFilters,
										showAppliedClosedCases: !activeFilters.showAppliedClosedCases,
									}))
								}
								defaultChecked={activeFilters.showAppliedClosedCases}
							/>
						</div>
						<div className="mr-6 mb-3 whitespace-nowrap text-sm">
							<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.showBlockedCases')}</label>
							<Toggle
								name="show-blocked"
								text=""
								onClick={() =>
									onFilter(currentFilters => ({
										...currentFilters,
										showClosedCases: !activeFilters.showClosedCases,
									}))
								}
								defaultChecked={activeFilters.showClosedCases}
							/>
						</div>
						{(priorityCustomer && !userContext.user?.external) &&
							<div className="mr-6 mb-3 whitespace-nowrap text-sm">
								<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.priorityCustomer')}</label>
								<Toggle
									name="priority-customer"
									text=""
									onClick={() =>
										onFilter(currentFilters => ({
											...currentFilters,
											showPriorityCustomerCases: !activeFilters.showPriorityCustomerCases,
										}))
									}
									defaultChecked={activeFilters.showPriorityCustomerCases}
								/>
							</div>
						}
						<div className="mr-6 mb-3 whitespace-nowrap text-sm">
							<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.showCategoryCause')}</label>
							<Toggle
								name="show-category-cause"
								text=""
								onClick={() =>
									onFilter(currentFilters => ({
										...currentFilters,
										showCategoryCause: !activeFilters.showCategoryCause,
									}))
								}
								defaultChecked={activeFilters.showCategoryCause}
							/>
						</div>
						{arraysHasMatchingValue([Permissions.CASES_ECONOMY_VIEW], userPermissions) && (
							<div className="mr-6 mb-3 whitespace-nowrap text-sm">
								<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.showEconomyField')}</label>
								<Toggle
									name="show-economy"
									text=""
									onClick={() =>
										onFilter(currentFilters => ({
											...currentFilters,
											showEconomyField: !activeFilters.showEconomyField,
										}))
									}
									defaultChecked={activeFilters.showEconomyField}
								/>
							</div>
						)}
						<div className="mr-6 mb-3 whitespace-nowrap text-sm">
							<label className="text-blue mb-1 block text-xs font-medium">{t('caseOverview.filters.showBusinessAreaField')}</label>
							<Toggle
								name="show-business-area"
								text=""
								onClick={() =>
									onFilter(currentFilters => ({
										...currentFilters,
										showBusinessAreaField: !activeFilters.showBusinessAreaField,
									}))
								}
								defaultChecked={activeFilters.showBusinessAreaField}
							/>
						</div>
					</div>
				</div>
			</div>

			<div
				className={classnames('flex w-full flex-row flex-wrap', {
					'hidden lg:flex': !showAllFilters,
				})}
			>
				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('common.department')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.departments?.map(v => {
								const d = adminData?.departments?.find(department => department.id === v);
								return {
									value: d?.id ?? '',
									label: typeof d !== 'undefined' ? `${d.name} (${d.departmentNumber})` : '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values => {
							onFilter(currentFilters => ({
								...currentFilters,
								departments: values?.map(v => v.value) ?? [],
							}));
						}}
						options={departments}
					/>
				</label>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('common.postalcode')}
					<input
						type="text"
						name="postalFilter"
						className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
						style={{ width: '150px' }}
						value={activeFilters.postalCodeText}
						onChange={values => {
							onFilter(currentFilters => ({
								...currentFilters,
								postalCodeText: values.target.value,
								postalCodes: values.target.value !== '' ? resolvePostalCodes(values.target.value.trim().split(',')) ?? [] : [],
							}));
						}}
					/>
				</label>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('common.damageCategory')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.damageCategories?.map(v => {
								const d = adminData?.damageCategories?.find(category => category.id === v);
								return {
									value: d?.id ?? '',
									label: d?.name ?? '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values =>
							onFilter(currentFilters => ({
								...currentFilters,
								damageCategories: values?.map(v => v.value) ?? [],
							}))
						}
						options={damageCategories}
					/>
				</label>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('case.damageCause')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.damageCauses
								?.map(v => {
									const l = damageCauses.find(dc => dc.value.includes(v));
									return {
										value: l?.value ?? '',
										label: l?.label ?? '',
									};
								})
								.filter((v, i, a) => a.findIndex(av => av.value === v.value) === i) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values =>
							onFilter(currentFilters => ({
								...currentFilters,
								damageCauses: values?.flatMap(v => v.value.split(',').filter(v => v !== '')) ?? [],
							}))
						}
						options={damageCauses}
					/>
				</label>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('case.track')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.track?.map(v => {
								return {
									value: v.toString() ?? '',
									label: `${t('case.track')} ${v}` ?? '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values => {
							onFilter(currentFilters => ({
								...currentFilters,
								track: values?.map(v => parseInt(v.value)) ?? [],
							}));
						}}
						options={tracks}
					/>
				</label>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('common.priority')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.priority?.map(v => {
								return {
									value: v ?? '',
									label: t(`case.${v}`) ?? '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values =>
							onFilter(currentFilters => ({
								...currentFilters,
								priority: values?.map(v => v.value as CasePriority) ?? [],
							}))
						}
						options={priorities}
					/>
				</label>

				<div>
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">
						{t('common.debitor')}
						<SearchableSelect
							key="debitor-filter"
							control={dummyForm.control}
							name="debitorFilter"
							options={(debitorsData?.debitors ?? [])
								.filter(d => d.debitorId !== null)
								.filter((d, i, a) => a.indexOf(d) === i)
								.map(d => ({
									value: d.debitorId ?? '?',
									label: d.company,
								}))}
							searchFn={searchText => setDebitorSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									onFilter(currentFilters => ({
										...currentFilters,
										debitors: [...(activeFilters.debitors ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={2}
							isLoading={debitorsLoading}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.debitors?.map(d => (
							<li key={d}>
								<Pill
									onClick={() =>
										onFilter(() => ({
											...activeFilters,
											debitors: activeFilters.debitors?.filter(ed => ed !== d),
										}))
									}
								>
									{debitorMapRef.current[d]} ({d})
								</Pill>
							</li>
						))}
					</ul>
				</div>

				<label className="text-blue mb-1 mr-3 block text-xs font-medium">
					{t('common.location')}
					<Select
						placeholder=""
						isClearable
						isMulti
						closeMenuOnSelect={false}
						menuPortalTarget={document.body}
						className="react-select-custom w-40 text-xs"
						value={
							activeFilters.locations?.map(v => {
								const l = adminData?.locations?.find(location => location.id === v);
								return {
									value: l?.id ?? '',
									label: l?.name ?? '',
								};
							}) ?? []
						}
						styles={{
							menuPortal: base => ({ ...base, zIndex: 6667 }),
						}}
						onChange={values =>
							onFilter(currentFilters => ({
								...currentFilters,
								locations: values?.map(v => v.value) ?? [],
							}))
						}
						options={locations}
					/>
				</label>

				<div>
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">
						{t('case.ssgProjectManager')}
						<SearchableSelect
							key="project-Manager-filter"
							control={dummyForm.control}
							name="projectManagerFilter"
							options={pmUsers ?? []}
							searchFn={searchText => setProjectManagerSearchText(searchText)}
							onSelect={value => {
								if (value !== '') {
									onFilter(currentFilters => ({
										...currentFilters,
										projectManagers: [...(activeFilters.projectManagers ?? []), value],
									}));
								}
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={2}
							isLoading={projectManagersLoading}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.projectManagers?.map(pm => (
							<li key={pm}>
								<Pill
									onClick={() =>
										onFilter(currentFilters => ({
											...currentFilters,
											projectManagers: activeFilters.projectManagers?.filter(epm => epm !== pm),
										}))
									}
								>
									{projectManagerMapRef.current[pm]}
								</Pill>
							</li>
						))}
					</ul>
				</div>

				<div>
					<label className="text-blue mb-1 mr-3 block text-xs font-medium">
						{t('case.ssgCaseManager')}
						<SearchableSelect
							key="case-Manager-filter"
							control={dummyForm.control}
							name="caseManagerFilter"
							options={cmUsers ?? []}
							searchFn={searchText => setCaseManagerSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									onFilter(currentFilters => ({
										...currentFilters,
										caseManagers: [...(activeFilters.caseManagers ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={2}
							isLoading={caseManagersLoading}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.caseManagers?.map(pm => (
							<li key={pm}>
								<Pill
									onClick={() =>
										onFilter(currentFilters => ({
											...currentFilters,
											caseManagers: activeFilters.caseManagers?.filter(epm => epm !== pm),
										}))
									}
								>
									{caseManagerMapRef.current[pm]}
								</Pill>
							</li>
						))}
					</ul>
				</div>

				<label className="text-blue mb-1 mr-3 text-xs font-medium">
					{t('common.created')}
					<div className="flex items-center">
						<input
							type="date"
							value={formatDateForInput(new Date(activeFilters.minDate ?? ''))}
							onChange={e => {
								const minDate = e.target.valueAsDate;
								if (minDate === null || typeof minDate === 'undefined') {
									return;
								}
								minDate.setHours(0, 0, 0, 0);

								onFilter(currentFilters => ({
									...currentFilters,
									minDate,
								}));

								if (typeof activeFilters.maxDate === 'undefined') return;

								if (minDate > activeFilters.maxDate) {
									const maxDate = new Date(minDate);
									maxDate.setHours(23, 59, 59, 999);
									onFilter(currentFilters => ({
										...currentFilters,
										maxDate,
									}));
								}
							}}
							className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
							style={{
								height: '34px',
								maxWidth: '144px',
								minWidth: '100px',
							}}
						/>
						<span className="px-1">&mdash;</span>
						<input
							type="date"
							min={formatDateForInput(new Date(activeFilters.minDate ?? ''))}
							value={formatDateForInput(new Date(activeFilters.maxDate ?? ''))}
							onChange={e => {
								const maxDate = e.target.valueAsDate;
								if (maxDate === null) {
									return;
								}
								maxDate.setHours(23, 59, 59, 999);

								onFilter(currentFilters => ({
									...currentFilters,
									maxDate,
								}));
							}}
							className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
							style={{
								height: '34px',
								maxWidth: '144px',
								minWidth: '100px',
							}}
						/>
						{(typeof activeFilters.minDate !== 'undefined' || typeof activeFilters.maxDate !== 'undefined') && (
							<FontAwesomeIcon
								icon={faTimes}
								size="1x"
								className="ml-2 cursor-pointer"
								onClick={() =>
									onFilter(currentFilters => ({
										...currentFilters,
										minDate: undefined,
										maxDate: undefined,
									}))
								}
							/>
						)}
					</div>
				</label>
			</div>

			{(managerFavoritesFlag && activeFilters.view === UserCaseFilterViewType.FAVORITES) &&
				<div>
					<label className="text-blue mb-1 mr-3 text-xs font-medium">{t('caseOverview.filters.markings')}</label>
					<div>
						{favoriteColors.map(color =>
							<FontAwesomeIcon
								key={color.value}
								icon={selectedStars?.includes(color.value) ? starFilled : faStar}
								onClick={e => {
									setSelectedStars(prevStars => {
										if (prevStars && prevStars.includes(color.value)) {
											onFilter(currentFilters => ({
												...currentFilters,
												favoriteColors: prevStars.filter(star => star !== color.value),
											}));
											return prevStars.filter(star => star !== color.value);

										} else {
											onFilter(currentFilters => ({
												...currentFilters,
												favoriteColors: prevStars ? [...prevStars, color.value] : [color.value],
											}));
											return prevStars ? [...prevStars, color.value] : [color.value];
										}
									});
								}}
								style={{ color: color.value !== '' ? color.value : 'inherit' }}
								className="hover-scale cursor-pointer text-lg mr-2"
							/>,
						)}
					</div>
				</div>
			}

			<Modal
				visible={saveFilterActive}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.saveFilter')}
				body={
					<>
						<Input name="filter-title" placeholder={t('caseOverview.filters.nameFilter')} onChange={e => setFilterSaveName(e.target.value)} />
						<Button
							primary
							text={t('caseOverview.filters.saveFilter')}
							onClick={() => {
								setSaveFilterActive(false);
								onFilterSave();
							}}
							disabled={filterSaveName === ''}
							className="mt-4"
						/>
					</>
				}
				close={() => setSaveFilterActive(false)}
			/>

			<Modal
				visible={deleteFilter}
				size={ModalSize.SMALL}
				title={t('caseOverview.filters.deleteFilter')}
				body={
					<>
						<p>{t('common.doYouWishToDeleteFilter')}</p>

						<p className="font-medium">{activeFilters.name}</p>

						<div className="flex justify-between">
							<Button
								danger
								text={t('common.delete')}
								onClick={() => {
									handleDeleteUserFilter(activeFilters?.name ?? '');
									setDeleteFilter(false);
								}}
								className="mt-4"
							/>
							<Button secondary text={t('common.cancel')} onClick={() => setDeleteFilter(false)} className="mt-4" />
						</div>
					</>
				}
				close={() => setDeleteFilter(false)}
			/>
		</div>
	);
};

export default CaseOverviewFilter;
