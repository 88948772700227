import React from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    data: number;
    loading: boolean;
    subTitle?: string;
    subData?: number;
	className?: string;
    small?: boolean;
    icon?: IconDefinition;
    iconSize?: FontAwesomeIconProps['size'];
    unit?: string;
}

const SummaryCard: React.FC<Props> = ({ title, data, subTitle, subData, loading, icon, iconSize, small = false, unit }) => {
    const { t } = useTranslation();

    if (small) {
        if(data === 0) return null;

        return (
            <div className="bg-white rounded-lg p-4 m-2 min-w-40">
                <div className="flex items-center">
                    {loading ? (
                        <div className="animate-pulse">
                            <div className="bg-gray-200 rounded h-10 w-16 mb-2"></div>
                            <div className="bg-gray-200 rounded h-5 w-16"></div>
                        </div>
                    ) : (
                        <div>
                            <div className="text-xl leading-5">{data} <span className="text-sm">{unit}</span></div>
                            <div className="text-xs">{t(title)}</div>
                        </div>
                    )}
                    {icon && (
                        <div>
                            <FontAwesomeIcon icon={icon} size={iconSize} className="ml-6" />
                        </div>
                    )}
                </div>
                {typeof subData !== 'undefined' && subData > 0 && (
                    <div className="flex items-center mt-2">
                        <div className="text-1xl leading-5 mr-2">{subData} {unit}</div>
                        <div className="text-xs">{t(subTitle ?? '')}</div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg p-4">
            <div className="flex items-center min-w-40 justify-between">
                {loading ? (
                    <div className="animate-pulse">
                        <div className="bg-gray-200 rounded h-10 w-16 mb-2"></div>
                        <div className="bg-gray-200 rounded h-5 w-16"></div>
                    </div>
                ) : (
                    <div>
                        <div className="text-3xl leading-8">{data}</div>
                        <div className="text-sm">{t(title)}</div>
                    </div>
                )}
                {icon && (
                    <div>
                        <FontAwesomeIcon icon={icon} size={iconSize} className="ml-6" />
                    </div>
                )}
            </div>
            {typeof subData !== 'undefined' && subData > 0 && (
                <div className="flex items-center mt-2">
                    <div className="text-1xl leading-5 mr-2">{subData}</div>
                    <div className="text-xs">{t(subTitle ?? '')}</div>
                </div>
            )}
        </div>
    );
};
export default SummaryCard;
