import React, { useContext } from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { GetWebCase_case } from '../../GraphQL';
import Input from '@ssg/common/Components/Input';
import Box from '../../Components/Layout/Box';
import InputPhone from '@ssg/common/Components/InputPhone';
import classNames from 'classnames';
import Checkbox from '@ssg/common/Components/Checkbox';
import TextButton from '@ssg/common/Components/TextButton';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';

interface Props {
	caseData: GetWebCase_case | undefined;
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	trigger: UseFormMethods['trigger'];
	setValue: UseFormMethods['setValue'];
}

const ReferrerBox: React.FC<Props> = ({ caseData, register, errors, control, trigger, setValue }): React.ReactElement => {
	const envVar = useContext(EnvironmentVariableContext);

	const [showReferrer, setShowReferrer] = React.useState(typeof caseData !== 'undefined' && caseData.referrer !== null);
	const [showHelpText, setShowHelpText] = React.useState(false);
	const [country, setCountry] = React.useState(envVar.defaultCountry ?? '');
	const [companyCountry, setCompanyCountry] = React.useState(envVar.defaultCountry ?? '');

	const phonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('ReferrerBox.phone', phonenumber, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	const companyPhonenumberHandler = React.useCallback(
		(phonenumber: string): void => {
			phonenumber = formatPhoneNumberIntl(phonenumber).replaceAll(/(\s|-)+/g, '');
			setValue('ReferrerBox.companyPhone', phonenumber, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	React.useEffect(() => {
		phonenumberHandler(caseData?.referrer?.phone ?? '');
	}, [caseData?.referrer, phonenumberHandler]);

	React.useEffect(() => {
		companyPhonenumberHandler(caseData?.referrer?.companyPhone ?? '');
	}, [caseData?.referrer, companyPhonenumberHandler]);

	const noEmail = useWatch({
		control,
		name: 'ReferrerBox.noEmail',
		defaultValue: typeof caseData !== 'undefined' && caseData.referrer?.email === '',
	});

	React.useEffect(() => {
		noEmail && setValue('ReferrerBox.email', '', { shouldValidate: true });
	}, [noEmail, setValue]);

	return (
		<Box form title="case.referrer" onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText} noPadding>
			<hr />
			<div className="w-full lg:w-3/4">
				<TextButton text={!showReferrer ? 'case.showReferrer' : 'case.hideReferrer'} icon={!showReferrer ? faChevronDown : faChevronUp} onClick={() => setShowReferrer(!showReferrer)} />

				<div
					className={classNames({
						hidden: !showReferrer,
					})}
				>
					<Input
						title="common.name"
						name="ReferrerBox.name"
						innerRef={register}
						helpText="case.helpText.referrerName"
						showHelpText={showHelpText}
						errorMessage={errors.ReferrerBox?.name?.message ?? ''}
						defaultValue={caseData?.referrer?.name ?? ''}
					/>

					<InputPhone
						title="common.phone"
						innerRef={register}
						name="ReferrerBox.phone"
						errorMessage={errors.ReferrerBox?.phone?.message ?? ''}
						helpText="case.helpText.referrerPhone"
						showHelpText={showHelpText}
						control={control}
						trigger={trigger}
						searchFn={(phonenumber): void => phonenumberHandler(phonenumber)}
						onCountryChange={(countrycode): void => setCountry(countrycode ?? '')}
						defaultCountry={country}
						defaultPhoneNumber={formatPhoneNumberIntl(caseData?.referrer?.phone ?? '').replaceAll(/(\s|-)+/g, '')}
					/>

					<Input
						title="common.email"
						name="ReferrerBox.email"
						innerRef={register}
						helpText="case.helpText.referrerEmail"
						disabled={noEmail}
						showHelpText={showHelpText}
						errorMessage={errors.ReferrerBox?.email?.message ?? ''}
						defaultValue={caseData?.referrer?.email}
					/>

					<Checkbox
						title="case.noEmail"
						name="ReferrerBox.noEmail"
						className="mt-1"
						innerRef={register}
						onChange={() => {
							trigger('ReferrerBox.email');
						}}
						defaultChecked={typeof caseData !== 'undefined' && caseData.referrer?.email === ''}
					/>

					<Input
						title="common.companyName"
						name="ReferrerBox.companyName"
						innerRef={register}
						helpText="case.helpText.referrerCompanyName"
						showHelpText={showHelpText}
						errorMessage={errors.ReferrerBox?.companyName?.message ?? ''}
						defaultValue={caseData?.referrer?.companyName ?? ''}
					/>

					<InputPhone
						title="common.companyPhone"
						innerRef={register}
						name="ReferrerBox.companyPhone"
						errorMessage={errors.ReferrerBox?.companyPhone?.message ?? ''}
						helpText="case.helpText.referrerCompanyPhone"
						showHelpText={showHelpText}
						control={control}
						trigger={trigger}
						searchFn={(phonenumber): void => companyPhonenumberHandler(phonenumber)}
						onCountryChange={(countrycode): void => setCompanyCountry(countrycode ?? '')}
						defaultCountry={companyCountry}
						defaultPhoneNumber={formatPhoneNumberIntl(caseData?.referrer?.companyPhone ?? '').replaceAll(/(\s|-)+/g, '')}
					/>
				</div>
			</div>
		</Box>
	);
};

export default ReferrerBox;
