import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	DeleteCatalogFile,
	DeleteCatalogFileVariables,
	FileActionType,
	GetCatalogESDHFiles,
	GetCatalogESDHFilesVariables,
	LogCatalogFileChange,
	LogCatalogFileChangeVariables,
} from '../../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';

const LOG_FILE_CHANGE = loader('src/GraphQL/Files/LogCatalogFileChange.gql');
const DELETE_FILE = loader('src/GraphQL/Files/DeleteCatalogFile.gql');
const GET_CATALOG_ESDH_FILES = loader('src/GraphQL/Files/GetCatalogESDHFiles.gql');

interface Props {
	filenameToDelete: string;
	catalogId: string;
	customerId: string;
	changedBy: string;
	visible: boolean;
	close(): void;
}

const DeleteCatalogFileModal: React.FC<Props> = ({ filenameToDelete, catalogId, customerId, changedBy, visible, close }): React.ReactElement => {
	const { t } = useTranslation();

	const [logFileChange] = useMutation<LogCatalogFileChange, LogCatalogFileChangeVariables>(LOG_FILE_CHANGE);
	const [deleteCatalogFile, { loading: loadingDeleteFile }] = useMutation<DeleteCatalogFile, DeleteCatalogFileVariables>(DELETE_FILE);

	const deleteFile = async (fileName: string) => {
		try {
			await deleteCatalogFile({
				variables: {
					fileName: fileName,
					folder: catalogId,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalogESDHFiles, GetCatalogESDHFilesVariables>({
						query: GET_CATALOG_ESDH_FILES,
						variables: {
							id: catalogId,
						},
					});

					if (cachedRequest === null || cachedRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalogESDHFiles, GetCatalogESDHFilesVariables>({
						query: GET_CATALOG_ESDH_FILES,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedRequest.catalog,
								documents: cachedRequest.catalog.documents.filter(doc => doc.name !== fileName),
							},
						},
					});
				},
			});

			await logFileChange({
				variables: {
					filename: fileName,
					catalogId: catalogId,
					customerId: customerId,
					globalFile: false,
					action: FileActionType.REMOVE,
					changedBy: changedBy,
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="catalog.file.delete"
			size={ModalSize.SMALL}
			close={() => close()}
			visible={visible}
			body={
				<div>
					<div className="flex flex-col">
						<p>
							{t('catalog.file.deleteConfirm')} {filenameToDelete}?
						</p>

						<Button danger text="catalog.file.delete" onClick={() => deleteFile(filenameToDelete)} className="mt-4" loading={loadingDeleteFile} />
					</div>
				</div>
			}
		/>
	);
};

export default DeleteCatalogFileModal;
