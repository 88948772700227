import Dropdown from '@ssg/common/Components/Dropdown';
import { InvoiceCategory } from '@ssg/common/GraphQL';
import enumToSelectOptions from '@ssg/common/Helpers/enumToSelectOptions';
import React from 'react';

interface Props {
	isTrackOne: boolean;
	setType: (type: InvoiceCategory) => unknown;
}

const trackOneOptions = enumToSelectOptions(
	{
		[InvoiceCategory.TRACK_ONE]: InvoiceCategory.TRACK_ONE,
	},
	'case.invoice.categories.',
);

const regularOptions = enumToSelectOptions(
	{
		[InvoiceCategory.FINAL]: InvoiceCategory.FINAL,
		[InvoiceCategory.SUB]: InvoiceCategory.SUB,
		[InvoiceCategory.ACCOUNT]: InvoiceCategory.ACCOUNT,
		[InvoiceCategory.SCREENING]: InvoiceCategory.SCREENING,
	},
	'case.invoice.categories.',
);

const InvoiceStepCreate: React.FC<Props> = ({ isTrackOne, setType }) => {
	React.useEffect(() => {
		setType(isTrackOne ? InvoiceCategory.TRACK_ONE : InvoiceCategory.FINAL);
	}, [isTrackOne, setType]);

	return (
		<Dropdown
			name="type"
			title="case.invoice.category"
			data={isTrackOne ? trackOneOptions : regularOptions}
			defaultValue={isTrackOne ? InvoiceCategory.TRACK_ONE : InvoiceCategory.FINAL}
			onChange={e => setType(e.target.value as InvoiceCategory)}
		/>
	);
};
export default InvoiceStepCreate;
