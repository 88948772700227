import React from 'react';
import { useTranslation } from 'react-i18next';
import { faFileUpload, faCommentAltEdit, faCommentAltPlus } from '@fortawesome/pro-regular-svg-icons';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';
import Button from '@ssg/common/Components/Button';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classnames from 'classnames';
import useCaseImages from '../useCaseImages';
import UploadImageForm from './UploadImageForm';
import Textarea from '@ssg/common/Components/Textarea';
import Loading from '@ssg/common/Components/Loading';
import { EsdhFileFragmentFragment } from '@ssg/common/GraphQL/indexV2';
//import { ReportFileInput } from '../../../../GraphQL';

interface Props {
	caseNo: string;
	headerTitle: string;
	title: string;
	onImageSelect(file: EsdhFileFragmentFragment): void;
	onImageRemove?(): unknown;
	//selectedImages: ESDHFileFragment[];
	noSelectedImageText: string;
	selectedImagesNames: string[];
}

const CaseImageInput: React.FC<Props> = ({ caseNo, title, headerTitle, onImageSelect, noSelectedImageText, onImageRemove, selectedImagesNames }) => {
	const { t } = useTranslation();
	const [showSelectModal, setShowSelectModal] = React.useState(false);
	const [showUploadModal, setShowUploadModal] = React.useState(false);
	const [showCommentModal, setShowCommentModal] = React.useState(false);

	const [selectedImage, setSelectedImage] = React.useState<EsdhFileFragmentFragment>();
	const [comment, setComment] = React.useState(selectedImage?.comments ?? '');
	//const selectedImagesNames = React.useMemo(() => selectedImages.map(s => s.name),[selectedImages]);

	const onImageUpload = React.useCallback((files: EsdhFileFragmentFragment[]) => files.forEach(f => onImageSelect(f)), [onImageSelect]);
	const { images, changeComment, loadingImages } = useCaseImages(caseNo, onImageUpload);

	const imageArr: EsdhFileFragmentFragment[] = React.useMemo(() => {
		const imageArr: EsdhFileFragmentFragment[] = [];
		selectedImagesNames.forEach(name => {
			const image = images.find(img => img.name === name);
			if (typeof image !== 'undefined') {
				imageArr.push(image);
			}
		});
		return imageArr;
	}, [images, selectedImagesNames]);

	if (loadingImages) return <Loading />;
	return (
		<div className="py-2">
			<h2 className="text-blue text-lg font-bold">{t(headerTitle)}</h2>
			<div className="flex flex-wrap">
				{selectedImagesNames.length === 0 && <p>{t(noSelectedImageText)}</p>}
				{imageArr.map(image => (
					<div className="flex w-1/2 items-center" key={image.url}>
						<img className="h-68 w-68 mr-12 inline-block object-cover p-2" alt={image.name} key={image.url} src={image.thumbnail} />

						{image.comments ? (
							<div className="m-2 flex h-12 items-center rounded-md p-2">
								<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
									<TextButton
										iconClassName="text-2xl"
										icon={faCommentAltEdit}
										className="text-blue tex cursor-pointer"
										onClick={() => {
											setSelectedImage(image);
											setShowCommentModal(true);
										}}
									/>
								</div>

								<p className="text-blue font-bold">{image.comments}</p>
							</div>
						) : (
							<div className="m-2 flex h-12 items-center rounded-md p-2">
								<div className="bg-gray mr-4 flex items-center justify-center rounded-full">
									<TextButton
										iconClassName="text-2xl"
										icon={faCommentAltPlus}
										className="text-blue tex cursor-pointer"
										onClick={() => {
											setSelectedImage(image);
											setShowCommentModal(true);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				))}
			</div>

			{typeof onImageRemove !== 'undefined' && selectedImagesNames.length > 0 && <Button secondary text="common.remove" className="ml-4 inline-flex" onClick={() => onImageRemove()} />}

			<Modal
				size={ModalSize.LARGE}
				visible={showSelectModal}
				title={title}
				close={() => setShowSelectModal(false)}
				body={
					<div>
						{images.length === 0 && <p>{t('reports.noImagesOnCase')}</p>}
						{images.map(image => (
							<div key={image.url} className="inline-block w-1/3 cursor-pointer p-2" onClick={() => onImageSelect(image)}>
								<div
									className={classnames('flex flex-col items-center border-2 border-transparent', {
										'border-red-calm': selectedImagesNames.some(name => name === image.name),
									})}
								>
									<img src={image.thumbnail} className="h-72 w-72 object-cover" alt={image.name} />
									<p className="text-xs">{dateToDateTimeString(image.created)}</p>
									<p>{image.comments}</p>
								</div>
							</div>
						))}
					</div>
				}
				footer={<Button text="reports.saveImageSelection" primary onClick={() => setShowSelectModal(false)} />}
			/>

			<Modal
				size={ModalSize.LARGE}
				visible={showUploadModal}
				title={title}
				close={() => setShowUploadModal(false)}
				body={<UploadImageForm title="" caseNo={caseNo} onUploadComplete={onImageUpload} closeModal={() => setShowUploadModal(false)} />}
			/>

			<Modal
				size={ModalSize.LARGE}
				visible={showCommentModal}
				title="common.comment"
				close={() => setShowCommentModal(false)}
				body={
					<div>
						{typeof selectedImage !== 'undefined' ? (
							<Textarea name="comment" title="" defaultValue={selectedImage.comments ?? ''} onChange={e => setComment(e.target.value)} />
						) : (
							<div className="relative">
								<Loading />
							</div>
						)}
						<div className="mt-3 flex">
							<Button
								success
								text="common.confirm"
								className="mr-3"
								onClick={async () => {
									if (typeof selectedImage !== 'undefined') {
										await changeComment(selectedImage.fileId, comment);
										setShowCommentModal(false);
									}
								}}
							/>
							<Button danger text="common.cancel" onClick={() => setShowCommentModal(false)} />
						</div>
					</div>
				}
			/>

			<div className="flex space-x-2 pt-2">
				<Button primary text={`${t(title)} (${images.length})`} onClick={() => setShowSelectModal(true)} />
				<TextButton text="reports.uploadImage" icon={faFileUpload} onClick={() => setShowUploadModal(true)} />
			</div>
		</div>
	);
};

export default CaseImageInput;
