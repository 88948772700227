import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { JobType, GetJobs, GetJobsVariables } from '../../GraphQL';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import Fuse from 'fuse.js';
import MovableJobFilter from './MovableJobFilter';
import JobsTable from './JobsTable';
import BoxContainer from '@ssg/common/Components/BoxContainer';

const GET_JOBS = loader('src/GraphQL/Jobs/GetJobs.gql');

export interface JobFilters {
	name?: string | null;
	location: string;
	personalOnly: boolean;
}

const MovableJobs: React.FC = () => {
	const userContext = React.useContext(UserContext);

	const [searchTerm, setSearchTerm] = useDebouncedState('', 100);

	// Filters
	const [activeUserFilter, setActiveUserFilter] = useStorageState<string>(window.sessionStorage, 'activeUserFilter', '');

	const selectedUserFilter = React.useMemo(() => userContext?.user?.jobFilters.find(f => f.name === activeUserFilter), [activeUserFilter, userContext?.user?.jobFilters]);

	const [activeJobFilters, setActiveJobFilters] = useStorageState<JobFilters>(window.sessionStorage, 'activeJobFilters', {
		name: null,
		location: '',
		personalOnly: false,
	});

	React.useEffect(() => {
		if (typeof selectedUserFilter === 'undefined') {
			return;
		}

		setActiveJobFilters(currentFilters => ({
			...currentFilters,
			name: selectedUserFilter.name,
			location: selectedUserFilter.location,
			personalOnly: selectedUserFilter.personalOnly ?? false,
		}));
	}, [selectedUserFilter, setActiveJobFilters]);

	const { data, loading } = useQuery<GetJobs, GetJobsVariables>(GET_JOBS, {
		variables: {
			type: JobType.MOVABLE,
			completed: false,
			personalOnly: activeJobFilters.personalOnly,
			location: activeJobFilters.location,
		},
	});

	// filtered jobs
	const filteredJobs = React.useMemo(() => {
		let jobs = data?.jobs ?? [];
		const fuse = new Fuse(jobs, {
			shouldSort: true,
			threshold: 0.1,
			keys: ['description', 'location.name', 'assignedTo.name', 'assignedTo.email'],
		});

		if (searchTerm.length > 0) {
			jobs = fuse.search(searchTerm).map(v => v.item);
		}

		return jobs;
	}, [data?.jobs, searchTerm]);

	return (
		<BoxContainer>
			<Box full loading={loading}>
				<MovableJobFilter
					filters={activeJobFilters}
					setFilters={setActiveJobFilters}
					setFilterTerm={setSearchTerm}
					setActiveUserFilter={setActiveUserFilter}
					activeUserFilter={activeUserFilter}
					jobs={filteredJobs}
				/>

				<JobsTable
					jobs={filteredJobs}
					queryProps={{
						type: JobType.MOVABLE,
						completed: false,
						personalOnly: activeJobFilters.personalOnly,
						location: activeJobFilters.location,
					}}
					loading={loading}
					noDataText={'movable.noMovableJobsFound'}
				/>
			</Box>
		</BoxContainer>
	);
};

export default MovableJobs;
