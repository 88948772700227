import { useMutation } from '@apollo/client';
import Button from '@ssg/common/Components/Button';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DeleteCatalogContact,
	DeleteCatalogContactVariables,
	FileActionType,
	GetCatalog,
	GetCatalogContacts,
	GetCatalogContactsVariables,
	GetWebCatalogCustomers_catalogCustomers,
	GetCatalogVariables,
	GetCatalog_catalog_contacts,
	UpdateCatalogContact,
	UpdateCatalogContactVariables,
} from '../../../GraphQL';
import { removeTypename } from '../../../helper';

interface Props {
	contactData: GetCatalog_catalog_contacts;
	close(): void;
	visible: boolean;
	catalogId?: string;
	changedBy: string;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
	isExternalUser: boolean;
}

const UPDATE_CATALOG_CONTACT = loader('src/GraphQL/CatalogContacts/UpdateCatalogContact.gql');
const DELETE_CATALOG_CONTACT = loader('src/GraphQL/CatalogContacts/DeleteCatalogContact.gql');
const GET_CATALOG_CONTACTS = loader('src/GraphQL/CatalogContacts/GetCatalogContacts.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

const DeleteContactModal: React.FC<Props> = ({ contactData, visible, close, catalogId, changedBy, catalogCustomer, isExternalUser }): React.ReactElement => {
	const { t } = useTranslation();

	const [updateContact, { loading: loadingUpdate }] = useMutation<UpdateCatalogContact, UpdateCatalogContactVariables>(UPDATE_CATALOG_CONTACT);
	const [deleteContact, { loading: loadingDelete }] = useMutation<DeleteCatalogContact, DeleteCatalogContactVariables>(DELETE_CATALOG_CONTACT);

	const postDeleteContact = async () => {
		try {
			await deleteContact({
				variables: {
					id: contactData.id,
					changedBy: changedBy,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}
					const cachedRequest = cache.readQuery<GetCatalogContacts, GetCatalogContactsVariables>({
						query: GET_CATALOG_CONTACTS,
						variables: {
							globalOnly: true,
							thisCustomerOnly: isExternalUser,
						},
					});

					if (cachedRequest !== null && cachedRequest.catalogContacts !== null) {
						cache.writeQuery<GetCatalogContacts, GetCatalogContactsVariables>({
							query: GET_CATALOG_CONTACTS,
							variables: {
								globalOnly: true,
								thisCustomerOnly: isExternalUser,
							},
							data: {
								catalogContacts: cachedRequest.catalogContacts.filter(d => d.id !== contactData.id),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								contacts: cachedCatalogRequest.catalog.contacts.filter(c => c.id !== contactData.id),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const postRemoveContact = async () => {
		try {
			await updateContact({
				variables: {
					id: contactData.id,
					customer: contactData.customer.id,
					type: contactData.type,
					contactType: contactData.contactType,
					changedBy: changedBy,
					contactName: contactData.contactName,
					email: contactData.email,
					informations: contactData.informations ? removeTypename(contactData.informations) : [],
					global: contactData.global,
					globalType: contactData.globalType,
					active: true,
					catalogs: contactData.catalogIds.filter(id => id !== catalogId),
					catalogsBefore: contactData.catalogIds,
					action: FileActionType.REMOVE,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalogContacts, GetCatalogContactsVariables>({
						query: GET_CATALOG_CONTACTS,
						variables: {
							globalOnly: true,
							thisCustomerOnly: isExternalUser,
						},
					});

					if (cachedRequest !== null && cachedRequest.catalogContacts !== null) {
						cache.writeQuery<GetCatalogContacts, GetCatalogContactsVariables>({
							query: GET_CATALOG_CONTACTS,
							variables: {
								globalOnly: true,
								thisCustomerOnly: isExternalUser,
							},
							data: {
								catalogContacts: cachedRequest.catalogContacts.filter(c => c.id !== cacheData.updateCatalogContact.id),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								contacts: cachedCatalogRequest.catalog.contacts.filter(c => c.id !== cacheData.updateCatalogContact.id),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const toDelete = (): boolean => {
		if (contactData.global) {
			return true;
		} else if (!contactData.global && (contactData.catalogIds?.length === 1 || !catalogId)) {
			return true;
		} else {
			return false;
		}
	};

	const deleteRemoveText = (): string => {
		if (contactData.global) {
			return 'catalog.contact.globalDelete';
		} else if (!contactData.global && (contactData.catalogIds?.length === 1 || !catalogId)) {
			return 'catalog.contact.catalogDelete';
		} else {
			return 'catalog.contact.catalogRemove';
		}
	};
	return (
		<Modal
			title={toDelete() ? 'catalog.contact.delete' : 'catalog.contact.remove'}
			size={ModalSize.SMALL}
			close={() => close()}
			visible={visible}
			body={
				<div>
					<div className="flex flex-col">
						<div>
							<p>
								{t(toDelete() ? 'catalog.contact.delete' : 'catalog.contact.remove')} {contactData.contactName}?
							</p>
							<p className="text-red">{t(deleteRemoveText())}</p>
						</div>

						<Button
							danger
							text={toDelete() ? 'catalog.contact.delete' : 'catalog.contact.remove'}
							onClick={async () => {
								if (contactData.global) {
									postDeleteContact();
								} else if (!contactData.global && (contactData.catalogIds?.length === 1 || !catalogId)) {
									postDeleteContact();
								} else {
									postRemoveContact();
								}
							}}
							className="mt-4"
							loading={loadingDelete || loadingUpdate}
							disabled={loadingDelete || loadingUpdate}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteContactModal;
