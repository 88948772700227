import React from 'react';
import { useQuery } from '@apollo/client';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetCaseFiles, GetCaseFilesVariables } from '../../../GraphQL';
import Loading from '@ssg/common/Components/Loading';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	id: string;
}

const GET_CASE_FILES = loader('src/GraphQL/Files/GetCaseFiles.gql');

const CaseMailsTab: React.FC<Props> = ({ id }) => {
	const { t } = useTranslation();

	const { data, loading } = useQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, { variables: { caseErpNo: id } });

	if (loading)
		return (
			<div className="relative h-full">
				<Loading />
			</div>
		);

	const emails = data?.caseFiles?.filter(file => file.name.includes('.eml')) ?? [];

	if (emails.length === 0) return <div className="text-blue text-center font-medium">{t('file.noMails')}</div>;

	return (
		<table className="text-left text-xs">
			<thead>
				<tr>
					<th className="w-10"></th>
					<th>{t('common.name')}</th>
					<th>{t('mail.sentTo')}</th>
					<th>{t('mail.CC')}</th>
					<th>{t('mail.dateSent')}</th>
					<th>{t('common.download')}</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{emails.map(email => {
					const receiver = email.metadata.find(m => m.key === 'Mailtil')?.value ?? t('common.notSpecified');
					const cc = email.metadata.find(m => m.key === 'MailCC')?.value ?? t('common.notSpecified');
					const createdAt = email.metadata.find(m => m.key === 'Created')?.value;

					return (
						<tr key={email.name} className="text-xs even:bg-gray-100">
							<td></td>
							<td className="py-2">
								<a href={email.url} target="_blank" rel="noreferrer">
									<TextButton link text={email.name} />
								</a>
							</td>
							<td>{receiver}</td>
							<td>{cc}</td>
							<td>{createdAt && dateToDateTimeString(createdAt)}</td>
							<td className="flex flex-row py-2">
								<a href={email.url} target="_blank" rel="noreferrer">
									<TextButton icon={faEnvelope} text="case.mailEml" className="pr-4" />
								</a>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default CaseMailsTab;
