import { TFunction } from 'react-i18next';
import { GetScreenings, GetScreeningTemplates, ScreeningLineType } from '../../../GraphQL';

export function parseInputValue(inputValue: string | boolean | null): number | null {
	if (inputValue === '') return null;
	if (inputValue === true || inputValue === 'true') return 1;
	if (inputValue === false || inputValue === 'false') return null;
	if (typeof inputValue === 'string') return parseFloat(inputValue);
	return inputValue;
}

export type NonNullableProperty<TSource extends Record<string, any>, TProperty extends keyof TSource> = TSource & {
	[key in TProperty]: NonNullable<TSource[TProperty]>;
};

export type FieldValues = Record<string, string | boolean | null>; // The actual values are booleans and numbers as strings

export type TemplateLine = GetScreeningTemplates['screeningTemplates'][number]['lines'][number];
export type Heading = TemplateLine & { subLines: Heading[] };

export type ScreeningLine = GetScreenings['case']['screenings'][number]['lines'][number];

export type MappedLine = {
	erpReferenceId: string;
	title: string;
	account: string;
	jobTask: string;
	price: number;
	value: number;
	type: string;
	movableVolume: number | null;
	movableDays: number | null;
	movableDateFrom: string | null;
	movableDateTo: string | null;
	excludeFromEnvFee: boolean | null;
};

function calculateMovableValue(volume: number | null, days: number | null): string | null {
	if (volume === null || volume === 0 || days === null || days === 0) {
		return null;
	}

	return String(volume * days);
}

// Add the input value to a line
export function addInputValueToLine(linesRecord: FieldValues, t: TFunction) {
	return (
		line: TemplateLine | ScreeningLine,
	): (TemplateLine | ScreeningLine) & {
		inputValue: number | null;
		movableVolume: number | null;
		movableDays: number | null;
		movableDateFrom: string | null;
		movableDateTo: string | null;
	} => {
		let movableVolume: number | null = Number(linesRecord[`${line.id.toString()}-movableVolume`]);
		if (isNaN(movableVolume) || movableVolume === 0) {
			movableVolume = null;
		}
		let movableDays: number | null = Number(linesRecord[`${line.id.toString()}-movableDays`]);
		if (isNaN(movableDays) || movableDays === 0) {
			movableDays = null;
		}

		let movableDateFrom: string | null = String(linesRecord[`${line.id.toString()}-movableDateFrom`]);
		if (typeof movableDateFrom === 'string' && (movableDateFrom.length === 0 || movableDateFrom === String(null) || movableDateFrom === String(undefined))) {
			movableDateFrom = null;
		}

		let movableDateTo: string | null = String(linesRecord[`${line.id.toString()}-movableDateTo`]);
		if (typeof movableDateTo === 'string' && (movableDateTo.length === 0 || movableDateTo === String(null) || movableDateTo === String(undefined))) {
			movableDateTo = null;
		}

		const inputValueUnparsed =
			line.type === ScreeningLineType.INTEGER && line.showCalendar // The value of calendar input is volume times number of days
				? calculateMovableValue(movableVolume, movableDays)
				: line.type === ScreeningLineType.RADIO // The value of a radio option is placed on it's parent
				? linesRecord[line.parentId?.toString() ?? ''] === line.id.toString() // radio
				: linesRecord[line.id.toString()]; // "" - number - boolean - null
		const inputValue = parseInputValue(inputValueUnparsed);

		const customTitle = linesRecord[`${line.id.toString()}-customTitle`];
		const title =
			line.type === ScreeningLineType.INTEGER && line.showCalendar && movableVolume !== null && movableDays !== null
				? `${movableVolume} ${t('movable.volumeUnit')}, ${movableDays} ${t('movable.daysUnit')}`
				: line.allowCustomTitle && typeof customTitle === 'string' && customTitle.length > 0
				? customTitle
				: line.title;

		return {
			...line,
			inputValue,
			title,
			movableVolume,
			movableDays,
			movableDateFrom,
			movableDateTo,
		};
	};
}

export function mapLines(lines: TemplateLine[] | ScreeningLine[], data: FieldValues, t: TFunction): MappedLine[] {
	// Add inputValue
	const linesWithInputValue = lines.map(addInputValueToLine(data, t));

	// Find lines used for user input
	const inputLines = linesWithInputValue.filter(l => l.type !== ScreeningLineType.HEADING && l.type !== ScreeningLineType.FEE);

	// Find lee lines and add prices
	const feeLines = linesWithInputValue
		.filter(l => l.type === ScreeningLineType.FEE)
		.map(l => {
			const relatedLines = linesWithInputValue.filter(il => il.linePremiumId === l.id).filter((l): l is NonNullableProperty<typeof l, 'inputValue'> => l.inputValue != null);

			if (l.linePremiumFixedAmount !== null) {
				const quantity = relatedLines.reduce((sum, line) => {
					const lineQuantity = line.type === ScreeningLineType.AMOUNT ? 1 : line.inputValue;
					return sum + lineQuantity;
				}, 0);

				if (quantity !== 0) {
					l.inputValue = 1;
					l.price = l.linePremiumFixedAmount * quantity;
				}
			} else if (l.linePremiumPercentage !== null) {
				const price = relatedLines.reduce((sum, line) => {
					const lineQuantity = line.type === ScreeningLineType.AMOUNT ? 1 : line.inputValue;
					const linePrice = line.type === ScreeningLineType.AMOUNT ? line.inputValue : line.price;
					return sum + lineQuantity * linePrice;
				}, 0);

				if (price !== 0) {
					l.inputValue = 1;
					l.price = (l.linePremiumPercentage / 100) * price;
				}
			}

			return l;
		});

	// Map values to input data
	const mappedLines = [...inputLines, ...feeLines]
		// Remove lines without a relevant value
		.filter((l): l is NonNullableProperty<typeof l, 'inputValue'> => l.inputValue != null)
		.map<MappedLine>(l => ({
			erpReferenceId: l.erpReferenceId,
			title: l.title,
			type: l.type,
			account: 'erpReferenceAccount' in l ? l.erpReferenceAccount : '',
			jobTask: 'erpReferenceTask' in l ? l.erpReferenceTask : '',
			price: l.type === ScreeningLineType.AMOUNT ? l.inputValue : l.price,
			value: l.type === ScreeningLineType.AMOUNT ? 1 : l.inputValue,
			movableVolume: l.movableVolume,
			movableDays: l.movableDays,
			movableDateFrom: l.movableDateFrom,
			movableDateTo: l.movableDateTo,
			excludeFromEnvFee: l.excludeFromEnvFee,
		}));

	return mappedLines;
}

// Recursive function to add sub lines to a line. It will keep adding sub lines to the sub lines.
function withSubLines(lines: TemplateLine[]) {
	return function (line: TemplateLine): Heading {
		const subLines = lines
			// Find sublines
			.filter(l => l.parentId === line.id)
			// Remove fee lines
			.filter(l => l.type !== ScreeningLineType.FEE);
		subLines.sort((a, b) => a.index - b.index);

		return { ...line, subLines: subLines.map(withSubLines(lines)) };
	};
}

// Get template lines, root lines, and data mapped lines
export function getScreeningLines(
	template: GetScreeningTemplates['screeningTemplates'][number],
	data: Record<string, string>,
	t: TFunction,
): {
	lines: GetScreeningTemplates['screeningTemplates'][number]['lines'];
	rootLines: Heading[];
	mappedLines: MappedLine[];
} {
	// Get and sort lines
	const lines = template.lines.slice().sort((a, b) => a.index - b.index) ?? [];

	// Find parent/child relationships between all the lines
	const rootLines = lines.filter(l => l.parentId === null && l.type !== ScreeningLineType.FEE).map(withSubLines(lines));

	// Map lines
	const mappedLines = mapLines(lines, data, t);

	return { lines, rootLines, mappedLines };
}
