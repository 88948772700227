import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RootObject } from '../CatalogCreation/DAWATypes';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { CatalogAddressInput, CatalogInput, CreateCatalog_createCatalog_addresses, GetCatalog_catalog, HouseNumbers, Permissions } from '../../../GraphQL';
import { yupResolver } from '@hookform/resolvers/yup';
import { CatalogAddressSchema } from '../../../Schemas/CatalogAddressSchema';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: CatalogAddressInput;
	addressIndex?: number;
	erase?: boolean;
	changedBy: string;
	catalogData: GetCatalog_catalog;
	saveChanges: (data: CatalogInput) => Promise<void>;
	loadingUpdate?: boolean;
	addresses: CatalogAddressInput[];
}

const CatalogAddressBoxModal: React.FC<Props> = ({ open, close, edit = false, data, addressIndex, erase, changedBy, catalogData, saveChanges, loadingUpdate, addresses }): React.ReactElement => {
	const { t } = useTranslation();

	const userPermissions = React.useContext(UserContext).user?.permissions ?? [];
	const canVerify = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VERIFY]);

	const houseNumbersDropdownItems: SelectOption[] = [
		{
			label: t('catalog.address.BOTH'),
			value: HouseNumbers.BOTH,
		},
		{
			label: t('catalog.address.EQUAL'),
			value: HouseNumbers.EQUAL,
		},
		{
			label: t('catalog.address.ODD'),
			value: HouseNumbers.ODD,
		},
	];

	const { handleSubmit, control, setValue, errors, register, clearErrors } = useForm<CreateCatalog_createCatalog_addresses>({
		resolver: yupResolver(CatalogAddressSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const [distinctItems, setDistinctItems] = React.useState<RootObject[]>([]);

	const [address, setAddress] = React.useState<CatalogAddressInput>(
		data ?? {
			addressLine: '',
			zipCode: '',
			city: '',
			startNumber: '',
			endNumber: '',
			houseNumbers: HouseNumbers.BOTH,
			comment: '',
		},
	);

	const [loadingDistinctItems, setLoadingDistinctItems] = React.useState<boolean>(false);

	const [showHelpText, setShowHelpText] = React.useState<boolean>(false);

	const onSubmit = async (formData: CatalogAddressInput) => {
		const add: CatalogAddressInput = {
			addressLine: formData.addressLine,
			zipCode: formData.zipCode,
			city: formData.city,
			startNumber: formData.startNumber,
			endNumber: formData.endNumber,
			houseNumbers: formData.houseNumbers,
			comment: formData.comment,
		};

		const newAddresses = [...addresses];

		if (edit && typeof addressIndex !== 'undefined') {
			newAddresses[addressIndex] = add;
		} else {
			newAddresses.push(add);
		}

		await saveChanges({
			changedBy: changedBy,
			customer: catalogData.customer.id,
			verified: canVerify,
			propertyNumber: catalogData.propertyNumber,
			smsService: catalogData.smsService,
			debitorVerified: catalogData.debitorVerified,
			debitor: catalogData.debitor?.debitorId,
			addresses: newAddresses,
		});

		close();
	};

	const getUniqueAddresses = (items: RootObject[]) => {
		const emptyArray: RootObject[] = [];
		return items.reduce((accumulator, current) => {
			if (!accumulator.some(x => x.data.postnr === current.data.postnr && x.data.postnrnavn === current.data.postnrnavn && x.data.vejnavn === current.data.vejnavn)) {
				accumulator.push(current);
			}
			return accumulator;
		}, emptyArray);
	};

	async function request(addressValue: string) {
		setLoadingDistinctItems(true);

		setValue('addressLine', '', { shouldValidate: true });
		setValue('zipCode', '', { shouldValidate: true });
		setValue('city', '', { shouldValidate: true });

		setAddress({ ...address, addressLine: '', zipCode: '', city: '' });

		const url = `https://api.dataforsyningen.dk/autocomplete?startfra=adresse&q=${addressValue}&husnr=1`;

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: '*/*',
			},
		};

		const res = await fetch(url, requestOptions);
		const json = await res.json();

		setDistinctItems(getUniqueAddresses(json));

		setLoadingDistinctItems(false);
	}

	const searchSelectHandler = (id: string) => {
		const addressItem = distinctItems.filter(d => d.data.id === id)[0];

		if (addressItem) {
			clearErrors('addressLine');
			setValue('addressLine', addressItem.data.vejnavn, {
				shouldValidate: true,
			});
			setValue('zipCode', addressItem.data.postnr, {
				shouldValidate: true,
			});
			setValue('city', addressItem.data.postnrnavn, {
				shouldValidate: true,
			});
		}
	};

	return (
		<Modal
			title={(erase && 'catalog.address.delete') || (edit && 'catalog.address.update') || 'catalog.address.addAddress'}
			size={erase ? ModalSize.SMALL : ModalSize.MEDIUM}
			helpButtonClick={() => setShowHelpText(!showHelpText)}
			showHelpText={showHelpText}
			helpButton
			visible={open}
			close={close}
			body={
				erase ? (
					<div>
						<FormFieldHeader title="catalog.address.wantToDelete" />
						<div className="flex flex-col">
							<div>{`${address.addressLine}, ${address.zipCode}, ${address.city} ${address.startNumber}-${address.endNumber}`}</div>

							<Button
								danger
								text="catalog.address.delete"
								onClick={async () => {
									saveChanges &&
										(await saveChanges({
											changedBy: changedBy ?? '',
											customer: catalogData?.customer.id ?? '',
											verified: canVerify,
											propertyNumber: catalogData?.propertyNumber ?? '',
											smsService: catalogData?.smsService ?? false,
											debitorVerified: catalogData?.debitorVerified ?? false,
											debitor: catalogData?.debitor?.debitorId,
											addresses: addresses.filter(a => a !== data),
										}));

									close();
								}}
								className="mt-4"
								loading={loadingUpdate}
								disabled={loadingUpdate}
							/>
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<input name="addressLine" ref={register} hidden defaultValue={address.addressLine} className="invisible h-0 p-0" />

						<input name="zipCode" ref={register} hidden defaultValue={address.zipCode} className="invisible h-0 p-0" />

						<input name="city" ref={register} hidden defaultValue={address.city} className="invisible h-0 p-0" />

						<SearchableSelect
							key="label"
							control={control}
							name="fullAddressLine"
							title="catalog.address.fullAddressLine"
							helpText="catalog.address.helpText.fullAddressLine"
							showHelpText={showHelpText}
							required
							options={
								distinctItems.map(
									item =>
									({
										label: `${item.data.vejnavn}, ${item.data.postnr}, ${item.data.postnrnavn}`,
										value: item.data.id,
									} as SelectOption),
								) ?? []
							}
							searchFn={searchText => request(searchText)}
							onSelect={value => searchSelectHandler(value)}
							onBlur={() => undefined}
							minInputLength={2}
							isLoading={loadingDistinctItems}
							errorMessage={errors.addressLine?.message || errors.zipCode?.message || errors.city?.message}
							initialSelection={
								address.addressLine
									? {
										label: `${address.addressLine}, ${address.zipCode}, ${address.city}`,
										value: '',
									}
									: { label: '', value: '' }
							}
						/>

						<Input title="catalog.address.startNumber" name="startNumber" required innerRef={register} defaultValue={address.startNumber} errorMessage={errors.startNumber?.message} />

						<Input title="catalog.address.endNumber" name="endNumber" required innerRef={register} defaultValue={address.endNumber} errorMessage={errors.endNumber?.message} />

						<Dropdown title="catalog.address.houseNumbers" name="houseNumbers" innerRef={register} data={houseNumbersDropdownItems} required defaultValue={address.houseNumbers} />

						<Input title="common.comment" name="comment" innerRef={register} defaultValue={address.comment ?? ''} />

						<Button
							submit
							success
							text={edit ? 'catalog.address.update' : 'catalog.address.addAddress'}
							className="mt-2 pl-4"
							loading={loadingUpdate !== undefined ? loadingUpdate : false}
							disabled={loadingUpdate !== undefined ? loadingUpdate : false}
						/>
					</form>
					// </Box>
				)
			}
		/>
	);
};

export default CatalogAddressBoxModal;
