import React from 'react';
import { EditableInvoiceLine, toEditableInvoiceLine } from './InvoiceModal';
import { useQuery } from '@apollo/client';
import { CaseAgreementCategory, DraftInvoiceLineFragment, GetInvoicePredictions, GetInvoicePredictionsVariables, GetInvoicePredictions_invoicePredictions_lineSuggestions, GetScreenings, GetScreeningsVariables, GetSingleCase, GetSingleCaseVariables, JobTasks, JobTasksVariables, JobTasks_jobTasks } from '../../../GraphQL';
import { loader } from 'graphql.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faInfoCircle, faSpinner, faSquare, faSync } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import TextButton from '@ssg/common/Components/TextButton';
import dateToDateOnlyString from '@ssg/common/Helpers/dateToDateOnlyString';
import Popover from '@ssg/common/Components/Popover';
import { notNull } from '@ssg/common/Helpers/notNull';

const GET_INVOICE_PREDICTIONS = loader('src/GraphQL/Invoices/GetInvoicePredictions.gql');
const GET_SCREENINGS = loader('src/GraphQL/Screening/GetScreenings.gql');
const GET_CASE = loader('src/GraphQL/Cases/GetWebCase.gql');
const GET_JOB_TASKS = loader('src/GraphQL/Offer/GetJobTask.gql');

const SET_PRICE_ID = 'standard_price_list';
const SET_PRICE_NAME = 'Standard price list';
interface Props {
	caseId: string;
	invoiceERPReferenceNo: string;
	lines: EditableInvoiceLine[];
	totalPrice: number;
	totalCost: number;
	setLines: React.Dispatch<React.SetStateAction<EditableInvoiceLine[]>>;
	isNotScreening: boolean;
}

function getUniqueStringArrayByValue(arr: string[]): string[] {
	const uniqueValues = new Set();
	const resultArray: string[] = [];

	for (const obj of arr) {
		if (!uniqueValues.has(obj)) {
			uniqueValues.add(obj);
			resultArray.push(obj);
		}
	}

	return resultArray;
}

const getJobTaskDescription = (jobTasks: JobTasks_jobTasks[], taskNo: string): string => {
	const jobTask = jobTasks.find(jt => jt.jobTaskNo === taskNo);
	return jobTask?.description ?? '';
};

const LineSuggestions: React.FC<Props> = ({ caseId, lines, totalPrice, totalCost, invoiceERPReferenceNo, setLines, isNotScreening }): React.ReactElement => {
	const { t } = useTranslation();

	const { data: screeningData } = useQuery<GetScreenings, GetScreeningsVariables>(GET_SCREENINGS, {
		context: { debatch: true },
		variables: { id: caseId },
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-only',
		//skip: true,
	});

	const { data: caseData } = useQuery<GetSingleCase, GetSingleCaseVariables>(GET_CASE, {
		fetchPolicy: 'cache-only',
		variables: {
			id: caseId,
			includeEconomy: true,
		},
	});
	const { data: jobTaskData } = useQuery<JobTasks, JobTasksVariables>(GET_JOB_TASKS, { variables: { erpReferenceNo: caseData?.case.erpNo ?? '' }, fetchPolicy: 'cache-only' });
	const jobTasks = React.useMemo(() => jobTaskData?.jobTasks?.filter(notNull) ?? [], [jobTaskData?.jobTasks]);

	const profitability = React.useMemo(() => {
		const prof = ((totalPrice - totalCost) / totalPrice) * 100;
		if (prof === Infinity || prof === -Infinity || isNaN(prof)) {
			return 0;
		}

		return prof;
	}, [totalCost, totalPrice]);
	const listOfScreeningERPNo = React.useMemo(() => getUniqueStringArrayByValue(lines.map(l => l.documentNo)), [lines]);
	const screeningTemplate = React.useMemo(() => {
		if (isNotScreening) {
			return {
				templateName: SET_PRICE_NAME,
				erpReferenceNo: SET_PRICE_ID,
				erpReferenceId: SET_PRICE_ID,
				templateCode: SET_PRICE_ID,
			};
		}
		const templates =
			screeningData?.case.screenings
				.map(s => ({ templateName: s.templateName, erpReferenceNo: s.erpReferenceNo ?? '', erpReferenceId: s.templateId ?? '', templateCode: s.templateCode }))
				.filter(s => listOfScreeningERPNo.includes(s.erpReferenceNo)) ?? [];
		if (templates.length > 0) {
			return templates[0];
		}

	}, [isNotScreening, listOfScreeningERPNo, screeningData?.case.screenings]);
	const drivingSlipComments = React.useMemo(() => {
		return caseData?.case.drivingSlipSeries.flatMap(ds => ds.drivingSlips.map(d => d.comment ?? '')).join('. ') ?? '';
	}, [caseData?.case.drivingSlipSeries]);

	const caseAgreementStrings = React.useMemo(() => {
		const caseAgreements = caseData?.case.caseAgreements ?? [];
		const cause = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.CAUSE)
			.map(cA => cA.content)
			.join('. ');
		const furtherAction = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.FURTHER_ACTION)
			.map(cA => cA.content)
			.join('. ');
		const other = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.OTHER)
			.map(cA => cA.content)
			.join('. ');
		const scope = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.SCOPE)
			.map(cA => cA.content)
			.join('. ');
		const workCompleted = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.WORK_COMPLETED)
			.map(cA => cA.content)
			.join('. ');

		return {
			cause: cause.length > 0 ? cause : '',
			furtherAction: furtherAction.length > 0 ? furtherAction : '',
			other: other.length > 0 ? other : '',
			scope: scope.length > 0 ? scope : '',
			workCompleted: workCompleted.length > 0 ? workCompleted : '',
		};
	}, [caseData]);
	const hasLoadedOnce = React.useRef(false);
	const [suggestions, setSuggestions] = React.useState<GetInvoicePredictions_invoicePredictions_lineSuggestions[]>([]);
	const [fakeLoading, setFakeLoading] = React.useState(false);
	const { loading, refetch } = useQuery<GetInvoicePredictions, GetInvoicePredictionsVariables>(GET_INVOICE_PREDICTIONS, {
		variables: {
			invoicePredictionInput: {
				caseId: caseId,
				caseErpNo: caseData?.case.erpNo ?? '',
				screeningTemplateId: screeningTemplate?.erpReferenceId ?? '',
				screeningTemplateName: screeningTemplate?.templateName ?? '',
				screeningTemplateCode: screeningTemplate?.templateCode ?? '',
				drivingSlipComments: drivingSlipComments,
				invoiceERPNo: invoiceERPReferenceNo,
				profitability: profitability,
				invoiceLinesJobTaskDescription: lines.map(l => isNotScreening ? getJobTaskDescription(jobTasks, l.taskNo) : l.description),
				invoiceLinesInfo: lines.map(l => ({ id: isNotScreening ? l.taskNo : (l.screeningLineId ?? ''), description: isNotScreening ? getJobTaskDescription(jobTasks, l.taskNo) : l.description })),
				caseAgreementCause: caseAgreementStrings.cause,
				caseAgreementFurtherAction: caseAgreementStrings.furtherAction,
				caseAgreementOther: caseAgreementStrings.other,
				caseAgreementScope: caseAgreementStrings.scope,
				caseAgreementWorkCompleted: caseAgreementStrings.workCompleted,
				damageCauseName: caseData?.case.damage.cause.name ?? '',
				damageDescription: caseData?.case.damage.description ?? '',
			},
		},
		skip: typeof screeningTemplate === 'undefined' || typeof caseData === 'undefined' || hasLoadedOnce.current || lines.length === 0,
		onCompleted(data) {
			setSuggestions(data.invoicePredictions.lineSuggestions);
			if (lines.length !== 0) {
				hasLoadedOnce.current = true;
			}
		},
	});
	const addSuggestionLine = (predictionSuggestionLine: GetInvoicePredictions_invoicePredictions_lineSuggestions) => {
		const { screeningLinePositionText, ...suggestionLine } = predictionSuggestionLine;
		const newLine = toEditableInvoiceLine(suggestionLine as DraftInvoiceLineFragment);
		newLine.caseNo = caseData?.case.erpNo ?? '';
		newLine.documentNo = invoiceERPReferenceNo;
		newLine.sortIndex = lines.length + 1;
		newLine.planningDate = dateToDateOnlyString(new Date());
		setLines(current => [...current, newLine]);
	};

	return (
		<div className="text-blue">
			<div className="flex flex-row space-x-2 mb-2">
				<h2 className="font-semibold">{t('case.invoice.lineSuggestions')}</h2>
				<TextButton
					text="case.invoice.refetchSuggestions"
					onClick={async () => {
						setFakeLoading(true);
						try {
							const newSuggestions = await refetch();
							if (newSuggestions.data) {
								setSuggestions(newSuggestions.data.invoicePredictions.lineSuggestions);
							}
						} catch (e) {
							console.log(e);
						}
						setFakeLoading(false);
					}} icon={faSync} loading={fakeLoading} />
			</div>
			{loading && (
				<p className="text-blue">
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" /> {t('case.invoice.gettingLineSuggestions')}
				</p>
			)}

			<ul>{suggestions.map((d) =>
				<li key={d.systemId + d.erpReferenceTask} className="flex flex-row">
					<TextButton
						iconClassName="text-lg"
						onClick={() => lines.filter(l => l.systemId === d.systemId).length === 0 && addSuggestionLine(d)}
						icon={lines.filter(l => l.systemId === d.systemId).length > 0 ? faCheckSquare : faSquare}
						text={`${d.description}`}
					/>
					{d.screeningLinePositionText.length > 0 &&
						<Popover
							placement="top"
							content={<div className="rounded-b-default border-1 shadow-default border-gray-200 bg-white p-2 text-xs">{d.screeningLinePositionText}</div>}
						>
							<span>
								<FontAwesomeIcon icon={faInfoCircle} className="text-blue ml-1" />
							</span>
						</Popover>
					}
				</li>)}
			</ul>

		</div>
	);
};

export default LineSuggestions;
