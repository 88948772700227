import { useQuery } from '@apollo/client';
// import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';
import { Permissions } from '@ssg/common/GraphQL';
import { loader } from 'graphql.macro';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetWebCases_cases, GetWebCatalogs, GetWebCatalogsVariables } from '../../GraphQL';
import { getSessionStorageOrDefault } from '../../helper';
import { CatalogChangedBySchema } from '../../Schemas/CatalogChangedBySchema';
import UserContext from '../../UserContext';
import { IChangedBy } from '../Catalog/CatalogOverview/CatalogTabs';

const GET_CATALOGS = loader('src/GraphQL/Catalogs/GetWebCatalogs.gql');

interface Props {
	caseData: GetWebCases_cases;
}

const RelatedCatalogs: React.FC<Props> = ({ caseData }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const userContext = useContext(UserContext);

	const canViewCatalogs = userContext?.user?.permissions.includes(Permissions.CATALOGS_VIEW);
	const canVerify = userContext?.user?.permissions.includes(Permissions.CATALOGS_VERIFY);
	const username = userContext.user?.name ?? '';

	const changedBy = getSessionStorageOrDefault('catalogUsername');

	const { data, loading } = useQuery<GetWebCatalogs, GetWebCatalogsVariables>(GET_CATALOGS, {
		variables: {
			address: {
				road: caseData.damage.contact.address.road.trim(),
				houseNumber: caseData.damage.contact.address.houseNumber,
				addressLineAlt: caseData.damage.contact.address.addressLineAlt,
				postalCode: caseData.damage.contact.address.postalCode,
				city: caseData.damage.contact.address.city,
				country: caseData.damage.contact.address.country,
			},
			debitors: [caseData.debitor.debitorId],
		},
		skip: !canViewCatalogs,
	});

	const [catalogId, setCatalogId] = useState<string>('');
	const [showModal, setShowModal] = useState<boolean>(false);

	const { handleSubmit, register, errors } = useForm<IChangedBy>({
		resolver: yupResolver(CatalogChangedBySchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = (formData: IChangedBy) => {
		sessionStorage.setItem('catalogUsername', JSON.stringify(formData.changedBy));
		history.push(`/catalog/${catalogId}`, {
			changedBy: `${username} - ${formData.changedBy}`,
		});
	};

	if (!canViewCatalogs) {
		return <></>;
	}

	return (
		<>
			{!loading && data?.catalogs && data.catalogs.length > 0 && (
				<div className="flex text-sm md:flex-col lg:flex-row">
					<p className="font-medium">{t('case.relatedCatalogs')}:</p>
					<ul className="flex list-disc flex-row flex-wrap">
						{data.catalogs.map((c, i) => {
							return (
								<li key={i} className="ml-5 mr-3">
									<TextButton
										link
										text={c.propertyNumber}
										onClick={() => {
											if (!changedBy && !canVerify) {
												setShowModal(true);
												setCatalogId(c.id);
											} else {
												history.push(`/catalog/${c.id}`, {
													changedBy: username,
												});
											}
										}}
									/>
								</li>
							);
						})}
					</ul>
				</div>
			)}
			<Modal
				title=""
				size={ModalSize.SMALL}
				visible={showModal}
				close={() => history.goBack()}
				body={
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input
							title="catalog.changedBy"
							name="changedBy"
							defaultValue={changedBy}
							placeholder={t('common.name')}
							innerRef={register}
							errorMessage={errors?.changedBy?.message ?? ''}
							className="mb-3"
						/>

						<Button success submit text="common.approve" className="pl-4" />
					</form>
				}
			/>
		</>
	);
};

export default RelatedCatalogs;
