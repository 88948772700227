import React from 'react';
import Box from '../../../Components/Layout/Box';
import ScreeningModal from './ScreeningModalTwo';
import BoxFormDataViewer from '../../../Components/Layout/BoxFormDataViewer';
import Loading from '@ssg/common/Components/Loading';
import { faEdit, faLock } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CaseEconomicsOverview, CaseEconomicsOverviewVariables, GetDebitor_debitor, GetScreenings, GetScreeningsVariables, ScreeningLineType } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { mapLines } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { useFlag } from '@unleash/proxy-client-react';
import { calculateEnvironmentalFeeForLines, shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

interface Props {
	caseId: string;
	caseErpNo: string;
	defaultTemplate?: string;
	caseViewExternal: boolean;
	debitor: GetDebitor_debitor;
	caseBlocked: boolean;
}

const GET_SCREENINGS = loader('src/GraphQL/Screening/GetScreenings.gql');
const GET_CASE_ECONOMICS = loader('src/GraphQL/Cases/GetCaseEconomyOverview.gql');

type Line = GetScreenings['case']['screenings'][number]['lines'][number];

function formatLineValue(line: Line): string {
	if (line.value === null) {
		return '';
	}

	if (line.type === ScreeningLineType.BOOLEAN) {
		return line.value === 1 ? 'common.yes' : 'common.no';
	}

	return `${String(line.value)} ${line.uom}`;
}

function mapScreeningLineToBoxData(line: Line): SelectOption {

	const title = line.title + (line.position !== null && line.position.length > 0 ? ` (${line.position})` : '');
	const customTitle = line.allowCustomTitle && line.customTitle !== null && line.customTitle.length > 0 ? `${title} (${line.customTitle})` : null;

	// Radio options and boolean have no title
	if (line.type === ScreeningLineType.RADIO || line.type === ScreeningLineType.BOOLEAN) {
		return {
			label: '',
			value: customTitle ?? title,
		};
	}

	if (line.type === ScreeningLineType.AMOUNT || line.type === ScreeningLineType.FEE) {
		return {
			label: customTitle ?? title,
			value: `${String(line.price)} ${line.uom}`,
		};
	}

	return {
		label: customTitle ?? title,
		value: formatLineValue(line),
	};
}

const getDisplayData = (lines: Line[]) => {
	const populatedLines = lines.filter(l => l.value !== null || l.type === ScreeningLineType.HEADING);

	interface DisplayLine extends Pick<(typeof populatedLines)[number], 'id' | 'type' | 'title'> {
		boxData: SelectOption | null;
		children: DisplayLine[];
	}

	function hasDisplayData(displayLine: DisplayLine): boolean {
		return displayLine.boxData !== null || displayLine.children.some(hasDisplayData);
	}

	function mapDisplayLine(line: (typeof populatedLines)[number]): DisplayLine {
		return {
			id: line.id,
			type: line.type === 'RESOURCE' ? ScreeningLineType.HEADING : line.type,
			title: line.title + (line.position !== null && line.position.length > 0 ? ` (${line.position})` : ''),
			boxData: line.type !== 'RESOURCE' && line.value !== null ? mapScreeningLineToBoxData(line) : null,
			children: populatedLines
				.filter(childLine => childLine.parentId === line.id)
				.sort((a, b) => a.index - b.index)
				.map(mapDisplayLine)
				.filter(hasDisplayData),
		};
	}

	return populatedLines
		.filter(line => line.parentId === null)
		.sort((a, b) => a.index - b.index)
		.map(mapDisplayLine)
		.filter(hasDisplayData);
};

const ScreeningBox: React.FC<Props> = ({ caseId, caseErpNo, defaultTemplate, caseViewExternal, debitor, caseBlocked }) => {
	const { t } = useTranslation();
	const unlockScreeningFlag = useFlag(FeatureFlagEnums.UNLOCK_SCREENING);
	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);

	const [showEditModal, setShowEditModal] = React.useState(false);

	const { data, loading, refetch, networkStatus } = useQuery<GetScreenings, GetScreeningsVariables>(GET_SCREENINGS, {
		context: { debatch: true },
		variables: { id: caseId },
		notifyOnNetworkStatusChange: true,
	});

	const parsedScreenings = React.useMemo(
		() =>
			(data?.case.screenings ?? []).slice().sort((a, b) => parseInt((a.erpReferenceNo ?? '999999999').replace('SCR', '')) - parseInt((b.erpReferenceNo ?? '999999999').replace('SCR', ''))).map(s => {
				const data = s.lines.reduce<Record<string, string>>((record, line) => {
					// The value of a radio line is placed on the parent line, so just ignore it
					if (line.type === ScreeningLineType.RADIO) {
						return record;
					}

					// Check to see if the heading is a radio header.
					if (line.type === ScreeningLineType.HEADING) {
						const selectedOption = s.lines.find(l => l.parentId === line.id && l.type === ScreeningLineType.RADIO && l.value === 1);
						if (typeof selectedOption !== 'undefined') {
							// Add the title of the selected option as the default value of the heading
							record[String(line.id)] = String(selectedOption.id);
						}

						return record;
					}

					if (line.allowCustomTitle) {
						record[`${String(line.id)}-customTitle`] = line.customTitle ?? '';
					}

					if (line.type === ScreeningLineType.INTEGER && line.showCalendar) {
						record[`${String(line.id)}-movableDateFrom`] = line.movableDateFrom?.toString() ?? '';
						record[`${String(line.id)}-movableDateTo`] = line.movableDateTo?.toString() ?? '';
						record[`${String(line.id)}-movableDays`] = line.movableDays?.toString() ?? '';
						record[`${String(line.id)}-movableVolume`] = line.movableVolume?.toString() ?? '';
					}

					if (line.type === ScreeningLineType.AMOUNT && line.value === 1) {
						// Amount lines with user input always has value 1
						record[String(line.id)] = String(line.price);
					} else {
						record[String(line.id)] = line.value?.toString() ?? '';
					}

					return record;
				}, {});

				const displayData = getDisplayData(s.lines);
				const visibleLines = displayData.flatMap(d => [d.id, ...d.children.flatMap(c => [c.id, ...c.children.map(cc => cc.id)])]);
				const mappedLines = mapLines(s.lines, data, t);

				return {
					id: s.erpReferenceId ?? '?',
					name: s.description,
					locked: s.markedForInvoice,
					reopenAllowed: s.reopenAllowed,
					template: s.templateCode,
					templateId: s.templateId ?? '?',
					erpReferenceNo: s.erpReferenceNo ?? '?',
					templateName: s.templateName,
					data,
					displayData,
					visibleLines,
					mappedLines,
				};
			}),
		[data?.case.screenings, t],
	);

	const allScreeningsPriceCalculation = React.useMemo(
		() =>
			parsedScreenings.reduce(
				(sum, screening) => sum + screening.mappedLines.reduce((sum, line) => sum + (isNaN(line.price) ? 0 : line.price) * (isNaN(line.value) ? 0 : line.value), 0),
				0,
			),
		[parsedScreenings],
	);

	// const allScreeningsEnvironmentFeeCalculation = data?.case.screenings.reduce((sum, screening) => {
	//     const res = screening.lines
	//         .filter(x => x.type === ScreeningLineType.RESOURCE)
	//         .reduce(
	//             (sum, line) => {
	//                 const result = (isNaN(line.price) ? 0 : line.price) * (isNaN(line.price) ? 0 : line.price);
	//                 sum.sum += result;
	//                 sum.fee += line.price;
	//                 return sum;
	//             },
	//             { sum: 0, fee: 0 },
	//         );

	//     return sum + res.fee;
	// }, 0);

	const envCalcOnWholeScreening = React.useMemo(() => calculateEnvironmentalFeeForLines(parsedScreenings.flatMap(l => l.mappedLines).filter(l => l.type !== 'RESOURCE'), debitor), [parsedScreenings, debitor]);

	const [fetchCaseEconomics] = useLazyQuery<CaseEconomicsOverview, CaseEconomicsOverviewVariables>(GET_CASE_ECONOMICS, {
		variables: {
			caseERPReferenceNo: caseErpNo,
		},
		fetchPolicy: 'cache-and-network',
	});
	const showIcon = caseViewExternal
		? undefined
		: !caseBlocked
			? faEdit
			: undefined;
	return (
		<>
			<Box
				form
				title="case.screening"
				icon={showIcon}
				onClick={() => {
					if (!caseBlocked) {
						setShowEditModal(true);
					}
				}}
				className="mb-8"
			>
				{loading || isNetworkRequestInFlight(networkStatus) || typeof data === 'undefined' ? (
					<div className="relative h-32">
						<Loading />
					</div>
				) : data.case.screenings.length === 0 ? (
					<p className="p-3">{t('case.noScreenings')}</p>
				) : (
					parsedScreenings.map(screening => (
						<div key={screening.id} className="mt-4">
							<h3 className="bg-blue p-1 font-semibold text-white">
								{screening.name}
								{screening.locked && <FontAwesomeIcon icon={faLock} className="mx-2" />}
								{unlockScreeningFlag && !screening.reopenAllowed && <span className="text-base font-normal">({t('case.bcBlocker')})</span>}
								<span className="float-right">
									{t('case.screeningTotal')}&nbsp;
									{screening.mappedLines
										.filter(line => line.type !== 'RESOURCE')
										.reduce((sum, line) => sum + line.price * line.value, 0)
										.toLocaleString(undefined, {
											maximumFractionDigits: 2,
										})}
								</span>
							</h3>
							<div className="text-blue flex h-full flex-wrap px-3">
								{screening.displayData.map(displayLine => {
									function renderDisplayLine(line: typeof displayLine, child = false, indent = 0): React.ReactElement {
										return (
											<div
												key={line.id}
												style={{
													paddingLeft: indent * 6,
												}}
											>
												{line.type === ScreeningLineType.HEADING &&
													(child ? <div className="text-base font-semibold">{line.title}</div> : <div className="text-lg font-semibold">{line.title}</div>)}

												{line.boxData !== null && <BoxFormDataViewer keyId={`case.screeningInformation.${line.id}`} data={[line.boxData]} type={line.type} />}

												{line.children.map(childLine => (
													<div key={childLine.id} className="mt-2">
														{renderDisplayLine(childLine, true, indent + 1)}
													</div>
												))}
											</div>
										);
									}

									return (
										<div key={displayLine.id} className="w-1/4">
											{renderDisplayLine(displayLine)}
										</div>
									);
								})}
							</div>
						</div>
					))
				)}

				<div className="mt-4">
					{shouldCalculateEnvFeeForDebitor && (
						<h3 className="border-t-1 border-gray-300 px-1 pt-3 font-medium">
							&nbsp;
							{/* <span className="float-right">
                                {t('case.invoice.envFee')}&nbsp;
                                {allScreeningsEnvironmentFeeCalculation
                                    ? allScreeningsEnvironmentFeeCalculation.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                    : 0}
                            </span> */}
							<span className="float-right">
								{t('case.invoice.envFee')}&nbsp;
								{envCalcOnWholeScreening.fee.toLocaleString(undefined, { maximumFractionDigits: 2 })}
							</span>
						</h3>
					)}
					<h3 className="border-t-1 border-gray-300 px-1 pt-3 font-semibold">
						&nbsp;
						<span className="float-right">
							{t('case.screeningTotalAll')}&nbsp;
							{(allScreeningsPriceCalculation).toLocaleString(undefined, {
								maximumFractionDigits: 2,
							})}
						</span>
					</h3>
				</div>
			</Box>
			{showEditModal && (
				<ScreeningModal
					key={JSON.stringify(showEditModal)}
					caseId={caseId}
					close={() => setShowEditModal(false)}
					visible={showEditModal}
					screenings={parsedScreenings}
					defaultScreeningName={t('common.general')}
					defaultTemplate={data?.case.caseDefaultScreeningTemplate ?? defaultTemplate}
					debitor={debitor}
					submitCb={() => {
						refetch();
						fetchCaseEconomics();
						setShowEditModal(false);
					}}
					submitUnlockCb={() => {
						refetch();
					}}
				/>
			)}
		</>
	);
};

export default ScreeningBox;
