import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GetWebCase, GetWebCaseVariables, GetCatalogCaseCreation, GetCatalogCaseCreationVariables } from '../../GraphQL';
import Header from '@ssg/common/Components/Header';
import CaseCreationFormWrapper from './CaseCreationFormWrapper';
import Loading from '@ssg/common/Components/Loading';
import TextButton from '@ssg/common/Components/TextButton';

const GET_CASE = loader('../../GraphQL/Cases/GetWebCase.gql');
const GET_CATALOG_CASE_CREATION = loader('../../GraphQL/Catalogs/GetCatalogCaseCreation.gql');

const CaseCreation: React.FC = (): React.ReactElement => {
	const { id, catId } = useParams<{
		id: string;
		catId: string;
		index: string;
	}>();

	const { loading, data } = useQuery<GetWebCase, GetWebCaseVariables>(GET_CASE, {
		variables: {
			id: id,
		},
		skip: !id, // Handles undefined & empty string scenarios
		fetchPolicy: 'network-only',
	});

	const { loading: catalogLoading, data: catalogData } = useQuery<GetCatalogCaseCreation, GetCatalogCaseCreationVariables>(GET_CATALOG_CASE_CREATION, {
		variables: {
			id: catId,
		},
		skip: !catId,
	});

	const { t } = useTranslation();

	const [showMigrationDataBox, setShowMigrationDataBox] = React.useState(false);

	if (loading) return <Loading title="case.gettingCase" />;
	if (catalogLoading) return <Loading title="catalog.loading" />;

	return (
		<div>
			<Header title="common.caseCreateTitle">
				<div>
					{data?.case && (
						<p className="text-blue text-xl font-medium">
							{t('case.duplicateOf')}
							{data.case.erpNo}
						</p>
					)}
					{/* {catalog?.catalog && (
                        <p className="text-xl font-medium text-blue">
                            {t('case.fromDebitor')}
                            {catalog.catalog.debitor?.debitorId}
                        </p>
                    )} */}
					<Trans t={t} i18nKey="case.creationHeaderGuide" components={[<span className="text-red font-medium" />]} />

					{typeof id === 'undefined' && (
						<TextButton
							id="migration-toggle"
							text="Vis migreringsfelter"
							onClick={() => setShowMigrationDataBox(!showMigrationDataBox)}
							className="float-right mr-4"
							style={{ fontSize: 8 }}
						/>
					)}
				</div>
			</Header>
			<CaseCreationFormWrapper catalogData={catalogData?.catalog} caseData={data?.case} showMigrationDataBox={showMigrationDataBox} />
		</div>
	);
};

export default CaseCreation;
