import React from 'react';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { CatalogAddressInput, HouseNumbers } from '../../../GraphQL';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';

interface IProps {
	addresses: CatalogAddressInput[];
	setAddress: React.Dispatch<React.SetStateAction<CatalogAddressInput | undefined>>;
	setCreateAddress: React.Dispatch<React.SetStateAction<boolean>>;
	setUpdateAddress: React.Dispatch<React.SetStateAction<boolean>>;
	setDeleteAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const CatalogAddressesBox: React.FC<IProps> = ({ addresses, setAddress, setCreateAddress, setUpdateAddress, setDeleteAddress }) => {
	const { t } = useTranslation();
	const addressesWithIndex = React.useMemo(() => addresses.map((a, i) => ({ ...a, index: i })), [addresses]);
	return (
		<div className="w-full">
			{/* <Box form title='' className='text-blue' half> */}
			<div className="text flex justify-between">
				<FormFieldHeader title="catalog.addresses" labelClass="text-lg" titleClass="" />
				<TextButton
					icon={faPlus}
					text="catalog.address.addAddress"
					onClick={() => {
						if (addresses.length === 0) {
							setAddress({
								addressLine: '',
								zipCode: '',
								city: '',
								startNumber: '',
								endNumber: '',
								houseNumbers: HouseNumbers.BOTH,
								comment: '',
							});
						} else {
							setAddress({
								addressLine: addresses[addresses.length - 1]?.addressLine ?? '',
								zipCode: addresses[addresses.length - 1]?.zipCode ?? '',
								city: addresses[addresses.length - 1]?.city ?? '',
								startNumber: '',
								endNumber: '',
								houseNumbers: HouseNumbers.BOTH,
								comment: '',
							});
						}

						setCreateAddress(true);
					}}
				/>
			</div>

			<Table
				data={addressesWithIndex}
				columns={[
					{
						label: t('common.road'),
						selectFn: c => <p>{c?.addressLine}</p>,
						sortFn: (a, b) => (a?.addressLine ?? '').localeCompare(b?.addressLine ?? ''),
					},
					{
						label: t('catalog.address.startNumber'),
						numeric: true,
						selectFn: c => <p>{c?.startNumber}</p>,
						sortFn: (a, b) => (a?.startNumber ?? '').localeCompare(b?.startNumber ?? ''),
					},
					{
						label: t('catalog.address.endNumber'),
						numeric: true,
						selectFn: c => <p>{c?.endNumber}</p>,
						sortFn: (a, b) => (a?.endNumber ?? '').localeCompare(b?.endNumber ?? ''),
					},
					{
						label: t('catalog.address.houseNumbers'),
						selectFn: c => <p>{t(`catalog.address.${c?.houseNumbers}`)}</p>,
						sortFn: (a, b) => (t(`catalog.address.${a?.houseNumbers}`) ?? '').localeCompare(t(`catalog.address.${b?.houseNumbers}`) ?? ''),
					},
					{
						label: t('common.postalcode'),
						numeric: true,
						selectFn: c => <p>{c?.zipCode}</p>,
						sortFn: (a, b) => (a?.zipCode ?? '').localeCompare(b?.zipCode ?? ''),
					},
					{
						label: t('common.city'),
						selectFn: c => <p>{c?.city}</p>,
						sortFn: (a, b) => (a?.city ?? '').localeCompare(b?.city ?? ''),
					},
					{
						label: 'common.comment',
						selectFn: a => <p>{a.comment}</p>,
					},
					{
						label: t('common.edit'),
						classNameTh: 'text-right',
						selectFn: c => (
							<div className="flex content-start justify-end text-right">
								<FontAwesomeIcon
									icon={faEdit}
									size="lg"
									onClick={() => {
										setAddress(addresses[c.index]);
										setUpdateAddress(true);
									}}
									className="cursor-pointer"
								/>
							</div>
						),
					},
					{
						label: t('common.remove'),
						classNameTh: 'text-right',
						selectFn: c => (
							<div className="text-red flex content-start justify-end text-right">
								<FontAwesomeIcon
									icon={faTrashAlt}
									size="lg"
									onClick={() => {
										setAddress(addresses[c.index]);
										setDeleteAddress(true);
									}}
									className="cursor-pointer"
								/>
							</div>
						),
					},
				]}
				keySelector={c => `${c?.addressLine}${c?.startNumber}-${c?.endNumber}`}
			/>
		</div>
	);
};

export default CatalogAddressesBox;
