import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { GetTimeTrackings, GetTimeTrackingsVariables, GetTimeTrackings_timeTrackings, TrackingType } from '../../GraphQL';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import dateToDateOnlyString, { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Button from '@ssg/common/Components/Button';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';

const GET_TIME_TRACKINGS = loader('src/GraphQL/TimeTracking/GetTimeTrackings.gql');

interface MergedTrackingItems {
	id: string;
	date: string;
	user: {
		id: string;
		name: string;
	};
	trackings: GetTimeTrackings_timeTrackings[];
}

const OpenRegistrationsOverview: React.FC = () => {
	const { t } = useTranslation();

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const [dateStart, setDateStart] = React.useState<Date>(yesterday);
	const [dateEnd, setDateEnd] = React.useState<Date>(new Date());

	const { data: timeTrackingData, loading: loadingTimeTracking } = useQuery<GetTimeTrackings, GetTimeTrackingsVariables>(GET_TIME_TRACKINGS, {
		variables: {
			minDate: dateToDateOnlyString(dateStart),
			maxDate: dateToDateOnlyString(dateEnd),
		},
		fetchPolicy: 'cache-and-network',
	});

	const timeTrackings = React.useMemo(() => {
		return timeTrackingData?.timeTrackings ?? [];
	}, [timeTrackingData]);

	const closedDays = timeTrackings.filter(i => i.trackingType === TrackingType.DAYCLOSED);

	const openDays = timeTrackings.filter(dayOpen => !closedDays.find(dc => dayOpen.date === dc.date && dayOpen.user.id === dc.user.id));

	const trackingBundle = openDays.reduce((trackingBundle, item) => {
		const { id, date, user } = item;

		let bundle = trackingBundle.find(i => i.user.id === item.user.id && i.date === item.date);

		if (typeof bundle === 'undefined') {
			const index = trackingBundle.push({ id, date, user, trackings: [] }) - 1;
			bundle = trackingBundle[index];
		}

		bundle.trackings.push(item);

		return trackingBundle;
	}, [] as Array<MergedTrackingItems>);

	return (
		<div>
			<BoxContainer>
				<div className="flex w-full">
					<Box full>
						<div className="order-2 mb-3 mr-3 lg:order-3">
							<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.created')}</label>
							<div className="flex items-center">
								<input
									type="date"
									value={formatDateForInput(dateStart)}
									onChange={e => {
										const minDate = e.target.valueAsDate;
										if (minDate === null || typeof minDate === 'undefined') {
											return;
										}

										setDateStart(new Date(minDate));

										if (minDate > dateEnd) {
											const maxDate = new Date(minDate);
											maxDate.setHours(23, 59, 59, 999);
											setDateEnd(new Date(maxDate));
										}
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '34px',
										maxWidth: '144px',
										minWidth: '100px',
									}}
								/>
								<span className="px-1">&mdash;</span>
								<input
									type="date"
									min={formatDateForInput(dateEnd)}
									value={formatDateForInput(dateEnd)}
									onChange={e => {
										const maxDate = e.target.valueAsDate;
										if (maxDate === null) {
											return;
										}
										maxDate.setHours(23, 59, 59, 999);

										setDateEnd(new Date(maxDate));
									}}
									className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
									style={{
										height: '34px',
										maxWidth: '144px',
										minWidth: '100px',
									}}
								/>
								{typeof dateStart !== 'undefined' && typeof dateEnd !== 'undefined' && (
									<FontAwesomeIcon
										icon={faTimes}
										size="1x"
										className="ml-2 cursor-pointer"
										onClick={() => {
											setDateStart(new Date());
											setDateEnd(new Date());
										}}
									/>
								)}
							</div>
						</div>

						<div>
							<Button />
							<Table
								data={trackingBundle ?? []}
								columns={[
									{
										label: 'common.date',
										numeric: true,
										selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
										sortFn: (a, b) => a.date.localeCompare(b.date),
									},
									{
										label: 'common.name',
										selectFn: t => <p>{t.user.name ? t.user.name : ''}</p>,
										sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
									},
								]}
								keySelector={t => t.id}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={typeof timeTrackingData === 'undefined' && loadingTimeTracking}
							/>
						</div>
					</Box>
				</div>
			</BoxContainer>
		</div>
	);
};

export default OpenRegistrationsOverview;
