import React from 'react';
import { useMutation } from '@apollo/client';
import { faAlarmPlus, faCheck, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { GetWebCase_case_jobs, UpdateJob, UpdateJobVariables } from '../../../GraphQL';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import classNames from 'classnames';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';

const UPDATE_JOB = loader('../../../GraphQL/Jobs/UpdateJob.gql');

interface Props {
	job: GetWebCase_case_jobs;
}

const getOverdue = (date: Date): boolean => {
	const today = new Date();
	return date < today;
};

const JobsItem: React.FC<Props> = ({ job }): React.ReactElement => {
	const { t } = useTranslation();

	const [showPopover, setShowPopover] = React.useState<boolean | undefined>(undefined);

	const [updateJob] = useMutation<UpdateJob, UpdateJobVariables>(UPDATE_JOB);

	const overdue = getOverdue(new Date(job.deadline));
	const date = DateTime.fromJSDate(new Date(job.deadline));

	const updateDeadlineOrCompletionJob = async (deadline: Date, completed?: boolean) => {
		try {
			postJobUpdate({
				id: job.id,
				deadline: deadline.toISOString(),
				completed: completed,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const postJobUpdate = async ({ id, description, deadline, assignedTo, completed }: UpdateJobVariables): Promise<void> => {
		await updateJob({
			variables: {
				id: id,
				description: description,
				deadline: deadline,
				assignedTo: assignedTo,
				completed: completed,
			},
		});
	};

	return (
		<tr className="even:bg-gray-100">
			<td className="flex justify-center px-1 py-2">
				{job.completed && <FontAwesomeIcon icon={faCheck} size="lg" className="text-green" />}
				{overdue && !job.completed && <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-red" />}
			</td>
			<td>
				<div className="flex flex-row">
					<span
						className={classNames('py-2', {
							'text-red': overdue && !job.completed,
						})}
					>
						{dateToDateTimeString(job.deadline)}
					</span>
				</div>
			</td>
			<td>
				<div>{job.assignedTo?.name}</div>
			</td>
			<td>
				<div>{job.description}</div>
			</td>
			<td>
				{!job.completed && (
					<Popover
						placement="top"
						interactive
						clickTrigger
						hideOnClick
						visible={showPopover}
						onHide={() => setShowPopover(undefined)}
						content={
							<div className="shadow-default border-1 bg-white px-4 pt-2 pb-8">
								<p className="mb-1 text-center text-sm font-medium">{t('jobs.extendDeadline')}</p>
								<div className="flex flex-col items-center gap-y-1">
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 0.5 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`½ ${t('common.day')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 1 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`1 ${t('common.day')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 2 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`2 ${t('common.days')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 3 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`3 ${t('common.days')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 4 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`4 ${t('common.days')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 5 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`5 ${t('common.days')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 6 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`6 ${t('common.days')}`}
									</div>
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(date.plus({ days: 7 }).toJSDate(), false);
											setShowPopover(false);
										}}
									>
										{`7 ${t('common.days')}`}
									</div>
								</div>
								<p className="my-1 text-center">{t('common.or')}</p>
								<div className="flex flex-col items-center">
									<div
										className="cursor-pointer hover:font-medium"
										onClick={() => {
											updateDeadlineOrCompletionJob(new Date(job.deadline), true);
											setShowPopover(false);
										}}
									>{`${t('jobs.finish')}`}</div>
								</div>
							</div>
						}
					>
						<div className="flex w-8 justify-center">
							<TextButton icon={faAlarmPlus} />
						</div>
					</Popover>
				)}
			</td>
		</tr>
	);
};

export default JobsItem;
