import * as yup from 'yup';

export const drivingSlipSchema = yup.object().shape({
	urgent: yup.bool(),
	driver: yup.array().of(yup.string()).nullable(),
	immediatelyPlan: yup.boolean(),
	deadline: yup.date(),
	start: yup.date().required(),
	plannedDuration: yup
		.number()
		.transform(pd => (isNaN(pd) ? null : pd))
		.nullable(),
	comment: yup.string(),
	location: yup.string().required(),
	department: yup.string().required(),
	category: yup.string(),
});
