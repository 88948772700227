import { CaseAgreementCategory, useGetInvoicePredictionsLazyQuery, useGetSingleCaseQuery } from '@ssg/common/GraphQL/indexV2';
import React, { Fragment } from 'react';
import { AllScreeningsType, MovableValues, validateRootHeading } from './ScreeningModalTwo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSync } from '@fortawesome/pro-regular-svg-icons';
import { GetScreeningTemplates, GetWebScreeningPositionTexts, ScreeningLineType } from '../../../GraphQL';
import { Heading, MappedLine } from './helpers';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Radio from '@ssg/common/Components/Radio';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import Input from '@ssg/common/Components/Input';
import DynamicInput from './DynamicInput';
import DateRangeInput from './DateRangeInput';
import { useTranslation } from 'react-i18next';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import TextButton from '@ssg/common/Components/TextButton';
interface Props {
	caseId: string;
	screenings: AllScreeningsType[];
	positionTextsData: GetWebScreeningPositionTexts | undefined;
	data: GetScreeningTemplates | undefined
	setScreeningLineCustomLine: (screeningId: string, screeningLineId: string, customTitle: string, movableValues?: MovableValues) => void;
	setScreeningLineValue: (screeningId: string, screeningLineId: string, value: number, movableValues?: MovableValues) => void
}

interface SuggestionsDictionary {
	tabId: string;
	tabName: string;
	suggestionIds: string[];
}

function erpReferenceIdsExist(heading: Heading, erpReferenceIds: string[]): boolean {
	if (erpReferenceIds.includes(heading.erpReferenceId)) {
		return true;
	}
	for (const subLine of heading.subLines) {
		if (erpReferenceIdsExist(subLine, erpReferenceIds)) {
			return true;
		}
	}
	return false;
}


const ScreeningSuggestions: React.FC<Props> = ({ caseId, screenings, positionTextsData, data, setScreeningLineCustomLine, setScreeningLineValue }): React.ReactElement => {

	const { t } = useTranslation();

	const { data: caseData } = useGetSingleCaseQuery({
		fetchPolicy: 'cache-only',
		variables: {
			id: caseId,
			includeEconomy: true,
		},
	});

	const drivingSlipComments = React.useMemo(() => {
		return caseData?.case.drivingSlipSeries.flatMap(ds => ds.drivingSlips.map(d => d.comment ?? '')).join('. ') ?? '';
	}, [caseData?.case.drivingSlipSeries]);

	const caseAgreementStrings = React.useMemo(() => {
		const caseAgreements = caseData?.case.caseAgreements ?? [];
		const cause = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.Cause)
			.map(cA => cA.content)
			.join('. ');
		const furtherAction = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.FurtherAction)
			.map(cA => cA.content)
			.join('. ');
		const other = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.Other)
			.map(cA => cA.content)
			.join('. ');
		const scope = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.Scope)
			.map(cA => cA.content)
			.join('. ');
		const workCompleted = caseAgreements
			.filter(cA => cA.category === CaseAgreementCategory.WorkCompleted)
			.map(cA => cA.content)
			.join('. ');

		return {
			cause: cause.length > 0 ? cause : '',
			furtherAction: furtherAction.length > 0 ? furtherAction : '',
			other: other.length > 0 ? other : '',
			scope: scope.length > 0 ? scope : '',
			workCompleted: workCompleted.length > 0 ? workCompleted : '',
		};
	}, [caseData]);

	const [getScreeningPredictions] = useGetInvoicePredictionsLazyQuery();
	const [suggestions, setSuggestions] = React.useState<SuggestionsDictionary[]>([]);
	const [loading, setLoading] = React.useState(false);
	const getAllScreeningPredictions = async () => {
		setLoading(true);
		const listOfPredictions = await Promise.all(screenings.map(async screening => {
			const { data } = await getScreeningPredictions({
				variables: {
					invoicePredictionInput: {
						caseId: caseId,
						caseErpNo: caseData?.case.erpNo ?? '',
						screeningTemplateId: screening.templateId,
						screeningTemplateName: screening.templateName,
						screeningTemplateCode: screening.template,
						drivingSlipComments: drivingSlipComments,
						invoiceERPNo: screening.erpReferenceNo,
						profitability: 0,
						invoiceLinesJobTaskDescription: screening.mappedLines.map(l => l.title),
						invoiceLinesInfo: screening.mappedLines.map(l => ({ id: l.erpReferenceId ?? '', description: l.title })),
						caseAgreementCause: caseAgreementStrings.cause,
						caseAgreementFurtherAction: caseAgreementStrings.furtherAction,
						caseAgreementOther: caseAgreementStrings.other,
						caseAgreementScope: caseAgreementStrings.scope,
						caseAgreementWorkCompleted: caseAgreementStrings.workCompleted,
						damageCauseName: caseData?.case.damage.cause.name ?? '',
						damageDescription: caseData?.case.damage.description ?? '',
					},
				},
				fetchPolicy: 'no-cache',
			});

			return Promise.resolve({ tabId: screening.id, tabName: screening.name, suggestionIds: data?.invoicePredictions.lineSuggestions.map(l => l.screeningLineId) ?? [] });

		}));

		setSuggestions(listOfPredictions);
		setLoading(false);
	};

	React.useEffect(() => {
		getAllScreeningPredictions();
	}, []);

	return (
		<div className="space-y-4 text-blue">
			{!loading &&
				<TextButton
					text="case.invoice.refetchSuggestions"
					onClick={async () => {
						await getAllScreeningPredictions();
					}} icon={faSync} loading={loading} />
			}
			{loading && <p className="pl-2 py-1"><FontAwesomeIcon className="animate-spin" icon={faSpinner} /> Henter forslag til linjer ...</p>}
			{suggestions.map((s, index) => {
				const screening = screenings.find(screening => screening.id === s.tabId);
				if (typeof screening === 'undefined') return null;
				const screeningPriceCalculationNoFee = screening.mappedLines.reduce((sum, line) => sum + (isNaN(line.price) ? 0 : line.price) * (isNaN(line.value) ? 0 : line.value), 0) ?? 0;
				if (s.suggestionIds.length === 0) return (
					<div key={index}>
						<div className="w-full flex flex-row justify-between">

							<h2 className="font-semibold text-lg">{s.tabName}</h2>
							<h2>Total: {screeningPriceCalculationNoFee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
						</div>
						<div className="w-full h-px bg-blue" />
						<p className="text-center mt-2">{t('case.screeningNoSuggestions')}</p>
					</div>
				);

				const rootLines = screening.rootLines.filter((root) => erpReferenceIdsExist(root, s.suggestionIds));
				return (
					<>
						<div className="text-blue">
							<div className="w-full flex flex-row justify-between">

								<h2 className="font-semibold text-lg">{s.tabName}</h2>
								<h2>Total: {screeningPriceCalculationNoFee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
							</div>
							<div className="w-full h-px bg-blue" />

							{rootLines.map(rootLine => {
								const headingError = validateRootHeading(rootLine, screening.mappedLines);

								function mapLine(line: Heading): React.ReactElement {
									if (typeof screening === 'undefined') return <></>;

									const key = String(line.id);
									const mappedLine: MappedLine | undefined = screening.mappedLines.find(ml => ml.erpReferenceId === line.erpReferenceId);
									const feeLine = data?.screeningTemplates
										.find(st => st.templateCode === screening.template)
										?.lines.find(l => line.linePremiumId !== null && l.id === line.linePremiumId);
									let feeInfo: React.ReactElement | null = null;
									if (typeof feeLine?.linePremiumFixedAmount === 'number') {
										feeInfo = (
											<small className="w-50 flex flex-row justify-between px-1">
												<span>
													{feeLine.title}
													:&nbsp;
												</span>
												<span>
													{((mappedLine?.value ?? 0) * feeLine.linePremiumFixedAmount).toLocaleString(undefined, {
														maximumFractionDigits: 2,
													})}
												</span>
											</small>
										);
									} else if (typeof feeLine?.linePremiumPercentage === 'number') {
										feeInfo = (
											<small className="w-50 flex flex-row justify-between px-1">
												<span>
													{feeLine.title}
													:&nbsp;
												</span>
												<span>
													{((mappedLine?.value ?? 0) * (mappedLine?.price ?? 0) * (feeLine.linePremiumPercentage / 100)).toLocaleString(undefined, {
														maximumFractionDigits: 2,
													})}
												</span>
											</small>
										);
									}

									const positionText = positionTextsData?.screeningPositionTexts.find(spt => spt.position === line.position)?.text;

									const customTitleInput = line.allowCustomTitle ? (
										<Input
											compact
											name={`${key}-customTitle`}
											type="text"
											maxLength={99} // BC limit is 100 charaters
											placeholder={t('common.description')}
											readOnly={screening.locked}
											value={mappedLine?.title !== line.title ? mappedLine?.title : undefined}
											onChange={e => {
												screening.id && setScreeningLineCustomLine(screening.id, line.erpReferenceId, e.currentTarget.value);
											}}
										/>
									) : null;

									const isRadioHeader = line.type === ScreeningLineType.HEADING && line.subLines.some(l => l.type === ScreeningLineType.RADIO);
									if (isRadioHeader) {
										return (
											<Fragment key={key + screening.id}>
												<div
													className="flex w-full flex-row justify-between"
												>
													<div className="w-full -mt-2">
														<FormFieldHeader
															smallTitle
															title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
															labelClass="cursor-pointer"
															required={line.required}
														/>
													</div>
												</div>
												<div>
													<Radio
														name={key + index}
														title=""
														className="text-sm"
														options={line.subLines
															.filter(l => l.type === ScreeningLineType.RADIO)
															.map(l => {
																const positionText = positionTextsData?.screeningPositionTexts.find(spt => spt.position === l.position)?.text;
																return {
																	label: l.position ? `${l.title} (${l.position})` : `${l.title}`,
																	labelInfoPopover: typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined,
																	value: String(l.id),
																	children: mappedLine?.value !== l.id ? null : l.subLines.map(mapLine),
																};
															})}
														onChange={e => {
															if (typeof screening.id !== 'undefined') {
																setScreeningLineValue(screening.id, line.erpReferenceId, Number(e.currentTarget.value));
															}
														}}
														labelWeight="NONE"
														disabled={screening.locked}
													/>

													{customTitleInput}
													{feeInfo}

													{line.subLines.filter(l => l.type !== ScreeningLineType.RADIO).map(mapLine)}
												</div>
											</Fragment>
										);
									}

									const isBooleanHeader = line.type === ScreeningLineType.BOOLEAN && line.subLines.length > 0;
									if (isBooleanHeader) {
										if (!s.suggestionIds.includes(line.erpReferenceId)) return <></>;
										return (
											<Fragment key={key + screening.id}>
												<DynamicInput
													name={key + (index + 1)}
													type="BOOLEAN"
													unit={line.uom}
													title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
													labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
													disabled={screening.locked}
													value={mappedLine?.value}
													onChange={e => {
														if (typeof screening.id !== 'undefined') {
															setScreeningLineValue(screening.id, line.erpReferenceId, e.currentTarget.checked ? 1 : 0);
														}
													}}
												/>
												{Boolean(mappedLine?.value) && line.subLines.map(mapLine)}
												{customTitleInput}
												{feeInfo}
											</Fragment>
										);
									}

									const isHeader = line.type === ScreeningLineType.HEADING;
									if (isHeader) {
										return (
											<Fragment key={key + screening.id}>
												<div
													className="flex w-full flex-row justify-between"
												>
													<div className="w-full -mt-2">
														<FormFieldHeader
															smallTitle
															title={line.title}
															labelClass="cursor-pointer"
															required={line.required}
														/>
													</div>
												</div>
												<div>
													{line.subLines.map(mapLine)}
												</div>
											</Fragment>
										);
									}

									const isDateRange = line.type === ScreeningLineType.INTEGER && line.showCalendar;
									if (isDateRange) {
										if (!s.suggestionIds.includes(line.erpReferenceId)) return <></>;
										return (
											<Fragment key={key + screening.id}>
												<Input
													compact
													name={`${key}-movableDays`}
													type="number"
													title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
													labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
													unit={line.uom}
													value={mappedLine?.movableDays ?? undefined}
													readOnly={screening.locked}
													onChange={e => {
														screening.id &&
															setScreeningLineValue(
																screening.id,
																line.erpReferenceId,
																e.currentTarget.valueAsNumber * (mappedLine?.movableVolume ?? 0),
																{
																	movableDateFrom: mappedLine?.movableDateFrom ?? null,
																	movableDateTo: mappedLine?.movableDateTo ?? null,
																	movableDays: e.currentTarget.valueAsNumber ?? null,
																	movableVolume: mappedLine?.movableVolume ?? null,
																},
															);
													}}
													header={
														<>
															<p className="text-blue block cursor-pointer text-sm font-semibold">
																{t('case.screeningShowCalender')}
															</p>

															<DateRangeInput
																fromValue={mappedLine?.movableDateFrom}
																toValue={mappedLine?.movableDateTo}
																onChange={(from, to, count) => {
																	screening.id &&
																		setScreeningLineValue(screening.id, line.erpReferenceId, (mappedLine?.movableVolume ?? 0) * count, {
																			movableDateFrom: formatDateForInput(from),
																			movableDateTo: formatDateForInput(to),
																			movableDays: count,
																			movableVolume: mappedLine?.movableVolume ?? null,
																		});
																}}
															/>
														</>
													}
												/>
												<Input
													compact
													name={`${key}-movableVolume`}
													type="number"
													unit={t('movable.volumeUnit')}
													readOnly={screening.locked}
													value={mappedLine?.movableVolume ?? undefined}
													onChange={e => {
														screening.id &&
															setScreeningLineValue(
																screening.id,
																line.erpReferenceId,
																e.currentTarget.valueAsNumber * (mappedLine?.movableDays ?? 0),
																{
																	movableDateFrom: mappedLine?.movableDateFrom ?? null,
																	movableDateTo: mappedLine?.movableDateTo ?? null,
																	movableDays: mappedLine?.movableDays ?? null,
																	movableVolume: e.currentTarget.valueAsNumber,
																},
															);
													}}
												/>
												<small className="flex w-full flex-row justify-between px-1 lg:w-3/4">
													<span className="mx-1">{t('common.total')}</span>
													<span className="flex-grow-default text-right">
														{((mappedLine?.movableDays ?? 0) * (mappedLine?.movableVolume ?? 0)).toLocaleString(undefined, {
															maximumFractionDigits: 2,
														})}
													</span>
													<span className="mx-1">{t('movable.volumeUnit')}</span>
												</small>

												{customTitleInput}
												{feeInfo}
											</Fragment>
										);
									}
									if (!s.suggestionIds.includes(line.erpReferenceId)) return <></>;
									return (
										<Fragment key={key + screening.id}>
											<DynamicInput
												name={key + (index + 1)}
												type={line.type === ScreeningLineType.BOOLEAN ? 'BOOLEAN' : 'NUMBER'}
												unit={line.uom}
												title={line.position ? `${line.title} (${line.position})` : `${line.title}`}
												labelInfoPopover={typeof positionText !== 'undefined' ? <span>{positionText}</span> : undefined}
												labelWeight="NONE"
												disabled={screening.locked}
												value={line.type !== ScreeningLineType.AMOUNT ? mappedLine?.value : mappedLine?.price}
												onChange={e => {
													if (typeof screening.id !== 'undefined') {
														line.type !== ScreeningLineType.BOOLEAN &&
															setScreeningLineValue(screening.id, line.erpReferenceId, e.currentTarget.valueAsNumber);
														line.type === ScreeningLineType.BOOLEAN &&
															setScreeningLineValue(screening.id, line.erpReferenceId, e.target.checked ? 1 : 0);
													}
												}}
											/>
											{customTitleInput}
											{feeInfo}
										</Fragment>
									);
								}

								return (
									<div key={rootLine.id + screening.id}>
										{rootLine.type !== ScreeningLineType.HEADING ? (
											s.suggestionIds.includes(rootLine.erpReferenceId) ? mapLine(rootLine) : <></>
										) : (
											<div className="px-2">
												<div
													className="flex w-full flex-row justify-between"
												>
													<div className="w-full pt-2">
														<h2 className="text-blue font-bold text-base">{rootLine.title}</h2>
													</div>
												</div>

												{headingError != null && <FormErrorText text={headingError} />}

												<div>
													{rootLine.subLines.filter((root) => erpReferenceIdsExist(root, s.suggestionIds)).map(mapLine)}
												</div>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</>
				);
			},
			)}
		</div>
	);
};

export default ScreeningSuggestions;