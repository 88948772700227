import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetSingleCase_case } from '../../../GraphQL';
import JobsItem from './JobsItem';

interface Props {
	caseData: GetSingleCase_case;
}

const JobsTable: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<table className="text-left text-xs">
				<thead>
					<tr>
						<th></th>
						<th>{t('common.deadline')}</th>
						<th>{t('jobs.assignedTo')}</th>
						<th>{t('common.description')}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{caseData.jobs?.map(j => (
						<JobsItem job={j} key={j.id} />
					))}
				</tbody>
			</table>
			{[caseData.jobs ?? []].length === 0 && <div className="text-blue text-center font-medium">{t('jobs.none')}</div>}
		</>
	);
};
export default JobsTable;
