import React from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useTranslation } from 'react-i18next';
import { TimedMessageFilter } from './TimedMessages';
import Button from '@ssg/common/Components/Button';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { GetWebCatalogCustomers_catalogCustomers } from '../../../GraphQL';
import { useForm } from 'react-hook-form';
import Pill from '@ssg/common/Components/Pill';

interface IProps {
	onFilter: React.Dispatch<React.SetStateAction<TimedMessageFilter>>;
	activeFilters: TimedMessageFilter;
	setFilterTerm: (v: string) => void;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	catalogCustomer?: GetWebCatalogCustomers_catalogCustomers;
}

const TimedMessagesFilter: React.FC<IProps> = ({ onFilter, activeFilters, setFilterTerm, catalogCustomers, catalogCustomer }) => {
	const { t } = useTranslation();

	const { control } = useForm();
	const [searchText, setSearchText] = React.useState<string>('');

	const [customerSearchText, setCustomerSearchText] = React.useState('');

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);
	React.useEffect(() => {
		setCustomers(catalogCustomers.filter(ct => ct.name.toLowerCase().includes(customerSearchText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, customerSearchText]);

	const resetFilters = () => {
		setSearchText('');
		setFilterTerm('');
		onFilter({
			fromDate: undefined,
			toDate: undefined,
			customerIds: null,
		});
	};
	if (typeof catalogCustomer !== 'undefined') return <div></div>;

	return (
		<div className="text-blue my-2 mx-0">
			<div className="flex w-full flex-row flex-wrap">
				<div className="mr-3">
					<label className="block text-xs font-medium">{t('common.search')}</label>
					<div className="flex">
						<div className="relative w-full">
							<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<Input
								name="overview-search"
								placeholder={t('catalog.timedMessage.filters.searchPlaceholder')}
								className="pr-8 text-sm lg:w-full"
								onChange={e => {
									setFilterTerm(e.target.value);
									setSearchText(e.target.value);
								}}
								value={searchText}
							/>
						</div>
					</div>
				</div>
				<div className="mr-3">
					<label className="mb-1 block text-xs font-medium">
						{t('catalog.customer')}
						<SearchableSelect
							key="customer-filter"
							control={control}
							name="customerFilter"
							options={customers}
							searchFn={searchText => setCustomerSearchText(searchText)}
							onSelect={value => {
								if (value !== '')
									onFilter(currentFilters => ({
										...currentFilters,
										customerIds: [...(activeFilters.customerIds ?? []), value],
									}));
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={-1}
							isLoading={false}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{activeFilters.customerIds?.map(d => (
							<li key={d}>
								<Pill
									onClick={() =>
										onFilter(() => ({
											...activeFilters,
											customerIds: activeFilters.customerIds?.filter(ed => ed !== d) ?? [],
										}))
									}
								>
									{catalogCustomers.filter(cc => cc.id === d)[0].name}
								</Pill>
							</li>
						))}
					</ul>
				</div>
				<div>
					<label className="block text-xs font-medium">{t('common.period')}</label>
					<div className="flex">
						<input
							type="date"
							value={formatDateForInput(activeFilters.fromDate)}
							onChange={e => {
								const minDate = e.target.valueAsDate;
								if (minDate === null || typeof minDate === 'undefined') {
									return;
								}
								minDate.setHours(0, 0, 0, 0);

								onFilter(currentFilters => ({
									...currentFilters,
									fromDate: minDate,
								}));

								if (typeof activeFilters.toDate === 'undefined') return;

								if (activeFilters.toDate) {
									if (minDate > activeFilters.toDate) {
										const maxDate = new Date(minDate);
										maxDate.setHours(23, 59, 59, 999);
										onFilter(currentFilters => ({
											...currentFilters,
											toDate: maxDate,
										}));
									}
								}
							}}
							className="border-1 rounded-default mr-3 block border-gray-600 p-1 text-sm focus:outline-none"
						/>

						<input
							type="date"
							min={formatDateForInput(activeFilters.fromDate)}
							value={formatDateForInput(activeFilters.toDate)}
							onChange={e => {
								const maxDate = e.target.valueAsDate;
								if (maxDate === null) {
									return;
								}
								maxDate.setHours(23, 59, 59, 999);

								onFilter(currentFilters => ({
									...currentFilters,
									toDate: maxDate,
								}));
							}}
							className="border-1 rounded-default mr-3 block border-gray-600 p-1 text-sm focus:outline-none"
						/>
					</div>
				</div>
				<div className="mt-4">
					<Button secondary text={t('common.resetFilter')} className="text-xs" onClick={resetFilters} />
				</div>
			</div>
		</div>
	);
};

export default TimedMessagesFilter;
