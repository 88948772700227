import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '@ssg/common/Components/Header';

const NoUser: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="mt-20 flex justify-center">
			<Header title={t('common.userNull')}>{t('common.userNullText')}</Header>
		</div>
	);
};

export default NoUser;
