import React from 'react';
import { useTranslation } from 'react-i18next';
import { faFileCsv, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { CSVLink } from 'react-csv';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { formatDate, formatTimestamp } from '@ssg/common/Helpers/Helpers';
import { GetWebCatalogs, GetWebCatalogsVariables } from '../../../GraphQL';
import { CatalogFilter } from './CatalogOverview';
import TextButton from '@ssg/common/Components/TextButton';

const GET_CATALOGS = loader('src/GraphQL/Catalogs/GetWebCatalogs.gql');

interface Props {
	activeFilters: CatalogFilter;
	searchTerm: string;
	isExternalUser: boolean;
}

interface ExportProps extends Props {
	setDownload(): unknown;
}

const ExportIt: React.FC<ExportProps> = ({ activeFilters, searchTerm, isExternalUser, setDownload }): React.ReactElement => {
	const { t } = useTranslation();

	const [csvData, setCsvData] = React.useState<CSV[] | undefined>(undefined);

	const csvRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

	const { data } = useQuery<GetWebCatalogs, GetWebCatalogsVariables>(GET_CATALOGS, {
		fetchPolicy: 'no-cache',
		variables: {
			debitors: activeFilters.debitors,
			smsService: activeFilters.smsService,
			verified: activeFilters.verified,
			debitorVerified: activeFilters.debitorVerified,
			noDebitor: activeFilters.noDebitor,
			customerIds: activeFilters.customerIds,
			thisCustomerOnly: isExternalUser,
			searchString: searchTerm.length === 0 ? null : searchTerm,
		},
	});

	const csvheader = [
		{ label: t('common.name'), key: 'name' },
		{ label: t('catalog.customer'), key: 'username' },
		{ label: t('common.debitor'), key: 'debitor' },
		{ label: t('catalog.smsService'), key: 'smsService' },
		{ label: t('catalog.addresses'), key: 'addresses' },
		{ label: t('common.contactNumber'), key: 'contactNumber' },
		{ label: t('common.contactHours'), key: 'contactHours' },
		{ label: t('catalog.verified'), key: 'verified' },
		{ label: t('catalog.debitorVerified'), key: 'debitorVerified' },
		{ label: t('common.updatedAt'), key: 'updatedAt' },
		{ label: t('catalog.filters.filters'), key: 'filters' },
	];

	interface CSV {
		name: string;
		username: string;
		debitor: string;
		smsService: string;
		addresses: string[];
		contactNumber: string;
		contactHours: string;
		verified: string;
		debitorVerified: string;
		updatedAt: string;
		filters: string;
	}

	// const csvData: CSV[] = [];

	const filterData = React.useCallback((): string => {
		const filterFactory: string[] = [];
		let filter = '';

		activeFilters.debitors?.length && filterFactory.push(t('common.debitor') + ': ' + activeFilters.debitors);
		activeFilters.customerIds?.length && filterFactory.push(t('catalog.customers'));
		activeFilters.smsService && filterFactory.push(t('catalog.smsService'));
		activeFilters.verified && filterFactory.push(t('catalog.verified'));
		activeFilters.noDebitor && filterFactory.push(t('catalog.noDebitor'));
		searchTerm.length > 0 && filterFactory.push(t('common.search') + ': ' + searchTerm);

		filterFactory.map((f, i) => {
			filter += i ? ' | ' : '';
			filter += f;
			return filter;
		});

		return filter;
	}, [activeFilters.customerIds?.length, activeFilters.debitors, activeFilters.noDebitor, activeFilters.smsService, activeFilters.verified, searchTerm, t]);

	React.useEffect(() => {
		if (typeof data !== 'undefined') {
			const tempCSVs: CSV[] = data.catalogs.map((c, i) => ({
				name: c.propertyNumber,
				username: c.customer.name,
				debitor: c.debitor ? `${c.debitor.debitorId} - ${c.debitor.company}${c.debitor.gln ? ` - ${c.debitor.gln}` : ''}` : t('catalog.noDebitor'),
				smsService: c.smsService ? t('common.yes') : t('common.no'),
				addresses:
					c.addresses?.map((a, i) => `${a.addressLine} ${a.startNumber}-${a.endNumber} (${t(`catalog.address.${a.houseNumbers}`)}) ${a.zipCode} ${a.city}`) ?? t('common.notSpecified'),
				contactNumber: c.contactNumber ? `=""${c.contactNumber}""` : t('common.notSpecified'),
				contactHours: c.contactHours ? `=""${c.contactHours}""` : t('common.notSpecified'),
				verified: c.verified ? t('common.yes') : t('common.no'),
				debitorVerified: c.debitorVerified ? t('common.yes') : t('common.no'),
				updatedAt: formatTimestamp(new Date(c.updatedAt)),
				filters: i === 0 ? filterData() : '',
			}));
			setCsvData(tempCSVs);
		}
	}, [data, filterData, t]);

	React.useLayoutEffect(() => {
		if (typeof csvData !== 'undefined') {
			window.setTimeout(() => {
				csvRef.current?.link.click();
				setDownload();
			});
		}
	}, [csvData, setDownload]);

	return (
		<>
			<TextButton link icon={faSpinner} text="common.exportForExcel" iconClassName="animate-spin" disabled />
			<div className="hidden">
				<CSVLink data={csvData ?? []} headers={csvheader} separator={';'} ref={csvRef} filename={`${t('catalog.overviewTitle')}-${formatDate(new Date())}.csv`} target="_blank" />
			</div>
		</>
	);
};

const CatalogsExport: React.FC<Props> = ({ activeFilters, searchTerm, isExternalUser }): React.ReactElement => {
	const [download, setDownload] = React.useState(false);

	return (
		<>
			{download ? (
				<ExportIt activeFilters={activeFilters} searchTerm={searchTerm} isExternalUser={isExternalUser} setDownload={() => setDownload(false)} />
			) : (
				<TextButton link icon={faFileCsv} text="common.exportForExcel" onClick={() => setDownload(true)} />
			)}
		</>
	);
};

export default CatalogsExport;
