import React from 'react';
import { Permissions } from '@ssg/common/GraphQL';
import { SelectOption, formatDate } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useFlag } from '@unleash/proxy-client-react';
import { useOpenRejectedTimeTrackingDay, useRejectedTimeTrackingsForUserQuery, useSearchUsersQuery } from '@ssg/common/TimeRegistration/TimeRegHelpers';
import { TimeTrackingDay, TimeTrackingStatus } from '@ssg/common/GraphQL/indexV2';
import Table from '@ssg/common/Components/Table';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import RejectedRegistrationModalNew from './RejectedRegistrationModalNew';


const RejectedRegistrationsOverviewNew: React.FC = () => {
	const { t } = useTranslation();
	const userContext = React.useContext(UserContext);

	const rejectedHoursUserSelect = useFlag(FeatureFlagEnums.REJECTED_HOURS_USER_SELECT);

	const [selectedDate, setSelectedDate] = React.useState('');
	const [selectedRejectedDayId, setSelectedRejectedDayId] = React.useState<string | undefined>(undefined);

	const [searchUserText, setSearchUserText] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState('');

	const { usersDataRaw, usersLoading } = useSearchUsersQuery(searchUserText, true, true);

	const searchedUsers = React.useMemo(() => usersDataRaw?.searchUsers.map((u): SelectOption => ({ value: u.id, label: u.name })) ?? [], [usersDataRaw]);

	const userId = selectedUser ? selectedUser : userContext.user?.id ?? '';

	const { control: userListControl } = useForm();

	const { openRejectedTimeTrackingDay, openRejectedTimeTrackingDayLoading } = useOpenRejectedTimeTrackingDay();

	const { rejectedTimeTrackingDataRaw, rejectedTimeTrackingLoading, refetchRejectedTimeTrackings } = useRejectedTimeTrackingsForUserQuery(userId, true);

	const rejectedTimeTrackings = React.useMemo(() => {
		return rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser ?? [];
	}, [rejectedTimeTrackingDataRaw]);

	const selectedRejectedDay = React.useMemo(() => {
		return rejectedTimeTrackingDataRaw?.rejectedTimeTrackingsDayForUser.find(d => d.id === selectedRejectedDayId);
	}, [rejectedTimeTrackingDataRaw, selectedRejectedDayId]);

	const selectedUserObj = usersDataRaw?.searchUsers.find(u => u.id === userId);

	const rejectedUserContext = {
		userId: userId,
		salaryType: selectedUser ? selectedUserObj?.employeeSalaryType ?? null : userContext.user?.employeeSalaryType ?? null,
		hireDate: selectedUser ? selectedUserObj?.employeeHireStartDate ?? null : userContext.user?.employeeHireStartDate ?? null,
	};

	return (
		<div>
			<BoxContainer>
				<div className="flex w-full">
					<Box full>
						{rejectedHoursUserSelect && userContext.user?.permissions.includes(Permissions.TIME_TRACKING_DATE_PICKER) && (
							<div className="mb-4 w-64">
								<SearchableSelect
									control={userListControl}
									name="user"
									title="common.employeeName"
									options={searchedUsers.filter(u => u.label.toLowerCase().includes(searchUserText.toLowerCase()))}
									searchFn={searchText => setSearchUserText(searchText)}
									onSelect={value => setSelectedUser(value)}
									onBlur={() => undefined}
									minInputLength={-1}
									isLoading={usersLoading}
								/>
								<p className="text-xs font-semibold">
									{t('common.selectedSubstituteNo')}: {usersDataRaw?.searchUsers.filter(u => u.id === selectedUser)[0]?.employeeId ?? ''}
								</p>
							</div>
						)}
						<div>
							<Table
								data={rejectedTimeTrackings ?? []}
								columns={[
									{
										label: 'common.date',
										numeric: true,
										selectFn: t => <p>{formatDate(new Date(t.date))}</p>,
										sortFn: (a, b) => a.date.localeCompare(b.date),
									},
									{
										label: 'timeRegistration.rejectedTotal',
										noTruncate: true,
										selectFn: t => <p>{t.timeTrackingEntries.filter(e => e.timeTrackingStatus === TimeTrackingStatus.Rejected || e.addonLines.some(a => a.timeTrackingStatus === TimeTrackingStatus.Rejected)).length} / {t.timeTrackingEntries.length}</p>,
									},
									{
										label: 'common.hours',
										selectFn: t => <p>{t.totalHours}</p>,
									},
								]}
								keySelector={tb => tb.id}
								onRowClick={async tb => {
									if (openRejectedTimeTrackingDayLoading) {
										return;
									}

									try {
										await openRejectedTimeTrackingDay({
											dayId: tb.id,
											userEmail: rejectedHoursUserSelect ? userId : userContext.user?.id ?? '',
											date: tb.date,
										});
										setSelectedDate(tb.date);
										setSelectedRejectedDayId(tb.id);
									} catch (e) {
										console.log(e);
										alert(t('error.timereg'));
									}
								}}
								noDataFoundText="timeRegistration.noTimeRegFound"
								loading={rejectedTimeTrackingLoading}
							/>
						</div>
					</Box>
				</div>
			</BoxContainer>

			{selectedDate !== '' && typeof selectedRejectedDay !== 'undefined' && (
				<RejectedRegistrationModalNew
					date={selectedDate}
					open={true}
					close={() => setSelectedDate('')}
					data={selectedRejectedDay as TimeTrackingDay}
					userContext={rejectedUserContext}
					refetchRejected={refetchRejectedTimeTrackings}
				/>
			)}
		</div>
	);
};

export default RejectedRegistrationsOverviewNew;
