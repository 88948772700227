import React from 'react';
import { useMutation } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeleteCatalog, DeleteCatalogVariables, GetCatalog_catalog } from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import TextButton from '@ssg/common/Components/TextButton';

const DELETE_CATALOG = loader('src/GraphQL/Catalogs/DeleteCatalog.gql');

interface Props {
	catalog: GetCatalog_catalog;
	changedBy: string;
}

const CatalogDeleteModal: React.FC<Props> = ({ catalog, changedBy }) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = React.useState(false);
	const history = useHistory();

	const [deleteCatalog, { loading: loadingDelete }] = useMutation<DeleteCatalog, DeleteCatalogVariables>(DELETE_CATALOG);

	const deleteCT = async () => {
		const deleted = await deleteCatalog({
			variables: {
				id: catalog.id,
				changedBy: changedBy,
			},
		});

		if (deleted.data) {
			history.push('/catalogoverview');
		} else {
			console.log(deleted.errors);
		}
	};

	return (
		<>
			<TextButton text="catalog.delete" icon={faTrashAlt} onClick={() => setShowModal(true)} />

			<Modal
				title="catalog.delete"
				size={ModalSize.MEDIUM}
				visible={showModal}
				close={() => setShowModal(false)}
				body={
					<div>
						<FormFieldHeader title="catalog.wantToDelete" />
						<div className="flex flex-col">
							<div>{`${t('catalog.propertyNumber')}: ${catalog.propertyNumber}, ${t('catalog.customer')}: ${catalog.customer.name}`}</div>

							<Button
								danger
								text="common.delete"
								onClick={() => {
									deleteCT();
								}}
								className="mt-4"
								loading={loadingDelete}
								disabled={loadingDelete}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default CatalogDeleteModal;
