import { faLockOpen } from '@fortawesome/pro-regular-svg-icons';
import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';
import TextButton from '@ssg/common/Components/TextButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStatus } from '../../GraphQL';

interface Props {
	changeCaseStatus(status: CaseStatus): void;
	loading: boolean;
}

const ReopenCaseButton: React.FC<Props> = ({ changeCaseStatus, loading }) => {
	const { t } = useTranslation();

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	return (
		<>
			<TextButton className="ml-3" text="case.reopenCase" onClick={() => setShowConfirmModal(true)} icon={faLockOpen} />
			<Modal
				title="case.confirmReopen"
				close={() => setShowConfirmModal(false)}
				visible={showConfirmModal}
				body={<div>{t('case.confirmReopenMessage')}</div>}
				footer={
					<div className="flex space-x-2">
						<Button text="case.reopenCase" onClick={() => changeCaseStatus(CaseStatus.OPEN)} loading={loading} success />
						<Button text="common.cancel" onClick={() => setShowConfirmModal(false)} secondary />
					</div>
				}
			/>
		</>
	);
};

export default ReopenCaseButton;
