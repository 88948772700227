import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetCatalogChanges_catalog_changes_after_craftsmen, GetCatalogChanges_catalog_changes_before_craftsmen } from '../../../GraphQL';
import _ from 'lodash';

interface IProps {
	before: GetCatalogChanges_catalog_changes_before_craftsmen | null;
	after: GetCatalogChanges_catalog_changes_after_craftsmen | null;
}

const CatalogCraftsmenChangeLog: React.FC<IProps> = ({ before, after }) => {
	const { t } = useTranslation();

	return (
		<div>
			{!after && before && (
				<div>
					<strong>
						{t('catalog.craftsman.overviewTitle')} {t('common.added')}:
					</strong>
					{before.type && before.type.length > 0 && (
						<div>
							{t('common.type')}: {before.type}
						</div>
					)}
					{before.contactName && before.contactName.length > 0 && (
						<div>
							{t('catalog.contact.contactName')}: {before.contactName}
						</div>
					)}
					{before.email && before.email.length > 0 && (
						<div>
							{t('catalog.contact.email')}: {before.email}
						</div>
					)}
					{before.globalType && (
						<div>
							{t('catalog.globalType')}: {t(`catalog.changelog.${before.globalType}`)}
						</div>
					)}
					{before.informations && before.informations.length > 0 && (
						<div>
							<div>
								{t('catalog.contact.phoneNumbers')}:
								{before.informations.map((info, index) => (
									<div key={`${info?.phoneNumber}-${index}`}>
										<div>
											{t('common.phone')}: {info?.phoneNumber}
										</div>
										<div>
											{t('catalog.contact.remark')}: {info?.remark}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{before.priority && before.priority.length > 0 && (
						<div>
							{t('catalog.craftsman.priority')}: {before.priority}
						</div>
					)}
				</div>
			)}
			{after && !before && (
				<div>
					<strong>
						{t('catalog.craftsman.overviewTitle')} {t('common.removed')}:
					</strong>
					{after.type && after.type.length > 0 && (
						<div>
							{t('common.type')}: {after.type}
						</div>
					)}
					{after.contactName && after.contactName.length > 0 && (
						<div>
							{t('catalog.contact.contactName')}: {after.contactName}
						</div>
					)}
					{after.email && after.email.length > 0 && (
						<div>
							{t('catalog.contact.email')}: {after.email}
						</div>
					)}
					{after.globalType && (
						<div>
							{t('catalog.globalType')}: {t(`catalog.changelog.${after.globalType}`)}
						</div>
					)}
					{after.informations && after.informations.length > 0 && (
						<div>
							<div>
								{t('catalog.contact.phoneNumbers')}:
								{after.informations.map((info, index) => (
									<div key={`${info?.phoneNumber}-${index}`}>
										<div>
											{t('common.phone')}: {info?.phoneNumber}
										</div>
										<div>
											{t('catalog.contact.remark')}: {info?.remark}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{after.priority && after.priority.length > 0 && (
						<div>
							{t('catalog.craftsman.priority')}: {after.priority}
						</div>
					)}
				</div>
			)}
			{after && before && (
				<div>
					<div className="flex flex-row font-bold">
						<div>{t('catalog.craftsman.contactName')}&nbsp;</div>
						<div className="bg-yellow-mark text-black">"{after.contactName}"</div>
						<div>&nbsp;{t('common.changed')}:</div>
					</div>
					{before.type !== after.type && (
						<div className="flex flex-row">
							{t('common.type')} {t('common.changedFrom')} {before.type} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.type}</div>
						</div>
					)}
					{before.contactName !== after.contactName && (
						<div className="flex flex-row">
							{t('catalog.contact.contactName')} {t('common.changedFrom')} {before.contactName} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.contactName}</div>
						</div>
					)}
					{before.email !== after.email && (
						<div className="flex flex-row">
							{t('catalog.contact.email')} {t('common.changedFrom')} {before.email} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.email}</div>
						</div>
					)}
					{before.globalType !== after.globalType && (
						<div className="flex flex-row">
							{t('catalog.globalType')} {t('common.changedFrom')} {t(`catalog.changelog.${before.globalType}`)} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{t(`catalog.changelog.${after.globalType}`)}</div>
						</div>
					)}
					{before.informations && before.informations.length === 0 && after.informations && (
						<div>
							{t('catalog.contact.phoneNumbers')} {t('common.added')}:
							{after.informations &&
								after.informations.map((info, index) => (
									<div className="flex flex-row" key={`${info?.phoneNumber}-${index}`}>
										<div className="flex flex-row">
											{t('common.phone')}: &nbsp;
											{info?.phoneNumber}&nbsp;
										</div>
										<div className="flex flex-row">
											{t('catalog.contact.remark')}: &nbsp;{info?.remark}
										</div>
									</div>
								))}
						</div>
					)}
					{before.informations && after.informations && after.informations.length === 0 && (
						<div>
							{t('catalog.contact.phoneNumbers')} {t('common.removed')}:
							{before.informations &&
								before.informations.map((info, index) => (
									<div className="flex flex-row" key={`${info?.phoneNumber}-${index}`}>
										<div>
											{t('common.phone')}: &nbsp;
											{info?.phoneNumber}&nbsp;
										</div>
										<div>
											{t('catalog.contact.remark')}: &nbsp;{info?.remark}
										</div>
									</div>
								))}
						</div>
					)}
					{before.informations && after.informations && _.difference(before.informations, after.informations).length > 0 && (
						<div>
							<div>
								{t('catalog.contact.phoneNumbers')} {t('common.changedFrom')}:
								{before.informations &&
									before.informations.map((info, index) => (
										<div className="flex flex-row" key={`${info?.phoneNumber}-${index}`}>
											<div>
												{t('common.phone')}: &nbsp;
												{info?.phoneNumber}
												&nbsp;
											</div>
											<div>
												{t('catalog.contact.remark')}: &nbsp;{info?.remark}
											</div>
										</div>
									))}
							</div>
							<div>
								{t('common.to')}:
								{after.informations &&
									after.informations.map((info, index) => (
										<div className="flex flex-row" key={`${info?.phoneNumber}-${index}`}>
											<div className="flex flex-row">
												{t('common.phone')}: &nbsp;
												<div className={before.informations && before.informations[index]?.phoneNumber !== info?.phoneNumber ? 'bg-yellow-mark flex-row text-black' : ''}>
													{info?.phoneNumber}
												</div>
												&nbsp;
											</div>
											<div className="flex flex-row">
												{t('catalog.contact.remark')}: &nbsp;
												<div className={before.informations && before.informations[index]?.remark !== info?.remark ? 'bg-yellow-mark flex-row text-black' : ''}>
													{info?.remark}
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					)}
					{before.active !== after.active && (
						<div className="flex flex-row">
							{t('common.active')} {t('common.changedFrom')} {before.active} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.active}</div>
						</div>
					)}
					{before.priority !== after.priority && (
						<div className="flex flex-row">
							{t('catalog.craftsman.priority')} {t('common.changedFrom')} {before.priority} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.priority}</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CatalogCraftsmenChangeLog;
