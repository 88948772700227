import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const CustomTooltipUsage: React.FC<TooltipProps<ValueType, NameType>> = ({ payload, label }) => {
	const { t } = useTranslation();

	if (payload && payload.length > 0) {
		return (
			<div
				className="custom-tooltip ml-2 bg-white"
				style={{
					border: '1px solid rgb(224,224,224)',
				}}
			>
				<div className="m-3">
					<p className="label">{label}</p>
					{payload[0].value !== 0 && payload[0].payload.isCase ? (
						<p className="label" style={{ color: '#BA5BEF' }}>{`${t('machines.consumptionOnCase')} : ${payload[0].value} Kwh`}</p>
					) : (
						<p className="label" style={{ color: '#BA5BEF' }}>{`${t('machines.consumptionOnLocation')} : ${payload[0].value} Kwh`}</p>
					)}
					{payload[0].payload.totalConsumptionCase && (
						<p className="label" style={{ color: '#d9b8c4' }}>{`${t('machines.totalConsumptionCase')} : ${payload[0].payload.totalConsumptionCase} Kwh`}</p>
					)}
					<p className="label" style={{ color: '#66334f' }}>{`${t('machines.totalConsumption')} : ${payload[0].payload.totalConsumption} Kwh`}</p>
					<p className="desc">{`${t('common.period')} : ${payload[0].payload.tickText}`}</p>
				</div>
			</div>
		);
	}

	return null;
};

export default CustomTooltipUsage;
