import React from 'react';

export default function useDebouncedState<T>(defaultValue: T, ms: number): [T, (v: T) => void] {
	const [value, setValue] = React.useState<T>(defaultValue);
	const timeoutRef = React.useRef<number>();

	const setValueDebounced = React.useCallback(
		(v: T): void => {
			if (typeof timeoutRef.current === 'number') {
				window.clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = window.setTimeout((): void => setValue(v), ms);
		},
		[ms],
	);

	return [value, setValueDebounced];
}
