import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faFileUpload, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FileActionType,
	DeleteDrivingSlipFile,
	DeleteDrivingSlipFileVariables,
	GetWebDrivingSlipFile,
	GetDrivingSlipFiles,
	GetDrivingSlipFilesVariables,
	GetWebDrivingSlipFileVariables,
	UploadDrivingSlipFiles,
	UploadDrivingSlipFilesVariables,
	LogDrivingSlipFileChange,
	LogDrivingSlipFileChangeVariables,
	FileInput,
} from '../../GraphQL';
import { toBase64, stringToByteArray } from '@ssg/common/Helpers/inputFileHelper';
import { Permissions } from '@ssg/common/GraphQL';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import Loading from '@ssg/common/Components/Loading';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Box from '../../Components/Layout/Box';
import UserContext from '../../UserContext';
import AddExistingFileToDrivingSlip from './AddExistingFileToDrivingSlip';
import TextButton from '@ssg/common/Components/TextButton';

const GET_FILE = loader('src/GraphQL/Files/GetWebDrivingSlipFile.gql');
const LOG_FILE_CHANGE = loader('src/GraphQL/Files/LogDrivingSlipFileChange.gql');
const GET_DRIVING_SLIP_FILES = loader('src/GraphQL/Files/GetDrivingSlipFiles.gql');
const DELETE_FILE = loader('src/GraphQL/Files/DeleteDrivingSlipFile.gql');
const UPLOAD_FILES = loader('src/GraphQL/Files/UploadDrivingSlipFiles.gql');

interface Props {
	open: boolean;
	close: () => void;
	series: string;
	caseErpNo: string;
}

interface FileProps {
	fileName: string;
	folder: string;
}

const DrivingSlipFileBox: React.FC<Props> = ({ series, caseErpNo, open, close }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const canDelete = userContext.user?.permissions.includes(Permissions.CASES_DOCUMENTS_DELETE);
	const canEdit = userContext.user?.permissions.includes(Permissions.CASES_DRIVING_SLIPS_EDIT);

	const { data: dsFiles, loading: dsFilesLoading } = useQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>(GET_DRIVING_SLIP_FILES, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-only',
		variables: {
			caseNo: caseErpNo,
			drivingSlipId: series,
		},
	});

	const files = React.useMemo(() => dsFiles?.drivingSlipFiles ?? [], [dsFiles]);
	const fileNames = React.useMemo(() => dsFiles?.drivingSlipFiles?.map(file => file.name) ?? [], [dsFiles]);
	const [fileData, setFileData] = React.useState<FileProps>({
		fileName: '',
		folder: '',
	});

	const { data: file, loading: fileLoading } = useQuery<GetWebDrivingSlipFile, GetWebDrivingSlipFileVariables>(GET_FILE, {
		fetchPolicy: 'cache-and-network',
		variables: {
			caseNo: caseErpNo,
			drivingSlipId: series,
			fileName: fileData.fileName,
		},
		skip: fileData.fileName === '',
	});

	const [deleteDrivingSlipFile, { loading: loadingDeleteFile }] = useMutation<DeleteDrivingSlipFile, DeleteDrivingSlipFileVariables>(DELETE_FILE);
	const [uploadDrivingSlipFiles, { loading: loadingUploadFiles }] = useMutation<UploadDrivingSlipFiles, UploadDrivingSlipFilesVariables>(UPLOAD_FILES);

	const [logFileChange] = useMutation<LogDrivingSlipFileChange, LogDrivingSlipFileChangeVariables>(LOG_FILE_CHANGE);

	const [showAddFilesModal, setAddShowFilesModal] = React.useState(false);

	async function deleteFile(fileName: string) {
		await deleteDrivingSlipFile({
			variables: {
				caseNo: caseErpNo,
				drivingSlipId: series,
				fileName: fileName,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseErpNo,
						drivingSlipId: series,
					},
				});

				if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
					return;
				}

				cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseErpNo,
						drivingSlipId: series,
					},
					data: {
						drivingSlipFiles: cachedRequest.drivingSlipFiles.filter(doc => doc.name !== fileName),
					},
				});
			},
		});

		await logFileChange({
			variables: {
				filename: fileName,
				action: FileActionType.REMOVE,
				series: series,
			},
		});
	}

	async function uploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files || e.target.files.length === 0) {
			alert(t('error.fileMissing'));
			return;
		}

		const returnValues: FileInput[] = [];

		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files[i];
			const fileAsString = await toBase64(file);
			const result = stringToByteArray(fileAsString);

			returnValues.push({ fileData: result, filename: file.name });
		}

		await uploadDrivingSlipFiles({
			variables: {
				caseNo: caseErpNo,
				drivingSlipId: series,
				files: returnValues,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseErpNo,
						drivingSlipId: series,
					},
				});

				if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
					return;
				}

				cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseErpNo,
						drivingSlipId: series,
					},
					data: {
						drivingSlipFiles: [...cachedRequest.drivingSlipFiles, ...cacheData.uploadDrivingSlipFiles],
					},
				});
			},
		});

		await logFileChange({
			variables: {
				filename: returnValues.map(val => `${val.filename}`).join(', '),
				action: FileActionType.ADD,
				series: series,
			},
		});
	}

	React.useEffect(() => {
		if (file?.drivingSlipFile) {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(file.drivingSlipFile.fileData)]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${file.drivingSlipFile.filename}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
		}
	}, [fileData, file]);

	return (
		<Modal
			title="common.files"
			size={ModalSize.XLARGE}
			visible={open}
			close={close}
			body={
				<Box title="" full className="text-blue">
					<div className="text-blue flex justify-end">
						<div>
							{canEdit && (
								<div className="flex flex-row">
									<TextButton text="drivingSlips.addFilesFromCase" icon={faFileUpload} className="mr-3" onClick={() => setAddShowFilesModal(true)} />
									<label className="rounded-default text-blue flex cursor-pointer flex-col items-center p-1 focus:outline-none">
										<span>
											<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
											{t('case.chooseFiles')}
										</span>
										<input type="file" autoComplete="nope" className="hidden" onChange={e => uploadFiles(e)} multiple />
									</label>
								</div>
							)}
						</div>
					</div>
					{fileLoading || typeof files === 'undefined' || loadingDeleteFile || loadingUploadFiles || dsFilesLoading ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<div>
							<Table
								data={files ?? []}
								columns={[
									{
										label: 'common.name',
										selectFn: f => <p>{f.name.substring(0, f.name.lastIndexOf('.'))}</p>,
									},
									{
										label: 'catalog.fileName',
										selectFn: f => (
											<div>
												<TextButton
													text={f.name}
													onClick={() =>
														setFileData({
															fileName: f.name,
															folder: series,
														})
													}
												/>
											</div>
										),
									},
									{
										label: 'common.updatedAt',
										selectFn: f => <p className="py-1">{dateToDateTimeString(f.metadata.find(m => m.key === 'Modified')?.value ?? '')}</p>,
									},
									{
										label: t('common.remove'),
										classNameTh: 'text-right',
										hideColumn: !canDelete,
										selectFn: f => (
											<div>
												<div className="text-red flex content-start justify-end text-right">
													<FontAwesomeIcon
														icon={faTrashAlt}
														size="lg"
														onClick={() => {
															deleteFile(f.name);
														}}
														className="cursor-pointer"
													/>
												</div>
											</div>
										),
									},
								]}
								keySelector={f => f.name}
								noDataFoundText="catalog.noFiles"
							/>
							<div>
								<AddExistingFileToDrivingSlip open={showAddFilesModal} close={() => setAddShowFilesModal(false)} series={series} caseErpNo={caseErpNo} excludeFileNames={fileNames} />
							</div>
						</div>
					)}
				</Box>
			}
		/>
	);
};

export default DrivingSlipFileBox;
