import React, { useContext } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, FieldMergeFunction, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { useMicrosoftAuth } from './MicrosoftAuth';
import { createErrorLink } from '@ssg/common/Helpers/Helpers';
import { GraphQLExtensionsApi } from './GraphQLExtensionsContext';
import useErrorHotToaster from '@ssg/common/Hooks/useErrorHotToaster';

const merge: FieldMergeFunction<Array<{ __ref: string }>> = (existing = [], incoming) => {
	const incomingIds = incoming.map(x => x.__ref);
	return [...existing.filter(x => !incomingIds.includes(x.__ref)), ...incoming];
};

const drivingSlipMerge: FieldMergeFunction<Array<{ __ref: string }>> = (existing = [], incoming, { field }) => {
	const alias = field?.alias;
	if (alias && alias.value === 'plannerDrivingSlips') {
		return [...incoming];
	}
	const incomingIds = incoming.map(x => x.__ref);
	return [...existing.filter(x => !incomingIds.includes(x.__ref)), ...incoming];
};

const cache = new InMemoryCache({
	typePolicies: {
		Screening: {
			keyFields: false, // Don't write this data to cache since we can't make a proper id
		},
		ScreeningLine: {
			keyFields: false, // Don't write this data to cache since we can't make a proper id
		},
		CaseEconomicsOverview: {
			keyFields: ['jobNo'],
		},
		Debitor: {
			keyFields: false, // Don't write this data to cache since the police number can be different on debitors with the same id
		},
		Vendor: {
			keyFields: ['erpReferenceNo'],
		},
		Machine: {
			keyFields: ['erpReferenceNo'], // Don't write this data to cache since we can't make a proper id
		},
		Vehicle: {
			keyFields: ['vehicleNumber'],
		},
		Query: {
			fields: {
				cases: {
					/* 
					Key arguments of the cases query decides whether new data should be cached. All arguments are included in the array except offset and limit, because these are used for pagination and therefore only data from the fetchMore should be cached. 
					Links for more info: 
						KeyArgs: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#specifying-key-arguments
						Pagination: https://www.apollographql.com/docs/react/pagination/core-api
					*/
					keyArgs: [
						'address',
						'awaiting',
						'damageServiceCompleted',
						'calledBack',
						'machinesOnCase',
						'ownCases',
						'readyForInvoice',
						'appliedClosed',
						'favoriteCases',
						'closedCases',
						'priorityCustomers',
						'debitor',
						'postalCode',
						'track',
						'department',
						'location',
						'damageCategory',
						'damageCause',
						'priority',
						'caseManager',
						'projectManager',
						'anyManager',
						'minDate',
						'maxDate',
						'erpReferenceNo',
						'erpReferenceNos',
						'includeEconomy',
						'searchString',
						'favoriteColors',
					],
					merge,
				},
				machines: {
					keyArgs: ['status', 'cases', 'locations', 'types', 'unavailable', 'hibernation', 'reservationByRessource', 'searchString'],
					merge,
				},
				movables: {
					keyArgs: ['case', 'status', 'location', 'includeCompleted', 'searchString', 'debitors', 'fromDate', 'toDate'],
					merge,
				},
				drivingSlips: {
					keyArgs: [
						'personalOnly',
						'dateRange',
						'status',
						'urgent',
						'exceeded',
						'noDriver',
						'departments',
						'locations',
						'damageCauses',
						'damageCategories',
						'drivers',
						'debitor',
						'priorityCustomers',
						'minStartDate',
						'maxStartDate',
						'minEndDate',
						'maxEndDate',
						'case',
						'caseERPReferenceNo',
						'postalCode',
						'debitors',
						'searchString',
						'seriesId',
					],
					merge: drivingSlipMerge,
				},
				catalogs: {
					keyArgs: ['smsService', 'verified', 'debitorVerified', 'noDebitor', 'debitors', 'address', 'thisCustomerOnly', 'searchString', 'customerIds'],
					merge,
				},
				requisitions: {
					keyArgs: ['myRequisitionsOnly', 'caseId', 'departments', 'vendor', 'status', 'type', 'fromDate', 'toDate', 'damageCategories', 'damageCauses', 'searchString'],
					merge,
				},
				catalogContacts: {
					keyArgs: ['globalOnly', 'catalogId', 'customerIds', 'contactType', 'thisCustomerOnly', 'searchString'],
					merge,
				},
				catalogCraftsmen: {
					keyArgs: ['globalOnly', 'catalogId', 'customerIds', 'thisCustomerOnly', 'searchString'],
					merge,
				},
				globalCatalogFiles: {
					keyArgs: ['thisCustomerOnly', 'searchString', 'customerIds'],
					merge,
				},
				timeTrackingEntries: {
					merge(existing = [], incoming) {
						return [incoming];
					},
				},
				vehicles: {
					keyArgs: ['isCar', 'dateRange', 'locations', 'departments'],
				}
			},
		},
	},
});

const ApolloAuthenticationWrapper: React.FC<{
	graphUrl: string;
	onError: (errorMessage: string) => void;
}> = ({ graphUrl, onError, children }) => {
	const { getTokens } = useMicrosoftAuth();
	const setGraphQLExtensions = useContext(GraphQLExtensionsApi);

	const authLink = setContext(async (_, { headers }) => {
		// get the authentication token from local storage if it exists
		const { appToken, bcToken } = await getTokens();
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: appToken ? `Bearer ${appToken}` : '',
				'bc-token': bcToken ?? '',
			},
		};
	});

	const batchHttpLink = new BatchHttpLink({
		uri: graphUrl,
		credentials: 'same-origin',
	});

	const httpLink = new HttpLink({
		uri: graphUrl,
		credentials: 'same-origin',
	});

	const errorHotToaster = useErrorHotToaster();
	const errorLink = createErrorLink(graphError => {
		onError(graphError.message);
		errorHotToaster(graphError);
	}, true);

	const graphQLExtensionsLink = new ApolloLink((operation, forward) => {
		return forward(operation).map(response => {
			if (response.extensions) {
				setGraphQLExtensions(current => ({
					...current,
					...response.extensions,
				}));
			}

			return response;
		});
	});

	const link = ApolloLink.from([graphQLExtensionsLink, authLink, errorLink]).split(operation => operation.getContext().debatch === true, authLink.concat(httpLink), authLink.concat(batchHttpLink));

	const apolloClient = new ApolloClient({
		cache,
		link,
	});

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloAuthenticationWrapper;
