import Table from '@ssg/common/Components/Table';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetSingleCase_case } from '../../../GraphQL';

interface Props {
	caseData: GetSingleCase_case;
}

const RequisitionsTab: React.FC<Props> = ({ caseData }): React.ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<Table
				data={caseData.requisitions}
				keySelector={r => r.id}
				noDataFoundText={'requisitions.noRequisitions'}
				columns={[
					{
						label: 'requisitions.requisitionId',
						selectFn: r => r.orderNumber ?? '',
						sortFn: (a, b) => (a.orderNumber ?? '').localeCompare(b.orderNumber ?? ''),
						numeric: true,
					},
					{
						label: 'common.status',
						selectFn: r => <p>{r.status ? t('requisitions.status.OPEN') : t('requisitions.status.CLOSED')}</p>,
					},
					{
						label: 'common.drivingSlip',
						selectFn: r => (r.drivingSlip ? `${r.drivingSlip?.case.damage.category.name} - ${r.drivingSlip?.case.damage.category.name}` : ''),
						sortFn: (a, b) => (a.drivingSlip?.case.erpNo ?? '').localeCompare(b.drivingSlip?.case.erpNo ?? ''),
					},
					{
						label: 'common.subcontractor',
						selectFn: r => {
							if (r.vendor) {
								return (
									<div>
										<p className="font-semibold">{r.vendor.company}</p>
										<p>
											<a href={`mailto:${r.vendor.email}`} className="hover:underline">
												{r.vendor.email}
											</a>
										</p>
										<p>
											<a href={`tel:${r.vendor.phone}`} className="hover:underline">
												{r.vendor.phone}
											</a>
										</p>
									</div>
								);
							} else {
								return (
									<div>
										<p className="font-semibold">{r.vendorName}</p>
										<p>
											<a href={`mailto:${r.vendorEmail}`} className="hover:underline">
												{r.vendorEmail}
											</a>
										</p>
										<p>
											<a href={`tel:${r.vendorPhoneNumber}`} className="hover:underline">
												{r.vendorPhoneNumber}
											</a>
										</p>
									</div>
								);
							}
						},
						sortFn: (a, b) => (a.vendor?.company ?? a.vendorName).localeCompare(b.vendor?.company ?? b.vendorName),
					},
					{
						label: 'common.description',
						selectFn: r => <div className="whitespace-normal">{r.description}</div>,
					},
					{
						label: 'common.type',
						selectFn: r => <div>{t(`requisitions.${r.type}`)}</div>,
						sortFn: (a, b) => a.type.localeCompare(b.type),
					},
					{
						label: 'common.created',
						selectFn: r => dateToDateTimeString(r.createdAt),
						sortFn: (a, b) => a.createdAt.localeCompare(b.createdAt),
					},
					{
						label: 'common.createdBy',
						selectFn: r => (
							<div>
								<p className="font-semibold">{r.createdBy.name}</p>
								<p>
									<a href={`mailto:${r.createdBy.email}`} className="hover:underline">
										{r.createdBy.email}
									</a>
								</p>
								<p>
									<a href={`tel:${r.createdBy.phone}`} className="hover:underline">
										{r.createdBy.phone}
									</a>
								</p>
							</div>
						),
						sortFn: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
					},
				]}
			/>
			{caseData.requisitions.length === 0 && <div className="text-blue text-center font-medium">{t('requisitions.noRequisitions')}</div>}
		</>
	);
};

export default RequisitionsTab;
