import React from 'react';
import PlannerDrivingSlips from './PlannerDrivingSlips';
import PlannerEmployees from './PlannerEmployees';
import PlannerCars from './PlannerCars';
import PlannerMaterials from './PlannerMaterials';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import PlannerTabs from './PlannerTabs';
import { SelectedUser, TABS_WIDTH, useCalendarEvents } from './PlannerHelpers';
import PlannerSchedule from './PlannerSchedule';
import PlannerContext, { PlannerContextProvider } from './PlannerContext';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';

const tabs = ['planner.employees', 'drivingSlips.overviewTitle', 'planner.cars', 'planner.materials'];



const PlannerPage: React.FC = (): React.ReactElement => {
	const [activeTab, setActiveTab] = useStorageState<string>(window.sessionStorage, 'plannerActiveTab', tabs[0]);
	const NewPlannerVehiclesFlag = useFlag(FeatureFlagEnums.NEW_PLANNER_VEHICLES);
	const { selectedUsers } = React.useContext(PlannerContext);
	return (
		<div className="flex h-screen flex-row overflow-hidden">
			<div className="select-none px-4 pt-12 pb-10" style={{ width: TABS_WIDTH }}>
				<PlannerTabs
					titles={NewPlannerVehiclesFlag ? tabs : ['planner.employees', 'drivingSlips.overviewTitle']}
					count={[selectedUsers.length]}
					active={activeTab}
					onClick={setActiveTab}
				>

					{activeTab === 'planner.employees' &&
						<PlannerEmployees />
					}
					{activeTab === 'drivingSlips.overviewTitle' &&
						<PlannerDrivingSlips />
					}
					{activeTab === 'planner.cars' &&
						<PlannerCars
						/>
					}
					{activeTab === 'planner.materials' &&
						<PlannerMaterials
						/>
					}

				</PlannerTabs>
			</div>
			<PlannerSchedule />
		</div>
	);
};

export default PlannerPage;