import React from 'react';
import { formatDate } from '@ssg/common/Helpers/Helpers';
import { useTranslation } from 'react-i18next';
import { GetCatalogChanges_catalog_changes_after_timedMessages, GetCatalogChanges_catalog_changes_before_timedMessages } from '../../../GraphQL';

interface IProps {
	before: GetCatalogChanges_catalog_changes_before_timedMessages | null;
	after: GetCatalogChanges_catalog_changes_after_timedMessages | null;
}

const CatalogTimedMessageChangeLog: React.FC<IProps> = ({ before, after }) => {
	const { t } = useTranslation();

	return (
		<div>
			{!after && before && (
				<div>
					<strong>
						{t('catalog.timedMessage.information')} {t('common.added')}:
					</strong>
					{before.startTime && (
						<div>
							{t('catalog.timedMessage.startTime')}: {formatDate(new Date(before.startTime))}
						</div>
					)}
					{before.endTime && (
						<div>
							{t('catalog.timedMessage.endTime')}: {formatDate(new Date(before.endTime))}
						</div>
					)}
					{before.message && before.message.length > 0 && (
						<div>
							{t('common.message')}: {before.message}
						</div>
					)}
					{before.globalType && (
						<div>
							{t('catalog.globalType')}: {t(`catalog.changelog.${before.globalType}`)}
						</div>
					)}
				</div>
			)}
			{after && !before && (
				<div>
					<strong>
						{t('catalog.timedMessage.information')} {t('common.removed')}:
					</strong>
					{after.startTime && (
						<div>
							{t('catalog.timedMessage.startTime')}: {formatDate(new Date(after.startTime))}
						</div>
					)}
					{after.endTime && (
						<div>
							{t('catalog.timedMessage.endTime')}: {formatDate(new Date(after.endTime))}
						</div>
					)}
					{after.message && after.message.length > 0 && (
						<div>
							{t('common.message')}: {after.message}
						</div>
					)}
					{after.globalType && (
						<div>
							{t('catalog.globalType')}: {t(`catalog.changelog.${after.globalType}`)}
						</div>
					)}
				</div>
			)}
			{after && before && (
				<div>
					<strong>
						{t('catalog.timedMessage.information')} {t('common.changed')}:
					</strong>
					<div className="flex flex-row">
						{t('catalog.timedMessage.startTime')} {t('common.changedFrom')} {formatDate(new Date(before.startTime ?? ''))} {t('common.to')} &nbsp;
						<div className={before.startTime !== after.startTime ? 'bg-yellow-mark flex-row text-black' : ''}>{formatDate(new Date(after.startTime ?? ''))}</div>
					</div>
					<div className="flex flex-row">
						{t('catalog.timedMessage.endTime')} {t('common.changedFrom')} {formatDate(new Date(before.endTime ?? ''))} {t('common.to')} &nbsp;
						<div className={before.endTime !== after.endTime ? 'bg-yellow-mark flex-row text-black' : ''}>{formatDate(new Date(after.endTime ?? ''))}</div>
					</div>
					<div className="flex flex-row">
						{t('common.message')} {t('common.changedFrom')} {before.message} {t('common.to')} &nbsp;
						<div className={before.message !== after.message ? 'bg-yellow-mark flex-row text-black' : ''}>{after.message}</div>
					</div>
					{before.active !== after.active && (
						<div className="flex flex-row">
							{t('common.active')} {t('common.changedFrom')} {before.active} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{after.active}</div>
						</div>
					)}
					{before.globalType !== after.globalType && (
						<div className="flex flex-row">
							{t('catalog.globalType')} {t('common.changedFrom')} {t(`catalog.changelog.${before.globalType}`)} {t('common.to')} &nbsp;
							<div className="bg-yellow-mark flex-row text-black">{t(`catalog.changelog.${after.globalType}`)}</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CatalogTimedMessageChangeLog;
