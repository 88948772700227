import React from 'react';
import { faCopy, faEdit, faExchange } from '@fortawesome/pro-regular-svg-icons';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';

interface Props {
	visible: boolean;
	children: React.ReactElement;
	onCopyClick?: () => unknown;
	onEditClick?: () => unknown;
	onChangeClick?: () => unknown;
}

const PopoverDrivingSlipActions: React.FC<Props> = ({ visible, children, onCopyClick, onEditClick, onChangeClick }): React.ReactElement => {
	if (!visible || typeof onCopyClick === 'undefined' || typeof onEditClick === 'undefined' || typeof onChangeClick === 'undefined') {
		return <>{children}</>;
	}

	return (
		<>
			<Popover
				interactive
				visible
				delay={[100, 0]}
				duration={0}
				placement="bottom"
				content={
					<div className="rounded-default border-1 shadow-default space-x-1 border-black bg-white p-1 text-xs text-black">
						<div className="flex flex-row justify-between">
							<TextButton icon={faCopy} text="common.copy" onClick={onCopyClick} />

							<TextButton icon={faEdit} text="common.edit" onClick={onEditClick} />

							<TextButton icon={faExchange} text="common.convert" onClick={onChangeClick} />
						</div>
					</div>
				}
			>
				{children}
			</Popover>
		</>
	);
};

export default PopoverDrivingSlipActions;
