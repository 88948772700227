import React from 'react';
import { GetCarsWithCalendars, GetUsersWithCalendars } from '../../GraphQL';
import classNames from 'classnames';

interface Props {
	entity: GetUsersWithCalendars['usersWithCalendars'][number] | GetCarsWithCalendars['carsWithCalendars'][number];
	onClick(): void;
	active: boolean;
	bgColor: string;
}

const CalendarEntity: React.FC<Props> = ({ entity, onClick, active, bgColor }): React.ReactElement => (
	<div
		className={classNames('border-1 rounded-default mb-1 flex w-full cursor-pointer flex-row bg-white p-1 text-sm', active && bgColor, {
			'text-white': active,
		})}
		onClick={onClick}
	>
		<div className="mr-2 flex w-10 items-center justify-center">
			<div className="border-1 flex h-10 w-10 items-center justify-center rounded-full border-white bg-gray-900 text-lg text-white">{entity.name?.substr(0, 1).toUpperCase()}</div>
		</div>
		<div className="overflow-hidden">
			<p className="truncate font-medium">{entity.name}</p>
			<p className="truncate text-xs">
				{entity.department}
				{'area' in entity && entity.area && ` (${entity.area})`}
			</p>

			{'jobFunction' in entity && <p className="truncate text-xs">{entity.jobFunction}</p>}

			{'plannerHelpText' in entity && entity.plannerHelpText !== null && entity.plannerHelpText.length > 0 && <p className="mt-1 text-xs italic">{entity.plannerHelpText}</p>}
		</div>
	</div>
);
export default CalendarEntity;
