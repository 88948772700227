import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ssg/common/Components/Button';
import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import Textarea from '@ssg/common/Components/Textarea';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { loader } from 'graphql.macro';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
	CreateJob,
	CreateJobVariables,
	JobType,
	GetJobs,
	SearchWebUsers,
	GetWebLocations,
	GetWebLocationsVariables,
	GetWebCasesByErpNo,
	GetWebCasesByErpNoVariables,
	GetCaseMovables,
	GetCaseMovablesVariables,
	GetJobsVariables,
	SearchWebUsersVariables,
} from '../../GraphQL';
import { IMovableJobCreation } from '../../Schemas/IMovableJobCreation';
import { movableJobCreationSchema } from '../../Schemas/MovableJobCreationSchema';
import UserContext from '../../UserContext';
import { JobFilters } from './MovableJobs';

const GET_JOBS = loader('src/GraphQL/Jobs/GetJobs.gql');
const CREATE_JOB = loader('src/GraphQL/Jobs/CreateJob.gql');
const SEARCH_USERS = loader('src/GraphQL/Users/SearchWebUsers.gql');
const GET_CASES_BY_ERPNO = loader('src/GraphQL/Cases/GetWebCasesByErpNo.gql');
const GET_CASES_MOVABLES = loader('src/GraphQL/Cases/GetCaseMovables.gql');
const GET_LOCATIONS = loader('src/GraphQL/Locations/GetWebLocations.gql');

interface Props {
	callback: (id: string) => unknown;
}

const CreateMovableJob: React.FC<Props> = ({ callback }) => {
	const userContext = React.useContext(UserContext);

	const [activeJobFilters] = useStorageState<JobFilters>(window.sessionStorage, 'activeJobFilters', {
		name: null,
		location: '',
		personalOnly: false,
	});

	const [assignedToSearchText, setAssignedToSearchText] = useDebouncedState('', 100);

	const { data: searchUsers, loading: searchUsersLoading } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: assignedToSearchText,
			onlyEmployees: true,
		},
		skip: assignedToSearchText.length === 0,
	});

	const [caseAddress, setCaseAddress] = React.useState<GetWebCasesByErpNo['cases'][number]['damage']['contact']['address']>();
	const [casesSearchText, setCasesSearchText] = useDebouncedState<string>('', 100);
	const { data: casesByErpNo, loading: loadingCasesByErpNo } = useQuery<GetWebCasesByErpNo, GetWebCasesByErpNoVariables>(GET_CASES_BY_ERPNO, {
		variables: { erpReferenceNo: casesSearchText },
		skip: casesSearchText.length < 2,
	});
	const [getCaseMovables, { data: caseMovablesData, loading: caseMovablesLoading }] = useLazyQuery<GetCaseMovables, GetCaseMovablesVariables>(GET_CASES_MOVABLES);

	const { data: locations } = useQuery<GetWebLocations, GetWebLocationsVariables>(GET_LOCATIONS, {
		variables: { movableLocationsOnly: true },
	});

	const { control, register, errors, formState, setValue, handleSubmit } = useForm<IMovableJobCreation>({
		defaultValues: {
			assignedTo: '', // Default values needed for validation
			location: '',
			case: '',
			movable: '',
		},
		resolver: yupResolver(movableJobCreationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const [createJob, { loading: createJobSubmitting }] = useMutation<CreateJob, CreateJobVariables>(CREATE_JOB);

	const onSubmit = async (data: IMovableJobCreation) => {
		// Create movable and update cache

		const caseTemp = typeof data.case === 'undefined' ? undefined : data.case.length === 0 ? undefined : data.case;

		const movableId = typeof data.movable === 'undefined' ? undefined : data.movable.length === 0 ? undefined : data.movable;

		await createJob({
			variables: {
				job: {
					type: JobType.MOVABLE,
					description: data.description,
					deadline: data.deadline.toISOString(),
					assignedToId: data.assignedTo.length === 0 ? undefined : data.assignedTo,
					locationId: data.location.length === 0 ? undefined : data.location,
					caseId: caseTemp,
					movableId: movableId,
				},
			},
			update: (cache, { data }): void => {
				if (typeof data === 'undefined' || data === null) {
					return;
				}

				// Update cache for personal only query if relevant
				if (data.createJob.assignedTo !== null && data.createJob.assignedTo.id === userContext.user?.id) {
					const cachedRequestPersonalOnly = cache.readQuery<GetJobs, GetJobsVariables>({
						query: GET_JOBS,
						variables: {
							type: JobType.MOVABLE,
							completed: false,
							personalOnly: true,
							location: activeJobFilters.location,
						},
					});
					if (cachedRequestPersonalOnly !== null) {
						cache.writeQuery({
							query: GET_JOBS,
							variables: {
								type: JobType.MOVABLE,
								completed: false,
								personalOnly: true,
								location: activeJobFilters.location,
							},
							data: {
								jobs: [...cachedRequestPersonalOnly.jobs, data.createJob],
							},
						});
					}
				}

				// Update cache for "all" query
				const cachedRequestAll = cache.readQuery<GetJobs, GetJobsVariables>({
					query: GET_JOBS,
					variables: {
						type: JobType.MOVABLE,
						completed: false,
						personalOnly: false,
						location: activeJobFilters.location,
					},
				});
				if (cachedRequestAll !== null) {
					cache.writeQuery<GetJobs, GetJobsVariables>({
						query: GET_JOBS,
						variables: {
							type: JobType.MOVABLE,
							completed: false,
							personalOnly: false,
							location: activeJobFilters.location,
						},
						data: {
							jobs: [...cachedRequestAll.jobs, data.createJob],
						},
					});
				}

				// Call callback fn
				callback(data.createJob.id);
			},
		});
	};

	return (
		<form className="space-y-8 text-sm" onSubmit={handleSubmit(onSubmit)}>
			<div>
				<Textarea required name="description" rows={3} title="common.description" errorMessage={errors.description?.message} innerRef={register} />
			</div>

			<div>
				<Datepicker required time name="deadline" title="common.deadline" errorMessage={errors.deadline?.message} innerRef={register} />
			</div>

			<div>
				<SearchableSelect
					required
					control={control}
					name="assignedTo"
					title="jobs.assignedTo"
					options={
						searchUsers?.searchUsers.map(u => ({
							value: u.id,
							label: u.name,
						})) ?? []
					}
					searchFn={searchText => setAssignedToSearchText(searchText)}
					onSelect={value => setValue('assignedTo', value, { shouldValidate: true })}
					onBlur={() => undefined}
					minInputLength={2}
					isLoading={searchUsersLoading}
					errorMessage={errors.assignedTo?.message ?? ''}
				/>
			</div>

			<div>
				<Dropdown
					name="location"
					title="common.location"
					data={[
						{ label: '', value: '' },
						...(locations?.locations ?? []).map(l => ({
							label: l.name,
							value: l.id,
						})),
					]}
					errorMessage={errors.location?.message}
					innerRef={register}
				/>
			</div>

			<div>
				<SearchableSelect
					name="case"
					title="common.case"
					searchFn={searchText => setCasesSearchText(searchText)}
					onSelect={value => {
						setValue('case', value, { shouldValidate: true });
						setCaseAddress(casesByErpNo?.cases.find(c => c.id === value)?.damage.contact.address);

						setValue('movable', '');
						getCaseMovables({ variables: { id: value } });
					}}
					onBlur={() => undefined}
					minInputLength={2}
					options={(casesByErpNo?.cases ?? []).map(c => ({
						label: c.erpNo,
						value: c.id,
					}))}
					isLoading={loadingCasesByErpNo}
					errorMessage={errors.case?.message}
					control={control}
				/>

				{typeof caseAddress !== 'undefined' && (
					<div>
						<div>
							{caseAddress.road} {caseAddress.houseNumber} {caseAddress.floor ?? ''}
						</div>
						{caseAddress.addressLineAlt !== null && <div>{caseAddress.addressLineAlt}</div>}
						<div>
							{caseAddress.postalCode} {caseAddress.city}
						</div>
						<div>{caseAddress.country}</div>
					</div>
				)}
			</div>

			<div>
				<Dropdown
					name="movable"
					title="common.movable"
					data={[
						{ label: '', value: '' },
						...(caseMovablesData?.case.movables ?? []).map(m => ({
							label: `${m.description}, ${m.status}, ${m.placement.name}`,
							value: m.id,
						})),
					]}
					innerRef={register}
					disabled={typeof caseMovablesData === 'undefined' || caseMovablesLoading}
				/>
			</div>

			<div className="pl-4">
				<Button submit success text="movable.createMovableJob" loading={createJobSubmitting} disabled={!formState.isValid || createJobSubmitting} />
			</div>
		</form>
	);
};
export default CreateMovableJob;
