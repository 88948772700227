import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	DeleteCatalogCraftsman,
	DeleteCatalogCraftsmanVariables,
	FileActionType,
	GetCatalog,
	GetCatalogCraftsmen,
	GetCatalogCraftsmenVariables,
	GetCatalogVariables,
	GetCatalog_catalog_craftsmen,
	UpdateCatalogCraftsman,
	UpdateCatalogCraftsmanVariables,
} from '../../../GraphQL';
import { removeTypename } from '../../../helper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';

const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');
const GET_CATALOG_CRAFTSMEN = loader('src/GraphQL/CatalogCraftsmen/GetCatalogCraftsmen.gql');
const DELETE_CATALOG_CRAFTSMAN = loader('src/GraphQL/CatalogCraftsmen/DeleteCatalogCraftsman.gql');
const UPDATE_CATALOG_CRAFTSMAN = loader('src/GraphQL/CatalogCraftsmen/UpdateCatalogCraftsman.gql');

interface Props {
	craftsmanData: GetCatalog_catalog_craftsmen;
	close(): void;
	visible: boolean;
	catalogId?: string;
	changedBy: string;
	isExternalUser: boolean;
}

const DeleteCraftsmanModal: React.FC<Props> = ({ craftsmanData, visible, close, catalogId, changedBy, isExternalUser }): React.ReactElement => {
	const { t } = useTranslation();

	const [deleteCraftsman, { loading: loadingDelete }] = useMutation<DeleteCatalogCraftsman, DeleteCatalogCraftsmanVariables>(DELETE_CATALOG_CRAFTSMAN);
	const [updateCraftsman, { loading: loadingUpdate }] = useMutation<UpdateCatalogCraftsman, UpdateCatalogCraftsmanVariables>(UPDATE_CATALOG_CRAFTSMAN);

	const postDeleteCraftsman = async () => {
		try {
			await deleteCraftsman({
				variables: {
					id: craftsmanData.id,
					changedBy: changedBy,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedCraftsmenRequest = cache.readQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
						query: GET_CATALOG_CRAFTSMEN,
						variables: {
							globalOnly: true,
							thisCustomerOnly: isExternalUser,
						},
					});

					if (cachedCraftsmenRequest !== null && cachedCraftsmenRequest.catalogCraftsmen !== null) {
						cache.writeQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
							query: GET_CATALOG_CRAFTSMEN,
							variables: {
								globalOnly: true,
								thisCustomerOnly: isExternalUser,
							},
							data: {
								catalogCraftsmen: cachedCraftsmenRequest.catalogCraftsmen.filter(cat => cat.id !== cacheData.deleteCatalogCraftsman),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								craftsmen: cachedCatalogRequest.catalog.craftsmen.filter(cc => cc.id === cacheData.deleteCatalogCraftsman),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const postRemoveCraftman = async () => {
		try {
			await updateCraftsman({
				variables: {
					id: craftsmanData.id,
					customer: craftsmanData.customer.id,
					changedBy: changedBy,
					type: craftsmanData.type,
					contactName: craftsmanData.contactName,
					email: craftsmanData.email,
					informations: craftsmanData.informations ? removeTypename(craftsmanData.informations) : [],
					priority: craftsmanData.priority,
					global: craftsmanData.global,
					globalType: craftsmanData.globalType,
					active: craftsmanData.active,
					catalogs: craftsmanData.catalogIds.filter(id => id !== catalogId),
					catalogsBefore: craftsmanData.catalogIds,
					action: FileActionType.REMOVE,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
						query: GET_CATALOG_CRAFTSMEN,
						variables: {
							globalOnly: true,
							thisCustomerOnly: isExternalUser,
						},
					});

					if (cachedRequest !== null && cachedRequest.catalogCraftsmen !== null) {
						cache.writeQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
							query: GET_CATALOG_CRAFTSMEN,
							variables: {
								globalOnly: true,
								thisCustomerOnly: isExternalUser,
							},
							data: {
								catalogCraftsmen: cachedRequest.catalogCraftsmen.filter(cc => cc.id !== cacheData.updateCatalogCraftsman.id),
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								craftsmen: cachedCatalogRequest.catalog.craftsmen.filter(cc => cc.id !== cacheData.updateCatalogCraftsman.id),
							},
						},
					});
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	const toDelete = (): boolean => {
		if (craftsmanData.global) {
			return true;
		} else if (!craftsmanData.global && (craftsmanData.catalogIds.length === 1 || !catalogId)) {
			return true;
		} else {
			return false;
		}
	};

	const deleteRemoveText = (): string => {
		if (craftsmanData.global) {
			return 'catalog.craftsman.globalDelete';
		} else if (!craftsmanData.global && (craftsmanData.catalogIds.length === 1 || !catalogId)) {
			return 'catalog.craftsman.catalogDelete';
		} else {
			return 'catalog.craftsman.catalogRemove';
		}
	};
	return (
		<Modal
			title={toDelete() ? 'catalog.craftsman.delete' : 'catalog.craftsman.remove'}
			size={ModalSize.SMALL}
			close={() => close()}
			visible={visible}
			body={
				<div>
					<div className="flex flex-col">
						<div>
							<p>
								{t(toDelete() ? 'catalog.craftsman.delete' : 'catalog.craftsman.remove')} {craftsmanData.contactName}?
							</p>
							<p className="text-red">{t(deleteRemoveText())}</p>
						</div>

						<Button
							danger
							text={toDelete() ? 'catalog.craftsman.delete' : 'catalog.craftsman.remove'}
							onClick={async () => {
								if (craftsmanData.global) {
									postDeleteCraftsman();
								} else if (!craftsmanData.global && (craftsmanData.catalogIds.length === 1 || !catalogId)) {
									postDeleteCraftsman();
								} else {
									postRemoveCraftman();
								}
							}}
							className="mt-4"
							loading={loadingDelete || loadingUpdate}
							disabled={loadingDelete || loadingUpdate}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteCraftsmanModal;
