import React from 'react';
import { FC, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { useGetManagerFlexTimeListQuery } from '@ssg/common/GraphQL/indexV2';
import Table from '@ssg/common/Components/Table';
import Box from '../../Components/Layout/Box';

const ManagerFlexOverview: FC = () => {
	const managerWorkerFlexView = useFlag(FeatureFlagEnums.MANAGER_WORKER_FLEX_VIEW);

	const { data, loading } = useGetManagerFlexTimeListQuery({
		skip: !managerWorkerFlexView,
	});

	const managerFlexTimeListSorted = useMemo(() => {
		const list = data?.flexTimeManagerList.slice() ?? [];
		list.sort((a, b) => a.user.name.localeCompare(b.user.name));

		return list;
	}, [data?.flexTimeManagerList]);

	return (
		<div>
            {(data && managerFlexTimeListSorted.length > 0) && (
                <Box full title="myPage.managerFlexOverview" className="text-blue mb-8">
                    <Table
                        data={managerFlexTimeListSorted}
                        keySelector={({ user }) => user.id}
                        columns={[
                            {
                                label: 'common.user',
                                selectFn: ({ user }) => user.name,
                                sortFn: (a, b) => a.user.name.localeCompare(b.user.name),
                            },
                            {
                                label: 'common.email',
                                selectFn: ({ user }) => <a href={`mailto:${user.email}`}>{user.email}</a>,
                                sortFn: (a, b) => a.user.email.localeCompare(b.user.email),
                            },
                            {
                                label: 'common.phone',
                                selectFn: ({ user }) => (user.phone !== null && user.phone.length > 0 ? <a href={`tel:${user.phone}`}>{user.phone}</a> : ''),
                                sortFn: (a, b) => (a.user.phone ?? '').localeCompare(b.user.phone ?? ''),
                            },
                            {
                                label: 'common.flexAmount',
                                selectFn: (a) => a.flexTime,
                                sortFn: (a, b) => a.flexTime - b.flexTime,
                            },
                        ]}
                        loading={loading}
                    />
                </Box>
            )}
		</div>
	);
};

export default ManagerFlexOverview;
