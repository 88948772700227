import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { faSpinner, faTimes } from '@fortawesome/pro-regular-svg-icons';
import Dropdown from '@ssg/common/Components/Dropdown';
import Table from '@ssg/common/Components/Table';
import addThousandSeperator from '@ssg/common/Helpers/addThousandSeperator';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import TextButton from '@ssg/common/Components/TextButton';
import { EditableInvoiceLine, InvoiceJobTask, InvoiceLineType, InvoiceAssortment, InvoiceSamplePrice, lineTypeHasAssortment, lineTypeIsText } from './InvoiceModal';
import { GetDebitor_debitor, GetInvoices_invoices_lines, GetSamplePriceForInvoiceLine, GetSamplePriceForInvoiceLineVariables } from '../../../GraphQL';
import Textarea from '@ssg/common/Components/Textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlag } from '@unleash/proxy-client-react';
import { DEFAULT_ENVFEE_NAME, calculateTotalEnvFee, hasMaximumEnvironmentalFeeAmountForDebitor, shouldCalculateEnvFeeForCategory, shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import LineSuggestions from './LineSuggestions';

const GET_INVOICE_LINE_PRICE = loader('../../../GraphQL/Invoices/GetSamplePriceForInvoiceLine.gql');

const updateToNoTypeTaskNo = (updates: Partial<EditableInvoiceLine>): boolean => {
	if ('no' in updates) {
		return true;
	}

	if ('type' in updates) {
		return true;
	}

	if ('taskNo' in updates) {
		return true;
	}

	return false;
};

// interface SamplePriceDictionary {
// 	[rowIndex: number]: InvoiceSamplePrice;
// }

interface SamplePriceDictionary {
	[renderKey: string]: InvoiceSamplePrice;
}

interface Props {
	caseId: string;
	invoiceERPReferenceNo: string;
	lines: EditableInvoiceLine[];
	existingLines: GetInvoices_invoices_lines[];
	setLines: React.Dispatch<React.SetStateAction<EditableInvoiceLine[]>>;
	jobTasks: InvoiceJobTask[];
	lineTypes: InvoiceLineType[];
	assortments: InvoiceAssortment[];
	description: string;
	setDescription: React.Dispatch<React.SetStateAction<string>>;
	locked: boolean;
	isScreening: boolean;
	isFinal: boolean;
	debitor: GetDebitor_debitor;
	category: string | undefined;
	screeningLines: SelectOption[];
	highlightBcDifference: boolean;
}

const TableInput: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { unit?: string, width?: string }> = ({ unit, width = 'w-full', ...props }) => (
	<div className={`relative ${width}`}>
		<input
			{...props}
			className={classNames('border-1 rounded-default w-full border-gray-600 p-1 text-sm focus:outline-none', props.className, {
				'cursor-not-allowed bg-gray-300': props.readOnly,
			})}
		/>
		{unit && (
			<div className="absolute top-0 right-0 p-2 flex items-center text-blue text-right text-xs font-medium">
				{unit}
			</div>
		)}
	</div>
);

const InvoiceStepEdit: React.FC<Props> = ({ lines, existingLines, setLines, jobTasks, lineTypes, assortments, screeningLines, locked, description, setDescription, isScreening, isFinal, debitor, category, caseId, invoiceERPReferenceNo, highlightBcDifference }) => {
	const { t } = useTranslation();
	const negativeInvoiceInputFixFlag = useFlag(FeatureFlagEnums.NEGATIVE_INVOICE_INPUT_FIX);
	const aIInvoicePredictionsFinalFlag = useFlag(FeatureFlagEnums.AI_INVOICE_PREDICTIONS_FINAL);
	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);
	const hasMaxEnvFeeAmount = hasMaximumEnvironmentalFeeAmountForDebitor(debitor.maxEnvironmentFeeAmount);

	const [samplePriceDictionary, setSamplePriceDictionary] = useState<SamplePriceDictionary>(
		lines.reduce((map, line, index) => {
			map[line.renderKey] = {
				sample: {
					totalCost: line.cost ?? 0,
					lineAmount: line.amount ?? 0,
				},
				standardUnitPrice: (line.amount ?? 0) / (line.quantity ?? 0),
			};
			return map;
		}, {} as SamplePriceDictionary),
	);

	const [getSamplePrice, { loading: getSamplePriceLoading }] = useMutation<GetSamplePriceForInvoiceLine, GetSamplePriceForInvoiceLineVariables>(GET_INVOICE_LINE_PRICE);

	async function onLineChange(key: string, updates: Partial<EditableInvoiceLine>, forceNewUnitPriceUpdate: boolean) {
		const idx = lines.findIndex(l => l.renderKey === key);
		let newLines = [...lines];
		newLines[idx] = {
			...lines[idx],
			...updates,
		};
		setLines(newLines);

		// Update sample price if applicable
		const line = newLines[idx];
		const { renderKey, amount, cost, attachDocument, allowAttachDocument, screeningLineId, screening, ...lineWithoutRenderKey } = line;
		if (lineTypeHasAssortment(line) && line.no && typeof line.quantity === 'number' && !isNaN(line.quantity)) {
			const price = await getSamplePrice({
				variables: {
					invoiceLine: lineWithoutRenderKey,
				},
			});

			if (typeof price?.data?.samplePriceForInvoiceLine !== 'undefined') {
				const priceData = price.data.samplePriceForInvoiceLine;
				const newSamplePrice = samplePriceDictionary[key];
				if (typeof newSamplePrice === 'undefined') {
					setSamplePriceDictionary(current => ({
						...current,
						[key]: {
							sample: priceData,
							standardUnitPrice: priceData.lineAmount / (line.quantity ?? 0),
						},
					}));
				} else {
					if (updateToNoTypeTaskNo(updates)) {
						setSamplePriceDictionary(current => ({
							...current,
							[key]: {
								sample: priceData,
								standardUnitPrice: priceData.lineAmount / (line.quantity ?? 0),
							},
						}));
					} else {
						setSamplePriceDictionary(current => ({
							...current,
							[key]: { ...newSamplePrice, sample: priceData },
						}));
					}
				}
				setLines(current => {
					const copyLines = current.slice();
					copyLines[idx].amount = priceData.lineAmount;
					copyLines[idx].cost = priceData.totalCost;

					if (copyLines[idx].newUnitPrice === null || forceNewUnitPriceUpdate === true) {
						copyLines[idx].newUnitPrice = priceData.lineAmount / (copyLines[idx].quantity ?? 0);
						newLines = copyLines;
					}
					if (shouldCalculateEnvFeeForCategory(category)) {
						const calculatedEnvFee = calculateTotalEnvFee(copyLines, shouldCalculateEnvFeeForDebitor, debitor, category);

						const envFeeLineRenderKey = lines.find(l => l.no === DEFAULT_ENVFEE_NAME)?.renderKey;

						if (envFeeLineRenderKey) {
							const idx = lines.findIndex(l => l.renderKey === envFeeLineRenderKey);
							copyLines[idx].newUnitPrice = calculatedEnvFee;
						}
					}
					return copyLines;
				});

				return;

				// return copy;
				// newLines = copyLines;
				// setLines(current => {
				// 	const copy = current.slice();
				// 	copy[idx].amount = priceData.lineAmount;
				// 	copy[idx].cost = priceData.totalCost;

				// 	if (copy[idx].newUnitPrice === null || forceNewUnitPriceUpdate === true) {
				// 		copy[idx].newUnitPrice = priceData.lineAmount / (copy[idx].quantity ?? 0);
				// 	}

				// 	return copy;
				// });
			}
		}

		if (shouldCalculateEnvFeeForCategory(category)) {
			calculateTotalEnvironmentFee(newLines);
		}
	}

	React.useEffect(() => {
		if (shouldCalculateEnvFeeForCategory(category)) {
			calculateTotalEnvironmentFee(lines);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category]);

	function handleRemoveLine(key: string) {
		const idx = lines.findIndex(l => l.renderKey === key);

		const newLines = [...lines];
		newLines.splice(idx, 1);
		setLines(newLines);
	}

	const jobTaskOptions: SelectOption[] = jobTasks.map(jobTask => ({
		label: `${jobTask.description} (${jobTask.jobTaskNo})`,
		value: jobTask.jobTaskNo,
	}));

	const lineTypeOptions: SelectOption[] = lineTypes.map(lineType => ({
		label: 'offer.resourceTypes.' + lineType.name.toUpperCase(),
		value: lineType.name,
	}));

	const assortmentOptions = (line: EditableInvoiceLine): SelectOption[] =>
		assortments
			.filter(assortment => assortment.type === line.type)
			.map(
				(assortment): SelectOption => ({
					value: assortment.no,
					label: `${assortment.description} ${assortment.no}`,
				}),
			);

	const calculateLinePrice = (line: EditableInvoiceLine) => (typeof line.quantity === 'number' && !isNaN(line.quantity ?? 0) ? line.quantity : 0) * (line.newUnitPrice ?? 0);

	const calculateTotalEnvironmentFee = (linesForCalc: EditableInvoiceLine[]) => {
		const calculatedEnvFee = calculateTotalEnvFee(linesForCalc, shouldCalculateEnvFeeForDebitor, debitor, category);
		//setTotalEnvFee(calculatedEnvFee);
		const copyLines = linesForCalc.slice();
		const envFeeLineRenderKey = lines.find(l => l.no === DEFAULT_ENVFEE_NAME)?.renderKey;

		if (envFeeLineRenderKey) {
			const idx = lines.findIndex(l => l.renderKey === envFeeLineRenderKey);
			copyLines[idx].newUnitPrice = calculatedEnvFee;
		}
		setLines(copyLines);
	};

	const totalPrice = lines.reduce((totalPrice, line) => totalPrice + (typeof line.quantity === 'number' && !isNaN(line.quantity ?? 0) ? line.quantity : 0) * (line.newUnitPrice ?? 0), 0);
	const totalcost = lines.reduce((totalcost, line) => totalcost + (typeof line.quantity === 'number' && !isNaN(line.quantity ?? 0) ? line.quantity : 0) * (line.cost ?? 0), 0);

	return (
		<div style={{ maxHeight: '70vh' }}>
			{isScreening && lines.length > 0 && <LineSuggestions lines={lines} caseId={caseId} invoiceERPReferenceNo={invoiceERPReferenceNo} totalPrice={totalPrice} totalCost={totalcost} setLines={setLines} isNotScreening={false} />}
			{aIInvoicePredictionsFinalFlag && isFinal && <LineSuggestions lines={lines} caseId={caseId} invoiceERPReferenceNo={invoiceERPReferenceNo} totalPrice={totalPrice} totalCost={totalcost} setLines={setLines} isNotScreening={true} />}
			<Textarea
				title={`${t('common.description')} (${t('common.characterCount')} ${description.length}/2048)`}
				name=""
				maxLength={2048}
				className="h-40"
				value={description}
				onChange={e => setDescription(e.currentTarget.value)}
			/>
			<Table
				data={lines.filter(l => l.no !== DEFAULT_ENVFEE_NAME)}
				className="max-h-max"
				keySelector={l => l.renderKey}
				groupSelector={locked ? l => l.screening ?? '' : undefined}
				groupHeaderClassName="text-lg"
				columns={[
					{
						label: '',
						selectFn: (line) => (
							<TextButton onClick={() => handleRemoveLine(line.renderKey)} icon={faTimes} tooltip="case.invoice.removeLine" className="text-red-calm" disabled={(locked && (line.screening ?? '').length > 0) || lines.length === 1} />
						),
					},
					{
						label: t('case.invoice.line.jobTask'),
						selectFn: (line, idx) =>
							!locked && line.type !== 'G/L Account' ? (
								<Dropdown
									name={`lines.${idx}.jobTask`}
									value={line.taskNo ?? ''}
									style={{ minWidth: '300px' }}
									data={[{ label: '', value: '' }, ...jobTaskOptions]}
									onChange={async e => {
										const jobTask = jobTasks.find(jt => jt.jobTaskNo === e.target.value);
										if (jobTask == null) return;

										await onLineChange(
											line.renderKey,
											{
												taskNo: jobTask.jobTaskNo,
												workTypeCode: jobTask.workTypeCode,
												description: jobTask.description,
												newUnitPrice: null,
												excludeInEnvFee: jobTask.excludeInEnvFee ?? undefined,
											},
											true,
										);
									}}
								/>
							) : (
								<TableInput style={{ minWidth: '100px' }} type="text" name={`lines.${idx}.jobTask`} value={line.taskNo ?? ''} readOnly={locked} />
							),
					},
					{
						label: t('case.invoice.line.typeCamelCase'),
						selectFn: (line, idx) =>
							!locked && line.type !== 'G/L Account' ? (
								<Dropdown
									name={`lines.${idx}.type`}
									value={line.type ?? ''}
									style={{ minWidth: '100px' }} // Force width with style to override the underlying class width. TODO: How to make dynamic?
									data={[{ label: '', value: '' }, ...lineTypeOptions]}
									onChange={async e => {
										const lineType = lineTypes.find(lt => lt.name === e.target.value);
										if (lineType == null) return;

										const updates: Partial<EditableInvoiceLine> = {
											type: lineType.name,
											no: undefined,
										};

										if (lineType.name === 'Text') {
											updates.quantity = null;
											updates.newUnitPrice = null;
										}

										await onLineChange(line.renderKey, updates, true);
									}}
								/>
							) : (
								<TableInput style={{ minWidth: '100px' }} type="text" name={`lines.${idx}.type`} value={line.type ?? ''} readOnly={locked} />
							),
					},
					{
						label: t('case.invoice.line.no'),
						noTruncate: true,
						selectFn: (line, idx) =>
							!locked && line.type !== 'G/L Account' ? (
								<Dropdown
									name={`lines.${idx}.no`}
									value={line.no ?? ''}
									style={{ minWidth: '200px' }} // Force width with style to override the underlying class width. TODO: How to make dynamic?
									data={[{ label: '', value: '' }, ...assortmentOptions(line)]}
									onChange={async e => {
										const assortment = assortments?.find(u => u.no === e.target.value);

										if (assortment == null) return;

										await onLineChange(
											line.renderKey,
											{
												no: assortment.no,
												description: line.type === 'Resource' ? line.description : assortment.description,
											},
											true,
										);
									}}
								/>
							) : (
								<TableInput style={{ minWidth: '100px' }} type="text" name={`lines.${idx}.no`} value={line.no ?? ''} readOnly={locked} />
							),
					},
					{
						label: t('case.invoice.line.description'),
						selectFn: (line, idx) => (
							<TableInput
								width="w-full"
								type="text"
								name={`lines.${idx}.description`}
								value={line.description ?? ''}
								onChange={async e => await onLineChange(line.renderKey, { description: e.target.value }, false)}
								readOnly={locked}
							/>
						),
						dynamicClassNameTh: t => 'w-full',
					},
					...(isScreening
						? [
							{
								label: t('case.invoice.line.screeningPosition'),
								selectFn: (line: EditableInvoiceLine) => <TableInput style={{ minWidth: '100px' }} type="text" value={line.screeningLinePosition ?? ''} readOnly={true} />,
							},
						]
						: []),
					{
						label: t('case.invoice.line.attachDocument'),
						selectFn: (line, idx) => (
							<TableInput
								type="checkbox"
								name={`lines.${idx}.attachDocument`}
								checked={line.attachDocument ?? false}
								onChange={async e => await onLineChange(line.renderKey, { attachDocument: e.target.checked }, false)}
								readOnly={!line.allowAttachDocument}
								className={classNames('cursor-pointer', {
									hidden: !line.allowAttachDocument,
								})}
								style={{ width: '1rem' }}
							/>
						),
						align: 'CENTER',
						hideColumn: true,
					},
					{
						label: 'offer.unitCost',
						selectFn: (line, idx) => (
							<TableInput
								className="cursor-not-allowed bg-gray-300"
								type={lineTypeIsText(line) ? 'text' : 'number'}
								width="w-24"
								name=""
								value={lineTypeIsText(line) ? '' : samplePriceDictionary[idx]?.sample.totalCost ?? 0}
								readOnly={true}
							/>
						),
						hideColumn: true,
					},
					{
						label: t('case.invoice.unitPriceExample'),
						selectFn: (line, idx) => (
							<TableInput
								className="cursor-not-allowed bg-gray-300"
								type={lineTypeIsText(line) ? 'text' : 'number'}
								width="w-24"
								name=""
								value={lineTypeIsText(line) ? '' : samplePriceDictionary[idx]?.standardUnitPrice ?? 0}
								readOnly={true}
							/>
						),
					},
					{
						label: t('case.invoice.line.unitPrice'),
						selectFn: (line, idx) => {
							if (negativeInvoiceInputFixFlag) {
								if (isNaN(line.newUnitPrice as number))
									return (
									<div>
										<TableInput
											type={lineTypeIsText(line) ? 'text' : 'number'}
											width="w-24"
											name={`lines.${idx}.newUnitPrice`}
											className={classNames({
												'border-2 border-blue-light': (
													highlightBcDifference &&
													typeof existingLines.find(l => l.systemId === line.systemId)?.unitPrice != 'undefined' &&
													existingLines.find(l => l.systemId === line.systemId)?.unitPrice !== line.newUnitPrice
												),
											})}
											onChange={async e =>
												await onLineChange(
													line.renderKey,
													{
														newUnitPrice: e.target.valueAsNumber,
													},
													false,
												)
											}
											readOnly={locked || lineTypeIsText(line)}
										/>
										{(highlightBcDifference &&
											typeof existingLines.find(l => l.systemId === line.systemId)?.unitPrice != 'undefined' &&
											existingLines.find(l => l.systemId === line.systemId)?.unitPrice !== line.newUnitPrice) &&
											<p className="text-xs font-semibold text-blue-light">BC værdi: {existingLines.find(l => l.systemId === line.systemId)?.unitPrice}</p>
										}
									</div>
									);
							}
							return (
								<div>
									<TableInput
										type={lineTypeIsText(line) ? 'text' : 'number'}
										width="w-24"
										name={`lines.${idx}.newUnitPrice`}
										value={line.newUnitPrice ?? undefined}
										className={classNames({
											'border-2 border-blue-light': (
												highlightBcDifference &&
												typeof existingLines.find(l => l.systemId === line.systemId)?.unitPrice != 'undefined' &&
												existingLines.find(l => l.systemId === line.systemId)?.unitPrice !== line.newUnitPrice
											),
										})}
										onChange={async e =>
											await onLineChange(
												line.renderKey,
												{
													newUnitPrice: e.target.valueAsNumber,
												},
												false,
											)
										}
										readOnly={locked || lineTypeIsText(line)}
									/>
									{(highlightBcDifference &&
										typeof existingLines.find(l => l.systemId === line.systemId)?.unitPrice != 'undefined' &&
										existingLines.find(l => l.systemId === line.systemId)?.unitPrice !== line.newUnitPrice) &&
										<p className="text-xs font-semibold text-blue-light">BC værdi: {existingLines.find(l => l.systemId === line.systemId)?.unitPrice}</p>
									}
								</div>
							);
						},
					},
					{
						label: t('case.invoice.line.quantity'),
						selectFn: (line, idx) => (
							<div>
								<TableInput
									type={lineTypeIsText(line) ? 'text' : 'number'}
									unit={assortments.find(a => a.no === line.no)?.uom ?? screeningLines.find(s => s.value === line.screeningLineId)?.label}
									width="w-24"
									name={`lines.${idx}.quantity`}
									className={classNames({
										'border-2 border-blue-light': (
											highlightBcDifference &&
											typeof existingLines.find(l => l.systemId === line.systemId)?.quantity != 'undefined' &&
											existingLines.find(l => l.systemId === line.systemId)?.quantity !== line.quantity
										),
									})}
									value={lineTypeIsText(line) ? '' : line.quantity ?? 0}
									onChange={async e => await onLineChange(line.renderKey, { quantity: e.target.valueAsNumber }, false)}
									readOnly={(locked && (line.screening ?? '').length > 0) || lineTypeIsText(line)}
								/>
								{(highlightBcDifference &&
									typeof existingLines.find(l => l.systemId === line.systemId)?.quantity != 'undefined' &&
									existingLines.find(l => l.systemId === line.systemId)?.quantity !== line.quantity) &&
									<p className="text-xs font-semibold text-blue-light">BC værdi: {existingLines.find(l => l.systemId === line.systemId)?.quantity}</p>
								}
							</div>
						),
					},
					{
						label: t('case.invoice.line.total'),
						selectFn: line => (lineTypeIsText(line) ? <></> : <span>{addThousandSeperator(calculateLinePrice(line))}</span>),
						align: 'RIGHT',
					},
				]}
			/>

			{shouldCalculateEnvFeeForDebitor && (
				<div className="mt-2 text-right">
					<p>
						{t('case.invoice.envFee')}: {debitor.environmentFeePercentage}% | {t('case.invoice.maxEnvFee')}: {hasMaxEnvFeeAmount ? debitor.maxEnvironmentFeeAmount : 'N/A'}
					</p>
					<p>
						{t('case.invoice.envFeeTotal')}: {lines.find(l => l.no === DEFAULT_ENVFEE_NAME)?.newUnitPrice ?? 0}
					</p>
				</div>
			)}

			<p className="mt-2 text-right">
				{t('case.invoice.line.total')} {getSamplePriceLoading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : addThousandSeperator(totalPrice)}
			</p>
		</div>
	);
};

export default InvoiceStepEdit;
