import { EsdhFileFragmentFragment } from '@ssg/common/GraphQL/indexV2';

export const checkFilesExist = <TFile extends { fileName: string; folder?: string }, TFiles extends EsdhFileFragmentFragment>(files: TFile[], filesToCheck: TFiles[]): TFile[] => {
	const images: TFile[] = [];
	files.forEach(file => {
		const existingFile = filesToCheck.find(f => f.name === file.fileName);
		if (typeof existingFile !== 'undefined') {
			images.push({
				fileName: existingFile.name,
				folder: file.folder,
			} as TFile);
		}
	});
	return images;
};

export const tryGetFile = <TFiles extends EsdhFileFragmentFragment>(fileName: string, filesToCheck: TFiles[]): EsdhFileFragmentFragment | null => {
	return filesToCheck.find(f => f.name === fileName) ?? null;
};

