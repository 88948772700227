import React from 'react';
import { useQuery } from '@apollo/client';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { GetVendors, GetVendorsVariables, RequisitionType } from '../../GraphQL';
import { RequisitionsFilter, RequisitionStatus } from './RequisitionsOverview';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Input from '@ssg/common/Components/Input';
import ButtonGroup from '@ssg/common/Components/ButtonGroup';
import Select from 'react-select';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import SearchableCaseSelect from '../../Components/Select/SearchableCaseSelect';

const GET_VENDORS = loader('src/GraphQL/Requisitions/GetVendors.gql');

interface Props {
	activeFilter: RequisitionsFilter;
	setActiveFilter: React.Dispatch<React.SetStateAction<RequisitionsFilter>>;
	setSearchTerm: (v: string) => void;
	damageCategoryOptions: SelectOption[];
	departmentOptions: SelectOption[];
	damageCauseOptions: SelectOption[];
}

const RequisitionsOverviewFilter: React.FC<Props> = ({ activeFilter, setActiveFilter, setSearchTerm, damageCategoryOptions, departmentOptions, damageCauseOptions }): React.ReactElement => {
	const { t } = useTranslation();
	const dummyForm = useForm();

	const [vendorSearchText, setVendorSearchText] = React.useState('');

	const { data: vendors } = useQuery<GetVendors, GetVendorsVariables>(GET_VENDORS, {
		variables: { company: vendorSearchText },
		skip: vendorSearchText.length === 0,
	});

	return (
		<div>
			<div>
				<div className="flex flex-row flex-wrap items-center">
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.tableView')}</label>
						<ButtonGroup
							items={[
								{
									title: 'requisitions.filters.viewMine',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											myRequisitionsOnly: true,
										}),
									isActive: activeFilter.myRequisitionsOnly,
								},
								{
									title: 'requisitions.filters.viewAll',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											myRequisitionsOnly: false,
										}),
									isActive: !activeFilter.myRequisitionsOnly,
								},
							]}
						/>
					</div>
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.status')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: 'requisitions.status.OPEN',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											status: activeFilter.status !== RequisitionStatus.OPEN ? RequisitionStatus.OPEN : undefined,
										}),
									isActive: activeFilter.status === RequisitionStatus.OPEN,
								},
								{
									title: 'requisitions.status.CLOSED',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											status: activeFilter.status !== RequisitionStatus.CLOSED ? RequisitionStatus.CLOSED : undefined,
										}),
									isActive: activeFilter.status === RequisitionStatus.CLOSED,
								},
							]}
						/>
					</div>
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.type')}</label>
						<ButtonGroup
							unselectable
							items={[
								{
									title: 'common.subcontractor',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											type: activeFilter.type !== RequisitionType.SUBCONTRACTOR ? RequisitionType.SUBCONTRACTOR : undefined,
										}),
									isActive: activeFilter.type === RequisitionType.SUBCONTRACTOR,
								},
								{
									title: 'common.materiale',
									onClick: () =>
										setActiveFilter({
											...activeFilter,
											type: activeFilter.type !== RequisitionType.MATERIAL ? RequisitionType.MATERIAL : undefined,
										}),
									isActive: activeFilter.type === RequisitionType.MATERIAL,
								},
							]}
						/>
					</div>
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 block text-xs font-medium">{t('common.search')}</label>
						<div className="flex justify-between">
							<div className="relative flex">
								<FontAwesomeIcon icon={faSearch} className="absolute" style={{ top: '9px', right: '8px' }} />
								<Input
									name="search"
									placeholder={t('requisitions.filters.searchPlaceholder')}
									className="pr-8 text-sm"
									style={{ minWidth: '220px' }}
									onChange={(e): void => setSearchTerm(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row flex-wrap items-center">
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 mr-3 text-xs font-medium">{t('common.created')}</label>
						<div className="flex items-center">
							<input
								type="date"
								value={formatDateForInput(new Date(activeFilter?.fromDate ?? ''))}
								max={formatDateForInput(new Date(activeFilter?.toDate ?? ''))}
								onChange={e => {
									const fromDate = e.target.valueAsDate;
									if (fromDate === null) {
										return;
									}
									fromDate.setHours(0, 0, 0, 0);

									setActiveFilter(currentFilters => ({
										...currentFilters,
										fromDate,
									}));
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							<span className="px-1">&mdash;</span>
							<input
								type="date"
								min={formatDateForInput(new Date(activeFilter?.fromDate ?? ''))}
								value={formatDateForInput(new Date(activeFilter?.toDate ?? ''))}
								onChange={e => {
									const toDate = e.target.valueAsDate;
									if (toDate === null) {
										return;
									}
									toDate.setHours(23, 59, 59, 999);

									setActiveFilter(currentFilters => ({
										...currentFilters,
										toDate,
									}));
								}}
								className="border-1 rounded-default block border-gray-600 p-1 text-sm focus:outline-none"
								style={{
									height: '34px',
									maxWidth: '144px',
									minWidth: '100px',
								}}
							/>
							{typeof activeFilter.fromDate !== 'undefined' && typeof activeFilter.toDate !== 'undefined' && (
								<FontAwesomeIcon
									icon={faTimes}
									size="1x"
									className="text-blue ml-2 cursor-pointer"
									onClick={() =>
										setActiveFilter(currentFilters => ({
											...currentFilters,
											fromDate: undefined,
											toDate: undefined,
										}))
									}
								/>
							)}
						</div>
					</div>
					<div className="mr-3 mb-3 py-px">
						<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.subcontractor')}</label>
						<SearchableSelect
							name="vendor"
							searchFn={searchText => setVendorSearchText(searchText)}
							onSelect={value => {
								setActiveFilter(currentFilters => ({
									...currentFilters,
									vendor: value.length > 0 ? value : undefined,
								}));
								dummyForm.setValue('vendor', value);
							}}
							onBlur={() => undefined}
							minInputLength={2}
							options={
								vendors?.vendors.map(v => ({
									label: v.company ?? '',
									value: v.erpReferenceNo,
								})) ?? []
							}
							isLoading={false}
							control={dummyForm.control}
						/>
					</div>
					<div className="mr-3 mb-3 py-px">
						<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('requisitions.case')}</label>
						<SearchableCaseSelect
							erpNo={false}
							onSelect={value => {
								setActiveFilter(currentFilters => ({
									...currentFilters,
									caseId: value.length > 0 ? value : undefined,
								}));
							}}
						/>
					</div>
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('requisitions.department')}</label>
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-52 text-xs"
							value={
								activeFilter.departments?.map(v => {
									const d = departmentOptions.find(department => department.value === v);
									return {
										value: d?.value ?? '',
										label: d?.label ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values => {
								setActiveFilter(currentFilters => ({
									...currentFilters,
									departments: values?.map(v => v.value) ?? undefined,
								}));
							}}
							options={departmentOptions}
						/>
					</div>
					<div className="mr-3 mb-3">
						<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('common.damageCategory')}</label>
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-48 text-xs"
							value={
								activeFilter.damageCategories?.map(v => {
									const d = damageCategoryOptions.find(category => category.value === v);
									return {
										value: d?.value ?? '',
										label: d?.label ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								setActiveFilter(currentFilters => ({
									...currentFilters,
									damageCategories: values?.map(v => v.value) ?? undefined,
								}))
							}
							options={damageCategoryOptions}
						/>
					</div>
					<div className="mb-3">
						<label className="text-blue mb-1 mr-3 block text-xs font-medium">{t('case.damageCause')}</label>
						<Select
							placeholder=""
							isClearable
							isMulti
							closeMenuOnSelect={false}
							menuPortalTarget={document.body}
							className="react-select-custom w-48 text-xs"
							value={
								activeFilter.damageCauses?.map(v => {
									const d = damageCauseOptions.find(causes => causes.value === v);
									return {
										value: d?.value ?? '',
										label: d?.label ?? '',
									};
								}) ?? []
							}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 6667 }),
							}}
							onChange={values =>
								setActiveFilter(currentFilters => ({
									...currentFilters,
									damageCauses: values?.map(v => v.value) ?? undefined,
								}))
							}
							options={damageCauseOptions}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequisitionsOverviewFilter;
