import React from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CatalogAddressInput, CatalogInput, CreateCatalog, CreateCatalogVariables, CreateCatalog_createCatalog, GetWebCatalogCustomers_catalogCustomers } from '../../../GraphQL';
import { CatalogSchema } from '../../../Schemas/CatalogSchema';
import { Permissions } from '../../../GraphQL';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import CatalogAddressesBox from './CatalogAddressesBox';
import Button from '@ssg/common/Components/Button';
import CatalogDescriptionBox from './CatalogDescriptionBox';
import CatalogInformationBox from './CatalogInformationBox';
import CatalogAddressModal from './CatalogAddressModal';
import UserContext from '../../../UserContext';
import arraysHasMatchingValue from '@ssg/common/Helpers/arraysHasMatchingValue';

const CREATE_CATALOG = loader('src/GraphQL/Catalogs/CreateCatalog.gql');

interface Props {
	changedBy: string;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	open: boolean;
	close: () => void;
}

const CatalogCreationWrapper: React.FC<Props> = ({ changedBy, catalogCustomers, open, close }) => {
	const history = useHistory();

	const userContext = React.useContext(UserContext);
	const userPermissions = userContext.user?.permissions ?? [];
	const canVerify = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_VERIFY]);
	const canVerifyDebitor = arraysHasMatchingValue(userPermissions, [Permissions.CATALOGS_DEBITOR_VERIFY]);

	const [createCatalog, { loading: loadingCreate }] = useMutation<CreateCatalog, CreateCatalogVariables>(CREATE_CATALOG);

	const catalog: CreateCatalog_createCatalog = {
		id: '',
		propertyNumber: '',
		customer: {
			id: '',
			name: '',
		},
		changedBy: changedBy,
		description: null,
		contactNumber: null,
		contactHours: null,
		smsService: false,
		debitor: null,
		addresses: [],
		ssgAccountable: null,
		verified: false,
		debitorVerified: false,
		version: 0,
		createdAt: '',
		updatedAt: '',
		craftsmen: [],
		contacts: [],
		timedMessages: [],
	};

	const [addresses, setAddresses] = React.useState<CatalogAddressInput[]>([]);

	const [updateAddress, setUpdateAddress] = React.useState(false);
	const [createAddress, setCreateAddress] = React.useState(false);
	const [deleteAddress, setDeleteAddress] = React.useState(false);

	const [address, setAddress] = React.useState<CatalogAddressInput>();

	const { handleSubmit, control, setValue, errors, register, trigger, setError, clearErrors } = useForm<CatalogInput>({
		resolver: yupResolver(CatalogSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (formData: CatalogInput) => {
		console.log(formData);

		const createMe: CreateCatalogVariables = {
			catalog: {
				propertyNumber: formData.propertyNumber,
				customer: formData.customer,
				changedBy: changedBy,
				description: formData.description,
				contactNumber: formData.contactNumber,
				contactHours: formData.contactHours,
				smsService: formData.smsService,
				debitor: formData.debitor,
				addresses: addresses,
				ssgAccountable: formData.ssgAccountable,
				verified: canVerify,
				debitorVerified: canVerifyDebitor,
			},
		};

		const createdCT = await createCatalog({
			variables: {
				catalog: createMe.catalog,
			},
		});
		if (createdCT.data) {
			history.push(`/catalog/${createdCT.data.createCatalog.id}`, {
				changedBy: changedBy,
				catalogCustomer: createdCT.data.createCatalog.customer.id,
			});
		} else {
			console.log('CREATE FAILED: ', createdCT.errors);
		}
	};

	return (
		<Modal
			title="common.createOrder"
			visible={open}
			close={close}
			size={ModalSize.XLARGE}
			body={
				<div>
					<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
						{catalog && (
							<div className="text-blue">
								<div className="flex flex-col pb-8 lg:flex-row">
									<div className="w-2/5 pr-8">
										<CatalogInformationBox
											catalog={catalog}
											setError={setError}
											clearErrors={clearErrors}
											errors={errors}
											setValue={setValue}
											control={control}
											register={register}
											trigger={trigger}
											catalogCustomers={catalogCustomers}
										/>
									</div>

									<div className="w-3/5">
										<CatalogDescriptionBox description={catalog.description ?? ''} register={register} setValue={setValue} />
									</div>
								</div>
								<div className="flex flex-col pb-8 lg:flex-row">
									<CatalogAddressesBox
										addresses={addresses}
										setAddress={setAddress}
										setCreateAddress={setCreateAddress}
										setUpdateAddress={setUpdateAddress}
										setDeleteAddress={setDeleteAddress}
									/>
								</div>
								<div>
									<Button submit success text={'common.create'} loading={loadingCreate} disabled={loadingCreate} />
								</div>
							</div>
						)}
					</form>

					{/* Catalog Address Modal */}
					<div>
						{createAddress && address && (
							<CatalogAddressModal
								addresses={addresses}
								setAddresses={setAddresses}
								addressIndex={addresses.findIndex(a => a === address)}
								open={createAddress}
								close={() => setCreateAddress(false)}
								data={address}
								submitCb={() => {
									setCreateAddress(false);
								}}
							/>
						)}

						{updateAddress && typeof address !== 'undefined' && (
							<CatalogAddressModal
								addresses={addresses}
								setAddresses={setAddresses}
								addressIndex={addresses.findIndex(a => a === address)}
								open={updateAddress}
								close={() => setUpdateAddress(false)}
								edit
								data={address}
								submitCb={() => {
									setUpdateAddress(false);
								}}
							/>
						)}

						{deleteAddress && typeof address !== 'undefined' && (
							<CatalogAddressModal
								addresses={addresses}
								setAddresses={setAddresses}
								addressIndex={addresses.findIndex(a => a === address)}
								erase
								open={deleteAddress}
								close={() => setDeleteAddress(false)}
								data={address}
								submitCb={() => {
									setDeleteAddress(false);
								}}
							/>
						)}
					</div>
				</div>
			}
		/>
	);
};

export default CatalogCreationWrapper;
