import React, { useContext, useState } from 'react';
import Button from '@ssg/common/Components/Button';
import SmallLogo from '@ssg/common/Components/Logo/SmallLogo';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import UserContext from '../UserContext';
import { useMutation } from '@apollo/client';
import { CreateGDPRAccept, CreateGDPRAcceptVariables, DeleteGDPRAccept, DeleteGDPRAcceptVariables, GetWebCurrentUser, UpdateGDPRAccept, UpdateGDPRAcceptVariables } from '../GraphQL';
import Loading from '@ssg/common/Components/Loading';
import { useForm } from 'react-hook-form';
import { formatTimestamp } from '@ssg/common/Helpers/Helpers';

const CREATE_GDPR_ACCEPT = loader('src/GraphQL/GDPR/CreateGDPRAccept.gql');
const UPDATE_GDPR_ACCEPT = loader('src/GraphQL/GDPR/UpdateGDPRAccept.gql');
const DELETE_GDPR_ACCEPT = loader('src/GraphQL/GDPR/DeleteGDPRAccept.gql');
const GET_CURRENT_USER = loader('src/GraphQL/Users/GetWebCurrentUser.gql');

const GDPR: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const userContext = useContext(UserContext);

	const gdpr = userContext.user?.gdpr;
	const userId = userContext.user?.id ?? '';

	const [createAccept, setCreateAccept] = useState(false);
	const [updateAccept, setUpdateAccept] = useState(false);
	const [deleteAccept, setDeleteAccept] = useState(false);

	const [createGDPRAccept] = useMutation<CreateGDPRAccept, CreateGDPRAcceptVariables>(CREATE_GDPR_ACCEPT);
	const [updateGDPRAccept] = useMutation<UpdateGDPRAccept, UpdateGDPRAcceptVariables>(UPDATE_GDPR_ACCEPT);
	const [deleteGDPRAccept] = useMutation<DeleteGDPRAccept, DeleteGDPRAcceptVariables>(DELETE_GDPR_ACCEPT);

	const { handleSubmit } = useForm();

	const onSubmit = async () => {
		if (createAccept) {
			console.log('create');
			try {
				await createGDPRAccept({
					variables: {
						gdpr: {
							userId: userId,
							timestamp: new Date().toISOString(),
						},
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
						});

						if (cachedRequest === null || cachedRequest.currentUser === null) {
							return;
						}

						cache.writeQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
							data: {
								currentUser: {
									...cachedRequest.currentUser,
									gdpr: cacheData.createGDPRAccept,
								},
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else if (updateAccept) {
			console.log('update');
			try {
				await updateGDPRAccept({
					variables: {
						id: userId,
						timestamp: new Date().toISOString(),
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
						});

						if (cachedRequest === null || cachedRequest.currentUser === null) {
							return;
						}

						cache.writeQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
							data: {
								currentUser: {
									...cachedRequest.currentUser,
									gdpr: cacheData.updateGDPRAccept,
								},
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		} else if (deleteAccept) {
			console.log('delete');
			try {
				await deleteGDPRAccept({
					variables: {
						id: userId,
					},
					update: (cache, { data: cacheData }): void => {
						if (typeof cacheData === 'undefined' || cacheData === null) {
							return;
						}

						const cachedRequest = cache.readQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
						});

						if (cachedRequest === null || cachedRequest.currentUser === null) {
							return;
						}

						cache.writeQuery<GetWebCurrentUser>({
							query: GET_CURRENT_USER,
							data: {
								currentUser: {
									...cachedRequest.currentUser,
									gdpr: null,
								},
							},
						});
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
		setCreateAccept(false);
		setUpdateAccept(false);
		setDeleteAccept(false);
	};

	if (userContext.loading) {
		return <Loading />;
	}

	if (gdpr === null) {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="mt-64 flex flex-col items-center justify-center">
				<SmallLogo className="h-20 w-20 object-contain" />
				<p className="text-blue text-6xl font-semibold italic">5C</p>

				<p className="mb text-blue mt-5 max-w-5xl text-xl">{t('gdpr.text')}</p>
				<div className="mt-24 flex flex-row items-center">
					<Button success submit text="common.accept" onClick={() => setCreateAccept(true)} className="mr-3" />
					<Button danger text="common.decline" onClick={() => console.log('Decline')} />
				</div>
			</form>
		);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="mt-96 flex flex-col items-center justify-center">
			<p className="mb text-blue mt-5 max-w-5xl text-xl">
				Placeholder for tekst omkring brugen af brugerens data osv. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
				Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
				<br />
				<br />
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
				laboris. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
				ullamco laboris.
				<br />
				<br />
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
				laboris.
			</p>
			{formatTimestamp(new Date(gdpr?.timestamp ?? ''))}
			<div className="mt-24 flex flex-row items-center">
				<Button success submit text="gdpr.updateAccept" onClick={() => setUpdateAccept(true)} className="mr-3" />
				<Button danger submit text="gdpr.withdrawAccept" onClick={() => setDeleteAccept(true)} />
			</div>
		</form>
	);
};

export default GDPR;
