import React from 'react';
import { faSpinner, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';
import { GetInvoiceAttachments_invoice_attachments } from '../../../GraphQL';
import { useTranslation } from 'react-i18next';

interface Props {
	deleteAttachment(fileName: string): void;
	attachments: GetInvoiceAttachments_invoice_attachments[];
	loading: boolean;
}

const InvoiceStepAttachments: React.FC<Props> = ({ deleteAttachment, attachments, loading }) => {
	const { t } = useTranslation();

	if (loading)
		return (
			<div className="h-8">
				<FontAwesomeIcon icon={faSpinner} className="text-blue animate-spin" size="lg" />
			</div>
		);

	if (attachments.length === 0) return <p>{t('case.invoice.noAttachments')}</p>;

	return (
		<Table
			data={attachments ?? []}
			keySelector={file => file.name}
			columns={[
				{
					label: '',
					selectFn: file => <TextButton onClick={() => deleteAttachment(file.name)} icon={faTimes} tooltip="case.invoice.removeLine" className="text-red-calm" />,
				},
				{
					label: 'Name',
					selectFn: file => (
						<a href={file.url} target="_blank" rel="noreferrer">
							{file.name}
						</a>
					),
				},
			]}
		/>
	);
};

export default InvoiceStepAttachments;
