import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
	GetWebLocations,
	GetWebLocationsVariables,
	GetMovableLocations,
	GetMovableLocationsVariables,
	GetMovables,
	GetMovableStatusOptions,
	UpdateMovable,
	UpdateMovableVariables,
} from '../../GraphQL';
import { IMovableCreation } from '../../Schemas/IMovableCreation';
import { movableEditSchema } from '../../Schemas/MovableEditSchema';
import Button from '@ssg/common/Components/Button';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Loading from '@ssg/common/Components/Loading';
import Textarea from '@ssg/common/Components/Textarea';
import { faScanner } from '@fortawesome/pro-regular-svg-icons';
import Modal from '@ssg/common/Components/Modal';
import BarcodeScanner from './BarcodeScanner';
import { MOVABLE_PLACEMENT_PREFIX } from './movablePrefixes';

const GET_LOCATIONS = loader('src/GraphQL/Locations/GetWebLocations.gql');
const GET_MOVABLE_STATUS_OPTIONS = loader('src/GraphQL/Movables/GetMovableStatusOptions.gql');
const GET_MOVABLE_LOCATIONS = loader('src/GraphQL/Movables/GetMovableLocations.gql');
const UPDATE_MOVABLE = loader('src/GraphQL/Movables/UpdateMovable.gql');

type IMovableEdit = Pick<IMovableCreation, 'description' | 'status' | 'location' | 'placement' | 'volume'>;

interface Props {
	movable: GetMovables['movables'][number];
	status: string;
	callback: (id: string) => unknown;
}

const EditMovable: React.FC<Props> = ({ movable, status, callback }) => {
	const { t } = useTranslation();

	const [volumeExceeded, setVolumeExceeded] = React.useState(false);

	const { register, watch, errors, formState, setValue, handleSubmit } = useForm<IMovableEdit>({
		resolver: yupResolver(movableEditSchema),
		defaultValues: {
			description: movable.description,
			status: movable.status,
			location: movable.placement.location.id,
			placement: movable.placement.id,
			volume: movable.volume,
		},
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const locationValue = watch('location');
	const placementValue = watch('placement');
	const volumeValue = watch('volume');

	const { data: locations, loading: locationsLoading } = useQuery<GetWebLocations, GetWebLocationsVariables>(GET_LOCATIONS, {
		variables: { movableLocationsOnly: true },
	});

	const { data: movableStatusOptions, loading: movableStatusOptionsLoading } = useQuery<GetMovableStatusOptions>(GET_MOVABLE_STATUS_OPTIONS, {
		fetchPolicy: 'cache-first',
	});
	React.useEffect(() => {
		if (typeof movableStatusOptions?.movableStatusOptions !== 'undefined') {
			const match =
				movableStatusOptions.movableStatusOptions.find(opt => status.toUpperCase() === opt.toUpperCase()) ??
				movableStatusOptions.movableStatusOptions.find(opt => status.toUpperCase().startsWith(opt.toUpperCase()));

			if (typeof match !== 'undefined') {
				setValue('status', match, { shouldDirty: true });
			}
		}
	}, [movableStatusOptions?.movableStatusOptions, setValue, status]);

	const { data: movableLocations, loading: movableLocationsLoading } = useQuery<GetMovableLocations, GetMovableLocationsVariables>(GET_MOVABLE_LOCATIONS, {
		fetchPolicy: 'cache-and-network',
		// variables: { location: locationValue },
		// skip: (locationValue ?? '').length === 0,
	});

	const filteredMovableLocations = useMemo(() => movableLocations?.movablesLocations.filter(m => m.location.id === locationValue), [movableLocations?.movablesLocations, locationValue]);

	const [showScannerModal, setShowScannerModal] = React.useState(false);
	const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);
	React.useEffect(() => {
		if (typeof scannedId !== 'undefined') {
			console.log(scannedId);
			setShowScannerModal(false);

			if (scannedId.startsWith(MOVABLE_PLACEMENT_PREFIX)) {
				const placement = movableLocations?.movablesLocations.find(ml => ml.id === scannedId.substring(MOVABLE_PLACEMENT_PREFIX.length));
				console.log(movableLocations?.movablesLocations);
				console.log(placement);
				if (typeof placement !== 'undefined') {
					setValue('location', placement.location.id, {
						shouldDirty: true,
						shouldValidate: true,
					});
					window.setTimeout(() => {
						// Wrapped in timeout since the line above will cause dropdown to rerender and in the process reset the value
						setValue('placement', placement.id, {
							shouldDirty: true,
							shouldValidate: true,
						});
					}, 0);

					setValue('placement', placement.id);
				}
			}
		}
	}, [movableLocations?.movablesLocations, scannedId, setValue]);

	const [updateMovable, { loading: updateMovableSubmitting }] = useMutation<UpdateMovable, UpdateMovableVariables>(UPDATE_MOVABLE);

	const onSubmit = async (data: IMovableCreation) => {
		// Get updatable values
		const { description, status, placement, volume } = data;

		// TODO: Refactor to accept number
		const volumeAsNumber = parseFloat(volume.toString());

		// Edit movable and update cache
		if (movable.description !== description || movable.status !== status || movable.placement.id !== placement || movable.volume !== volume) {
			await updateMovable({
				variables: {
					id: movable.id,
					description,
					status,
					placement,
					volume: volumeAsNumber,
				},
			});
		}

		// Call callback fn
		callback(movable.id);
	};

	// Calc if placement volume is exceeded
	React.useEffect(() => {
		setVolumeExceeded(false);

		// If movable placement is the same
		if (placementValue === movable.placement.id) {
			const orgPlacement = movableLocations?.movablesLocations.find(m => m.id === movable.placement.id);

			if (typeof orgPlacement === 'undefined') {
				setVolumeExceeded(false);
				return;
			}

			// Subtract movable org.volume from placement accVolume
			const calcPlacementVolume = orgPlacement.accVolume - movable.volume + parseFloat(volumeValue.toString());

			setVolumeExceeded(calcPlacementVolume > orgPlacement.volume);

			// If movable placement has changed
		} else {
			const newPlacement = movableLocations?.movablesLocations.find(m => m.id === placementValue);

			if (typeof newPlacement === 'undefined') {
				setVolumeExceeded(false);
				return;
			}

			const calcPlacementVolume = newPlacement.accVolume + parseFloat(volumeValue.toString());

			setVolumeExceeded(calcPlacementVolume > newPlacement.volume);
		}
	}, [movable.placement.id, movable.volume, movableLocations?.movablesLocations, placementValue, volumeValue]);

	if (locationsLoading || movableStatusOptionsLoading) {
		return <Loading />;
	}

	return (
		<>
			<form className="space-y-8 text-sm" onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Textarea name="description" title="common.description" rows={3} errorMessage={errors.description?.message} innerRef={register} />
				</div>

				<div>
					<Dropdown
						name="status"
						title="common.status"
						data={[{ label: '', value: '' }, ...(movableStatusOptions?.movableStatusOptions ?? []).map(so => ({ label: so, value: so }))]}
						defaultValue={movable.status}
						errorMessage={errors.status?.message}
						innerRef={register}
					/>
				</div>

				<div className="w-3/4">
					<Dropdown
						name="location"
						title="common.location"
						data={[
							{ label: '', value: '' },
							...(locations?.locations ?? []).map(l => ({
								label: l.name,
								value: l.id,
							})),
						]}
						defaultValue={movable.placement.location.id}
						errorMessage={errors.location?.message}
						innerRef={register}
					/>
				</div>

				<div className="float-right">
					<div className="-mt-10 mr-8">
						<Button
							primary
							icon={faScanner}
							iconSize="2x"
							onClick={() => {
								setScannedId(undefined);
								setShowScannerModal(true);
							}}
							className="px-8 py-6"
						/>
					</div>
				</div>

				<div className="w-3/4">
					{movableLocationsLoading ? (
						<Dropdown name="loadingPlacements" title="common.placement" disabled />
					) : (
						<Dropdown
							name="placement"
							title="common.placement"
							data={[
								{ label: '', value: '' },
								...(filteredMovableLocations ?? []).map(ml => ({
									label: `${ml.name} - ${ml.accVolume} / ${ml.volume}`,
									value: ml.id,
								})),
							]}
							defaultValue={movable.placement.id}
							errorMessage={errors.placement?.message}
							innerRef={register}
							disabled={(locationValue ?? '').length === 0}
						/>
					)}
				</div>

				<div>
					<Input
						name="volume"
						type="number"
						unit="m&sup3;"
						min={0}
						step={0.01}
						title="movable.volume"
						errorMessage={errors.volume?.message}
						innerRef={register}
						className={volumeExceeded ? 'border-orange focus:border-orange border-2' : ''}
					/>

					{volumeExceeded && <p className="mt-0">{t('movable.placementVolumeExceeded')}</p>}
				</div>

				<div className="pl-4">
					<Button submit success text="movable.saveMovable" loading={updateMovableSubmitting} disabled={!formState.isValid || updateMovableSubmitting} />
				</div>
			</form>

			<Modal title="movable.scanLabel" close={() => setShowScannerModal(false)} visible={showScannerModal} body={<BarcodeScanner setScannedId={setScannedId} />} />
		</>
	);
};
export default EditMovable;
