import classNames from 'classnames';
import React from 'react';
import { DateTime, Interval } from 'luxon';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import { useTranslation } from 'react-i18next';
import toSentenceCase from '@ssg/common/Helpers/toSentenceCase';
import { EventFragment } from '@ssg/common/GraphQL/indexV2';
import PlannerDaySlot from './PlannerDaySlot';
import { NumberOfDays, getWeekDay } from './PlannerHelpers';
import { UserCalendarAndColor } from './PlannerSchedule';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';

interface Props {
	leftBoxElement: React.ReactNode;
	top?: boolean;
	dates: string[];
	numberOfDays: NumberOfDays;
	userCalendarAndColor: UserCalendarAndColor;
}
const today = DateTime.now().toISODate();

function getWeekNumber(dateIso: string, index: number): number | undefined {
	const date = DateTime.fromISO(dateIso);
	if (index === 0) {
		return date.weekNumber;
	}
	const dayNo = date.weekday;

	if (dayNo === 1) {
		return date.weekNumber;
	}
}

function eventsOnThisDate(events: EventFragment[], date: string) {
	const eventToReturn = events.filter(e => formatDateForInput(new Date(e.timeRange.from.replace('Z', '') + 'Z'), true).includes(date)).sort((a, b) => a.timeRange.from.localeCompare(b.timeRange.from));
	const differentDaysEvent = events.filter(e => {
		const from = formatDateForInput(new Date(e.timeRange.from.replace('Z', '') + 'Z'));
		const to = formatDateForInput(new Date(e.timeRange.to.replace('Z', '') + 'Z'));
		return from !== to;
	});

	differentDaysEvent.forEach(e => {
		const fromDate = DateTime.fromISO(e.timeRange.from.replace('Z', '') + 'Z');
		const toDate = DateTime.fromISO(e.timeRange.to.replace('Z', '') + 'Z');
		const numberOfDays = Interval.fromDateTimes(fromDate, toDate).length('days');
		const plusDay = e.isAllDay ? 0 : 1;
		const arr = Array.from({ length: numberOfDays + plusDay }, (_, i) => fromDate.plus({ days: i }).toISODate());
		if (arr.includes(date)) {
			if (!eventToReturn.includes(e)) {
				eventToReturn.push(e);
			}
		}
	});

	return eventToReturn.sort((a, b) => a.timeRange.from.localeCompare(b.timeRange.from));
}

const PlannerLine: React.FC<Props> = ({ leftBoxElement, dates, numberOfDays, top = false, userCalendarAndColor: { user, events, color } }): React.ReactElement => {
	const { t } = useTranslation();
	return (
		<div
			className={classNames('flex flex-row border-b-1 z-50', {
				'sticky top-0 left-0 h-26': top,
			})}
			style={{ width: numberOfDays === 14 ? `${(12 * numberOfDays) + 16}rem` : undefined, minHeight: !top ? '7rem' : undefined }}
		>
			<div className={classNames('w-64 sticky left-0 top-0 z-40', color)}>{leftBoxElement}</div>
			{dates.map((d, index) => <div className={classNames('border-l-1', color, {
				'flex-1': numberOfDays === 7,
				'w-48': numberOfDays === 14,
				'bg-opacity-25': !top,
			})} key={d + user.id}>{
					top
						?
						<div className="text-center flex flex-col justify-between h-full pb-2">
							<div>
								<p className="w-full text-left text-sm pl-1">{getWeekNumber(d, index) ? `Uge ${getWeekNumber(d, index)} ` : ''}</p>
							</div>
							<div>
								<p>{getWeekDay(d)} {today === d && `(${t('planner.today')})`}</p>
								<p>{dateToDateTimeString(d, true)}</p>
							</div>
						</div>
						: <PlannerDaySlot date={d} events={eventsOnThisDate(events, d)} user={{ color, user }} />

				}</div>)}
		</div>
	);
};

export default PlannerLine;