import React from 'react';
import { Permissions } from '../GraphQL';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import arraysHasMatchingValue from '../Helpers/arraysHasMatchingValue';

interface Props extends RouteProps {
	userPermissions: Permissions[];
	requiredPermissions: Permissions[];
	redirect?: string;
}

const RestrictedRoute: React.FC<Props> = ({ userPermissions, requiredPermissions, redirect = '/', component, render, ...restProps }): React.ReactElement => {
	const canSee = arraysHasMatchingValue(requiredPermissions, userPermissions);
	if (!canSee) {
		return <Redirect to={redirect} />;
	}

	return (
		<Route {...restProps} render={(props): React.ReactNode => (typeof component !== 'undefined' ? React.createElement(component, props) : typeof render !== 'undefined' ? render(props) : null)} />
	);
};

export default RestrictedRoute;
