import { useMutation, useQuery } from '@apollo/client';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ssg/common/Components/Button';
import Loading from '@ssg/common/Components/Loading';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import { loader } from 'graphql.macro';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AddCaseFilesToDrivingSlip,
	AddCaseFilesToDrivingSlipVariables,
	FileActionType,
	GetCaseFiles,
	GetCaseFilesVariables,
	GetDrivingSlipFiles,
	GetDrivingSlipFilesVariables,
	LogDrivingSlipFileChange,
	LogDrivingSlipFileChangeVariables,
} from '../../GraphQL';

const GET_CASE_FILES = loader('src/GraphQL/Files/GetCaseFiles.gql');
const GET_DRIVING_SLIP_FILES = loader('src/GraphQL/Files/GetDrivingSlipFiles.gql');
const ADD_CASE_FILES_TO_DRIVING_SLIP = loader('src/GraphQL/Files/AddCaseFilesToDrivingSlip.gql');
const LOG_FILE_CHANGE = loader('src/GraphQL/Files/LogDrivingSlipFileChange.gql');

interface BaseProps {
	open: boolean;
	close: () => void;
	caseErpNo: string;
	excludeFileNames: string[];
}

interface DirectUploadProps extends BaseProps {
	series: string;
}

interface DelayedUploadProps extends BaseProps {
	upload: (fileNames: string[]) => unknown;
}

type Props = DirectUploadProps | DelayedUploadProps;

const AddExistingFileToDrivingSlip: FC<Props> = ({ open, close, caseErpNo, excludeFileNames, ...restProps }) => {
	const { t } = useTranslation();

	const [filesToAdd, setFilesToAdd] = React.useState<string[]>([]);

	const { data: caseFilesList, loading: loadingCaseFiles } = useQuery<GetCaseFiles, GetCaseFilesVariables>(GET_CASE_FILES, {
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-only',
		variables: {
			caseErpNo: caseErpNo,
		},
	});

	const [addDrivingSlipFiles, { loading: loadingUploadFiles }] = useMutation<AddCaseFilesToDrivingSlip, AddCaseFilesToDrivingSlipVariables>(ADD_CASE_FILES_TO_DRIVING_SLIP);
	const [logFileChange] = useMutation<LogDrivingSlipFileChange, LogDrivingSlipFileChangeVariables>(LOG_FILE_CHANGE);

	const caseFiles = useMemo(() => caseFilesList?.caseFiles?.filter(file => !excludeFileNames.includes(file.name)) ?? [], [caseFilesList, excludeFileNames]);

	const addFiles = async () => {
		if ('upload' in restProps) {
			restProps.upload(filesToAdd);
		} else {
			const series = restProps.series;

			await addDrivingSlipFiles({
				variables: {
					caseFolder: caseErpNo,
					seriesFolder: series,
					filenames: filesToAdd,
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseErpNo,
							drivingSlipId: series,
						},
					});

					if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
						return;
					}

					cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseErpNo,
							drivingSlipId: series,
						},
						data: {
							drivingSlipFiles: [...cachedRequest.drivingSlipFiles, ...cacheData.addCaseFilesToDrivingSlip],
						},
					});

					const cachedRequestCaseFile = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseErpNo,
						},
					});

					if (cachedRequestCaseFile === null || cachedRequestCaseFile.caseFiles === null) {
						return;
					}

					cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseErpNo,
						},
						data: {
							caseFiles: cachedRequestCaseFile.caseFiles.filter(fil => !cacheData.addCaseFilesToDrivingSlip.some(caseFile => caseFile.name === fil.name)),
						},
					});
				},
			});

			await logFileChange({
				variables: {
					filename: filesToAdd.map(val => `${val}`).join(', '),
					action: FileActionType.ADD,
					series: series,
				},
			});
		}
		close();
	};

	return (
		<Modal
			title="drivingSlips.addFilesFromCase"
			size={ModalSize.MEDIUM}
			visible={open}
			close={close}
			nestedModal={'upload' in restProps}
			body={
				<div className="text-blue">
					{loadingCaseFiles || loadingUploadFiles ? (
						<div className="relative h-40">
							<Loading />
						</div>
					) : (
						<Table
							data={caseFiles ?? []}
							columns={[
								{
									label: 'common.name',
									selectFn: f => <p>{f.name.substring(0, f.name.lastIndexOf('.'))}</p>,
								},
								{
									label: t('common.add'),
									classNameTh: 'text-right',
									selectFn: f => (
										<div>
											<div className="flex content-start justify-end text-right">
												{!filesToAdd.includes(f.name) ? (
													<FontAwesomeIcon icon={faSquare} size="lg" onClick={() => setFilesToAdd(current => [...current, f.name])} className="cursor-pointer" />
												) : (
													<FontAwesomeIcon icon={faCheckSquare} size="lg" onClick={() => setFilesToAdd(current => current.filter(name => name !== f.name))} />
												)}
											</div>
										</div>
									),
								},
							]}
							keySelector={f => f.name}
							noDataFoundText="catalog.noFiles"
						/>
					)}
				</div>
			}
			footer={<Button success text="common.add" onClick={() => addFiles()} disabled={filesToAdd.length === 0} />}
		/>
	);
};

export default AddExistingFileToDrivingSlip;
