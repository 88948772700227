import React from 'react';
import { useQuery } from '@apollo/client';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { GetWebCases_cases, GetCauseQuestions, GetCauseQuestionsVariables } from '../../GraphQL';
import ProgressIcon, { ProgressColor } from '../../Components/ProgressIcon';

const GET_CAUSE_QUESTIONS = loader('src/GraphQL/VisitationQuestions/GetCauseQuestions.gql');

interface Question {
	id: string;
	question: string;
	answer: string;
}

interface Props {
	caseData: GetWebCases_cases;
}

const CaseVisitation: React.FC<Props> = ({ caseData }) => {
	const { t } = useTranslation();

	const { data: questions } = useQuery<GetCauseQuestions, GetCauseQuestionsVariables>(GET_CAUSE_QUESTIONS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			damageCause: caseData.damage.cause.id,
		},
		skip: caseData.visitation.answers === null || (caseData.visitation.answers !== null && caseData.visitation.answers.findIndex(a => a.answer !== '') === -1),
	});

	const questionsMap = new Map<string, Question>();

	questions?.causeQuestions.forEach(q => {
		questionsMap.set(q.id, { id: q.id, question: q.question, answer: '' });
	});

	questionsMap.forEach(qm => {
		caseData.visitation.answers?.forEach(a => {
			if (a.question === qm.id && a.answer !== '') {
				questionsMap.set(qm.id, {
					id: qm.id,
					question: qm.question,
					answer: a.answer,
				});
			} else if (a.question === qm.id && a.answer === '') {
				questionsMap.delete(qm.id);
			}
		});
	});

	const questionsArray = Array.from(questionsMap.values()).filter(q => q.answer !== '');

	return (
		<>
			{caseData.visitation.answers && questionsArray.length > 0 && (
				<ProgressIcon icon={faFileAlt} popperTitle={t('case.visitationQuestions')} iconColor={ProgressColor.BLUE}>
					{questionsArray.map((q, i) => {
						return (
							<div key={q.id}>
								<span className="mr-1 font-semibold">{q.question}:</span>
								<span>{q.answer}</span>
							</div>
						);
					})}
				</ProgressIcon>
			)}
		</>
	);
};

export default CaseVisitation;
