import React from 'react';
import Button from '@ssg/common/Components/Button';
import Modal from '@ssg/common/Components/Modal';
import Textarea from '@ssg/common/Components/Textarea';
import { GetOperationManagerCaseData_cases } from '../../../GraphQL';
import { CaseStatus } from '@ssg/common/GraphQL';

interface Props {
	singleCase: GetOperationManagerCaseData_cases | null;
	visible: boolean;
	close: () => void;
	rejectCaseClosing: (caseId: string, applicantEmail: string, rejectionComment: string) => Promise<void>;
}

const AppliedClosedCasseRejectionModal: React.FC<Props> = ({ singleCase, visible, close, rejectCaseClosing }) => {
	const [rejectionComment, setRejectionComment] = React.useState<null | string>(null);

	const replaceWithBr = (comment: string) => {
		return comment.replace(/\n/g, '<br />');
	};

	return (
		<Modal
			visible={visible}
			close={close}
			title="myPage.appliedClosedCaseRejection.modal.title"
			body={
				<Textarea title="myPage.appliedClosedCaseRejection.modal.rejectionComment" name="rejectionComment" value={rejectionComment ?? ''} onChange={e => setRejectionComment(e.target.value)} />
			}
			footer={
				<div className="flex w-full flex-row justify-between">
					<Button
						text="myPage.appliedClosedCaseRejection.modal.reject"
						disabled={!rejectionComment}
						danger
						onClick={async () => {
							if (!rejectionComment || singleCase === null) {
								return;
							}
							const applicantEmail = singleCase.changes.find(ch => ch.after.status === CaseStatus.APPLIED_CLOSED)?.user?.id;
							if (applicantEmail === undefined) {
								return;
							}

							const commentWithBrakes = replaceWithBr(rejectionComment);
							await rejectCaseClosing(singleCase.id, applicantEmail, commentWithBrakes);

							close();
						}}
					/>
				</div>
			}
		/>
	);
};

export default AppliedClosedCasseRejectionModal;
