import React from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Input from '@ssg/common/Components/Input';
import { GetWebCatalogCustomers_catalogCustomers } from '../../../GraphQL';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import Pill from '@ssg/common/Components/Pill';
import { useForm } from 'react-hook-form';

interface IProps {
	setFilterTerm: (v: string) => void;
	catalogCustomers: GetWebCatalogCustomers_catalogCustomers[];
	customerIds: string[] | null;
	setCustomerIds(ids: React.SetStateAction<string[] | null>): void;
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
}

const CraftsmenFilter: React.FC<IProps> = ({ setFilterTerm, catalogCustomers, customerIds, setCustomerIds, catalogCustomer }) => {
	const { t } = useTranslation();
	const dummyForm = useForm();

	const [customerSearchText, setCustomerSearchText] = React.useState('');

	const [customers, setCustomers] = React.useState<SelectOption[]>([]);
	React.useEffect(() => {
		setCustomers(catalogCustomers.filter(ct => ct.name.toLowerCase().includes(customerSearchText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, customerSearchText]);

	if (typeof catalogCustomer !== 'undefined') return <div></div>;
	return (
		<div className="text-blue mx-0">
			<div className="flex w-full flex-row flex-wrap">
				<div className="mr-3">
					<label className="block text-xs font-medium">{t('common.search')}</label>
					<div className="flex">
						<div className="relative w-full">
							<div className="pointer-events-none absolute mt-2 flex content-start justify-end pr-2 lg:w-full">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<Input name="overview-search" placeholder={t('common.searchPlaceholder')} className="pr-8 text-sm lg:w-full" onChange={e => setFilterTerm(e.target.value)} />
						</div>
					</div>
				</div>
				<div className="mr-3">
					<label className="mb-1 block text-xs font-medium">
						{t('catalog.customer')}
						<SearchableSelect
							key="customer-filter"
							control={dummyForm.control}
							name="customerFilter"
							options={customers}
							searchFn={searchText => setCustomerSearchText(searchText)}
							onSelect={value => {
								if (value !== '') setCustomerIds(ids => [...(ids ?? []), value]);
							}}
							onBlur={clearFn => clearFn()}
							minInputLength={-1}
							isLoading={false}
							className="lg:w-full"
						/>
					</label>

					<ul className="text-xs">
						{customerIds?.map(d => (
							<li key={d}>
								<Pill onClick={() => setCustomerIds(ids => (ids ?? []).filter(id => id !== d))}>{catalogCustomers.filter(cc => cc.id === d)[0].name}</Pill>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default CraftsmenFilter;
