import * as yup from 'yup';

yup.setLocale({
	mixed: {
		required: 'error.required', // Translation key goes here
		notType: 'error.required',
	},
	string: {
		required: 'error.required',
		email: 'error.correctEmail',
	},
	number: {
		required: 'error.required',
	},
	boolean: {
		isValue: 'error.required',
	},
});

export const UpdateEventSchema = yup.object().shape(
	{
		type: yup.string().required(),

		title: yup.string().required(),

		fromDate: yup.string().required(),

		toDate: yup.string().required(),

		userId: yup.string().when('carId', {
			is: (carId: string | undefined) => typeof carId === 'undefined' || carId.length === 0,
			then: yup.string().required(),
			otherwise: yup.string(),
		}),

		carId: yup.string().when('userId', {
			is: (userId: string | undefined) => typeof userId === 'undefined' || userId.length === 0,
			then: yup.string().required(),
			otherwise: yup.string(),
		}),
	},
	[['userId', 'carId']],
);
