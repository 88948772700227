import React from 'react';

export default function useSaveFile(bytes: number[] | undefined, fileName: string): void {
	React.useEffect(() => {
		if (typeof bytes !== 'undefined') {
			const byteArray = new Uint8Array(bytes);

			const a = window.document.createElement('a');
			a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
			a.download = fileName;

			document.body.appendChild(a);
			a.click();

			document.body.removeChild(a);
		}
	}, [bytes, fileName]);
}
