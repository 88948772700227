import React from 'react';
import { DeepMap, FieldError, UseFormMethods, useWatch } from 'react-hook-form';
import { ICaseCreation } from '../../Schemas/ICaseCreation';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { useQuery } from '@apollo/client';
import { GetWebCaseAdminData_damageCauses, GetWebCaseAdminData_departments, GetWebCaseAdminData_locations, GetWebCase_case, SearchWebUsers, SearchWebUsersVariables } from '../../GraphQL';
import { loader } from 'graphql.macro';
import Box from '../../Components/Layout/Box';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import useDebouncedState from '@ssg/common/Hooks/useDebouncedState';

const SEARCH_USERS = loader('../../GraphQL/Users/SearchWebUsers.gql');

interface Props {
	caseData: GetWebCase_case | undefined;
	causeList: GetWebCaseAdminData_damageCauses[];
	departmentsList: GetWebCaseAdminData_departments[];
	locationsList: GetWebCaseAdminData_locations[];
	register: UseFormMethods['register'];
	errors: DeepMap<ICaseCreation, FieldError>;
	control: UseFormMethods['control'];
	setValue: UseFormMethods['setValue'];
}

const SSGInformationBox: React.FC<Props> = ({ caseData, causeList, departmentsList, locationsList, register, errors, control, setValue }): React.ReactElement => {
	const [showHelpText, setShowHelpText] = React.useState(false);
	const [projectManagerSearchText, setProjectManagerSearchText] = useDebouncedState(caseData?.projectManager?.name ?? '', 100);
	const [caseWorkerSearchText, setCaseWorkerSearchText] = useDebouncedState(caseData?.caseManager?.name ?? '', 100);

	const searchProjectManagerSelectHandler = React.useCallback(
		(value: string): void => {
			setValue('SSGInformationBox.ssgProjectManager', value, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	const searchCaseWorkerSelectHandler = React.useCallback(
		(value: string): void => {
			setValue('SSGInformationBox.ssgCaseManager', value, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	React.useEffect(() => {
		searchProjectManagerSelectHandler(caseData?.projectManager?.id ?? '');
	}, [caseData?.projectManager, searchProjectManagerSelectHandler]);

	React.useEffect(() => {
		searchCaseWorkerSelectHandler(caseData?.caseManager?.id ?? '');
	}, [caseData?.caseManager, searchCaseWorkerSelectHandler]);

	const { loading: pmLoading, data: pmData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: projectManagerSearchText,
			onlyEmployees: true,
		},
		skip: projectManagerSearchText === '',
	});

	const { loading: cwLoading, data: cwData } = useQuery<SearchWebUsers, SearchWebUsersVariables>(SEARCH_USERS, {
		variables: {
			searchText: caseWorkerSearchText,
			onlyEmployees: true,
		},
		skip: caseWorkerSearchText === '',
	});

	const selectedLocation = useWatch({
		control,
		name: 'SSGInformationBox.ssgLocation',
	});

	const selectedDamageCause = useWatch({
		control,
		name: 'DamageBox.damageCause',
	});

	const damageCountry = useWatch({
		control,
		name: 'DamageAddressBox.damageCountry',
	});

	const damagePostalCode: string | undefined = useWatch({
		control,
		name: 'DamageAddressBox.damageZip',
	});

	const pmUsers = React.useMemo(() => {
		return (pmData?.searchUsers ?? []).map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [pmData]);

	const cwUsers = React.useMemo(() => {
		return (cwData?.searchUsers ?? []).map((d): SelectOption => ({ value: d.id, label: d.name }));
	}, [cwData]);

	const locList = React.useMemo<SelectOption[]>(() => [{ label: '', value: '' }, ...locationsList.map(d => ({ value: d.id, label: d.name }))], [locationsList]);

	const depList = React.useMemo<SelectOption[]>(
		() => [
			{ label: '', value: '' },
			...departmentsList
				.filter(l => selectedLocation === l.location.id)
				.map(d => ({
					value: d.id,
					label: `${d.name} (${d.departmentNumber})`,
				})),
		],
		[departmentsList, selectedLocation],
	);

	React.useEffect(() => {
		if (causeList) {
			const cause = causeList.find(c => c.id === selectedDamageCause);
			if (cause?.departments) {
				const department = cause.departments.find(d => d?.location.id === selectedLocation);
				if (department) {
					setValue('SSGInformationBox.ssgDepartment', department.id);
				}
			}
		}
	}, [selectedDamageCause, causeList, selectedLocation, setValue, depList]);

	React.useEffect(() => {
		if (typeof caseData === 'undefined') {
			if (typeof damagePostalCode !== 'undefined') {
				const postalCodeLocation = locationsList.find(l => l.attachedPostalCodes.includes(damagePostalCode));
				if (typeof postalCodeLocation !== 'undefined') {
					setValue('SSGInformationBox.ssgLocation', postalCodeLocation.id);
				} else {
					setValue('SSGInformationBox.ssgLocation', '');
				}
			}
		}
	}, [caseData, damageCountry, damagePostalCode, locationsList, setValue, locList]);

	return (
		<Box form title="case.ssgInformation" half={true} onClick={() => setShowHelpText(!showHelpText)} showHelpText={showHelpText}>
			<hr />
			<div className="w-full lg:w-3/4">
				{showHelpText && <FormHelpText text="case.helpText.ssgInformation" />}

				<SearchableSelect
					control={control}
					name="SSGInformationBox.ssgProjectManager"
					title="case.ssgProjectManager"
					options={pmUsers}
					searchFn={searchText => setProjectManagerSearchText(searchText)}
					onSelect={value => searchProjectManagerSelectHandler(value)}
					onBlur={() => undefined}
					minInputLength={2}
					isLoading={pmLoading}
					helpText="case.helpText.ssgProjectManager"
					errorMessage={errors.SSGInformationBox?.ssgProjectManager?.message ?? ''}
					showHelpText={showHelpText}
					initialSelection={
						caseData?.projectManager
							? {
								value: caseData?.projectManager.id,
								label: caseData?.projectManager.name,
							}
							: undefined
					}
				/>

				<SearchableSelect
					control={control}
					name="SSGInformationBox.ssgCaseManager"
					title="case.ssgCaseManager"
					options={cwUsers}
					searchFn={searchText => setCaseWorkerSearchText(searchText)}
					onSelect={value => searchCaseWorkerSelectHandler(value)}
					onBlur={() => undefined}
					minInputLength={2}
					isLoading={cwLoading}
					helpText="case.helpText.ssgCaseManager"
					errorMessage={errors.SSGInformationBox?.ssgCaseManager?.message ?? ''}
					showHelpText={showHelpText}
					initialSelection={
						caseData?.caseManager
							? {
								value: caseData?.caseManager.id,
								label: caseData?.caseManager.name,
							}
							: undefined
					}
				/>

				<Dropdown
					required
					title="common.location"
					name="SSGInformationBox.ssgLocation"
					errorMessage={errors.SSGInformationBox?.ssgLocation?.message ?? ''}
					data={locList}
					innerRef={register}
					helpText="case.helpText.ssgLocation"
					showHelpText={showHelpText}
					defaultValue={caseData?.ssgLocation.id}
				/>

				<Dropdown
					required
					title="common.department"
					name="SSGInformationBox.ssgDepartment"
					errorMessage={errors.SSGInformationBox?.ssgDepartment?.message ?? ''}
					data={depList}
					innerRef={register}
					helpText="case.helpText.ssgDepartment"
					showHelpText={showHelpText}
					defaultValue={caseData?.ssgDepartment.id}
				/>
			</div>
		</Box>
	);
};

export default SSGInformationBox;
