import React from 'react';
import { useQuery } from '@apollo/client';
import { faCheck, faExchange } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { GetWebCase, GetDebitor, GetDebitorVariables, GetInvoiceHeader, GetInvoiceHeaderVariables, GetSalesInvoiceLines, GetSalesInvoiceLinesVariables } from '../../GraphQL';
import classNames from 'classnames';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import Loading from '@ssg/common/Components/Loading';
import Modal from '@ssg/common/Components/Modal';
import Table from '@ssg/common/Components/Table';
import Textarea from '@ssg/common/Components/Textarea';
import Box from '../../Components/Layout/Box';
import BoxContainer from '@ssg/common/Components/BoxContainer';
import BoxFormDataViewer from '../../Components/Layout/BoxFormDataViewer';
import Header from '@ssg/common/Components/Header';
import { countries } from '@ssg/common/Helpers/countries';

const GET_CASE = loader('../../GraphQL/Cases/GetWebCase.gql');
const GET_DEBITOR = loader('../../GraphQL/Debitors/GetDebitor.gql');
const GET_INVOICE_HEADER = loader('../../GraphQL/Invoices/GetInvoiceHeader.gql');
const GET_SALES_INVOICE_LINES = loader('../../GraphQL/Invoices/GetSalesInvoiceLines.gql');

const CreditNote: React.FC = (): React.ReactElement => {
	const { id, docuNo } = useParams<{ id: string; docuNo: string }>();
	const { t } = useTranslation();
	const [editSellTo, setEditSellTo] = React.useState(false);
	const [editBillTo, setEditBillTo] = React.useState(false);

	const { loading: caseLoading, data: caseData } = useQuery<GetWebCase>(GET_CASE, {
		variables: {
			id: id,
		},
	});

	const { data: debitorData } = useQuery<GetDebitor, GetDebitorVariables>(GET_DEBITOR, {
		variables: {
			id: caseData?.case.debitor.company ?? '',
		},
	});

	const { data: headerData } = useQuery<GetInvoiceHeader, GetInvoiceHeaderVariables>(GET_INVOICE_HEADER, {
		variables: {
			caseERPReferenceNo: caseData?.case.erpNo ?? '', //TODO: CHANGE TO VARIABLE
		},
	});

	const { data: linesData } = useQuery<GetSalesInvoiceLines, GetSalesInvoiceLinesVariables>(GET_SALES_INVOICE_LINES, {
		variables: {
			offerERPReferenceNo: docuNo,
		},
	});

	const [header] = headerData?.invoiceHeaders.filter(h => h.no === docuNo) ?? [];

	// Hvad skal man kunne ændre til?
	const injuredParty: SelectOption[] = [
		{ value: '', label: 'case.injuredParty' },
		{ value: caseData?.case.damage.contact.name ?? '', label: '' },
		{
			value: caseData?.case.damage.contact.address.road + ' ' + caseData?.case.damage.contact.address.houseNumber + ' ' + caseData?.case.damage.contact.address.floor ?? '',
			label: '',
		},
		{
			value: caseData?.case.damage.contact.address.addressLineAlt ?? '',
			label: '',
		},
		{
			value: caseData?.case.damage.contact.address.postalCode + ' ' + caseData?.case.damage.contact.address.city,
			label: '',
		},
		{
			value: countries.find(c => c.value === caseData?.case.damage.contact.address.country)?.label ?? '',
			label: '',
		},
	];

	const debitor: SelectOption[] = [
		{ value: '', label: 'common.debitor' },
		{ value: debitorData?.debitor.company ?? '', label: '' },
		{ value: caseData?.case.debitor.attention ?? '', label: '' },
		{
			value:
				caseData?.case.debitor.billingAddress?.road + ' ' + caseData?.case.debitor.billingAddress?.houseNumber + ' ' + caseData?.case.debitor.billingAddress?.floor ??
				debitorData?.debitor.address?.addressLine ??
				'',
			label: '',
		},
		{
			value: caseData?.case.debitor.billingAddress?.addressLineAlt ?? debitorData?.debitor.address?.addressLineAlt ?? '',
			label: '',
		},
		{
			value:
				(caseData?.case.debitor.billingAddress?.postalCode ?? debitorData?.debitor.address?.postalCode) +
				'' +
				(caseData?.case.debitor.billingAddress?.city ?? debitorData?.debitor.address?.city),
			label: '',
		},
		{
			value: countries.find(c => c.value === caseData?.case.debitor.billingAddress?.country)?.label ?? '',
			label: '',
		},
	];

	//policyHolder

	const policyHolder: SelectOption[] = [
		{ value: '', label: 'case.policyHolder' },
		{ value: caseData?.case.policyHolder.name ?? '', label: '' },
		{
			value: caseData?.case.policyHolder.address.road + ' ' + caseData?.case.policyHolder.address.houseNumber + ' ' + caseData?.case.policyHolder.address.floor ?? '',
			label: '',
		},
		{
			value: caseData?.case.policyHolder.address.addressLineAlt ?? '',
			label: '',
		},
		{
			value: caseData?.case.policyHolder.address.postalCode + ' ' + caseData?.case.policyHolder.address.city,
			label: '',
		},
		{
			value: countries.find(c => c.value === caseData?.case.policyHolder.address.country)?.label ?? '',
			label: '',
		},
	];

	//TODO: Should this be changeable in backend/admin
	const ssgInfo: SelectOption[] = [
		{ value: 'BELFOR Denmark A/S', label: '' },
		{ value: 'Knapholm 6', label: '' },
		{ value: '2730 Herlev', label: '' },
		{ value: 'Danmark', label: '' },
		{ value: '+4570153800', label: '' },
		{ value: 'mail@ssg.dk', label: '' },
		{ value: '19440834', label: 'case.companyRegistrationNumber' },
		{ value: '5078 5595591', label: 'Jyske Bank' },
	];

	const paymentInfo: SelectOption[] =
		header === undefined
			? []
			: [
					{
						value: header.orderNo ?? '',
						label: 'creditNote.orderNo',
					},
					{ value: header.customerNo ?? '', label: 'case.debitorNo' },
					{
						value: header.salesPerson ?? '',
						label: 'creditNote.salesPerson',
					},
					{
						value: header.paymentTerms ?? '',
						label: 'creditNote.paymentTerms',
					},
			  ];

	const almInfo: SelectOption[] = [
		{
			value: caseData?.case.debitor.policeNumber ?? '',
			label: 'case.policyOrClaimNumber',
		},
		{
			value: caseData?.case.requisitioner.name ?? '',
			label: 'case.requisitioner',
		},
		{
			value: caseData?.case.damage.cause.name ?? '',
			label: 'case.damageType',
		},
		{ value: '??', label: 'common.date' },
		{ value: '??', label: 'common.dueDate' },
	];
	const [sellTo, setSellTo] = React.useState<number>(0);
	const [billTo, setBillTo] = React.useState<number>(0);

	const posted = false;

	const choices: SelectOption[][] = [injuredParty, debitor, policyHolder];

	const priceSum =
		linesData?.salesInvoiceLines !== null
			? linesData?.salesInvoiceLines.reduce(function (prev, cur) {
					return prev + (cur?.vatBaseAmount ?? 0);
			  }, 0)
			: 0;

	if (caseLoading || typeof caseData === 'undefined') return <Loading />;
	//sellTo <-- Bestilt  billto <-- Betaler

	return (
		<div>
			<Header>
				<h1 className="text-blue h-6 text-xl font-bold">{`${t('creditNote.overviewTitle')} ${t('case.caseNo')} ${caseData.case.erpNo}`}</h1>
				<div className="mt-2 flex flex-row items-center">
					<div className="mr-4">
						<Checkbox title="creditNote.setReady" name="setReady" defaultChecked={true} />
						<Checkbox title="common.approve" name="approve" defaultChecked={true} />
					</div>
					{!posted ? (
						<Button submit success text="creditNote.post" />
					) : (
						<p className="text-green font-semibold">
							<FontAwesomeIcon icon={faCheck} className="mr-2" />
							{t('common.creditNotePosted')}
						</p>
					)}
				</div>
			</Header>
			<BoxContainer>
				<Box title="common.sellTo" icon={posted ? undefined : faExchange} onClick={() => setEditSellTo(true)}>
					<BoxFormDataViewer data={choices[sellTo]} keyId="common.sellTo" />
				</Box>
				<Box title="common.billTo" icon={posted ? undefined : faExchange} onClick={() => setEditBillTo(true)}>
					<BoxFormDataViewer data={choices[billTo]} keyId="common.billTo" />
				</Box>
				<Box title="case.ssgInformation">
					<BoxFormDataViewer data={ssgInfo} keyId="case.ssgInformation" />
				</Box>
				<Box title="creditNote.paymentInfo">
					<BoxFormDataViewer data={paymentInfo} keyId="case.paymentInfo" />
				</Box>
				<Box title="creditNote.almInfo">
					<BoxFormDataViewer data={almInfo} keyId="case.almInfo" />
				</Box>
				<Box title="creditNote.comment">
					<Textarea title="" name="comment" rows={3} className="h-40 w-full lg:w-full" defaultValue="Der er foretaget arbejde." disabled={posted} />
					{/* Arbejde gemt? */}
				</Box>
				<Box title="" full className="text-blue">
					<Table
						data={linesData?.salesInvoiceLines ?? []}
						columns={[
							{
								classNameTh: 'w-2/5',
								label: 'common.description',
								selectFn: l => <p className="py-2 text-sm font-medium">{l?.description}</p>,
							},
							{
								label: 'common.quantity',
								selectFn: l => (
									<p className="py-2 text-sm font-medium">
										{l?.quantity} {l?.uom}
									</p>
								),
							},
							{
								label: 'common.salesPrice',
								align: 'RIGHT',
								selectFn: l => (
									<p className="py-2 text-sm font-medium">
										{l?.unitPrice}
										{t('common.localCurrency')}
									</p>
								),
							},
							{
								label: 'creditNote.discountPercentage',
								align: 'CENTER',
								selectFn: l => <p className="py-2 text-sm font-medium">{l?.lineDiscountPercentage}%</p>,
							},
							{
								label: 'creditNote.totalNoVat',
								align: 'RIGHT',
								selectFn: l => (
									<p className="py-2 text-sm font-medium">
										{l?.vatBaseAmount}
										{t('common.localCurrency')}
									</p>
								),
							},
						]}
						keySelector={l => l?.description ?? Math.random().toString(36).substring(2)}
					/>
					<div className="text-blue border-t-1 py-1 text-right font-semibold">
						{priceSum}
						{t('common.localCurrency')}
					</div>
				</Box>
			</BoxContainer>
			<Modal
				title="creditNote.selectSellTo"
				body={
					<div className="flex flex-row flex-wrap justify-between">
						{choices.map((c, i) => (
							<div
								key={'creditNota.editSellTo' + i}
								onClick={() => {
									setSellTo(i);
									setEditSellTo(false);
								}}
								className={classNames('cursor-pointer border-2 px-4 py-2', {
									'border-blue': i === sellTo,
									'border-transparent': i !== sellTo,
								})}
							>
								<BoxFormDataViewer data={c} keyId="creditNota.editSellToData" />
							</div>
						))}
					</div>
				}
				visible={editSellTo}
				close={() => setEditSellTo(false)}
			/>

			<Modal
				title="creditNote.selectBillTo"
				body={
					<div className="flex flex-row flex-wrap justify-between">
						{choices.map((c, i) => (
							<div
								key={'creditNota.editBillTo' + i}
								onClick={() => {
									setBillTo(i);
									setEditBillTo(false);
								}}
								className={classNames('cursor-pointer border-2 px-4 py-2', {
									'border-blue': i === billTo,
									'border-transparent': i !== billTo,
								})}
							>
								<BoxFormDataViewer data={c} keyId="creditNota.editSellToData" />
							</div>
						))}
					</div>
				}
				visible={editBillTo}
				close={() => setEditBillTo(false)}
			/>
		</div>
	);
};

export default CreditNote;
