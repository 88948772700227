import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

interface Props {
	fromValue: string | null | undefined;
	toValue: string | null | undefined;
	// fromOnChange: React.ChangeEventHandler<HTMLInputElement>;
	// toOnChange: React.ChangeEventHandler<HTMLInputElement>;
	// betweenOnChange: (value: number) => void;
	onChange(from: Date | undefined, to: Date | undefined, count: number): unknown;
	className?: string;
}

const STYLES = 'border-1 border-gray-600 rounded-default block focus:outline-none py-0 pr-3 pl-1 text-sm w-40';

const toDateString = (date?: Date) => (date == null ? '' : date.toISOString().split('T')[0]);

const fromNullStringUndefinedToDate = (dateString: string | null | undefined): Date | undefined => {
	if (typeof dateString === 'undefined' || dateString === null) {
		return undefined;
	}
	return new Date(dateString);
};

const DateRangeInput: React.FC<Props> = ({ fromValue, toValue, onChange, className }) => {
	const [from, setFrom] = React.useState<Date | undefined>(fromNullStringUndefinedToDate(fromValue));
	const [to, setTo] = React.useState<Date | undefined>(fromNullStringUndefinedToDate(toValue));
	const prevDaysBetween = React.useRef<number>();

	const daysBetween = React.useMemo(() => {
		if (from == null || to == null) return;

		const luxFrom = DateTime.fromJSDate(from);
		const luxTo = DateTime.fromJSDate(to);

		const duration = luxTo.diff(luxFrom, ['days']);

		return Math.floor(duration.days);
	}, [from, to]);

	React.useEffect(() => {
		if (daysBetween != null && daysBetween !== prevDaysBetween.current) {
			prevDaysBetween.current = daysBetween;
			onChange(from, to, daysBetween);
		}
	}, [daysBetween, from, onChange, to]);

	return (
		<div className={classNames('flex flex-col space-y-2', className)}>
			<input
				type="date"
				className={STYLES}
				value={toDateString(from)}
				onChange={e => {
					const newFrom = e.target.valueAsDate ?? undefined;
					if (!newFrom) return;

					setFrom(newFrom);

					if (to == null || newFrom > to) {
						setTo(newFrom);
					}
				}}
			/>
			<input
				type="date"
				className={STYLES}
				value={toDateString(to)}
				onChange={e => {
					const newTo = e.target.valueAsDate ?? undefined;
					if (!newTo) return;

					setTo(newTo);

					if (from == null || newTo < from) {
						setFrom(newTo);
					}
				}}
			/>
		</div>
	);
};

export default DateRangeInput;
