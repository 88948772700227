import React from 'react';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { loader } from 'graphql.macro';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
	FileActionType,
	CatalogCraftsmanInput,
	CatalogGlobalType,
	CreateCatalogCraftsman,
	CreateCatalogCraftsmanVariables,
	CreateCatalogCraftsman_createCatalogCraftsman_informations,
	GetCatalogCraftsmen,
	GetWebCatalogCustomers_catalogCustomers,
	UpdateCatalogCraftsman,
	UpdateCatalogCraftsmanVariables,
	GetCatalog,
	GetCatalogContactsVariables,
	GetCatalogsForDropdown,
	GetCatalogsForDropdownVariables,
	GetCatalog_catalog_craftsmen,
	GetCatalogCraftsmenVariables,
	GetCatalogVariables,
} from '../../../GraphQL';
import { CatalogCraftsmanSchema } from '../../../Schemas/CatalogCraftsmanSchema';
import { faEdit, faPlus, faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeTypename } from '../../../helper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Select, { ValueType, ControlProps } from 'react-select';
import Button from '@ssg/common/Components/Button';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import SearchableSelect from '@ssg/common/Components/SearchableSelect';
import UserContext from '../../../UserContext';
import Dropdown from '@ssg/common/Components/Dropdown';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import FormHelpText from '@ssg/common/Components/FormHelpText';
import Checkbox from '@ssg/common/Components/Checkbox';
import CatalogPhoneInformationModal from '../CatalogPhoneInformation/CatalogPhoneInformationModal';
import Table from '@ssg/common/Components/Table';
import TextButton from '@ssg/common/Components/TextButton';

const CREATE_CATALOG_CRAFTSMAN = loader('src/GraphQL/CatalogCraftsmen/CreateCatalogCraftsman.gql');
const UPDATE_CATALOG_CRAFTSMAN = loader('src/GraphQL/CatalogCraftsmen/UpdateCatalogCraftsman.gql');
const GET_CATALOG_CRAFTSMEN = loader('src/GraphQL/CatalogCraftsmen/GetCatalogCraftsmen.gql');
const GET_CATALOGS_FOR_DROPDOWN = loader('src/GraphQL/Catalogs/GetCatalogsForDropdown.gql');
const GET_CATALOG = loader('src/GraphQL/Catalogs/GetCatalog.gql');

interface Props {
	open: boolean;
	close: () => void;
	edit?: boolean;
	data?: GetCatalog_catalog_craftsmen;
	changedBy: string;
	catalogId: string | undefined;
	catalogCustomers?: GetWebCatalogCustomers_catalogCustomers[];
	catalogCustomer: GetWebCatalogCustomers_catalogCustomers | undefined;
	typeDropdownItems: SelectOption[];
	priorityDropdownItems: SelectOption[];
	fetchVariables: GetCatalogContactsVariables;
}

const CatalogCraftsmanModal: React.FC<Props> = ({
	open,
	close,
	edit = false,
	data,
	changedBy,
	catalogId,
	catalogCustomers,
	catalogCustomer,
	typeDropdownItems,
	priorityDropdownItems,
	fetchVariables,
}) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);
	const isExternal = userContext.user?.external;

	const [globalType, setGlobalType] = React.useState<CatalogGlobalType>(data ? data.globalType : !catalogId ? CatalogGlobalType.CUSTOMER_GLOBAL : CatalogGlobalType.NOT_GLOBAL);

	const { handleSubmit, control, setValue, errors, register } = useForm<CatalogCraftsmanInput>({
		resolver: yupResolver(CatalogCraftsmanSchema),
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			customer: catalogCustomer?.id,
		},
	});

	const [showHelpText, setShowHelpText] = React.useState(false);
	const [selectedCustomer, setSelectedCustomer] = React.useState<string | undefined>(isExternal ? catalogCustomer?.id ?? undefined : data?.customer.id);

	const { data: catalogList, loading: catalogListLoading } = useQuery<GetCatalogsForDropdown, GetCatalogsForDropdownVariables>(GET_CATALOGS_FOR_DROPDOWN, {
		fetchPolicy: 'cache-and-network',
		variables: {
			thisCustomerOnly: isExternal,
			customerIds: [selectedCustomer ?? ''],
		},
		skip: typeof selectedCustomer === 'undefined' || typeof catalogId !== 'undefined',
	});

	const [createCraftsman, { loading: loadingCreate }] = useMutation<CreateCatalogCraftsman, CreateCatalogCraftsmanVariables>(CREATE_CATALOG_CRAFTSMAN);
	const [updateCraftsman, { loading: loadingUpdate }] = useMutation<UpdateCatalogCraftsman, UpdateCatalogCraftsmanVariables>(UPDATE_CATALOG_CRAFTSMAN);

	const [informations, setInformations] = React.useState<CreateCatalogCraftsman_createCatalogCraftsman_informations[]>(
		data?.informations
			? data.informations.map(info => {
				return {
					phoneNumber: info?.phoneNumber,
					remark: info?.remark,
				} as CreateCatalogCraftsman_createCatalogCraftsman_informations;
			})
			: [],
	);

	const [informationIndex, setInformationIndex] = React.useState<number | undefined>(undefined);
	const [information, setInformation] = React.useState<CreateCatalogCraftsman_createCatalogCraftsman_informations>();
	const [updateInformation, setUpdateInformation] = React.useState(false);
	const [createInformation, setCreateInformation] = React.useState(false);
	const [deleteInformation, setDeleteInformation] = React.useState(false);
	React.useEffect(() => {
		if (typeof informationIndex !== 'undefined') {
			setInformation(informations[informationIndex]);
		}
	}, [informationIndex, informations]);

	const onSubmit = async (formData: CatalogCraftsmanInput) => {
		if (edit) {
			await updateCraftsman({
				variables: {
					id: data?.id ?? '',
					customer: formData.customer,
					changedBy: changedBy,
					type: formData.type,
					contactName: formData.contactName,
					email: formData.email,
					informations: removeTypename(informations) ?? [],
					priority: formData.priority,
					global: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL,
					active: !catalogId ? formData.active : data?.active ?? true,
					globalType: formData.globalType,
					catalogs: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? [] : selectedCats,
					catalogsBefore: data?.catalogIds ?? [],
					action: FileActionType.UPDATE,
				},
			});
		} else {
			await createCraftsman({
				variables: {
					catalogCraftsman: {
						customer: formData.customer,
						changedBy: changedBy,
						type: formData.type,
						contactName: formData.contactName,
						email: formData.email ?? '',
						informations: removeTypename(informations) ?? [],
						priority: formData.priority,
						global: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL,
						active: !catalogId ? formData.active : true,
						globalType: formData.globalType,
						catalogs: formData.globalType === CatalogGlobalType.CUSTOMER_GLOBAL ? [] : selectedCats,
					},
				},
				update: (cache, { data: cacheData }): void => {
					if (typeof cacheData === 'undefined' || cacheData === null) {
						return;
					}

					const cachedCraftmenRequest = cache.readQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
						query: GET_CATALOG_CRAFTSMEN,
						variables: fetchVariables,
					});

					if (cachedCraftmenRequest !== null && cachedCraftmenRequest.catalogCraftsmen !== null) {
						cache.writeQuery<GetCatalogCraftsmen, GetCatalogCraftsmenVariables>({
							query: GET_CATALOG_CRAFTSMEN,
							variables: fetchVariables,
							data: {
								catalogCraftsmen: [cacheData.createCatalogCraftsman, ...cachedCraftmenRequest.catalogCraftsmen],
							},
						});
					}

					if (!catalogId) {
						return;
					}

					const cachedCatalogRequest = cache.readQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
					});

					if (cachedCatalogRequest === null || cachedCatalogRequest.catalog === null) {
						return;
					}

					cache.writeQuery<GetCatalog, GetCatalogVariables>({
						query: GET_CATALOG,
						variables: {
							id: catalogId,
						},
						data: {
							catalog: {
								...cachedCatalogRequest.catalog,
								craftsmen: [...cachedCatalogRequest.catalog.craftsmen, cacheData.createCatalogCraftsman],
							},
						},
					});
				},
			});
		}
		close();
	};
	const [customers, setCustomers] = React.useState<SelectOption[]>([]);

	const [searchCustomerText, setSearchCustomerText] = React.useState<string>('');

	React.useEffect(() => {
		setCustomers((catalogCustomers ?? []).filter(ct => ct.name.toLowerCase().includes(searchCustomerText.toLowerCase())).map((u): SelectOption => ({ value: u.id, label: u.name })) ?? []);
	}, [catalogCustomers, searchCustomerText]);

	//const [selectedCats, setSelectedCats] = React.useState<string[]>(catalogId ? [catalogId] : (data?.catalogs && data.catalogs.map(c => c.id)) ?? []);
	const [selectedCats, setSelectedCats] = React.useState<string[]>(catalogId ? [...(data?.catalogIds ?? []).filter(c => c !== catalogId), ...[catalogId]] : data?.catalogIds ?? []);

	const cats = React.useMemo(
		() =>
			catalogList?.catalogs
				.filter(c => c.customer.id === selectedCustomer)
				.map(
					(d): SelectOption => ({
						value: d.id ?? '',
						label: d.propertyNumber ?? '',
					}),
				) ?? [],
		[catalogList?.catalogs, selectedCustomer],
	);

	const CatalogHandler = (value: ValueType<SelectOption, true>): void => {
		if (value) {
			setSelectedCats(value.map(val => val.value));
		} else {
			setSelectedCats([]);
		}
		setValue('catalogs', value, { shouldValidate: true });
	};

	const selectedCatalogs = React.useMemo(() => {
		return cats.filter(c => selectedCats.includes(c.value));
	}, [cats, selectedCats]);

	const customStyles = {
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		control: (provided: React.CSSProperties) => ({
			...provided,
			border: 'none',
			fontSize: '1rem',
			outline: '0px',
		}),
		valueContainer: (provided: React.CSSProperties, state: ControlProps<SelectOption, true>) => ({
			...provided,
			padding: '4px',
			outline: state.isFocused ? '0px' : '0px',
		}),
	};

	React.useEffect(() => {
		if (edit) {
			setValue('customer', data?.customer.id);
		} else {
			setValue('customer', catalogCustomer?.id);
		}
	}, [setValue, catalogCustomer, edit, data]);

	return (
		<Modal
			title={(edit && 'catalog.craftsman.update') || 'catalog.craftsman.create'}
			size={ModalSize.LARGE}
			helpButtonClick={() => setShowHelpText(!showHelpText)}
			showHelpText={showHelpText}
			helpButton={globalType === CatalogGlobalType.CATALOGS_GLOBAL}
			visible={open}
			close={close}
			body={
				<div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col lg:flex-row">
							<div className="mr-4 w-2/5">
								<SearchableSelect
									key="userLabel"
									control={control}
									name="customer"
									title="catalog.customer"
									required
									readOnly={catalogCustomer !== undefined}
									options={customers}
									searchFn={searchText => setSearchCustomerText(searchText)}
									onSelect={value => {
										setValue('customer', value, {
											shouldValidate: true,
										});
										setSelectedCustomer(value);
									}}
									onBlur={() => undefined}
									minInputLength={-1}
									allowEmpty
									className={catalogCustomer !== undefined ? 'hidden' : undefined}
									isLoading={false}
									errorMessage={errors?.customer?.message ?? ''}
									initialSelection={
										typeof data === 'undefined'
											? undefined
											: {
												value: data.customer.id ?? '',
												label: data.customer.name ?? '',
											}
									}
								/>
								{catalogCustomer !== undefined && (
									<>
										<h2>{catalogCustomer.name}</h2>
									</>
								)}

								<Dropdown title="common.type" name="type" innerRef={register} data={typeDropdownItems} required defaultValue={data?.type} />

								<Input title="catalog.contact.name" name="contactName" required innerRef={register} defaultValue={data?.contactName} errorMessage={errors.contactName?.message} />

								<Input title="common.email" name="email" innerRef={register} defaultValue={data?.email ?? ''} errorMessage={errors.email?.message} />

								<Dropdown title="catalog.craftsman.priority" name="priority" innerRef={register} data={priorityDropdownItems} required defaultValue={data?.priority} />

								{!catalogId && (
									<Checkbox
										title="common.active"
										name="active"
										className="w-full pt-3 lg:w-full"
										required
										innerRef={register}
										defaultChecked={data?.active ?? false}
										errorMessage={errors.active?.message}
									/>
								)}

								<Dropdown
									title="catalog.attached"
									name="globalType"
									innerRef={register}
									required
									value={globalType}
									onChange={e => setGlobalType(e.currentTarget.value as CatalogGlobalType)}
									disableSortLabelAlphabetically
									data={[
										{
											value: CatalogGlobalType.CATALOGS_GLOBAL,
											label: typeof catalogId === 'undefined' ? 'catalog.catalog' : 'catalog.thisCatalog',
										},
										{
											value: CatalogGlobalType.CUSTOMER_GLOBAL,
											label: 'catalog.customer',
										},
									]}
								/>

								<div>
									{typeof catalogId === 'undefined' && globalType === CatalogGlobalType.CATALOGS_GLOBAL && (
										<div>
											{!catalogId && <FormFieldHeader title="catalog.propertyNumbers" />}
											{showHelpText && <FormHelpText text="catalog.craftsman.helpText.catalogs" />}
											<Controller
												name="catalogs"
												control={control}
												render={() => (
													<Select
														isMulti
														options={cats}
														closeMenuOnSelect={false}
														noOptionsMessage={inputValue =>
															inputValue.inputValue === ''
																? t('catalog.timedMessage.noCatalogs')
																: `${t('catalog.timedMessage.noCatalogWithName')} '${inputValue.inputValue}'`
														}
														placeholder={<div>{t('catalog.timedMessage.searchCatalogs')}</div>}
														styles={customStyles}
														inputRef={register}
														onChange={val => CatalogHandler(val)}
														value={selectedCatalogs}
														className={catalogId ? 'invisible m-0 h-0 p-0' : 'border-1 rounded-default w-full border-gray-600 lg:w-full'}
													/>
												)}
											/>
											{catalogListLoading && (
												<p className="text-blue text-xs">
													<FontAwesomeIcon icon={faSpinner} className="mr-1 animate-spin" />
													{t('catalog.loadingPropertyNumbers')}
												</p>
											)}
											<FormErrorText text={typeof errors.catalogs !== 'undefined' ? (errors.catalogs as unknown as FieldError).message ?? '' : ''} />
										</div>
									)}
								</div>

								<Button
									submit
									success
									text={edit ? 'catalog.craftsman.update' : 'catalog.craftsman.create'}
									className="mt-2 pl-4"
									loading={loadingCreate || loadingUpdate}
									disabled={loadingCreate || loadingUpdate}
								/>
							</div>
							<div className="mr-4 w-3/5">
								<div className="text-blue mb-3">
									<div className="flex justify-end">
										<TextButton text="catalog.craftsman.addInformation" icon={faPlus} className="mt-7" onClick={() => setCreateInformation(true)} />
									</div>
									<Table
										data={informations}
										columns={[
											{
												label: t('common.phone'),
												selectFn: c => formatPhoneNumberIntl(c.phoneNumber),
											},
											{
												label: t('catalog.contact.remark'),
												noTruncate: true,
												selectFn: c => <p>{c.remark}</p>,
												sortFn: (a, b) => (a?.remark ?? '').localeCompare(b?.remark ?? ''),
											},
											{
												label: t('common.edit'),
												classNameTh: 'text-right',
												selectFn: (c, i) => (
													<div className="flex content-start justify-end text-right">
														<FontAwesomeIcon
															icon={faEdit}
															size="lg"
															onClick={() => {
																setInformationIndex(i);
																setUpdateInformation(true);
															}}
															className="cursor-pointer"
														/>
													</div>
												),
											},
											{
												label: t('common.remove'),
												classNameTh: 'text-right',
												selectFn: (c, i) => (
													<div className="text-red flex content-start justify-end text-right">
														<FontAwesomeIcon
															icon={faTrashAlt}
															size="lg"
															onClick={() => {
																setInformationIndex(i);
																setDeleteInformation(true);
															}}
															className="cursor-pointer"
														/>
													</div>
												),
											},
										]}
										keySelector={c => `${c.phoneNumber}-${c.remark}`}
										noDataFoundText={t('catalog.craftsman.noNumbers')}
									/>
								</div>
							</div>
						</div>
					</form>
					{/* Catalog Craftsman Information Modal */}
					<div>
						{createInformation && (
							<CatalogPhoneInformationModal setInformations={setInformations} informations={informations} open={createInformation} close={() => setCreateInformation(false)} />
						)}

						{updateInformation && typeof information !== 'undefined' && (
							<CatalogPhoneInformationModal
								setInformations={setInformations}
								informations={informations}
								open={updateInformation}
								close={() => setUpdateInformation(false)}
								edit
								data={information}
								index={informationIndex}
							/>
						)}

						{deleteInformation && typeof information !== 'undefined' && (
							<CatalogPhoneInformationModal
								setInformations={setInformations}
								informations={informations}
								erase
								open={deleteInformation}
								close={() => setDeleteInformation(false)}
								data={information}
								index={informationIndex}
							/>
						)}
					</div>
				</div>
			}
		/>
	);
};

export default CatalogCraftsmanModal;
